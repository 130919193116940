var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode === "title"
    ? _c(
        "div",
        { staticClass: "ware-title" },
        [
          _c("nuxt-link", { attrs: { to: _vm.wareLink } }, [
            _c(
              "h1",
              { staticClass: "custom-headline custom-headline--level2" },
              [
                _c("span", { staticClass: "bold" }, [
                  _vm._v("\n        " + _vm._s(_vm.tmName) + " "),
                  _vm.useTmBr ? _c("br") : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.acName) + "\n      "),
                ]),
                _vm._v(" "),
                _vm.useBr ? _c("br") : _vm._e(),
                _vm._v(" "),
                _c("span", { class: { "text-small-2": _vm.doubleName } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.ware.display_title || _vm.ware.name) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "small",
                  [
                    _c(
                      "el-link",
                      {
                        staticClass: "text-small-2",
                        attrs: { type: "primary" },
                      },
                      [
                        _vm._v(
                          "\n          (" +
                            _vm._s(_vm.$t("ware.details")) +
                            ")\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm.mode === "info"
    ? _c(
        "div",
        { staticClass: "ware-title" },
        [
          _c("div", { staticClass: "tm bold color-black" }, [
            _vm._v("\n    " + _vm._s(_vm.tmName) + "\n  "),
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "mb-05", attrs: { align: "middle" } },
            [
              _c(
                "el-link",
                {
                  staticClass: "mr-1 block ac",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$router.push(_vm.wareLink)
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.acName) + "\n    ")]
              ),
              _vm._v(" "),
              _vm.isShowIcons
                ? _c("WareInfoIcons", {
                    staticClass: "op-4",
                    attrs: { "google-search": "", ware: _vm.icons || _vm.ware },
                    on: {
                      show: function ($event) {
                        return _vm.$emit("info:show", _vm.ware)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text-small-2" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.ware.display_title || _vm.ware.name) +
                "\n  "
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }