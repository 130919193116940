var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-preview-list" },
    [
      _c("FilePreview", {
        staticClass: "mb-1 main-photo",
        attrs: {
          alt: _vm.altWithIndex(_vm.activeFileIndex),
          file: _vm.value[_vm.activeFileIndex || 0],
          size: _vm.previewSize,
          "img-path": _vm.imgPath,
          "default-img-path": _vm.defaultImgPath,
        },
        on: { clickedFile: _vm.showSlider },
      }),
      _vm._v(" "),
      _vm.sortedFiles.length > 1
        ? _c(
            "div",
            { staticClass: "list-preview scroll" },
            _vm._l(_vm.sortedFiles, function (file, index) {
              return _c(
                "div",
                { key: file.id, staticClass: "list-preview-item" },
                [
                  _c(
                    "FilePreview",
                    _vm._b(
                      {
                        class: [
                          "file",
                          { "disable-events": _vm.disableEvents },
                        ],
                        attrs: {
                          alt: _vm.altWithIndex(index),
                          file: file,
                          "preview-icon": "",
                          size: _vm.size,
                          theme: _vm.theme,
                          "img-path": "image_60x60",
                          "default-img-path": _vm.defaultImgPath,
                        },
                        on: {
                          clickedFile: function ($event) {
                            return _vm.setActiveFileAt(index)
                          },
                        },
                      },
                      "FilePreview",
                      _vm.$attrs,
                      false
                    )
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSliderShow && _vm.sortedFiles.length
        ? _c("FilePreviewDialog", {
            attrs: {
              "active-file-index": _vm.activeFileIndex || 0,
              files: _vm.sortedFiles,
              "img-path": _vm.dialogImgPath,
              "default-img-path": _vm.defaultImgPath,
            },
            on: {
              close: function ($event) {
                _vm.isSliderShow = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [_vm._t("popupHeader")]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [_vm._t("popupFooter")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }