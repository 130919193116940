<template>
  <div :class="['audio-preview', { 'mini-audio': previewIcon }]">
    <audio v-if="!previewIcon" ref="audio" controls>
      <source :src="value.url">
    </audio>
    <img v-else src="@/assets/fileTypes/audio-icon.svg">
  </div>
</template>

<script>
export default {
  name: 'AudioPreview',

  props: {
    value: {
      type: Object, required: true
    },
    previewIcon: Boolean,
    playing: Boolean
  },

  watch: {
    playing: 'togglePlay'
  },

  methods: {
    togglePlay (playing) {
      if (!this.$refs.audio) {
        return;
      }

      if (!playing) {
        this.$refs.audio.pause();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.mini-audio {
  background: $grey-200;
}

.audio-preview {
  display: flex;
  justify-content: center;
  max-width: 100%;

  * {
    max-width: 100%;
  }

  audio {
    margin: auto;
  }

  img {
    opacity: .7;
  }

  &:hover img {
    opacity: 1;
  }
}
</style>
