<template>
  <div class="price-create-steps">
    <el-steps :active="active" finish-status="success" align-center>
      <el-step
        v-for="(step, index) in $t('prices.steps')"
        :key="index"
        :description="step"
        :title="`${$t('prices.step')} ${index +1}`"
      />
    </el-steps>
  </div>
</template>

<script>
import ElSteps from 'element-ui/lib/steps';
import ElStep from 'element-ui/lib/step';

export default {
  name: 'PriceCreateSteps',

  components: {
    ElSteps,
    ElStep
  },

  props: {
    active: { type: Number, default: 0 }
  }
};
</script>

<style lang="scss">
.price-create-steps {
  margin-bottom: 10px;

  ::v-deep {
    .is-process {
      color: $--color-text-regular!important;
    }

    .el-step__head.is-process .el-step__icon.is-text {
      border-color: $--color-text-regular;
    }
  }
}
</style>
