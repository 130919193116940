<template>
  <div class="the-logo">
    <nuxt-link class="link" :to="link">
      <img
        v-lazy-load
        class="img"
        :data-src="logo"
        :alt="APP_NAME"
        width="120"
        height="36"
        :title="APP_NAME"
      >
    </nuxt-link>
  </div>
</template>

<script>
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';
import { env } from '@/lib/core';

export default {
  name: 'TheLogo',

  data: () => ({ APP_NAME: env.APP_NAME }),

  computed: {
    logo () {
      const NODE_ENV = this.$store.getters.isDev ? 'dev' : 'prod';
      const name = `logo_${env.MARKETPLACE}_${NODE_ENV}`;
      return require(`@/assets/img/${name}.svg`);
    },

    link () {
      return getSeoLinkBy({}, this, {});
    }
  }
};
</script>

<style scoped lang="scss">
.link {
  background-color: transparent!important;
}
.img {
  display: block;
  height: 36px;
  width: 120px;
  background-color: transparent!important;
}
</style>
