<template>
  <TheDialog
    width="400px"
    class="profile-select"
    top="5vh"
    :value="true"
    :title="$t('profile.selectProfile')"
    @cancel="$emit('close')"
  >
    <div class="profile-select__wrapper">
      <div class="m-label">
        {{ $t('profile.buyer') }}
      </div>

      <el-radio-group v-if="user" v-model="code" class="pg-2">
        <el-radio
          :class="['mb-1', `u${user.id}`]"
          :label="`u${user.id}`"
        >
          {{ user.profile.profile_name }}
        </el-radio>

        <el-radio
          v-for="buyer in profiles.buyers"
          :key="buyer.id"
          :class="['mb-1', `b${buyer.id}`]"
          :label="`b${buyer.id}`"
        >
          {{ buyer.business.name }}
        </el-radio>
      </el-radio-group>

      <div v-if="profiles.sellers.length" class="m-label">
        {{ $t('profile.seller') }}
      </div>

      <el-radio-group v-model="code" class="pg-2">
        <el-radio
          v-for="seller in profiles.sellers"
          :key="seller.id"
          :class="['mb-1', `b${seller.id}`]"
          :label="`b${seller.id}`"
        >
          {{ seller.business.name }}
        </el-radio>
      </el-radio-group>
    </div>

    <el-row justify="space-around" class="buttons mt-2">
      <el-button @click="close">{{ $t('buttons.cancel') }}</el-button>
      <el-button :disabled="!code" type="primary" @click="select">
        {{ $t('buttons.select') }}
      </el-button>
    </el-row>

    <hr>

    <el-row v-if="$route.name !== 'profile.business.create'" justify="center">
      <el-button icon="el-icon-circle-plus-outline" @click="goCreateProfile">
        {{ $t('buttons.addBusinessProfile') }}
      </el-button>
    </el-row>
  </TheDialog>
</template>

<script>
import partition from 'lodash/partition';
import scrollToTop from '@/lib/core/services/scrollToTop';

export default {
  name: 'ProfileSelect',

  props: {
    updateBasket: Boolean
  },

  data () {
    return {
      code: ''
    };
  },

  computed: {
    user () {
      return this.$store.getters['users/user'];
    },

    userCode () {
      return this.$store.getters.userCode;
    },

    profiles () {
      const allBusiness = this.$store.getters['businessUsers/businessUsers'] || [];
      const [sellers, buyers] = partition(allBusiness, item => item.business.is_seller);
      return { sellers, buyers };
    }
  },

  watch: {
    userCode: {
      immediate: true,
      handler () {
        this.code = this.userCode;
        this.scrollToSelected();
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.$refs.buttons && this.$refs.buttons.handleScroll();
    });
  },

  methods: {
    scrollToSelected () {
      if (this.code) {
        scrollToTop(this.code, { parentClassName: 'profile-select__wrapper' });
      }
    },

    async select () {
      // TODO collect login in one place, update all needed fields when user select profile
      await this.$store.dispatch('setUserCode', this.code);
      this.$store.dispatch('fetchCity');
      await this.$store.dispatch('cars/fetchCarsQL', { force: true });

      this.$emit('select', this.code);

      if (this.updateBasket) {
        this.$nextTick(() => {
          this.$store.dispatch('baskets/fetchBasket', { clear: true });
        });
      }
    },

    close () {
      this.$emit('close');
    },

    goCreateProfile () {
      this.$router.push({
        name: this.getRouteName('profile.business.create')
      });

      this.$emit('close', true);
    }
  }
};
</script>

<style scoped lang="scss">
.profile-select {
  &__wrapper {
    max-height: calc(100vh - 15vh - 220px);
    overflow: auto;
  }

  ::v-deep {
    .el-dialog {
      text-align: left !important;
      max-height: calc(100vh - 10vh);
      display: flex;
      flex-direction: column;
    }

    .el-radio__label {
      white-space: normal!important;
    }

    .m-label {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .el-dialog__body {
      display: flex;
      flex-direction: column;
      height: calc(100% - 55px) !important;
    }
  }

  @media all and (max-width: $--xss) {
    &__wrapper {
      max-height: unset;
      flex: 1 1;
    }
  }
}
</style>
