import get from 'lodash/get';
import { i18n } from '@/plugins/i18n';

export default (data, options = {}) => {
  const metaH1 = get(data, 'meta.h1');
  const _pageNumSuffix = options.page
    ? ` - ${i18n.t('core.pageNum', { page: options.page })}`
    : '';

  if (metaH1 && !options.withoutRegion) {
    return metaH1 + _pageNumSuffix;
  }

  const line = get(data, 'product_line.name', '');
  const trademark = get(data, 'trademark.name', '');
  const region = options.withoutRegion ? '' : get(data, 'region.name', '');

  const names = {
    brand: get(data, 'car_brand.name'),
    model: get(data, 'car_model.name'),
    generation: get(data, 'car_generation.name')
  };

  let title = line || i18n.t('catalog.catalog');

  if (trademark) {
    title = `${title} ${trademark} `;
  }

  if (Object.values(names).filter(Boolean).length) {
    title += ` ${i18n.t('catalog.for')} `;
  }

  const carName = names.generation
    ? names.generation
    : (names.brand ? `${names.brand} ` : '') + (names.model ? `${names.model} ` : '');

  return (title ? `${title} ` : '') +
    (carName ? `${carName} ` : '') +
    region + _pageNumSuffix;
};
