<template>
  <div class="wares-small-table">
    <el-table
      :data="value"
      :header-cell-style="{ textAlign: 'center' }"
      :row-key="rowKey"
      stripe
      @row-click="goToOrEmit"
    >
      <el-table-column
        :label="$t('search.tm_name')"
        :width="maxmq('xs') ? null : '150px'"
        prop="trademark.name"
      >
        <template slot-scope="{ row }">
          {{ row.trademark.name }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('search.article')"
        :width="maxmq('xs') ? null : '180px'"
        prop="article"
      />

      <el-table-column
        :label="$t('search.article_name')"
        prop="name"
      >
        <template slot-scope="{ row }">
          <slot name="description" v-bind="{ row }">
            {{ row.name }}
          </slot>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { parsers } from '@/lib/core';

export default {
  name: 'WaresSmallTable',

  components: {
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column')
    /* eslint-enable max-len */
  },

  props: {
    rowKey: { type: String, default: 'id' },
    value: { type: Array, required: true }
  },

  methods: {
    goToOrEmit (row) {
      if (this.$listeners.rowClick) {
        this.$listeners.rowClick(row);
      } else if (row.ware_id && row.article && row.tm_name) {
        const route = parsers.getSearchRouteBy(this, row);
        this.$router.push(route);
      } else {
        this.$router.push({
          name: this.getRouteName('search'),
          query: { q: row.article }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.wares-small-table {
  .ghost {
    color: $grey-500;
  }

  @media all and (max-width: $--xs) {
    font-size: 10px;
  }
}
</style>
