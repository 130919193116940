<template>
  <div class="ware-info">
    <TheLoader v-if="isLoading" />

    <el-row v-else-if="!ware" class="pg-2" justify="center">
      {{ $t('errors.emptyData') }}
    </el-row>

    <template v-else>
      <WareTitle class="mb-2" :ware="ware" />

      <el-tabs v-model="activeTab" type="card" @tab-click="setTab">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab"
          lazy
          :label="$t(`search.${tab}`)"
          :name="tab"
        >
          <component
            :is="`WareInfo${upperFirst(tab)}`"
            :ware="ware"
            :ware-title="wareTitle"
          />
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import without from 'lodash/without';

import WareTitle from '../WareTitle';
import WareInfoIcons from './WareInfoIcons';
import WareInfoMain from './WareInfoMain';
import WareInfoKits from './WareInfoKits';
import WareInfoApplication from './WareInfoApplication';
import WareInfoVariants from './WareInfoVariants';

export default {
  name: 'WareInfo',

  components: {
    WareTitle,
    WareInfoIcons,
    WareInfoMain,
    WareInfoKits,
    WareInfoApplication,
    WareInfoVariants
  },

  props: {
    id: { type: [Number, String] }
  },

  data () {
    return {
      isLoading: true,
      upperFirst,
      tabs: ['main', 'kits', 'application', 'variants'],
      activeTab: 'main',
      ware: null
    };
  },

  computed: {
    wareTitle () {
      const tm = get(this.ware, 'trademark.name', '');
      return `${this.ware.name} ${this.ware.article} ${tm}`;
    }
  },

  watch: {
    id: {
      immediate: true,
      handler: 'loadWare'
    }
  },

  methods: {
    async loadWare () {
      this.isLoading = true;

      try {
        this.ware = await this.$store.dispatch(
          'seo/getWareMeta',
          { id: this.id }
        );

        this.customizeUi(this.ware);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    customizeUi (ware) {
      if (!ware.has_kits) {
        this.tabs = without(this.tabs, 'kits');
      }

      if (!ware.has_applicability || !ware.car_brands?.length) {
        this.tabs = without(this.tabs, 'application');
      }
    },

    setTab ({ name }) {
      this.activeTab = name;
    }
  }
};
</script>
