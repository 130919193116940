var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cars-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                (_vm.meta && _vm.meta.h1) || _vm.$t("catalog.autoPartsForCars")
              ) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("CarsBrandsModels", {
        staticClass: "mb-2",
        attrs: { value: _vm.carBrands },
      }),
      _vm._v(" "),
      _vm.meta && _vm.meta.text
        ? _c("section", {
            staticClass: "pg-2 default-style",
            domProps: { innerHTML: _vm._s(_vm.meta.text) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }