var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "platforms-list-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("platform.choiceOfWorkingConditionsOnSites")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-ghost mb-4" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("platform.platformListDescription")))]),
      ]),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : _c("div", { staticClass: "outline-block" }, [
            _c("div", { staticClass: "outline-block__title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("platform.selectConditions")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("PlatformsTable", {
                  staticClass: "mb-4",
                  attrs: { value: _vm.platforms },
                }),
              ],
              1
            ),
          ]),
      _vm._v(" "),
      _c("el-row", { staticClass: "mb-1", attrs: { justify: "end" } }, [
        _c(
          "div",
          { staticClass: "text-center w-350p" },
          [
            _c(
              "el-button",
              {
                staticClass: "mb-1 w-100",
                attrs: { disabled: "", type: "primary" },
                on: { click: _vm.signContract },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("buttons.signContract")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-ghost" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("platform.signContractDescription")) +
                  "\n      "
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }