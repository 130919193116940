import { INT_FLOAT_REG } from './patterns';
import { i18n } from '@/plugins/i18n';

export default {
  business: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'change' }
  ],
  name: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'blur' }
  ],
  currency: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'change' }
  ],
  scheme_type: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'change' }
  ],
  static_coefficient: [
    {
      required: true,
      pattern: INT_FLOAT_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ],
  min_price: [
    {
      required: true,
      pattern: INT_FLOAT_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ],
  max_price: [
    {
      required: true,
      pattern: INT_FLOAT_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ],
  max_markup: [
    {
      required: true,
      pattern: INT_FLOAT_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ],
  min_markup: [
    {
      required: true,
      pattern: INT_FLOAT_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ]
};
