<template>
  <div class="price-create-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t("routes.profile_business_prices_create") }}
    </h1>

    <PriceCreateSteps :active="step" />

    <TheLoader v-if="!isPageLoaded" />

    <template v-else>
      <div v-if="step !== 3" class="price-label">
        <span class="custom-headline custom-headline--level2-bold mr-1">
          {{ formData.name }}
        </span>

        <el-row v-if="step === 1">
          <el-button
            class="mr-1"
            size="mini"
            type="danger"
            @click="isUploadDialog = true"
          >
            {{ $t("buttons.uploadPrice") }}
          </el-button>

          <PriceRemover :price="dependency.price" />
        </el-row>
      </div>

      <el-form
        ref="form"
        class="form-block"
        label-position="right"
        label-width="160px"
        :model="formData"
        :rules="rules"
        size="mini"
        @submit.native.prevent="submit"
      >
        <div v-if="step === 1" class="outline-block">
          <div class="outline-block__title">
            {{ $t("priceFile.mainInfoBlock") }}
          </div>

          <MainInfo
            :dependency="dependency"
            :value="formData"
            v-bind.sync="formData"
          />
        </div>

        <div v-else-if="step === 2" class="outline-block">
          <div class="outline-block__title">
            {{ $t("priceFile.mappingBlock") }}
          </div>

          <template v-if="isPriceError">
            <div class="mb-2">
              <h4 class="color-red">
                {{ $t("prices.status") }}: {{ getPETranslation(errorStatus) }}
              </h4>
            </div>
            <div>
              <p class="color-red">{{ price.error_message }}</p>
            </div>
          </template>

          <template v-else-if="!get(dependency, 'price.data_preview')">
            <div class="pg-2 text-center">
              {{ $t("priceFile.waitPreview") }}
            </div>
            <TheLoader />
          </template>

          <template v-else>
            <FileProcessing
              :dependency="dependency"
              :value="formData"
              :data-preview-count="dependency.price.data_preview_count"
              v-bind.sync="formData"
              @page:update="formData.column_mapping = []"
            />

            <el-row class="mb-2" align="middle" justify="space-between">
              <span class="color-label">
                {{ $t("priceFile.firstTenRow") }}
              </span>

              <el-button size="mini" @click="isFormatDialog = true">
                {{ $t("buttons.wrongTableData") }}
              </el-button>
            </el-row>

            <div class="mapping mb-2">
              <div class="table-container">
                <el-form-item
                  ref="mapping"
                  label-width="0"
                  prop="column_mapping"
                >
                  <ColumnMapping
                    v-if="!isLoading"
                    v-model="formData.column_mapping"
                    class="mb-2"
                    :dependency="dependency"
                    @input="clearMapValidation"
                  />

                  <div v-else class="pg-2">
                    {{ $t("priceMapping.isMappingProcessing") }}
                  </div>
                </el-form-item>

                <PricePreviewTable
                  :data-preview="dependency.price.data_preview"
                  :data-start-row="formData.data_start_row"
                  :header-row="formData.header_row"
                  :mapping="formData.column_mapping"
                />
              </div>
            </div>
          </template>
        </div>

        <div
          v-else-if="
            !isEmpty(get(dependency, 'price.data_preview')) && step === 3
          "
          class="outline-block"
        >
          <div class="outline-block__title">
            {{ $t("priceFile.availabilitiesBlock") }}
          </div>

          <el-form-item label-width="0" prop="availabilities">
            <Availabilities
              :dependency="{
                ...dependency,
                column_mapping: formData.column_mapping,
              }"
              :value="formData.availabilities"
              v-bind.sync="formData"
            />
          </el-form-item>
        </div>

        <PriceCreateStatus v-else-if="step === 4" :errors="errors" />

        <el-row v-if="step === 4" class="mt-2" justify="center">
          <el-button type="primary" @click="next">
            {{ $t("buttons.finish") }}
          </el-button>
        </el-row>

        <el-row v-else class="mt-2" justify="space-between">
          <div>
            <el-button v-if="step !== 4" @click="cancel">
              {{ $t("buttons.cancel") }}
            </el-button>
          </div>

          <el-row>
            <el-button v-if="step !== 4" @click="back">
              {{ $t("buttons.back") }}
            </el-button>

            <el-button
              :disabled="isNextDisabled"
              :loading="isLoading"
              type="primary"
              @click="next"
            >
              {{ $t("buttons.next") }}
            </el-button>
          </el-row>
        </el-row>
      </el-form>
    </template>

    <PriceReUploadDialog
      v-if="dependency.price && isUploadDialog"
      :price="dependency.price"
      @cancel="isUploadDialog = false"
      @price:update="updatePrice"
      @status:update="updatePriceStatus"
    />

    <TheDialog
      :title="$t('prices.formatting')"
      :value="dependency.price && isFormatDialog"
      @cancel="isFormatDialog = false"
    >
      <PriceFormatForm
        :price="dependency.price"
        @cancel="isFormatDialog = false"
        @price:update="updatePrice"
      />
    </TheDialog>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import transform from 'lodash/transform';
import priceEditMixin from './mixins/priceEditMixin';
import PriceRemover from './components/PriceRemover';
import MainInfo from './components/formItems/MainInfo';
import FileProcessing from './components/formItems/FileProcessing';
import ColumnMapping from './components/colMapping/ColumnMapping';
import PricePreviewTable from './components/PricePreviewTable';
import Availabilities from './components/formItems/Availabilities';
import PriceCreateSteps from './components/PriceCreateSteps';
import PriceCreateStatus from './components/PriceCreateStatus';
import getPETranslation from '@/lib/prices/services/getPriceErrorTranslation';
import { getValidationFor, getDefault, scrollToTop } from '@/lib/core';

export default {
  name: 'PriceCreatePage',

  mixins: [priceEditMixin],

  components: {
    PriceCreateStatus,
    PriceCreateSteps,
    PriceRemover,
    MainInfo,
    FileProcessing,
    ColumnMapping,
    PricePreviewTable,
    Availabilities
  },

  data () {
    return {
      mode: 'create',
      isLoading: false,
      step: 1,
      formData: getDefault('priceFile'),
      rules: getValidationFor(null, 'price', this),
      errors: null
    };
  },

  computed: {
    isNextDisabled () {
      return this.isPriceError || (this.step === 2 && !this.price.data_preview);
    },

    errorStatus () {
      return this.price.error_code &&
        this.$te(`prices.statuses.${this.price.error_code}`)
        ? this.price.error_code
        : this.price.status;
    }
  },

  methods: {
    get,
    isEmpty,
    getPETranslation,

    clearMapValidation () {
      this.$refs.mapping.clearValidate();
    },

    cancel () {
      this.$router.push({
        name: this.getRouteName('profile.business.prices')
      });
    },

    setAviliToActive () {
      this.unwatchIsLiveProp = this.$watch(
        () => this.formData.availabilities,
        (newVal) => {
          if (newVal) {
            this.formData.availabilities.forEach((item) => {
              item.is_active = true;
            });

            this.unwatchIsLiveProp();
          }
        },
        { deep: true }
      );
    },

    next () {
      this.$refs.form.validate((isValid) => {
        if (!isValid) {
          this.showError();
          return false;
        }

        if (this.step === 1) {
          this.$refs.form.validate((isValid) => {
            if (!isValid) {
              this.showError();
              return false;
            } else {
              this.step = 2;
              this.setAviliToActive();
            }
          });
        } else if (this.step === 2) {
          this.step += 1;
        } else if (this.step === 3) {
          this.submit();
        } else if (this.step === 4) {
          this.$router.push({
            name: this.getRouteName('profile.business.prices.edit')
          });
        } else {
          this.step += 1;
        }
      });
    },

    back () {
      if (this.step <= 1) {
        return this.$router.push({
          name: this.getRouteName('profile.business.prices.upload')
        });
      }

      this.step -= 1;
    },

    setDefaultData () {
      this.formData.name = this.price.filename || '';
    },

    checkStatus () {
      if (this.isPriceError) {
        const { error_code, error_message } = this.price;

        let message = this.$te(`errors.${error_code}.message`);

        if (message) {
          message = this.$t(`errors.${error_code}.message`, {
            message: error_message
          });
        } else {
          message = error_code ? this.$t(`prices.statuses.${error_code}`) : '';
        }

        this.$notify.error({
          title: this.$t('errors.default.title'),
          message
        });

        this.showFileReUploadConfirm();
      }
    },

    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid || this.isPriceError) {
          this.showError();
          return false;
        }

        this.isLoading = true;

        const { id, business, file_type } = this.price;
        const price_file = this.getClearData(this.formData);

        const pl = {
          push: true,
          id,
          formData: {
            business,
            file_type,
            price_file: {
              ...price_file,
              business,
              upload: this.price.url
            }
          }
        };

        try {
          const priceUpload = await this.$store.dispatch(
            'priceUploads/patchPrice',
            pl
          );

          if (
            priceUpload.error_code === 'confirm_mapping' &&
            !this.isConfirmMapping
          ) {
            if (!priceUpload.price_file.trademark) {
              priceUpload.price_file.trademark = null;
            }

            this.confirmMapping(priceUpload);

            this.price = priceUpload;
            this.$set(this, 'formData', cloneDeep(priceUpload.price_file));
            this.isLoading = false;

            return priceUpload.price_file;
          }

          const cbp =
            this.$store.getters['businessProfiles/currentBusinessProfile'];

          if (!cbp.has_prices) {
            await this.$store.dispatch('users/updateCurrentUser');
          }
          this.step += 1;
        } catch (e) {
          console.error(e);
          this.errors = e;
        } finally {
          this.isLoading = false;
        }
      });
    },

    getClearData (formData) {
      return transform(cloneDeep(formData), (acc, value, key) => {
        if (key === 'column_mapping') {
          acc[key] = value.map((col) => {
            const _col = { ...col };

            if (_col.type === 'quantity' && _col.quantity_warehouse) {
              _col.quantity_warehouse = _col.quantity_warehouse.url;
            } else if (
              _col.type === 'selling_price' && !Array.isArray(col.price_def.tags)
            ) {
              col.price_def.tags = [col.price_def.tags];
            } else if (
              _col.type === 'purchase_price' && !isEmpty(_col.price_actions)
            ) {
              _col.price_actions = _col.price_actions.map((it) => {
                if (it.price_def.tags && !Array.isArray(it.price_def.tags)) {
                  it.price_def.tags = [it.price_def.tags];
                }

                return it;
              });
            }

            _col.sheet = formData.data_sheet;

            return _col;
          });

          return acc;
        }

        if (value) {
          acc[key] = value;
        }
      }, {});
    },

    showError () {
      const a = document.getElementsByClassName('mapping');

      if (a[0]) {
        a[0].scroll(0, 0);
      }

      scrollToTop('is-error');
    }
  }
};
</script>

<style scoped lang="scss">
.price-create-page {
  overflow: hidden;

  .price-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .mapping {
    overflow-x: auto;

    ::v-deep .table-container {
      width: fit-content;
      padding: 0 10px 20px;
    }

    .price-preview-table {
      width: 100%;
    }
  }
}
</style>
