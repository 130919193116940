import { phoneNumberObject, emailObject, requiredString } from './common';

const requiredByChange = { ...requiredString, trigger: 'change' };

export default {
  buyer_phone_number: phoneNumberObject,
  buyer_email: emailObject,
  delivery_method: [requiredByChange],
  recipient_phone_number: phoneNumberObject,
  recipient_first_name: [requiredString],
  recipient_last_name: [requiredString],
  payment_method: [requiredByChange],
  delivery_address: [
    requiredByChange,
    { required: true, validator: 'deliveryAddress', trigger: 'change' }
  ],
  'delivery_address.region': [requiredByChange],
  'delivery_address.city': [{
    ...requiredByChange,
    validator: 'deliveryAddressCity'
  }],
  'delivery_address.service_name': [requiredString],
  'delivery_address.text': [requiredString],
  'delivery_address.service_office_number': [requiredString]
};
