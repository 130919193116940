var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "physical-person" },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.lastName"),
            prop: "last_name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-420p",
            attrs: { value: _vm.value.last_name },
            on: {
              input: function ($event) {
                return _vm.emit("last_name", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.firstName"),
            prop: "first_name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-420p",
            attrs: { value: _vm.value.first_name },
            on: {
              input: function ($event) {
                return _vm.emit("first_name", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.middleName"),
            prop: "middle_name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-420p",
            attrs: { value: _vm.value.middle_name },
            on: {
              input: function ($event) {
                return _vm.emit("middle_name", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }