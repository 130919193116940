var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cars-brands-models-item" },
    [
      _c(
        "el-row",
        { staticClass: "mb-1", attrs: { justify: "space-between" } },
        [
          _c(
            "div",
            [
              _c(
                "nuxt-link",
                {
                  staticClass: "label",
                  attrs: { to: _vm.getLink({ carBrand: _vm.value }) },
                },
                [_vm._v("\n        " + _vm._s(_vm.value.name) + "\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-link",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isOpen,
                      expression: "!isOpen",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("buttons.unCollapseAll")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v("`\n    "),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { class: ["models", { open: _vm.isOpen }] },
        _vm._l(_vm.value.models, function (carModel) {
          return _c(
            "li",
            { key: carModel.id, staticClass: "model" },
            [
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: _vm.getLink({
                      carBrand: _vm.value,
                      carModel: carModel,
                    }),
                  },
                },
                [_vm._v("\n        " + _vm._s(carModel.name) + "\n      ")]
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }