<template>
  <FormBlock
    class="file-processing-block"
    completeness-every
    :completeness-fields="['data_start_row', 'column_mapping']"
    entity-name="price"
    :fields="fields"
    label-position="left"
    label-width="320px"
    :submit="submit"
    :unlocked="unlocked"
    :value="value"
    v-on="{ ...$listeners, validate: scrollToStart }"
    @refresh="$emit('page:update', $event.data_sheet)"
  >
    <template #default="{ formData, readonly, isLoading }">
      <template v-if="['error', 'invalid'].includes(dependency.price.status)">
        <div class="mb-2">
          <span class="color-red">
            {{ $t('prices.status') }}:
            {{ getPriceErrorTranslation(dependency.price.error_code) }}
          </span>
        </div>
        <div>
          <p class="color-red">{{ dependency.price.error_message }}</p>
        </div>
      </template>

      <template v-else-if="isEmpty(dependency.price.data_preview)">
        <div class="pg-2 text-center">{{ $t('priceFile.waitPreview') }}</div>
        <TheLoader />
      </template>

      <template v-else>
        <FileProcessing
          :dependency="dependency"
          :readonly="readonly"
          :value="formData"
          :data-preview-count="dependency.price.data_preview_count"
          v-bind.sync="formData"
          @page:update="formData.column_mapping = []; $emit('page:update', $event)"
        />

        <el-row
          align="middle"
          class="mb-2"
          justify="space-between"
        >
          <span class="color-label">
            {{ $t('priceFile.firstTenRow') }}
          </span>

          <el-button
            :disabled="readonly"
            size="mini"
            @click="$emit('error:format')"
          >
            {{ $t('buttons.wrongTableData') }}
          </el-button>
        </el-row>

        <div class="mapping">
          <div class="table-container">
            <el-form-item
              ref="mapping"
              label-width="0"
              prop="column_mapping"
            >
              <ColumnMapping
                v-if="!isLoading"
                v-model="formData.column_mapping"
                class="mb-2"
                :dependency="dependency"
                :readonly="readonly"
                @input="clearMapValidation"
              />

              <div v-else class="pg-2">
                {{ $t('priceMapping.isMappingProcessing') }}
              </div>
            </el-form-item>

            <PricePreviewTable
              :data-preview="dependency.price.data_preview"
              :data-start-row="formData.data_start_row"
              :header-row="formData.header_row"
              :mapping="formData.column_mapping"
            />
          </div>
        </div>
      </template>
    </template>
  </FormBlock>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import FileProcessing from '../formItems/FileProcessing';
import ColumnMapping from '../colMapping/ColumnMapping';
import PricePreviewTable from '../PricePreviewTable';
import FormBlock from '@/lib/core/components/FormBlock';
import getPriceErrorTranslation from '@/lib/prices/services/getPriceErrorTranslation';

const fields = [
  'header_row',
  'data_start_row',
  'trademark',
  'data_sheet',
  'column_mapping'
];

export default {
  name: 'MappingBlock',

  components: {
    FormBlock,
    FileProcessing,
    ColumnMapping,
    PricePreviewTable
  },

  props: {
    submit: Function,
    dependency: Object,
    value: { type: Object, required: true }
  },

  data: () => ({
    fields
  }),

  computed: {
    unlocked () {
      return this.dependency.price.error_code === 'confirm_mapping';
    }
  },

  methods: {
    isEmpty,
    getPriceErrorTranslation,

    clearMapValidation () {
      this.$refs.mapping.clearValidate();
    },

    scrollToStart (isValid) {
      if (!isValid) {
        const a = document.getElementsByClassName('mapping');

        if (a[0]) {
          a[0].scroll(0, 0);
        }
      }

      this.$emit('validate', isValid);
    }
  }
};
</script>

<style scoped lang="scss">
.file-processing-block {
  .mapping {
    overflow-x: auto;

    ::v-deep .table-container {
      width: fit-content;
      padding: 0 10px 20px;
    }

    .price-preview-table {
      width: 100%;
    }
  }
}
</style>
