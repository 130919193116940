import { gql } from 'graphql-tag';
import { GARAGE_CAR_MAIN_FRAGMENT } from './carsGql';

export const VIN_REQUEST_MASTER_FRAGMENT = `
fragment VIN_REQUEST_MASTER_FRAGMENT on VinRequestMaster {
carComment
  color
  created
  cylinders
  driveTypeText
  engineCapacity
  engineCode
  gearSteps
  gearTypeText
  id
  insuranceExpiryDate
  licensePlate
  name
  phoneNumber
  phoneNumberChannels
  powerPs
  productionYear
  valves
  vin
  car {
    id
  }
  
  cClimateControl
  cHasConditioner
  cHasCruiseControl
  cHasGasInstallation
  cHasGlassTinting
  cHasHeadlightWasher
  cHasLaneAssist
  cHasLightSensor
  cHasMirrorsDimming
  cHasMirrorsHeating
  cHasMultimediaSteering
  cHasParkAssist
  cHasParktronic
  cHasPowerSteering
  cHasRainSensor
  cHasRoofRails
  cHasSeatMemory
  cHasStartButton
  cHasSteeringHeating
  cHasSummerTires
  cHasTowbar
  cHasWinterTires
  cInteriorMaterial
  cSeatsHeating
  cSunroof
  cWindowLifters
  mHasAcoustics
  mHasAndroidAuto
  mHasBluetooth
  mHasCarPlay
  mHasCd
  mHasDvd
  mHasGps
  mHasMp3
  mHasSubwoofer
  mHasTapeRecorder
  sAirbag
  sCentralLock
  sHasAbd
  sHasAbs
  sHasAirSuspension
  sHasArmoredBody
  sHasAsr
  sHasBsm
  sHasEsp
  sHasGearLock
  sHasNv
  sHeadlight
  sImmobilizer
  sSignalling
  brand {
    id
    name
  }
  model {
    id
    name
  }
  modification {
    id
    name
  }
  engineType {
    id
    name
  }
  fuelType {
    id
    name
  }
  generation {
    id
    name
  }
  gearType {
    id
    name
  }
  driveType {
    id
    name
  }
  bodyType {
    id
    name
  }
  brakeSystemType {
    id
    name
  }
  brakeType {
    id
    name
  }
  items {
    comment
    id
    rawItem
  }
}
`;

const VIN_REQUEST_FRAGMENT = `
fragment VIN_REQUEST_FRAGMENT on VinRequest {
  created
  id
  publicId
  responsesCount
  items {
    comment
    id
    rawItem
  }
}
`;

const VIN_REQUEST_RESPONSE_FRAGMENT = `
fragment VIN_REQUEST_RESPONSE_FRAGMENT on VinRequest {
  ...VIN_REQUEST_FRAGMENT
  items {
    comment
    id
    rawItem
    selectionResponses {
      article
      trademark
      seller {
        name
        id
      }
      price
      pointOfSale {
        id
        name
      }
      id
      deliveryTime
      created
      commentPublic
      isArticleHidden
      isFormerUse
      name
      offerId
      ware {
        imageUrl
        id
      }
    }
  }
}
${VIN_REQUEST_FRAGMENT}
`;

export const GET_VIN_REQUEST = gql`
  query GetVinRequest($vinRequestId: ID!) {
    vinRequest(id: $vinRequestId) {
      ...VIN_REQUEST_FRAGMENT
      car {
        ...GARAGE_CAR_MAIN_FRAGMENT
      }
    }
  }
  ${VIN_REQUEST_FRAGMENT}
  ${GARAGE_CAR_MAIN_FRAGMENT}
`;

export const GET_VIN_REQUESTS = gql`
  query GetVinRequests($business: ID, $person: ID, $car: ID) {
    vinRequests(business: $business, person: $person, car: $car) {
      ...VIN_REQUEST_FRAGMENT
      car {
        ...GARAGE_CAR_MAIN_FRAGMENT
      }
    }
  }
  ${VIN_REQUEST_FRAGMENT}
  ${GARAGE_CAR_MAIN_FRAGMENT}
`;

export const GET_VIN_REQUEST_RESPONSE = gql`
  query GetVinRequestResponse($vinRequestId: ID!) {
    vinRequest(id: $vinRequestId) {
      ...VIN_REQUEST_RESPONSE_FRAGMENT
      car {
        ...GARAGE_CAR_MAIN_FRAGMENT
      }
    }
  }
  ${VIN_REQUEST_RESPONSE_FRAGMENT}
  ${GARAGE_CAR_MAIN_FRAGMENT}
`;

export const GET_VIN_REQUEST_RESPONSE_BY_HASH = gql`
  query GET_VIN_REQUEST_RESPONSE_BY_HASH($hashId: String!) {
    vinRequestByHashId(hashId: $hashId) {
      ...VIN_REQUEST_RESPONSE_FRAGMENT
      hashId
      car {
        ...GARAGE_CAR_MAIN_FRAGMENT
      }
    }
  }
  ${VIN_REQUEST_RESPONSE_FRAGMENT}
  ${GARAGE_CAR_MAIN_FRAGMENT}
`;

export const CREATE_VIN_REQUEST_MASTER = gql`
  mutation CreateVinRequestMaster($input: CreateVinRequestMasterInput!) {
    createVinRequestMaster(input: $input) {
      vinRequestMaster {
        person {
          id
          lastName
          middleName
          profileName
          firstName
        }
        ...VIN_REQUEST_MASTER_FRAGMENT
      }
    }
  }
  ${VIN_REQUEST_MASTER_FRAGMENT}
`;

export const PROCESS_VIN_REQUEST_MASTER = gql`
  mutation ProcessVinRequestMaster($processVinRequestMasterId: ID!) {
    processVinRequestMaster(id: $processVinRequestMasterId) {
      vinRequest {
        hashId
        ...VIN_REQUEST_FRAGMENT
        car {
          ...GARAGE_CAR_MAIN_FRAGMENT
        }
      }
    }
  }
  ${GARAGE_CAR_MAIN_FRAGMENT}
  ${VIN_REQUEST_FRAGMENT}
`;
