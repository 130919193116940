export const actions = {
  postOrder (_, { formData }) {
    return this.$axios.$post('/orders/', formData);
  },

  async getOrders ({ rootGetters, dispatch }, filter = {}) {
    const sessionId = await dispatch('getSessionId', null, { root: true });
    const userId = rootGetters['users/userId'];
    const businessId = rootGetters['businessProfiles/businessId'];

    const params = {};

    if (!filter.hash_id) {
      if (businessId) {
        params.buyer_business = businessId;
      } else if (userId) {
        params.buyer_user = userId;
      } else if (sessionId) {
        params.session_key = sessionId;
      }
    }

    return await this.$axios
      .$get('/orders/', { params: { ...params, ...filter } });
  },

  getOrder (_, { id }) {
    return this.$axios.$get(`/orders/${id}/`);
  },

  revertOrderStatus (_, { id }) {
    return this.$axios.$patch(`/orders/${id}/`, { revert_status: true });
  }
};
