<template>
  <div :class="['avatar-uploader', { circle }]">
    <el-upload
      action="https://mp-api.dev.mriyar.ua/"
      :auto-upload="false"
      :disabled="readonly"
      drag
      name="avatar"
      :on-change="setAvatar"
      :show-file-list="false"
    >
      <div class="upload-text color-default">
        {{ $t('buttons.uploadPhotoText') }}
      </div>
      <el-image v-if="logoUrl" class="avatar" fit="contain" :src="logoUrl" />
      <i v-else class="el-icon-plus avatar-uploader-icon" />
    </el-upload>

    <el-button
      v-if="avatar && !hideRemoveBtn && !readonly"
      circle
      size="mini"
      class="remove-btn"
      icon="el-icon-delete"
      @click="$emit('input', null)"
    />
  </div>
</template>

<script>
import ElImage from 'element-ui/lib/image';
import ElUpload from 'element-ui/lib/upload';
const formats = ['image/jpeg', 'image/png', 'image/gif'];

export default {
  name: 'AvatarUploader',

  components: {
    ElImage,
    ElUpload
  },

  props: {
    circle: Boolean,
    readonly: Boolean,
    hideRemoveBtn: Boolean,
    value: { type: null, required: true }
  },

  computed: {
    logoUrl () {
      if (typeof this.avatar === 'string') {
        return this.avatar;
      }

      return this.avatar ? URL.createObjectURL(this.avatar) : null;
    },

    avatar: {
      get () {
        return this.value;
      },
      set (formData) {
        this.$emit('input', formData);
      }
    }
  },

  methods: {
    setAvatar (file) {
      if (!formats.includes(file.raw.type)) {
        this.$notify.error({
          message: this.$t('validation.avatar.format', { format: formats.toString() })
        });
      } else {
        this.avatar = file.raw;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader {
  position: relative;

  .el-upload {
    border: 1px dashed $grey-300;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  &.circle ::v-deep .el-upload-dragger {
    border-radius: 50%;
  }

  .avatar-uploader .el-upload:hover {
    border-color: $blue-500;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .remove-btn {
    position: absolute;
    right: 2px;
    bottom: 6px;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .upload-text {
    position: absolute;
    bottom: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
