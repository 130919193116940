var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        staticClass: "registration-block",
        attrs: {
          "entity-name": "account",
          fields: ["profile_name", "avatar", "language_code"],
          "label-width": _vm.labelWidth,
          submit: _vm.submit,
          value: _vm.value,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var formData = ref.formData
              return [
                _c("el-row", { attrs: { justify: "space-between" } }, [
                  _c(
                    "div",
                    { staticClass: "mr-1" },
                    [
                      _c("div", { staticClass: "description mb-1" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("account.nameForSocialDescription"))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("labels.nameForSocial"),
                            prop: "profile_name",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: formData.profile_name,
                              callback: function ($$v) {
                                _vm.$set(formData, "profile_name", $$v)
                              },
                              expression: "formData.profile_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("labels.login"),
                            prop: "email",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", value: _vm.entity.email },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c("SocialRegistration", {
                            attrs: { id: _vm.entity.id },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "relative",
                          attrs: { "label-width": "0", prop: "avatar" },
                        },
                        [
                          _c("AvatarUploader", {
                            attrs: { circle: "" },
                            model: {
                              value: formData.avatar,
                              callback: function ($$v) {
                                _vm.$set(formData, "avatar", $$v)
                              },
                              expression: "formData.avatar",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }