var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aside-business-menu" },
    [
      _c(
        "el-menu",
        { attrs: { collapse: _vm.isCollapsed, router: "" } },
        [
          _c("header", { staticClass: "nav-header" }, [
            _c("img", {
              staticClass: "nav-header-icon",
              attrs: { src: require("@/assets/icons/hamburger.svg") },
              on: {
                click: function ($event) {
                  _vm.isCollapsed = !_vm.isCollapsed
                },
              },
            }),
            _vm._v(" "),
            !_vm.isCollapsed
              ? _c("span", [_vm._v(_vm._s(_vm.businessProfile.name))])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "el-menu-item",
            {
              attrs: {
                index: "profile.business.prices",
                route: { name: _vm.getRouteName("profile.business.prices") },
              },
            },
            [
              _c("i", { staticClass: "el-icon-document" }),
              _vm._v(" "),
              !_vm.isCollapsed
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("routes.profile_business_prices")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-menu-item",
            {
              attrs: {
                index: "profile.business.sale-points",
                route: {
                  name: _vm.getRouteName("profile.business.sale-points"),
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-location-outline" }),
              _vm._v(" "),
              !_vm.isCollapsed
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("routes.profile_business_sale_points")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-menu-item",
            {
              attrs: {
                index: "profile.business.edit",
                route: {
                  name: _vm.getRouteName("profile.business.edit"),
                  params: { id: _vm.businessProfile.id },
                },
              },
            },
            [
              _c("img", {
                staticClass: "el-icon- icon-img",
                attrs: { src: require("@/assets/icons/currency-usd.svg") },
              }),
              _vm._v(" "),
              !_vm.isCollapsed
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("routes.profile_business_edit"))),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-submenu",
            { attrs: { index: "profile.finance" } },
            [
              _c("template", { slot: "title" }, [
                _c("img", {
                  staticClass: "el-icon- icon-img",
                  attrs: { src: require("@/assets/icons/earth.svg") },
                }),
                _vm._v(" "),
                !_vm.isCollapsed
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("routes.finance")))])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "el-menu-item",
                {
                  attrs: {
                    index: "profile.finance.platforms",
                    route: {
                      name: _vm.getRouteName("profile.business.platforms"),
                      params: { id: _vm.businessProfile.id },
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      " - " +
                        _vm._s(_vm.$t("routes.profile_business_platforms"))
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-menu-item",
                {
                  attrs: {
                    index: "profile.finance.balance",
                    route: {
                      name: _vm.getRouteName("profile.business.balance"),
                      params: { id: _vm.businessProfile.id },
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      " - " + _vm._s(_vm.$t("routes.profile_business_balance"))
                    ),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }