<template>
  <el-form-item
    class="activities"
    :label="$t('labels.point_sale_activities')"
    prop="activities"
  >
    <el-checkbox-group
      v-model="formData"
      class="cell"
      :disabled="readonly"
    >
      <el-checkbox
        v-for="option in options"
        :key="option.id"
        :label="option.label"
      >
        {{ $t(`sale_activities.${option.label}`) }}
      </el-checkbox>
    </el-checkbox-group>
  </el-form-item>
</template>

<script>
export default {
  name: 'Activities',

  props: {
    readonly: Boolean,
    value: { type: Array, required: true }
  },

  data: ({ $store }) => ({
    options: $store.getters['dependencies/saleActivities']
  }),

  computed: {
    formData: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
