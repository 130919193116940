const getFilesFormData = (pointUrl, photos) => {
  return photos.map((file) => {
    const formData = new FormData();
    formData.append('point', pointUrl);
    formData.append('photo', file.raw);
    formData.append('is_primary', file.is_primary || false);
    return formData;
  });
};

export const actions = {
  async postPointPhotos (_, { pointUrl, pointId, photos }) {
    const formDataList = getFilesFormData(pointUrl, photos);
    const promises = formDataList.map(formData => this.$axios
      .$post('/seller-photos/', formData));

    return await Promise.all(promises);
  },

  async patchPointPhotos (_, { photos }) {
    const getRes = (data) => {
      return this.$axios.$patch(`/seller-photos/${data.id}`, data);
    };

    const promises = photos.map(formData => getRes(formData));

    return await Promise.all(promises);
  },

  async deletePointPhotos (_, { photos: ids }) {
    const promises = ids.map((id) => {
      return this.$axios.$delete(`/seller-photos/${id}`);
    });

    return await Promise.all(promises);
  }
};
