export const actions = {
  async getPriceSchemes (_, params) {
    const { data } = await this.$axios({
      method: 'get',
      url: '/pricing-schemes/',
      params
    });

    return data;
  },

  postPricingScheme (_, { formData }) {
    return this.$axios.$post('/pricing-schemes/', formData);
  }
};
