<template>
  <div class="garage-widget-panel">
    <div class="flex">
      <div class="left">
        <div class="mr-1">
          <el-button
            class="garage-list-btn"
            type="danger"
            :disabled="!fullList.length"
            @click="toggleView('list')"
          >
            <el-row align="middle">
              <img
                width="24"
                height="24"
                class="mr-05"
                src="@/assets/icons/car-1-white.svg"
                alt="Garage"
              >
              {{ $t('catalog.garage') }}
              ({{ fullList.length }})
            </el-row>
          </el-button>
        </div>

        <div class="widget-title">
          <div v-if="car">
            <div class="title">{{ getShortCarTitle(car) }}</div>
            <el-row v-if="car.vin" align="bottom" class="text-small color-label">
              VIN: {{ car.vin }}
              <BufferCopy :value="car.vin" class="ml-05" />
            </el-row>
          </div>

          <template v-else>
            <span class="title">{{ title }}</span>

            <el-link v-if="selectorOptions.isBackspace" class="ml-1">
              <img
                class="block"
                src="@/assets/icons/backspace_outlined-blue.svg"
                alt="backspace"
                @click="goBackSelect"
              >
            </el-link>
          </template>
        </div>

        <el-row v-if="!hideOptions" align="middle" class="widget-options ml-1">
          <el-link
            v-if="car && car._isGarage"
            type="primary"
            class="mr-1"
            @click="editCar(car)"
          >
            <i class="el-icon-edit-outline" />
          </el-link>

          <el-link
            :underline="false"
            type="primary"
            class="mr-1 bold"
            @click="toVinRequest(car)"
          >
            VIN
          </el-link>

          <el-link
            v-if="!$route.name.includes('home') || selectorOptions.isBackspace"
            type="primary"
            @click="toggleView('selector', 'home')"
          >
            <i class="el-icon-close" />
          </el-link>
        </el-row>
      </div>

      <div class="right">
        <img
          v-if="!hideOptions"
          v-lazy-load
          width="45"
          height="45"
          class="c-pointer"
          src="@/assets/icons/plus-success.svg"
          alt="Create Car"
          @click="toggleView('selector')"
        >
      </div>
    </div>
  </div>
</template>

<script>
import BufferCopy from '@/lib/core/components/BufferCopy';

export default {
  name: 'GarageWidgetPanel',

  components: {
    BufferCopy
  },

  props: {
    hideOptions: Boolean,
    isLoading: Boolean,
    fullList: { type: Array, required: true },
    car: null,
    toggleView: { type: Function, required: true },
    toVinRequest: { type: Function, required: true },
    editCar: { type: Function, required: true },
    goBackSelect: { type: Function, required: true },
    getShortCarTitle: { type: Function, required: true },
    selectorOptions: Object
  },

  computed: {
    title () {
      return this.selectorOptions?.title ||
        this.$t('garage.chooseYourCarForStart');
    }
  }
};
</script>

<style scoped lang="scss">
.garage-widget-panel {
  .left {
    min-height: 45px;
    display: flex;
    flex: 1;
    padding: 4px;
    margin-right: 2px;
    background-color: $grey-300;
  }

  .widget-title {
    display: flex;
    align-items: center;
    flex: 1;
    color: $black;
    font-weight: bold;
    font-size: 1rem;
  }

  .right {
    height: fit-content;

    img {
      display: block;
      height: 45px;
      width: 45px;
    }
  }

  .garage-list-btn {
    min-height: 30px;
    min-width: 97px;
    padding: 2px 8px;
  }

  .widget-options * {
    font-size: 1rem;
  }
}
</style>
