import { i18n } from '@/plugins/i18n';

const getYearRange = (item) => {
  const _start = item.production_start || item.productionStart;
  const _end = item.production_end || item.productionEnd;
  const start = _start && _start.split('-')[0];
  const end = _end
    ? _end.split('-')[0]
    : i18n.t('catalog.nowTime');

  return `${start}-${end}`;
};

export const getShortCarTitleOld = (car) => {
  const { production_year, brand_detail, model_detail } = car;

  return `${production_year} ${brand_detail?.name} ${model_detail?.name}`;
};

export const getShortCarTitle = (car, { useBr } = {}) => {
  const { productionYear, brand, model, name } = car;

  const br = useBr ? '<br>' : '';

  if (productionYear && brand && model) {
    return `${productionYear} ${brand?.name} ${br} ${model?.name}`;
  } else if (brand && model) {
    const [year] = car.productionStart.split('-');
    const brand = car.brand?.name || car.model?.brand?.name;
    const model = car.model?.name;
    return `${year} ${brand} ${br} ${model}`;
  }

  return name;
};

export const getCarTitle = (car, options = {}) => {
  const { productionYear, name, powerPs, _yearRange } = car;

  if (options.useName && name && name !== 'UNKNOWN') {
    return name;
  }

  if (options?.small) {
    return `
      ${(productionYear || '')}
      ${car._brand || car.brand?.name || ''}
      ${car._model || car.model?.name || ''}
    `;
  }

  const _engineCode = car.engineCode ? ` / ${car.engineCode}` : '';

  const _name =
    (name && name !== 'UNKNOWN' ? name : '') ||
    (car.brand ? `${car.brand?.name} ${car.model?.name}` : '').trim() ||
    '';

  return `
  ${productionYear || _yearRange || getYearRange(car)}
  ${_name}
  (${powerPs || ''}
  ${i18n.t('catalog.ls')})
  ${_engineCode}
  `.trim();
};

const getDataFor = (scope, id) => ({
  id,
  name: id ? i18n.t(`${scope}.${id}`) : ''
});

export const parseRestModificationToQl = (car) => {
  return {
    ...car,
    brand: car.brand || car.model?.brand,
    bodyType: getDataFor('carBodyTypes', car.body_type),
    driveType: getDataFor('carDriveTypes', car.drive_type),
    engineCode: car.engine_code,
    engineType: getDataFor('carEngineTypes', car.engine_type),
    fuelType: getDataFor('carFuelTypes', car.fuel_type),
    gearType: getDataFor('carGearTypes', car.gear_type),
    powerPs: car.power_ps,
    productionEnd: car.production_end,
    productionStart: car.production_start,
    productionYear: car.production_year || car.production_start.split('-')[0],
    valves: car.valves,

    _yearRange: getYearRange(car),
    _modificationId: car.id,
    _origin: car
  };
};
