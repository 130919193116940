var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-create-page" },
    [
      _vm.isLoader
        ? _c("TheLoader")
        : _c(
            "div",
            [
              _c(
                "h1",
                { staticClass: "custom-headline custom-headline--level2-bold" },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("basket.createOrder")) + "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("basket.seller")) + ":\n      "
                ),
                _c("span", { staticClass: "bold" }, [
                  _vm._v(_vm._s(_vm.salePoint.name)),
                ]),
                _vm._v(
                  "\n      (" +
                    _vm._s(_vm.salePoint.address.city_detail.name) +
                    ")\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-position": "top",
                    disabled: _vm.disabled,
                    model: _vm.formData,
                    rules: _vm.rules,
                    size: "medium",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "BuyerData",
                        _vm._b(
                          {
                            attrs: {
                              "is-form": _vm.isBuyerForm,
                              "is-show-last-first": !!_vm.user,
                              value: _vm.formData,
                              "set-field": _vm.setField,
                            },
                            on: {
                              "is-form:change": function ($event) {
                                _vm.isBuyerForm = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "options",
                                fn: function (ref) {
                                  var _isMobile = ref.isMobile
                                  return [
                                    _c(
                                      "div",
                                      {
                                        class: [
                                          "buyer-form__options",
                                          {
                                            center: _isMobile,
                                            "ml-2": !_isMobile,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          {
                                            staticClass: "mb-1",
                                            attrs: {
                                              justify: "center",
                                              align: "bottom",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("basket.toBePaid")
                                                ) +
                                                ":\n\n                "
                                            ),
                                            _c("ThePrice", {
                                              attrs: { value: _vm.totalAmount },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "mb-1",
                                            attrs: {
                                              "native-type": "submit",
                                              type: "primary",
                                              loading: _vm.isLoading,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "buttons.selectDeliveryAndPayment"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "text-center text-small",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "basket.agreeWithPublicOffer",
                                                { href: _vm.publicOfferHref }
                                              )
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          "BuyerData",
                          _vm.formData,
                          false,
                          true
                        )
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isBuyerForm
                    ? [
                        _c(
                          "OrderDelivery",
                          _vm._b(
                            {
                              staticClass: "form-block mb-1",
                              attrs: {
                                dependency: Object.assign({}, _vm.dependency, {
                                  rules: _vm.rules,
                                }),
                                value: _vm.formData,
                                "set-field": _vm.setField,
                              },
                            },
                            "OrderDelivery",
                            _vm.formData,
                            false,
                            true
                          )
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-block mb-1" },
                          [
                            _c(
                              "OrderPayment",
                              _vm._b(
                                {
                                  attrs: {
                                    dependency: _vm.dependency,
                                    value: _vm.formData,
                                  },
                                },
                                "OrderPayment",
                                _vm.formData,
                                false,
                                true
                              )
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-block pg-1-0 mb-1" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { "label-width": "0", prop: "comment" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    placeholder: _vm.$t("basket.commentForm"),
                                    rows: 4,
                                  },
                                  model: {
                                    value: _vm.formData.comment,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "comment", $$v)
                                    },
                                    expression: "formData.comment",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "buy-block" }, [
                          _c(
                            "div",
                            { staticClass: "mb-1 flex fit-content" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("basket.toBePaid")) +
                                  ":\n            "
                              ),
                              _c("ThePrice", {
                                attrs: { value: _vm.totalAmount },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mb-1" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "w-200p",
                                  attrs: {
                                    "native-type": "submit",
                                    type: "primary",
                                    loading: _vm.isLoading,
                                    disabled: !_vm.canSubmit,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("buttons.toOrder")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.isBuyerForm
                            ? _c("div", {
                                staticClass: "public-offer",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("basket.agreeWithPublicOffer", {
                                      href: _vm.publicOfferHref,
                                    })
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("div", { staticClass: "mb-1 block-title" }, [
                        _vm._v(_vm._s(_vm.$t("basket.orders"))),
                      ]),
                      _vm._v(" "),
                      _c("OrderItemsTable" + (_vm.isMobile ? "M" : ""), {
                        key: _vm.formData.id,
                        tag: "component",
                        class: { "form-block pg-1-0": !_vm.isMobile },
                        attrs: {
                          "currency-name": _vm.MARKETPLACE_CURRENCY,
                          value: _vm.formData.items,
                        },
                        on: {
                          "item:update": _vm.updateItem,
                          "item:remove": _vm.removeItem,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
      _vm._v(" "),
      _vm.isLiqPay
        ? _c("LiqPayConfirmForm", {
            attrs: { order: _vm.isLiqPay },
            on: { done: _vm.closeLiqPay, cancel: _vm.closeLiqPay },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }