<template>
  <div class="prices-table">
    <div v-if="localError && load" class="mb-2">
      <el-button size="mini" type="primary" @click="reload">
        {{ $t('buttons.searchAgain') }}
      </el-button>
    </div>

    <el-table
      v-loading="loading"
      class="table"
      :data="sortByFileAndDate"
      :header-cell-style="{ textAlign: 'center' }"
      :row-class-name="getRowId"
      @row-click="$emit('rowClick', $event)"
      @click.native.middle="$emit('row-click-middle', $event)"
    >
      <el-table-column
        align="center"
        :label="$t('prices.status')"
        prop="price_file.is_active"
        width="80px"
      >
        <template #default="{ row }">
          <PriceActivator
            v-if="row.price_file"
            :price-file="row.price_file"
            :readonly="readonly"
            @update="row.price_file.is_active = $event"
          />
          <div v-else>-</div>
        </template>
      </el-table-column>

      <el-table-column
        align="left"
        :label="$t('prices.name')"
        min-width="250px"
        prop="name"
      >
        <template #default="{ row }">
          <el-row align="middle">
            <div
              class="mr-1 c-pointer click-op pg-05"
              @click.stop="$emit('row-click-middle', row)"
            >
              <i class="el-icon-info" />
            </div>

            <TheTooltip :content="row.price_file && row.price_file.name">
              <span class="text-ellipsis">
                {{ row.price_file ? row.price_file.name : '-' }}
              </span>
            </TheTooltip>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :label="$t('prices.currency')"
        prop="currency" width="150px"
      >
        <template #default="{ row }">
          {{ row.price_file && currencies[getIdFromUrl(row.price_file.currency)] || '-' }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :label="$t('prices.state')"
        prop="state"
        width="120px"
      >
        <template #default="{ row }">
          <span v-show="row.status">
            {{ $t(`prices.statuses.${row.status}`) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :label="$t('prices.lastUpdate')"
        prop="created"
        width="240px"
      >
        <template #default="{ row }">
          {{ dateFilter(get(row, 'price_file.updated')) }}
        </template>
      </el-table-column>

      <el-table-column align="center" width="200px">
        <template #default="{ row }">
          <el-button
            size="mini"
            plain
            type="warning"
            @click.stop="$emit('reupload:file', row)"
          >
            {{ $t('buttons.uploadOtherFile') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import PriceActivator from './PriceActivator';
import asyncTableMixin from '@/lib/core/mixins/asyncTableMixin';
import { parsers, filters } from '@/lib/core';

export default {
  name: 'PricesTable',

  mixins: [asyncTableMixin],

  components: {
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column'),
    /* eslint-enable max-len */
    PriceActivator
  },

  computed: {
    currencies () {
      return (this.$store.getters['dependencies/currencies'] || [])
        .reduce((acc, item) => {
          acc[item.id] = item.name;
          return acc;
        }, {});
    },

    sortByFileAndDate () {
      if (!this.value) {
        return null;
      } else {
        const byFile = sortBy(this.value, price => !!price.price_file);
        return sortBy(byFile, price => price.created);
      }
    }
  },

  methods: {
    get,
    getIdFromUrl: parsers.getIdFromUrl,
    dateFilter: filters.dateFilter,

    getRowId ({ rowIndex }) {
      return `row-index__${rowIndex}`;
    }
  }
};
</script>

<style scoped lang="scss">
.prices-table {
  overflow-x: auto;

  .el-table {
    width: unset;
    max-width: unset;
    min-width: 100%;
    overflow: visible;
  }
}
</style>
