import $notify from 'element-ui/lib/notification';
import dayjs from 'dayjs';
import { i18n } from '@/plugins/i18n';
import { cookie, session, LAST_URL_INT_REG, env, parsers } from '@/lib/core';

const progressCache = {};
const cookiesOptions = {
  expires: dayjs().add(1, 'year').toDate()
};

const getCityIds = (rootGetters, vm) => {
  const profileId = rootGetters['businessUsers/profileId'];
  const userId = rootGetters['users/userId'];
  const getF = vm?.$cookies?.get || cookie.get;

  const nullCityId = Number.parseInt(getF('null-cityId'));
  const cityId = Number.parseInt(getF(`${profileId || userId}-cityId`));

  return { nullCityId, cityId };
};

const isLocalDev = env.NPM_LIFECYCLE_EVENT === 'dev'; // only for develop mode

export const state = () => ({
  userCode: null, // u83 - string
  langCode: env.MARKETPLACE_DEFAULT_LANG,
  timezone: 'Europe/Kiev',
  isCollapsedMenu: isLocalDev,
  progress: null,
  city: null,
  isShowCityConfirm: null,
  isCitySelector: false,
  version: null,
  isAppLoaded: false,
  isDomReadyState: false,
  isDev: env.NODE_ENV !== 'production',
  isLocalDev,
  breadcrumbs: [],
  tempData: [],
  viewPort: null
});

export const mutations = {
  setUserCode: (state, userCode) => { state.userCode = userCode; },
  setLangCode: (state, langCode) => { state.langCode = langCode; },
  setTimezone: (state, timezone) => { state.timezone = timezone; },
  setMenuMode: (state, mode) => { state.isCollapsedMenu = mode; },
  setProgress: (state, progress) => { state.progress = progress; },
  setCity: (state, city) => { state.city = city; },
  setShowCityConfirm: (state, isShow) => { state.isShowCityConfirm = isShow; },
  setVersion: (state, version) => { state.version = version; },
  setAppLoaded: (state, isAppLoaded) => { state.isAppLoaded = isAppLoaded; },
  setDomReadyState: (state, isReady) => { state.isDomReadyState = isReady; },
  setIsDev: (state, isDev) => { state.isDev = isDev; },
  setBreadcrumbs: (state, breadcrumbs) => { state.breadcrumbs = breadcrumbs; },
  setTempData: (state, data) => { state.tempData = data; },
  setViewPort: (state, viewPort) => { state.viewPort = viewPort; },
  setIsCitySelector: (state, val) => { state.isCitySelector = val; }
};

export const actions = {
  nuxtServerInit (_, { req }) {},

  setLangCode ({ state, commit, dispatch }, { lang, push }) {
    i18n.locale = lang;
    this.app.i18n.locale = lang;
    commit('setLangCode', lang);
    dispatch('setCookie', { name: 'language_code', value: lang });

    if (push) {
      const route = parsers.getRouteByLang(this.$router.currentRoute, lang);

      return this.$router.push(route);
    }
  },

  setTimezone ({ commit }, timeZone) {
    commit('setTimezone', timeZone);
  },

  setMenuMode ({ commit }, mode) {
    commit('setMenuMode', mode);
  },

  setUserCode ({ commit, dispatch }, code) {
    commit('setUserCode', code);
    session.set('userCode', code);
    return dispatch('setCookie', { name: 'userCode', value: code });
  },

  setProgress ({ commit }, progress) {
    if (progress && progress.percent >= 100) {
      commit('setProgress', null);

      if (progressCache[progress.code]) {
        return progress;
      }

      progressCache[progress.code] = true;

      $notify.success({
        title: i18n.t('message.success'),
        message: i18n.t(`message.${progress.code}UploadSuccess`),
        onClose: () => delete progressCache[progress.code]
      });
    } else {
      commit('setProgress', progress ? { ...progress } : null);
    }

    return progress;
  },

  async fetchCity ({ dispatch, rootGetters }, options = {}) {
    const { nullCityId, cityId } = getCityIds(rootGetters, this);

    try {
      const currentCity = await dispatch(
        'searchLocation/getCurrentCity',
        null,
        { root: true }
      );

      const countryId = currentCity.country.match(LAST_URL_INT_REG)[1];

      if (Number(countryId) !== env.MARKETPLACE_COUNTRY_ID) {
        const city = await dispatch(
          'searchLocation/getCityById',
          {
            id: env.CAPITAL_ID
          },
          { root: true }
        );

        dispatch('setCity', city);
        !options.hideConfirm && dispatch('setShowCityConfirm', true);
      } else if (cityId) {
        const city = await dispatch(
          'searchLocation/getCityById',
          { id: cityId },
          { root: true }
        );

        dispatch('setCity', city);
      } else if (nullCityId) {
        const city = await dispatch(
          'searchLocation/getCityById',
          { id: nullCityId },
          { root: true }
        );

        dispatch('setCity', city);
      } else {
        dispatch('setCity', currentCity);

        if (!options.hideConfirm) {
          dispatch('setShowCityConfirm', true);
        }
      }
    } catch (e) {
      console.error(e);
      return dispatch('setShowCityConfirm', true);
    }
  },

  setCity ({ commit }, city) {
    commit('setCity', city);
  },

  setCityIdToCookie ({ dispatch, rootGetters }, id) {
    const profileId = rootGetters['businessUsers/profileId'];
    const userId = rootGetters['users/userId'];
    return dispatch('setCookie', {
      name: `${profileId || userId}-cityId`,
      value: id
    });
  },

  setShowCityConfirm ({ commit }, isShow) {
    commit('setShowCityConfirm', isShow);
  },

  setIsDev ({ commit }, isDev) {
    commit('setIsDev', isDev);
  },

  setAppLoaded ({ commit }, isAppLoaded) {
    commit('setAppLoaded', isAppLoaded);
  },

  setDomReadyState ({ commit }, isReady) {
    commit('setDomReadyState', isReady);
  },

  setCookie (_, { name, value }) {
    if (value) {
      return new Promise((resolve) => {
        this.$cookies.set(name, value, cookiesOptions);
        setTimeout(() => resolve(value), 0);
      });
    } else {
      return this.$cookies.remove(name);
    }
  },

  getCookie (_, key) {
    return this.$cookies.get(key);
  },

  setBreadcrumbs ({ commit }, breadcrumbs = []) {
    commit('setBreadcrumbs', breadcrumbs);
    return breadcrumbs;
  },

  setTempData ({ commit, getters }, data) {
    const _data = getters.tempData.concat(data);
    commit('setTempData', _data);
    return _data;
  },

  setViewPort ({ commit }, media) {
    commit('setViewPort', media);
    return media;
  },

  setIsCitySelector ({ commit }, val) {
    commit('setIsCitySelector', val);
    return val;
  },

  async checkServerVersion ({ commit, getters, dispatch }) {
    try {
      const baseURL = getters.isLocalDev
        ? 'http://localhost:3000'
        : env.BASE_CLIENT_PATH;

      const { data: version } = await this.$axios({
        method: 'get',
        baseURL,
        url: '/version.txt'
      });

      const lastVersion = await dispatch('getCookie', 'app_version');

      if (!version) {
        return { lastVersion, version };
      }

      commit('setVersion', version);
      dispatch('setCookie', { name: 'app_version', value: version });

      if (!lastVersion) {
        return { version };
      }

      const isChanged =
        lastVersion && version
          ? lastVersion.split(':')[0] !== version.split(':')[0]
          : false;

      return {
        isChanged,
        lastVersion,
        version
      };
    } catch (e) {
      console.error(e);
      return {};
    }
  },

  getCityId ({ getters, rootGetters }) {
    const { cityId, nullCityId } = getCityIds(rootGetters, this);
    return getters.city?.id || cityId || nullCityId || env.CAPITAL_ID;
  },

  getSessionId () {
    return this.$cookies.get(env.SESSION_ID_KEY);
  }
};

export const getters = {
  userCode: state => state.userCode,
  userCodeId: state => state.userCode
    ? Number.parseInt(state.userCode.slice(1))
    : null,
  langCode: state => state.langCode,
  timezone: state => state.timezone,
  isCollapsedMenu: state => state.isCollapsedMenu,
  progress: state => state.progress,
  city: state => state.city,
  cityId: (state, getters, rootState, rootGetters) => {
    const stateId = state.city && state.city.id;
    const { cityId, nullCityId } = getCityIds(rootGetters);
    return stateId || cityId || nullCityId || env.CAPITAL_ID;
  },
  isShowCityConfirm: state => state.isShowCityConfirm,
  version: state => state.version,
  isAppLoaded: state => state.isAppLoaded,
  isDomReadyState: state => state.isDomReadyState,
  // getSessionId: () => () => cookie.get(env.SESSION_ID_KEY),
  isDev: state => state.isDev,
  isLocalDev: state => state.isLocalDev,
  buyer: (state, getters, rootState, rootGetters) => {
    if (getters.userCode) {
      return getters.userCode.startsWith('u')
        ? rootGetters['users/user']?.profile.buyer
        : rootGetters['businessProfiles/currentBusinessProfile']?.buyer;
    }
  },
  buyerId: (state, getters) => getters.buyer ? getters.buyer.id : null,
  sellerId: (state, getters, rootStore, rootGetters) => {
    const profile = rootGetters['businessProfiles/currentBusinessProfile'];
    return profile?.seller?.id || null;
  },
  breadcrumbs: state => state.breadcrumbs,
  tempData: state => state.tempData,
  viewPort: state => state.viewPort,
  isCitySelector: state => state.isCitySelector
};
