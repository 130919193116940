var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header" },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "nav",
          { staticClass: "row-1" },
          [
            _c("ul", { staticClass: "links pg-0-1" }, [
              _c(
                "li",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "color-label",
                      attrs: {
                        to: {
                          name: _vm.getRouteName("docs"),
                          params: { id: "payment" },
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("core.marketplacePayment")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "color-label",
                      attrs: {
                        to: {
                          name: _vm.getRouteName("docs"),
                          params: { id: "delivery" },
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("core.marketplaceDelivery")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "color-label",
                      attrs: {
                        to: {
                          name: _vm.getRouteName("docs"),
                          params: { id: "warranty" },
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("core.marketplaceWarranty")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "color-label",
                      attrs: {
                        to: {
                          name: _vm.getRouteName("docs"),
                          params: { id: "about-us" },
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("routes.about_us")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("li", [_c("LangLinks", { staticClass: "mr-05" })], 1),
              _vm._v(" "),
              !_vm.$store.getters["businessProfiles/businessProfiles"].length
                ? _c(
                    "li",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-create-business",
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              _vm.$router.push({
                                name: _vm.getRouteName(
                                  "profile.business.create"
                                ),
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("buttons.createBusiness")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("UserNav"),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row-2" }, [
          _c("div", { staticClass: "left-block" }, [_c("TheLogo")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "center-block" }, [_c("CleverSearch")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "right-block" }, [_c("BasketIcon")], 1),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-3" },
          [_c("CleverSearch", { attrs: { row: "" } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c("BusinessMenuShort"),
      _vm._v(" "),
      _c("Breadcrumb"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }