<template>
  <div class="search-entity-page">
    <TheLoader v-if="isLoading || isCatalogLoading" />

    <div v-else-if="isEmpty(options)">
      <EmptySearchResult :name="$route.query.q" />
    </div>

    <div v-else>
      <div v-for="key in Object.keys(options)" :key="key" class="mb-2">
        <div class="title bold mb-2">
          {{ $t(`cleverSearch.${key}`) }}:
        </div>

        <ul class="list">
          <li
            v-for="item in getList(key)"
            :key="item.related_object_id"
            class="item"
          >
            <CatalogItem
              v-if="key === 'product_line'"
              title-center
              :value="item"
            />

            <el-button v-else @click="onSelect(key, item)">
              {{ item._label }}
            </el-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import CatalogItem from './components/CatalogItem';
import env from '@/lib/core/services/envConstants';
import parsers from '@/lib/core/services/parsers';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';
import getSeoBreadcrumbs from '@/lib/catalog/services/getSeoBreadcrumbs';
import { getAllLines } from '@/lib/catalog/services/parseCatalog';

export default {
  name: 'SearchEntityPage',

  components: {
    CatalogItem,
    /* eslint-disable max-len */
    EmptySearchResult: () => import(/* webpackChunkName: "ElTable" */ '@/lib/core/components/EmptySearchResult')
    /* eslint-enable max-len */
  },

  asyncData ({ app, store, query }) {
    const q = query.q;

    const rowBreadcrumbs = [{
      name: 'catalog-search',
      label: q,
      path: `/search/catalog?q=${q}`
    }];

    const pl = {
      vm: { $store: store, $router: app.router },
      rowBreadcrumbs
    };
    const { breadcrumbs, seoBreadcrumbs } = getSeoBreadcrumbs(pl);

    store.dispatch('setBreadcrumbs', breadcrumbs);

    return { seoBreadcrumbs };
  },

  data () {
    return {
      isLoading: true,
      isCatalogLoading: true,
      options: null,
      allCatalog: {}
    };
  },

  computed: {
    filteredLines () {
      if (isEmpty(this.options?.product_line) || isEmpty(this.allCatalog)) {
        return [];
      }

      return this.options?.product_line?.map((it) => {
        return {
          ...it,
          ...(this.allCatalog[it.related_object_slug] || {})
        };
      }) || [];
    }
  },

  watch: {
    '$route.query.q': {
      handler: 'loadData'
    }
  },

  async mounted () {
    await Promise.all([
      this.loadCatalog(),
      this.loadData(this.$route.query.q)
    ]);
  },

  methods: {
    isEmpty,

    getList (key) {
      return key === 'product_line'
        ? this.filteredLines
        : this.options[key];
    },

    async loadCatalog () {
      this.isCatalogLoading = true;

      try {
        const catalog = await this.$store.dispatch(
          'cache/get',
          { action: 'productLines/fetchCatalog' }
        );

        this.allCatalog = getAllLines(catalog, { key: 'slug' });
      } catch (e) {
        console.error(e);
      } finally {
        this.isCatalogLoading = false;
      }
    },

    async loadData (q = this.$route.query.q) {
      if (!q) {
        this.isLoading = false;
        return null;
      }

      this.isLoading = true;

      try {
        const data = await this.$store.dispatch(
          'search/cleverSearch',
          { q }
        );

        this.setOptions(data, q);
      } catch (e) {
        console.error(e);
        this.options = null;
      } finally {
        this.isLoading = false;
      }
    },

    setOptions (data) {
      if (data.search?.results) {
        this.options = Object
          .entries(data.search?.results)
          .reduce((acc, [key, value]) => {
            acc[key] = value.map(it => ({
              ...it,
              _label: it.related_object_name || it.name
            }));

            return acc;
          }, {});
      } else {
        this.options = null;
      }
    },

    getStrong (str, q) {
      return str.replace(q, `<span class="bold">${q}</span>`);
    },

    onSelect (key, value) {
      let route = null;

      if (key === 'search') {
        route = {
          name: this.getRouteName('search'),
          query: { q: this.query, ...value }
        };
      } else if (key === 'product_line') {
        const pl = {
          lineId: value.related_object_slug,
          trademarkId: get(this.options, 'search.tm[0].related_object_slug'),
          carBrand: get(this.options, 'search.car_brand[0].related_object_slug'),
          carModel: get(this.options, 'search.car_model[0].related_object_slug')
        };

        route = getSeoLinkBy(pl, this, pl);
      } else if (key === 'car_brand') {
        const pl = { carBrand: value.related_object_slug };
        route = getSeoLinkBy(pl, this, pl);
      } else if (key === 'car_model') {
        const pl = {
          carBrand: value.brand?.slug,
          carModel: value.related_object_slug
        };
        route = getSeoLinkBy(pl, this, pl);
      } else if (key === 'tm') {
        const pl = { trademarkId: value.related_object_slug };
        route = getSeoLinkBy(pl, this, pl);
      } else if (key === 'ware') {
        route = parsers.getSearchRouteBy(this, value);
      }

      if (route) {
        this.$store.dispatch(
          'analytics/pushEvent',
          { event: 'clever-search', query: this.query }
        );

        return this.$router.push(route).catch(e => e);
      }

      console.info('ERROR', value, key);
    }
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>

<style scoped lang="scss">
.search-entity-page {
  padding-top: 20px;

  .title {
    font-size: 1.4rem;
  }

  .list {
    display: flex;
    flex-wrap: wrap;

    .item {
      padding: 10px;
    }
  }
}
</style>
