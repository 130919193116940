<template>
  <div class="s-request-create-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('sRequests.createRequestByVin') }}
    </h1>

    <!--eslint-disable vue/no-v-html-->
    <div class="default-style mb-2" v-html="$t('sRequests.createRequestByVinDesc')" />

    <TheLoader v-if="isLoading" />

    <SRequestForm
      v-else
      class="container"
      :car="car"
      :submit="submit"
      @cancel="toRequests"
    />
  </div>
</template>

<script>
import SRequestForm from './components/SRequestForm';

export default {
  name: 'SRequestCreatePage',

  components: {
    SRequestForm
  },

  data () {
    return {
      isLoading: true,
      car: null
    };
  },

  mounted () {
    this.loadCar();
  },

  methods: {
    async loadCar () {
      this.isLoading = true;

      try {
        this.car = await this.$store.dispatch('cars/getCarQL', {
          id: Number(this.$route.params.carId)
        });

        return this.car;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    async submit (formData) {
      this.isVLoading = true;

      try {
        const requestMaster = await this.$store.dispatch(
          'selectionRequests/postVinRequestMasterQL',
          { formData }
        );

        const vinRequest = await this.$store.dispatch(
          'selectionRequests/processVinRequestMasterQL',
          { id: requestMaster.id }
        );

        return this.$router.push({
          name: this.getRouteName('garage.requests.created'),
          params: {
            requestId: vinRequest.id,
            propVinRequest: vinRequest
          }
        });
      } catch (e) {
        console.error(e);
        this.isVLoading = false;
      }
    },

    getClear (formData) {
      const unUsed = ['id', 'isActive', 'created'];

      const car = Object.entries(this.car).reduce((acc, [key, val]) => {
        if (key.startsWith('_')) {
          return acc;
        }

        if (key === 'comment') {
          acc.carComment = val;
        } else if (!unUsed.includes(key) && val !== null) {
          acc[key] = val?.id || val;
        }

        return acc;
      }, {});

      const { id, ..._car } = car;

      return {
        ..._car,
        car: id || Number(this.$route.params.carId),
        itemsAdd: formData.itemsAdd.filter(({ rawItem }) => !!rawItem)
      };
    },

    toRequests () {
      this.$router.push({ name: this.getRouteName('garage.requests') });
    }
  },

  validate ({ params }) {
    return /^\d+$/.test(params.carId);
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/default-style.scss";

.container {
  max-width: 980px;
  margin: 0 auto;
}
</style>
