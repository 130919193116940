<template>
  <div class="garage-widget">
    <div
      v-show="!$store.getters.isAppLoaded"
      :class="['placeholder', { selected: defaultData.car_modification }]"
    />

    <component
      :is="`GarageWidgetPanel${isMobile ? 'M' : ''}`"
      v-show="$store.getters.isAppLoaded"
      :is-loading="isWPLoading"
      :hide-options="hideOptions"
      :full-list="fullList"
      :car="car"
      :toggle-view="isMobile ? toggleViewM : toggleView"
      :to-vin-request="toVinRequest"
      :edit-car="editCar"
      :go-back-select="goBackSelect"
      :get-short-car-title="getShortCarTitle"
      :selector-options="selectorOptions"
    />

    <component
      :is="`CarSelector${isMobile ? 'M' : ''}`"
      v-if="isShowSelector"
      ref="carSelector"
      class="mt-1"
      :default-data="defaultData"
      :use-default="!$route.query.cm"
      :get-parsed="parseRestModificationToQl"
      @select="saveModificationId"
      @title:update="selectorOptions = $event"
    />

    <CarsList
      v-if="isShowList"
      :class="['mt-1', { 'is-mobile': isMobile }]"
      is-mobile
      :show-options="!hideOptions"
      :hide-all-options="hideOptions"
      :value="fullList"
      @remove="remove"
      @set-item="select"
      @to-vin-request="toVinRequest"
      @car-edit="editCar"
    >
      <el-row
        class="garage-btns"
        justify="space-between"
        @click.stop
      >
        <el-button
          type="success"
          @click="isMobile ? toggleViewM('selector') : toggleView('selector')"
        >
          {{ $t('buttons.addCar') }}
        </el-button>
        <el-button @click="toGarage">
          {{ $t('buttons.goToGarage') }}
        </el-button>
      </el-row>
    </CarsList>

    <TheDialog
      v-model="isShowVinConfirm"
      width="600px"
      :title="$t('garage.requestToSelectionByVin')"
    >
      <VinConfirmForm
        v-if="isShowVinConfirm"
        :car="isShowVinConfirm"
        :submit="saveVinAndGoToInquire"
      />
    </TheDialog>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import GarageWidgetPanel from './GarageWidgetPanel';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';
import {
  parseRestModificationToQl,
  getShortCarTitle
} from '@/lib/garage/services/carParsers';
import { eventBus, scrollToTop, parsers } from '@/lib/core';

const parseGarageCars = list => list.map(it => ({
  ...cloneDeep(it),
  _isGarage: true,
  _modificationId: Number(it.modification?.id)
}));

const clearData = ({ id, name }) => ({ id, name });

export default {
  name: 'GarageWidget',

  components: {
    GarageWidgetPanel,
    /* eslint-disable */
    GarageWidgetPanelM: () => import(/* webpackChunkName: "GarageWidgetPanelM" */ './GarageWidgetPanelM'),
    CarSelector: () => import(/* webpackChunkName: "CarSelector" */ './CarSelector'),
    CarSelectorM: () => import(/* webpackChunkName: "CarSelectorM" */ './CarSelectorM'),
    CarsList: () => import(/* webpackChunkName: "CarsList" */ './CarsList'),
    VinConfirmForm: () => import(/* webpackChunkName: "VinConfirmForm" */ '../VinConfirmForm')
    /* eslint-enable */
  },

  props: {
    hideOptions: Boolean,
    useSeoLink: Boolean,
    saveToListOnly: Boolean,
    defaultData: { type: Object, default: () => ({}) },
    propCar: { type: Object }
  },

  data ({ $store }) {
    const mId = $store.getters['carModifications/modificationId'] ||
      Number(this.$route.query.cm);

    return {
      selectorOptions: {},
      isWPLoading: !!(!this.car && mId),
      isShowList: false,
      isShowVinConfirm: false,
      isShowSelector: !mId,
      modificationId: mId,

      modifications: $store.getters['carModifications/savedCars'],
      garage: []
    };
  },

  computed: {
    isAuth () {
      return this.$store.getters['auth/isAuthenticated'];
    },

    isMobile () {
      return this.maxmq('xs');
    },

    car () {
      if (this.propCar?.modification) {
        return this.propCar;
      }

      return this.modificationId
        ? this.fullList
          .find(({ _modificationId }) => _modificationId === this.modificationId)
        : null;
    },

    fullList () {
      return this.isAuth
        ? this.garage
        : this.modifications;
    }
  },

  watch: {
    isAuth () {
      if (this.$route.name.includes('home')) {
        setTimeout(this.load, 2000);
      }
    }
  },

  mounted () {
    this.setDefaultData();
  },

  methods: {
    parseRestModificationToQl,
    getShortCarTitle,

    async setDefaultData () {
      const { car_modification } = this.defaultData;

      await this.load();

      if (car_modification) {
        const existed = this.fullList
          .find(({ _modificationId }) => car_modification.id === _modificationId);

        const m = existed || car_modification;

        this.modificationId = Number(m._modificationId || m.id);

        if (!existed) {
          const car = parseRestModificationToQl(car_modification);
          this.modifications.push(car);

          const isInG = this.garage.includes((it) => {
            return Number(car._modificationId) === Number(it._modificationId);
          });

          if (!isInG) {
            this.garage.push(car);
          }
        }
      }

      this.$nextTick(() => {
        this.isShowSelector = !this.car;
        this.isWPLoading = false;
      });
    },

    async load () {
      try {
        const modifications = this.$store.getters['carModifications/savedCars'];
        this.modifications = cloneDeep(modifications);
        await this.loadGarage();
      } catch (e) {
        console.error(e);
      }
    },

    async loadGarage () {
      const garage = this.isAuth
        ? await this.$store.dispatch('cars/fetchCarsQL')
        : [];

      this.garage = parseGarageCars(garage);

      return this.garage;
    },

    async select (data, pl = {}, options = {}) {
      const { _modificationId } = data;

      // TODO clear id => modificationId
      const _pl = { ...pl, id: _modificationId };

      if (this.useSeoLink && _pl.id) {
        const carData = await this.getCarSlugs(_pl.id);

        if (carData) {
          _pl.carData = carData;
        }
      } else if (!_pl.id) {
        _pl.carData = null;
      }

      if (this.saveToListOnly) {
        data.productionYear = Number(pl.formData?.production_year || data.productionYear);
      }

      await this.$store.dispatch('carModifications/setCarData', _pl);

      if (
        this.useSeoLink &&
        !options.default &&
        (Object.prototype.hasOwnProperty.call(_pl, 'carData') || !_pl.id)
      ) {
        this.goToCar(_pl.carData);
      }

      this.modificationId = _pl.id;
      this.isShowList = false;
      this.isShowSelector = !_pl.id;

      this.emitReloadFilter();
      scrollToTop();

      this.$emit('select', { data, formData: pl.formData });
    },

    async remove ({ id: _id, _isGarage }, { onlyList } = {}) {
      const id = Number(_id);

      if (_isGarage) {
        await this.$store.dispatch('cars/deleteCarQl', { id });
        await this.loadGarage();
      }

      this.modifications = this.modifications
        .filter(it => it._modificationId !== id && it.id !== id);

      const pl = { cars: this.modifications };

      if (!onlyList && id === this.modificationId) {
        const nextItem = this.modifications
          .find(it => it._modificationId !== id && it.id !== id);

        return this.select({ id: nextItem?.id || null }, pl);
      } else if (!onlyList && !this.modifications.length) {
        return this.clear(pl);
      } else {
        return this.$store.dispatch('carModifications/setCarData', pl);
      }
    },

    toggleView (name, page) {
      if (name === 'selector') {
        this.clear({ toPage: page });

        this.$nextTick(() => {
          this.$refs.carSelector && this.$refs.carSelector.clearFilter();
        });
      } else if (name === 'list') {
        this.isShowList = !this.isShowList;
        this.isShowSelector = !this.isShowList && !this.car;
      }
    },

    toggleViewM (name, page) {
      if (name === 'selector') {
        this.isShowSelector = true;
        const name = this.selectorOptions.activeMenuItem || 'brand';

        setTimeout(() => {
          this.$refs.carSelector.onToggleMenuM(name);
          this.clear({ toPage: page });
        }, 100);
      } else if (name === 'list') {
        this.isShowList = !this.isShowList;
        this.isShowSelector = !this.isShowList && !this.car;
      } else if (name === 'clear') {
        this.clear();
      }
    },

    async saveModificationId ({ item, formData }) {
      const exist = this.fullList
        .find(car => car._modificationId === item._modificationId);

      if (exist) {
        return this.select(exist);
      }

      if (this.isAuth) {
        const car = await this.saveItemToGarageQL({ item, formData });

        if (car) {
          return this.select(car);
        }
      }

      const car = this.getParsedCarForStorage({ item, formData });

      this.modifications = uniqBy([...this.modifications, car], '_modificationId');

      return this.select(car, { formData, cars: this.modifications });
    },

    async getCarSlugs (car_modification) {
      if (!car_modification) {
        return null;
      }

      try {
        const pl = { car_modification };
        const data = await this.$store.dispatch('seo/getSeoData', pl);

        const carData = {
          carBrand: data?.car_brand?.slug,
          carModel: data?.car_model?.slug,
          carGeneration: data?.car_generation?.slug,
          carModification: data?.car_modification?.id
        };

        return Object.values(carData).every(Boolean)
          ? carData
          : null;
      } catch (e) {
        console.error(e);
        return null;
      }
    },

    emitReloadFilter () {
      eventBus.$emit('filter:updated');
    },

    toVinRequest (car) {
      if (!this.isAuth || !car) {
        this.$router.push({
          name: this.getRouteName('vin-requests-master_create'),
          query: { cm: car?._modificationId },
          params: { propCar: car }
        });
      } else if (car && !car?.vin) {
        this.isShowVinConfirm = car;
      } else if (car) {
        return this.$router.push({
          name: this.getRouteName('garage.requests.create'),
          params: { carId: car.id }
        });
      } else if (!car) {
        // TODO write logic for select car on request create page?
        return this.$notify.info({ message: this.$t('garage.requireCarForVin') });
      }
    },

    async saveVinAndGoToInquire ({ car, vin }) {
      try {
        let _car = null;

        if (car._isGarage) {
          _car = await this.$store.dispatch(
            'cars/updateCarQL',
            { id: car.id, formData: { vin } }
          );

          await this.loadGarage();
        } else {
          _car = await this.saveItemToGarageQL({
            item: car,
            formData: {
              production_year: car.production_start.slice(0, 4),
              vin
            }
          });
        }

        return this.$router.push({
          name: this.getRouteName('garage.requests.create'),
          params: { carId: _car.id }
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.isShowVinConfirm = false;
      }
    },

    getParsedCar ({ item, formData }) {
      return {
        vin: item.vin || formData.vin,
        brand: item.brand.id,
        model: item.model.id,
        modification: item._modificationId,
        productionYear: formData.production_year,
        bodyType: item.bodyType.id,
        fuelType: item.fuelType.id,
        gearType: item.gearType.id,
        engineType: item.engineType.id,
        engineCode: item.engineCode,
        engineCapacity: Number(item.capacity || formData.capacity),
        cylinders: item.cylinders,
        valves: item.valves,
        powerPs: item.powerPs,
        name: `${formData.production_year} ${item.brand.name} ${item.model.name}`
      };
    },

    getParsedCarForStorage ({ item, formData }) {
      return {
        id: parsers.getDefaultId(),
        name: `${formData.production_year} ${item.brand.name} ${item.model.name}`,
        isActive: true,
        vin: item.vin || formData.vin || '',
        productionYear: formData.production_year,
        valves: item.valves,
        powerPs: item.powerPs,
        engineCapacity: Number(item.capacity || formData.capacity),
        brand: clearData(item.brand),
        model: clearData(item.model),
        modification: item._modificationId,
        engineType: item.engineType,
        engineCode: item.engineCode,
        gearType: clearData(item.gearType),
        fuelType: clearData(item.fuelType),
        bodyType: clearData(item.bodyType),
        _modificationId: item._modificationId
      };
    },

    async saveItemToGarageQL ({ item, formData }) {
      const _formData = this.getParsedCar({ item, formData });

      try {
        const car = await this.$store.dispatch(
          'cars/createCarQL',
          { formData: _formData }
        );

        await this.loadGarage();

        if (car.modification) {
          this.remove(
            { id: item._modificationId },
            { onlyList: true }
          );
        }

        return { ...car, _modificationId: item._modificationId };
      } catch (e) {
        console.error(e);
      }
    },

    goBackSelect () {
      this.$refs.carSelector?.goBack();
    },

    clear (pl) {
      this.select(
        { id: null },
        { cars: pl?.cars || this.modifications },
        pl || { default: true }
      );
    },

    editCar (car) {
      if (this.isAuth) {
        this.$router.push({
          name: this.getRouteName('garage.edit'),
          params: { id: car.id }
        });
      }
    },

    async toGarage () {
      const { resolved, location } = this.$router.resolve({
        name: this.getRouteName('garage')
      });

      if (!this.isAuth) {
        const isConfirm = await this.$confirm(
          this.$t('message.authIsRequired'),
          {
            type: 'warning',
            confirmButtonText: this.$t('buttons.goEnter')
          }
        );

        if (isConfirm) {
          return this.$router.push({
            name: this.getRouteName('auth'),
            query: { redirect: resolved.path }
          });
        }

        return null;
      }

      return this.$router.push(location);
    },

    goToCar (carData) {
      const _carData = carData || {
        carBrand: false,
        carModel: false,
        carGeneration: false,
        carModification: false
      };

      const url = getSeoLinkBy(_carData, this);

      if (url.path !== '#') {
        return this.$router.push(url).catch(e => e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.placeholder {
  min-height: 45px;
  background-color: $grey-300;
}

.garage-btns {
  padding: 10px;
  border: 1px solid $grey-300;
}

.cars-list  {
  max-width: 320px;

  &.is-mobile {
    max-width: 100%;
  }
}

.garage-widget-panel, .car-selector {
  display: block;

  &-m {
    display: none;
  }
}

@media all and (max-width: $--xs) {
  .garage-widget-panel, .car-selector {
    display: none;

    &-m {
      display: block;
    }
  }

  .placeholder {
    min-height: 92px;
    border-radius: 4px;

    &.selected {
      min-height: 60px;
    }
  }
}
</style>
