export const breakpointList = [
  { name: 'ss', value: 359 },
  { name: 'xss', value: 420 },
  { name: 'xs', value: 600 }, // mobile
  { name: 'sm', value: 768 },
  { name: 'md', value: 865 },
  { name: 'lg', value: 1200 },
  { name: 'xl', value: 1600 },
  { name: 'if', value: Infinity }
];

export const breakpoints = breakpointList.reduce((acc, { name, value }) => {
  acc[name] = value;
  return acc;
}, {});
