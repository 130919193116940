var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      ref: "select",
      staticClass: "search-location-select",
      attrs: {
        autocomplete: "no",
        clearable: "",
        filterable: "",
        "reserve-keyword": "",
        "value-key": "id",
        remote: "",
        "allow-create": _vm.allowCreate,
        disabled: _vm.disabled,
        loading: _vm.loading,
        placeholder: _vm.$t("labels." + _vm.field),
        "remote-method": _vm.search,
        value: _vm.value && _vm.value.name,
      },
      on: {
        change: _vm.emitValue,
        clear: function ($event) {
          _vm.options = []
        },
        blur: function ($event) {
          return _vm.setEntered()
        },
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item[_vm.labelName], value: item },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }