import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import env from '@/lib/core/services/envConstants';
import getWareLink from '@/lib/catalog/services/getWareLink';

const BCP = env.BASE_CLIENT_PATH;
const langPrefix = 'ru';

const getWebSiteType = () => {
  const address = env.MARKETPLACE === 'mriyar'
    ? [{
        '@type': 'PostalAddress',
        streetAddress: 'ул. Шимановского 2/1',
        addressLocality: 'г. Киев'
      }]
    : [];

  return {
    type: 'application/ld+json',
    json: [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: BCP,
        potentialAction:
          {
            '@type': 'SearchAction',
            target: `${BCP}/search/catalog?q={search_term_string}`,
            'query-input': 'required name=search_term_string'
          }
      },
      {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: BCP,
        logo: `${BCP}/img/logo.png`,
        name: env.APP_DESCRIPTION,
        email: 'info@mriyar.com',
        address,
        sameAs: ['https://www.facebook.com/Mriyar.ua']
      }
    ]
  };
};

const getItemList = (vm, list) => {
  return {
    type: 'application/ld+json',
    json: {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      itemListElement: list.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url: `${BCP}${vm.$router.resolve(getWareLink(vm, item)).href}`
      }))
    }
  };
};

const getLinksBy = ($route) => {
  const path = $route.fullPath.replace(`/${langPrefix}`, '');
  const _path = path.replace(/\/$/, '');

  const links = [
    { rel: 'alternate', hrefLang: 'x-default', href: `${BCP}${path}` },
    { rel: 'alternate', hrefLang: 'uk-ua', href: `${BCP}${path}` },
    { rel: 'alternate', hrefLang: 'ru-ua', href: `${BCP}/${langPrefix}${_path}` }
  ];

  if (!$route.path.includes('page-')) {
    links.push({ rel: 'canonical', href: `${BCP}${$route.path}` });
  }

  return links;
};

const getRobots = (vm, robots) => {
  return {
    name: 'robots',
    content: vm.$route?.meta?.robots || robots || env.ROBOTS_VALUES
  };
};

export default {
  head () {
    const head = {
      meta: [],
      script: [getWebSiteType(this)],
      link: getLinksBy(this.$route)
    };

    if (this.options?.searchCatalogTile) {
      head.script.push(getItemList(this, this.analogsRes?.results || []));
    }

    if (!isEmpty(this.seoBreadcrumbs)) {
      head.script.push({
        type: 'application/ld+json',
        json: this.seoBreadcrumbs
      });
    }

    if (!isEmpty(this.seoProductMeta)) {
      head.script.push({
        type: 'application/ld+json',
        json: this.seoProductMeta
      });
    }

    if (!isEmpty(this.meta)) {
      const { title, description, robots } = this.meta;

      head.title = title || this.pageTitle;

      if (!this.options?.hideSeoText) {
        head.meta.push(
          {
            hid: 'description',
            name: 'description',
            content: description
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: description
          }
        );
      }

      head.meta.push(getRobots(this, robots));
    } else {
      head.title = this.pageTitle;
      head.meta.push(getRobots(this));
    }

    set(head, 'htmlAttrs.lang', this.$store.getters.langCode);

    return head;
  }
};
