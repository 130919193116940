var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "generate-invoice-dialog" },
    [
      _c(
        "el-button",
        {
          attrs: { disabled: !_vm.contract, type: "primary" },
          on: {
            click: function ($event) {
              _vm.isModal = !_vm.isModal
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("balance.upBalance")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: { width: "400px", title: _vm.$t("balance.upBalance") },
          model: {
            value: _vm.isModal,
            callback: function ($$v) {
              _vm.isModal = $$v
            },
            expression: "isModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "generate-modal" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("balance.enterAmount")))]),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "mt-1",
                  model: {
                    value: _vm.amount,
                    callback: function ($$v) {
                      _vm.amount = $$v
                    },
                    expression: "amount",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.MARKETPLACE_CURRENCY) + " "),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mt-2",
                  attrs: { type: "primary" },
                  on: { click: _vm.generateInvoice },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("balance.generateInvoice")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }