<template>
  <div class="buyer-order-page">
    <TheLoader v-if="isLoading" />

    <div v-else class="content">
      <OrderInfo
        class="mb-2"
        :value="order"
        @sale-point:info="isSalePointInfo = $event"
      />

      <component
        :is="`BuyerOrderItemsViewTable${maxmq('md') ? 'M' : ''}`"
        class="mb-2"
        :currency-name="MARKETPLACE_CURRENCY"
        :readonly="readonly"
        :value="items"
        @item:update="updateItem"
      />

      <div
        v-if="order.no_call_required || order.comment"
        class="underline-block"
      >
        <div class="title">{{ $t('basket.commentForm') }}</div>

        <div class="content pg-0">
          <el-checkbox disabled :value="order.no_call_required" class="mb-2">
            <span class="text-small">{{ $t('basket.no_call_required') }}</span>
          </el-checkbox>

          <el-input :value="order.comment" disabled />
        </div>
      </div>

      <el-row v-if="!readonly" class="mb-2" justify="end">
        <el-button
          v-if="canCancelItems"
          class="mr-2"
          type="danger"
          @click="cancelOrderItems"
        >
          {{ $t('buttons.cancelAllOrder') }}
        </el-button>
      </el-row>
    </div>

    <TheDialog
      v-model="isSalePointInfo"
      :title="$t('orders.salePointInfo')"
    >
      <SalePointInfo v-if="isSalePointInfo" :id="isSalePointInfo" />
    </TheDialog>
  </div>
</template>

<script>
import getSeoBreadcrumbs from '../catalog/services/getSeoBreadcrumbs';
import OrderInfo from './components/OrderInfo';
import { useAsyncDebounce, env, filters, parsers } from '@/lib/core';

export default {
  name: 'BuyerOrderPage',

  components: {
    OrderInfo,
    /* eslint-disable max-len */
    SalePointInfo: () => import(/* webpackChunkName: "SalePointInfo" */ '@/lib/salePoints/components/SalePointInfo'),
    BuyerOrderItemsViewTable: () => import(/* webpackChunkName: "BuyerOrderItemsViewTable" */ './components/BuyerOrderItemsViewTable'),
    BuyerOrderItemsViewTableM: () => import(/* webpackChunkName: "BuyerOrderItemsViewTableM" */ './components/BuyerOrderItemsViewTableM')
    /* eslint-enable max-len */
  },

  data () {
    return {
      MARKETPLACE_CURRENCY: this.$t(`currencies.${env.MARKETPLACE_CURRENCY}`),
      isLoading: true,
      order: null,
      items: [],
      isSalePointInfo: null
    };
  },

  computed: {
    basket () {
      return this.$store.getters['baskets/basket'];
    },

    buyerId () {
      return this.$store.getters.buyerId;
    },

    canCancelItems () {
      return this.items.every(({ status }) => status === 'new');
    },

    readonly () {
      return !this.$store.getters['auth/isAuthenticated'] ||
        (this.order?.buyer_detail?.id || '-') !== (this.buyerId | '+');
    }
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      const { orderId } = this.$route.params;
      const { hashId } = this.$route.query;

      this.isLoading = true;

      try {
        this.order = await (
          hashId
            ? this.loadOrderByHash(hashId)
            : this.loadOrder(orderId)
        );

        if (
          !this.order ||
          (!hashId && (this.order.buyer_detail.id !== this.buyerId))
        ) {
          return this.$nuxt.error({ statusCode: 404 });
        }

        this.items = this.order.items.map(this.getParsed);
        this.emitBreadcrumbs(this.order);
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        return this.$nuxt.error({ statusCode: 404 });
      }
    },

    emitBreadcrumbs (order) {
      const rowBreadcrumbs = [{
        name: 'basket.order',
        label: `
        ${order.public_id}
        (${filters.getDateTime(order.created)} -
        ${order.point_of_sale_detail.name})
        `,
        path: `/basket/order/${order.id}`
      }];

      if (this.buyerId) {
        rowBreadcrumbs.unshift({
          name: 'basket.orders',
          label: this.$t('routes.basket_orders'),
          path: '/basket/orders'
        });
      }

      const pl = {
        vm: this,
        rowBreadcrumbs,
        options: { passHome: true }
      };

      const { breadcrumbs } = getSeoBreadcrumbs(pl);

      this.$store.dispatch('setBreadcrumbs', breadcrumbs);
    },

    async loadOrder (id) {
      try {
        return await this.$store.dispatch(
          'orders/getOrder',
          { id }
        );
      } catch (e) {
        console.error(e);
      }
    },

    async loadOrderByHash (hash_id) {
      try {
        const [order] = await this.$store.dispatch(
          'orders/getOrders',
          { hash_id }
        );

        return order;
      } catch (e) {
        console.error(e);
      }
    },

    getParsed (item) {
      return {
        ...item,
        _notFound: item.error_code === 'not_found',
        _articleHref: parsers.getSearchRouteBy(this, item),
        _canRevertStatus: item._canRevertStatus || false,
        _revertStatusTimer: item._revertStatusTimer ||
          useAsyncDebounce({ debounce: env.STATUS_REVERT_DEBOUNCE })
      };
    },

    async updateItem (_formData) {
      const { id, ...formData } = _formData;

      try {
        const item = await this.$store.dispatch(
          'orderItems/patchItem',
          { id, formData }
        );

        const parsedItem = this.getParsed(item);

        this.updateLocalItem(parsedItem, _formData);

        return parsedItem;
      } catch (e) {
        console.error(e);
      }
    },

    updateLocalItem (item, formData = {}) {
      if (formData.status) {
        this.addStartRevertStatusTimer(item, formData);
      }

      const index = this.items.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        this.$set(this.items, index, item);
      }
    },

    addStartRevertStatusTimer (item) {
      const { updateItem } = this;
      const apply = () => { item._canRevertStatus = false; };
      const cancel = () => updateItem({ id: item.id, revert_status: true });

      item._canRevertStatus = true;
      item._revertStatusTimer.start({ cancel, apply });
    },

    async cancelOrderItems () {
      try {
        const editableItems = this.items.filter(({ status }) => status === 'new');

        const res = editableItems.map(({ id }) => {
          return this.updateItem({ id, status: 'buyer_canceled' });
        });

        await Promise.all(res);

        this.notifyAboutOrderCancel();
      } catch (e) {
        console.error(e);
      }
    },

    notifyAboutOrderCancel () {
      const order = filters.dateFilter(this.order.created);

      this.$notify.success({
        title: this.$t('message.success'),
        message: this.$t('message.orderCanceled', { order })
      });
    }
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  },

  validate ({ params, query }) {
    return params.orderId ? /^\d+$/.test(params.orderId) : !!query.hashId;
  }
};
</script>

<style scoped>
.content {
  max-width: 900px;
  padding-top: 20px;
}
</style>
