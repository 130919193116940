<template>
  <div class="address-select">
    <el-form-item
      :label="$t('basket.deliveryCity')"
      prop="delivery_address.city"
    >
      <div v-if="cityReadonly">
        {{ value.city_detail ? value.city_detail.name : '' }}
      </div>
      <el-input v-else :value="value.city_detail.name" />
    </el-form-item>

    <el-form-item
      :label="$t('basket.delivery_address')"
      prop="delivery_address"
    >
      <TheTooltip v-if="addressReadonly" :content="value._label">
        <el-input disabled :value="value._label" />
      </TheTooltip>

      <el-input
        v-else-if="!parsedAddresses.length"
        :value="value._label"
        @input="setField('delivery_address._label', $event)"
      />

      <el-select
        v-else
        class="w-100"
        filterable
        allow-create
        value-key="_label"
        :value="value"
        @change="setAddress"
      >
        <el-option
          v-for="item in parsedAddresses"
          :key="item.id"
          :label="item._label"
          :value="item"
        />
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import getAddressTitle from '@/lib/orders/services/getConcatedDeliveryAddress';
import orderFormMixin from '@/lib/orders/mixins/orderFormMixin';
import getConcatedAddress from '@/lib/salePoints/services/getConcatedAddress';

export default {
  name: 'AddressSelect',

  mixins: [orderFormMixin],

  props: {
    addressReadonly: Boolean,
    cityReadonly: Boolean,
    readonly: Boolean,
    setField: { type: Function, required: true },
    value: Object
  },

  data () {
    return {
      addresses: []
    };
  },

  computed: {
    parsedAddresses () {
      const addresses = this.addresses
        .map((address) => {
          address._label = address._label || getAddressTitle(address);
          return address;
        });

      return uniqBy(addresses, '_label');
    }
  },

  methods: {
    getConcatedAddress,

    setDefaultData () {
      if (this.value.city) {
        const address = cloneDeep(this.value);
        address._label = address._label || getAddressTitle(address);
        this.addresses.push(address);
      }
    },

    setAddress (address) {
      if (address) {
        this.emit('delivery_address', address);
      }
    }
  }
};
</script>
