import { parsers } from '@/lib/core';

export default async function ({ app, store, redirect, route }) {
  if (
    !route.name.startsWith('profile.business.create') &&
    !store.getters['businessProfiles/businessProfiles'].length
  ) {
    await store.dispatch('users/updateCurrentUser');

    if (!store.getters['businessProfiles/businessProfiles'].length) {
      store.dispatch('auth/logoutAndGo', { code: 'businessAccess' });

      const { name } = parsers.getRouteByLang({ name: 'auth' }, app.i18n.locale);

      redirect({ name });
    }
  }
}
