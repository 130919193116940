const getParsedItems = (items, order) => items.map((ware) => {
  return {
    item_name: `${ware.display_title} ${ware.display_trademark} ${ware.display_article}`,
    item_id: ware.ware_id, // id товару, який передаєте в фіді Merchant
    price: ware.price, // Ціна актуальна на день перегляду. Разделитель десятичных - точка
    affiliation: order.seller_detail?.name, // Продавець,
    item_brand: ware.display_trademark, // Бренд товару
    item_category: '', // "Система очищення скла і фар", // Перший рівень категорії товару
    item_category2: '', // "Щітка склоочисника", // Другий рівень категорії товару
    quantity: ware.quantity || ware.quantity // Кількість товару, яку замовив користувач
  };
});

const getParsedEcommerceData = ({ order, currency, coupon, itemListName }) => {
  return {
    transaction_id: order.public_id,
    affiliation: order.seller_detail?.name,
    value: order.total_amount,
    tax: 0,
    shipping: 0,
    currency,
    coupon: coupon || (order.is_one_click_order ? 'order 1click' : 'order cart'),
    items: getParsedItems(order.items, order),
    item_list_name: itemListName || ''
  };
};

const getParsedItem = (ware, metaData) => {
  return {
    item_name: `${ware.display_title} ${ware.display_trademark} ${ware.display_article}`,
    item_id: ware.ware_id, // id товару, який передаєте в фіді Merchant
    price: ware.price, // Ціна актуальна на день перегляду. Разделитель десятичных - точка
    affiliation: ware.seller_detail?.name, // Продавець,
    item_brand: ware.display_trademark, // Бренд товару
    item_category: '', // "Система очищення скла і фар", // Перший рівень категорії товару
    item_category2: '', // "Щітка склоочисника", // Другий рівень категорії товару
    quantity: ware.quantity, // Кількість товару, яку замовив користувач
    ...metaData
  };
};

const getAddToBasketEcommerceData = (ware, options) => {
  const itemsMeta = {
    item_list_name: options.from // Listing Page, Product Page, Search Results
  };

  return {
    currency: ware.currency,
    value: ware.price,
    items: [getParsedItem(ware, itemsMeta)]
  };
};

const getViewItemEcommerceData = (ware, options) => {
  const fields = {};

  if (['Listing Page', 'Search Results'].includes(options.from)) {
    fields.item_name = `${ware.display_title} ${ware.display_trademark} ${ware.display_article}`;
    fields.affiliation = ware.seller_detail?.name;
    fields.item_brand = ware.display_trademark;
  }

  return {
    items: [{
      item_name: `${ware.name} ${ware.trademark?.name} ${ware.article}`, // Назва товару повна як в карточці товару. На скріні 1
      item_id: ware.id, // id товару, який передаєте в фіді Merchant.На скріні 2
      price: ware.price, // Ціна актуальна на день перегляду. Разделитель десятичных - точка. На скріні 3
      affiliation: ware.seller?.business?.name, // Продавець. На скріні нах 4
      item_brand: ware.trademark?.name, // Бренд товару. На скріні 5
      item_category: ware.product_line?.parent?.name, // Перший рівень категорії товару. На скріні 6
      item_category2: ware.product_line?.name, // Другий рівень категорії товару. На скріні 7
      item_list_name: options?.item_list_name || options?.from || '',
      ...fields
    }]
  };
};

export {
  getParsedEcommerceData,
  getAddToBasketEcommerceData,
  getViewItemEcommerceData
};
