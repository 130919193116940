<template>
  <div
    v-if="value"
    :class="['the-rate', { 'disable-events': !$listeners.input }]"
  >
    <el-rate
      allow-half
      :value="Number(value) || 0"
      :max="max"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import ElRate from 'element-ui/lib/rate';

export default {
  name: 'TheRate',

  components: {
    ElRate
  },

  props: {
    value: { type: null, required: true },
    max: { type: Number, default: 5 }
  }
};
</script>

<style scoped>
.the-rate {
  width: 80px;
}
</style>
