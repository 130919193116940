<template>
  <div class="s-request-processing-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_s_requests_edit') }}
    </h1>

    <TheLoader v-if="isLoading" />

    <template v-else>
      <el-row justify="space-between" class="mb-2">
        <div>№{{ originFormData.public_id }}</div>
        <div>
          {{ $t(`sRequests.created`, { date: getDate(originFormData.created) }) }}
        </div>
      </el-row>

      <div class="mb-1">
        <el-collapse v-model="isCarInfo" class="mb-2" accordion>
          <el-collapse-item name="car">
            <template slot="title">
              <span class="custom-headline custom-headline--level3-bold pg-0-1">
                {{ getShortCarTitleOld(originFormData.car_detail) }}
              </span>
            </template>

            <component
              :is="`CarFullInfoItem${maxmq('sm') ? 'M' : ''}`"
              :value="originFormData.car_detail"
              readonly
              old
            />
          </el-collapse-item>
        </el-collapse>
      </div>

      <div class="mb-2">
        <BufferCopy :value="originFormData.car_detail.vin">
          <span class="text-ghost">VIN:</span> {{ originFormData.car_detail.vin }}
        </BufferCopy>
      </div>

      <SResponseForm
        v-if="isResponseForm"
        :value="isResponseForm"
        :editable-row="editableRow"
        @submit="addResponseToItem"
        @update="updateResponseItem"
        @cancel="isResponseForm = false"
      />

      <template v-else>
        <div v-if="originFormData.comment" class="comment mb-2">
          <div class="mb-1 bold">{{ $t('sRequests.comment') }}</div>
          <div>{{ originFormData.comment }}</div>
        </div>

        <SRequestProcessingItemsTable
          is-comment-private
          :value="formData.items"
          @add-item="editableRowData = null; isResponseForm = $event"
          @remove="removeRowFromItem"
          @edit="editRowItem"
        />

        <el-row justify="end" class="mt-4 mb-2">
          <el-checkbox :value="isArticleHidden" @change="setIsArticleHidden">
            {{ $t('sRequests.hideArticlesForBuyer') }}
          </el-checkbox>
        </el-row>

        <el-row justify="end">
          <el-button @click="confirmCancel">
            {{ $t('buttons.cancel') }}
          </el-button>

          <el-button
            type="primary"
            :loading="isVLoading"
            :disabled="isEqual"
            @click="submit"
          >
            {{ $t('buttons.submitRequest') }}
          </el-button>
        </el-row>
      </template>
    </template>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import SRequestProcessingItemsTable from './components/SRequestProcessingItemsTable';
import SResponseForm from './components/SResponseForm';
import { filters } from '@/lib/core';
import BufferCopy from '@/lib/core/components/BufferCopy';
import { getShortCarTitleOld } from '@/lib/garage/services/carParsers';

export default {
  name: 'SRequestProcessingPage',

  middleware: ['businessUserLoaded'],

  components: {
    /* eslint-disable max-len */
    CarFullInfoItem: () => import(/* webpackChunkName: "CarFullInfoItem" */ '@/lib/garage/components/CarFullInfoItem'),
    CarFullInfoItemM: () => import(/* webpackChunkName: "CarFullInfoItemM" */ '@/lib/garage/components/CarFullInfoItemM'),
    /* eslint-enable max-len */
    BufferCopy,
    SRequestProcessingItemsTable,
    SResponseForm
  },

  data () {
    return {
      isLoading: true,
      isVLoading: false,
      isCarInfo: '',
      isResponseForm: null,
      isArticleHidden: false,

      originFormData: null,
      formData: null,
      isEqual: true,
      editableRow: null
    };
  },

  watch: {
    formData: {
      deep: true,
      handler () {
        this.isEqual = isEqual(this.originFormData, this.formData);
      }
    }
  },

  async mounted () {
    await this.load();

    this.setDefaultData();
  },

  methods: {
    getShortCarTitleOld,
    getDate: filters.getDate,

    async load () {
      this.isLoading = true;

      try {
        const request = await this.$store.dispatch(
          'selectionRequests/getRequestCommon',
          { id: this.$route.params.id }
        );

        this.originFormData = request;
        this.formData = cloneDeep(request);
      } finally {
        this.isLoading = false;
      }
    },

    setDefaultData () {
      this.originFormData.items.forEach((item) => {
        if (
          item.selection_responses.length &&
          item.selection_responses.every(({ is_article_hidden }) => is_article_hidden) &&
          !this.isArticleHidden
        ) {
          this.isArticleHidden = true;
        }
      });
    },

    addResponseToItem ({ item, value }) {
      const index = this.formData.items.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        this.formData.items[index].selection_responses.push(value);
      }

      this.isResponseForm = null;
    },

    updateResponseItem ({ item, value, origin }) {
      const index = this.formData.items.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        const index2 = this.formData.items[index].selection_responses
          .findIndex(item => item === origin);

        if (index2 > -1) {
          this.$set(this.formData.items[index].selection_responses, index2, value);
        }
      }

      this.editableRow = null;
      this.isResponseForm = null;
    },

    async removeRowFromItem ({ item, row }) {
      if (row.id) {
        try {
          await this.$store.dispatch(
            'selectionResponses/removeResponse',
            { id: row.id }
          );
        } catch (e) {
          console.error(e);
          return null;
        }
      }

      const index = this.formData.items.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        this.formData.items[index].selection_responses =
          without(this.formData.items[index].selection_responses, row);
      }
    },

    async submit () {
      this.isVLoading = true;

      const { post, patch } = this.formData.items.reduce((acc, item, itemIndex) => {
        item.selection_responses.forEach((res, resIndex) => {
          const oldRes = this.originFormData.items[itemIndex]?.selection_responses[resIndex];

          if (!res.id) {
            acc.post.push({
              ...res,
              item: item.url,
              is_article_hidden: this.isArticleHidden
            });
          } else if (
            oldRes &&
            (
              oldRes.is_article_hidden !== res.is_article_hidden ||
              !isEqual(oldRes, res)
            )
          ) {
            acc.patch.push({ ...res, is_article_hidden: this.isArticleHidden });
          }
        });

        return acc;
      }, { post: [], patch: [] });

      try {
        const promises = [];

        if (post.length) {
          promises.push(this.$store.dispatch(
            'selectionResponses/postResponses',
            { responses: post }
          ));
        }

        if (patch.length) {
          promises.push(
            this.$store.dispatch(
              'selectionResponses/patchResponses',
              { responses: patch }
            )
          );
        }

        await Promise.all(promises);

        return this.$router.push({
          name: this.getRouteName('profile.business.s-requests')
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.isVLoading = false;
      }
    },

    setIsArticleHidden (isHidden) {
      this.formData.items.forEach((item) => {
        item.selection_responses.forEach((res) => {
          res.is_article_hidden = isHidden;
        });
      });

      this.isArticleHidden = isHidden;
    },

    editRowItem ({ item, row }) {
      this.editableRow = row;
      this.isResponseForm = item;
    },

    async confirmCancel () {
      let isConfirm = true;

      if (!this.isEqual) {
        isConfirm = await this.$confirm(
          this.$t('sRequests.responseProcessingExitConfirm'),
          {
            type: 'warning',
            title: this.$t('message.warning'),
            confirmButtonText: this.$t('buttons.yes'),
            cancelButtonText: this.$t('buttons.no')
          }
        );
      }

      if (isConfirm) {
        return this.$router.push({
          name: this.getRouteName('profile.business.s-requests')
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.s-request-processing-page {
  position: relative;

  .comment {
    padding: 10px;
    background-color: $blue-50;
  }
}
</style>
