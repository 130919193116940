<template>
  <div class="ware-info-main">
    <div class="mr-2 mb-2">
      <WareMainParams class="mb-2" hide-label :ware="ware" />
      <WareArticleReplacement v-if="isReplacement" :value="ware" />
    </div>

    <div class="mb-2">
      <FilePreviewList
        hide-title
        preview-size="xxl"
        readonly
        size="ml"
        dialog-img-path="image_200x200"
        default-img-path="image_url"
        vertical
        :alt="wareTitle"
        :value="ware.images"
      />

      <SearchInGoogle :query="wareTitle" />
    </div>
  </div>
</template>

<script>
import SearchInGoogle from './SearchInGoogle';
import { FilePreviewList } from '@/lib/core';
import WareMainParams from '@/lib/catalog/components/ware/WareMainParams';
import WareAR from '@/lib/catalog/components/ware/WareArticleReplacement';

export default {
  name: 'WareInfoMain',

  components: {
    FilePreviewList,
    SearchInGoogle,
    WareMainParams,
    WareArticleReplacement: WareAR
  },

  props: {
    ware: { type: Object, required: true }
  },

  computed: {
    wareTitle () {
      const { trademark, article } = this.ware;
      return `${trademark?.name || ''} ${article}`;
    },
    isReplacement () {
      return this.ware.replacements_replaces.length ||
        this.ware.replacements_replaced_by.length;
    }
  }
};
</script>

<style scoped lang="scss">
.ware-info-main {
  display: flex;
  justify-content: space-between;

  @media all and (max-width: $--xs) {
    flex-direction: column-reverse;
  }
}
</style>
