var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      staticClass: "collapse-toggle",
      attrs: { size: "mini", type: "text" },
      on: { click: _vm.toggle },
    },
    [
      _c("i", { class: "el-icon-arrow-" + (_vm.isOpened ? "up" : "down") }),
      _vm._v(" "),
      !_vm.hideLabel
        ? _c("span", { staticClass: "color-blue" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t(
                    "buttons." +
                      (_vm.isOpened ? "collapseAll" : "unCollapseAll")
                  )
                ) +
                "\n  "
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }