var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "delivery-time-string text-center",
        ((_obj = {}),
        (_obj["color-" + _vm.delivery.type] = !_vm.hideColor),
        (_obj["bold"] = _vm.delivery.bold && !_vm.hideBold),
        (_obj["text-ghost"] = _vm.delivery.ghost),
        _obj),
      ],
    },
    [
      _vm.delivery.warning
        ? _c("div", [_vm._v(_vm._s(_vm.$t("core.warning")))])
        : _vm._e(),
      _vm._v(" "),
      _vm.delivery.datetime
        ? _c("time", { attrs: { datetime: _vm.delivery.datetime } }, [
            _vm._v("\n    " + _vm._s(_vm.delivery.value) + "\n  "),
          ])
        : _c("div", [_vm._v(_vm._s(_vm.delivery.value))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }