/* eslint-disable */
var re_weburl = new RegExp(
  "^" +
  // protocol identifier (optional)
  // short syntax // still required
  "(?:(?:(?:https?|ftp):)?\\/\\/)" +
  // user:pass BasicAuth (optional)
  "(?:\\S+(?::\\S*)?@)?" +
  "(?:" +
  // IP address exclusion
  // private & local networks
  "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
  "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
  "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broadcast addresses
  // (first & last IP address of each class)
  "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
  "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
  "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
  "|" +
  // host & domain names, may end with dot
  // can be replaced by a shortest alternative
  // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
  "(?:" +
  "(?:" +
  "[a-z0-9\\u00a1-\\uffff]" +
  "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
  ")?" +
  "[a-z0-9\\u00a1-\\uffff]\\." +
  ")+" +
  // TLD identifier name, may end with dot
  "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
  ")" +
  // port number (optional)
  "(?::\\d{2,5})?" +
  // resource path (optional)
  "(?:[/?#]\\S*)?" +
  "$", "i"
);

export const EMAIL_REG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\u0100-\uFFFF\-0-9]+\.)+[a-zA-Z\u0100-\uFFFF]{2,}))$/;
// export const EMAIL_U_REG = /^[\w.\u0100-\uFFFF-]+@[\w\u0100-\uFFFF-]+(\.[\w\u0100-\uFFFF-]+)+$/u;
export const PHONE_REG = /^\+?(\d+[ ().-])*\d+$/;
export const INT_FLOAT_REG = /^(?=.)([+-]?([0-9]*)(\.([0-9]{1,2}))?)$/;
export const INT_FLOAT3_REG = /^(?=.)([+-]?([0-9]*)(\.([0-9]{1,3}))?)$/;
export const LAST_URL_INT_REG = /\/(\d+)\/$/;
export const SITE_REG = /.+(\.\w+)+$/;
export const FULL_SITE_REG = re_weburl;
export const SEARCH_ON_SITE_REG = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const CUT_LAST_DOTH_REG = /.[^.]+$/i;
export const AFTER_LAST_SLASH_REG = /([^/])+$/i;
export const FILE_FORMAT = /([^.])+$/i;
export const DELIVERY_DATE = /[\w]{4}-[\w]{2}-[\w]{2}/;
export const VIN = /[a-zA-Z0-9]{17}/;
