var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "mt-4 mb-4", attrs: { justify: "center" } },
    [
      !_vm.errors
        ? _c(
            "span",
            {
              staticClass:
                "list-header mb-2 custom-headline custom-headline--level3-bold",
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("prices.finish")) + "\n  ")]
          )
        : _vm.errors
        ? _c("pre", { staticClass: "color-red" }, [
            _vm._v("    " + _vm._s(_vm.errors) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }