var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "catalog-tree" }, [
    !_vm.value.length
      ? _c("div", { staticClass: "w-100 text-center pg-2" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("errors.emptyData")) + "\n  "),
        ])
      : _c(
          "ul",
          { staticClass: "catalog-body" },
          _vm._l(_vm.value, function (topLine) {
            return _c(
              "li",
              { key: topLine.id },
              [
                _c("CatalogItem", {
                  attrs: { dependency: _vm.dependency, value: topLine },
                }),
              ],
              1
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }