<template>
  <div class="location-select-map">
    <SearchPointMap
      ref="preview"
      class="map-preview mb-1"
      readonly
      :value="value"
    />

    <el-row v-if="!readonly" class="controls">
      <el-button-group>
        <el-button
          circle
          icon="el-icon-location"
          size="mini"
          @click="isDialog = true"
        />
      </el-button-group>
    </el-row>

    <SearchPointMapDialog
      v-if="isDialog"
      :input-query-string="inputQueryString"
      :is-visible="isDialog"
      :readonly="readonly"
      :search-query-string="searchQueryString"
      :value="value"
      @cancel="isDialog = false"
      @input="emit"
      @remove="remove"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import SearchPointMap from './SearchPointMap';
import SearchPointMapDialog from './SearchPointMapDialog';

export default {
  name: 'LocationSelectMap',

  components: {
    SearchPointMap,
    SearchPointMapDialog
  },

  props: {
    inputQueryString: String,
    searchQueryString: String,
    readonly: Boolean,
    value: { type: Object, default: () => ({}) }
  },

  data: () => ({
    isDialog: false
  }),

  computed: {
    hasGeo () {
      return this.value.lng && this.value.lat;
    },

    isEqual () {
      return this.value.lng === this.geo.lng && this.value.lon === this.geo.lon;
    }
  },

  watch: {
    searchQueryString: {
      async handler (query) {
        const geo = await this.searchByQuery(query);
        this.emit(geo);
      }
    }
  },

  created () {
    this.searchByQuery = debounce(this.searchByQuery, 500);
  },

  methods: {
    async searchByQuery (query) {
      if (!query) {
        return null;
      }

      try {
        return await this.$refs.preview.searchBy(query);
      } catch (e) {
        console.error(e);
      }
    },

    emit (geo) {
      if (geo && geo.lat && geo.lat) {
        this.$emit('input', geo);
      }

      this.isDialog = false;
    },

    remove () {
      this.$emit('input', { lat: null, lng: null });
      this.isDialog = false;
    },

    centered () {
      const { flyTo, zoom } = this.$refs.preview;
      flyTo(this.geo, zoom);
    }
  }
};
</script>

<style scoped lang="scss">
.location-select-map {
  position: relative;
  width: 200px;
  height: 200px;

  .map-preview {
    width: 100%;
    height: 200px;

    &.readonly {
      /deep/ .leaflet-control-zoom {
        visibility: hidden;
      }
    }
  }

  .controls {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 999;
  }
}
</style>
