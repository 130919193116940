<template>
  <div class="business-create-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_create') }}
    </h1>

    <el-form
      ref="form"
      class="pg-0-1"
      :model="formData"
      :rules="rules"
      label-width="150px"
      @submit.native.prevent="submit"
    >
      <div class="text-ghost mb-1">
        <p>{{ $t('profile.addCompanyInfo') }}</p>
      </div>

      <BusinessNamePhoto
        mode="create"
        :value="formData"
        v-bind.sync="formData"
      />

      <el-row class="mb-4" type="block">
        <div class="mb-1 bold">
          {{ $t('profile.companyUserContacts') }}
        </div>

        <div class="text-ghost mb-1">
          <p>{{ $t('profile.setYourContactInfo') }}</p>
          <p>{{ $t('profile.changeYourInfoToCompanyInfo') }}</p>
        </div>

        <el-form-item
          :label="$t('labels.phone')"
          prop="acl[0].phone_numbers.0.phone_number"
          :rules="rules.phone_number"
        >
          <PhoneInput
            v-model="formData.acl[0].phone_numbers[0].phone_number"
            class="max-w-210p"
            @blur="fixPhoneNumber(formData.acl[0].phone_numbers[0])"
          />
        </el-form-item>

        <el-form-item
          :label="$t('labels.email')"
          prop="acl[0].emails.0.email"
          :rules="rules.email"
        >
          <el-input
            v-model="formData.acl[0].emails[0].email"
            class="max-w-210p"
            type="email"
          />
        </el-form-item>
      </el-row>

      <el-row justify="center">
        <el-button class="mr-2" @click="goBack">
          {{ $t('buttons.nix') }}
        </el-button>

        <el-button
          native-type="submit"
          type="primary"
          :loading="loading"
        >
          {{ $t('buttons.continue') }}
        </el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BusinessNamePhoto from './components/formItems/BusinessNamePhoto';
import { getDefault } from '@/lib/core/services/defaultEntities';
import getValidationFor from '@/lib/core/validations';
import { fixPhoneNumber, getPrimaryOrFirst } from '@/lib/core/services/parsers';
import scrollToTop from '@/lib/core/services/scrollToTop';
import PhoneInput from '@/lib/core/components/PhoneInput';

const rules = [
  'name',
  'is_seller',
  'phone_number',
  'email'
];

export default {
  name: 'BusinessCreatePage',

  components: {
    BusinessNamePhoto,
    PhoneInput
  },

  data () {
    return {
      loading: false,
      rules: getValidationFor(rules, 'profile', this),
      formData: getDefault('businessProfile')
    };
  },

  computed: {
    user () {
      return this.$store.getters['users/user'];
    },

    logoUrl () {
      return this.formData.logo ? URL.createObjectURL(this.formData.logo) : null;
    }
  },

  created () {
    this.setDefaultData();
  },

  methods: {
    fixPhoneNumber,

    setDefaultData () {
      if (!this.user) {
        return null;
      }

      const { phone_numbers, emails } = this.user.profile;

      const phone = getPrimaryOrFirst(phone_numbers, 'phone');
      const email = getPrimaryOrFirst(emails, 'email');

      this.$set(this.formData.acl[0].phone_numbers, '0', phone);
      this.$set(this.formData.acl[0].emails, '0', email);
    },

    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          scrollToTop('is-error');
          return false;
        }

        this.loading = true;

        try {
          const business = await this.$store.dispatch(
            'businessProfiles/postBusinessProfile',
            this.formData
          );

          const routeName = business.is_seller
            ? 'profile.business.sale-points.create'
            : 'profile.business.edit';

          return this.$router.push({
            name: this.getRouteName(routeName)
            // query: { mode: 'business-create' }
          });
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      });
    },

    goBack () {
      this.$router.push({ name: this.getRouteName('home') });
    }
  }
};
</script>

<style scoped lang="scss">
.business-create-page {
  .is-seller ::v-deep .el-form-item__error {
    min-width: 100px;
  }

  @media all and (max-width: $--xs) {
    .is-seller ::v-deep {
      .el-form-item__label {
        width: unset !important;
      }

      .el-form-item__content {
        margin-left: unset !important;
      }
    }
  }
}
</style>
