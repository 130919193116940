<template>
  <FormBlock
    class="auto-update-block"
    entity-name="price"
    :fields="fields"
    :submit="submit"
    :value="dependency.price"
  >
    <template #default="{ formData, readonly }">
      <el-row justify="space-between">
        <el-form-item
          class="label-to-top"
          :label="$t('prices.selectUpdateMode')"
          prop="enable_auto_update"
        >
          <el-radio-group
            v-model="formData.enable_auto_update"
            class="ml-2"
          >
            <el-radio class="mb-1" :label="false">
              {{ $t('prices.manualUpdate') }}
            </el-radio>
            <el-radio :label="true">{{ $t('prices.urlUpdate') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <div>
          <el-button
            v-if="!readonly"
            size="mini"
            plain
            type="warning"
            @click="$emit('reupload:file')"
          >
            {{ $t('buttons.uploadOtherFile') }}
          </el-button>
        </div>
      </el-row>

      <div v-if="formData.enable_auto_update" class="outline-block">
        <div class="outline-block__title">{{ $t('prices.manualSettings') }}</div>

        <el-form-item
          :label="$t('labels.auto_update_url')"
          label-width="150px"
          prop="auto_update_url"
        >
          <el-input v-model="formData.auto_update_url" class="mb-1" />

          <ul class="rules-list">
            <li>{{ $t('prices.manualSettingsDescription1') }}</li>
            <li>{{ $t('prices.manualSettingsDescription2') }}</li>
          </ul>
        </el-form-item>
      </div>
    </template>
  </FormBlock>
</template>

<script>
import { parsers } from '@/lib/core';
import FormBlock from '@/lib/core/components/FormBlock';

export default {
  name: 'AutoUpdateBlock',

  components: { FormBlock },

  props: {
    dependency: { type: Object, required: true }
  },

  data: () => ({
    fields: ['enable_auto_update', 'auto_update_url']
  }),

  methods: {
    async submit (formData) {
      try {
        const price = await this.$store.dispatch('priceUploads/patchPrice', {
          id: this.dependency.price.id,
          formData
        });

        this.$emit('price:update', price);

        return parsers.debounce(300);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
