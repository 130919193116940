<template>
  <div v-if="invoice" class="invoice invoice-ua">
    <component
      :is="component"
      v-bind="{ ...$data, getDateTime, }"
    />
  </div>
</template>

<script>
import { getDateTime } from './services/filters';
import env from './services/envConstants';

export default {
  name: 'DocsInvoicePage',

  layout: 'empty',

  components: {
    // TODO use query from api (DocPage)
    'invoice-uk': () => import('./docs/invoice-uk'),
    'invoice-ru': () => import('./docs/invoice-ru')
  },

  async asyncData ({ app, store, params, error }) {
    try {
      const invoice = await store.dispatch(
        'balance/getInvoice',
        {
          id: params.id,
          business: store.getters['businessProfiles/businessId']
        }
      );

      return {
        invoice,
        mriyar: invoice.mriyar_contractor_detail,
        client: invoice.business_contractor_detail
      };
    } catch (e) {
      console.error(e);

      error({
        statusCode: 404,
        message: e.message || app.i18n.t('errors.404.message')
      });
    }
  },

  data ({ $store, $route }) {
    const lang = $route.params.id === 'privacy'
      ? env.MARKETPLACE_DEFAULT_LANG
      : $store.getters.langCode;

    return {
      component: `${$route.params.id}-${lang}`,
      dependency: {
        invoice: this.invoice,
        mriyar: this.mriyar,
        client: this.client,
        getDateTime,

        getFullPrice (amount) {
          const splitAmount = String(amount).split('.');
          return `${splitAmount[0]} гривень ${splitAmount[1]} копійок`;
        },

        getJuridicalAddress (detail) {
          const {
            street,
            building,
            room,
            city_detail: { full_name }
          } = detail.juridical_address;

          return `
            юр. адреса: вул.
            ${street}, ${building} оф.
            ${room}, ${full_name}
          `;
        }
      }
    };
  }
};
</script>

<style lang="scss">
.invoice {
  width: 836px;
  height: 835px;
  padding: 14px 35px;
  color: $--color-black;
  line-height: 21px;

  &__warning {
    height: 74px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    color: $red-600;
    font-weight: bold;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 15px;
  }

  &__title {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid $--color-black;
    font-weight: bold;
  }

  &__description {
    &--row {
      display: flex;
      padding: 0 20px;
      flex-shrink: 0;
      margin-bottom: 18px;
      margin-top: 4px;

      .row-label {
        display: flex;
        flex-direction: column;
        width: 105px;
        font-size: 13px;
        align-items: flex-end;
        margin-right: 10px;
      }

      .row-value {
        width: 560px;
        font-size: 13px;
        font-weight: 700;
        display: flex;
      }
    }
  }

  &__requisites {
    margin-bottom: 34px;
    height: 169px;
    width: 100%;
    border: 1px solid $--color-black;
    border-bottom: none;
    display: flex;
    font-weight: bold;

    &--col {
      div {
        border-bottom: 1px solid $--color-black;
        width: 100%;
      }

      small {
        font-weight: normal;
      }

      &.middle {
        width: 95px;
        border-left: 1px solid $--color-black;
        border-right: 1px solid $--color-black;
      }

      .middle__line {
        padding: 1px 6px;

        &:first-child {
          height: 100px;
        }

        &:last-child {
          height: 68px;
        }
      }

      &.first {
        width: 300px;
      }

      .first__line {
        padding: 7px 15px 12px 15px;
        height: 68px;
        flex-direction: column;
        justify-content: space-between;
        display: flex;

        &:first-child {
          height: 32px;
          padding-bottom: 4px;
        }
      }

      &.last {
        width: 370px;
      }

      .last__line {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          height: 100px;
        }

        &:last-child {
          height: 68px;
        }
      }
    }
  }

  &__table {
    width: 100%;
    display: flex;

    &--col {
      .col__line {
        border-left: 1px solid $--border-color-base;
        border-top: 1px solid $--border-color-base;
        display: flex;
        align-items: center;
        height: 56px;
        padding: 10px;

        &.total {
          font-weight: bold;

          .total__label {
            position: absolute;
            margin-right: 120px;
          }
        }

        &:last-child {
          border-bottom: 1px solid $--border-color-base;
        }

        &.header {
          background: $--background-color-base;
          justify-content: center;
        }
      }

      &.first {
        width: 50px;
      }

      &.middle {
        width: 597px;
      }

      &.last {
        width: 122px;

        .col__line {
          border-right: 1px solid $--border-color-base;
          justify-content: flex-end;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
</style>
