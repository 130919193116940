var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ware-info-main" }, [
    _c(
      "div",
      { staticClass: "mr-2 mb-2" },
      [
        _c("WareMainParams", {
          staticClass: "mb-2",
          attrs: { "hide-label": "", ware: _vm.ware },
        }),
        _vm._v(" "),
        _vm.isReplacement
          ? _c("WareArticleReplacement", { attrs: { value: _vm.ware } })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb-2" },
      [
        _c("FilePreviewList", {
          attrs: {
            "hide-title": "",
            "preview-size": "xxl",
            readonly: "",
            size: "ml",
            "dialog-img-path": "image_200x200",
            "default-img-path": "image_url",
            vertical: "",
            alt: _vm.wareTitle,
            value: _vm.ware.images,
          },
        }),
        _vm._v(" "),
        _c("SearchInGoogle", { attrs: { query: _vm.wareTitle } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }