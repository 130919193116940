<template>
  <div class="error">
    <div class="error-content">
      <div class="mb-2">
        <h1 class="error-title">
          {{ $t('errors.default.title') }} {{ error && error.statusCode }}
        </h1>
      </div>

      <div class="mb-2">{{ error && error.message }}</div>

      <nuxt-link :to="link">{{ $t('buttons.goBackHome') }}</nuxt-link>
    </div>
  </div>
</template>

<script>
import { env } from '@/lib/core';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';

export default {
  name: 'Error',

  props: {
    error: { type: Object, default: () => ({}) }
  },

  data () {
    return {
      link: getSeoLinkBy({}, this, {})
    };
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>

<style scoped lang="scss">
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 400px;

  &-title {
    color: red;
    text-align: center;
  }

  &-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
  }
}
</style>
