var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buyer-order-page" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : _c(
            "div",
            { staticClass: "content" },
            [
              _c("OrderInfo", {
                staticClass: "mb-2",
                attrs: { value: _vm.order },
                on: {
                  "sale-point:info": function ($event) {
                    _vm.isSalePointInfo = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("BuyerOrderItemsViewTable" + (_vm.maxmq("md") ? "M" : ""), {
                tag: "component",
                staticClass: "mb-2",
                attrs: {
                  "currency-name": _vm.MARKETPLACE_CURRENCY,
                  readonly: _vm.readonly,
                  value: _vm.items,
                },
                on: { "item:update": _vm.updateItem },
              }),
              _vm._v(" "),
              _vm.order.no_call_required || _vm.order.comment
                ? _c("div", { staticClass: "underline-block" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("basket.commentForm"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content pg-0" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "mb-2",
                            attrs: {
                              disabled: "",
                              value: _vm.order.no_call_required,
                            },
                          },
                          [
                            _c("span", { staticClass: "text-small" }, [
                              _vm._v(_vm._s(_vm.$t("basket.no_call_required"))),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          attrs: { value: _vm.order.comment, disabled: "" },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.readonly
                ? _c(
                    "el-row",
                    { staticClass: "mb-2", attrs: { justify: "end" } },
                    [
                      _vm.canCancelItems
                        ? _c(
                            "el-button",
                            {
                              staticClass: "mr-2",
                              attrs: { type: "danger" },
                              on: { click: _vm.cancelOrderItems },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("buttons.cancelAllOrder")) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: { title: _vm.$t("orders.salePointInfo") },
          model: {
            value: _vm.isSalePointInfo,
            callback: function ($$v) {
              _vm.isSalePointInfo = $$v
            },
            expression: "isSalePointInfo",
          },
        },
        [
          _vm.isSalePointInfo
            ? _c("SalePointInfo", { attrs: { id: _vm.isSalePointInfo } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }