var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "balance-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("routes.profile_business_balance")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "balance-header", attrs: { justify: "end" } },
        [
          _c("div", { staticClass: "mb-2 balance-info" }, [
            _c("div", { staticClass: "mb-2" }, [
              _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("balance.currentBalance")) +
                    ":\n          "
                ),
                _c("span", { staticClass: "bold" }, [
                  _vm._v(_vm._s(_vm.lastTransaction.created)),
                ]),
              ]),
              _vm._v(" "),
              _c("span", [
                _c(
                  "strong",
                  {
                    class:
                      "color-" +
                      (_vm.lastTransaction.balance < 0 ? "red" : "green"),
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.lastTransaction.balance) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.MARKETPLACE_CURRENCY) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isTransactionsLoading
        ? _c("TheLoader")
        : [
            _vm.transactions.result.length
              ? _c("TransactionsTable", {
                  staticClass: "mb-2",
                  attrs: {
                    value: _vm.transactions.result,
                    total: _vm.transactions.total,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("GenerateInvoiceDialog"),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }