<template>
  <div class="site-info">
    <el-row align="middle" :gutter="20">
      <el-col>
        <el-form-item :label="$t('profile.site')" label-width="250px" prop="site">
          <el-input :disabled="readonly" :value="value.site" @input="emit('site', $event)" />
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item label-width="0" prop="order_on_site">
          <el-checkbox :value="value.order_on_site" @change="emit('order_on_site', $event)">
            {{ $t('profile.order_on_site') }}
          </el-checkbox>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          :label="$t('profile.search_on_site_url')"
          label-width="250px"
          prop="search_on_site_url"
        >
          <el-input
            :disabled="readonly"
            :value="value.search_on_site_url"
            @input="emit('search_on_site_url', $event)"
          />
        </el-form-item>

        <div class="description ml-250p">
          <!--eslint-disable-next-line-->
          <p v-html="$t('profile.searchOnSiteUrlDescription', { mp })" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import env from '@/lib/core/services/envConstants';

export default {
  name: 'SiteInfo',

  props: {
    readonly: Boolean,
    value: { type: Object, required: true }
  },

  data: () => ({
    mp: env.BASE_CLIENT_PATH
  }),

  methods: {
    emit (name, val) {
      this.$emit(`update:${name}`, val);
    }
  }
};
</script>
