var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        staticClass: "account-info-block",
        attrs: {
          fields: _vm.fields,
          "label-width": "150px",
          submit: _vm.submit,
          value: _vm.value,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var formData = ref.formData
              return [
                _c("div", { staticClass: "description mb-1" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("account.accountInfoDescription"))),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.fields, function (field) {
                  return _c(
                    "el-form-item",
                    { key: field, attrs: { label: _vm.$t("labels." + field) } },
                    [
                      _c("el-input", {
                        model: {
                          value: formData[field],
                          callback: function ($$v) {
                            _vm.$set(formData, field, $$v)
                          },
                          expression: "formData[field]",
                        },
                      }),
                    ],
                    1
                  )
                }),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }