var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-response-page" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : [
            _c("div", { staticClass: "header" }, [
              _c(
                "h1",
                { staticClass: "custom-headline custom-headline--level2-bold" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("sRequests.sRequestNum", {
                          id: _vm.vinRequest.publicId,
                        })
                      ) +
                      "\n        "
                  ),
                  _c("span", { staticClass: "text-ghost text-small-2" }, [
                    _vm._v(
                      "\n          (" +
                        _vm._s(_vm.getDateTime(_vm.vinRequest.created)) +
                        ")\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              !_vm.$route.name.includes("hash")
                ? _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.$router.push({
                                name: _vm.getRouteName("garage.requests"),
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("buttons.toSelectionRequestsList")
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("div", { staticClass: "mb-2" }, [
                  _c("span", { staticClass: "bold text-ghost" }, [
                    _vm._v(_vm._s(_vm.$t("garage.answersCount")) + ":"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "answers-count" }, [
                    _vm._v(_vm._s(_vm.vinRequest.responsesCount)),
                  ]),
                ]),
                _vm._v(" "),
                _c("CarInfoCard" + (_vm.isMobile ? "M" : ""), {
                  tag: "component",
                  staticClass: "mb-2",
                  attrs: { value: _vm.vinRequest.car },
                }),
                _vm._v(" "),
                !_vm.vinRequest.responsesCount
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "bold color-label raw-items mb-2" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getComputedRawItems(_vm.vinRequest.items)
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("sRequests.emptyResponse")) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _c(
                      "el-collapse",
                      {
                        class: { "is-desktop": !_vm.isMobile },
                        model: {
                          value: _vm.activeBlocks,
                          callback: function ($$v) {
                            _vm.activeBlocks = $$v
                          },
                          expression: "activeBlocks",
                        },
                      },
                      _vm._l(_vm.tableData, function (value, sellerId) {
                        return _c(
                          "el-collapse-item",
                          { key: sellerId, attrs: { name: sellerId } },
                          [
                            _c(
                              "template",
                              { slot: "title" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "w-100 pg-0-1",
                                    attrs: {
                                      justify: "space-between",
                                      align: "middle",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "seller" },
                                        [
                                          _c("span", { staticClass: "bold" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("basket.seller")) +
                                                ":"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "el-link",
                                            {
                                              staticClass: "bold",
                                              attrs: { type: "primary" },
                                            },
                                            [_vm._v(_vm._s(value.sellerName))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      !_vm.isMobile
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-ghost text-small-2",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "sRequests.answerTime"
                                                    )
                                                  ) +
                                                  ":\n                  " +
                                                  _vm._s(
                                                    _vm.getDateTime(
                                                      value.lastUpdate
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _vm.isMobile
                                      ? _c(
                                          "div",
                                          { staticClass: "date text-ghost" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getDateTime(
                                                    value.lastUpdate
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _vm.activeBlocks.includes(sellerId)
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "btn",
                                                    attrs: {
                                                      type: "success",
                                                      plain: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "buttons.answerHide"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "btn",
                                                    attrs: { type: "success" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "buttons.answerShow"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "SResponseItemsTable" + (_vm.isMobile ? "M" : ""),
                              {
                                tag: "component",
                                attrs: { value: value.items },
                                on: {
                                  "ware-info:show": function ($event) {
                                    _vm.wareInfoId = $event
                                  },
                                  "sale-point:info": function ($event) {
                                    _vm.isSalePointInfo = $event
                                  },
                                  buy: _vm.addToBasket,
                                },
                              }
                            ),
                          ],
                          2
                        )
                      }),
                      1
                    ),
              ],
              1
            ),
          ],
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: { width: "1100px" },
          model: {
            value: _vm.isSalePointInfo,
            callback: function ($$v) {
              _vm.isSalePointInfo = $$v
            },
            expression: "isSalePointInfo",
          },
        },
        [
          _vm.isSalePointInfo
            ? _c("SalePointInfo", { attrs: { id: _vm.isSalePointInfo.id } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          model: {
            value: _vm.wareInfoId,
            callback: function ($$v) {
              _vm.wareInfoId = $$v
            },
            expression: "wareInfoId",
          },
        },
        [
          _vm.wareInfoId
            ? _c("WareInfo", {
                attrs: { id: _vm.wareInfoId },
                on: {
                  cancel: function ($event) {
                    _vm.wareInfoId = null
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }