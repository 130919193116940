<template>
  <div class="documents-page">
    <div v-if="textData">
      <h1 class="custom-headline custom-headline--level2-bold">
        {{ textData.h1_tag || textData.title_tag || textData.title || '' }}
      </h1>

      <!--eslint-disable-next-line-->
      <section class="default-style" v-html="textData.content" />
    </div>

    <p v-else class="pg-2 text-center">
      {{ $t('errors.emptyData') }}
    </p>
  </div>
</template>

<script>
import seoHeadMixin from '@/lib/catalog/mixins/seoHeadMixin';

export default {
  name: 'DocsPage',

  mixins: [seoHeadMixin],

  async asyncData ({ store, params, error }) {
    try {
      const [textData] = await store.dispatch(
        'cache/get',
        {
          action: 'pages/getPage',
          payload: { path: `docs/${params.id}` }
        }
      );

      if (!textData) {
        throw new Error(`there is no page with key: ${params.id}`);
      }

      return {
        textData,
        meta: {
          robots: textData.meta_robots,
          description: textData.meta_description,
          title: textData.title_tag
        }
      };
    } catch (e) {
      console.error(e);
      return error({
        statusCode: 404,
        message: `There is no page with key: ${params.id}`
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/default-style.scss";

.documents-page {
  padding: 10px;
  min-height: 100%;
}
</style>
