<template>
  <FormBlock
    class="main-info-block"
    :completeness-fields="['name', 'currency']"
    entity-name="price"
    :fields="fields"
    label-position="left"
    label-width="150px"
    :submit="submit"
    :value="value"
    v-on="$listeners"
  >
    <template #default="{ formData, readonly }">
      <MainInfo
        :dependency="dependency"
        :readonly="readonly"
        :value="formData"
        v-bind.sync="formData"
      />
    </template>
  </FormBlock>
</template>

<script>
import MainInfo from '../formItems/MainInfo';
import FormBlock from '@/lib/core/components/FormBlock';

const fields = [
  'name',
  'currency',
  'is_active',
  'description',
  'quantity_warehouse_is_own',
  'is_former_use',
  'is_final_price',
  'other_selling_terms',
  'payment_terms',
  'payment_terms_text',
  'warranty_terms_text'
];

export default {
  name: 'MainInfoBlock',

  components: {
    FormBlock,
    MainInfo
  },

  props: {
    dependency: { type: Object, required: true },
    value: { type: Object, required: true },
    submit: Function
  },

  data () {
    return {
      fields
    };
  }
};
</script>
