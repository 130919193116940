const addZero = num => num < 10 ? `0${num}` : num;

export const getDate = (rawDate, options = {}) => {
  // "2021-03-01T16:13:22+02:00"
  if (!rawDate) {
    return '-';
  }

  const date = new Date(rawDate);
  const result = {
    day: date.getDate(),
    months: date.getMonth() + 1,
    year: date.getFullYear()
  };

  if (options.add) {
    Object.keys(options.add).forEach((key) => {
      result[key] = result[key] + options.add[key];
    });
  }

  const splitter = options.splitter || '.';

  const { day, months, year } = result;

  const res = `${day}${splitter}${addZero(months)}${splitter}${addZero(year)}`;

  return options.reverse
    ? res.split(splitter).reverse().join(splitter)
    : res;
};

export const getTime = (rawDate, options = {}) => {
  // "2021-03-01T16:13:22+02:00"
  if (!rawDate) {
    return '-';
  }

  const date = new Date(rawDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  let time = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;

  if (options.milliseconds) {
    time = `${time}:00`;
  }

  return time;
};

export const getDateTime = (rawDate) => {
  // "2021-03-01T16:13:22+02:00"
  return rawDate ? `${getDate(rawDate)} ${getTime(rawDate)}` : '-';
};

const dateFilter = rawDate => getDateTime(rawDate);

export const getDateFromTime = (time = '00:00:00') => {
  // "09:00:00"
  if (!time) {
    return '-';
  }

  const [hours, min] = time.split(':');

  const date = new Date(0, 0, 0, Number(hours), Number(min));
  return date.toISOString();
};

export const cutTime = (time) => {
  // 09:00:00
  if (!time) { return '00:00'; }

  const [hours, min] = time.split(':');

  return `${hours}:${min}`;
};

// 2021-04-12
export const formatDate = date => date.split('-').reverse().join('.');

export default {
  dateFilter,
  getTime,
  getDate,
  getDateTime,
  getDateFromTime,
  cutTime,
  formatDate
};
