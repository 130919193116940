<template>
  <div class="buyer-orders-page min-page-height">
    <component
      :is="`BuyerOrdersTable${maxmq('md') ? 'M' : ''}`"
      use-page
      :load="loadOrderItems"
      :prop-count="count"
      :prop-value="items"
      @item:update="updateItem"
      @sale-point:info="showSalePoint"
    />

    <TheDialog
      v-model="isSalePointInfo"
      :title="$t('orders.salePointInfo')"
    >
      <SalePointInfo v-if="isSalePointInfo" :id="isSalePointInfo" />
    </TheDialog>
  </div>
</template>

<script>
import ordersPageMixin from './mixins/ordersPageMixin';
import getConcatedContacts from './services/getConcatedContacts';
import { parsers, filters, useAsyncDebounce, env } from '@/lib/core';

export default {
  name: 'BuyerOrdersPage',

  mixins: [ordersPageMixin],

  components: {
    /* eslint-disable max-len */
    BuyerOrdersTable: () => import(/* webpackChunkName: "BuyerOrdersTable" */ './components/BuyerOrdersTable'),
    BuyerOrdersTableM: () => import(/* webpackChunkName: "BuyerOrdersTableM" */ './components/BuyerOrdersTableM'),
    SalePointInfo: () => import(/* webpackChunkName: "SalePointInfo" */ '@/lib/salePoints/components/SalePointInfo')
    /* eslint-enable max-len */
  },

  data: () => ({ isSalePointInfo: false }),

  computed: {
    buyerId () {
      return this.$store.getters.buyerId;
    },

    basket () {
      return this.$store.getters['baskets/basket'];
    }
  },

  methods: {
    getParsed (item) {
      const {
        id,
        url,
        name,
        phone_numbers,
        emails,
        address
      } = item.order_detail.point_of_sale_detail;

      return {
        id: item.id,
        article: item.article,
        display_article: item.display_article,
        display_trademark: item.display_trademark,
        display_title: item.display_title,
        delivery_time: item.delivery_time,
        quantity: item.quantity,
        status: item.status,
        is_paid: item.is_paid,
        ware_id: item.ware_id,
        _publicId: item.order_detail.public_id,
        _displayPublicId: item.order_detail.display_public_id,
        _price: parsers.getPriceNum(item.price),
        _priceSum: parsers.getPriceNum(item.price * item.quantity),
        _orderId: parsers.getIdFromUrl(item.order),
        _deliveryDate: item.delivery_date
          ? filters.getDate(item.delivery_date)
          : '',
        _pointId: id,
        _pointUrl: url,
        _pointOfSaleName: name,
        _sellerId: item.order_detail.seller_detail.id,
        _sellerUrl: item.order_detail.seller_detail.url,
        _created: filters.getDateTime(item.order_detail.created),
        _updated: filters.getDateTime(item.updated),
        _city: address?.city_detail?.name,
        _phones: getConcatedContacts(phone_numbers || [], 'phone_number'),
        _emails: getConcatedContacts(emails, 'email'),
        _canRevertStatus: false,
        _revertStatusTimer: useAsyncDebounce({ debounce: env.STATUS_REVERT_DEBOUNCE })
      };
    },

    emitAnalytic (row) {
      this.$store.dispatch('analytics/pushEvent', 'seller_info_show');

      // this.$store.dispatch('analytics/postClick', {
      //   referer_url: `${env.BASE_CLIENT_PATH}${this.$route.fullPath}`,
      //   click_type: 'contacts',
      //   seller: row._sellerUrl,
      //   aprice: Number(row._price.replace(',', '.')) || undefined,
      //   ware_id: row.ware_id,
      //   point_of_sale: row._pointUrl,
      //   display_article: row.display_article,
      //   display_trademark: row.display_trademark
      // });
    },

    showSalePoint (order) {
      this.isSalePointInfo = order._pointId;
      this.emitAnalytic(order);
    }
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>
