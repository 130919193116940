var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ware-main-params" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hideLabel,
            expression: "!hideLabel",
          },
        ],
        staticClass: "mb-1",
      },
      [
        _c(
          "span",
          { staticClass: "custom-headline custom-headline--level3-bold" },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("ware.characteristic")) + "\n    "
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "p-char" },
      [
        _c("li", { staticClass: "p-char__item" }, [
          _c("div", { staticClass: "p-char__name" }, [
            _vm._v(_vm._s(_vm.$t("ware.trademark")) + ":"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-char__value" },
            [
              _c(
                "nuxt-link",
                {
                  staticClass: "custom-link",
                  attrs: { to: _vm.getLineTrademarkLink(_vm.ware) },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.get(_vm.ware, "trademark.name", "")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "p-char__item" }, [
          _c("div", { staticClass: "p-char__name" }, [
            _vm._v(_vm._s(_vm.$t("ware.country_name")) + ":"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "p-char__value" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.get(_vm.ware, "trademark.country_name")) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm._l(_vm.attrs, function (attr, index) {
          return _c("li", { key: index, staticClass: "p-char__item" }, [
            _c("div", { staticClass: "p-char__name" }, [
              _vm._v(_vm._s(attr.name) + ":"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "p-char__value" }, [
              _vm._v(_vm._s(attr.value)),
            ]),
          ])
        }),
        _vm._v(" "),
        _vm.ware.has_replacement_rel
          ? [
              _vm.ware.changed_from
                ? _c("li", { staticClass: "p-char__item" }, [
                    _c("div", { staticClass: "p-char__name" }, [
                      _vm._v(_vm._s(_vm.$t("search.changed_from")) + ":"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "p-char__value" },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.goToChanged(_vm.ware.changed_from)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.ware.changed_from.article) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ware.changed_to
                ? _c("li", { staticClass: "p-char__item" }, [
                    _c("div", { staticClass: "p-char__name" }, [
                      _vm._v(_vm._s(_vm.$t("search.changed_to")) + ":"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "p-char__value" },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.goToChanged(_vm.ware.changed_to)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.ware.changed_to.article) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "p-char-info mt-2" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("ware.characteristicDescription1")) + " "
      ),
      _c("br"),
      _vm._v(
        "\n    " + _vm._s(_vm.$t("ware.characteristicDescription2")) + "\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }