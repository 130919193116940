var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "delivery-service-select" },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("basket.deliveryRegion"),
            prop: "delivery_address.region",
            rules: _vm.dependency.rules["delivery_address.region"],
          },
        },
        [
          _c("SearchLocationSelect", {
            staticClass: "w-100pr",
            attrs: {
              value: _vm.value.region ? _vm.value.region_detail : null,
              field: "admin1",
              "label-name": "full_name",
            },
            on: { input: _vm.setRegion },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("basket.deliveryCity"),
            prop: "delivery_address.city",
            rules: _vm.dependency.rules["delivery_address.city"],
          },
        },
        [
          _c("SearchLocationSelect", {
            staticClass: "w-100pr",
            attrs: {
              value: _vm.city,
              field: "city",
              "label-name": "full_name",
              "allow-create": "",
              params: _vm.locationParams,
            },
            on: { input: _vm.setCity },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.type !== "address"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("basket.service_name"),
                prop: "delivery_address.service_name",
                rules: _vm.dependency.rules["delivery_address.service_name"],
              },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "w-100pr",
                  attrs: { value: _vm.value.service },
                  on: { change: _vm.setService },
                },
                [
                  _c(
                    "template",
                    { slot: "prefix" },
                    [
                      _c("BlockSize", {
                        staticClass: "mt-1 mr-1",
                        attrs: { size: "xs", src: _vm.getSelectPrefixIcon() },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.deliveryServices, function (service) {
                    return _c(
                      "el-option",
                      {
                        key: service.id,
                        attrs: {
                          label: _vm.$t(
                            "deliveryServices." +
                              service.label.replace(/\./g, "_")
                          ),
                          value: service.label,
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { align: "middle" } },
                          [
                            _c("BlockSize", {
                              staticClass: "mr-1",
                              attrs: {
                                size: "xs",
                                src: _vm.getIcon(service.label),
                              },
                            }),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "deliveryServices." +
                                        service.label.replace(/\./g, "_")
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "service_office"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("basket.service_office_number"),
                prop: "delivery_address.service_office_number",
                rules:
                  _vm.dependency.rules[
                    "delivery_address.service_office_number"
                  ],
              },
            },
            [
              _c("el-input", {
                attrs: { value: _vm.value.service_office_number },
                on: {
                  input: function ($event) {
                    _vm.setField(
                      "delivery_address.service_office_number",
                      $event
                    )
                    _vm.clearId()
                  },
                },
              }),
            ],
            1
          )
        : _vm.type === "address"
        ? _c(
            "el-form-item",
            {
              staticClass: "relative",
              attrs: {
                prop: "delivery_address.text",
                label: _vm.$t("basket.orderAddress"),
                rules: _vm.dependency.rules["delivery_address.text"],
              },
            },
            [
              _c("el-input", {
                attrs: { clearable: "", value: _vm.value.text },
                on: { input: _vm.onInputAddress, blur: _vm.clearResult },
              }),
              _vm._v(" "),
              _vm.searchResult.length
                ? _c(
                    "div",
                    { staticClass: "custom-autocomplete back-shadow scroll" },
                    _vm._l(_vm.searchResult, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "item",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.setSelected(item)
                            },
                          },
                        },
                        [_vm._v("\n        " + _vm._s(item.text) + "\n      ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }