import Vue from 'vue';
import { breakpointList } from './breakpoints';
import { parsers } from '@/lib/core';

Vue.mixin({
  computed: {
    isDev () {
      return this.$store.getters.isDev;
    }
  },

  methods: {
    maxmq (size, secondSize) {
      const { isAppLoaded, viewPort } = this.$store.getters;
      const mq = (!isAppLoaded && viewPort === 'mobile') ? 'xs' : this.$mq;

      if (!this.$store.getters.isDomReadyState && secondSize) {
        return secondSize === viewPort;
      } else if (size === 'mobile') {
        return viewPort === size;
      } else if (!size) {
        return mq;
      } else if (typeof size === 'string') {
        const viewIndex = breakpointList.findIndex(({ name }) => name === mq);
        const sizeIndex = breakpointList.findIndex(({ name }) => name === size);

        return viewIndex <= sizeIndex;
      }

      return breakpointList
        .filter(({ value }) => value <= size)
        .some(({ name }) => name === mq);
    },

    minmq (size) {
      return !this.maxmq(size);
    },

    getRouteName (_name, lang) {
      const { name } = parsers.getRouteByLang({ name: _name }, lang);
      return name;
    }
  }
});
