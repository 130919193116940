var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "availabilities" },
    [
      _c("ul", { staticClass: "description mb-2" }, [
        _c("li", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("priceFile.availabilitiesDescription")) +
              "\n      "
          ),
          _c("i", { staticClass: "el-icon-date" }),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(_vm._s(_vm.$t("priceFile.availabilitiesDescription2")) + " "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "rate mb-1",
              attrs: {
                label: _vm.$t("deliveryRate.askAddRate"),
                "label-width": "0",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { value: _vm.isDeliveryRate },
                  on: { input: _vm.toggleDeliveryRate },
                },
                [
                  _c(
                    "el-radio",
                    { staticClass: "mb-1", attrs: { label: false } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("deliveryRate.notUseRate")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("deliveryRate.addByRate")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isDeliveryRate
            ? _c("AvailabilitiesRate", {
                model: {
                  value: _vm.rateUrl,
                  callback: function ($$v) {
                    _vm.rateUrl = $$v
                  },
                  expression: "rateUrl",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "RateWarehouseTables",
        _vm._g(
          {
            attrs: {
              dependency: Object.assign({}, _vm.dependency, {
                rateUrl: _vm.rateUrl,
              }),
              value: _vm.value,
            },
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }