var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      staticClass: "delivery-rate-params",
      attrs: { justify: "space-between" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { "label-width": "0", prop: "correction_factor" } },
        [
          _c("NumInput", {
            attrs: {
              field: "correction_factor",
              readonly: _vm.readonly,
              "to-fixed": 3,
              value: _vm.value.correction_factor,
            },
            on: {
              input: function ($event) {
                return _vm.emit("correction_factor", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { "label-width": "0", prop: "constant" } },
        [
          _c("NumInput", {
            attrs: {
              field: "constant",
              label: _vm.$t("deliveryRate.constant", {
                name: _vm.l_currencyName,
              }),
              readonly: _vm.readonly,
              value: _vm.value.constant,
            },
            on: {
              input: function ($event) {
                return _vm.emit("constant", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { "label-width": "0", prop: "weight_rate" } },
        [
          _c("NumInput", {
            attrs: {
              field: "constant",
              label: _vm.$t("deliveryRate.weight_rate", {
                name: _vm.l_currencyName,
              }),
              readonly: _vm.readonly,
              value: _vm.value.weight_rate,
            },
            on: {
              input: function ($event) {
                return _vm.emit("weight_rate", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { "label-width": "0", prop: "no_weight_factor" } },
        [
          _c("NumInput", {
            attrs: {
              field: "no_weight_factor",
              readonly: _vm.readonly,
              "to-fixed": 3,
              value: _vm.value.no_weight_factor,
            },
            on: {
              input: function ($event) {
                return _vm.emit("no_weight_factor", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { "label-width": "0", prop: "no_trade_without_weight" } },
        [
          _c("el-checkbox", {
            attrs: {
              disabled: _vm.readonly,
              label: _vm.$t("deliveryRate.no_trade_without_weight"),
              value: _vm.value.no_trade_without_weight,
            },
            on: {
              change: function ($event) {
                return _vm.emit("no_trade_without_weight", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }