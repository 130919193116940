import cookie from '@/lib/core/services/cookie';
import { getIdFromUrl } from '@/lib/core/services/parsers';
import env from '@/lib/core/services/envConstants';

export const actions = {
  async postAndUpdateBasket ({ dispatch, rootGetters }, { formData }) {
    const ware = await dispatch('postItem', formData);
    const {
      session_key,
      basket_total_amount,
      basket_total_quantity,
      basket_items_count
    } = ware;

    const _basket = {
      total_amount: basket_total_amount,
      total_quantity: basket_total_quantity,
      items_count: basket_items_count,
      id: getIdFromUrl(ware.basket) || null
    };

    if (session_key) {
      cookie.set(env.SESSION_ID_KEY, session_key);
    }

    const pl = { formData: _basket, ware };
    dispatch('baskets/updateLocalBasket', pl, { root: true });

    return ware;
  },

  async postItem (_, formData) {
    return await this.$axios.$post('/basket-items/', formData);
  },

  async patchItem (_, { id, formData }) {
    return await this.$axios
      .$patch(`/basket-items/${id}/`, formData, { progress: false });
  },

  async removeItem (_, { id }) {
    return await this.$axios
      .$delete(`/basket-items/${id}/`, { progress: false });
  }
};
