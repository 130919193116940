import LRU from 'lru-cache';
import env from '../lib/core/services/envConstants';
import cookie from '../lib/core/services/cookie';
import { langs } from '../lang';

const options = {
  max: env.CACHE_MAX,
  maxAge: env.PAGE_CACHE_TIME
};

// https://www.npmjs.com/package/lru-cache
const cache = new LRU(options);

const noCache = [
  /\/search/g,
  /\/basket/g,
  /\/auth/g,
  /\/profile/g,
  /\/garage/g,
  /\/vin-requests/g,
  /\/test/g,
  /\/development/g
];

const isCachePage = (pathname) => {
  return pathname === '/' ||
    langs.some(lang => pathname === `/${lang}`) ||
    !noCache.some(reg => reg.test(pathname));
};

export const clearPageCache = () => cache.reset();

export default function (req, res, next) {
  if (!env.USE_PAGE_CACHE_MIDDLEWARE) {
    return next();
  }

  const url = req._parsedOriginalUrl;
  const pathname = url?.path || '';

  if (!pathname) {
    return next();
  } else if (/resetCache=true/g.test(pathname)) {
    cache.reset();
    console.info('CACHE RESET');
    return next();
  } else if (pathname && /delCache=true/g.test(pathname)) {
    cache.del(pathname);
    console.info('CACHE DEL: ', pathname);
    return next();
  } else if (/noCache=true/g.test(pathname)) {
    console.info('NO CACHE: ', pathname);
    return next();
  }

  const parsedCookie = req?.headers?.cookie
    ? cookie.parse(req.headers.cookie)
    : {};

  if (pathname && !parsedCookie['jwt-token'] && !parsedCookie.mrsessionid) {
    if (isCachePage(pathname)) {
      const existsHtml = cache.get(pathname);

      if (existsHtml) {
        // Don't forget to set up Content-Type Otherwise,
        // sometimes the browser may not render but trigger the download of files
        res.writeHead(200, { 'Content-Type': 'text/html; charset=utf-8' });
        return res.end(existsHtml.html, 'utf-8');
      } else {
        res.original_end = res.end;

        res.end = function (data) {
          if (res.statusCode === 200) {
            cache.set(pathname, { html: data });
          }

          res.original_end(data, 'utf-8');
        };
      }
    }
  }

  next();
}
