import Vue from 'vue';
import VuePositionSticky from 'vue-position-sticky';
import env from '../lib/core/services/envConstants';
import scrollToTop from '@/lib/core/services/scrollToTop';

const components = [VuePositionSticky];

export default ({ app, $gtm }) => {
  components.forEach((Component) => {
    if (typeof Component.install === 'function') {
      Vue.use(Component);
    } else if (Component.name) {
      Vue.component(Component.name, Component);
    } else {
      console.error('Unable to register component', Component); // eslint-disable-line no-console
    }
  });

  app.router.afterEach(scrollToTop);

  // @nuxtjs/gtm
  if ($gtm && env.GOOGLE_TAG_MANAGER_ID) {
    console.info('env.GOOGLE_TAG_MANAGER_ID', $gtm);
    // $gtm.init(env.GOOGLE_TAG_MANAGER_ID)
  }
};
