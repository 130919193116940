// https://github.com/yiminghe/async-validator

import userRules from './userRules';
import priceRules from './priceRules';
import profileRules from './profileRules';
import salePointRules from './salePointRules';
import accountRules from './accountRules';
import warehouseRules from './warehouseRules';
import priceSchemeRules from './priceSchemeRules';
import sellerTrademarkRules from './sellerTrademarkRules';
import deliveryRateRules from './deliveryRateRules';
import orderRules from './orderRules';
import deliveryAddressRules from './deliveryAddressRules';
import recipientRules from './recipientRules';
import businessRequisitesRules from './businessRequisitesRules';
import carRules from './carRules';
import selectionRequestRules from './selectionRequestRules';
import selectionResponseRules from './selectionResponseRules';
import shipmentRules from './shipmentRules';

import validators from './validators';
import transforms from './transforms';

const allRules = {
  userRules,
  businessRequisitesRules,
  priceRules,
  profileRules,
  salePointRules,
  accountRules,
  warehouseRules,
  priceSchemeRules,
  sellerTrademarkRules,
  deliveryRateRules,
  orderRules,
  deliveryAddressRules,
  recipientRules,
  carRules,
  selectionRequestRules,
  selectionResponseRules,
  shipmentRules
};

const getRuleValueFor = (rules = [], vm) => {
  return rules.map((rowRule) => {
    const rule = { ...rowRule };
    const { message, validator, transform } = rule;

    if (message) { rule.message = message; }
    if (validator) { rule.validator = validators[validator].bind(vm); }
    if (transform) { rule.transform = transforms[transform]; }

    return rule;
  });
};

const getValidationCollection = (rules, names, vm) => {
  return names.reduce((acc, key) => {
    acc[key] = getRuleValueFor(rules[key], vm) || key;
    return acc;
  }, {});
};

export default function getValidationFor (fields, entityName = 'user', vm) {
  const rules = allRules[`${entityName}Rules`];

  if (!rules) {
    throw new Error(`entityName ${entityName}: No validation rules (${vm.name})`);
  }

  return !fields
    ? getValidationCollection(rules, Object.keys(rules), vm)
    : getValidationCollection(rules, Array.isArray(fields) ? fields : [fields], vm);
}
