<template>
  <TheLoader v-if="isLoading" />

  <el-form
    v-else
    ref="businessReq"
    label-position="right"
    label-width="300px"
    :model="formData"
    :rules="rules"
    size="mini"
    class="mt-2"
    @submit.native.prevent="submit"
  >
    <div class="outline-block">
      <div class="outline-block__title">
        {{ $t('profile.mainRequisitesBlock') }}
      </div>
      <MainRequisitesBlock :value="formData" v-bind.sync="formData" />
    </div>

    <div class="outline-block">
      <div class="outline-block__title">
        {{ $t('profile.contactRequisitesBlock') }}
      </div>
      <ContactRequisitesBlock :value="formData" v-bind.sync="formData" />
    </div>

    <template v-if="formData.type">
      <div v-if="formData.type !== 'physical_person'" class="outline-block">
        <div class="outline-block__title">
          {{ $t('profile.bankRequisitesBlock') }}
        </div>
        <BankRequisitesBlock
          :value="formData.bank_requisites"
          v-bind.sync="formData.bank_requisites"
        />
      </div>

      <div v-if="formData.type !== 'physical_person'" class="outline-block">
        <div class="outline-block__title">
          {{ $t('profile.scanFilesRequisitesBlock') }}
        </div>

        <el-form-item
          label-width="420px"
          prop="udrpou_page_1"
          class="uploader"
        >
          <template #label>
            <div>
              {{ $t('requisites.scan.stateRegister') }}: <br>
              <span class="text-small">{{ $t('requisites.scan.stateRegisterDesc') }}</span>
            </div>
          </template>

          <ScanFilesRequisitesBlock
            v-model="formData.udrpou_page_1"
            :label="$t('requisites.scan.stateRegister')"
          />
        </el-form-item>

        <el-form-item
          :label="$t('requisites.scan.stateRegister2')"
          label-width="420px"
          prop="udrpou_page_2"
        >
          <ScanFilesRequisitesBlock v-model="formData.udrpou_page_2" />
        </el-form-item>

        <el-form-item
          label-width="420px"
          class="uploader"
          prop="bank_requisites.iban_page"
        >
          <template #label>
            <div>
              {{ $t('requisites.scan.accountOpen') }}: <br>
              <small>{{ $t('requisites.scan.accountOpenDesc') }}</small>
            </div>
          </template>
          <ScanFilesRequisitesBlock v-model="formData.bank_requisites.iban_page" />
        </el-form-item>

        <el-form-item
          v-if="formData.is_vat_payer"
          :label="$t('requisites.scan.pdv')"
          label-width="420px"
          prop="itn_of_vat_payer_page"
        >
          <ScanFilesRequisitesBlock v-model="formData.itn_of_vat_payer_page" />
        </el-form-item>
      </div>
    </template>

    <div class="footer">
      <div class="mb-2">
        <div class="mb-1">{{ $t('requisites.actualData') }}</div>

        <div v-if="platform" class="mb-1 required">
          <el-checkbox v-model="checked.terms" class="reverse">
            {{ $t('requisites.accept_term') }}
          </el-checkbox>
        </div>

        <span v-if="platform" class="print-document">
          <i class="el-icon-printer" />
          {{ $t('requisites.print_document') }}
        </span>
      </div>

      <el-row justify="end">
        <el-button
          v-if="!platform"
          type="primary"
          :disabled="isEqual || !formData.type"
          native-type="submit"
        >
          {{ $t('buttons.save') }}
        </el-button>

        <el-button
          v-else
          type="primary"
          :disabled="!checked.terms || !formData.type"
          native-type="submit"
        >
          <i class="el-icon-goods" />
          {{ $t('requisites.send_connect') }}
        </el-button>
      </el-row>
    </div>
  </el-form>
</template>

<script>
import mergeWith from 'lodash/mergeWith';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import MainRequisitesBlock from './formBlocks/MainRequisitesBlock';
import ContactRequisitesBlock from './formBlocks/ContactRequisitesBlock';
import BankRequisitesBlock from './formBlocks/BankRequisitesBlock';
import ScanFilesRequisitesBlock from './formBlocks/ScanFilesRequisitesBlock';
import { getDefault, getValidationFor, parsers, scrollToTop, library } from '@/lib/core';

export default {
  name: 'BusinessRequisitesTab',

  components: {
    MainRequisitesBlock,
    ContactRequisitesBlock,
    BankRequisitesBlock,
    ScanFilesRequisitesBlock
  },

  props: {
    platform: Object
  },

  data () {
    return {
      isLoading: true,
      checked: { terms: false },
      rules: getValidationFor(null, 'businessRequisites', this),
      originData: getDefault('businessRequisites'),
      formData: getDefault('businessRequisites'),
      isEqual: true
    };
  },

  computed: {
    business () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'];
    }
  },

  watch: {
    formData: {
      deep: true,
      handler (formData) {
        this.isEqual = isEqual(this.originData, formData);
      }
    }
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      this.isLoading = true;

      try {
        const response = await this.$store.dispatch(
          'businessProfiles/getBusinessRequisites',
          { id: this.$store.getters['businessProfiles/businessId'] }
        );

        if (response.length) {
          this.formData = mergeWith(
            {},
            this.formData,
            response[0],
            (o, s) => isNull(s) ? o : s
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;

        setTimeout(() => {
          this.originData.actual_address = cloneDeep(this.formData.actual_address);
        }, 500);
      }
    },

    submit () {
      this.$refs.businessReq.validate(async (isValid) => {
        if (!isValid) {
          scrollToTop('is-error');
          return false;
        }

        try {
          const action = this.formData.id ? 'update' : 'create';

          this.originData = await this.$store.dispatch(
            `businessProfiles/${action}BusinessRequisites`,
            {
              formData: this.prepareFormData(this.formData),
              id: this.formData.id
            });

          this.$set(this, 'formData', cloneDeep(this.originData));

          if (this.platform) {
            return this.connectPlatform({
              business_contractor: this.originData.url
            });
          } else {
            this.$notify.success({
              title: this.$t('message.success'),
              message: this.$t('message.businessSave')
            });
          }
        } catch (e) {
          console.error(e);
        }
      });
    },

    prepareFormData (formData) {
      const form = parsers.getClearFromEmpty(formData);

      if (form.id) {
        [
          'itn_of_vat_payer_page',
          'udrpou_page_1',
          'udrpou_page_2',
          'bank_requisites.iban_page'
        ].forEach((inputPath) => {
          const file = get(form, inputPath);
          const val = typeof file === 'string' ? null : file;
          set(form, inputPath, val);
        });
      }

      const _formDataByType = Object.entries(form).reduce((acc, [key, value]) => {
        if (library.businessRequisitesFieldsByTypes[form.type]?.includes(key)) {
          acc[key] = value;
        }

        return acc;
      }, {});

      return parsers.getNestedMultipartFormData({
        ..._formDataByType,
        business: this.business.url
      });
    },

    async connectPlatform ({ business_contractor }) {
      try {
        const formData = {
          business_contractor,
          marketplace: this.platform.url,
          marketplace_detail: this.platform
        };

        await this.$store.dispatch('platforms/connect', { formData });

        this.$notify.success({
          title: this.$t('message.success'),
          message: this.$t('message.platformConnect')
        });

        return this.$router.push({
          name: this.getRouteName('profile.platforms')
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
  text-align: right;
}

.uploader ::v-deep {
  .el-form-item__label {
    line-height: unset;
    position: relative;
    padding-right: 22px;
  }

  .el-form-item__label:after {
    position: absolute;
    top: -6px;
    right: 10px;
  }
}

.print-document {
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  color: #409EFF;

  &:hover {
    text-decoration: underline;
  }

  i {
    margin-right: 6px;
  }
}
</style>
