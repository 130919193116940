<template>
  <FormBlock
    class="photos-block"
    :fields="['photos']"
    :readonly="readonly"
    :submit="submitPhoto"
    :value="value"
    v-on="$listeners"
  >
    <template #default="{ formData, disabled }">
      <PhotoGallery v-model="formData.photos" :readonly="disabled" />
    </template>
  </FormBlock>
</template>

<script>
import isEqual from 'lodash/isEqual';
import PhotoGallery from '../PhotoGallery';
import FormBlock from '@/lib/core/components/FormBlock';

const fields = ['is_primary'];

export default {
  name: 'PhotosBlock',

  components: {
    FormBlock,
    PhotoGallery
  },

  props: {
    readonly: Boolean,
    value: { type: Object, required: true }
  },

  methods: {
    async submitPhoto ({ photos }, origin) {
      const {
        added,
        removed,
        updated
      } = this.splitPhotosByAction(origin.photos, photos);

      const formData = {
        pointId: this.value.id,
        pointUrl: this.value.url,
        added,
        removed,
        updated
      };

      try {
        const salePoint = await this.$store
          .dispatch('salePoints/submitPhotos', formData);

        this.$emit('input', salePoint);
        return salePoint;
      } catch (e) {
        console.error(e);
      }
    },

    splitPhotosByAction (origin, edited) {
      const existingIds = {};
      const diff = {
        added: [],
        removed: [],
        updated: []
      };

      edited.forEach(({ uid, ...item }) => {
        const itemId = item.id || uid;
        const old = origin.find(({ id }) => itemId === id);

        if (!old) {
          diff.added.push(item);
        } else if (old && this.isUpdated(old, item)) {
          diff.updated.push({ id: itemId, ...this.getDiff(old, item) });
        }

        existingIds[itemId] = true;
      });

      this.pushToDelete(origin, diff, existingIds);

      return diff;
    },

    pushToDelete (origin, diff, existingIds) {
      origin.forEach((oldItem) => {
        if (!existingIds[oldItem.id]) {
          diff.removed.push(oldItem.id);
        }
      });
    },

    isUpdated (oldItem, newItem) {
      return fields.some(key => !isEqual(oldItem[key], newItem[key]));
    },

    getDiff (oldItem, newItem) {
      return fields.reduce((acc, key) => {
        if (!isEqual(oldItem[key], newItem[key])) {
          acc[key] = newItem[key];
        }

        return acc;
      }, {});
    }
  }
};
</script>
