var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "search-filter-m",
    },
    [
      _c(
        "el-row",
        { staticClass: "top pg-2", attrs: { justify: "space-between" } },
        [
          _c("div", { staticClass: "bold" }, [
            _vm._v(_vm._s(_vm.$t("search.filter"))),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("i", {
              staticClass: "el-icon-close",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._t("default", function () {
        return [
          _c(
            "SearchFilter",
            _vm._g(
              { attrs: { "is-manual": "", "is-visible": _vm.visible } },
              _vm.$listeners
            )
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }