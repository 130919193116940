<template>
  <div class="s-request-wrapper mb-4">
    <nuxt />
  </div>
</template>

<script>
import { env } from '@/lib/core';

export default {
  name: 'SRequestWrapper',

  middleware: ['userAuth'],

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>
