var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.value.length
    ? _c("el-row", { staticClass: "pg-2", attrs: { justify: "center" } }, [
        _vm._v("\n  " + _vm._s(_vm.$t("errors.emptyData")) + "\n"),
      ])
    : _c(
        "ul",
        { staticClass: "mr-list car-default-list" },
        _vm._l(_vm.value, function (item) {
          return _c(
            "li",
            { key: item.id, staticClass: "mr-list-item" },
            [
              _c(
                "el-button",
                {
                  class: { "disable-events": _vm.readonly },
                  attrs: {
                    size: "mini",
                    type: _vm.isActive(item) ? "primary" : "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("set-item", item)
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
              ),
            ],
            1
          )
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }