var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "taxi-delivery" }, [
    _c("div", { staticClass: "mr-2 mb-2" }, [
      _c("div", { staticClass: "el-form-item__label" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("basket.salePointAddress")) + ":\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "address" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.getConcatedAddress(_vm.salePointAddress, {
                hideCountry: true,
              })
            ) +
            "\n    "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-form-item",
          {
            attrs: {
              label: _vm.$t("basket.delivery_address"),
              prop: "delivery_address.text",
              rules: _vm.dependency.rules["delivery_address.text"],
            },
          },
          [
            _c("el-input", {
              attrs: { value: _vm.value.text },
              on: {
                input: function ($event) {
                  return _vm.setField("delivery_address.text", $event)
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }