import get from 'lodash/get';

export default (list) => {
  return (list || []).map((it) => {
    const tm_sl = it.trademark?.slug;

    return {
      id: null,
      display_article: it.article,
      display_trademark: it.trademark?.name,
      display_title: it.name,
      delivery_time: null,
      ...it,
      trademark_slug: tm_sl,
      ware: {
        id: it.id,
        image_url: get(it, 'images.0.image_url', ''),
        article_slug: it.article_slug,
        trademark_slug: tm_sl,
        trademark: {
          slug: tm_sl
        }
      }
    };
  });
};
