<template>
  <div class="s-response-search-wares">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <div class="min-w-320 mb-1 pg-0-1">
        <h4 class="custom-headline custom-headline--level4 pg-0">
          {{ $t('search.invitedArticle') }}
        </h4>
      </div>

      <el-row v-if="!wares.length" justify="center" class="pg-2">
        {{ $t('sRequests.emptySearchText', { name: searchName }) }}
      </el-row>

      <WaresTable
        v-else
        :prop-value="wares"
        :ordering="ordering"
        @info:show="$emit('info:show', $event)"
        @toggle-ordering="toggleOrdering"
      >
        <template #6h>
          {{ $t('priceFile.name') }}
        </template>

        <template #6="row">
          {{ row.price_file ? row.price_file.name : '' }}
        </template>

        <template #options="row">
          <el-button
            size="mini"
            type="primary"
            @click.stop="$emit('select', row)"
          >
            {{ $t('buttons.select') }}
          </el-button>
        </template>
      </WaresTable>

      <div v-if="limitedAnalogs.length" class="mt-4">
        <div class="min-w-320 mb-1 pg-0-1">
          <h4 class="custom-headline custom-headline--level4 pg-0">
            {{ $t('search.invitedArticleAndAnalogs') }}
          </h4>
        </div>

        <WaresTable
          :prop-value="limitedAnalogs"
          :ordering="ordering"
          @info:show="$emit('info:show', $event)"
          @toggle-ordering="toggleOrdering"
        >
          <template #6h>
            {{ $t('priceFile.name') }}
          </template>

          <template #6="row">
            {{ row.price_file ? row.price_file.name : '' }}
          </template>

          <template #options="row">
            <el-button
              size="mini"
              type="primary"
              @click.stop="$emit('select', row)"
            >
              {{ $t('buttons.select') }}
            </el-button>
          </template>
        </WaresTable>

        <el-row v-if="analogsLimit < analogs.length" justify="end" class="mt-2">
          <el-button size="small" @click="analogsLimit += 10">
            {{ $t('buttons.getMoreRows', { num: 10 }) }}
          </el-button>
        </el-row>
      </div>
    </template>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import WaresTable from '@/lib/search/components/WaresTable';

export default {
  name: 'SResponseSearchWares',

  components: {
    WaresTable
  },

  props: {
    value: { type: Object, required: true }
  },

  data () {
    return {
      isLoading: true,
      wares: [],
      analogs: [],
      analogsLimit: 5,
      ordering: []
    };
  },

  computed: {
    searchName () {
      const { display_trademark, display_article, display_title } = this.value;
      return `${display_title} ${display_trademark} ${display_article}`;
    },

    limitedAnalogs () {
      return this.analogs.slice(0, this.analogsLimit);
    }
  },

  mounted () {
    this.toggleOrdering([]);
  },

  methods: {
    async loadWares () {
      this.isLoading = true;

      try {
        const { results } = await this.$store.dispatch(
          'search/searchBusinessPrises',
          {
            seller: this.$store.getters['businessProfiles/sellerId'],
            ordering: this.ordering.toString() || '-rank',
            ware: this.value.price_search_ware_key || this.value.ware.id
          }
        );

        this.wares = results;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    async loadAnalogs () {
      try {
        const { results } = await this.$store.dispatch(
          'search/searchBusinessPrises',
          {
            seller: this.$store.getters['businessProfiles/sellerId'],
            ordering: this.ordering.toString() || '-rank',
            ware_and_analogs: this.value.price_search_ware_key || this.value.ware.id
          }
        );

        this.analogs = results;
      } catch (e) {
        console.error(e);
      }
    },

    toggleOrdering (_ordering) {
      this.ordering = isEqual(this.ordering, _ordering) ? [] : _ordering;
      this.loadWares();
      this.loadAnalogs();
    }
  }
};
</script>
