import get from 'lodash/get';
import { parsers } from '@/lib/core';

const getCanBy = (item) => {
  return !item.other_selling_terms.includes('does_not_conduct_activities') &&
    !!get(item, 'point_of_sale.id');
};

export const getParsedItem = (_item) => {
  const item = { ..._item };

  item.offer_id = item.offer_id || item.id;
  item._price = item.price ? parsers.getPriceNum(item.price) : '-';
  item._searchOnSellerSiteUrl = item.search_on_seller_site_url
    ? `${item.search_on_seller_site_url}${item.article}`
    : '';
  item._canBy = getCanBy(item);
  item._isVatPayer = item?.payment_terms?.includes('vat_payer');
  item._storeId = get(item, 'point_of_sale.id');
  item._city = get(item, 'point_of_sale.address.city.name');

  return item;
};

export const getParsedWare = (it) => {
  const tm_sl = it.trademark?.slug || '';

  return {
    id: it.id,
    offer_id: it.offer_id || it.id,
    max_price: it.max_price,
    min_price: it.min_price,
    price: it.price,
    price_search_ware_key: it.price_search_ware_key,

    display_article: it.article,
    display_trademark: it.trademark?.name || tm_sl,
    name: it.name,

    article_slug: it.article_slug,
    trademark: {
      slug: tm_sl
    },

    in_stock: it.in_stock,
    availability: it.availability,
    availability_date: it.availability_date,

    has_analogs: it.has_analogs,
    has_applicability: it.has_applicability,
    has_images: it.has_images,
    has_info: it.has_info,
    has_kits: it.has_kits,
    has_replacement_rel: it.has_replacement_rel,

    ware: {
      id: it.ware?.id || it.id
    },
    point_of_sale: it.point_of_sale
      ? {
          id: it.point_of_sale?.id,
          name: it.point_of_sale?.name
        }
      : null,

    _price: it.price ? parsers.getPriceNum(it.price) : '-',
    _isShowRetail: null,
    _formerUse: (it.other_selling_terms || []).includes('former_use'),
    _city: it.point_of_sale?.address.city.name
  };
};

export default function (list) {
  return list.map(getParsedItem);
}
