import transform from 'lodash/transform';
import { i18n } from '@/plugins/i18n';

const priceSchemeTypes = ['static', 'dynamic'];
const priceFileFormats =
  ['xls', 'xlsx', 'xlsb', 'xlsm', 'csv', 'txt', 'zip', '7z', 'rar'];
const salePointFileFormats = ['zip', 'rar', 'xsl', 'xlsx', 'txt', 'csv', 'xml'];

const priceTypes = [
  {
    type: 'csv',
    title: 'TXT/CSV',
    attributes: [
      {
        title: i18n.t('priceFile.file_encoding'),
        code: 'file_encoding',
        options: [
          { value: 'auto', label: i18n.t('priceFile.auto') },
          { value: 'utf-8', label: 'UTF-8' },
          { value: 'cp1251', label: 'windows-1251' }
        ]
      },
      {
        title: i18n.t('priceFile.csv_delimiter'),
        code: 'csv_delimiter',
        allowCreate: true,
        options: [
          { value: ';', label: ';' },
          { value: ':', label: ':' },
          { value: ',', label: ',' },
          { value: '\t', label: 'tab' }
        ]
      }
    ]
  }
];

// osa / oc - it used because we field like ware_and_analogs / city_and_delivery,
// but in SearchFilter.vue we have inverted translation -
// "Місцеві пропозиції" / "Тільки аналог що шукали"

const searchFilterAbbreviation = {
  osa: 'onlySelectArticle',
  oc: 'onlyCity',
  cad: 'city_and_delivery',
  waa: 'ware_and_analogs',
  tm: 'trademarks',
  is: 'in_stock',
  irp: 'is_return_possible',
  iw: 'is_warranted',
  pg: 'price_gte',
  pl: 'price_lte',
  dtg: 'delivery_time_gte',
  dtl: 'delivery_time_lte',
  pm: 'payment_methods',
  dm: 'delivery_methods',
  ost: 'other_selling_terms',
  pt: 'payment_terms',
  sa: 'sale_activities',
  iot: 'is_official_trademark',
  og: 'ordering'
};

const searchFilterKeys = transform(searchFilterAbbreviation, (acc, value, key) => {
  acc[value] = key;
}, {});

const deliveryTypes = ['service_office', 'address'];

const orderItemEndStatuses = ['buyer_canceled', 'buyer_rejected', 'seller_canceled', 'issued'];
const orderItemLifeCycle = ['new', 'in_progress', 'ready', 'issued'];
const orderItemsStatuses = [
  'new',
  'in_progress',
  'ready',
  'issued',
  'buyer_canceled',
  'buyer_rejected',
  'seller_canceled'
];

const carModificationFilterTabs = [
  'brand',
  'model',
  'production_year',
  'body_type',
  'fuel_types_and_capacitie',
  'drive_type',
  'modification'
];

const seoUrlsByParams = {
  '': '/home/',
  carBrand: '/car-brands/carBrand/',
  'carBrand,lineId': '/car-brands/carBrand/product-lines/lineId/',
  'carBrand,lineId,trademarkId':
    '/car-brands/carBrand/product-lines/lineId/trademarks/trademarkId/',
  'carBrand,carModel': '/car-brands/carBrand/car-models/carModel/',
  'carBrand,carModel,lineId': '/car-brands/carBrand/car-models/carModel/product-lines/lineId/',
  'carBrand,carModel,lineId,trademarkId':
    '/car-brands/carBrand' +
    '/car-models/carModel' +
    '/product-lines/lineId' +
    '/trademarks/trademarkId/',
  'carBrand,carGeneration,carModel':
    '/car-brands/carBrand/car-models/carModel/car-generations/carGeneration/',
  'carBrand,carGeneration,carModel,lineId':
    '/car-brands/carBrand' +
    '/car-models/carModel' +
    '/car-generations/carGeneration' +
    '/product-lines/lineId/',
  'carBrand,carGeneration,carModel,lineId,trademarkId':
    '/car-brands/carBrand' +
    '/car-models/carModel' +
    '/car-generations/carGeneration' +
    '/product-lines/lineId' +
    '/trademarks/trademarkId/',
  lineId: '/product-lines/lineId/',
  'lineId,regionId': '/product-lines/lineId/regions/regionId/',
  'lineId,trademarkId': '/product-lines/lineId/trademarks/trademarkId/',
  'lineId,regionId,trademarkId': '/product-lines/lineId/trademarks/trademarkId/regions/regionId/',
  trademarkId: '/trademarks/trademarkId/'
};

const businessRequisitesFieldsByTypes = {
  entrepreneur: [
    'id',
    'type',
    'itn',
    'is_vat_payer',
    'itn_of_vat_payer',
    'actual_address',
    'phone_number',
    'email',
    'bank_requisites',
    'udrpou_page_1',
    'udrpou_page_2',
    'itn_of_vat_payer_page'
  ],
  juridical_person: [
    'id',
    'type',
    'udrpou',
    'official_name',
    'short_name',
    'is_vat_payer',
    'itn_of_vat_payer',
    'juridical_address',
    'signatory_full_name',
    'actual_address',
    'phone_number',
    'email',
    'bank_requisites',
    'udrpou_page_1',
    'udrpou_page_2',
    'itn_of_vat_payer_page'
  ],
  physical_person: [
    'id',
    'type',
    'first_name',
    'last_name',
    'middle_name',
    'actual_address',
    'phone_number',
    'email'
  ]
};

export const googleTagsKeys = [
  'gclid',
  'gclsrc',
  'UTM_source',
  'UTM_medium',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content'
];

export default {
  searchFilterAbbreviation,
  searchFilterKeys,
  deliveryTypes,

  priceSchemeTypes,
  priceTypes,
  priceFileFormats,

  salePointFileFormats,

  orderItemEndStatuses,
  orderItemsStatuses,
  orderItemLifeCycle,

  carModificationFilterTabs,

  seoUrlsByParams,
  businessRequisitesFieldsByTypes,

  googleTagsKeys
};
