<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="rules"
    size="mini"
    @submit.native.prevent="theSubmit"
  >
    <el-checkbox-group v-model="formData.delivery_methods" class="cell">
      <template v-for="item in deliveryMethods">
        <div :key="item">
          <el-checkbox :label="item">
            {{ $t(`delivery_methods.${item}`) }}
          </el-checkbox>
        </div>
      </template>
    </el-checkbox-group>

    <div
      v-show="formData.delivery_methods.includes('carrier_in_country')"
      class="mt-2"
    >
      <DeliveryServicesTable
        :value="filteredServices"
        @input="setDeliveryService"
        @save="theSubmit"
      />
    </div>

    <div class="mt-4 mb-4">
      <div>{{ $t("salePoint.delivery_methods_text") }}</div>

      <el-input
        v-model="formData.delivery_methods_text"
        :rows="5"
        type="textarea"
      />
    </div>

    <el-row class="mt-4" justify="end">
      <el-button @click="cloneOrigin">
        {{ $t("buttons.cancel") }}
      </el-button>

      <el-button
        :loading="isLoading"
        :disabled="isEqual"
        native-type="submit"
        type="primary"
      >
        {{ $t("buttons.save") }}
      </el-button>
    </el-row>
  </el-form>
</template>

<script>
import SalePointMainInfoTab from './SalePointMainInfoTab';
import DeliveryServicesTable from './DeliveryServicesTable';

export default {
  name: 'SalePointDeliveryTab',

  extends: SalePointMainInfoTab,

  components: {
    DeliveryServicesTable
  },

  data () {
    return {
      fields: [
        'delivery_methods',
        'delivery_services',
        'delivery_methods_text'
      ]
    };
  },

  computed: {
    deliveryMethods () {
      return this.$store.getters['dependencies/deliveryMethods']
        .filter(({ in_point_of_sale }) => in_point_of_sale)
        .map(({ label }) => label);
    },

    activeServicesIds () {
      return this.$store.getters['dependencies/deliveryServices'].map(
        ({ id }) => id
      );
    },

    filteredServices () {
      return this.formData.delivery_services.filter((it) => {
        return this.activeServicesIds.includes(it.service_detail.id);
      });
    }
  },

  methods: {
    setDeliveryService (data) {
      const index = this.formData.delivery_services.findIndex((it) => {
        return it.service_detail.id === data.service_detail.id;
      });

      if (index > -1) {
        this.$set(this.formData.delivery_services, index, data);
      }
    }
  }
};
</script>
