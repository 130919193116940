import dayjs from 'dayjs';
import cookie from '@/lib/core/services/cookie';
import env from '@/lib/core/services/envConstants';
import errorHandler from '@/plugins/errorHandler';

let autoUpdateTokenTimer = null;
let isSecondTokenUpdateTry = false;

export const isActualToken = (token_expires) => {
  return token_expires &&
    token_expires - dayjs(new Date().toISOString()).unix() > 0;
};

export const state = () => ({
  token: null,
  token_expires: null
});

export const mutations = {
  setToken: (state, token) => { state.token = token; },
  setTokenExpires: (state, token_expires) => {
    state.token_expires = token_expires;
  }
};

export const actions = {
  async autoLogin ({ state, commit, dispatch }, payload = {}) {
    const _cookie = payload.cookie || {};

    const _token = _cookie.token ||
      await dispatch('getCookie', env.TOKEN_KEY, { root: true });

    const _tE = _cookie.token_expires ||
      await dispatch('getCookie', env.TOKEN_EXPIRES_KEY, { root: true });

    const _tokenExpires = Number.parseInt(_tE);

    if (
      _token &&
      _tokenExpires &&
      isActualToken(_tokenExpires)
    ) {
      await dispatch(
        'setTokenData',
        {
          token: _token,
          token_expires: _tokenExpires,
          _blockUpdate: true
        }
      );

      try {
        const user = await dispatch('updateToken');

        await Promise.all([
          dispatch('setTokenData', { ...user, _blockUpdate: true }),
          dispatch(
            'users/setUserData',
            { user, cookie: _cookie },
            { root: true }
          )
        ]);

        return user;
      } catch (e) {
        console.error(e);
        await dispatch('setTokenData', { _blockUpdate: true });
        return null;
      }
    }
  },

  async login ({ commit, dispatch, getters, rootGetters }, formData) {
    const user = await this.$axios.$post('/api-token-auth/', formData);

    await Promise.all([
      dispatch('setTokenData', { ...user, _blockUpdate: true }),
      dispatch('users/setUserData', { user }, { root: true }),
      dispatch('carModifications/setCarData', { ids: [], carData: null }, { root: true })
    ]);

    return user;
  },

  async loginBySocial ({ commit, dispatch, rootGetters }, formData) {
    const user = await this.$axios
      .$post('/api-token-social-auth/', formData);

    await Promise.all([
      dispatch('setTokenData', user),
      dispatch('users/setUserData', { user }, { root: true }),
      dispatch('carModifications/setCarData', { ids: [], carData: null }, { root: true })
    ]);

    return user;
  },

  async autoUpdateToken ({ dispatch, getters }) {
    await dispatch('clearAutoUpdateToken');

    autoUpdateTokenTimer = setTimeout(async () => {
      if (getters.isAuthenticated && isActualToken(getters.tokenEx)) {
        try {
          await dispatch('updateToken');
          console.info('updateToken', new Date());
        } catch (e) {
          console.error(e);
          dispatch('clearAutoUpdateToken');
        }
      }
    }, env.TOKEN_UPDATE_INTERVAL);
  },

  async updateToken ({ dispatch, getters, rootGetters }) {
    try {
      // const user = await this.$axios.$post('/api-token-refresh/', {
      //   token: getters.token,
      //   progress: false
      // });

      const { data: user } = await this.$axios({
        method: 'post',
        baseURL: env.BASE_API,
        url: '/api-token-refresh/',
        progress: false,
        data: {
          token: getters.token
        }
      });

      await Promise.all([
        dispatch('setTokenData', { ...user }),
        dispatch('users/setUserData', { user }, { root: true })
      ]);

      isSecondTokenUpdateTry = false;
      return user;
    } catch (e) {
      console.error(e);

      if (isActualToken(getters.tokenEx) && !isSecondTokenUpdateTry) {
        isSecondTokenUpdateTry = true;
        await dispatch('autoUpdateToken');
      } else {
        const lc = rootGetters.langCode;
        const name = lc !== env.MARKETPLACE_DEFAULT_LANG
          ? `auth__${lc}`
          : 'auth';

        await dispatch('logoutAndGo', { code: '401', name });
      }
      throw e;
    } finally {
      if (process.client && window.closed) {
        dispatch('clearAutoUpdateToken');
      }
    }
  },

  setTokenData ({ commit, dispatch }, payload = {}) {
    const { token, token_expires, _blockUpdate } = payload;

    commit('setToken', token || null);
    commit('setTokenExpires', token_expires || null);

    if (token && token_expires) {
      cookie.set(env.TOKEN_KEY, token);
      cookie.set(env.TOKEN_EXPIRES_KEY, token_expires);
    } else {
      cookie.remove(env.TOKEN_KEY);
      cookie.remove(env.TOKEN_EXPIRES_KEY);
    }

    if (token_expires && !_blockUpdate) {
      return dispatch('autoUpdateToken');
    } else if (process.client && window.closed) {
      console.info('window was closed');
    }
  },

  async logoutAndGo ({ dispatch }, { name, code } = {}) {
    if (name) {
      await this.$router.push({ name });
    }

    if (code) {
      errorHandler({ code });
    }

    return dispatch('logout');
  },

  logout ({ dispatch }) {
    return Promise.all([
      dispatch('baskets/clearLocalBasket', null, { root: true }),
      dispatch('users/setUserData', undefined, { root: true }),
      dispatch('clearAutoUpdateToken'),
      dispatch('setTokenData')
    ]);
  },

  onCloseTab ({ dispatch }) {
    window && window.addEventListener(
      'beforeunload',
      () => dispatch('clearAutoUpdateToken')
    );
  },

  clearAutoUpdateToken () {
    if (autoUpdateTokenTimer) {
      clearTimeout(autoUpdateTokenTimer);
      autoUpdateTokenTimer = null;
    }
  }
};

export const getters = {
  token: state => state.token,
  tokenEx: state => state.token_expires,
  isAuthenticated: state => state.token // && isActualToken(state.token_expires)
};
