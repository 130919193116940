<template>
  <div class="platforms-list-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('platform.choiceOfWorkingConditionsOnSites') }}
    </h1>

    <div class="text-ghost mb-4">
      <p>{{ $t('platform.platformListDescription') }}</p>
    </div>

    <TheLoader v-if="isLoading" />

    <div v-else class="outline-block">
      <div class="outline-block__title">
        {{ $t('platform.selectConditions') }}
      </div>

      <div class="content">
        <PlatformsTable
          class="mb-4"
          :value="platforms"
        />
      </div>
    </div>

    <el-row justify="end" class="mb-1">
      <div class="text-center w-350p">
        <el-button
          disabled
          type="primary"
          class="mb-1 w-100"
          @click="signContract"
        >
          {{ $t('buttons.signContract') }}
        </el-button>

        <div class="text-ghost">
          {{ $t('platform.signContractDescription') }}
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import PlatformsTable from './components/PlatformsTable';

export default {
  name: 'PlatformsListPage',

  middleware: ['development'],

  components: { PlatformsTable },

  data () {
    return {
      isLoading: true,
      platforms: []
    };
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      try {
        this.platforms = await this.$store.dispatch(
          'platforms/getPlatforms',
          {
            business: this.$store.getters['businessProfiles/businessId']
          }
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    signContract () {
      return this.$router.push({
        name: this.getRouteName('profile.business.platforms.contract')
      });
    }
  }
};
</script>
