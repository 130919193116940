<template>
  <el-row align="bottom" justify="center" :class="['the-price', size]">
    <span class="mr-05 prefix">
      <slot />
    </span>

    <span class="price mr-05">{{ getPriceNum(value) }}</span>

    <span class="currency">
      <span v-if="num">{{ currency || MC }}/{{ $t('core.num') }}</span>
      <span v-else>{{ currency || MC }}</span>
    </span>
  </el-row>
</template>

<script>
import { getPriceNum } from '../services/parsers';
import { env } from '@/lib/core';

export default {
  name: 'ThePrice',

  props: {
    currency: String,
    num: Boolean,
    value: { type: [String, Number], required: true },
    // medium, large, small
    size: { type: String, default: 'medium' }
  },

  data () {
    return {
      MC: this.$t(`currencies.${env.MARKETPLACE_CURRENCY}`)
    };
  },

  methods: {
    getPriceNum
  }
};
</script>

<style scoped lang="scss">
.the-price {
  width: fit-content;
  line-height: 1rem;
  color: $--color-text-regular;

  .price {
    font-size: 1rem;
    font-weight: bold;
  }

  &.mini {
    font-size: 13px;

    .price {
      font-size: 13px;
    }
  }

  &.large {
    font-size: 1.1rem;

    .price {
      font-size: 1.2rem;
    }
  }
}
</style>
