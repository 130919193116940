<template>
  <div class="price-edit-page">
    <TheLoader v-if="!isPageLoaded" />

    <template v-else>
      <el-row class="mb-2" justify="space-between" align="middle">
        <h1 class="custom-headline custom-headline--level2-bold">
          {{ price.price_file.name || price.filename }}
        </h1>

        <PriceActivator
          left
          :price-file="price.price_file"
          show-label
          @update="price.price_file.is_active = $event"
        />
      </el-row>

      <el-tabs type="card" :value="activeTab" @tab-click="setActiveTab">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab"
          :label="$t(`priceFile.${tab}Tab`)"
          :name="tab"
        >
          <component
            :is="`Price${upperFirst(tab)}Tab`"
            :key="key"
            ref="component"
            class="mb-4"
            entity-name="priceFile"
            :dependency="dependency"
            :entity="price.price_file"
            :submit="submit"
            @error:format="isFormatDialog = true"
            @page:update="updatePage"
            @price-file:update="updatePriceFile"
            @price:update="updatePrice"
            @reupload:file="isUploadDialog = true"
            @status:update="price.status = $event"
          />
        </el-tab-pane>
      </el-tabs>

      <PriceReUploadDialog
        v-if="price && isUploadDialog"
        :price="price"
        @cancel="isUploadDialog = false"
        @price:update="updatePrice"
        @status:update="updatePriceStatus"
      />

      <TheDialog
        :title="$t('prices.formatting')"
        :value="price && isFormatDialog"
        @cancel="isFormatDialog = false"
      >
        <PriceFormatForm
          :price="price"
          @cancel="isFormatDialog = false"
          @price:update="updatePrice"
        />
      </TheDialog>
    </template>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import priceEditMixin from './mixins/priceEditMixin';
import PriceSettingsTab from './components/PriceSettingsTab';
import PriceAssortmentTab from './components/PriceAssortmentTab';
import PriceProcessingTab from './components/PriceProcessingTab';

import { getClearFormData } from './services/getClearPriceFormData.js';
import { eventBus } from '@/lib/core';
import { debounce } from '@/lib/core/services/parsers';

import {
  getFilteredAvailabilitiesByMapping
} from '@/lib/prices/services/getClearPriceFormData';

export default {
  name: 'PriceEditPage',

  mixins: [priceEditMixin],

  components: {
    PriceSettingsTab,
    PriceAssortmentTab,
    PriceProcessingTab
  },

  data ({ $route }) {
    return {
      tabs: ['settings', 'assortment', 'processing'],
      activeTab: $route.query.tab || 'processing',
      warehouses: [],
      key: 0
    };
  },

  computed: {
    businessId () {
      return this.$store.getters['businessProfiles/businessId'].id;
    }
  },

  created () {
    eventBus.$on('warehouses:create', this.pushToWarehouse);
  },

  beforeDestroy () {
    eventBus.$off('warehouses:create', this.pushToWarehouse);
  },

  methods: {
    pushToWarehouse (warehouse) {
      this.warehouses.push(warehouse);
    },

    updatePage (data_sheet) {
      // TODO clear this spike, for update data preview
      const pageInfo = this.getCurrentPreview(data_sheet);
      this.dependency.price.data_preview = pageInfo.data_preview;
    },

    async loadWarehouses () {
      try {
        const pl = {
          business: this.businessId,
          price_file: this.price.price_file.id
        };

        this.warehouses = await this.$store.dispatch(
          'warehouses/getWarehouses',
          pl
        );
      } catch (e) {
        console.error(e);
      }
    },

    setActiveTab ({ name }) {
      this.$router.replace({
        query: { ...this.$route.query, tab: name }
      });
    },

    async submit (formData, originData) {
      const {
        isQuantityChanged,
        clearFormData: { availabilities, ...clearFormData }
      } = getClearFormData(formData, this.price);

      const { id, business, url } = this.price;

      let priceFile = null;

      try {
        if (!isEmpty(clearFormData)) {
          const pl = {
            id: this.price.price_file.id,
            formData: {
              ...clearFormData,
              business,
              upload: url
            }
          };

          priceFile = await this.$store.dispatch(
            'priceFiles/patchPrice',
            pl
          );
        }

        if (isQuantityChanged && !this.isCheckAvilibilities) {
          this.isCheckAvilibilities = true;

          eventBus.$emit('quantity:changed');

          // for save availabilities (view effect)
          await debounce(1000);

          this.$set(
            this.price,
            'price_file',
            this.getParsedPriceFile(priceFile)
          );

          return null;
        }

        if (formData.availabilities && formData.availabilities.length) {
          this.isCheckAvilibilities = false;

          if (this.isCheckAvilibilities) {
            this.isShowInfoCheckAvilibilities = true;
          }

          await this.saveAvailabilitiesByChuncks(
            formData,
            this.price,
            originData
          );
        }

        // push for processing price
        const pl = { push: true, id };

        const price = await this.$store.dispatch('priceUploads/patchPrice', pl);

        this.continueSubmit(price, formData, priceFile);
      } catch (e) {
        console.error(e);
      }
    },

    continueSubmit (price) {
      this.price = {
        ...price,
        price_file: this.getParsedPriceFile(price.price_file)
      };

      const { status, error_code } = price;

      if (status === 'error') {
        if (error_code === 'confirm_mapping' && !this.isConfirmMapping) {
          return this.confirmMapping(price);
        } else if (error_code === 'invalid') {
          return this.notifyInvalidMapping(price);
        }
      }

      if (this.isShowInfoCheckAvilibilities) {
        this.isShowInfoCheckAvilibilities = false;

        this.$notify.info({
          message: this.$t('message.checkChangedAvailabilities')
        });
      }

      // spike for fix component update after submit
      // there is some bug with element ui table update after update data in price file
      setTimeout(() => { this.key++; }, 200);
    },

    async saveAvailabilitiesByChuncks (formData, priceUpload, originData) {
      const updateList = getFilteredAvailabilitiesByMapping(
        formData,
        priceUpload,
        originData
      );

      const progress = 100 / updateList.length;
      const progressData = {
        percent: 0,
        code: 'priceAvailability',
        suffix: '%'
      };

      const update = async (index) => {
        const item = updateList[index];

        if (!item) {
          progressData.percent = 100;
          await this.$store.dispatch('setProgress', progressData);
          return null;
        } else {
          progressData.percent = progress * index;
          await this.$store.dispatch('setProgress', progressData);
        }

        try {
          const action = item.id ? 'patch' : 'post';
          const pl = {
            id: item.id,
            formData: {
              ...item,
              price_file: priceUpload.price_file.url
            }
          };

          await this.$store.dispatch(
            `priceFiles/${action}Availability`,
            pl
          );
        } catch (e) {
          console.error(e);
        }

        return update(index + 1);
      };

      try {
        await update(0);
        return formData.availabilities;
      } catch (e) {
        console.error(e);
      }
    }
  },

  validate ({ params }) {
    return /^\d+$/.test(params.id);
  }
};
</script>
