var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "garage-page" },
    [
      _c(
        "el-row",
        {
          staticClass: "mb-4",
          attrs: { justify: "space-between", align: "middle" },
        },
        [
          _c(
            "h1",
            { staticClass: "custom-headline custom-headline--level2-bold" },
            [_vm._v("\n      " + _vm._s(_vm.$t("routes.garage")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "success" },
                  on: {
                    click: function ($event) {
                      _vm.$router.push({
                        name: _vm.getRouteName("garage.create"),
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("buttons.addCar")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : !_vm.cars.length
        ? _c("el-row", { staticClass: "pg-2", attrs: { justify: "center" } }, [
            _vm._v("\n    " + _vm._s(_vm.$t("el.table.emptyText")) + "\n  "),
          ])
        : _c(
            "div",
            [
              _vm._l(_vm.cars, function (car) {
                return [
                  _c("CarFullInfoItem" + (_vm.maxmq("sm") ? "M" : ""), {
                    key: car.id,
                    tag: "component",
                    staticClass: "mb-4",
                    attrs: { value: car },
                    on: {
                      edit: _vm.toEditCar,
                      "request-new": _vm.onNewRequest,
                      "request-history": _vm.toRequestHistory,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: {
            width: "600px",
            title: _vm.$t("garage.requestToSelectionByVin"),
          },
          model: {
            value: _vm.isShowVinConfirm,
            callback: function ($$v) {
              _vm.isShowVinConfirm = $$v
            },
            expression: "isShowVinConfirm",
          },
        },
        [
          _vm.isShowVinConfirm
            ? _c("VinConfirmForm", {
                attrs: {
                  car: _vm.isShowVinConfirm,
                  submit: _vm.updateCarGoToRequest,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }