<template>
  <el-menu
    class="business-hot-keys"
    mode="horizontal"
    router
  >
    <el-menu-item
      v-for="route in routes"
      :key="route.name"
      :index="route.name"
      :route="{ name: getRouteName(route.name) }"
      router
    >
      <el-row align="middle">
        <img
          v-if="route.img"
          class="img icon"
          :src="route.img"
          :alt="route.title"
        >

        <i v-else :class="route.icon" />

        <span class="label">{{ $t(`routes.${route.title}`) }}</span>
      </el-row>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'BusinessHotKeys',

  data: () => ({
    routes: [
      {
        name: 'profile.business.s-requests',
        title: 'profile_business_s_requests',
        img: require('@/assets/icons/car-white.svg')
      },
      {
        name: 'profile.business.prices',
        title: 'profile_business_prices',
        icon: 'el-icon-tickets icon'
      },
      {
        name: 'profile.business.seller_orders',
        title: 'profile_business_seller_orders',
        icon: 'el-icon-document-checked icon'
      }
    ]
  })
};
</script>

<style scoped lang="scss">
.business-hot-keys {
  padding-top: 2px;

  &.el-menu--horizontal {
    border-bottom: none;

    .is-active {
      border-bottom-color: transparent!important;
      color: $--color-info;
    }
  }

  .el-menu-item {
    padding-right: 0;
  }

  i {
    margin: 0;
    padding-top: 2px;
  }

  .icon {
    width: 23px;
    height: 23px;
  }

  img {
    display: block;
    margin-right: 4px;
  }

  @media all and (max-width: $--md) {
    .label {
      display: none;
    }
  }
}
</style>
