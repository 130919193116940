<template>
  <div class="ware-main-params">
    <div v-show="!hideLabel" class="mb-1">
      <span class="custom-headline custom-headline--level3-bold">
        {{ $t('ware.characteristic') }}
      </span>
    </div>

    <ul class="p-char">
      <li class="p-char__item">
        <div class="p-char__name">{{ $t('ware.trademark') }}:</div>

        <div class="p-char__value">
          <nuxt-link class="custom-link" :to="getLineTrademarkLink(ware)">
            {{ get(ware, 'trademark.name', '') }}
          </nuxt-link>
        </div>
      </li>

      <li class="p-char__item">
        <div class="p-char__name">{{ $t('ware.country_name') }}:</div>
        <div class="p-char__value">
          {{ get(ware, 'trademark.country_name') }}
        </div>
      </li>

      <li v-for="(attr, index) in attrs" :key="index" class="p-char__item">
        <div class="p-char__name">{{ attr.name }}:</div>
        <div class="p-char__value">{{ attr.value }}</div>
      </li>

      <template v-if="ware.has_replacement_rel">
        <li v-if="ware.changed_from" class="p-char__item">
          <div class="p-char__name">{{ $t('search.changed_from') }}:</div>
          <div class="p-char__value">
            <el-link
              type="primary"
              @click.prevent="goToChanged(ware.changed_from)"
            >
              {{ ware.changed_from.article }}
            </el-link>
          </div>
        </li>

        <li v-if="ware.changed_to" class="p-char__item">
          <div class="p-char__name">{{ $t('search.changed_to') }}:</div>
          <div class="p-char__value">
            <el-link type="primary" @click.prevent="goToChanged(ware.changed_to)">
              {{ ware.changed_to.article }}
            </el-link>
          </div>
        </li>
      </template>
    </ul>

    <div class="p-char-info mt-2">
      {{ $t('ware.characteristicDescription1') }} <br>
      {{ $t('ware.characteristicDescription2') }}
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { parsers } from '@/lib/core';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';

export default {
  name: 'WareMainParams',

  props: {
    hideLabel: Boolean,
    ware: { type: Object, required: true }
  },

  computed: {
    attrs () {
      return (this.ware.attrs || []);
    }
  },

  methods: {
    get,

    goToChanged (changed) {
      this.$router.push(parsers.getSearchRouteBy(this, changed));
    },

    getLineTrademarkLink (ware) {
      const payload = {
        lineId: ware.product_line?.slug,
        trademarkId: ware.trademark?.slug
      };

      return getSeoLinkBy(payload, this, payload);
    }
  }
};
</script>

<style scoped lang="scss">
.ware-main-params {
  min-width: 300px;

  .p-char {
    padding: 0 10px;
  }

  .p-char__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed $grey-300;
    margin-bottom: 10px;

    div {
      margin-bottom: -2px;
      padding-bottom: 2px;
      background-color: $white;
    }
  }
}
</style>
