<template>
  <div class="upload-info">
    <el-row v-if="isPriceInQueueForUpdating">
      {{ $t('prices.priceInQueueForUpdating') }}
    </el-row>

    <el-row v-else :gutter="20" justify="space-between">
      <el-col>
        <div>
          <div>{{ $t('prices.uploadStatus') }}:</div>

          <el-tooltip
            v-model="isShowInfo"
            effect="light"
            enterable
            :open-delay="500"
            placement="bottom"
          >
            <div slot="content">
              <div v-if="isLoading">{{ $t('core.loading') }}...</div>

              <el-row v-else class="price-status-info" :gutter="20">
                <el-col class="info">
                  <div v-for="(value, key) in info" :key="key" class="item">
                    {{ $t(`priceStatusesFields.${key}`) }}:

                    <span v-if="['offers_refreshed', 'updated'].includes(key)">
                      {{ value | dateFilter }}
                    </span>

                    <span v-else>{{ value }}</span>
                  </div>

                  <div v-if="error">{{ error }}</div>
                </el-col>
              </el-row>
            </div>

            <span v-if="price.status"> {{ $t(`prices.statuses.${price.status}`) }}</span>
          </el-tooltip>
        </div>
        <div>{{ $t('prices.uploadSource') }}: {{ getSource(price.enable_auto_update) }}</div>
      </el-col>

      <el-col>
        <div>{{ $t('prices.lastUpdate') }}: {{ price.price_file.updated | dateFilter }}</div>
      </el-col>

      <el-col>
        <div>{{ $t('prices.fileSize', { size: price.file_size / 1000000 }) }}</div>
        <div>
          <el-link :href="price.download_url" type="primary">
            {{ $t('prices.downloadFile') }}
          </el-link>
        </div>
      </el-col>

      <el-col class="text-right">
        <PriceRePush
          v-if="price.price_file"
          :price-file="price.price_file"
          :readonly="readonly"
          @price-file:update="$emit('price-file:update', $event)"
        />
      </el-col>
    </el-row>

    <el-row class="mt-2">
      <PriceRemover :price="price" />
    </el-row>

    <el-row v-if="price.error_code" class="error mt-1" :gutter="20">
      <el-col>{{ price.error_code }} - {{ price.error_message }}</el-col>
    </el-row>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import PriceRePush from './PriceRePush';
import PriceRemover from './PriceRemover';

export default {
  name: 'UploadInfo',

  components: {
    PriceRePush,
    PriceRemover
  },

  props: {
    readonly: Boolean,
    price: { type: Object, required: true }
  },

  data: () => ({
    isShowInfo: false,
    info: null,
    error: null,
    isLoading: false,
    requestTimeout: null
  }),

  computed: {
    isPriceInQueueForUpdating () {
      return this.price.status === 'in_progress';
    }
  },

  watch: {
    isShowInfo (isShowInfo) {
      if (isShowInfo) {
        this.loadInfo();
      }
    },
    'price.status': {
      immediate: true,
      handler () {
        this.loadInfo();
      }
    }
  },

  beforeDestroy () {
    if (this.requestTimeout) {
      clearTimeout(this.requestTimeout);
    }
  },

  methods: {
    getSource (isEnableAutoUpdate) {
      return this.$t(`prices.uploadSources.${isEnableAutoUpdate ? 'auto' : 'handle'}`);
    },

    async loadInfo () {
      this.isLoading = true;

      try {
        this.error = null;
        this.info = null;

        const payload = { id: this.price.price_file.id };
        const info = await this.$store.dispatch('priceStatus/getPriceStatus', payload);

        if (isEmpty(info)) {
          this.error = this.$t('errors.price_status.empty');
          this.requestTimeout = setTimeout(this.loadInfo, 30000);
        } else {
          this.info = info[0];
        }
      } catch (e) {
        console.error(e);
        this.error = this.$t('errors.price_status.404');
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.upload-info {
  .el-link {
    font-size: 13px !important;
  }

  .price-re-push {
    margin: 4px 0 0;
  }
}
</style>
