<template>
  <div :class="[
    'delivery-time-string text-center',
    {
      [`color-${delivery.type}`]: !hideColor,
      'bold': delivery.bold && !hideBold,
      'text-ghost': delivery.ghost
    }
  ]"
  >
    <div v-if="delivery.warning">{{ $t('core.warning') }}</div>

    <time v-if="delivery.datetime" :datetime="delivery.datetime">
      {{ delivery.value }}
    </time>

    <div v-else>{{ delivery.value }}</div>
  </div>
</template>

<script>
import { parsers } from '@/lib/core';

export default {
  name: 'DeliveryTimeString',

  props: {
    hideColor: Boolean,
    hideBold: Boolean,
    value: String,
    options: { type: Object, default: () => ({}) }
  },

  computed: {
    delivery () {
      return parsers.getGetInformDeliveryTime(this.value, this.options);
    }
  }
};
</script>
