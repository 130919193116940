import { isActualToken } from '@/store/auth';

export default {
  async beforeMount () {
    if (!this.$store.getters.isAppLoaded) {
      try {
        return await Promise.all([
          this.$store.dispatch('search/fetchMapSearchSettings'),
          this.$store.dispatch('auth/onCloseTab'),
          this.$store.dispatch('users/loadProfile')
        ]);
      } catch (e) {
        console.error(e);
      }
    }
  },

  mounted () {
    // It is for page speed. Show components after first content loaded
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        return this.$store.dispatch('setDomReadyState', true);
      }
    };

    if (!this.$store.getters.isAppLoaded) {
      setTimeout(async () => {
        await this.$store.dispatch('users/loadProfile');

        const promises = [
          this.setAnalyticsData(),
          this.$store.dispatch('fetchCity')
        ];

        if (this.$route.meta?.layoutLoadBasket !== false) {
          promises.push(this.$store.dispatch('baskets/fetchBasket'));
        }

        if (isActualToken(this.$store.getters['auth/tokenEx'])) {
          promises.push(this.$store.dispatch('auth/autoUpdateToken'));
        }

        await Promise.all(promises);

        await this.$store.dispatch('setAppLoaded', true);
      }, 0);
    }

    this.startBinotelWatcher();
  },

  beforeDestroy () {
    this.binotelWatcher && this.binotelWatcher();
  },

  methods: {
    async setAnalyticsData () {
      await this.$store.dispatch('analytics/checkAndSetGtags', this.$route.query);
      return this.$store.dispatch('analytics/setLocationToGtag');
    },

    startBinotelWatcher () {
      window.binotelWatcher && window.binotelWatcher();

      window.binotelWatcher = this.$watch(
        () => [this.$route.name, this.$store.getters['businessProfiles/businessId']],
        this.toggleBinotel,
        { immediate: true }
      );
    },

    toggleBinotel () {
      setTimeout(() => {
        const el1 = document.querySelector('#bingc-passive');
        const el2 = document.querySelector('#bingc-active');
        const el3 = document.querySelector('#bingc-phone-button');

        const isHidden = !this.$route.meta?.seoName ||
          this.$store.getters['businessProfiles/businessId'];

        [el1, el2, el3].forEach((el) => {
          if (el) {
            el.style.visibility = isHidden ? 'hidden' : 'visible';
          }
        });
      }, 500);
    }
  }
};
