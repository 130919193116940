var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "seller-order-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("routes.profile_business_seller_orders_order")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isLoader
        ? _c("TheLoader")
        : [
            _c("OrderInfo", {
              staticClass: "mb-1",
              attrs: { value: _vm.order },
            }),
            _vm._v(" "),
            _c("SellerOrderItemsTable", {
              staticClass: "mb-2",
              attrs: {
                "hide-bottom-status-select": "",
                "prop-value": _vm.items,
              },
              on: { "item:update": _vm.updateItem },
            }),
            _vm._v(" "),
            _vm.order.no_call_required || _vm.order.comment
              ? _c("div", { staticClass: "underline-block" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("basket.commentForm"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.order.comment)),
                  ]),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }