var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      staticClass: "price-remover",
      attrs: {
        plain: "",
        size: "mini",
        type: "danger",
        loading: _vm.isLoading,
        disabled: _vm.readonly,
      },
      on: { click: _vm.onRemove },
    },
    [_vm._v("\n  " + _vm._s(_vm.$t("buttons.removePrice")) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }