var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wares-tile" },
    [
      _c("div", { staticClass: "mr-table" }, [
        _vm.isShowOrdering
          ? _c(
              "div",
              { staticClass: "mr-table-header" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.maxmq("xs"),
                        expression: "!maxmq('xs')",
                      },
                    ],
                    staticClass: "mr-1",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("search.doSort")) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button-group",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type:
                            _vm.activeOrdering === "price,-rank"
                              ? "primary"
                              : null,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleOrdering("price,-rank")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("search.lowerCost")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type:
                            _vm.activeOrdering === "-price,-rank"
                              ? "primary"
                              : null,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleOrdering("-price,-rank")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("search.higherCost")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type:
                            _vm.activeOrdering === "-rank" ? "primary" : null,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleOrdering("-rank")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("search.higher")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "section",
          [
            !_vm.analogsRes.results.length
              ? _c(
                  "el-row",
                  {
                    staticClass: "pg-2 text-center color-default",
                    attrs: { justify: "center" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("search.emptyResult")) +
                        "\n      "
                    ),
                  ]
                )
              : _c(
                  "ul",
                  { staticClass: "list" },
                  _vm._l(_vm.analogsRes.results, function (ware) {
                    return _c(
                      "li",
                      { key: ware.id },
                      [
                        _c("WareSmallCard", {
                          attrs: { value: ware },
                          on: {
                            "basket-click": function ($event) {
                              return _vm.$emit("basket-click", $event)
                            },
                            "sale-point:info": function ($event) {
                              return _vm.$emit("sale-point:info", $event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mt-2", attrs: { justify: "center" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "hide-on-single-page": "",
              "use-seo-link": "",
              layout: "prev, pager, next",
              "current-page": _vm.page,
              "page-size": _vm.PAGINATION_SIZE,
              "pager-count": 5,
              total: _vm.analogsRes.count - 1,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }