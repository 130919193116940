<template>
  <GoogleLogin
    class="google-btn google"
    :on-failure="onFailure"
    :on-success="onSuccess"
    :params="params"
  >
    <img alt="Google" class="icon-img" src="@/assets/img/google1.png">
    <span>{{ $t('buttons.google') }}</span>
  </GoogleLogin>
</template>

<script>
import GoogleLogin from 'vue-google-login';
import env from '@/lib/core/services/envConstants';

export default {
  name: 'GoogleBtn',

  components: {
    GoogleLogin
  },

  data: () => ({
    params: {
      client_id: env.SOCIAL_AUTH_GOOGLE_OAUTH2_KEY,
      scope: 'openid profile email'
    }
  }),

  methods: {
    async onSuccess (googleUser) {
      try {
        const user = await this.$store.dispatch('auth/loginBySocial', {
          provider: 'google-oauth2',
          access_token: googleUser.getAuthResponse().access_token
        });

        this.$emit('registered', user);
      } catch (e) {
        console.error(e);
      }
    },

    onFailure (error) {
      console.error('onError', error);
    }
  }
};
</script>

<style scoped lang="scss">
.google {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 34px;
  width: 100%;
  text-align: center;
  font-size: 10px;
  background-color: $grey-200;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  .icon-img {
    margin-right: 12px;
  }
}
</style>
