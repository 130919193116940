var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        staticClass: "contacts-block",
        attrs: {
          "entity-name": "salePoint",
          fields: ["phone_numbers", "emails"],
          readonly: _vm.readonly,
          rules: ["phone_numbers", "phone_number", "email"],
          submit: _vm.submitContacts,
          value: _vm.contacts,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var formData = ref.formData
              var disabled = ref.disabled
              var validationRules = ref.validationRules
              return [
                _c(
                  "Contacts",
                  _vm._b(
                    {
                      attrs: {
                        readonly: disabled,
                        rules: validationRules,
                        value: formData,
                      },
                    },
                    "Contacts",
                    formData,
                    false,
                    true
                  )
                ),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }