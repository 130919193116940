<template>
  <div class="doc-uploader">
    <el-upload
      ref="upload"
      action="https://mp-api.dev.mriyar.ua/"
      :auto-upload="false"
      class="mb-1"
      drag
      :on-change="changeHandler"
      :on-remove="clear"
      :show-file-list="!hideList"
    >
      <div class="el-upload__text">{{ label || $t('files.dropOrClick') }}</div>
    </el-upload>
  </div>
</template>

<script>
import ElUpload from 'element-ui/lib/upload';
import { library } from '@/lib/core';

const formats = library.salePointFileFormats;

export default {
  name: 'DocUploader',

  components: { ElUpload },

  props: {
    value: null,
    label: String,
    hideList: Boolean
  },

  methods: {
    changeHandler (file) {
      if (
        !formats.includes(file.raw.type) &&
        (!file.raw.type && !this.testName(file.name))
      ) {
        this.$notify.error({
          message: this.$t('validation.price.format', { format: formats.toString() })
        });

        this.$refs.upload.clearFiles();
      } else {
        this.$set(this.$refs.upload, 'uploadFiles', [file]);
        this.emit(file.raw);
      }
    },

    emit (value) {
      this.$emit('input', value);
    },

    clear () {
      this.$emit('input', null);
    },

    testName (name) {
      return formats.some((format) => {
        // eslint-disable-next-line
        return (new RegExp(`(\.${format})$`).test(name));
      });
    }
  }
};
</script>

<style scoped lang="scss">
.doc-uploader {
  ::v-deep .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
      height: auto;
      padding: 10px;
    }
  }
}
</style>
