<template>
  <div class="schedule">
    <el-form-item
      class="label-to-top"
      prop="schedule"
      :label="$t('labels.workDays')"
    >
      <el-row class="main-row">
        <div class="w-350p mb-2 mr-2">
          <el-row
            v-if="!hideLabel"
            align="middle"
            class="w-100"
            :gutter="4"
          >
            <el-col :span="6" class="el-form-item__label">
              {{ $t('labels.workDays') }}
            </el-col>
            <el-col :span="18" />
          </el-row>

          <el-row
            v-for="key in days"
            :key="key"
            align="middle"
            :gutter="4"
            justify="start"
            type="flex"
          >
            <el-col :span="6">
              <el-checkbox
                :disabled="readonly"
                false-label="null"
                true-label="true"
                :value="formData[key] ? 'true' : 'false'"
                @mousedown.native="toggleDay(key, formData)"
              >
                {{ $t(`el.datepicker.weeks.${key.slice(0, 3)}`) }}
              </el-checkbox>
            </el-col>

            <el-col :span="18">
              <el-time-picker
                v-model="formData[key]"
                :default-value="defaultTime"
                :disabled="!formData[key] || readonly"
                :end-placeholder="$t('labels.endWork')"
                format="HH:mm"
                is-range
                :range-separator="$t('labels.timeSeparator')"
                :start-placeholder="$t('labels.startWork')"
              />
            </el-col>
          </el-row>
        </div>

        <div>
          <el-button
            v-if="!readonly"
            type="primary"
            plain
            @click="copyTime(formData)"
          >
            {{ $t('buttons.copyTimeToAllDays') }}
          </el-button>
        </div>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import ElTimePicker from 'element-ui/lib/time-picker';

import { defaultEntities, getDefault } from '@/lib/core/services/defaultEntities';
import { getDateFromTime, getTime } from '@/lib/core/services/filters';

export default {
  name: 'Schedule',

  components: {
    ElTimePicker
  },

  props: {
    hideLabel: Boolean,
    readonly: Boolean,
    value: Object
  },

  data: () => ({
    days: Object.keys(defaultEntities.schedule),
    defaultTime: getDefault('time'),
    formData: []
  }),

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (value) {
        this.formData = this.parseFrom(value || {});
      }
    },

    formData: {
      deep: true,
      handler (formData) {
        if (!isEqual(this.parseFrom(this.value || {}), formData)) {
          this.parseToAndEmit(formData);
        }
      }
    }
  },

  methods: {
    toggleDay (day, schedule) {
      this.$set(schedule, day, schedule[day] ? null : getDefault('time'));
    },

    copyTime (formData) {
      const time = Object.values(formData).find(Boolean);
      this.days.forEach(day => this.$set(formData, day, time));
    },

    parseFrom (schedule) {
      return this.days.reduce((acc, day) => {
        if (schedule[day]) {
          const _from = getDateFromTime(schedule[day].from);
          const _to = getDateFromTime(schedule[day].to);

          acc[day] = [_from, _to];
        } else {
          acc[day] = null;
        }

        return acc;
      }, {});
    },

    parseToAndEmit (formData) {
      const schedule = this.days.reduce((acc, day) => {
        if (formData[day]) {
          const _from = getTime(formData[day][0], { milliseconds: true });
          const _to = getTime(formData[day][1], { milliseconds: true });

          acc[day] = { from: _from, to: _to };
        }

        return acc;
      }, {});

      this.$emit('input', schedule);
    }
  }
};
</script>

<style scoped lang="scss">
.schedule {
  .el-date-editor {
    width: 100%;
  }

  @media all and (max-width: $--sm) {
    .main-row {
      flex-direction: column;
    }

    .controls {
      margin-bottom: 20px;
    }
  }
}
</style>
