var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["audio-preview", { "mini-audio": _vm.previewIcon }] },
    [
      !_vm.previewIcon
        ? _c("audio", { ref: "audio", attrs: { controls: "" } }, [
            _c("source", { attrs: { src: _vm.value.url } }),
          ])
        : _c("img", {
            attrs: { src: require("@/assets/fileTypes/audio-icon.svg") },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }