import errorHandler from '@/plugins/errorHandler';

export default function ({ store }) {
  if (!store.getters['dependencies/loaded']) {
    try {
      return store.dispatch('dependencies/loadAll');
    } catch (e) {
      console.error(e);
      errorHandler({ e });
    }
  }
}
