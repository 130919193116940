import {
  CREATE_VIN_REQUEST_MASTER,
  PROCESS_VIN_REQUEST_MASTER,
  GET_VIN_REQUEST,
  GET_VIN_REQUEST_RESPONSE,
  GET_VIN_REQUEST_RESPONSE_BY_HASH,
  GET_VIN_REQUESTS
} from '../schemes/vinRequest';
import { env } from '@/lib/core';

const getUserId = ({ rootGetters }) => {
  const business = rootGetters['businessProfiles/businessId'];
  const person = rootGetters['users/userId'];
  const res = {};

  if (business) {
    res.business = business;
  } else if (person) {
    res.person = person;
  }

  return res;
};

export const actions = {
  async getVinRequestsQL ({ rootGetters }, { car } = {}) {
    const { data } = await this.$apollo.query({
      query: GET_VIN_REQUESTS,
      fetchPolicy: 'network-only',
      variables: {
        car,
        ...getUserId({ rootGetters })
      }
    });

    return data.vinRequests;
  },

  async postVinRequestMasterQL ({ rootGetters }, { formData }) {
    const { data } = await this.$apollo.mutate({
      mutation: CREATE_VIN_REQUEST_MASTER,
      variables: {
        input: {
          ...formData,
          person: rootGetters['users/userId'] || undefined,
          business: rootGetters['businessProfiles/businessId'] || undefined
        }
      }
    });

    return data.createVinRequestMaster?.vinRequestMaster;
  },

  async processVinRequestMasterQL ({ rootGetters }, { id }) {
    const { data } = await this.$apollo.mutate({
      mutation: PROCESS_VIN_REQUEST_MASTER,
      variables: { processVinRequestMasterId: id }
    });

    return data.processVinRequestMaster.vinRequest;
  },

  async getVinRequestQL (_, { id, response }) {
    const { data } = await this.$apollo.query({
      query: response ? GET_VIN_REQUEST_RESPONSE : GET_VIN_REQUEST,
      variables: { vinRequestId: id }
    });

    return data.vinRequest;
  },

  async getVinRequestByHashQL (_, { hashId }) {
    const { data } = await this.$apollo.query({
      query: GET_VIN_REQUEST_RESPONSE_BY_HASH,
      variables: { hashId }
    });

    return data.vinRequestByHashId;
  },

  async getCommonList (_, params = { ordering: '-created' }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.GARAGE_API,
      url: '/selection-requests/common/',
      params
    });

    return data;
  },

  async getRequestCommon (_, { id }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.GARAGE_API,
      url: `/selection-requests/${id}/common/`
    });

    return data;
  }
};
