<template>
  <div class="contact-requisites-block">
    <el-form-item
      :label="$t('requisites.contact.address')"
      prop="actual_address"
      required
    >
      <el-tooltip
        v-if="value.type === 'juridical_person'"
        class="copy-block"
        :content="$t('requisites.contact.copy')"
        effect="dark"
        placement="top-start"
      >
        <i class="el-icon-copy-document" @click="copy" />
      </el-tooltip>

      <Address
        hide-description-input
        hide-label
        hide-map
        hide-no-address
        has-physical-address
        prop="actual_address"
        :value="value.actual_address"
        v-on="onAddressUpdate"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.contact.email')"
      prop="email.email"
    >
      <el-input
        class="max-w-182p"
        type="email"
        :value="value.email.email"
        @input="setField('email', $event)"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.contact.phone')"
      prop="phone_number.phone_number"
    >
      <PhoneInput
        class="max-w-182p"
        :value="value.phone_number.phone_number"
        @input="setField('phone_number', $event)"
      />
    </el-form-item>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Address from '@/lib/salePoints/components/formItems/Address';
import PhoneInput from '@/lib/core/components/PhoneInput';

export default {
  name: 'ContactRequisitesBlock',

  components: { Address, PhoneInput },

  props: {
    value: { type: Object, required: true }
  },

  computed: {
    keys () {
      return Object.keys(this.value?.actual_address || {});
    },

    onAddressUpdate () {
      return this.keys.reduce((acc, name) => {
        acc[`update:${name}`] = val => this.emitAddress(name, val);
        return acc;
      }, {});
    }
  },

  methods: {
    emitAddress (name, val) {
      const value = { ...this.value.actual_address, [name]: val };
      this.emit('actual_address', value);
    },

    copy () {
      this.emit('actual_address', cloneDeep(this.value.juridical_address));
    },

    setField (name, val) {
      this.emit(name, { ...this.value[name], [name]: val });
    },

    emit (name, value) {
      this.$emit(`update:${name}`, value);
    }
  }
};
</script>

<style scoped>
.copy-block {
  position: absolute;
  margin-left: -30px;
  margin-top: 30px;
  cursor: pointer;
}
</style>
