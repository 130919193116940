<template>
  <div class="seller-order-items-table">
    <div v-if="localError && load" class="mb-2">
      <el-button size="mini" type="primary" @click="reload">
        {{ $t('buttons.searchAgain') }}
      </el-button>
    </div>

    <el-row align="middle" class="mb-2" justify="end">
      <StatusCycleHelp />

      <el-select
        v-model="selectedStatusCollection"
        class="mr-1"
        clearable
        :placeholder="$t('placeholders.setStatus')"
        size="mini"
      >
        <el-option
          v-for="(value, status) in groupedByStatus"
          :key="status"
          :disabled="value.disabled"
          :label="status ? `${$t(`orderStatuses.${status}`)} (${value.items.length})` : ''"
          :value="status"
        >
          {{ $t(`orderStatuses.${status}`) }} ({{ value.items.length }})
        </el-option>
      </el-select>

      <el-button
        :disabled="!selectedStatusCollection"
        size="mini"
        type="primary"
        @click="setNextStatusForGroup(selectedStatusCollection)"
      >
        {{ $t('buttons.apply') }}
      </el-button>
    </el-row>

    <el-table
      :cell-style="{ textAlign: 'center' }"
      :data="value"
      :header-cell-style="{ textAlign: 'center' }"
      @row-click="$emit('rowClick', $event)"
    >
      <el-table-column :label="$t('basket.index')" type="index" width="50" />

      <el-table-column
        min-width="200px"
        prop="_ware"
        :label="$t('search.nomenclature')"
      >
        <template #default="{ row }">
          <el-row class="article-row">
            <div class="mr-1">
              <strong class="text-ellipsis">{{ row.display_trademark }}</strong>
            </div>

            <div class="article-title">
              <div class="mr-1">{{ row.display_article }}</div>
            </div>
          </el-row>

          <el-row>
            <div class="small">{{ row.display_title }}</div>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column
        prop="delivery_time"
        width="100px"
        :label="$t('basket.delivery_time')"
      >
        <template #default="{ row }">
          <div>{{ row._deliveryDate }}</div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('basket.quantity')" prop="quantity" width="100px" />

      <el-table-column
        :label="`${$t('search.price')}, ${MARKETPLACE_CURRENCY}`"
        prop="price"
        width="100px"
      >
        <template #default="{ row }">
          <div>{{ row._priceSum }}</div>
          <div class="small">× {{ row._price }}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="priceFile"
        width="150px"
        :label="$t('basket.priceFile')"
      >
        <template #default="{ row }">
          <el-row align="middle" justify="center">
            <div class="text-ellipsis pg-0-1">
              <TheTooltip :content="row._priceFileName">
                <span>{{ row._priceFileName }}</span>
              </TheTooltip>
            </div>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column
        prop="shipment_detail"
        width="150px"
        :label="$t('basket.shipment')"
      >
        <template #default="{ row }">
          <el-row class="text-ellipsis" justify="space-between">
            <div />

            <div v-if="hasWaybill(row)" class="text-ellipsis">
              <TheTooltip
                v-if="row.shipment_detail.waybill"
                :content="row.shipment_detail.waybill"
              >
                <span>{{ row.shipment_detail.waybill }}</span>
              </TheTooltip>

              <br>

              <span v-if="row.shipment_detail.waybill_date" class="small">
                {{ row._waybillDate }}
              </span>
            </div>

            <div v-else>-</div>

            <div>
              <img
                v-if="row.shipment_detail"
                class="icon-img c-pointer"
                src="@/assets/icons/information-variant-primary.svg"
                @click="showShipmentInfo(row)"
              >
            </div>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column :label="$t('basket.status')" width="200px">
        <template #default="{ row }">
          <el-row justify="center" class="color-green">
            {{ row.is_paid ? $t('basket.is_paid') : '' }}
          </el-row>

          <OrderStatusToggle
            :value="row"
            :can-set-next-status="canSetNextStatus"
            :get-next-status="getNextStatus"
            :set-next-status="setNextStatus"
          />

          <div v-if="canCancel(row.status)" @click="itemFormCancel = row">
            <TheTooltip :content="$t('buttons.cancelOrderItem')">
              <i class="el-icon-circle-close c-pointer" />
            </TheTooltip>
          </div>
        </template>
      </el-table-column>

      <el-table-column width="40px">
        <template #header>
          <el-checkbox v-model="isAllChecked" />
        </template>

        <template #default="{ row }">
          <el-checkbox
            :disabled="orderItemEndStatuses.includes(row.status)"
            :value="checkedIds.includes(row.id)"
            @change="toggleItem(row, $event)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-row v-if="!hideBottomStatusSelect" align="middle" class="mt-2" justify="end">
      <StatusCycleHelp />

      <el-select
        v-model="selectedStatusCollection"
        class="mr-1"
        clearable
        :placeholder="$t('placeholders.setStatus')"
        size="mini"
      >
        <el-option
          v-for="(value, status) in groupedByStatus"
          :key="status"
          :disabled="value.disabled"
          :label="status ? `${$t(`orderStatuses.${status}`)} (${value.items.length})` : ''"
          :value="status"
        >
          {{ $t(`orderStatuses.${status}`) }} ({{ value.items.length }})
        </el-option>
      </el-select>

      <el-button
        :disabled="!selectedStatusCollection"
        size="mini"
        type="primary"
        @click="setNextStatusForGroup(selectedStatusCollection)"
      >
        {{ $t('buttons.apply') }}
      </el-button>
    </el-row>

    <el-row v-if="usePage && propCount > PAGE_SIZE" class="mt-2" justify="center">
      <el-pagination
        background
        :current-page.sync="page"
        layout="prev, pager, next"
        :page-size="PAGE_SIZE"
        :total="propCount"
      />
    </el-row>

    <TheDialog
      v-model="itemFormCancel"
      width="600px"
      :title="$t('basket.rejectOrderProcessing')"
    >
      <OrderCancelConfirm
        :value="itemFormCancel"
        @cancel="itemFormCancel = null"
        @item:update="emitCancel"
      />
    </TheDialog>

    <ShipmentFormDialog
      v-if="!!forShipments"
      :value="forShipments"
      @cancel="forShipments = null"
      @created="updateItemsByShipment"
    />

    <TheDialog
      v-model="shipmentInfo"
      width="600px"
      :title="$t('basket.orderShipment')"
    >
      <ShipmentInfo
        v-if="shipmentInfo"
        :id="shipmentInfo"
        @cancel="shipmentInfo = null"
      />
    </TheDialog>
  </div>
</template>

<script>
import StatusCycleHelp from './StatusCycleHelp';
import SellerOrdersTable from './SellerOrdersTable';
import ShipmentFormDialog from './shipment/ShipmentFormDialog';

export default {
  name: 'SellerOrderItemsTable',

  extends: SellerOrdersTable,

  components: {
    StatusCycleHelp,
    ShipmentFormDialog
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/components/order-tables.scss';
</style>
