<template>
  <div class="availabilities">
    <ul class="description mb-2">
      <li>
        {{ $t('priceFile.availabilitiesDescription') }}
        <i class="el-icon-date" />
      </li>
      <li>{{ $t('priceFile.availabilitiesDescription2') }} </li>
    </ul>

    <div class="mb-4">
      <el-form-item
        class="rate mb-1"
        :label="$t('deliveryRate.askAddRate')"
        label-width="0"
      >
        <el-radio-group :value="isDeliveryRate" @input="toggleDeliveryRate">
          <el-radio class="mb-1" :label="false">
            {{ $t('deliveryRate.notUseRate') }}
          </el-radio>
          <el-radio :label="true">
            {{ $t('deliveryRate.addByRate') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <AvailabilitiesRate v-if="isDeliveryRate" v-model="rateUrl" />
    </div>

    <RateWarehouseTables
      :dependency="{ ...dependency, rateUrl }"
      :value="value"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import get from 'lodash/get';
import RateWarehouseTables from '../RateWarehouseTables';
import AvailabilitiesRate from '../AvailabilitiesRate';

export default {
  name: 'Availabilities',

  components: {
    AvailabilitiesRate,
    RateWarehouseTables
  },

  props: {
    value: { type: Array, default: () => [] },
    dependency: { type: Object, required: true }
  },

  data () {
    return {
      isDeliveryRate: !!get(this.value, '0.delivery_rate', false),
      activeBlock: null
    };
  },

  computed: {
    rateUrl: {
      get () {
        return get(this.value, '0.delivery_rate', null);
      },

      set (rate) {
        const value = this.value.map(item => ({ ...item, delivery_rate: rate }));
        this.$emit('update:availabilities', value);
      }
    }
  },

  methods: {
    checkDeliveryRate () {
      this.isDeliveryRate = !!get(this.value, '0.delivery_rate', false);
    },

    toggleDeliveryRate (val) {
      this.isDeliveryRate = val;

      if (!val) {
        this.rateUrl = null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.availabilities {
  width: 100%;

  .description li {
    line-height: 1rem;
    margin-left: 20px;
    list-style-type: disc;
  }

  .rate {
    display: flex;
    flex-direction: column;

    /deep/ .el-form-item__label {
      width: 100% !important;
      text-align: left;
      margin-bottom: 10px;
    }

    /deep/ .el-form-item__content {
      margin-left: 20px !important;
    }
  }
}
</style>
