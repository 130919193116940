export const actions = {
  getWarehouses (_, params) {
    return this.$axios.$get('/warehouses/', { params });
  },

  postWarehouse (_, formData) {
    return this.$axios.$post('/warehouses/', formData);
  },

  patchWarehouse (_, { id, formData }) {
    return this.$axios.$patch(`/warehouses/${id}/`, formData);
  },

  removeWarehouse (_, { id }) {
    return this.$axios.$delete(`/warehouses/${id}`);
  }
};
