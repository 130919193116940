var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-articles-page",
      on: {
        click: function ($event) {
          _vm.isOrdering = false
        },
      },
    },
    [
      _c(
        "el-row",
        { attrs: { justify: "space-between", align: "middle" } },
        [
          _c(
            "div",
            [
              !_vm.isMobile && _vm.ware
                ? _c("WareTitle", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isWareLoading,
                        expression: "isWareLoading",
                      },
                    ],
                    attrs: {
                      "use-br": _vm.isMobile,
                      "double-name": _vm.isMobile,
                      ware: _vm.ware,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isMobile && !_vm.isArticlesLoading && !_vm.isEmptyResult
            ? _c(
                "el-row",
                { attrs: { align: "middle" } },
                [
                  _vm.tab === "map"
                    ? [
                        _c("el-button", {
                          attrs: {
                            circle: "",
                            icon: "el-icon-setting",
                            round: "",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              _vm.isSearchSettings = true
                            },
                          },
                        }),
                        _vm._v(" "),
                        !_vm.$route.query.waa && !_vm.isMobile
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "description map-description ml-1 mr-1 bold",
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t(
                                        "search." +
                                          _vm.mapSearchSettings.limit_by_order_by.replace(
                                            /,/g,
                                            "-"
                                          )
                                      )
                                    ) +
                                    ".\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button-group",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-s-grid",
                            size: "mini",
                            type: _vm.tab === "tables" ? "primary" : "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.tab = "tables"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.likeList")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-map-location",
                            size: "mini",
                            type: _vm.tab === "map" ? "primary" : "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.tab = "map"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.onMap")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isArticlesLoading
        ? _c("TheLoader")
        : _c(
            "el-container",
            [
              _vm.isMobile
                ? _c(
                    "el-header",
                    { staticClass: "pg-0", attrs: { height: "fit-content" } },
                    [
                      _vm.ware
                        ? _c("WareShortInfo", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.isWareLoading,
                                expression: "isWareLoading",
                              },
                            ],
                            staticClass: "mb-2",
                            attrs: { "is-mobile": "", row: "", ware: _vm.ware },
                            on: { "info:show": _vm.showFullInfo },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "filters-btns",
                          attrs: { align: "middle", justify: "space-between" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "fl-row mr-2" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "fl-row mr-1",
                                  on: {
                                    click: function ($event) {
                                      _vm.isFilter = !_vm.isFilter
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("search.filter")) +
                                      "\n            "
                                  ),
                                  _c("img", {
                                    staticClass: "icon-img ml-05",
                                    attrs: {
                                      src: require("@/assets/icons/filter.svg"),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("CustomSelect", {
                                attrs: { label: _vm.$t("search.order") },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "menu",
                                      fn: function (ref) {
                                        var isMenu = ref.isMenu
                                        return [
                                          isMenu
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "menu__item",
                                                      {
                                                        "is-active":
                                                          _vm.ordering[0] ===
                                                          "delivery_time",
                                                      },
                                                    ],
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.toggleOrdering(
                                                          [
                                                            "delivery_time",
                                                            "-rank",
                                                          ]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t("search.term")
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "menu__item",
                                                      {
                                                        "is-active":
                                                          _vm.ordering[0] ===
                                                          "price",
                                                      },
                                                    ],
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.toggleOrdering(
                                                          ["price", "-rank"]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t("search.price")
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1343724788
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "buttons-container",
                              attrs: { align: "middle" },
                            },
                            [
                              _c("div", { staticClass: "mr-05" }, [
                                _vm._v(_vm._s(_vm.$t("search.look"))),
                              ]),
                              _vm._v(" "),
                              _vm.tab === "map"
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.isSearchSettings = true
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon-img ml-05",
                                        attrs: {
                                          src: require("@/assets/icons/icon-setting.svg"),
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "active-svg",
                                  on: {
                                    click: function ($event) {
                                      _vm.tab = "tables"
                                    },
                                  },
                                },
                                [
                                  _vm.tab === "tables"
                                    ? _c("img", {
                                        staticClass: "icon-img ml-05",
                                        attrs: {
                                          src: require("@/assets/icons/list-view__active.svg"),
                                        },
                                      })
                                    : _c("img", {
                                        staticClass: "icon-img ml-05",
                                        attrs: {
                                          src: require("@/assets/icons/list-view.svg"),
                                        },
                                      }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.tab = "map"
                                    },
                                  },
                                },
                                [
                                  _vm.tab === "map"
                                    ? _c("img", {
                                        staticClass: "icon-img ml-05",
                                        attrs: {
                                          src: require("@/assets/icons/map-view__active.svg"),
                                        },
                                      })
                                    : _c("img", {
                                        staticClass: "icon-img ml-05",
                                        attrs: {
                                          src: require("@/assets/icons/map-view.svg"),
                                        },
                                      }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-container",
                [
                  !_vm.isArticlesLoading && !_vm.isMobile
                    ? _c(
                        "el-aside",
                        {
                          staticClass: "filter-block",
                          attrs: { width: "260px" },
                        },
                        [
                          _vm.ware
                            ? _c("WareShortInfo", {
                                staticClass: "mb-2",
                                attrs: { ware: _vm.ware },
                                on: { "info:show": _vm.showFullInfo },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("SearchFilter", { attrs: { "is-visible": "" } }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "el-main pg-0" },
                    [
                      _vm.isEmptyResult
                        ? _c("EmptySearchResult", {
                            attrs: {
                              params: _vm.$route.params,
                              ware: _vm.ware,
                            },
                          })
                        : [
                            _c(
                              "keep-alive",
                              [
                                _c("SearchArticlesTable", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tab === "tables",
                                      expression: "tab === 'tables'",
                                    },
                                  ],
                                  ref: "table",
                                  class: { "is-mobile": _vm.isMobile },
                                  attrs: {
                                    "analogs-res": _vm.analogsRes,
                                    "selected-articles-res":
                                      _vm.selectedArticlesRes,
                                    ordering: _vm.ordering,
                                  },
                                  on: {
                                    "basket-click": _vm.submitOrder,
                                    "info:show": _vm.showFullInfo,
                                    "sale-point:info": function ($event) {
                                      _vm.salePointInfo = $event
                                    },
                                    "update:page": _vm.updatePage,
                                    "toggle-ordering": _vm.toggleOrdering,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "keep-alive",
                              [
                                _vm.tab === "map" && !_vm.isArticlesLoading
                                  ? _c("WareSalePointsMap", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.tab === "map",
                                          expression: "tab === 'map'",
                                        },
                                      ],
                                      ref: "map",
                                      attrs: { trademarks: _vm.trademarkById },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _vm.isMobile
        ? _c("SearchFilterM", {
            attrs: { visible: _vm.isFilter },
            on: {
              close: function ($event) {
                _vm.isFilter = false
              },
              submitted: function ($event) {
                _vm.isFilter = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.getters.isAppLoaded
        ? [
            _c(
              "TheDialog",
              {
                model: {
                  value: _vm.wareInfoId,
                  callback: function ($$v) {
                    _vm.wareInfoId = $$v
                  },
                  expression: "wareInfoId",
                },
              },
              [
                _c("WareInfo", {
                  attrs: { id: _vm.wareInfoId },
                  on: {
                    cancel: function ($event) {
                      _vm.wareInfoId = null
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "TheDialog",
              {
                attrs: {
                  width: "585px",
                  title: _vm.$t("search.searchSettings"),
                },
                model: {
                  value: _vm.isSearchSettings,
                  callback: function ($$v) {
                    _vm.isSearchSettings = $$v
                  },
                  expression: "isSearchSettings",
                },
              },
              [
                _c("SearchMapSettings", {
                  on: {
                    cancel: function ($event) {
                      _vm.isSearchSettings = false
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "TheDialog",
              {
                attrs: { width: "1400px" },
                model: {
                  value: _vm.checkPoints,
                  callback: function ($$v) {
                    _vm.checkPoints = $$v
                  },
                  expression: "checkPoints",
                },
              },
              [
                _vm.checkPoints
                  ? _c("WareCheckPoints", {
                      attrs: {
                        ware: _vm.checkPoints.ware,
                        points: _vm.checkPoints.otherPoints,
                      },
                      on: {
                        select: function ($event) {
                          _vm.checkPoints = null
                          _vm.salePointInfo = $event
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("TheDialog", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.checkPoints,
                  expression: "!checkPoints",
                },
              ],
              attrs: { width: "800px" },
              scopedSlots: _vm._u(
                [
                  _vm.salePointInfo
                    ? {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "custom-headline custom-headline__dialog",
                              },
                              [
                                _c("span", { staticClass: "bold" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.salePointInfo,
                                          "ware.display_trademark",
                                          ""
                                        )
                                      ) +
                                      "\n            " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.salePointInfo,
                                          "ware.display_article",
                                          ""
                                        )
                                      ) +
                                      ",\n          "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.get(
                                        _vm.salePointInfo,
                                        "ware.display_title",
                                        ""
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _vm.salePointInfo
                          ? _c("WareSalePointInfo", {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.isSubmitOrderLoading,
                                  expression: "isSubmitOrderLoading",
                                },
                              ],
                              attrs: {
                                id: _vm.salePointInfo.id,
                                ware: _vm.salePointInfo.ware,
                                "affiliation-list-name": "Search Results",
                              },
                              on: {
                                "check-points": function ($event) {
                                  _vm.checkPoints = $event
                                },
                                "basket-click": _vm.submitOrder,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.salePointInfo,
                callback: function ($$v) {
                  _vm.salePointInfo = $$v
                },
                expression: "salePointInfo",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }