var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sale-point-options-view" }, [
    _c(
      "div",
      { staticClass: "fl-wrap" },
      _vm._l(_vm.options, function (data, listName) {
        return _c("div", { key: listName, staticClass: "mr-4" }, [
          _c("div", { staticClass: "label mb-1" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("labels." + listName)) + ":\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "pl-2" },
            _vm._l(data.list, function (item) {
              return _c("li", { key: item }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t(listName + "." + item)) +
                    "\n        "
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          data.textField && _vm.value[data.textField]
            ? _c(
                "div",
                { key: listName + "_text", staticClass: "mt-2" },
                [
                  _c("div", { staticClass: "text-ghost mb-05" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("salePoint." + data.textField)) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: {
                      rows: 4,
                      disabled: "",
                      type: "textarea",
                      value: _vm.value[data.textField],
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _vm.value.is_return_possible && _vm.value.return_terms_text
      ? _c("div", { staticClass: "mt-2" }, [
          _c("div", { staticClass: "mb-05 text-ghost" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("salePoint.return_terms_text")) +
                ":\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.value.return_terms_text))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.value.is_warranted && _vm.value.warranty_terms_text
      ? _c("div", { staticClass: "mt-2" }, [
          _c("div", { staticClass: "mb-05 text-ghost" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("salePoint.warranty_terms_text")) +
                ":\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.value.warranty_terms_text))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }