var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c("el-dialog", {
        ref: "dialog",
        staticClass: "the-dialog",
        attrs: {
          "append-to-body": "",
          center: "",
          visible: "",
          "show-close": !_vm.hideClose,
          top: _vm.top,
          fullscreen: _vm.fullscreen || _vm.maxmq("xs"),
          title: _vm.title,
          width: _vm.width,
        },
        on: { close: _vm.cancel },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [_vm._t("title")]
              },
              proxy: true,
            },
            {
              key: "default",
              fn: function () {
                return [_vm._t("default", null, null, _vm.$attrs)]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }