var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error" }, [
    _c(
      "div",
      { staticClass: "error-content" },
      [
        _c("div", { staticClass: "mb-2" }, [
          _c("h1", { staticClass: "error-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("errors.default.title")) +
                " " +
                _vm._s(_vm.error && _vm.error.statusCode) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-2" }, [
          _vm._v(_vm._s(_vm.error && _vm.error.message)),
        ]),
        _vm._v(" "),
        _c("nuxt-link", { attrs: { to: _vm.link } }, [
          _vm._v(_vm._s(_vm.$t("buttons.goBackHome"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }