<template>
  <div class="wares-table">
    <div v-if="localError && load" class="mb-2">
      <el-button size="mini" type="primary" @click="reload">
        {{ $t("buttons.searchAgain") }}
      </el-button>
    </div>

    <el-table
      v-else
      :data="value"
      :empty-text="emptyTitle"
      header-cell-class-name="header-cell"
      :header-cell-style="{ textAlign: 'center' }"
      :row-key="rowKey"
      stripe
    >
      <el-table-column
        :label="$t('search.article')"
        prop="display_title"
        width="200px"
      >
        <template #default="{ row }">
          <div class="article-row">
            <div class="mr-1 bold">{{ row.display_trademark }}</div>
            <div class="article-title">
              <el-link
                :class="['link', 'mr-1', { selected: article === row.article }]"
                :href="row._articleHref"
                @click.native.prevent.stop="goTo(row)"
              >
                {{ row.display_article }}
              </el-link>
              <BufferCopy :value="row.display_article" />
            </div>
          </div>

          <div
            v-if="row.other_selling_terms.includes('former_use')"
            class="color-red"
          >
            ({{ $t('search.formerUse') }})
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('search.info')" width="100px">
        <template #default="{ row }">
          <WareInfoIcons
            v-if="row.ware && row.ware.id"
            position="start"
            :ware="row.ware"
            @show="$emit('info:show', row)"
          />
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('search.name')"
        min-width="160px"
        prop="display_title"
      >
        <template #default="{ row }">
          <el-tooltip
            :content="row.display_title"
            effect="light"
            :enterable="false"
            placement="top"
          >
            <div class="text-ellipsis">{{ row.display_title }}</div>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column prop="delivery_time" width="120px">
        <template #header>
          <div
            class="sortable-header-col"
            @click="ordering && $emit('toggle-ordering', ['delivery_time', '-rank'])"
          >
            {{ $t('search.term') }}
            <i
              v-if="ordering && ordering[0] === 'delivery_time'"
              class="sort-icon el-icon-caret-top"
            />
          </div>
        </template>

        <template #default="{ row }">
          <div class="sortable-header-col">
            <DeliveryTimeString
              :value="row.delivery_time"
              :options="{ ware: row }"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="price" width="100px">
        <template #header>
          <div
            class="sortable-header-col"
            @click="ordering && $emit('toggle-ordering', ['price', '-rank'])"
          >
            {{ $t('search.price') }}
            <i
              v-if="ordering && ordering[0] === 'price'"
              class="sort-icon el-icon-caret-top"
            />
          </div>
        </template>

        <template #default="{ row }">
          <el-row class="price" justify="end">
            <div>
              <DebugInfo mode="ware" :value="row">
                <span class="price__value">
                  {{ row._price || getPriceNum(row.price) }}
                </span>
              </DebugInfo>

              <TheTooltip v-if="row._isShowRetail" :content="$t('search.retailPrice')">
                <span class="retail small">p</span>
              </TheTooltip>

              <el-row v-if="row._isVatPayer" class="small" justify="end">
                {{ $t('search.withVat') }}
              </el-row>
            </div>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column width="200px">
        <template slot="header">
          <slot name="6h">
            <span>{{ $t('search.point_of_sale') }}</span>
          </slot>
        </template>

        <template #default="{ row }">
          <slot name="6" v-bind="row">
            <div class="sale-point-col">
              <!--TODO to TheTooltip-->
              <el-tooltip
                v-if="row.point_of_sale"
                :content="row.point_of_sale.name"
                effect="light"
                :enterable="false"
                placement="top"
              >
                <el-link
                  type="primary"
                  class="analytics-sale-point-info-link"
                  @click="$emit(
                    'sale-point:info',
                    { id: row.point_of_sale.id, ware: row }
                  )"
                >
                  {{ row.point_of_sale.name }}
                </el-link>
              </el-tooltip>

              <div v-if="row.is_official_trademark" class="op-4">
                ({{ $t('search.formDist') }})
              </div>

              <div
                v-if="row.point_of_sale"
                class="city-col text-ellipsis text-center color-default"
              >
                <small>{{ row._city }}</small>
              </div>
            </div>
          </slot>
        </template>
      </el-table-column>

      <el-table-column v-if="!readonly" width="120px" align="right">
        <template #default="{ row }">
          <slot name="options" v-bind="row">
            <TheTooltip
              :content="row._canBy ? $t('search.addToBasket') : $t('search.noSell')"
            >
              <el-button
                size="mini"
                type="primary"
                :class="['basket', { 'in-basket': inBasket(row) }]"
                :disabled="isDisabledBasket(row)"
                :icon="inBasket(row)
                  ? 'el-icon-shopping-cart-full'
                  : 'el-icon-shopping-cart-2'"
                @click.stop="$emit('basket-click', row)"
              >
                {{ $t('buttons.buy') }}
              </el-button>
            </TheTooltip>
          </slot>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import WareInfoIcons from './wareInfo/WareInfoIcons';
import asyncTableMixin from '@/lib/core/mixins/asyncTableMixin';
import DebugInfo from '@/lib/core/components/DebugInfo';
import { DeliveryTimeString } from '@/lib/orders';
import { getPriceNum, getSearchRouteBy } from '@/lib/core/services/parsers';
import BufferCopy from '@/lib/core/components/BufferCopy';

export default {
  name: 'WaresTable',

  mixins: [asyncTableMixin],

  components: {
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column'),
    /* eslint-enable max-len */
    BufferCopy,
    WareInfoIcons,
    DeliveryTimeString,
    DebugInfo
  },

  props: {
    readonly: Boolean,
    article: String,
    byButtonText: String,
    ordering: { type: Array },
    disabledIds: { type: Array, default: () => [] }
  },

  methods: {
    getPriceNum,

    isDisabledBasket (row) {
      return !(row._canBy && !this.inBasket(row));
    },

    inBasket (row) {
      return this.disabledIds.includes(row.id);
    },

    goTo (row) {
      this.$router.push(getSearchRouteBy(this, row));
    }
  }
};
</script>

<!-- TODO clear it style by ssr-wares-table-->

<style scoped lang="scss">
@import "@/styles/components/mobile-tables.scss";

.wares-table {
  position: relative;
  width: 100%;

  * {
    line-height: 1.2rem;
  }

  ::v-deep .el-table__row {
    cursor: default;
  }

  .city {
    font-size: 10px;
    color: $--color-info;
  }

  .small {
    font-size: .6rem;
    opacity: .6;
  }

  .article-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    * {
      width: fit-content;
      white-space: nowrap;
      flex-wrap: nowrap;
    }

    .article-title {
      display: flex;
      flex-wrap: wrap;
      width: fit-content;
      position: relative;
      padding-right: 20px;

      .buffer-copy {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
      }
    }
  }

  .link {
    display: block;
    height: 100%;
    color: $--color-info;
    cursor: pointer;

    &.selected {
      text-decoration: underline;
      font-weight: bold;
    }

    &:hover:after {
      display: none;
    }
  }

  .sale-point-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 10px;

    .el-link,
    .city {
      text-align: center;
      max-width: 100%;
    }

    /deep/ * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .site {
      position: absolute;
      top: 0;
      right: -10px;
    }
  }

  .basket {
    position: relative;

    &.in-basket {
      color: $blue-500;
      border-color: $blue-500;
    }

    /deep/ .el-icon-shopping-cart-full:before {
      position: relative;
      top: 2px;
      left: -1px;
      font-size: 1rem;
      width: 14px;
      height: 14px;
    }
  }

  /deep/ .header-cell {
    height: 50px;
    padding: 0;

    & .cell {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  /deep/ .sortable-header-col {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    text-align: center;

    .sort-icon {
      position: absolute;
      top: 2px;
      right: 0;
      color: $green-600;
      font-size: 1.4rem;
    }
  }

  .price {
    position: relative;
    padding: 2px 0 0 0;

    &__value {
      font-weight: bold;
    }

    .retail {
      content: 'p';
      position: absolute;
      top: -6px;
      right: -8px;
    }
  }
}
</style>
