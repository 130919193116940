<template>
  <el-dialog
    class="shipment-form-dialog"
    :title="$t('basket.orderShipment')"
    visible
    width="1000px"
    @close="close"
  >
    <TheLoader v-if="isLoading" />

    <el-form
      v-else
      ref="form"
      label-position="top"
      :model="formData"
      :disabled="!!formData.id || isVLoading"
      :rules="rules"
      size="large"
      @submit.native.prevent="submit"
    >
      <div class="mb-2">
        <div class="mb-2 bold">{{ $t('basket.orderAddress') }}</div>

        <div class="form-block">
          <ShipmentOrderDelivery
            ref="orderDelivery"
            :value="formData"
            :dependency="dependency"
            :set-field="setField"
            v-bind.sync="formData"
          />
        </div>
      </div>

      <div class="mb-2">
        <div class="mb-2 bold">{{ $t('placeholders.comment') }}</div>

        <div class="form-block">
          <el-form-item label-width="0" prop="comment">
            <el-input
              v-model="formData.comment"
              :placeholder="$t('basket.shipmentComment')"
              rows="5"
              type="textarea"
            />
          </el-form-item>
        </div>
      </div>

      <el-row justify="end">
        <el-button @click="$emit('cancel')">
          {{ $t('buttons.cancel') }}
        </el-button>
        <el-button :loading="isVLoading" native-type="submit" type="primary">
          {{ $t('buttons.save') }}
        </el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import ShipmentOrderDelivery from '../formItems/ShipmentOrderDelivery';
import { getDefault, getValidationFor, scrollToTop } from '@/lib/core';

export default {
  name: 'ShipmentFormDialog',

  components: {
    ShipmentOrderDelivery
  },

  props: {
    value: { type: Object, required: true }
  },

  data () {
    return {
      isLoading: true,
      isVLoading: false,
      formData: getDefault('shipment'),
      rules: getValidationFor(null, 'shipment', this),
      shipment: null,
      shipments: [],
      addresses: [],
      salePoint: null
    };
  },

  computed: {
    dependency () {
      return {
        rules: this.rules,
        shipments: this.shipments,
        shipment: this.shipment,
        addresses: this.addresses,
        value: this.value,
        order: this.value.items[0].order_detail,
        salePoint: this.salePoint,
        address: [this.salePoint.address],
        delivery_methods: this.salePoint.delivery_methods
      };
    },

    sellerId () {
      return this.$store.getters['businessProfiles/sellerId'];
    }
  },

  async mounted () {
    this.isLoading = true;
    await Promise.all([this.loadPoint(), this.loadShipments()]);

    this.isLoading = false;

    this.$nextTick(this.setDefaultData);
  },

  methods: {
    async loadPoint () {
      try {
        this.salePoint = await this.$store.dispatch(
          'salePoints/getSalePoint',
          { id: this.value.items[0].order_detail.point_of_sale_detail.id }
        );
      } catch (e) {
        console.error(e);
        this.salePoint = this.value.items[0].order_detail.point_of_sale_detail;
      }
    },

    async loadShipments () {
      try {
        const shipments = await this.$store.dispatch(
          'shipments/getShipments',
          { seller: this.sellerId, created_today: true }
        );

        this.shipments = shipments
          .filter(item => item.delivery_method === 'carrier_in_country');
      } catch (e) {
        console.error(e);
      }
    },

    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          scrollToTop('is-error');
          return false;
        }

        this.isVLoading = true;

        const formData = this.getClearData(this.formData);

        try {
          const shipment = await this.$store.dispatch(
            'shipments/postShipment',
            { formData }
          );

          this.$emit('created', { shipment, value: this.dependency.value });
        } catch (e) {
          console.error(e);
        } finally {
          this.isVLoading = false;
        }
      });
    },

    getClearData (formData) {
      const { waybill_date, ..._formData } = cloneDeep(formData);

      if (waybill_date) {
        _formData.waybill_date = new Date(waybill_date).toISOString().slice(0, 10);
      }

      Object.keys(_formData).forEach((key) => {
        if (
          !_formData[key] ||
          key === 'id' ||
          (key === 'delivery_address' && !_formData.delivery_address?.city) ||
          (
            key === 'recipient_phone_number' &&
            !_formData.recipient_phone_number?.phone_number
          )
        ) {
          delete _formData[key];
        }
      });

      return _formData;
    },

    setDefaultData () {
      const order = this.value.items[0].order_detail;

      this.formData.point_of_sale = order.point_of_sale;
      this.formData.buyer = order.buyer;

      const deliveryType = `${order.delivery_method}.${order.delivery_address?.type}`;

      this.$refs.orderDelivery.toggleDeliveryMethod(deliveryType);
    },

    close () {
      this.$emit('cancel');
    },

    setField (path, val) {
      set(this.formData, path, val);
    }
  }
};
</script>

<style scoped lang="scss">
.shipment-form-dialog {
  ::v-deep {
    .form-block {
      background-color: $grey-100;
      padding: 10px;
      border-radius: 10px;
    }
  }
}
</style>
