import cloneDeep from 'lodash/cloneDeep';
import getSeoLinkBy from './getSeoLinkBy';
import { i18n } from '@/plugins/i18n';
import env from '@/lib/core/services/envConstants';

const mainList = [{ name: 'home', path: '/' }];

export default ({ vm, rowBreadcrumbs = [], options = {} }) => {
  const lang = vm.$store.getters.langCode === env.MARKETPLACE_DEFAULT_LANG
    ? ''
    : `/${vm.$store.getters.langCode}`;
  const fullList = options.passHome
    ? rowBreadcrumbs
    : cloneDeep(mainList.concat(rowBreadcrumbs));

  const breadcrumbs = fullList.map((crumb, index) => {
    if (index === 0 && crumb.name.includes('home')) {
      return {
        ...crumb,
        ...getSeoLinkBy({}, vm, {}),
        label: i18n.t('routes.home')
      };
    } else if (lang && !crumb.path.startsWith(`${lang}/`)) {
      return { ...crumb, path: `${lang}${crumb.path}` };
    }

    return crumb;
  });

  const itemListElement = breadcrumbs.map((crumb, index) => {
    const _crumb = { ...crumb };

    const item = (env.BASE_CLIENT_PATH + _crumb.path)
      .replace(/\s+/g, '%20')
      .replace('&amp%3B', '&');

    return {
      '@type': 'ListItem',
      position: index + 1,
      name: _crumb.label || i18n.t(`routes.${_crumb.name}`),
      item
    };
  });

  const _breadcrumbs = breadcrumbs.length === 1 && breadcrumbs[0].name === 'home'
    ? []
    : breadcrumbs;

  return {
    breadcrumbs: _breadcrumbs,
    seoBreadcrumbs: itemListElement.length
      ? {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement
        }
      : null
  };
};
