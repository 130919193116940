<template>
  <div
    :class="[
      'file-processing',
      { 'highlight-required': dependency.mode === 'create' },
    ]"
  >
    <div class="description mb-2">
      <p>{{ $t("prices.docTypeMarkupDescription") }}</p>
    </div>

    <div v-if="dataPreviewCount > 1" class="fit-content">
      <TheTooltip :content="$t('priceFile.dataSheetDescription')">
        <el-form-item
          prop="data_sheet"
          label-width="210px"
          :label="$t('priceFile.data_sheet')"
        >
          <el-select
            class="data-sheet"
            :value="value.data_sheet"
            @change="pageUpdate"
          >
            <el-option
              v-for="index in dataPreviewCount"
              :key="`sheet_${index}`"
              :label="index"
              :value="index"
            />
          </el-select>
        </el-form-item>
      </TheTooltip>
    </div>

    <div class="header-row">
      <div class="mr-2">
        <el-form-item
          :label="$t('priceFile.rowWithTitles')"
          label-width="300px"
          prop="header_row"
        >
          <el-input-number
            class="mr-2"
            :disabled="!value.header_row"
            :max="
              dependency.price.data_preview
                ? dependency.price.data_preview.length
                : 1
            "
            :min="0"
            :value="value.header_row"
            @change="emit('header_row', $event ? $event : null)"
          />
        </el-form-item>
      </div>
      <div class="mr-2">
        <el-form-item prop="header_row" label-width="0">
          <el-checkbox
            class="no-head-row"
            :label="$t('priceFile.noHeaderCol')"
            :value="!Boolean(value.header_row)"
            @change="emit('header_row', value.header_row ? null : 1)"
          />
        </el-form-item>
      </div>

      <div class="data_start_row mr-2">
        <el-form-item
          :label="$t('priceFile.dataStartRow')"
          label-width="300px"
          prop="data_start_row"
        >
          <el-input-number
            :max="
              dependency.price.data_preview
                ? dependency.price.data_preview.length
                : 1
            "
            :min="value.header_row + 1"
            :value="value.data_start_row"
            @change="emit('data_start_row', $event)"
          />
        </el-form-item>
      </div>
    </div>

    <div>
      <el-form-item
        ref="trademark"
        :label="$t('priceFile.oneTradeMarkInPrice')"
        label-width="266px"
        prop="trademark"
      >
        <el-row>
          <el-checkbox :value="hasOneTradeMark" @change="toggleTrademark" />
          <el-input
            class="ml-2 max-w-350p"
            :disabled="!hasOneTradeMark"
            :value="value.trademark"
            @input="emit('trademark', $event)"
          />
        </el-row>
      </el-form-item>

      <el-form-item
        :label="$t('priceFile.noCounterInfoInPrice')"
        label-width="300px"
        prop="noCounterInPrice"
      >
        <el-checkbox
          :value="value.noCounterInPrice"
          @change="emit('noCounterInPrice', $event)"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/lib/core';

export default {
  name: 'FileProcessing',

  props: {
    value: { type: Object, required: true },
    dataPreviewCount: { type: Number, default: 1 },
    dependency: { type: Object, required: true }
  },

  computed: {
    hasOneTradeMark () {
      return typeof this.value.trademark === 'string';
    }
  },

  watch: {
    'value.header_row': {
      immediate: true,
      handler (headerRow) {
        if (this.value.data_start_row <= headerRow) {
          this.emit('data_start_row', headerRow + 1);
        }
      }
    }
  },

  created () {
    eventBus.$on('set.data_sheet', this.pageUpdate);
  },

  beforeDestroy () {
    eventBus.$off('set.data_sheet', this.pageUpdate);
  },

  methods: {
    emit (name, value) {
      this.$emit(`update:${name}`, value);
    },

    toggleTrademark (event) {
      this.emit('trademark', event ? '' : null);
      this.$nextTick(() => this.$refs.trademark.clearValidate());
    },

    pageUpdate (data_sheet) {
      this.emit('data_sheet', data_sheet);
      this.$emit('page:update', data_sheet);
    }
  }
};
</script>

<style scoped lang="scss">
.file-processing {
  .header-row {
    display: flex;
    flex-wrap: wrap;
  }

  .el-checkbox {
    display: block;
    width: fit-content;
  }

  .no-head-row {
    padding-top: 2px;
  }

  .data-sheet {
    width: 80px;
  }
}
</style>
