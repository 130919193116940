var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rate-warehouse-table" },
    [
      _c(
        "el-table",
        {
          ref: "table",
          attrs: {
            data: _vm.value,
            "header-cell-style": { textAlign: "center" },
            "row-key": "_id",
          },
        },
        [
          _c("el-table-column", {
            attrs: { width: "55" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.isSelectionAll,
                        callback: function ($$v) {
                          _vm.isSelectionAll = $$v
                        },
                        expression: "isSelectionAll",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      on: {
                        change: function ($event) {
                          return _vm.updateRow(row)
                        },
                      },
                      model: {
                        value: row.is_active,
                        callback: function ($$v) {
                          _vm.$set(row, "is_active", $$v)
                        },
                        expression: "row.is_active",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("salePoint.salePoint"),
              "min-width": "250px",
              prop: "_salePointName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-row",
                      { attrs: { align: "middle", justify: "space-between" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(row._salePointName) +
                            "\n          "
                        ),
                        !$index
                          ? _c("el-button", {
                              attrs: {
                                circle: "",
                                icon: "el-icon-document-copy",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("copy-value", row)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("salePoint.addressBlock"),
              width: "120px",
              prop: "_salePointCity",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " + _vm._s(row._salePointCity) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("prices.order_time_until"),
              "min-width": "150px",
              prop: "delivery_time",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("TimeSelector", {
                      attrs: { readonly: !row.is_active, required: "" },
                      on: {
                        input: function ($event) {
                          return _vm.updateRow(row)
                        },
                      },
                      model: {
                        value: row.order_time_until,
                        callback: function ($$v) {
                          _vm.$set(row, "order_time_until", $$v)
                        },
                        expression: "row.order_time_until",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("prices.delivery_time"),
              "min-width": "250px",
              prop: "delivery_time",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("IntervalSelector", {
                      attrs: {
                        "is-price-warehouse": "",
                        readonly: !row.is_active,
                        "show-controls": "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateRow(row)
                        },
                      },
                      model: {
                        value: row.delivery_time,
                        callback: function ($$v) {
                          _vm.$set(row, "delivery_time", $$v)
                        },
                        expression: "row.delivery_time",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }