var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "price-format-form",
      attrs: {
        "label-position": "left",
        "label-width": "150px",
        model: _vm.formData,
        rules: _vm.rules,
        size: "mini",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _vm.formData
        ? _c(
            "el-row",
            { staticClass: "mb-2" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "mr-1",
                  attrs: { "label-width": "0", prop: "type" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { value: _vm.formData.file_type },
                      on: {
                        input: function ($event) {
                          _vm.formData = { file_type: $event }
                        },
                      },
                    },
                    _vm._l(_vm.formats, function (format) {
                      return _c(
                        "el-radio",
                        { key: format.type, attrs: { label: format.type } },
                        [
                          _vm._v(
                            "\n          " + _vm._s(format.title) + "\n        "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                _vm.component,
                _vm._b(
                  { tag: "component", attrs: { value: _vm.formData } },
                  "component",
                  _vm.formData,
                  false,
                  true
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isChild
        ? _c(
            "el-row",
            { staticClass: "mt-2", attrs: { justify: "end" } },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("buttons.cancel")) + "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.isEqual,
                    loading: _vm.loading,
                    "native-type": "submit",
                    type: "primary",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("buttons.save")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }