<template>
  <div class="s-request-form">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      @submit.native.prevent="checkAndSubmit"
    >
      <el-form-item prop="vin">
        <component
          :is="`CarInfoCard${isMobile ? 'M' : ''}`"
          :value="car"
        />
      </el-form-item>

      <el-form-item prop="itemsAdd">
        <div
          v-for="(item, index) in formData.itemsAdd"
          :key="index"
          class="item-row grey-block"
        >
          <el-form-item
            :class="['mb-1 required-form-label', {'mr-2': !isMobile }]"
            :label="$t('garage.selectionRequests.rawItem')"
            :rules="rules.rawItem"
            :prop="`itemsAdd[${index}].rawItem`"
          >
            <div class="relative">
              <el-input
                :ref="`itemId_${index}`"
                class="select-input"
                size="small"
                clearable
                :value="item.rawItem"
                @blur="hideSelect"
                @input="onSearchChange(index, item, $event)"
              />

              <div
                v-if="activeItem === `itemId_${index}` && autocomplete.length"
                class="select back-shadow scroll"
              >
                <div
                  v-for="(_item, i) in autocomplete"
                  :key="`${i}_${_item}`"
                  class="item"
                  @click="item.rawItem = _item; autocomplete = []"
                >
                  {{ _item }}
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item
            :label="$t('garage.selectionRequests.itemComment')"
            :prop="`itemsAdd[${index}].comment`"
          >
            <el-input
              v-model="item.comment"
              type="textarea"
              :autosize="{ minRows: 1 }"
            />
          </el-form-item>
        </div>
      </el-form-item>

      <el-row justify="end" class="mb-2">
        <el-button type="success" @click="addItem">
          {{ $t('buttons.addMoreRow') }}
        </el-button>
      </el-row>

      <el-row v-if="!hideCity" :justify="isMobile ? 'center' : 'end'" class="mb-2 city">
        <span>{{ $t('sRequests.myCity') }}:</span>
        <el-link class="ml-05" type="primary" @click="showCitySelector">
          {{ cityName }}
        </el-link>
      </el-row>

      <el-row justify="center" class="buttons">
        <el-button @click="$emit('cancel')">
          {{ $t('buttons.cancel') }}
        </el-button>

        <el-button
          :disabled="!canSubmit"
          :loading="isVLoading"
          type="primary"
          native-type="submit"
        >
          {{ submitBtnText || $t('buttons.emitRequest') }}
        </el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import uniq from 'lodash/uniq';
import { getDefault, getValidationFor, scrollToTop } from '@/lib/core';
import { getLowLines } from '@/lib/catalog/services/parseCatalog';

export default {
  name: 'SRequestForm',

  components: {
    /* eslint-disable max-len */
    CarInfoCard: () => import(/* webpackChunkName: "CarInfoCard" */ '@/lib/garage/components/CarInfoCard'),
    CarInfoCardM: () => import(/* webpackChunkName: "CarInfoCardM" */ '@/lib/garage/components/CarInfoCardM')
    /* eslint-enable max-len */
  },

  props: {
    hideCity: Boolean,
    submitBtnText: String,
    car: { type: Object, required: true },
    submit: { type: Function, required: true }
  },

  data () {
    return {
      isVLoading: false,
      formData: getDefault('selectionRequest'),
      rules: getValidationFor(null, 'selectionRequest', this),
      autocomplete: [],
      allCatalog: [],
      activeItem: ''
    };
  },

  computed: {
    isMobile () {
      return this.maxmq('xs');
    },

    canSubmit () {
      return this.formData.itemsAdd.length &&
        this.formData.itemsAdd.some(({ rawItem }) => rawItem);
    },

    cityName () {
      return this.$store.getters.city?.name || '';
    }
  },

  mounted () {
    this.loadCatalog();
    this.setDefaultData();
  },

  methods: {
    async loadCatalog () {
      try {
        const catalog = await this.$store.dispatch('productLines/fetchCatalog');

        this.allCatalog = uniq(getLowLines(catalog).map(({ name }) => name)).sort();
      } catch (e) {
        console.error(e);
      }
    },

    setDefaultData () {
      this.formData.vin = this.car?.vin;
      this.formData.brand = this.car?.brand?.id;
      this.formData.model = this.car?.model?.vin;
      this.formData.modification = this.car?.modification?.vin;
      this.formData.productionYear = this.car?.productionYear;
    },

    checkAndSubmit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid || !this.car) {
          return scrollToTop('is-error');
        }

        this.isVLoading = true;
        const formData = this.getClear(this.formData);
        await this.submit(formData);
        this.isVLoading = false;
      });
    },

    getClear (formData) {
      const unUsed = ['id', 'isActive', 'created'];

      const car = Object.entries(this.car).reduce((acc, [key, val]) => {
        if (key.startsWith('_')) {
          return acc;
        }

        if (key === name) {
          acc[key] = `
            ${this.car.productionYear}
            ${this.car.brand.name}
            ${this.car.model.name}
          `;
        } else if (key === 'comment') {
          acc.carComment = val;
        } else if (!unUsed.includes(key) && val !== null) {
          acc[key] = typeof val === 'object' && Object.hasOwn(val, 'id')
            ? val.id
            : val;
        }

        return acc;
      }, {});

      const { id, ..._car } = car;

      return {
        ..._car,
        car: id || Number(this.$route.params.carId),
        itemsAdd: formData.itemsAdd.filter(({ rawItem }) => !!rawItem)
      };
    },

    onSearchChange (index, item, query = '') {
      this.activeItem = `itemId_${index}`;

      item.rawItem = query || '';

      if (!query || query.length < 3) {
        this.autocomplete = [];
        return null;
      }

      const _q = item.rawItem.toLowerCase();
      this.autocomplete = this.allCatalog.filter(i => i.toLowerCase().includes(_q));
    },

    addItem () {
      const newItem = getDefault('selectionRequestItem');

      this.formData.itemsAdd.push(newItem);
    },

    showCitySelector () {
      this.$store.dispatch('setIsCitySelector', true);
    },

    hideSelect () {
      setTimeout(() => { this.autocomplete = []; }, 500);
    }
  }
};
</script>

<style scoped lang="scss">
.s-request-form {
  .select {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 300px;
    max-width: 96vw;
    max-height: 150px;
    background-color: $white;
    border: 1px solid $grey-300;
    border-radius: 6px;
    z-index: 99;
    overflow: auto;

    .label {
      font-weight: bold;
      background-color: $grey-100;
      padding: 10px;
    }

    .item {
      line-height: 1rem;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: $blue-50;
      }
    }
  }

  .item-row {
    display: flex;

    .el-form-item {
      flex: 1;
    }

    .select-input {
      margin-top: 3px;
    }
  }

  .grey-block {
    padding: 10px;
    background-color: $grey-200;
    border-radius: 4px;
  }

  .city * {
    font-size: 1.2rem;
  }

  .buttons .el-button {
    min-width: 150px;
  }

  @media all and (max-width: $--xs) {
    .item-row {
      flex-direction: column;
    }

    .el-select {
      width: 100%;
    }
  }
}
</style>
