var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "business-edit-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("routes.profile_business_edit")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isLoading || !_vm.businessProfile
        ? _c("TheLoader")
        : [
            _c(
              "el-tabs",
              {
                attrs: { type: "card", value: _vm.activeTab },
                on: {
                  "tab-click": function ($event) {
                    return _vm.toggleMenu($event.name)
                  },
                },
              },
              _vm._l(_vm.tabs, function (tab) {
                return _c(
                  "el-tab-pane",
                  {
                    key: tab,
                    attrs: {
                      label: _vm.$t("profile." + tab + "Tab"),
                      name: tab,
                    },
                  },
                  [
                    _c("Business" + _vm.upperFirst(tab) + "Tab", {
                      tag: "component",
                      staticClass: "mb-4",
                      attrs: {
                        entity: _vm.businessProfile,
                        "entity-name": "profile",
                        submit: _vm.submit,
                      },
                      on: { input: _vm.submit },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }