var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ware-preview-list" },
    [
      _c("FilePreviewList", {
        attrs: {
          alt: _vm.alt,
          "preview-size": _vm.isMobile ? "120" : "300",
          readonly: "",
          size: _vm.isMobile ? "xs" : "md",
          "img-path": _vm.isMobile ? "image_200x200" : "image_500x500",
          "dialog-img-path": "image_800x800",
          value: _vm.ware.images,
        },
        scopedSlots: _vm._u([
          {
            key: "popupHeader",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass:
                      "custom-headline custom-headline--level2-bold text-center",
                  },
                  [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }