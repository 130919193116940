<template>
  <el-link class="search-in-google" @click.stop.prevent="searchOnGoogle">
    <slot>{{ $t('buttons.searchGooglePhoto') }}</slot>
  </el-link>
</template>

<script>
export default {
  name: 'SearchInGoogle',

  props: {
    tab: { type: String, default: 'isch' },
    query: { type: String, required: true }
  },

  methods: {
    searchOnGoogle () {
      const url = 'https://www.google.com/search';
      const q = `${this.query}&tbm=${this.tab}`;
      window.open(`${url}?q=${q}`, '_blank');
    }
  }
};
</script>

<style scoped lang="scss">
.search-in-google {
  font-size: .8rem;
  margin: 4px;
}
</style>
