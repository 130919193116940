var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "site-info" },
    [
      _c(
        "el-row",
        { attrs: { align: "middle", gutter: 20 } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("profile.site"),
                    "label-width": "250px",
                    prop: "site",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.readonly, value: _vm.value.site },
                    on: {
                      input: function ($event) {
                        return _vm.emit("site", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "0", prop: "order_on_site" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { value: _vm.value.order_on_site },
                      on: {
                        change: function ($event) {
                          return _vm.emit("order_on_site", $event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("profile.order_on_site")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("profile.search_on_site_url"),
                    "label-width": "250px",
                    prop: "search_on_site_url",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.readonly,
                      value: _vm.value.search_on_site_url,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.emit("search_on_site_url", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "description ml-250p" }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("profile.searchOnSiteUrlDescription", {
                        mp: _vm.mp,
                      })
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }