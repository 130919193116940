<template>
  <div class="sale-points-table">
    <el-table
      :data="value"
      :header-cell-style="{ textAlign: 'center' }"
      @row-click="$emit('row-click', $event)"
    >
      <el-table-column :label="$t('salePoint.pointName')" prop="name" width="200px" />

      <el-table-column :label="$t('labels.city')" prop="address" width="160px">
        <template #default="{ row }">
          {{ get(row, 'address.city_detail.name') }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('labels.address')" min-width="300px" prop="address">
        <template #default="{ row }">
          {{ getConcatedAddress(row.address) }}
        </template>
      </el-table-column>

      <el-table-column align="center" :label="$t('salePoint.status')" width="80px">
        <template #default="{ row }">
          <SalePointActivator
            :point="row"
            :readonly="readonly"
            @update="row.is_active = $event"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import get from 'lodash/get';
import getConcatedAddress from '../services/getConcatedAddress';
import SalePointActivator from './SalePointActivator';

export default {
  name: 'SalePointsTable',

  components: {
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column'),
    /* eslint-enable max-len */
    SalePointActivator
  },

  props: {
    readonly: Boolean,
    value: { type: Array, required: true }
  },

  methods: {
    get,
    getConcatedAddress
  }
};
</script>
