<template>
  <div class="seller-orders-table">
    <div v-if="localError && load" class="mb-2">
      <el-button size="mini" type="primary" @click="reload">
        {{ $t('buttons.searchAgain') }}
      </el-button>
    </div>

    <TheLoader v-if="loading" />

    <template v-else>
      <div class="mb-2 table-header">
        <OrdersFilter use-route />

        <el-row align="middle">
          <el-button
            class="mr-1"
            size="mini"
            plain
            type="warning"
            @click="reload"
          >
            {{ $t('buttons.reload') }}
          </el-button>

          <StatusCycleHelp />

          <el-select
            v-model="selectedStatusCollection"
            class="mr-1 w-200p"
            clearable
            :placeholder="$t('placeholders.setStatus')"
            size="mini"
          >
            <el-option
              v-for="(value, status) in groupedByStatus"
              :key="status"
              :disabled="value.disabled"
              :label="status ? `${$t(`orderStatuses.${status}`)} (${value.items.length})` : ''"
              :value="status"
            >
              {{ $t(`orderStatuses.${status}`) }} ({{ value.items.length }})
            </el-option>
          </el-select>

          <el-button
            :disabled="!selectedStatusCollection"
            size="mini"
            type="primary"
            @click="setNextStatusForGroup(selectedStatusCollection)"
          >
            {{ $t('buttons.apply') }}
          </el-button>
        </el-row>
      </div>

      <el-row
        v-if="usePage && propCount > PAGE_SIZE"
        class="mt-2 mb-2"
        justify="center"
      >
        <el-pagination
          background
          :current-page.sync="page"
          layout="prev, pager, next"
          :page-size="PAGE_SIZE"
          :total="propCount"
        />
      </el-row>

      <el-table
        :cell-style="{ textAlign: 'center' }"
        :data="value"
        :header-cell-style="{ textAlign: 'center' }"
        @row-click="$emit('rowClick', $event)"
      >
        <el-table-column
          :label="$t('basket.orderNum')"
          prop="_created"
          width="170px"
        >
          <template #default="{ row }">
            <el-row align="middle" justify="center">
              <div class="filter">
                <img
                  class="icon-img c-pointer"
                  src="@/assets/icons/filter-outline-primary.svg"
                  @click="toggleFilter('order_public_id', row._publicId)"
                >
              </div>

              <nuxt-link
                class="pg-0-1"
                :to="{
                  name: getRouteName('profile.business.seller_orders.order'),
                  params: { id: row._orderId }
                }"
              >
                <span class="color-blue c-pointer">
                  {{ row._displayPublicId }}
                </span>
                <span v-if="row.order_detail.is_one_click_order">
                  <TheTooltip :content="$t('orders.fastOrder')">
                    <span class="pg-05 required one-click-order-icon" />
                  </TheTooltip>
                </span>
              </nuxt-link>

              <TheTooltip
                v-if="row.order_detail.comment"
                :content="row.order_detail.comment"
              >
                <i class="el-icon-chat-line-round" />
              </TheTooltip>
            </el-row>
            <div class="small">{{ row._created }}</div>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('basket.deliveryCity')"
          prop="_deliveryCity"
          width="150px"
        >
          <template #default="{ row }">
            <TheTooltip :content="row._deliveryCity">
              <!--eslint-disable-next-line  vue/no-v-html-->
              <div class="pg-0-1 text-ellipsis" v-html="row._deliveryCityHtml" />
            </TheTooltip>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('basket.buyer')"
          prop="_buyer"
          width="160px"
        >
          <template #default="{ row }">
            <el-row align="middle">
              <div class="filter">
                <img
                  class="icon-img c-pointer"
                  src="@/assets/icons/filter-outline-primary.svg"
                  @click="toggleFilter('buyer', row._buyerId)"
                >
              </div>

              <TheTooltip :content="row._buyerName">
                <div class="pg-0-1 text-ellipsis">{{ row._buyerName }}</div>
              </TheTooltip>
            </el-row>

            <el-row>
              <TheTooltip :content="row._buyerData">
                <div class="small">{{ row._buyerData }}</div>
              </TheTooltip>
            </el-row>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('basket.ware')"
          min-width="280px"
          prop="_ware"
        >
          <template #default="{ row }">
            <el-row class="article-row">
              <div class="filter">
                <img
                  class="icon-img c-pointer"
                  src="@/assets/icons/filter-outline-primary.svg"
                  @click="toggleFilter('article', row.article)"
                >
              </div>

              <div class="pg-0-1">
                <strong class="text-ellipsis">{{ row.display_trademark }}</strong>
              </div>

              <div class="article-title">{{ row.display_article }}</div>
            </el-row>

            <el-row>
              <div class="small">{{ row.display_title }}</div>
            </el-row>
          </template>
        </el-table-column>

        <el-table-column
          :label="`${$t('el.table.sumText')}, ${MARKETPLACE_CURRENCY}`"
          prop="price"
          width="120px"
        >
          <template #default="{ row }">
            <div>{{ row._priceSum }}</div>
            <div class="small">{{ row.quantity }} × {{ row._price }}</div>
          </template>
        </el-table-column>

        <el-table-column
          :label="`${$t('basket.priceFile')}, ${$t('basket.delivery_time')}`"
          prop="priceFile"
          width="200px"
        >
          <template #default="{ row }">
            <el-row align="middle" justify="center">
              <div class="filter">
                <img
                  v-if="row._priceFileId"
                  class="icon-img c-pointer"
                  src="@/assets/icons/filter-outline-primary.svg"
                  @click="toggleFilter('price_file', row._priceFileId)"
                >
              </div>
              <div class="text-ellipsis pg-0-1">
                <TheTooltip :content="row._priceFileName">
                  <span>{{ row._priceFileName }}</span>
                </TheTooltip>
              </div>
              <div />
            </el-row>

            <el-row v-if="row.selection_request_detail" justify="center">
              <nuxt-link
                :to="{
                  name: getRouteName('profile.business.s-requests.edit'),
                  params: { id: row.selection_request_detail.id }
                }"
              >
                {{ row.selection_request_detail.public_id }}
              </nuxt-link>
            </el-row>

            <el-row justify="center" class="small">
              {{ row._deliveryDate }}
            </el-row>
          </template>
        </el-table-column>

        <el-table-column :label="$t('basket.status')" width="160px">
          <template #default="{ row }">
            <el-row justify="center" class="color-green">
              {{ row.is_paid ? $t('basket.is_paid') : '' }}
            </el-row>

            <OrderStatusToggle
              :value="row"
              :can-set-next-status="canSetNextStatus"
              :get-next-status="getNextStatus"
              :set-next-status="setNextStatus"
            />

            <div v-if="canCancel(row.status)" @click="itemFormCancel = row">
              <TheTooltip :content="$t('buttons.cancelOrderItem')">
                <i class="el-icon-circle-close c-pointer" />
              </TheTooltip>
            </div>

            <div v-if="row.shipment_detail">
              <el-row class="text-ellipsis" justify="space-between">
                <div v-if="hasWaybill(row)">
                  <TheTooltip
                    class="text-ellipsis"
                    :content="row.shipment_detail.waybill"
                    enterable
                  >
                    <span>{{ row.shipment_detail.waybill }}</span>
                  </TheTooltip>
                </div>

                <div v-else>-</div>

                <div>
                  <img
                    class="icon-img c-pointer"
                    src="@/assets/icons/information-variant-primary.svg"
                    @click="showShipmentInfo(row)"
                  >
                </div>
              </el-row>
            </div>
          </template>
        </el-table-column>

        <el-table-column width="40px">
          <template #header>
            <el-checkbox v-model="isAllChecked" />
          </template>

          <template #default="{ row }">
            <el-checkbox
              :disabled="orderItemEndStatuses.includes(row.status)"
              :value="checkedIds.includes(row.id)"
              @change="toggleItem(row, $event)"
            />
          </template>
        </el-table-column>
      </el-table>

      <el-row
        v-if="!hideBottomStatusSelect"
        align="middle"
        class="mt-2"
        justify="end"
      >
        <StatusCycleHelp />

        <el-select
          v-model="selectedStatusCollection"
          class="mr-1"
          clearable
          :placeholder="$t('placeholders.setStatus')"
          size="mini"
        >
          <el-option
            v-for="(value, status) in groupedByStatus"
            :key="status"
            :disabled="value.disabled"
            :label="status ? $t(`orderStatuses.${status}`) : ''"
            :value="status"
          >
            {{ $t(`orderStatuses.${status}`) }} ({{ value.items.length }})
          </el-option>
        </el-select>

        <el-button
          :disabled="!selectedStatusCollection"
          size="mini"
          type="primary"
          @click="setNextStatusForGroup(selectedStatusCollection)"
        >
          {{ $t('buttons.apply') }}
        </el-button>
      </el-row>

      <el-row
        v-if="usePage && propCount > PAGE_SIZE"
        class="mt-2"
        justify="center"
      >
        <el-pagination
          background
          :current-page.sync="page"
          layout="prev, pager, next"
          :page-size="PAGE_SIZE"
          :total="propCount"
        />
      </el-row>
    </template>

    <TheDialog
      v-model="itemFormCancel"
      width="600px"
      :title="$t('basket.rejectOrderProcessing')"
    >
      <OrderCancelConfirm
        v-if="itemFormCancel"
        :value="itemFormCancel"
        @cancel="itemFormCancel = null"
        @item:update="emitCancel"
      />
    </TheDialog>

    <ShipmentFormDialog
      v-if="!!forShipments"
      :value="forShipments"
      @cancel="forShipments = null"
      @created="updateItemsByShipment"
    />

    <TheDialog
      v-model="shipmentInfo"
      width="600px"
      :title="$t('basket.orderShipment')"
    >
      <ShipmentInfo
        v-if="shipmentInfo"
        :id="shipmentInfo"
        @cancel="shipmentInfo = null"
      />
    </TheDialog>
  </div>
</template>

<script>
import without from 'lodash/without';
import OrdersFilter from './OrdersFilter';
import OrderStatusToggle from './OrderStatusToggle';
import asyncTableMixin from '@/lib/core/mixins/asyncTableMixin';
import library from '@/lib/core/services/library';
import env from '@/lib/core/services/envConstants';

export default {
  name: 'SellerOrdersTable',

  mixins: [asyncTableMixin],

  components: {
    OrdersFilter,
    OrderStatusToggle,
    /* eslint-disable max-len */
    ElPagination: () => import(/* webpackChunkName: "ElPagination" */ '@/lib/core/components/ElPagination'),
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column'),
    ShipmentFormDialog: () => import(/* webpackChunkName: "ShipmentFormDialog" */ './shipment/ShipmentFormDialog'),
    ShipmentInfo: () => import(/* webpackChunkName: "ShipmentInfo" */ './ShipmentInfo'),
    StatusCycleHelp: () => import(/* webpackChunkName: "StatusCycleHelp" */ './StatusCycleHelp'),
    OrderCancelConfirm: () => import(/* webpackChunkName: "OrderCancelConfirm" */ './OrderCancelConfirm')
    /* eslint-enable max-len */
  },

  props: {
    hideBottomStatusSelect: Boolean
  },

  data () {
    return {
      MARKETPLACE_CURRENCY: env.MARKETPLACE_CURRENCY,
      itemFormCancel: null,
      watchParams: true,
      orderItemLifeCycle: library.orderItemLifeCycle,
      orderItemEndStatuses: library.orderItemEndStatuses,
      checkedIds: [],
      selectedStatusCollection: null,
      isAddressDialog: false,
      defaultAddressFields: { type: null },
      forShipments: null,
      shipmentInfo: null,
      PAGE_SIZE: env.SMALL_PAGINATION_SIZE
    };
  },

  computed: {
    isAllChecked: {
      get () {
        const canSetNext = this.value
          .filter(({ status }) => this.canSetNextStatus(status));
        return this.checkedIds.length &&
          this.checkedIds.length === canSetNext.length;
      },

      set (isChecked) {
        if (!isChecked) {
          this.checkedIds = [];
        } else {
          this.checkedIds = this.value.reduce((acc, { id, status }) => {
            if (this.canSetNextStatus(status)) {
              acc.push(id);
            }

            return acc;
          }, []);
        }
      }
    },

    statusCollection () {
      return this.value.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },

    groupedByStatus () {
      return this.checkedIds.reduce((acc, id) => {
        const item = this.statusCollection[id];
        const nextStatus = this.getNextStatus(item.status);
        const status = item.status;

        if (!acc[status]) {
          acc[status] = { items: [item], disabled: false };
        } else {
          const { _buyerId, _pointId } = acc[status].items[0];

          acc[status].items.push(item);

          acc[status].disabled =
            nextStatus === 'issued' && (_buyerId !== item._buyerId || _pointId !== item._pointId);
        }

        acc[status].status = status;
        acc[status].nextStatus = nextStatus;

        return acc;
      }, {});
    },

    allStatuses () {
      return this.value.map(({ status }) => status);
    }
  },

  watch: {
    allStatuses: 'reCountCheckedIds'
  },

  methods: {
    getNextStatus (status) {
      const index = this.orderItemLifeCycle.findIndex(code => code === status);

      if (index > -1 && this.orderItemLifeCycle[index + 1]) {
        return this.orderItemLifeCycle[index + 1];
      }
    },

    reCountCheckedIds () {
      this.checkedIds = this.checkedIds.filter((id) => {
        return this.canSetNextStatus(this.statusCollection[id].status);
      });

      this.selectedStatusCollection = null;
    },

    async setNextStatusForGroup (status) {
      if (this.getNextStatus(status) === 'issued') {
        this.showShipmentsPopup({ status, multiple: true });
      } else {
        const nextStatus = this.$t(`orderStatuses.${this.getNextStatus(status)}`);

        const isAgree = await this.$confirm(
          this.$t('message.confirmSetNextStatus', { status: nextStatus }),
          { type: 'warning', title: this.$t('message.warning') }
        );

        if (isAgree) {
          this.selectedStatusCollection = null;

          const multiple = this.groupedByStatus[status].items.length > 1;

          this.groupedByStatus[status].items.forEach((item) => {
            this.setNextStatus(item, multiple);
          });
        }
      }
    },

    showShipmentsPopup ({ status, multiple, item }) {
      if (multiple) {
        this.forShipments = this.groupedByStatus[status];
      } else {
        this.forShipments = {
          items: [item],
          status,
          nextStatus: this.getNextStatus(status)
        };
      }
    },

    toggleItem (item, isChecked) {
      if (isChecked) {
        this.checkedIds.push(item.id);
      } else {
        this.checkedIds = without(this.checkedIds, item.id);
      }
    },

    canCancel (status) {
      return !this.orderItemEndStatuses.includes(status);
    },

    canSetNextStatus (status) {
      return !this.orderItemEndStatuses.includes(status);
    },

    updateItemsByShipment ({ shipment, value }) {
      value.items.forEach((item) => {
        if (item._revertStatusTimer) {
          item._revertStatusTimer.stop();
        }

        this.$emit('item:update', {
          id: item.id,
          status: value.nextStatus,
          shipment: shipment.url,
          multiple: value.items.length > 1
        });
      });

      this.forShipments = null;
    },

    setNextStatus (item, multiple) {
      const { id, status } = item;

      if (item._revertStatusTimer) {
        item._revertStatusTimer.stop();
      }

      const nextStatus = this.getNextStatus(status);

      if (nextStatus === 'issued') {
        return this.showShipmentsPopup({ status, item });
      }

      this.$emit('item:update', { id, status: nextStatus, multiple });
    },

    emitCancel (item) {
      this.$emit('item:update', item);
      this.itemFormCancel = null;
    },

    hasWaybill ({ shipment_detail }) {
      return shipment_detail && (shipment_detail.waybill || shipment_detail.waybill_date);
    },

    showShipmentInfo (row) {
      this.shipmentInfo = row.shipment_detail.id;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/components/order-tables.scss';

::v-deep {
  .cell {
    padding-left: 0!important;
  }
}

.one-click-order-icon {
  top: 2px;
}

.table-header {
  display: flex;
  justify-content: space-between;

  @media all and (max-width: $--md) {
    display: block;

    .orders-filter {
      margin-bottom: 20px;
    }
  }
}
</style>
