<template>
  <div :key="key" class="rate-warehouse-tables">
    <el-collapse v-model="activeBlock" accordion>
      <el-collapse-item
        v-for="(points, name) in viewValue"
        :key="name"
        :name="name"
      >
        <template slot="title">
          <i class="active-icon el-icon-caret-right" />
          {{ `${$t('prices.warehousesRate')} '${name}'` }}
        </template>
        <div v-show="activeBlock === name" class="pg-2">
          <RateWarehouseTable
            :value="points"
            @copy-value="copyValue(points, $event)"
            @toggle-selection="toggleSelection(points, $event)"
            @update-row="updateRow"
          />
        </div>
      </el-collapse-item>
    </el-collapse>

    <el-row
      v-if="showByPart && totalLength && totalLength > PAGE_SIZE"
      class="mt-2"
      justify="center"
    >
      <el-pagination
        small
        layout="prev, pager, next"
        :current-page.sync="page"
        :page-size="PAGE_SIZE"
        :total="totalLength"
      />
    </el-row>
  </div>
</template>

<script>
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import RateWarehouseTable from './RateWarehouseTable';
import { eventBus, env } from '@/lib/core';

const PAGE_SIZE = 2;
const VAL_LIMIT_FOR_PAGINATION = 250;

export default {
  name: 'RateWarehouseTables',

  components: {
    /* eslint-disable max-len */
    ElPagination: () => import(/* webpackChunkName: "ElPagination" */ '@/lib/core/components/ElPagination'),
    /* eslint-enable max-len */
    RateWarehouseTable
  },

  props: {
    dependency: { type: Object, required: true },
    value: { type: Array, required: true }
  },

  data () {
    return {
      PAGE_SIZE,
      page: 1,
      activeBlock: '',
      parsedValue: [],
      valueByKey: {},
      warehouses: [],
      warehouseByUrl: {},
      collection: {},
      mapCopy: null,
      key: 0
    };
  },

  computed: {
    showByPart () {
      return this.warehouses.length * this.dependency.salePoints.length > VAL_LIMIT_FOR_PAGINATION;
    },

    viewValue () {
      return this.showByPart
        ? this.collectionPart
        : this.collection;
    },

    totalLength () {
      return Object.keys(this.collection).length;
    },

    collectionPart () {
      const start = (this.page - 1) * this.PAGE_SIZE;
      const end = start + this.PAGE_SIZE;

      return Object.keys(this.collection).slice(start, end).reduce((acc, key) => {
        acc[key] = this.collection[key];
        return acc;
      }, {});
    }
  },

  watch: {
    value: {
      deep: true,
      handler: 'checkMapping'
    },
    'dependency.column_mapping': {
      deep: true,
      immediate: true,
      handler: 'checkMapping'
    },
    'dependency.rateUrl' () {
      this.countValue(this.mapCopy);
    }
  },

  created () {
    this.emit = debounce(this.emit, env.DEBOUNCE);
    this.setActiveAll = debounce(this.setActiveAll, env.DEBOUNCE);

    this.showFirstBlock();

    eventBus.$on('warehouses:create', this.setActiveAll);
    eventBus.$on('quantity:remove', this.setActiveAll);
  },

  beforeDestroy () {
    eventBus.$off('warehouses:create', this.setActiveAll);
    eventBus.$off('quantity:remove', this.setActiveAll);
  },

  methods: {
    checkMapping () {
      const mapCopy = this.getMapCopy();

      if (!isEqual(mapCopy, this.mapCopy)) {
        this.countValue(mapCopy);
        // this.key++;
      }
    },

    getMapCopy () {
      const mapping = this.dependency.column_mapping
        ? cloneDeep(this.dependency.column_mapping)
        : [];

      return mapping.filter((col) => {
        return col.type === 'quantity' && col.quantity_warehouse;
      });
    },

    countValue (mapCopy) {
      this.$set(this, 'mapCopy', mapCopy);

      const parsedValue = this.value
        .map(item => ({ ...item, _id: item.point_of_sale + item.warehouse }));

      this.$set(this, 'parsedValue', parsedValue);

      this.valueByKey = keyBy(this.parsedValue, '_id');

      this.warehouses = (
        this.dependency.column_mapping
          ? cloneDeep(this.dependency.column_mapping)
          : []
      )
        .reduce((acc, col) => {
          if (col.type === 'quantity' && col.quantity_warehouse) {
            acc.push(col.quantity_warehouse);
          }
          return acc;
        }, []);

      this.warehouseByUrl = keyBy(this.warehouses, 'url');

      const collection = this.warehouses.reduce((acc, warehouse) => {
        acc[warehouse.name] = this.getTableData(warehouse);
        return acc;
      }, {});

      this.$set(this, 'collection', collection);
    },

    showFirstBlock () {
      this.activeBlock = Object.keys(this.collection)[0];
    },

    emit (value = []) {
      const val = cloneDeep(value).map(({ _id, _salePointName, ...item }) => item);
      this.$emit('update:availabilities', val);
      setTimeout(this.countValue, 0);
    },

    updateRow (row) {
      const index = this.parsedValue.findIndex(item => item._id === row._id);

      if (index > -1) {
        this.parsedValue[index] = row;
      } else {
        this.parsedValue.push(row);
      }

      this.emit(this.parsedValue);
    },

    toggleSelection (list, is_active) {
      const keys = list.map(({ _id }) => _id);

      const value = this.parsedValue.map((item) => {
        return keys.includes(item._id) ? { ...item, is_active } : item;
      });

      this.emit(value);
    },

    setActiveAll () {
      this.countValue();

      const all = flatten(Object.values(this.collection));
      const usedKeys = {};

      const list = uniqBy(this.parsedValue.concat(all), '_id');

      const value = list.reduce((acc, item) => {
        item.is_active = Boolean(!usedKeys[item._id] && this.warehouseByUrl[item.warehouse]);
        usedKeys[item._id] = true;
        acc.push(item);
        return acc;
      }, []);

      this.emit(value);

      this.showFirstBlock();
    },

    copyValue (list, row) {
      const { order_time_until, delivery_time, delivery_schedule } = row;
      const keys = list.map(({ _id }) => _id);
      const value = this.parsedValue.map((item) => {
        if (keys.includes(item._id)) {
          item.order_time_until = order_time_until;
          item.delivery_time = delivery_time;
          item.delivery_schedule = cloneDeep(delivery_schedule);
        }
        return item;
      });

      this.emit(value);
    },

    getTableData (warehouse) {
      const { salePoints, rateUrl } = this.dependency;

      return salePoints.map((point) => {
        const _id = point.url + warehouse.url;
        const existed = this.valueByKey[_id] || {};

        return {
          _id,
          _salePointName: point.name,
          _salePointCity: point.address?.city_detail?.name || '',
          id: existed.id,
          url: existed.url,
          point_of_sale: point.url,
          warehouse: warehouse.url,
          is_active: existed.is_active || false,
          order_time_until: existed.order_time_until || env.DEFAULT_ORDER_TIME_UNTIL,
          delivery_time: existed.delivery_time || env.DEFAULT_DELIVERY_TIME,
          delivery_rate: existed.delivery_rate || rateUrl,
          delivery_schedule: existed.delivery_schedule
        };
      });
    }
  }
};
</script>

<style scoped lang="scss">
.rate-warehouse-tables {
  ::v-deep .el-collapse-item__header {
    color: $--color-text-regular;
    padding: 0 20px;

    .el-collapse-item__arrow {
      display: none;
    }

    .active-icon {
      font-size: 2rem;
      color: $blue-400;
      margin-right: 10px;
      transition: all 0.1s ease-out;
    }

    &.is-active .active-icon {
      transform: rotate(0.25turn)
    }
  }
}
</style>
