var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      staticClass: "interval-selector",
      attrs: { align: "middle", justify: "center" },
    },
    [
      _vm.readonly && !_vm.showControls
        ? [
            _vm.value
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.days + "d") + " " + _vm._s(_vm.hours + "h")
                  ),
                ])
              : _vm._e(),
          ]
        : [
            _c("el-input-number", {
              attrs: {
                "controls-position": "right",
                disabled: _vm.readonly,
                min: 0,
                size: "mini",
              },
              nativeOn: {
                keyup: function ($event) {
                  return _vm.emitByKey("days", $event)
                },
              },
              model: {
                value: _vm.days,
                callback: function ($$v) {
                  _vm.days = $$v
                },
                expression: "days",
              },
            }),
            _vm._v("\n    " + _vm._s(_vm.$t("placeholders.days")) + "\n    "),
            _c("el-input-number", {
              attrs: {
                "controls-position": "right",
                disabled: _vm.readonly || _vm.disabledHours,
                max: 23,
                min: 0,
                size: "mini",
              },
              nativeOn: {
                keyup: function ($event) {
                  return _vm.emitByKey("hours", $event)
                },
              },
              model: {
                value: _vm.hours,
                callback: function ($$v) {
                  _vm.hours = $$v
                },
                expression: "hours",
              },
            }),
            _vm._v("\n    " + _vm._s(_vm.$t("placeholders.hours")) + "\n  "),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }