import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import chunk from 'lodash/chunk';
import without from 'lodash/without';
import random from 'lodash/random';
import get from 'lodash/get';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import env from '../services/envConstants';
import { LAST_URL_INT_REG } from '../validations/patterns';
import { getDefault } from './defaultEntities';
import { i18n } from '@/plugins/i18n';

const BASE_ROUTE_LANG = env.MARKETPLACE_DEFAULT_LANG;

export const getMultipartFormData = (data, file, fileName = 'logo') => {
  const formData = Object.keys(data).reduce((acc, key) => {
    acc.append(key, data[key]);
    return acc;
  }, new FormData());

  if (file) {
    formData.append(fileName, file, file.name);
  }

  return formData;
};

export const getNestedMultipartFormData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;

  for (const property in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '.' + property;
      } else {
        formKey = property;
      }
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        getNestedMultipartFormData(obj[property], fd, property);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export const getDefaultId = () => random(10000000000);

export const addIdsTo = (list) => {
  return list.map((item) => {
    item._id = getDefaultId();
    return item;
  });
};

export const getDayHourBy = (value) => {
  // value = "2 03:00:00"
  const time = value || '0 00:00:00';
  const day = time && time.match(/^\d+/);

  return {
    days: Number.parseInt(day && time.length > 8 ? day[0] : '0'),
    hours: Number.parseInt(time ? time.match(/\s?(\d+):/)[1] : '0')
  };
};

export const getGetInformDeliveryTime = (value, options = {}) => {
  // 1 00:00:00
  // 03:00:00
  const { isEmpty, suffix, ware } = options;
  const { days, hours } = getDayHourBy(value);

  const ad = ware?.availability_date
    ? ware.availability_date.split('-').reverse().join('.')
    : '';

  if (ware) {
    if (!ware.price) {
      return { type: 'red', value: i18n.t('search.not_in_stock') };
    }

    if (ware.in_stock) {
      return {
        type: 'green',
        value: i18n.t('search.in_stock'),
        bold: true,
        datetime: dayjs().format('YYYY-MM-DD')
      };
    }

    if (days < 1) {
      return {
        type: 'green',
        value: i18n.t('search.todayIn'),
        datetime: dayjs().format('YYYY-MM-DD')
      };
    }

    if (days === 1) {
      return {
        type: 'green',
        value: i18n.t('search.tomorrow'),
        datetime: dayjs().add(1, 'day').format('YYYY-MM-DD')
      };
    }

    if (ad && options.useDateIfDaysMore && options.useDateIfDaysMore <= days) {
      return {
        type: options.warning ? 'red' : 'grey',
        value: ad,
        warning: options.warning,
        ghost: !options.warning,
        datetime: ware.availability_date
      };
    }

    if (ad) {
      return {
        type: options.warning ? 'red' : 'grey',
        value: ad,
        warning: options.warning,
        ghost: !options.warning,
        datetime: ware.availability_date
      };
    }
  }

  if (isEmpty) {
    return { type: 'red', value: i18n.t('search.not_in_stock') };
  }

  if (!days && !hours) {
    return {
      type: 'green',
      value: i18n.t('search.in_stock'),
      bold: true,
      datetime: dayjs().format('YYYY-MM-DD')
    };
  }

  if (!days) {
    return {
      type: 'green',
      value: i18n.t('search.todayIn'),
      datetime: dayjs().format('YYYY-MM-DD')
    };
  }

  if (suffix) {
    let suffix = '';
    const datStr = String(days);
    const _last = Number(last(datStr));
    const _last2 = Number(datStr.substring(datStr.length, -2));

    if (days > 9 && _last2 > 9 && _last2 < 21) {
      suffix = i18n.t('el.day3');
    } else if ([1].includes(_last)) {
      suffix = i18n.t('el.day1');
    } else if ([2, 3, 4].includes(_last)) {
      suffix = i18n.t('el.day2');
    } else if ([0, 5, 6, 7, 8, 9].includes(_last)) {
      suffix = i18n.t('el.day3');
    }

    return {
      type: 'grey',
      value: `${days} ${suffix}`,
      ghost: true,
      datetime: dayjs().add(days, 'day').format('YYYY-MM-DD')
    };
  }

  return {
    type: 'grey',
    value: days,
    ghost: true,
    datetime: dayjs().add(days, 'day').format('YYYY-MM-DD')
  };
};

export const getPriceNum = (price, { toEmpty } = {}) => {
  if (!Number(price) && toEmpty) {
    return '';
  } else if (!price) {
    return `0${env.PRICE_SPLITTER}00`;
  }

  const [pref, suff] = String(price)
    .replace(/,/g, '.')
    .split('.');

  const _pref = Number((pref || '0').replace(/\s/g, ''))
    .toLocaleString()
    .replace(/,/g, ' ');

  const _suff = (suff || '00').slice(0, 2);

  return `${_pref}${env.PRICE_SPLITTER}${_suff}`;
};

export const getPriceWithCurrency = (price) => {
  // price - number int (103)
  const currency = i18n.t(`currencies.${env.MARKETPLACE_CURRENCY}`);
  return `${getPriceNum(price)} ${currency}`;
};

export const getIdFromUrl = (url) => {
  // https://dev.mriyar.ua/api/mp/v1/baskets/22928/

  if (!url) {
    return null;
  }

  const rawId = url.match(LAST_URL_INT_REG)[1];
  return Number(rawId);
};

export const fixPhoneNumber = (phoneObject) => {
  phoneObject.phone_number = phoneObject.phone_number.replace(/[^+\d]/g, '');
  return phoneObject;
};

export const getPrimaryOrFirst = (list, defaultEntityName) => {
  if (!list || !list.length) {
    return getDefault(defaultEntityName);
  } else {
    const primary = list.find(({ is_primary }) => is_primary);

    return cloneDeep(primary || { ...list[0], is_primary: true });
  }
};

const forCityDelivery = ['pickup', 'taxi', 'courier', 'payment_upon_receipt'];
const forCityOther = ['with_installation_only'];
const forCityPayment = ['payment_upon_receipt'];

const isFilterForCity = (filter, { skipInStock } = {}) => {
  const res =
    (!skipInStock && filter.in_stock) ||
    (filter.delivery_methods &&
      filter.delivery_methods.split(',').some(item => forCityDelivery.includes(item))) ||
    (filter.other_selling_terms &&
      filter.other_selling_terms.split(',').some(item => forCityOther.includes(item))) ||
    (filter.payment_terms &&
      filter.payment_terms.split(',').some(item => forCityPayment.includes(item)));

  return Boolean(res);
};

export const getRouteByLang = (route, lang = i18n.locale) => {
  const { name, params, query } = route;
  const rawName = (name || '').split('__')[0];
  const _name = lang === BASE_ROUTE_LANG ? rawName : `${rawName}__${lang}`;
  return { name: _name, params, query };
};

export const seoParams = [
  'wareId',
  'trademarkId',
  'carBrand',
  'carModel',
  'carGeneration',
  'carModification',
  'lineId',
  'regionId',
  'page'
];

export const getClearSeoParams = (params) => {
  return Object.entries(params)
    .reduce((acc, [key, value]) => {
      if (seoParams.includes(key) && value) {
        acc[key] = value;
      }

      return acc;
    }, {});
};

export const getSearchRouteBy = (vm, ware, forceParams = {}) => {
  const {
    ware_id,
    trademark,
    article,
    tm_name,
    price_search_ware_key
  } = ware;

  const wareId = forceParams.id || get(ware, 'ware.id') || ware_id;
  const _trademark = tm_name ||
    (typeof trademark === 'object' ? trademark.name : trademark);

  return {
    name: vm.getRouteName('article'),
    params: {
      wareIndex: price_search_ware_key
    },
    query: {
      q: forceParams.q || article,
      trademark: _trademark,
      article,
      wareId,
      ...(forceParams.query || {})
    }
  };
};

export const getWareTotalQuantity = (items) => {
  return (items || []).reduce((acc, { error_code, quantity }) => {
    return error_code === 'not_found' ? acc : acc + quantity;
  }, 0);
};

export const getWareTotalAmountNum = (items) => {
  return (items || []).reduce((acc, { error_code, price, quantity }) => {
    return error_code === 'not_found'
      ? acc
      : acc + (Number.parseFloat(price) * quantity);
  }, 0);
};

export const debounce = time => new Promise((resolve) => { setTimeout(resolve, time); });

// _env - use only for tests
export const getUrlBy = (name, id, _env = env) => {
  const _BAU = _env.GARAGE_API.startsWith('http') ? '' : _env.BASE_API_URL;

  return id && name
    ? `${_BAU}${_env.GARAGE_API}${name}/${id}/`
    : null;
};

export const getApiUrl = (name, id) => {
  const _BAU = env.BASE_API.startsWith('http') ? '' : env.BASE_API_URL;

  return id && name
    ? `${_BAU}${env.BASE_API}${name}/${id}/`
    : null;
};

export const goToSellerSite = (vm, entity) => {
  vm.$store.dispatch('analytics/postClick', {
    referer_url: `${env.BASE_CLIENT_PATH}${vm.$route.fullPath}`,
    click_type: 'seller_site',
    target_type: 'seller_site_home',
    seller: entity.seller?.business?.url,
    target_url: entity._searchOnSellerSiteUrl
  });

  return vm.$router.blank({
    name: vm.getRouteName('home'),
    query: {
      goTo: entity._searchOnSellerSiteUrl
    }
  });
};

export const toggleInList = (list, value) => {
  return list.includes(value)
    ? without(list, value)
    : [...list, value];
};

export const splitByChunks = (list, num) => {
  const chunkNum = Math.ceil(list.length / num);
  return chunk(list, chunkNum);
};

export const getOrderLink = (vm, order, options = {}) => {
  const { suffix = 'order', params = {} } = options;

  return vm.$store.getters['auth/isAuthenticated'] || !order.hash_id
    ? {
        name: vm.getRouteName(`basket.${suffix}`),
        params: { orderId: order.id, ...params }
      }
    : {
        name: vm.getRouteName(`basket.${suffix}.hash`),
        params: { hashId: order.hash_id, ...params },
        query: { hashId: order.hash_id }
      };
};

export const camelToSnakeCase = (str, splitter = '_') => {
  return str.replace(
    /[A-Z]/g,
    letter => `${splitter}${letter.toLowerCase()}`
  );
};

export const snakeToCamelCase = (str) => {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const getClearFromEmpty = (object) => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (!value || key.startsWith('_')) {
      return acc;
    }

    if (Array.isArray(value)) {
      acc[key] = value.map(getClearFromEmpty);
    } else if (typeof value === 'object') {
      const res = getClearFromEmpty(value);

      if (!isEmpty(res)) {
        acc[key] = res;
      }
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export default {
  getOrderLink,
  debounce,
  getMultipartFormData,
  getDayHourBy,
  getGetInformDeliveryTime,
  getPriceNum,
  getPriceWithCurrency,
  getIdFromUrl,
  getDefaultId,
  addIdsTo,
  fixPhoneNumber,
  getPrimaryOrFirst,
  isFilterForCity,
  getNestedMultipartFormData,
  getRouteByLang,
  getClearSeoParams,
  getSearchRouteBy,
  // basket
  getWareTotalQuantity,
  getWareTotalAmountNum,
  getUrlBy,
  goToSellerSite,
  getClearFromEmpty
};
