var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wares-small-table" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.value,
            "header-cell-style": { textAlign: "center" },
            "row-key": _vm.rowKey,
            stripe: "",
          },
          on: { "row-click": _vm.goToOrEmit },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("search.tm_name"),
              width: _vm.maxmq("xs") ? null : "150px",
              prop: "trademark.name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " + _vm._s(row.trademark.name) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("search.article"),
              width: _vm.maxmq("xs") ? null : "180px",
              prop: "article",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("search.article_name"), prop: "name" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _vm._t(
                        "description",
                        function () {
                          return [
                            _vm._v(
                              "\n          " + _vm._s(row.name) + "\n        "
                            ),
                          ]
                        },
                        null,
                        { row: row }
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }