var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "doc-uploader" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "mb-1",
          attrs: {
            action: "https://mp-api.dev.mriyar.ua/",
            "auto-upload": false,
            drag: "",
            "on-change": _vm.changeHandler,
            "on-remove": _vm.clear,
            "show-file-list": !_vm.hideList,
          },
        },
        [
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v(_vm._s(_vm.label || _vm.$t("files.dropOrClick"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }