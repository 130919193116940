<template>
  <div class="delivery-service-select">
    <el-form-item
      :label="$t('basket.deliveryRegion')"
      prop="delivery_address.region"
      :rules="dependency.rules['delivery_address.region']"
    >
      <SearchLocationSelect
        class="w-100pr"
        :value="value.region ? value.region_detail : null"
        field="admin1"
        label-name="full_name"
        @input="setRegion"
      />
    </el-form-item>

    <el-form-item
      :label="$t('basket.deliveryCity')"
      prop="delivery_address.city"
      :rules="dependency.rules['delivery_address.city']"
    >
      <SearchLocationSelect
        class="w-100pr"
        :value="city"
        field="city"
        label-name="full_name"
        allow-create
        :params="locationParams"
        @input="setCity"
      />
    </el-form-item>

    <el-form-item
      v-if="type !== 'address'"
      :label="$t('basket.service_name')"
      prop="delivery_address.service_name"
      :rules="dependency.rules['delivery_address.service_name']"
    >
      <el-select
        class="w-100pr"
        :value="value.service"
        @change="setService"
      >
        <template slot="prefix">
          <BlockSize
            class="mt-1 mr-1"
            size="xs"
            :src="getSelectPrefixIcon()"
          />
        </template>

        <el-option
          v-for="service in deliveryServices"
          :key="service.id"
          :label="$t(`deliveryServices.${service.label.replace(/\./g, '_')}`)"
          :value="service.label"
        >
          <el-row align="middle">
            <BlockSize class="mr-1" size="xs" :src="getIcon(service.label)" />
            <div>
              {{ $t(`deliveryServices.${service.label.replace(/\./g, '_')}`) }}
            </div>
          </el-row>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      v-if="type === 'service_office'"
      :label="$t('basket.service_office_number')"
      prop="delivery_address.service_office_number"
      :rules="dependency.rules['delivery_address.service_office_number']"
    >
      <el-input
        :value="value.service_office_number"
        @input="
          setField('delivery_address.service_office_number', $event);
          clearId()
        "
      />
    </el-form-item>

    <el-form-item
      v-else-if="type === 'address'"
      class="relative"
      prop="delivery_address.text"
      :label="$t('basket.orderAddress')"
      :rules="dependency.rules['delivery_address.text']"
    >
      <el-input
        clearable
        :value="value.text"
        @input="onInputAddress"
        @blur="clearResult"
      />

      <div
        v-if="searchResult.length"
        class="custom-autocomplete back-shadow scroll"
      >
        <div
          v-for="item in searchResult"
          :key="item.id"
          class="item"
          @click.stop="setSelected(item)"
        >
          {{ item.text }}
        </div>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import SearchLocationSelect from '@/lib/core/components/SearchLocationSelect';
import BlockSize from '@/lib/core/components/BlockSize';
import env from '@/lib/core/services/envConstants';

export default {
  name: 'DeliveryServiceSelect',

  components: {
    BlockSize,
    SearchLocationSelect
  },

  props: {
    value: { type: Object, required: true },
    setField: { type: Function, required: true },
    type: { type: String, default: 'service_office' },
    dependency: { type: Object, required: true }
  },

  data: () => ({ searchResult: [] }),

  computed: {
    city () {
      if (this.value.city) {
        return this.value.city_detail;
      } else if (this.value.city_text) {
        return {
          id: 0,
          full_name: this.value.city_text,
          name: this.value.city_text
        };
      }

      return null;
    },

    locationParams () {
      return {
        country: { id: env.MARKETPLACE_COUNTRY_ID },
        admin1: this.value.region_detail
      };
    },

    deliveryServices () {
      return this.dependency.salePoint.delivery_services.reduce((acc, it) => {
        if (it.is_active && it.service_detail.is_active) {
          acc.push(it.service_detail);
        }
        return acc;
      }, []);
    }
  },

  watch: {
    'value.type': {
      immediate: true,
      handler () {
        if (
          this.value.type === 'service_office' &&
          !this.value.service &&
          this.deliveryServices.length === 1
        ) {
          this.setService(this.deliveryServices[0].label);
        }
      }
    }
  },

  created () {
    this.searchBy = debounce(this.searchBy, env.DEBOUNCE);
  },

  mounted () {
    this.$store.dispatch('dependencies/fetchDeliveryServices');

    if (this.dependency.city?.admin1) {
      this.setField('delivery_address.region', this.dependency.city?.admin1);
    }
  },

  methods: {
    setRegion (data) {
      this.setField('delivery_address.region', data.url || '');
      this.setField('delivery_address.region_detail', {
        name: data.name || '',
        full_name: data.full_name || '',
        id: data.id || null
      });

      this.clearId();
    },

    setCity (data) {
      if (typeof data === 'string') {
        this.setField('delivery_address.city_text', data);
        this.setField('delivery_address.city', null);
        this.setField('delivery_address.city_detail', {
          name: data,
          full_name: data,
          id: data
        });
      } else {
        this.setField('delivery_address.city_text', '');
        this.setField('delivery_address.city', data.url);
        this.setField('delivery_address.city_detail', {
          name: data.name,
          full_name: data.full_name,
          id: data.id
        });
      }

      this.clearId();
    },

    setService (name) {
      const service = this.deliveryServices.find(item => item.label === name);

      if (service) {
        this.setField('delivery_address.service', service.label);
        const _name = this.$t(`deliveryServices.${service.label.replace(/\./g, '_')}`);
        this.setField('delivery_address.service_name', _name);
      } else {
        this.setField('delivery_address.service', name);
        this.setField('delivery_address.service_name', '');
      }

      this.setField('delivery_address.service_office_number', '');

      this.clearId();
    },

    getIcon (name) {
      const _name = name.replace(/\./g, '_');
      return require(`@/assets/icons/deliveryServiceIcons/${_name}.svg`);
    },

    getSelectPrefixIcon () {
      const id = this.value.service?.replace(/\./g, '_');
      return this.getIcon(id || 'other');
    },

    onInputAddress (value) {
      this.setField('delivery_address.text', value);

      if (this.$store.getters['auth/isAuthenticated']) {
        this.searchBy(value);
      }

      this.clearId();
    },

    searchBy (value) {
      const { addresses } = this.dependency;

      if (!value || value.length < 2 || !addresses.length) {
        this.searchResult = [];
        return this.searchResult;
      }

      const reg = new RegExp(value.toLowerCase());

      this.searchResult = addresses
        .filter(({ text }) => reg.test(text.toLowerCase()));
    },

    setSelected (address) {
      this.setField('delivery_address', address);
      this.searchResult = [];
    },

    clearResult () {
      setTimeout(() => { this.searchResult = []; }, 1000);
    },

    clearId () {
      this.setField('delivery_address.id');
    }
  }
};
</script>
