var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["buyer-data", { row: !_vm.isMobile }] }, [
    _vm.isForm
      ? _c(
          "div",
          { class: ["form-block mb-2", { "fit-content": !_vm.isMobile }] },
          [
            _c("div", { staticClass: "block-title mt-05" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("basket.buyer")) + "\n    "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buyer-block no-wrap-error" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      prop: "buyer_phone_number",
                      label: _vm.$t("basket.buyer_phone_number"),
                    },
                  },
                  [
                    _c("PhoneInput", {
                      attrs: {
                        value: _vm.value.buyer_phone_number.phone_number,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.setField(
                            "buyer_phone_number.phone_number",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "checkbox mt-1",
                attrs: { prop: "no_call_required" },
              },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: {
                      label: _vm.$t("basket.no_call_required"),
                      value: _vm.value.no_call_required,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.setField("no_call_required", $event)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "text-small" }, [
                      _vm._v(_vm._s(_vm.$t("basket.no_call_required"))),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "buyer-data-readonly mb-1" },
              [
                _c("span", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("basket.buyer")) + ":\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "buyer-data__name" }, [
                  _vm.value.buyer_last_name || _vm.value.buyer_first_name
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.value.buyer_last_name) +
                            " " +
                            _vm._s(_vm.value.buyer_first_name) +
                            ",\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n\n        " +
                      _vm._s(_vm.value.buyer_phone_number.phone_number) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("is-form:change", true)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("buttons.change")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "checkbox white-back pg-0",
                attrs: { prop: "no_call_required" },
              },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { value: _vm.value.no_call_required },
                    on: {
                      change: function ($event) {
                        return _vm.setField("no_call_required", $event)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "text-small" }, [
                      _vm._v(_vm._s(_vm.$t("basket.no_call_required"))),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
    _vm._v(" "),
    _vm.isForm
      ? _c(
          "div",
          [_vm._t("options", null, null, { isMobile: _vm.isMobile })],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }