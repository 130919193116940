<template>
  <VFacebookLogin
    :app-id="SOCIAL_AUTH_FACEBOOK_KEY"
    @mousedown.native="checkAndLogin"
    @sdk-init="setDependency"
  >
    <template #logout>{{ $t('buttons.facebook') }}</template>
  </VFacebookLogin>
</template>

<script>
import VFacebookLogin from './FB/VFacebookLogin';
import { env } from '@/lib/core';

export default {
  name: 'FacebookBtn',

  components: {
    VFacebookLogin
  },

  data: () => ({
    FB: null,
    SOCIAL_AUTH_FACEBOOK_KEY: env.SOCIAL_AUTH_FACEBOOK_KEY,
    params: {
      scope: 'public_profile,email'
    }
  }),

  methods: {
    setDependency ({ FB }) {
      this.FB = FB;
    },

    checkAndLogin () {
      if (this.FB.getAccessToken()) {
        this.FB.logout(() => this.FB.login(this.login, this.params));
      } else {
        this.FB.login(this.login, this.params);
      }
    },

    login () {
      this.FB && this.FB.api(
        '/me',
        'GET',
        { fields: this.params.scope },
        this.submitLogin
      );
    },

    async submitLogin () {
      const access_token = this.FB.getAccessToken();
      if (!access_token) { return; }

      try {
        const user = await this.$store.dispatch('auth/loginBySocial', {
          provider: 'facebook',
          access_token
        });

        this.$emit('registered', user);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-facebook-login {
  display: flex;
  align-items: center;
  height: 34px;
  min-width: unset;
  width: 100%;
  font-size: 10px;
  padding: 0 4px;

  img {
    top: 6px;
    width: 22px;
    height: auto;
  }
}
</style>
