var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-registration-forms" },
    [
      _c(
        "el-row",
        { staticClass: "form-block", attrs: { gutter: 8 } },
        [
          _c("LoginFormCard", {
            attrs: { "hide-close-icon": "", "show-social-links": "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "splitter" }, [
            _c("div", { staticClass: "line" }),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.$t("auth.or"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "line" }),
          ]),
          _vm._v(" "),
          _c("RegistrationFormCard", { attrs: { "hide-close-icon": "" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }