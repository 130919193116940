var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.basket
    ? _c(
        "div",
        { staticClass: "basket-nav" },
        [
          _vm.isAuthenticated
            ? _c(
                "nuxt-link",
                {
                  attrs: {
                    to: {
                      name: _vm.getRouteName("basket.orders"),
                      params: { pointId: 0, id: _vm.basket.id },
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        circle: _vm.maxmq("md"),
                        icon: "el-icon-folder-checked",
                        round: !_vm.maxmq("md"),
                        size: "mini",
                        type:
                          _vm.$route.name === "basket.orders" ? "primary" : "",
                      },
                    },
                    [
                      !_vm.maxmq("md")
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("routes.basket_orders"))),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }