<template>
  <div class="physical-person">
    <el-form-item
      :label="$t('requisites.info.lastName')"
      prop="last_name"
    >
      <el-input
        class="max-w-420p"
        :value="value.last_name"
        @input="emit('last_name', $event)"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.firstName')"
      prop="first_name"
    >
      <el-input
        class="max-w-420p"
        :value="value.first_name"
        @input="emit('first_name', $event)"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.middleName')"
      prop="middle_name"
    >
      <el-input
        class="max-w-420p"
        :value="value.middle_name"
        @input="emit('middle_name', $event)"
      />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'PhysicalPerson',

  props: {
    value: { type: Object, required: true }
  },

  methods: {
    emit (name, value) {
      this.$emit(`update:${name}`, value);
    }
  }
};
</script>
