var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warehouse-input", on: { click: _vm.toggleMode } },
    [
      _c(
        "TheTooltip",
        { attrs: { content: _vm.$t("buttons.edit") } },
        [
          _c("el-input", {
            ref: "input",
            attrs: { disabled: _vm.disabled },
            on: { blur: _vm.updateWarehouseName },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.updateWarehouseName.apply(null, arguments)
              },
            },
            model: {
              value: _vm.val,
              callback: function ($$v) {
                _vm.val = $$v
              },
              expression: "val",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "el-form-item__error" }, [
        _vm._v(_vm._s(_vm.existedError)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }