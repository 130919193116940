var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "catalog-page" }, [
    _c("h1", { staticClass: "custom-headline custom-headline--level2-bold" }, [
      _vm._v("\n    " + _vm._s(_vm.pageTitle) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-4" },
      [
        !_vm.$route.query.cm
          ? _c(
              "span",
              { staticClass: "custom-headline custom-headline--level2" },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("garage.chooseYourCar")) + "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("GarageWidget", {
          attrs: { "default-data": _vm.garageDefaultData, "use-seo-link": "" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb-2" },
      [
        _vm.options.catalogTree
          ? _c("CatalogTree", {
              attrs: { dependency: _vm.dependency, value: _vm.catalog },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.options.searchCatalogTile
          ? _c("SearchCatalogTile", {
              attrs: {
                "analogs-res": _vm.analogsRes,
                dependency: _vm.dependency,
                trademark: _vm.trademark,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cv" },
      [
        _vm.options.seoInfo || _vm.options.questionAnswer
          ? [
              _c("hr"),
              _vm._v(" "),
              _vm.options.seoInfo
                ? _c("SeoInfo", {
                    staticClass: "mb-4",
                    attrs: {
                      "is-show-prices": _vm.options.topProductsPrices,
                      meta: _vm.meta,
                      title: _vm.topProductsPricesTitle,
                      wares: _vm.analogsRes.results,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.options.questionAnswer
                ? _c("QuestionAnswer", {
                    staticClass: "mb-2",
                    attrs: {
                      title: _vm.questionAnswerTitle,
                      "max-price": _vm.analogsRes.max_price,
                      "min-price": _vm.analogsRes.min_price,
                      wares: _vm.analogsRes.results,
                      dependency: _vm.dependency,
                    },
                  })
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.options.topProductLines
          ? [
              _c("hr"),
              _vm._v(" "),
              _c("TopProductLines" + (_vm.isMobile ? "M" : ""), {
                tag: "component",
                staticClass: "mb-2",
                attrs: {
                  dependency: _vm.dependency,
                  title: _vm.topProductLinesTitle,
                  value: _vm.topProductLines,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.options.carsBrandsModels
          ? [
              _c("hr"),
              _vm._v(" "),
              _c("CarsBrandsModels", {
                staticClass: "mb-2",
                attrs: {
                  dependency: _vm.dependency,
                  title: _vm.topCarsBrandsTitle,
                  value: _vm.carsBrands,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.options.topCarsBrands
          ? [
              _c("hr"),
              _vm._v(" "),
              _c("TopCarsBrands" + (_vm.isMobile ? "M" : ""), {
                tag: "component",
                staticClass: "mb-2",
                attrs: {
                  dependency: _vm.dependency,
                  title: _vm.topCarsBrandsTitle,
                  value: _vm.topCarsBrands,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.options.topCarsModels
          ? [
              _c("hr"),
              _vm._v(" "),
              _c("TopCarsModels" + (_vm.isMobile ? "M" : ""), {
                tag: "component",
                staticClass: "mb-2",
                attrs: {
                  dependency: _vm.dependency,
                  title: _vm.topCarsModelsTitle,
                  value: _vm.topCarsModels,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.options.topCarsGenerations
          ? [
              _c("hr"),
              _vm._v(" "),
              _c("TopCarsGenerations" + (_vm.isMobile ? "M" : ""), {
                tag: "component",
                staticClass: "mb-2",
                attrs: {
                  dependency: _vm.dependency,
                  title: _vm.topCarsGenerationsTitle,
                  value: _vm.topCarsGenerations,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.options.topCarsModifications
          ? [
              _c("hr"),
              _vm._v(" "),
              _c("TopCarsModifications" + (_vm.isMobile ? "M" : ""), {
                tag: "component",
                staticClass: "mb-2",
                attrs: {
                  dependency: _vm.dependency,
                  title: _vm.topCarsModificationsTitle,
                  value: _vm.topCarsModifications,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.options.topTrademarks
          ? [
              _c("hr"),
              _vm._v(" "),
              _c("TopTrademarks" + (_vm.isMobile ? "M" : ""), {
                tag: "component",
                staticClass: "mb-2",
                attrs: {
                  dependency: _vm.dependency,
                  title: _vm.topTrademarksTitle,
                  value: _vm.topTrademarks,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.options.topRegions
          ? [
              _c("hr"),
              _vm._v(" "),
              _c("TopRegions" + (_vm.isMobile ? "M" : ""), {
                tag: "component",
                staticClass: "mb-2",
                attrs: {
                  dependency: _vm.dependency,
                  title: _vm.topRegionsTitle,
                  value: _vm.topRegions,
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }