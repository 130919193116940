import { env } from '@/lib/core';

export const actions = {
  async getModels ({ dispatch }, params) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: '/car-models/',
      params: {
        ordering: 'name',
        ...params
      },
      progress: false
    });

    return data;
  }
};
