var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sale-point-address pg-1 flex fl-wrap" }, [
    _c("div", { staticClass: "mr-2 mb-2" }, [
      _c("div", { staticClass: "el-form-item__label" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("basket.salePointAddress")) + ":\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "address" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.getConcatedAddress(_vm.address, { hideCountry: true })) +
            "\n    "
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.address.latitude && _vm.address.longitude
      ? _c(
          "div",
          [
            _c("LocationSelectMap", {
              attrs: {
                readonly: "",
                "search-query-string": _vm.getConcatedAddress(_vm.address),
                value: _vm.getLocation(_vm.address),
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }