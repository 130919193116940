<template>
  <div class="steps-panel">
    <el-steps :active="active" finish-status="success" align-center>
      <el-step
        v-for="(step, index) in $t(`${entity}s.steps`)"
        :key="index"
        :description="step"
      />
    </el-steps>
  </div>
</template>

<script>
import ElSteps from 'element-ui/lib/steps';
import ElStep from 'element-ui/lib/step';

export default {
  name: 'StepsPanel',

  components: {
    ElSteps,
    ElStep
  },

  props: {
    entity: { type: String, default: 'price' },
    active: { type: Number, default: 0 }
  }
};
</script>

<style scoped lang="scss">
.steps-panel {
  margin-bottom: 10px;

  ::v-deep {
    .is-process {
      color: $--color-text-regular!important;
    }

    .el-step__head.is-process .el-step__icon.is-text {
      border-color: $--color-text-regular;
    }

    .el-step__main {
      margin-top: 10px;
    }

    .el-step .el-step__description {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
</style>
