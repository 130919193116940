var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-delivery" }, [
    _c("div", { staticClass: "delivery-method" }, [
      _c(
        "div",
        { staticClass: "mr-2" },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("basket.delivery_method"),
                prop: "delivery_method",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { value: _vm.deliveryMethod },
                  on: { input: _vm.toggleDeliveryMethod },
                },
                _vm._l(_vm.deliveryMethods, function (method) {
                  return _c(
                    "el-radio",
                    { key: method.key, attrs: { label: method.key } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(method.title) + "\n          "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.componentInfo
        ? _c(
            "div",
            [
              _c(
                _vm.componentInfo.component,
                _vm._b(
                  {
                    ref: "component",
                    tag: "component",
                    staticClass: "mb-2",
                    attrs: {
                      "set-field": _vm.setField,
                      value: _vm.value.delivery_address,
                      type: _vm.value.delivery_address.type,
                      dependency: Object.assign({}, _vm.dependency, {
                        addresses: _vm.addressesByType,
                      }),
                    },
                  },
                  "component",
                  _vm.componentInfo.attrs,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.componentInfo && _vm.componentInfo.isRecipient
      ? _c(
          "div",
          [
            _c("div", { staticClass: "mb-2 bold" }, [
              _vm._v(_vm._s(_vm.$t("basket.waybillTitle"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-2 fl-wrap" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      label: _vm.$t("basket.waybill_date"),
                      prop: "waybill_date",
                    },
                  },
                  [
                    _c("el-date-picker", {
                      attrs: { type: "date", value: _vm.value.waybill_date },
                      on: {
                        input: function ($event) {
                          return _vm.setField("waybill_date", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("basket.waybillNum"),
                      prop: "waybill",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { value: _vm.value.waybill },
                      on: {
                        input: function ($event) {
                          return _vm.setField("waybill", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mb-2 bold" }, [
              _vm._v(_vm._s(_vm.$t("basket.orderRecipient"))),
            ]),
            _vm._v(" "),
            _c(
              "OrderRecipient",
              _vm._g(
                {
                  ref: "recipient",
                  attrs: {
                    value: _vm.value,
                    recipients: _vm.recipients,
                    "set-field": _vm.setField,
                    dependency: _vm.dependency,
                  },
                },
                _vm.$listeners
              )
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }