<template>
  <div class="s-requests-processing-table s-requests-table">
    <el-row v-if="!value.length" justify="center" class="pg-2">
      {{ $t('el.table.emptyText') }}
    </el-row>

    <div v-else>
      <div
        v-for="request in value"
        :key="request.id"
        :class="['request', { grey: !isAnswer(request) }]"
      >
        <el-collapse v-model="activeBlocks" class="mb-2" accordion>
          <el-collapse-item :name="request.id">
            <template slot="title">
              <div class="title-wrapper">
                <div class="title mr-2">
                  {{ getShortCarTitleOld(request.car_detail) }}
                </div>

                <div class="meta text-ghost text-right">
                  <div class="text-right">№{{ request.public_id }}</div>
                  <div class="text-right bold">
                    {{ getDateTime(request.created) }}
                  </div>
                </div>
              </div>
            </template>

            <component
              :is="`CarFullInfoItem${maxmq('sm') ? 'M' : ''}`"
              :value="request.car_detail"
              readonly
              old
            />
          </el-collapse-item>
        </el-collapse>

        <el-row justify="space-between">
          <div class="mr-2">
            {{ getRawTitles(request.items) }}
          </div>

          <div>
            <el-button
              :type="!isAnswer(request) ? 'primary' : ''"
              @click="$emit('select', request)"
            >
              {{ getBtnText(request) }}
            </el-button>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { filters } from '@/lib/core';
import { getShortCarTitleOld } from '@/lib/garage/services/carParsers';

export default {
  name: 'SRequestsProcessingTable',

  components: {
    /* eslint-disable max-len */
    CarFullInfoItem: () => import(/* webpackChunkName: "CarFullInfoItem" */ '@/lib/garage/components/CarFullInfoItem'),
    CarFullInfoItemM: () => import(/* webpackChunkName: "CarFullInfoItemM" */ '@/lib/garage/components/CarFullInfoItemM')
    /* eslint-enable max-len */
  },

  props: {
    value: { type: Array, required: true }
  },

  data: () => ({
    activeBlocks: ''
  }),

  methods: {
    getShortCarTitleOld,
    getDateTime: filters.getDateTime,

    getRawTitles (items) {
      return items.map(({ raw_item }) => raw_item).join(', ');
    },

    isAnswer (request) {
      return request.items
        .some(({ selection_responses }) => selection_responses.length);
    },

    getBtnText (request) {
      return this.$t(`buttons.answer${this.isAnswer(request) ? 'Show' : ''}`);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/s-requests-table.scss";

.s-requests-processing-table {
  .request {
    &.grey {
      background-color: $grey-300;
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 10px;

      .title {
        line-height: 1rem;
        margin-bottom: 0!important;
      }
    }

    ::v-deep {
      .el-collapse-item__header {
        line-height: 1rem!important;
      }
      .el-collapse-item__content {
        padding-bottom: 0;
      }
    }
  }

  @media all and (max-width: $--md) {
    .request {
      .title-wrapper {
        flex-direction: column;
        justify-content: start;
        align-items: start;
      }
    }
  }
}
</style>
