<template>
  <div class="main-requisites-block mt-2">
    <el-form-item
      :label="$t('requisites.info.type')"
      prop="type"
    >
      <el-radio-group
        prop="mainInfo.type"
        :value="value.type"
        @input="$emit('update:type', $event)"
      >
        <el-row class="mt-1">
          <el-radio
            v-for="field in Object.keys(componentsNames)"
            :key="field" :label="field"
          >
            {{ $t(`requisites.${field}`) }}
          </el-radio>
        </el-row>
      </el-radio-group>
    </el-form-item>

    <component
      :is="componentsNames[value.type]"
      :value="value"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import JuridicalPersonInfo from '../formItems/JuridicalPersonInfo';
import EntrepreneurInfo from '../formItems/EntrepreneurInfo';
import PhysicalPerson from '../formItems/PhysicalPerson';

export default {
  name: 'MainRequisitesBlock',

  components: { JuridicalPersonInfo, EntrepreneurInfo, PhysicalPerson },

  props: {
    value: { type: Object, required: true }
  },

  data () {
    return {
      componentsNames: {
        juridical_person: 'JuridicalPersonInfo',
        entrepreneur: 'EntrepreneurInfo',
        physical_person: 'PhysicalPerson'
      }
    };
  }
};
</script>
