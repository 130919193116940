var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c(
        "el-tooltip",
        _vm._b(
          {
            attrs: {
              content: _vm.content,
              effect: "light",
              "open-delay": _vm.openDelay,
              placement: _vm.placement,
            },
          },
          "el-tooltip",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      )
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }