import set from 'lodash/set';
import errorHandler from './errorHandler';
import env from '@/lib/core/services/envConstants';

const isAuthApi = baseURL => /\/api\/(os|mp|garage)\//.test(baseURL);

export default function ({ $sentry, $axios, store, route }) {
  $axios.interceptors.request.use(async (request) => {
    // it is spike for work with api from localhost without CORS errors
    // const a = request.baseURL;

    if (
      store.getters.isLocalDev &&
      env.BASE_API_URL &&
      !request.baseURL.startsWith('http')
    ) {
      if (request.baseURL === '/') {
        request.baseURL = env.BASE_API;
      } else if (
        request.baseURL === 'http://localhost:3000'
      ) {
        if (request.url !== env.GRAPHQL_API_URL) {
          request.baseURL = env.BASE_API;
        } else {
          request.baseURL = request.baseURL + env.BASE_API;
        }
      } else {
        const x = request.baseURL === '/'
          ? env.BASE_API.replace(/\/$/, '')
          : '';
        request.baseURL = env.BASE_API_URL + x + request.baseURL;
      }
    } else if (!request.baseURL.startsWith('http')) {
      request.baseURL = env.BASE_API_URL + request.baseURL;
    }

    const _isAuthApi = isAuthApi(request.baseURL);
    const token = store.getters['auth/token'];

    if (token && _isAuthApi) {
      request.headers.Authorization = `JWT ${token}`;
    } else {
      delete request.headers.Authorization;
      delete request.headers.common.Authorization;
    }

    if (_isAuthApi) {
      const bp = store.getters['businessProfiles/businessId'];
      const pp = store.getters['users/userId'];

      if (bp) {
        set(request, 'params.bp', bp);
      } else if (pp) {
        set(request, 'params.pp', pp);
      }
    }

    const sessionId = await store.dispatch('getSessionId');

    if (sessionId) {
      request.headers['X-SessionID'] = sessionId;
    } else {
      delete request.headers['X-SessionID'];
      delete request.headers.common['X-SessionID'];
    }

    request.headers['accept-language'] = store.getters.langCode;
    request.headers.common['accept-language'] = store.getters.langCode;
    request.headers['x-timezone'] = store.getters.timezone;
    request.headers.common['x-timezone'] = store.getters.timezone;

    return request;
  });

  $axios.onError((error) => {
    if (error.response) {
      const { status } = error.response;

      errorHandler(error.response.data, { status });

      if (status === 401) {
        const lc = store.getters.langCode;
        const name = lc !== env.MARKETPLACE_DEFAULT_LANG
          ? `auth__${lc}`
          : 'auth';
        store.dispatch('auth/logoutAndGo', { name, code: status });
      } else if (status === 408) {
        const url = error.response.request.responseURL;
        $sentry.setTag('type', 'api');
        $sentry.setTag('status', status);
        $sentry.captureException(new Error(`API Endpoint ${url} timeout\returned ${status}`));
      } else if ([500, 503, 504].includes(status) || status !== 404) {
        $sentry.setTag('type', 'api');
        $sentry.setTag('status', status);
        $sentry.captureException(error);
      }

      if (route.name?.includes('order')) {
        $sentry.setTag('BASKET_ERROR', 'submit');
        $sentry.captureException(error);
      }
    }

    throw error;
  });
}
