import { INT_FLOAT3_REG } from './patterns';
import { i18n } from '@/plugins/i18n';

export default {
  business: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'change' }
  ],
  name: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'blur' }
  ],
  currency: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'change' }
  ],
  correction_factor: [
    {
      required: true,
      pattern: INT_FLOAT3_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ],
  constant: [
    {
      required: true,
      pattern: INT_FLOAT3_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ],
  weight_rate: [
    {
      required: true,
      pattern: INT_FLOAT3_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ],
  no_weight_factor: [
    {
      required: true,
      pattern: INT_FLOAT3_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ]
};
