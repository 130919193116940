<template>
  <div class="car-factory-selector">
    <CarInfoCardM v-if="isMobile && car" :value="car" />

    <div v-else-if="isMobile" class="panel-m">
      <div class="title-block pg-1">
        <span class="title">{{ widgetTitleM }}</span>

        <el-link v-if="selectorOptions.isBackspace" class="ml-1">
          <img
            class="block"
            src="@/assets/icons/backspace_outlined-blue.svg"
            alt="backspace"
            @click="goBackSelect"
          >
        </el-link>
      </div>

      <hr>

      <el-row align="middle mb-1">
        <div class="car-icon-block mr-05">
          <img src="@/assets/icons/car-1-white.svg" alt="Garage">
        </div>

        <el-button
          class="fl-1"
          type="success"
          size="small"
          @click="toggleViewM('selector')"
        >
          {{ selectorOptions.prefix || $t('garage.chooseYourCar') }}
          <i class="el-icon-arrow-down" />
        </el-button>

        <el-link
          v-if="selectorOptions.isBackspace"
          class="pg-1 ml-05"
          type="primary"
          @click="clear"
        >
          <i class="el-icon-close" />
        </el-link>
      </el-row>
    </div>

    <CarInfoCard v-else-if="car" :value="car" />

    <div v-else class="panel">
      <div class="car-icon-block mr-1">
        <img src="@/assets/icons/car-1-white.svg" alt="Garage">
      </div>

      <div class="title-block">
        <span class="title">{{ widgetTitle }}</span>

        <el-link v-if="selectorOptions.isBackspace" class="ml-1">
          <img
            class="block"
            src="@/assets/icons/backspace_outlined-blue.svg"
            alt="backspace"
            @click="goBackSelect"
          >
        </el-link>
      </div>
    </div>

    <div class="car-factory-selector__body">
      <el-form-item v-if="isShowSelector && !car" prop="modification">
        <component
          :is="`CarSelector${isMobile ? 'M' : ''}`"
          ref="carSelector"
          class="mt-1"
          mobile-size="xs"
          :get-parsed="parseRestModificationToQl"
          @select="saveModification"
          @title:update="selectorOptions = $event"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
import {
  parseRestModificationToQl,
  getShortCarTitle
} from '@/lib/garage/services/carParsers';

export default {
  name: 'CarFactorySelector',

  components: {
    /* eslint-disable */
    BufferCopy: () => import(/* webpackChunkName: "BufferCopy" */ '@/lib/core/components/BufferCopy'),
    CarInfoCard: () => import(/* webpackChunkName: "CarInfoCard" */ './CarInfoCard'),
    CarInfoCardM: () => import(/* webpackChunkName: "CarInfoCardM" */ './CarInfoCardM'),
    CarSelector: () => import(/* webpackChunkName: "CarSelector" */ './garageWidget/CarSelector'),
    CarSelectorM: () => import(/* webpackChunkName: "CarSelectorM" */ './garageWidget/CarSelectorM'),
    /* eslint-enable */
  },

  props: {
    value: { type: Object, required: true }
  },

  data () {
    return {
      isShowSelector: false,
      selectorOptions: {}
    };
  },

  computed: {
    car () {
      return this.value.modification ? this.value : null;
    },

    isMobile () {
      return this.maxmq('xs');
    },

    widgetTitle () {
      return this.isShowSelector
        ? this.selectorOptions?.title
        : this.$t('garage.chooseYourCarForStart');
    },

    widgetTitleM () {
      if (this.selectorOptions.activeMenuItem === 'brand') {
        return this.$t('garage.startWithCarBrand');
      }

      return this.selectorOptions?.title || this.$t('garage.startWithCarBrand');
    }
  },

  watch: {
    isMobile: {
      immediate: true,
      handler (isMobile) {
        this.isShowSelector = !isMobile && !this.car;
      }
    }
  },

  methods: {
    parseRestModificationToQl,
    getShortCarTitle,

    goBackSelect () {
      this.$refs.carSelector?.goBack();
    },

    saveModification ({ item, formData }) {
      const payload = {
        brand: item.brand,
        model: item.model,
        productionYear: formData.production_year,
        bodyType: item.bodyType,
        fuelType: item.fuelType,
        engineCapacity: Number(item.capacity),
        modification: item._modificationId,
        engineCode: item.engineCode,
        powerPs: item.powerPs,
        valves: item.valves,
        driveType: item.driveType,
        engineType: item.engineType,
        gearType: item.gearType
      };

      Object.entries(payload).forEach(([key, value]) => {
        this.$emit(`update:${key}`, value);
      });

      this.isShowSelector = false;
    },

    clear () {
      this.$refs.carSelector.clearFilter();
    },

    toggleViewM (name) {
      if (name === 'selector') {
        this.isShowSelector = true;
        const name = this.selectorOptions.activeMenuItem || 'brand';

        setTimeout(() => {
          this.$refs.carSelector.onToggleMenuM(name);
        }, 100);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.car-factory-selector {
  .panel {
    display: flex;
    padding: 10px;
    border-radius: 4px;
    background-color: $grey-300;
  }

  .panel-m {
    padding: 4px;
    border-radius: 4px;
    background-color: $grey-300;
  }

  .title {
    color: $black;
    font-weight: bold;
    font-size: 1rem;
  }

  .car-icon-block {
    background-color: $red-600;
    border-radius: 4px;
    padding: 3px 4px;
    height: fit-content;

    img {
      width: 25px;
      height: 24px;
    }
  }

  hr {
    border-color: $white;
    padding: 0;
    margin: 0 0 10px;
  }
}
</style>
