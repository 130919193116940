var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "el-tooltip",
        {
          attrs: { effect: "light", placement: "top" },
          on: {
            mouseleave: function ($event) {
              _vm.isVisible = false
            },
          },
        },
        [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _c("div", { staticClass: "pre" }, [_vm._v(_vm._s(_vm.val))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pre fix" }, [_vm._v(_vm._s(_vm.value))]),
          _vm._v(" "),
          _c("span", [_vm._t("default")], 2),
        ]
      )
    : _c(
        "div",
        {
          on: {
            click: function ($event) {
              if (!$event.shiftKey) {
                return null
              }
              _vm.isVisible = true
            },
          },
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }