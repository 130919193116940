<template>
  <div class="price-assortment-tab">
    <el-input
      v-model="query"
      autofocus
      class="mb-2"
      :placeholder="$t('placeholders.catalogNumInPrice')"
      @keyup.native.enter="search"
    >
      <template slot="append">
        <el-button icon="el-icon-search" type="primary" @click="search" />
      </template>
    </el-input>

    <WaresTable
      ref="table"
      v-loading="isLoading"
      readonly
      row-key="_id"
      :load="load"
      @info:show="showInfo"
    />

    <TheDialog v-model="wareInfoId">
      <WareInfo :id="wareInfoId" @cancel="wareInfoId = null" />
    </TheDialog>
  </div>
</template>

<script>
import get from 'lodash/get';
import { WaresTable, WareInfo } from '@/lib/search';
import { parsers } from '@/lib/core';

export default {
  name: 'PriceAssortmentTab',

  components: {
    WaresTable,
    WareInfo
  },

  props: {
    entity: { type: Object, required: true }
  },

  data () {
    return {
      isLoading: false,
      query: '',
      wareInfoId: null
    };
  },

  methods: {
    showInfo (row) {
      this.wareInfoId = get(row, 'ware.id');
    },

    search () {
      this.$refs.table.reload();
    },

    async load () {
      if (!this.query) {
        return [];
      }

      this.isLoading = true;

      try {
        const res = await this.$store.dispatch('search/searchInPrice', {
          search: this.query,
          priceFileId: this.entity.id
        });

        return parsers.addIdsTo(res.results);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.price-assortment-tab {
  .el-input {
    width: 50%;
  }
}
</style>
