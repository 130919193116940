var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pdf-preview" }, [
    !_vm.previewIcon
      ? _c("div", { staticClass: "link-container" }, [
          !_vm.previewIcon
            ? _c(
                "a",
                {
                  staticClass: "link",
                  attrs: { download: "", href: _vm.value.url },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("buttons.upload")) + " PDF\n    "
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _c("img", {
          attrs: { src: require("@/assets/fileTypes/pdf-icon.svg") },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }