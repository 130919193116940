var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        staticClass: "site-block",
        attrs: {
          "entity-name": "profile",
          fields: ["site", "order_on_site", "search_on_site_url"],
          "label-position": "left",
          rules: _vm.rules,
          submit: _vm.submitSeller,
          value: _vm.value.seller || {},
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var formData = ref.formData
              var readonly = ref.readonly
              return [
                _c(
                  "SiteInfo",
                  _vm._b(
                    { attrs: { readonly: readonly, value: formData } },
                    "SiteInfo",
                    formData,
                    false,
                    true
                  )
                ),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }