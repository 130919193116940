<template>
  <div v-if="basket" class="basket-nav">
    <nuxt-link
      v-if="isAuthenticated"
      :to="{
        name: getRouteName('basket.orders'),
        params: { pointId: 0, id: basket.id }
      }"
    >
      <el-button
        :circle="maxmq('md')"
        icon="el-icon-folder-checked"
        :round="!maxmq('md')"
        size="mini"
        :type="$route.name === 'basket.orders' ? 'primary' : ''"
      >
        <span v-if="!maxmq('md')">{{ $t('routes.basket_orders') }}</span>
      </el-button>
    </nuxt-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BasketNav',

  computed: {
    ...mapGetters({
      basket: 'baskets/basket',
      isAuthenticated: 'auth/isAuthenticated'
    })
  }
};
</script>
