export const actions = {
  getItems ({ rootGetters }, params) {
    return this.$axios.$get('/order-items/', { params });
  },

  patchItem (_, { id, formData }) {
    return this.$axios
      .$patch(`/order-items/${id}/`, formData, { progress: false });
  }
};
