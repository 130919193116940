var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-requests-processing-table s-requests-table" },
    [
      !_vm.value.length
        ? _c("el-row", { staticClass: "pg-2", attrs: { justify: "center" } }, [
            _vm._v("\n    " + _vm._s(_vm.$t("el.table.emptyText")) + "\n  "),
          ])
        : _c(
            "div",
            _vm._l(_vm.value, function (request) {
              return _c(
                "div",
                {
                  key: request.id,
                  class: ["request", { grey: !_vm.isAnswer(request) }],
                },
                [
                  _c(
                    "el-collapse",
                    {
                      staticClass: "mb-2",
                      attrs: { accordion: "" },
                      model: {
                        value: _vm.activeBlocks,
                        callback: function ($$v) {
                          _vm.activeBlocks = $$v
                        },
                        expression: "activeBlocks",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { name: request.id } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("div", { staticClass: "title-wrapper" }, [
                              _c("div", { staticClass: "title mr-2" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getShortCarTitleOld(
                                        request.car_detail
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "meta text-ghost text-right" },
                                [
                                  _c("div", { staticClass: "text-right" }, [
                                    _vm._v("№" + _vm._s(request.public_id)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "text-right bold" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.getDateTime(request.created)
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("CarFullInfoItem" + (_vm.maxmq("sm") ? "M" : ""), {
                            tag: "component",
                            attrs: {
                              value: request.car_detail,
                              readonly: "",
                              old: "",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-row", { attrs: { justify: "space-between" } }, [
                    _c("div", { staticClass: "mr-2" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getRawTitles(request.items)) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: !_vm.isAnswer(request) ? "primary" : "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("select", request)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getBtnText(request)) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }