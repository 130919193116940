var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "location-select-map" },
    [
      _c("SearchPointMap", {
        ref: "preview",
        staticClass: "map-preview mb-1",
        attrs: { readonly: "", value: _vm.value },
      }),
      _vm._v(" "),
      !_vm.readonly
        ? _c(
            "el-row",
            { staticClass: "controls" },
            [
              _c(
                "el-button-group",
                [
                  _c("el-button", {
                    attrs: {
                      circle: "",
                      icon: "el-icon-location",
                      size: "mini",
                    },
                    on: {
                      click: function ($event) {
                        _vm.isDialog = true
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isDialog
        ? _c("SearchPointMapDialog", {
            attrs: {
              "input-query-string": _vm.inputQueryString,
              "is-visible": _vm.isDialog,
              readonly: _vm.readonly,
              "search-query-string": _vm.searchQueryString,
              value: _vm.value,
            },
            on: {
              cancel: function ($event) {
                _vm.isDialog = false
              },
              input: _vm.emit,
              remove: _vm.remove,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }