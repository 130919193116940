var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        staticClass: "photos-block",
        attrs: {
          fields: ["photos"],
          readonly: _vm.readonly,
          submit: _vm.submitPhoto,
          value: _vm.value,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var formData = ref.formData
              var disabled = ref.disabled
              return [
                _c("PhotoGallery", {
                  attrs: { readonly: disabled },
                  model: {
                    value: formData.photos,
                    callback: function ($$v) {
                      _vm.$set(formData, "photos", $$v)
                    },
                    expression: "formData.photos",
                  },
                }),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }