<template>
  <FormBlock
    class="registration-block"
    entity-name="account"
    :fields="['profile_name', 'avatar', 'language_code']"
    :label-width="labelWidth"
    :submit="submit"
    :value="value"
    v-on="$listeners"
  >
    <template #default="{ formData }">
      <el-row justify="space-between">
        <div class="mr-1">
          <div class="description mb-1">
            <p>{{ $t('account.nameForSocialDescription') }}</p>
          </div>

          <el-form-item :label="$t('labels.nameForSocial')" prop="profile_name">
            <el-input v-model="formData.profile_name" />
          </el-form-item>

          <el-form-item :label="$t('labels.login')" prop="email">
            <el-input disabled :value="entity.email" />
          </el-form-item>

          <el-form-item label-width="0">
            <SocialRegistration :id="entity.id" />
          </el-form-item>
        </div>

        <div>
          <el-form-item class="relative" label-width="0" prop="avatar">
            <AvatarUploader v-model="formData.avatar" circle />
          </el-form-item>
        </div>
      </el-row>
    </template>
  </FormBlock>
</template>

<script>
import SocialRegistration from '../formItems/SocialRegistration';
import AvatarUploader from '@/lib/core/components/AvatarUploader';
import FormBlock from '@/lib/core/components/FormBlock';

export default {
  name: 'RegistrationBlock',

  components: {
    FormBlock,
    AvatarUploader,
    SocialRegistration
  },

  props: {
    submit: Function,
    entity: { type: Object, required: true },
    value: { type: Object, required: true }
  },

  data: () => ({ labelWidth: '250px' }),

  computed: {
    isShowLangs () {
      return this.$i18n.availableLocales.length > 1;
    }
  }
};
</script>

<style scoped lang="scss">
.registration-block .social-registration ::v-deep .label {
  width: 250px;
  justify-content: flex-end;
  padding-right: 12px;
}
</style>
