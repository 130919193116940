<template>
  <el-dialog
    append-to-body
    center
    class="search-point-map-dialog"
    :visible="isVisible"
    width="80vw"
    @close="cancel"
  >
    <template #title>
      <div class="queryString" @keyup.enter="searchBy(queryString)">
        <el-input v-model="queryString" size="medium" @input="searchBy($event)">
          <template slot="append">
            <el-button icon="el-icon-search" type="primary" @click="searchBy(queryString)" />
          </template>
        </el-input>
      </div>
    </template>

    <SearchPointMap
      v-if="isVisible"
      ref="map"
      map-id="select"
      :readonly="readonly"
      show-inputs
      :value="geo"
      @input="geo = $event"
      @update:search:result="geo = $event"
    />

    <template #footer>
      <el-row v-if="!readonly" class="mt-1" justify="end">
        <el-button-group>
          <el-button :disabled="!hasGeo" @click="centeredPopup">
            {{ $t('buttons.centered') }}
          </el-button>
          <el-button @click="cancel">{{ $t('buttons.cancel') }}</el-button>
          <el-button :disabled="!hasGeo || idEqual" type="primary" @click="emit">
            {{ $t('buttons.save') }}
          </el-button>
        </el-button-group>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import SearchPointMap from './SearchPointMap';
import { env } from '@/lib/core';

export default {
  name: 'SearchPointMapDialog',

  components: {
    SearchPointMap
  },

  props: {
    inputQueryString: String,
    searchQueryString: String,
    readonly: Boolean,
    isVisible: Boolean,
    value: { type: Object, required: true }
  },

  data () {
    return {
      geo: {},
      queryString: ''
    };
  },

  computed: {
    idEqual () {
      return isEqual(this.value, this.geo);
    },
    hasGeo () {
      return this.geo.lng && this.geo.lat;
    }
  },

  created () {
    this.queryString = this.inputQueryString;
    this.geo = cloneDeep(this.value);
    this.searchBy = debounce(this.searchBy, env.DEBOUNCE);
  },

  methods: {
    async searchBy (query) {
      try {
        this.geo = await this.$refs.map.searchBy(query);
      } catch (e) {
        console.error(e);
      }
    },

    centeredPopup () {
      const { flyTo, zoom } = this.$refs.map;
      flyTo(this.geo, zoom);
    },

    emit () {
      if (this.geo.lat && this.geo.lat) {
        this.$emit('input', this.geo);
        this.$emit('cancel');
      }
    },

    cancel () {
      this.geo = cloneDeep(this.value);
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped lang="scss">
.search-point-map-dialog {
  top: -40px;

  .queryString {
    width: calc(100% - 100px);

    .el-input {
      width: 100%;
    }
  }

  ::v-deep .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
