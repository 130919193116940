import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import unionBy from 'lodash/unionBy';
import dayjs from 'dayjs';
import env from '../services/envConstants';
import { EMAIL_REG, PHONE_REG, FULL_SITE_REG } from './patterns';
import { i18n } from '@/plugins/i18n';

export default {
  isPasswordsIdent (rule, value, callback) {
    if (this.formData.password !== value) {
      callback(new Error(i18n.t('validation.password2.ident')));
    } else {
      callback();
    }
  },

  address (rule, value, callback) {
    if (value === null) {
      callback();
    }

    const isValid = !!Object.values(value).filter(Boolean).length;

    if (isValid) {
      callback();
    } else {
      callback(new Error(i18n.t('validation.address.required')));
    }
  },

  deliveryAddress (rule, value, callback) {
    const isValid = value.city || (value.city_text && value.region);

    if (!value.type || !isValid) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else {
      callback();
    }
  },

  deliveryAddressCity (rule, value, callback) {
    const { city_text, region } = this.formData.delivery_address;
    const isValid = value || (city_text && region);

    if (!isValid) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else {
      callback();
    }
  },

  phoneNumberObject (rule, value = {}, callback) {
    if (!value.phone_number) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else if (
      !value.phone_number.startsWith(env.MARKETPLACE_PHONE_PREFIX) ||
      !PHONE_REG.test(value.phone_number) ||
      value.phone_number.length !== env.MARKETPLACE_PHONE_FORMAT.length
    ) {
      const _err = i18n.t(
        'validation.phone_number.pattern',
        { format: env.MARKETPLACE_PHONE_FORMAT }
      );

      callback(new Error(_err));
    } else {
      callback();
    }
  },

  emailObject (rule, value = {}, callback) {
    if (!value.email) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else if (!EMAIL_REG.test(value.email)) {
      callback(new Error(i18n.t('validation.email.pattern')));
    } else {
      callback();
    }
  },

  phone_numbers (rule, value = [], callback) {
    if (!value.some(({ phone_number }) => Boolean(phone_number))) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else if (unionBy(value, 'phone_number').length !== value.length) {
      callback(new Error(i18n.t('validation.phone_numbers.duplicate')));
    } else if (value.some(phone_number => phone_number.length < 13)) {
      const _err = i18n.t(
        'validation.phone_number.pattern',
        { format: env.MARKETPLACE_PHONE_FORMAT }
      );
      callback(new Error(_err));
    } else {
      callback();
    }
  },

  emails (rule, value = [], callback) {
    if (!value.every(({ email }) => Boolean(email))) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else if (!value.every(({ email }) => EMAIL_REG.test(email))) {
      callback(new Error(i18n.t('validation.email.pattern')));
    } else {
      callback();
    }
  },

  hasValue (rule, value, callback) {
    if (!isEmpty(value)) {
      callback();
    } else {
      callback(new Error(i18n.t('validation.simple.required')));
    }
  },

  valOrNul (rule, value = [], callback) {
    if (value || [null, 0].includes(value)) {
      callback();
    } else {
      callback(new Error(i18n.t('validation.simple.required')));
    }
  },

  hasTrademark (rule, value, callback) {
    const isInMapping = this.formData.column_mapping.find(
      ({ type }) => type === rule.field
    );

    if (value && isInMapping) {
      callback(new Error(i18n.t('validation.trademark.single')));
    } else if (!value && !isInMapping) {
      callback(new Error(i18n.t('validation.trademark.required')));
    } else {
      callback();
    }
  },

  columnMapping (rule, value, callback) {
    const byTypes = groupBy(value, 'type');

    const hasArticle = !!byTypes.article;

    const hasSellingPrice = !!byTypes.selling_price;

    const hasSellingPriceTags = hasSellingPrice &&
      byTypes.selling_price.every(({ price_def }) => {
        return price_def && !isEmpty(price_def.tags);
      });

    const hasWarehouses = !byTypes.quantity || byTypes.quantity
      .every(({ quantity_warehouse }) => {
        return quantity_warehouse && quantity_warehouse.url;
      });

    const hasPurchasePriceActions =
      byTypes.purchase_price &&
      byTypes.purchase_price.every(({ price_actions }) => !!(
        !isEmpty(price_actions) && price_actions
          .every(({ price_def }) => {
            return price_def && price_def.tags.length;
          })
      ));

    const hasOptionalAnalogsCol =
      (!byTypes.analog_article && !byTypes.analog_trademark) ||
      (byTypes.analog_article && byTypes.analog_trademark);

    const hasRelationType = !byTypes.analog_article ||
      byTypes.analog_article.every(({ relation_type }) => relation_type);

    const hasWeightUnit = !byTypes.weight ||
      byTypes.weight.every(({ unit }) => unit);

    const hasSizesUnit = !byTypes.sizes ||
      byTypes.sizes.every(({ unit }) => unit);

    let message = '';

    if (isEmpty(value)) {
      message += `${i18n.t('validation.simple.required')} `;
    }

    if (!hasArticle) {
      message += `${i18n.t('validation.article.required')} `;
    }

    if (!hasWarehouses) {
      message += `${i18n.t('validation.warehouse.required')} `;
    }

    if (
      (hasSellingPrice && !hasSellingPriceTags) ||
      (!hasSellingPrice && !hasPurchasePriceActions)
    ) {
      message += `${i18n.t('validation.sellPrice.required')} `;
    }

    if (!hasOptionalAnalogsCol) {
      message +=
        `${i18n.t('validation.analogsAndAnalogsTrademark.required')} `;
    }

    if (!hasRelationType) {
      message += `${i18n.t('validation.relationType.required')} `;
    }

    if (!byTypes.quantity) {
      message += `${i18n.t('validation.quantity.required')} `;
    }

    if (!hasWeightUnit) {
      message += `${i18n.t('validation.weightType.required')} `;
    }

    if (!hasSizesUnit) {
      message += `${i18n.t('validation.sizesType.required')} `;
    }

    if (message) {
      callback(new Error(message));
    } else {
      callback();
    }
  },

  availabilities (rule, value, callback) {
    const hasTime = value.every(
      ({ warehouse, point_of_sale }) => warehouse && point_of_sale
    );
    const hasUtil = value.every(({ order_time_until }) => order_time_until);

    let message = '';

    if (!hasTime) {
      message += `${i18n.t('validation.delivery_time.required')} `;
    }

    if (!hasUtil) {
      message += `${i18n.t('validation.order_time_until.required')} `;
    }

    if (message) {
      callback(new Error(message));
    } else {
      callback();
    }
  },

  termsUrl (rule, value, callback) {
    if (value.page_url.length < 1) {
      const m = i18n.t('validation.simple.min', { num: 1 });
      callback(new Error(m));
    } else if (value.page_url.length > 200) {
      const m = i18n.t('validation.simple.max', { num: 200 });
      callback(new Error(m));
    } else if (!FULL_SITE_REG.test(value.page_url)) {
      const m = i18n.t('validation.urls.pattern');
      callback(new Error(m));
    } else {
      callback();
    }
  },

  selectionRequestsItem (rule, value, callback) {
    const index = Number(rule.field.split(/\[|\]/g)[1]);

    if (this.formData.items[index].comment && !value) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else {
      callback();
    }
  },

  carFields (rule, value, callback) {
    const _val = value && typeof value === 'object'
      ? value?.id
      : value;

    if (!_val && !this.formData[`${rule.field}Text`]) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else {
      callback();
    }
  },

  carYear (rule, value, callback) {
    const _val = Number(value) || this.formData[`${rule.field}Text`];
    const nowYear = dayjs().year();

    if (!_val) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else if (_val > nowYear || _val < 1886) {
      callback(new Error(i18n.t('validation.simple.format')));
    } else {
      callback();
    }
  },

  selectionResponseDeliveryTime (rule, value, callback) {
    if (!value && !this.formData.offer_id) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else {
      callback();
    }
  },

  deliveryServices (rule, value, callback) {
    if (!value?.length || !value.some(({ is_active }) => is_active)) {
      callback(new Error(i18n.t('validation.simple.required')));
    } else {
      callback();
    }
  }
};
