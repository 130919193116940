<template>
  <div class="registration-form">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      size="medium"
      @submit.native.prevent="submit"
    >
      <el-form-item :label="$t('auth.phone_number')" prop="phone_number">
        <el-input
          v-model="formData.phone_number"
          :placeholder="`${MARKETPLACE_PHONE_PREFIX}...`"
          @blur="fixPhone"
        />
      </el-form-item>

      <el-form-item :label="$t('auth.email')" prop="email">
        <el-input v-model="formData.email" />
      </el-form-item>

      <el-form-item :label="$t('auth.password')" prop="password">
        <el-input
          v-model="formData.password"
          :type="isShowPass ? '' : 'password'"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-view c-pointer"
            @click="isShowPass = !isShowPass"
          />
        </el-input>
      </el-form-item>

      <el-form-item class="text-center">
        <el-button
          class="submit-btn"
          :loading="isLoading"
          native-type="submit"
          type="primary"
        >
          {{ $t('auth.goRegister') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Vue from 'vue';
import { getValidationFor, env } from '@/lib/core';

export default {
  name: 'RegistrationForm',

  props: {
    hideCloseIcon: Boolean
  },

  data () {
    return {
      MARKETPLACE_PHONE_PREFIX: env.MARKETPLACE_PHONE_PREFIX,
      isLoading: false,
      isShowPass: false,
      rules: getValidationFor(['phone_number', 'email', 'password'], 'user', this),
      formData: {
        phone_number: env.MARKETPLACE_PHONE_PREFIX,
        email: '',
        password: ''
      }
    };
  },

  mounted () {
    Vue.use(VueReCaptcha, {
      siteKey: env.GOOGLE_RECAPTCHA_KEY,
      loaderOptions: { autoHideBadge: true }
    });
  },

  methods: {
    fixPhone () {
      this.formData.phone_number = this.rules.phone_number[1]
        .transform(this.formData.phone_number);
    },

    async submit () {
      const captcha_token = this.$recaptcha && await this.$recaptcha('login');

      return this.$refs.form.validate(async (isValid) => {
        if (!captcha_token) {
          this.$notify.error({
            title: this.$t('errors.captcha.title'),
            message: this.$t('errors.captcha.message')
          });

          return false;
        } else if (!isValid) {
          return false;
        }

        this.isLoading = true;

        // TODO clear it after fix on server
        const formData = {
          ...this.formData,
          password2: this.formData.password,
          captcha_token,
          profile: {
            first_name: this.formData.email,
            language_code: this.$store.getters.langCode
          }
        };

        try {
          const { email } = await this.$store.dispatch('users/postUser', formData);

          await this.$router.push({
            name: this.getRouteName('auth.activation'),
            query: { email }
          });

          this.$emit('done');
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/auth-form.scss";
</style>
