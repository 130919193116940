<template>
  <div class="address">
    <div v-if="showDescription" class="description">
      <p>{{ $t('salePoint.setAddress') }}</p>
    </div>

    <el-form-item
      class="mb-0"
      :class="{ 'hide-required-star': hideRequiredStar }"
      :label="label"
      :label-width="label ? undefined : '0' "
      :prop="prop"
    >
      <el-row class="main-row fl-wrap">
        <div class="mr-2">
          <el-checkbox
            v-if="!hideNoAddress"
            class="mb-1"
            :disabled="readonly"
            :label="$t('labels.noAddress')"
            :value="!hasAddress"
            @change="togglePhysicalAddress"
          />

          <el-row :gutter="4" :type="maxmq('sm') ? 'block' : 'flex'">
            <el-col>
              <el-form-item ref="country" label-width="0">
                <SearchLocationSelect
                  :disabled="readonly"
                  field="country"
                  :value="cityDetail.country"
                  @input="setCountry"
                />
              </el-form-item>
            </el-col>

            <el-col>
              <el-form-item
                ref="admin1"
                label-width="0"
                :prop="`${prop}.city_detail.admin1`"
                :rules="rules.admin1"
              >
                <TheTooltip :content="!cityDetail.country && $t('salePoint.setCountryFirst')">
                  <SearchLocationSelect
                    :disabled="!cityDetail.country || readonly"
                    field="admin1"
                    :params="cityDetail"
                    :value="cityDetail.admin1"
                    @input="setAdmin1"
                  />
                </TheTooltip>
              </el-form-item>
            </el-col>

            <el-col>
              <el-form-item
                ref="city"
                label-width="0"
                :prop="`${prop}.city`"
                :rules="rules.city"
              >
                <TheTooltip
                  :content="!cityDetail.admin1 && $t('salePoint.setCountryAndAdminFirst')"
                >
                  <SearchLocationSelect
                    :disabled="!cityDetail.admin1 || readonly"
                    field="city"
                    :params="cityDetail"
                    :value="cityDetail"
                    @input="setCity"
                  />
                </TheTooltip>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="4" :type="maxmq('xs') ? 'block' : 'flex'">
            <el-col>
              <el-form-item
                ref="street"
                label-width="0"
                :prop="`${prop}.street`"
                :rules="hasAddress ? rules.street : []"
              >
                <el-input
                  :disabled="!hasAddress || readonly"
                  :placeholder="$t('labels.street')"
                  :value="value.street"
                  @input="emit('street', $event)"
                />
              </el-form-item>
            </el-col>

            <el-col :span="7">
              <el-form-item
                ref="building"
                label-width="0"
                :prop="`${prop}.building`"
                :rules="hasAddress ? rules.building : []"
              >
                <el-input
                  :disabled="!hasAddress || readonly"
                  :placeholder="$t('labels.building')"
                  :value="value.building"
                  @input="emit('building', $event)"
                />
              </el-form-item>
            </el-col>

            <el-col :span="7">
              <el-form-item
                ref="flat"
                label-width="0"
                :prop="`${prop}.flat`"
                :rules="rules.room"
              >
                <el-input
                  :disabled="!hasAddress || readonly"
                  :placeholder="$t('labels.flat')"
                  :value="value.room"
                  @input="emit('room', $event)"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item
            v-if="!hideDescriptionInput && (!readonly || (readonly && value.description))"
            ref="description"
            label-width="0"
            :prop="`${prop}.description`"
            :rules="rules.description"
          >
            <el-input
              :disabled="!hasAddress || readonly"
              :placeholder="$t('labels.landmarks')"
              rows="3"
              type="textarea"
              :value="value.description"
              @input="emit('description', $event)"
            />
          </el-form-item>
        </div>

        <div v-if="!hideMap">
          <LocationSelectMap
            ref="map"
            :input-query-string="queryString"
            :readonly="readonly"
            :search-query-string="searchQueryString"
            :value="location"
            @input="setLocation"
          />
        </div>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { getValidationFor, env } from '@/lib/core';
import { LocationSelectMap } from '@/lib/map';
import SearchLocationSelect from '@/lib/core/components/SearchLocationSelect';

const rules = [
  'country',
  'admin1',
  'city',
  'street',
  'building',
  'description'
];

export default {
  name: 'Address',

  components: {
    SearchLocationSelect,
    LocationSelectMap
  },

  props: {
    prop: { type: String, default: 'address' },
    propRules: Object,
    readonly: Boolean,
    showDescription: Boolean,
    hideLabel: Boolean,
    hideMap: Boolean,
    hideDescriptionInput: Boolean,
    hideNoAddress: Boolean,
    hasPhysicalAddress: Boolean,
    hideRequiredStar: Boolean,
    value: { type: Object, required: true }
  },

  computed: {
    rules () {
      return this.propRules || getValidationFor(rules, 'salePoint');
    },

    label () {
      return !this.hideLabel
        ? this.$t('labels.address')
        : '';
    },

    location () {
      const { latitude, longitude } = this.value;
      return { lat: latitude, lng: longitude };
    },

    hasAddress () {
      return this.hasPhysicalAddress || (this.value && this.value.has_physical_address);
    },

    cityDetail () {
      return this.value && this.value.city_detail ? cloneDeep(this.value.city_detail) : {};
    },

    queryString () {
      const country = get(this.value, 'city_detail.country.name', '');
      const city = get(this.value, 'city_detail.name', '');
      const street = this.value.street || '';
      const building = this.value.building || '';

      return `${country} ${city} ${street} ${building}`.trim();
    },

    searchQueryString () {
      return this.value.street ? this.queryString : '';
    }
  },

  created () {
    if (!this.readonly) {
      this.loadDefaultCountry();
    }
  },

  methods: {
    async loadDefaultCountry () {
      if (this.value?.city_detail?.country) {
        return null;
      }

      try {
        const country = await this.$store.dispatch(
          'searchLocation/getCountryById',
          { id: env.MARKETPLACE_COUNTRY_ID }
        );

        this.setCountry(country);
      } catch (e) {
        console.error(e);
      }
    },

    setCountry (country) {
      const fields = {
        city_detail: { country: country || null },
        city: null
      };

      this.emitFields(fields);
    },

    setAdmin1 (admin1) {
      const fields = {
        city_detail: {
          country: this.cityDetail.country,
          admin1: admin1 || null
        },
        city: null
      };

      this.emitFields(fields);

      if (admin1 && admin1.id === env.ADMIN1_CAPITAL_ID) {
        this.setCapitalCity();
      }
    },

    setCity (city) {
      const _city = city || {};

      const fields = {
        city_detail: {
          ..._city,
          country: this.cityDetail.country,
          admin1: this.cityDetail.admin1
        },
        city: _city.url || null
      };

      this.emitFields(fields);

      if (!this.hideMap) {
        this.setLocation({ lat: _city.latitude, lng: _city.longitude });
      }
    },

    togglePhysicalAddress (event) {
      if (event) {
        const fields = ['description', 'room', 'building', 'street'];
        fields.forEach(field => this.emit(field, ''));
      }

      this.emit('has_physical_address', !event);
      this.clearValidate(rules);
    },

    clearValidate (links) {
      links.forEach(key => this.$refs[key] && this.$refs[key].clearValidate());
    },

    setLocation (value) {
      const { lat, lng } = value || {};
      const { latitude, longitude } = this.cityDetail;
      const toFix = val => Number.parseFloat(val).toFixed(env.GEO_TO_FIXED);

      let _latitude = null;
      let _longitude = null;

      if (lat && lng) {
        _latitude = toFix(lat);
        _longitude = toFix(lng);
      } else if (latitude && longitude) {
        _latitude = toFix(latitude);
        _longitude = toFix(longitude);
      }

      this.emit('latitude', _latitude);
      this.emit('longitude', _longitude);
    },

    async setCapitalCity () {
      try {
        const capital = await this.$store.dispatch('searchLocation/getCityById', {
          id: env.CAPITAL_ID
        });

        this.setCity(capital);
      } catch (e) {
        console.error(e);
      }
    },

    emitFields (fields) {
      if (!this.readonly) {
        Object.keys(fields).forEach(key => this.emit(key, fields[key]));
      }
    },

    emit (name, value) {
      if (!this.readonly) {
        this.$emit(`update:${name}`, value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.address {
  .el-select {
    min-width: 100%;
  }

  .hide-required-star ::v-deep label.el-form-item__label::after {
    display: none;
  }

  @media all and (max-width: $--xs) {
    .main-row {
      flex-direction: column;

      & > .el-col {
        width: 100%;
      }
    }
  }
}
</style>
