var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VFacebookLogin", {
    attrs: { "app-id": _vm.SOCIAL_AUTH_FACEBOOK_KEY },
    on: { "sdk-init": _vm.setDependency },
    nativeOn: {
      mousedown: function ($event) {
        return _vm.checkAndLogin.apply(null, arguments)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "logout",
        fn: function () {
          return [_vm._v(_vm._s(_vm.$t("buttons.facebook")))]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }