export default {
  langName: 'Русский',
  countryCode: 'ru',
  langCode: 'ru',

  core: {
    yes: 'Да',
    no: 'Нет',
    allReserved: '2018-{year} {mp}. маркетплейс автозапчастей в Украине. Запчасти в твоем городе.',
    loading: 'Загрузка',
    currency: "Валюта",
    isYourCity: 'Ваш город - {name}?',
    cityDescription: 'От выбранного города зависит наличие товара, способы и сроки доставки.',
    selectCity: 'Выберите город',
    pageInDevelopmentNow: 'Извините, данный раздел находится в разработке.',
    grn: 'грн',
    num: 'шт',
    siteRules: 'Условия использования сайта',
    offerRules: 'Договор оферты',
    protectionPersonalInfo: 'Защита персональных данных',
    questions: "Есть вопросы?",
    from: 'От',
    forSellers: 'Для продавцов автозапчастей',
    marketplaceAddress: 'г.Киев, ул. Шимановского 2/1',
    language: 'Язык сайта',
    contacts: 'Контакты',
    info: 'Информация',
    payOnSite: 'Оплата на сайте',
    canIHelp: 'Нужна помощь?',
    weInSocial: 'Мы в соцсетях',
    pageNum: 'страница №{page}',
    companyAddress: 'Адрес предприятия (админпараметр)',
    warning: 'Внимание',
    marketplaceDelivery: 'Доставка',
    marketplacePayment: 'Оплата',
    marketplaceWarranty: 'Гарантия и возврат',
    on: 'на',
    purchase: 'Покупка'
  },

  currencies: {
    UAH: 'грн',
    RUB: 'руб'
  },

  auth: {
    enter: 'Вход',
    or: 'Или',
    login: 'Email/телефон',
    phone_number: 'Телефон',
    email: 'Email',
    password: 'Пароль',
    remember: 'Запомнить меня',
    goRegister: 'Зарегистрироваться',
    forgetPassword: 'Забыли пароль?',
    registerNewAccount: 'Регистрация',
    recoveryPassword: 'Восстановление пароля',
    rememberPassword: 'Я вспомнил пароль',
    resendActivate: 'Повторно отправить письмо',
    activateAccount: 'Активация учетной записи',
    iAmOnlyBy: 'Я только покупаю запчасти на свои машины',
    iAmSeller: 'Я занимаюсь продажей автозапчастей или ремонтом автомобилей',
    youCanByNow: 'Теперь Вы можете покупать на сайте.',
    anyShopCanBy: 'Любой автомагазин или автосервис не имея своих прайсов может начать торговать на платформе.',
    registerAgreeWith: 'Регистрируясь, вы соглашаетесь с',
    siteRules: 'условиями сайта',
    thanksForRegistration: 'Спасибо за регистрацию на нашем сайте! Учетная запись создана',
    completionAuth: 'Спасибо за регистрацию на сайте. Для завершения регистрации осталось активировать учетную запись. Это можно сделать, если перейти по ссылке, что была отправлена вам на почтовый ящик, или ввести идентификатор самостоятельно. Теперь Вы можете в полной мере пользоваться сайтом. Для входа в свой аккаунт на сайт используется email и пароль, указанный при регистрации.',
  },

  profile: {
    selectProfile: 'Выберите профиль',
    buyer: 'Покупатель',
    seller: 'Продавец',
    sellerDescription: 'Я продавец автозапчастей или автосервис',
    addCompanyInfo: 'Добавьте информацию о компании, в которой вы работаете. Это позволит расширить возможности на сайте.',
    companyUserContacts: 'Контактные данные пользователя в компании',
    setYourContactInfo: 'Укажите свои контактные данные, которые будут использоваться для связи с Вами в случае, когда Вы будете оформлять заказ или выполнять какие-то действия от имени компании, которую Вы сейчас создаете.',
    changeYourInfoToCompanyInfo: 'По умолчанию внесены личные данные. Рекомендуем их изменить на рабочие контактные данные (корпоративную почту и телефон), чтобы не смешивать личное с рабочим.',
    sellIsEasily: 'Продавать на платформе легко!',
    mainInfoTab: 'Общие данные',
    deliveryTab: 'Доставка',
    requisitesTab: 'Реквизиты',
    trademarksTab: 'Представитель ТМ',
    mainInfoBlock: 'Общие данные о компании',
    siteBlock: 'Настройка сайта',
    mainRequisitesBlock: 'Реквизиты субъекта предпринимательской деятельности',
    contactRequisitesBlock: 'Контактные данные для официального общения',
    bankRequisitesBlock: 'Банковские реквизиты',
    scanFilesRequisitesBlock: 'Загрузка сканкпопии документов',
    removeBusinessConfirm: 'Вы уверены, что хотите удалить бизнес профиль?',
    removeBusinessDescription: 'Удаление профиля навсегда. Удалить профиль может только пользователь профиля с правами администратора.',
    leaveBusinessConfirm: 'Вы уверены, что хотите покинуть этот бизнес профиль?',
    leaveBusinessDescription: 'Если вы больше не является работником компании, то можете уйти из профиля. Если Вы - администратор профиля, то можете покинуть профиль, только если предоставите права доступа другому пользователю профиля.',
    removeBusiness: 'Удалить бизнес профиль',
    leaveBusiness: 'Уйти из бизнес профиля',
    name: 'Название',
    activities: 'Активность',
    isActive: 'Активировано',
    isNotActive: 'Не активировано',
    has_points_of_sale: 'Наличие точек продаж',
    has_prices: 'Наличие прайсов',
    is_seller: 'Продажа запчастей',
    site: 'Адрес сайта',
    search_on_site_url: 'Ссылка на результаты поиска',
    order_on_site: 'На сайте можно сделать заказ товара',
    searchOnSiteUrlDescription: 'Укажите URL в которую останется только добавить каталожный номер для поиска товара. Тогда пользователи смогут переходить к вам на сайт с сайта платформы. Например, на платформе это URL <strong>{mp}/search?search=</strong>',
    _agreeWithPublicOffer: 'Вы соглашаитесь с <a class="el-link el-link--primary" href="/ru/docs/public-offer" target="_blank" @click.stop> условиями публичной оферты"</a>',
  },

  account: {
    registrationBlock: 'Вход на сайт и общение',
    accountInfoBlock: 'Личные данные',
    contactsBlock: 'Контактные данные в профиле',
    nameForSocialDescription: 'Отображается другим пользователям в комментариях и продавцам, как заказчик (если не заполнено поле имя)',
    accountInfoDescription: 'Используется по умолчанию при оформлении заказов, и отображается продавцу при написании ему отзыва.',
    contactsDescription1: 'Укажите свои контактные данные, которые будут использоваться для связи с вами в случае, когда Вы будете оформлять заказ или выполнять какие-то действия.',
    contactsDescription2: 'По умолчанию внесены личные данные. Рекомендуем их изменить на рабочие контактные данные (корпоративную почту и телефон), чтобы не смешивать личное с рабочим.'
  },

  salePoint: {
    setAddress: 'Укажите адрес точки продаж и добавьте координаты, чтобы ваша точка продажи отображалась на карте.',
    setPhones: 'Укажите контактные номера телефонов организации, а также их назначение (например, Бухгалтерия).',
    setEmails: 'Укажите email, на который будут приходить сообщения о событиях профиля, и объяснения, если необходимо (например: для счетов).',
    salePoint: 'Точка продаж',
    createSellPointFirst: 'Сначала создайте точку продажи.',
    setMinInfo: 'Для создания точки продаж укажите минимальные необходимые данные. Полные данные о точке продажи можно будет ввести в форме редактирования после создания.',
    pointName: 'Название точки продаж',
    primary: 'Основной',
    status: 'Статус',
    isActive: 'Магазин работает',
    mainInfoTab: 'Общие данные',
    deliveryTab: 'Доставка',
    saleTermsTab: 'Условия продажи',
    returnWarrantyTermsTab: 'Возврат и гарантия',
    paymentAndDeliveryTab: 'Оплата и доставка',
    contactsTab: 'Контакты',
    trademarksTab: 'Представитель ТМ',
    descriptionTab: 'Описание',
    mainInfoBlock: 'Общие данные о точке продажи',
    addressBlock: 'Адрес',
    contactsBlock: 'Телефоны и электронная почта',
    scheduleBlock: 'Години работы',
    paymentTermsBlock: 'Условия заказа и оплата',
    paymentMethodsBlock: 'Способы оплаты',
    deliveryMethodsBlock: 'Способы доставки и выдачи товара',
    returnTermsBlock: 'Условия возврата',
    warrantyTermsBlock: 'Условия гарантии',
    descriptionBlock: 'Описание точки в свободной форме',
    photosBlock: 'Фотографии точки продаж',
    otherSellingTermsBlock: 'Дополнительно',
    allContactsBlock: 'Контактные данные',
    setCountryFirst: 'Сначала укажите страну',
    setCountryAndAdminFirst: 'Сначала укажите страну и область',
    selectSalePoint: 'Выбор точки продажи',
    description: 'Про компанию',
    comment: 'Коментарий',

    payment_methods_text: 'Дополнительные условия оплаты',
    delivery_methods_text: 'Дополнительные условия доставки',
    return_terms_text: 'Дополнительные умовия возврата',
    warranty_terms_text: 'Дополнительные условия гарантии',
    minPrice: 'Минимальная цена',
    minPriceDesc: 'Минимальная сумма для того, чтобы доставка была бесплатной',
  },

  ware: {
    details: 'детальная информация',
    wareCard: 'Карточка товара',
    mainInfo: 'Основная информация',
    characteristics: 'Характеристики',
    kits: 'Комплектность',
    applicability: 'Применение на автомобилях',
    description: 'Описание',
    wareId: 'КОД',
    wareSeller: 'Продавец товара',
    mainCharacteristic: 'Основные характеристики',
    country_name: 'Страна-производитель',
    delivery: 'Доставка',
    payment: 'Оплата',
    yourWare: 'Ваш товар',
    characteristic: 'Характеристики',
    fromKits: '{name} есть частью комплекта в следующих товарах',
    toKits: 'Комплектация {name}',
    showAllProposition: 'Показать все цены',
    recommendedSubstitutes: 'Рекомендуемые заменители',
    article: 'Каталожный номер',
    trademark: 'Бренд',
    yourShop: 'Ваш магазин',
    photo: " — Фото №",
    selectCarFormApplicability: 'Виберите марку модель, что б узнать применимость',
    characteristicDescription1: 'Характеристики и комплектация могут быть изменены производителем.',
    characteristicDescription2: 'Цвет изделия может отличаться из-за настроек монитора.',
    history: 'История изменения артикула',
    is_former_use: 'б/у',
    isInOtherSalePoint: 'Товар доступный в других точках продаж'
  },

  search: {
    filter: 'Фильтр',
    order: 'Сортировать',
    look: 'Вид',
    ware: 'Товар',
    orderNumber: 'Заказ № {id}',
    packageParams: 'Параметры товара в упаковке',
    allCharacteristic: 'Все характеристики',
    selectProducer: 'Выберите производителя из списка',
    main: 'Основные характеристики',
    analogues: 'Аналоги',
    kits: 'Комплекты',
    application: 'Применение на авто',
    options: 'Варианты',
    emptyResult: 'К сожалению, мы не смогли найти ни одной запчасти по каталожному номеру ИКН. Проверьте корректность каталожного номера. Если Вам известно, что автозапчасть действительно существует – отправьте информацию нашим специалистам. После проверки она будет добавлена к поисковой системе.',
    tm_name: 'TM',
    nomenclature: 'Номенклатура',
    name: 'Название',
    article: 'Артикул',
    arc: 'Кат. ном.',
    article_name: 'Описание',
    amount: 'Количество',
    am: 'Кол-во',
    city: 'Город',
    address: 'Адрес',
    changed_from: 'Заменен с',
    changed_to: 'Заменен на',
    brutto_x_sm: 'Размер Х (см)',
    brutto_y_sm: 'Размер Y (см)',
    brutto_z_sm: 'Размер Z (см)',
    brutto_volume_sm: 'Объем (см)³',
    brutto_weight_kg: 'Вес (кг)',
    size_sm: 'Габариты, см',
    formKits: '{name} есть комплектом, который состоит из',
    toKits: '{name} является комплектующей частью в других комплектах',
    optionsTitle: 'В каком еще виде встречается товар в источниках информации',
    foundOtherOptions: 'Найдено других непроверенных вариантов {count}',
    lineValue: '* - указывает на значение товарной линии',
    price: 'Цена',
    priceInterval: 'Интервал цен',
    seller: 'Продавец',
    term: 'Срок',
    delivery: 'Доставка',
    point_of_sale: 'Точка продаж',
    info: 'Инфо',
    formerUse: 'товар б/у',
    wareProp: 'Предложения на {name} и его заменителей',
    substitutes: 'Заменители',
    in_stock: 'В наличии',
    not_in_stock: 'Продано',
    todayIn: 'Сегодня',
    tomorrow: 'Завтра',
    formDist: 'Со складов дистрибьютора',

    // SEARCH SETTINGS
    searchSettings: 'Дополнительные настройки поиска',
    searchPriority: 'Приоритет поиска на карте',
    sortByDeliveryTime: 'Необходимо как можно быстрее, цена вторична',
    sortByPrice: 'Необходимо как можно дешевле, есть время подождать',
    '-rank-delivery_time-price': 'Приоритет по сроку',
    '-rank-price-delivery_time': 'Приоритет по цене',
    showOnlyArticleOnMap: 'На карте отображены предложения только от продавцов, которые имеют физические точки продажи',
    noResults: 'Предложений не найдено',
    searching: 'Поиск...',
    noSell: 'Заказ возможен только через сайт продавца',
    addToBasket: 'Добавить в корзину',
    withVat: 'с НДС',
    showAllSellers: 'Показать больше продавцов',
    noSelectedArticles: 'Отсутствуют предложения для запрошенного артикула {article}',
    invitedArticle: 'Запрашиваемый товар',
    invitedArticleAndAnalogs: 'Запрашиваемый товар и аналоги',
    retailPrice: 'Розничная цена',
    ascSellerAboutWare: 'Задать вопрос про товар',
    doSort: 'Сортировать',
    lowerCost: 'Дешевле',
    higherCost: 'Дороже',
    higher: 'Популярное',
    searchTitle: "{search} - результаты поиска на {mp}",
    availabilityAtSeller: 'Наличие у продавца',
    searchVariants: 'Искать варианты',
    optimalPrice: 'Оптимальная цена'
  },

  orders: {
    order: 'Замовлення',
    cancelOrderItem: 'Отменить заказ на этот товар',
    filter: 'Фильтровать',
    onlyStatus: 'Только со статусом',
    resetFilters: 'Сбросить фильтры',
    filterByStatus: 'Фильтровать по статусу',
    orderActions: "Действия с заказом",
    contactAddress: 'Адрес и контактные данные',
    salePointInfo: 'Информация о продавце',
    thanksForOrder: 'Благодарим за заказ!',
    checkProgress: 'Отслеживать состояние заказа',
    forPermanentLink: 'По постоянной ссылке',
    forTelegramBot: 'В Telegram каналі. Для цього зараз зайдіть до нашого бота',
    forEmail: 'На email',
    deliveryMethod: 'Способ доставки' ,
    deliverer: 'Перевозчик',
    address: 'Адрес',
    service_office_number: 'Отделение',
    orderSum: 'Сума заказа',
    stockQuantity: 'Максимальное количество',
    fastOrder: 'Быстрый заказ'
  },

  prices: {
    rePushConfirm: 'Вы уверены что файл нужно перечитать?',
    removeConfirm: 'Вы уверены, что хотите удалить прайс {name}?',
    saleYourWaresEasy: 'Распродайте свои остатки быстро и удобно.',
    priceLoadAction: 'Загрузите свой прайс и остатки своего поставщика',
    priceLoadOption1Description2: 'Для случаев, когда загружаемый файл выполнен на основе прайса поставщика, неизвестного платформе. Загрузите прайс с вашими остатками и, при желании, вы сможете дать разрешение на торговлю вашими товарами другим продавцам.',
    name: 'Название прайса',
    status: 'Статус',
    currency: 'Валюта прайса',
    state: 'Состояние',
    pushed: 'Дата обновления',
    finish: 'Завершено, ошибок нет',
    lastUpdate: 'Дата последнего обновления',
    delivery_time: 'Срок поставки',
    formatting: 'Настройка файла для импорта данных на платформу',
    uploadStatus: 'Статус обработки файла',
    uploadSource: 'Источник загрузки',
    fileSize: 'Размер: {size} Mb',
    downloadFile: 'Скачать файл',
    creationPriceScheme: 'Создание нового ценообразования',
    order_time_until: 'Прием заказов до',
    warehousesRate: 'График поставки со склада',
    priceInQueueForUpdating: 'Прайс в очереди на обновление',
    step: 'Шаг',
    steps: [
      'Загрузка файла',
      'Настройка работи с прайсом',
      'Настройка обработки',
      'Склады и сроки поставки',
      'Результаты обработки'
    ],
    statuses: {
      done: 'Загружено',
      error: 'Ошибка',
      in_progress: 'В обработке',
      api_timeout: 'Время ожидания ответа от сервера закончилось',
      0: 'Неизвестная ошибка сервиса Priceload.',
      1: 'Неизвестная ошибка сервиса Priceload API.',
      '1.1.1': 'Ошибка при регистрации загрузки файла.',
      '2': 'Ошибка обработки прайс-файла.',
      '2.1': 'Формат файла не поддерживается.',
      '2.1.1': 'Формат архива не поддерживается.',
      '2.1.1.1': 'Поддерживается только 1 файл в архиве, получено {files_in_archive}.',
      '2.1.2': 'Обнаружена не поддерживаемая кодировка файла: {encoding}.',
      '2_1_3': 'Ошибка чтения CSV',
      '2.2': 'Ошибка разметки (маппинга) файла.',
      '2.2.1': 'Ошибка разметки (маппинга) колонок файла - маппинг не валидный.',
      '2.2.2': 'Сигнатура колонок изменилась в прайс-файле.',
    },
    uploadSources: {
      handle: 'Ручное',
      auto: 'Автоматическое'
    },
    selectUpdateMode: 'Виберите способ автоматической/ручной загрузки',
    manualUpdate: 'Ручное',
    urlUpdate: 'По ftp або http, https',
    manualSettings: 'Настройки автоматического обновления по ftp або http, https',
    manualSettingsDescription1: 'Укажите полный путь к файлу, начиная с ftp або http, https',
    manualSettingsDescription2: 'Если у вас ftp с паролем, то укажите ее в URL по схеме ftp://логин:пароль@<хост>:порт/путь_к_файлу',
    docTypeMarkupDescription: 'Настройку автоматической загрузки прайса на платформу можно будет сделать после удачной обработки файла. Также можно будет изменить все остальные настройки файла.',
    error_code: 'Код ошибки',
    error_message: 'Сообщение ошибки'
  },

  priceFile: {
    isActive: 'Показывать в результатах поиска',
    settingsTab: 'Настройка прайса',
    processingTab: 'Обработка файла',
    assortmentTab: 'Ассортимент',
    mainInfoBlock: 'Общая информация',
    mappingBlock: 'Настройка обработки файла',
    autoUpdateBlock: 'Автоматическое обновление',
    availabilitiesBlock: 'Настройка складов и срока доставки',
    uploadInfoBlock: 'Данные файла прайса',
    name: 'Название прайса',
    currency: 'Валюта в файле',
    dataStartRow: 'Номер первой строки с данными',
    noHeaderCol: 'Нет заголовков столбцов',
    rowWithTitles: 'Номер строки с заголовком колонок',
    oneTradeMarkInPrice: 'В прайсе одна торговая марка',
    noCounterInfoInPrice: 'Информация о количестве в файле отсутствует',
    description: 'Описание прайса',
    priceReUpload: 'Обновления файла прайса',
    created: 'Дата создания файла',
    updated: 'Дата загрузки',
    quantity_warehouse_is_own: 'Прайс содержит собственные остатки',
    availabilitiesDescription: 'Если график доставки заказов со склада до точки продажи зависит от дня недели, то можно дополнительно настроить эти особенности работы, воспользовавшись календарем',
    availabilitiesDescription2: 'Если товар хранится в точке продажи, то укажите "Срок поставки" 0 суток 0 часов',
    firstTenRow: 'Первые 10 строк загружаемого файла',
    auto: 'Автоматически',
    file_encoding: 'Кодирования файла',
    csv_delimiter: 'Символ разделения колонок',
    csvTitle: 'Настройка текстового файла {name}',
    emptyPreview: 'Данные предварительного просмотра отсутствуют',
    waitPreview: 'Генерируется превью файла. Не закрывайте окно.',
    preview: "Номер страницы для предпросмотра",
    data_sheet: 'Номер обрабатываемой страницы',
    dataSheetDescription: 'Будут загружены данные только с указаного листа!',
    payment_terms_text: 'Условия предоплаты (условия будут отображены покупателям)',
    warrantedReturnTermsText: 'Условия гарантии и возврата (условия будут отображены покупателям)',
    fullPrepaymentForPrice: 'Требуется предоплата для заказа с этого прайса'
  },

  priceStatusesFields: {
    price_file_id: 'Идентификатор файла прайса',
    articles_count: 'Количество артикулов',
    price_articles_count: 'Количество артикулов с ценами',
    stock_articles_count: 'Количество артикулов со складами',
    updated: 'Дата обновления',
    recognized_articles_count: 'Количество распознанных артикулов',
    offers_count: 'Количество предложений',
    offers_refreshed: 'Обновленные предложения',
    offers_fresh_count: 'Количество новых предложений',
    wares_count: "Количество идентифицированной номенклатуры",
    wareless_count: "Количество не идентифицированной номенклатуры",
    stocks_count: "Количество номенклатуры с ненулевыми остатками"
  },

  orderStatuses: {
    'null': 'Корзина',
    new: 'Создано',
    in_progress: 'В работе',
    ready: 'Подготовлено',
    issued: 'Выдано',
    autoended: 'Закончено автоматически',
    buyer_canceled: 'Отменено покупателем',
    buyer_rejected: 'Отклонено покупателем',
    seller_canceled: 'Снято продавцом',
  },

  warehouses: {
    warehouse: 'Склад',
    creationWarehouses: 'Создание склада',
    name: 'Название склада'
  },

  priceMapping: {
    article: 'Каталожный номер',
    trademark: 'Торговая марка',
    title: 'Название товара',
    description: 'Описание товара',
    selling_price: 'Цена продажи',
    purchase_price: 'Цена закупки',
    quantity: 'Количество на складе',
    multiplicity: 'Кратность продажи',
    note: 'Дополнительное описание товара',
    image: 'Ссылка на изображение товара',
    analog_article: 'Каталоговый номер аналога',
    analog_trademark: 'Название производителя аналога',
    barcode: 'Штрихкод',
    ucgfea: 'УКТ ВЭД Код',
    wholesale: 'Опт',
    retail: 'Розница',
    createWarehouse: '+ Создать склад',
    remove_make_selling_price: 'Цена продажи',
    isMappingProcessing: 'Процес обработки мапинга. Подождите...',
    image_type: 'Тип картинки',
    weight: 'Вес',
    weight_tare_type: 'Тип веса',
    sizes: 'Габариты',
    sizes_tare_type: 'Тип габаритов'
  },

  weightUnitTypes: {
    'kg': 'Килограмм',
    'g': 'Грамм',
  },

  sizesUnitTypes: {
    'mm': 'Миллиметр',
    'cm': 'Сантиметр',
    'm': 'Метр',
  },

  relationTypes: {
    'analog': 'Аналог',
    'includes': 'Содержит в себе',
    'part_of': 'Является частью',
    'replaced_by': 'Заменен на',
    'replaces': 'Является заменой',
    'improved_analog': 'Улучшенный аналог',
    'simplified_analog': 'Упрощенный аналог',
    'universal_analog': 'Универсальный аналог',
    'specific_analog': 'Аналог с уточнением',
    'lays_inside': 'Лежит внутри',
    'is_container_for': 'Является упаковкой',
    'couple': 'Парный артикул (левый/правый)',
    'used_alternative_code': 'Альтернативный код (используется)',
    'unused_alternative_code': 'Альтернативный код (не используется)'
  },

  tareTypes: {
    netto: 'Нетто',
    brutto: 'Брутто'
  },

  imageTypes: {
    general: 'Изображение номенклатуры',
    schema: 'Схема установки',
    promo: 'Промо изображение',
  },

  deliveryRate: {
    deliveryRateCreate: 'Создание нового тарифа',
    askAddRate: 'Добавлять в цену товара цену расходов на доставку, от склада поставщика до точки продажи?',
    notUseRate: 'Не добавлять',
    addByRate: 'Добавлять согласно тарифа',

    creationNewRate: 'Создание нового тарифа',
    existName: 'Существующий тариф',
    name: 'Название создаваемого тарифа',
    currency: 'Валюта параметров на форме',
    deliveryPrams: 'Параметры для учета логистических и других расходов',

    correction_factor: 'Процент от стоимости',
    constant: 'Константа{name}',
    weight_rate: 'Тариф за вес{name}',
    no_weight_factor: 'Процент без веса',
    no_trade_without_weight: 'Не торговать без веса',
  },

  priceScheme: {
    type: 'Название схемы ценообразования',
    name: 'Название схемы ценообразования',
    currency: 'Валюта параметров на форме',
    logisticParams: 'Параметры для учета логистических и других расходов',
    selectPriceCreationScheme: 'Выберите схему ценообразования',
    dynamicPriceSchemeParams: 'Параметры для динамического ценообразования',
    min_price: 'Минимальная цена, {name}',
    max_price: 'Максимальная цена, {name}',
    min_markup: 'Минимальная наценка, %',
    max_markup: 'Максимальная наценка, %',
    types: {
      static: 'Единая наценка независимо от цены, %',
      dynamic: 'Наценка зависит от цены'
    },
    removeConfirm: 'Вы уверены, что хотите удалить "{name}"?'
  },

  files: {
    uploadError: 'Ошибка загрузки',
    validateError: 'Файлы не валидные',
    filePath: 'Путь к файлу',
    fileName: 'Имя файла',
    priceRules: {
      1: 'Файл может быть архивированным: zip, rar.',
      2: 'Размер файла не может быть больше чем 5 Гб.',
      3: 'форматы файла, которые принимает наша платформа: csv, excel.'
    },
    dropOrClick: 'Перетащите файл сюда, или нажмите для загрузки'
  },

  sellerTrademarks: {
    orAnd: 'и/или',
    sellerTrademarksDescription: 'Если ваша организация является официальным представителем производителя и при этом имеет прямые контракты на поставки, то перечислите торговые марки, которые Ваша компания представляет. По возможности укажите ссылку на страницу официального сайта производителя, на которой есть подтверждение этого факта или предоставьте скан документа.',
    sellerTrademarksConfirmDescription: 'После создания списка, пожалуйста, свяжитесь с представителем платформы, чтобы он подтвердил статусы официального представителя. Только после подтверждения на платформе все предложения из Вашего личного склада будут отмечены, как товары от официального представителя ТМ.',
    createTm: 'Добавить новую торговую марку',
    editTm: 'Редактирования торговой марки',
    trademark_name: 'Название ТМ',
    verification_url: 'Ссылка на официальный сайт ТМ',
    verificationUrlDescription: 'Ссылка на страницу официального сайта ТМ, где есть подтверждение статуса официального представителя ТМ',
    verificationDocumentDescription: 'Если нет ссылки на официальный сайт ТМ для подтверждения статуса, то загрузите документ, где указана информация об официальном статусе скан сертификата, или скан страницы договора с ТМ. Документ будет доступен исключительно представителю платформы исключительно из целью проверки статуса официального представителя!!!',
    verificationConfirmed: 'Подтверждено',
    activeStatus: 'Статус',
    statuses: {
      new: 'Новый',
      approved: 'Подтверждено',
      rejected: 'Отклонено'
    }
  },

  basket: {
    title: 'Корзина',
    index: '№',
    important: 'Важно',
    orders: 'Заказы',
    doYouHaveAccount: 'Есть учетная запись на нашем сайте?',
    remove: {
      title: 'Удаление содержимого корзины',
      message: 'Вы уверены, что хотите удалить все содержимое корзины?'
    },
    count: 'Сума',
    sum: 'Сума: {sum}',
    toBePaid: 'К оплате',
    total: 'Всего',
    sumAllOrders: 'Сумма всех заказов',
    tableDescription: {
      1: 'Срок доставки указан в рабочих днях к точке продажи продавца.',
      2: 'Конечная цена может отличаться от той, что отражена на сайте из-за несоответствия курса валют и / или из-за наличия дополнительных платежей за доставку и / или комиссию банка.',
      3: 'Товар в корзине не зарезервировано у продавца.',
    },
    display_article: 'Номенклатура',
    display_title: 'Описание',
    delivery_time: 'Срок',
    paidStatus: 'Статус оплаты',
    is_paid: 'Оплачено',
    price: 'Цена',
    quantity: 'Кол-во',
    quantity2: 'Количество',
    totalAmount: 'В целом, {cur}',
    status: 'Статус',
    buyer: 'Покупатель',
    buyer_type: 'Кто будет покупателем',
    freeShipping: 'Безплатная доставка',
    buyerTypes: {
      person: 'Частное лицо',
      organization: 'Организация'
    },
    additionally: 'Дополнительно',
    sellerPhones: 'Телефони',
    buyer_first_name: 'Імя',
    buyer_last_name: 'Отчество',
    buyer_phone_number: 'Телефон покупателя',
    buyer_email: 'Email покупателя',
    salePointAddress: 'Адрес точки продажи',
    orderAddress: 'Адрес получения заказа',
    delivery_method: 'Способ получения заказа',
    delivery_address: 'Адрес получения заказа',
    createAddress: '+ Добавить новый адрес',
    orderAddressCreation: 'Создание адреса получения',
    deliveryService: 'Служба доставки',
    deliveryServiceName: 'Название службы',
    deliveryRegion: 'Область',
    deliveryCity: 'Населенный пункт',
    orderRecipient: 'Получатель',
    recipient_phone_number: 'Телефон получателя',
    recipient_last_name: 'Фамилия получателя',
    recipient_first_name: 'Имя получателя',
    paymentMethod: 'Оплата',
    payment_method: 'Способ оплаты',
    commentForm: 'Комментарий к заказу',
    no_call_required: 'Звонить только в случае, если что-то пошло не так',
    agreementForm: 'Согласие с условиями выполнения заказа',
    _agreeWithPublicOffer: 'Заказав вы соглашаитесь с <a class="el-link el-link--primary" href="/ru/docs/public-offer" target="_blank" @click.stop> условиями публичной оферты"</a>',
    selectAddressType: 'Выберите тип адреса',
    selectDeliveryService: 'Выберите службу',
    service_name: 'Название служби',
    setServiceName: 'Укажите название',
    service_office_number: 'Название отделения',
    orderNum: '№ заказа',
    basketIsEmpty: 'Корзина пустая',
    seller: 'Продавец',
    ware: 'Товар',
    priceFile: 'Прайс',
    shipment: 'ТТН',
    availability: 'Наличие',
    display_public_id: 'Номер заказа',
    orderDetailsAndTerms: 'Реквизиты и условия заказа',
    rejectOrderProcessing: 'Отказ от обработки заказа',
    setCancelInitiator: 'Укажите, кто был инициатором отказа от заказа',
    orderRecipientCreate: 'Создание Получателя заказ',
    orderShipment: 'Выдача заказа',
    currentClientShipments: 'Текущие отгрузки на клиента',
    waybillTitle: 'Транспортная накладная',
    waybill: 'Номер транспортной накладной',
    waybillNum: 'Номер',
    waybill_date: 'Дата создания',
    addressesInItems: 'Адреса, которые были в заказах',
    shipmentComment: 'Примечание выполнения заказа. Будет отображаться Покупателю',
    shipmentCreateDate: 'Дата выдачи',
    prev_price: 'Предыдущая цена',
    addShipment: '+ Додати нове відвантаження',
    wareAddToBasket: 'Товар добавлено в корзину',
    createOrder: 'Оформление заказа',
    buyerData: 'Даные покупателя',
    agreeWithPublicOffer: 'оформляя заказ, я соглашаюсь <br> с <a class="el-link el-link--primary" href="{href}" target="_blank" @click.stop>условиями договора публичной оферты</a>',
    oneClickBuyDesc: 'Просто оставьте номер, на который Вам перезвонит продавец и уточнит условия заказа.',
    setPhoneNumber: 'Укажите номер телефона'
  },

  catalog: {
    brand: 'Марка авто',
    model: 'Модель',
    generation: 'Поколение',
    production_year: 'Год выпуска',
    body_type: 'Тип кузова',
    drive_type: 'Привод',
    engine_type: 'Тип двигателя',
    engine_code: 'Код двигателя',
    capacity: 'Объем двигателя',
    fuel_type: 'Тип топлива',
    fuel_types_and_capacitie: 'Двигатель',
    modification: 'Модификация',
    garage: 'Гараж',
    nowTime: 'наст. время',
    ls: 'лс',
    catalog: 'Каталог автозапчастей',
    catalogFor: 'Каталог - ',
    autoParts: 'Запчасти',
    for: 'для',
    on: 'на',
    in: 'в',
    allCars: 'Все автомобили',
    autoPartsForAllCars: 'Запчасти на все автомобили',
    autoPartsForCars: 'Запчасти к автомобилям',
    allCarsModels: 'Все модели',
    allPartsFor: 'Все запчасти на {name}',
    allTrademarks: 'Все производители',
    trademarks: 'Производители',
    topProducts: 'Популярные пропозиции',
    topCarsBrands: 'популярние марки автомобилей',
    topCarsModels: 'популярные модели {name}',
    topCarsGenerations: 'популярные поколения авто {name}',
    topCarsModifications: 'популярные модификации авто {name}',
    topProductLines: 'Популярные запчасти | Популярные запчасти для {name}',
    topTrademarks: 'Популярные производители {name}',
    topRegions: 'Купить {name} в городах Украины',
    pricesFor: 'Цены на',
    auto: 'автомобилей',
    readMore: "Читать полностью",
    vinInquiries: 'Запроси по VIN на подбор'
  },

  garage: {
    removeConfirm: 'Вы уверены что хотите удалить {name} навсегда? (операция не может быть отменена)',
    requestToSelectionByVin: 'Запрос на подбор запчастей по VIN',
    requireVinForSelectionRequest: 'Для оформления запроса на подбор необходимо указать VIN транспортного средства',
    requireCarForVin: 'Для оформления запроса на подбор по VIN необходимо выбрать авто',
    selectionRequest: 'Запрос на подбор',
    requestCreated: 'Запрос отправлен: {date}',
    answersCount: 'Всего ответов: {num}',
    expiry: 'Полисдействительный до {date}',
    autoPartsForTO: 'Запчасти для ТО',
    originalCatalog: 'Орегинальный каталог',
    engine: 'Двигатель (объем, мощность)',
    cubSm: 'Куб. см.',
    characteristics: 'Характеристики',
    auto: 'автомобиль',
    statusSelect: 'Вибор статуса авто',
    color: 'Цвет',
    chooseYourCar: 'Выберите авто',
    chooseYourCarForStart: 'Начните поиск запчастей по выбору авто',
    startWithCarBrand: 'Начните с выбора марки авто',

    selectTitles: {
      brand: 'Начните поиск запчастей с выбора марки авто',
      model: 'Выберите модель',
      production_year: 'Выберите год',
      body_type: 'Выберите кузов',
      fuel_types_and_capacitie: 'Выберите двигатель',
      drive_type: 'Выберите привод',
      modification: 'Выберите модификацию'
    },

    selectorDesc: {
      production_year: 'Выпускались в следующих годах',
      body_type: 'В <strong>{year}</strong> году выпускались с такими кузовами:',
      fuel_types_and_capacitie: '<strong>{year}, {bodyType}</strong> выпускались с такими двигателями:',
      drive_type: '<strong>{year}, {bodyType}</strong> выпускались с такими приводами:',
      modification: 'Выпускались в следующих модификациях'
    },

    selectionRequests: {
      rawItem: 'Необходимая запчасть',
      itemComment: 'Коментарий к линии',
      comment: 'Коментарий к запросу',
      answersCount: 'Ответов'
    },

    factory: {
      brand: "Марка",
      model: "Модель",
      productionYear: "Год выпуска",
      bodyType: "Типа кузова",
      engineType: "Типа двигателя",
      fuelType: "Типа топлива",
      engineCapacity: "Обьем двигателя",
      modification: "Модификации",
      engineCode: "Код двигателя",
      powerPs: "Мощьность двигателя",
      gearType: "Трансмиссия",
      driveType: "Тип привода",
      brakeType: "Код типа тормозов",
      cylinders: "Количество цилиндров",
      gearSteps: "Количество передач",
    },

    main: {
      vin: "VIN",
      licensePlate: "Госномер",
      insuranceExpiryDate: "Полис действителен до",
      color: "Цвет",
      comment: "Примечание",
      commentDesc: '(никто не увидит, кроме вас)'
    },

    securityTitle: 'Безпека',

    security: {
      sHasAbs: 'ABS',
      sHasAsr: 'ASR',
      sHasEsp: 'ESP',
      sHasAbd: 'ABD',
      sCentralLock: 'Центральный замок',
      sAirbag: 'Подушка безопасности',
      sSignalling: 'Сигнализация',
      sImmobilizer: 'Иммобилайзер',
      sHeadlight: 'Тип фар',
      sHasBsm: 'Ассистент мертвых зон',
      sHasNv: 'Ночное видение',
      sHasGearLock: 'Замок на КПП',
      sHasAirSuspension: 'Пневмоподвеска',
      sHasArmoredBody: 'Бронированный автомобиль',
    },

    securityOptions: {
      sCentralLock: {
        no: 'Нету',
        original: 'Штатный',
        unoriginal: 'Нештатный'
      },

      sAirbag: {
        no: 'Нет',
        driver_only: 'Только водителя',
        several: "Несколько"
      },

      sSignalling: {
        no: 'Нету',
        original: 'Штатная',
        unoriginal: 'Нештатная'
      },

      sImmobilizer: {
        no: 'Нету',
        original: 'Штатный',
        unoriginal: 'Нештатный'
      },

      sHeadlight: {
        halogen: 'Галогенные',
        xenon: 'Ксенон',
        bixenon: 'Биксенон',
        adaptive_xenon: 'Адаптивный ксенон',
        diode: 'Диодные'
      }
    },

    multimediaTitle: 'Мультимедиа',

    multimedia: {
      mHasMp3: 'MP3',
      mHasCd: 'CD',
      mHasTapeRecorder: 'Магнитола',
      mHasAcoustics: 'Акустика',
      mHasDvd: 'DVD',
      mHasGps: 'Система навигации GPS',
      mHasSubwoofer: 'Сабвуфер',
      mHasBluetooth: 'Bluetouth',
      mHasCarPlay: 'CarPlay',
      mHasAndroidAuto: 'Android Auto',
    },

    comfortTitle: 'Комфорт',

    comfort: {
      cHasPowerSteering: 'Усилитель руля',
      cWindowLifters: 'Тип стеклоподъемников',
      cHasRoofRails: 'Рейлинги',
      cHasParkAssist: 'Ассистент парковки',
      cHasLaneAssist: 'Ассистент полос',
      cHasConditioner: 'Кондиционер',
      cHasMirrorsHeating: 'Подогрев зеркал',
      cHasMirrorsDimming: 'Затемнение зеркал',
      cClimateControl: 'Климат контроль',
      cSeatsHeating: 'Подогрев сидений',
      cHasMultimediaSteering: 'Мультимедийный руль',
      cHasCruiseControl: "Круиз-контроль",
      cHasParktronic: 'Парктроник',
      cHasLightSensor: 'Датчик света',
      cInteriorMaterial: 'Материал оббивки салона',
      cHasRainSensor: 'Датчик дождя',
      cSunroof: 'Люк',
      cHasHeadlightWasher: 'Омыватель фар',
      cHasStartButton: 'Запуск кнопкой',
      cHasSeatMemory: 'Память сидений',
      cHasSteeringHeating: 'Обогрев руля',
      cHasSummerTires: 'Летние шины',
      cHasWinterTires: 'Зимние шины',
      cHasGlassTinting: 'Тонировка стекла',
      cHasTowbar: 'Фаркоп',
      cHasGasInstallation: 'Газовая установка',
    },

    comfortOptions: {
      cInteriorMaterial: {
        fabric: 'Ткань',
        velor: 'Велюр',
        leather: 'Кожа',
        alcantara: 'Алькантара'
      },

      cSunroof: {
        no: 'Нет',
        sunroof: 'Люк',
        panorama: 'Панорама'
      },

      cWindowLifters: {
        manual: 'Ручные',
        mixed: 'Перед электо, зад ручные',
        electronic: 'Все электро'
      },

      cSeatsHeating: {
        no: 'Нет',
        front: 'Передние',
        back: 'Задние',
        all: 'Все'
      },

      cClimateControl: {
        no: 'Нет',
        '1-zone': 'Одна зона',
        '2-zone': 'Две зоны'
      }
    },

    characteristicsTitle: 'Технические характеристики',

    // Статус отображения
    statuses: {
      mine: 'Принадлежит мне сейчас (начальный статус)',
      not_mine: 'Авто мне не принадлежал',
      on_sale: 'Продажа',
      given: 'Подарил (Отдал по наследству)',
      stolen: 'Угон',
      broken: 'ДТП. Восстановлению не принадлежит',
      utilized: 'Утилизация',
    }
  },

  sRequests: {
    requestSubmit: 'Запрос на подбор №{id} отправлено',
    requestSubmittedDesc: 'Подождите ответа от продавцов автозапчастей, а затем выберите из предложенных вариантов лучший.',
    showRequestLinkDesc: 'Смотреть ответы без регистрации на запрос по VIN по постоянной ссылке',
    createRequestByVin: 'Найти автозапчасти с помощью профессионалов',
    createRequestByVinDesc: `
      <p>Если вы не понимаете, как подбирать запчасть, или сомневаетесь, какой именно вариант запчасти подходит именно на ваш автомобиль, то воспользуйтесь БЕСПЛАТНОЙ помощью профессиональных продавцов автозапчастей. Создать запрос на подбор очень просто:</p>
      <ol>
        <li>Укажите данные о своем автомобиле</li>
        <li>Укажите запчасти, которые вам нужны</li>
        <li>Отправьте свой запрос профессионалам - продавцам автозапчастей</li>
        <li>Получите ответы на несколько продавцов</li>
        <li>Выберите самое лучшее предложение и закажите</li>
      </ol>
    `,
    myCity: 'Мой населенный пункт',
    price: 'Цена продажи, {val}',
    delivery_time: 'Время доставки, дни',
    requestSend: 'Отправлено',
    sRequestNum: 'Запрос №{id}',
    public_id: 'Номер запроса по VIN',
    allCarsModels: 'Все марки автомобилей',
    created: 'Запрос отправлен {date}',
    comment: 'Коментарий покупателя к запросу',
    responseExitConfirm: 'Вы хотите отменить ответ?',
    responseProcessingExitConfirm: 'В случае отмены все предложения будут удалены. Вы действительно хотите отменить обработку запроса?',
    comment_private: 'Поставщик, цена закупки, другие условия',
    commentPrivateDesc: 'Текст не будет видить покупатель. Поле доступно исключительно для внутреннего использования.',
    comment_public: 'Коментарий для покупателя',
    hideArticlesForBuyer: 'Не показивать покупателю каталожные номера',
    customWareSelect: 'Необходимый товар отсутствует. Ввести данные самостоятельно',
    emptySearchText: 'В ваших прайсах не найдено товаров по поиску {name}',
    selectWareFromPrises: 'Виберите товар из прайсов',
    sellerComment: 'Коментарий продавца',
    emptyResponse: 'Ответ отсутствует',
    answerTime: 'Время ответа',
    userContacts: 'Контактные данные',
    userContactsDesc: 'Контактные данные не будут переданы продавцам автозапчастей и будут использованы только для информирования о новых ответах продавцов',
    userContactsPhoneDesc: 'Будет отправлено уведомление о результате обработки запроса',
    telegramBotDesc: 'Отслеживать состояние обработки запроса Telegram на канале',
    steps: [
      'Указать данные по авто',
      'Указать нужные запчасти',
      'Указать контактные данные'
    ]
  },

  cleverSearch: {
    searchIn: 'Искать в',
    searchPlaceholder: 'Товар, марка или модель авто, производитель, артикул',
    specifyCategory: 'Пожалуйста, укажите категорию',
    article: 'Найдено в артикулах',
    car_brand: 'Перейти в бренды',
    car_model: 'Перейти в модели',
    product_line: 'Перейти в категорию',
    tm: 'Перейти в торговые марки',
  },

  questions: {
    title: 'Часто задаваемые вопросы',
    search: {
      title: '🚘 Как выбрать {tl} для своего авто?',
      answer: 'Вы можете подобрать {tl} воспользовавшись формой подбора по автомобилю: выбирайте марку, модель авто, год выпуска и тип вашего мотора. А также можно воспользоваться формой поиска по каталожному номеру запчасти.\n'
    },
    popular: {
      title: "🔝 Какие бренды самые популярные в группе {tl}?",
      answer: "Самые популярные производители:"
    },
    topForYear: {
      title: "🥇 Какие самые популярные товары в группе {tl} в {year} году?",
      answer: "ТОП-5 популярных товаров в {year} году:"
    },
    price: {
      title: "💵 Какие цены в {mp} на {tl}?",
      answer: "Диапазон цен на {tl} от {minPrice}, до {maxPrice} грн."
    }
  },

  requisites: {
    actualData: "Сохраняя данные вы подтверждаете, что они являются достоверными и актуальными",
    accept_term: "Согласен с условиями договора",
    send_connect: "Отправить запрос на подключение",
    print_document: "Распечатать договор",
    physical_person: "Физическое лицо",
    entrepreneur: "Физическое лицо предприниматель",
    juridical_person: "Юрлицо",
    bank: {
      name: "Название банка",
      mfo: "МФО банка",
      iban: "IBAN"
    },
    contact: {
      address: "Фактический адрес",
      email: "Email",
      phone: "Телефон",
      copy: 'Копировать'
    },
    scan: {
      stateRegister: "Выписка из Единого государственного реестра (1 страница либо 1+2 страницы в одном файле)",
      stateRegisterDesc: '(или 1+2 страницы в одном файле)',
      stateRegister2: "Выписка из Единого государственного реестра (2 страница)",
      accountOpen: "Справка об открытии счета",
      accountOpenDesc: '(или справка о банковских реквизитах)',
      pdv: "Выдержка из реестра плательщиков НДС."
    },
    info: {
      type: 'Форма собственности',
      firstName: "Имя",
      lastName: "Фамилия",
      middleName: "Отчество",
      inn: "ИНН",
      address: "Юридический адрес",
      officialName: "Официальное название предприятия",
      shortName: "Краткое наименование предприятия",
      pdv: "Платник ПДВ",
      certificate: "№ Свидетельства плательщика НДС",
      egrpoy: "ЕГРПОУ"
    },
    signatory_full_name: 'ФИО подписчика'
  },

  platform: {
    choiceOfWorkingConditionsOnSites: 'Выбор условий работы на площадках',
    selectConditions: 'Выберите тарифы для площадок',
    platformListDescription: 'Выберите тарифы, по которым вы планируете работать на площадках. Тарифы и количество строк в прайсах можно изменить в любое время.',
    connect_description: "Для начала работы на площадке <strong>{business_name}</strong>, необходимо заключить Договор с компанией {mp}. Для заключения договора, введите реквизиты компании, распечатайте договор в двух экземплярах, подпишите и  отправьте по адресу <strong>{address}</strong>",
    name: "Название площадки",
    contract: "Договор",
    status: "Статус",
    subscriptionFee: 'Абонплата',
    subscriptionFeeDescription: 'Абонплата начисляется за каждые 30 дней работы на сайте. Отдельно оплачиваются средства за конверсные действия согласно тарифам',
    statuses: {
      approved: "Активный",
      await_confirmation: "Ожидает подтверждения",
      rejected: "Отклонено",
      empty: "Отсутствует"
    },
    signContractDescription: 'Отправляя запрос на подключение вы соглашаетесь с условиями выбранных тарифов.'
  },

  balance: {
    currentBalance: "Текущий баланс на",
    upBalance: "Пополнить баланс",
    enterAmount: "Укажите сумму пополнения",
    generateInvoice: "Сформировать счет для безналичной оплаты",
    balance: 'Баланс',
    dateTime: 'Дата и время',
    type: 'Тип',
    order: 'Заказ',
    article: 'Артикул',
    price: 'Цена',
    quantity: 'Количество',
    base: 'Сумма',
    rate: 'Роялти %',
    rateAmount: 'Роялти',
    status: {
      refill: 'Пополнение',
      write_off: 'Списание',
      accrual: 'Начисление',
      refund: 'Возврат'
    }
  },

  carBodyTypes: {
    1: 'Наклонная задняя часть',
    2: 'Седан',
    3: 'Купе',
    4: 'Кабриолет',
    5: 'Универсал',
    6: 'Вэн',
    7: 'Особый кузов',
    8: 'Вездеход закрытый',
    9: 'Пикап',
    10: 'Фургон',
    11: 'C бортовой платформой / ходовая часть',
    12: 'Автобус',
    13: 'Фургон / универсал',
    14: 'Тарга',
    15: 'Вездеход открытый',
    16: 'Самосвал',
    17: 'Автомобиль для нужд коммунальног',
    18: 'Тягач',
    19: 'Одноосный тягач',
    20: 'Кузов с твердым верхом',
    21: 'Фургон / хетчбэк',
    22: 'Мотоцикл',
    23: 'Седельный тягач',
    24: 'Бетономешалка',
    25: 'Трактор'
  },

  carEngineTypes: {
    1: 'Бензиновый двигатель',
    2: 'Дизель',
    3: 'Электрический двигатель',
    4: 'Гибрид',
    5: 'Двигатель ванкеля',
    6: 'Бензиновый двигатель (двухтактный)',
    7: 'Гибрид (карбюраторный двигатель/электродвигатель)'
  },

  carFuelTypes: {
    1: 'Бензин',
    2: 'Дизель',
    3: 'Бензин / природный газ (cng)',
    4: 'Бензин/автогаз (lpg)',
    5: 'Бензин / электричество',
    6: 'Природный газ',
    7: 'Бензин / этанол',
    8: 'Смесь',
    9: 'Электрическ. - дизельное топливо',
    10: 'Спирт',
    11: 'Сжиженный газ',
    12: 'Электричество',
    13: 'Бензин / этанол / электричество',
    14: 'Flexfuel / электро',
    15: 'Водород',
    16: 'Бензин / этанол / природный газ',
    17: 'Дизель / природный газ (cng)',
    18: 'Бензин / электричество / автогаз (lpg)',
    19: 'Бензин / природный газ (lpg)'
  },

  carGearTypes: {
    2: 'Механическая коробка передач',
    // 1: 'Ручная коробка передач 5-ступенчатая',
    // 3: 'Ручная коробка передач 4-ступенчатая',
    // 7: 'Ручная коробка передач 6-ступенчатая',
    11: "Вариатор",
    // 5: 'Полностью автоматическая коробка передач',
    8: "Автоматическая коробка передач",
    // 13: 'Cvt-автоматическая коробка передач (без ступений)',
    // 4: "Автоматическая коробка передач 3-ступенчатая",
    // 10: "Автоматическая коробка передач 4-ступенчатая",
    // 12: 'Автоматическая коробка передач 5-ступенчатая',
    // 14: "Автоматическая коробка передач 6-ступенчатая",
    // 15: 'Передачи с двойным сцеплением 7-ступенчатая',
    // 16: 'Автоматическая коробка передач 8-ступенчатая',
    // 9: 'Ступенчатая / факультативная автоматическая коробка передач',
    // 6: 'Лента с толкающими звеньями (бесступенчатая)',
    17: 'Робот'
  },

  carDriveTypes: {
    1: 'Передний привод',
    2: 'Задний привод',
    3: 'Полный привод',
    4: 'Прямой',
    5: 'Цупь',
    6: 'Кардан',
    7: 'Вариатор',
    8: 'Ремень'
  },

  labels: {
    error: 'Ошибка',
    avatar: 'Аватарка',
    logo: 'Логотип',
    phone_number: 'Телефон',
    last_name: 'Фамилия',
    middle_name: 'Отчество',
    first_name: 'Ваше имя',
    email: 'Email',
    password: 'Пароль',
    password2: 'Повторить пароль',
    recovery_key: 'Ключ активации',
    captcha: 'Капча',
    facebook: 'Facebook',
    google: 'Google',
    companyName: 'Название компании',
    isSeller: 'Ваша компания занимается торговлей автозапчастями?',
    point_sale_activities: 'Виды деятельности точки продаж',
    address: 'Адреса',
    noAddress: 'Нет физического адреса для контакта с клиентами',
    country: 'Страна',
    admin1: 'Область',
    city: 'Населенный пункт',
    street: 'Улица',
    building: 'Дом',
    flat: 'Офис',
    landmarks: 'Ориентиры, которые помогут быстрее найти указанный адрес',
    phone: 'Телефон',
    phones: 'Телефоны',
    recipientPhone: 'Телефоон получателя',
    main: 'Основной',
    workDays: 'Режим работы',
    startWork: 'Начало работы',
    endWork: 'Конец роботы',
    timeSeparator: '-',
    returnIsPossible: 'Возможен возврат',
    guarantee: 'Гарантия',
    contact_name: 'Фамилия, имя',
    salePointName: 'Название точки продаж',
    is_return_possible: 'Возможен возврат',
    is_warranted: 'Предоставляется гарантия',
    nameForSocial: 'Ваше имя (для общения)',
    login: 'Логин',
    detail: 'Сведения',
    non_field_errors: 'Неверные данные',
    searchInPrice: 'Искать в прайсах',
    exactArticle: 'Только артикул который искали',
    status: 'Статус',
    mains: 'Основные',
    priceGrn: 'Цена',
    delivery_time: 'Срок поставки',
    payment_methods: 'Способы оплаты',
    delivery_methods: 'Способи доставка',
    warrantedAndReturn: 'Гарантия и возврат',
    other_selling_terms: 'Дополнительно',
    payment_terms: 'Условия оплати',
    sale_activities: 'Виды деятельности продавца',
    in_stock: 'В наличии',
    localOffers: 'Местные предложения',
    is_official_trademark: 'Со складов дистрибьютора',

    auto_update_url: 'Путь к файлу',
    seller: 'Продавец',
    buyer_phone_number: 'Телефон заказчика',
    recipient_phone_number: 'Телефон получателя',
    buyer_email: 'Email заказчика',
    buyer_phone: 'Телефон заказчика',

    not_found: 'Товар уже не продается у данного продавца',
    price_changed: 'Цена изменилась',
    quantity_changed: 'Количество изменилось',
    terms_changed: 'Условия изменились',
    info_changed: 'Информация о товаре изменилась',
    insufficient_stock: 'Количество изменено',
    buyer: 'Покупатель',
    reloadPageByTermsChange: 'Во время оформления заказа условия изменились (цени, количество, наличие). Проверте, что новие условия вас удовлетворяют и повторно отправте заказ в работу. Если условия вас не удовлетворяют то повторите поиск.', //
    created: 'Создано',
    column_mapping: 'Ошибка маппинга',
    terms_urls: 'Ссылка на ресурс',
    buyer_first_name: 'Имя покупателя',
    buyer_last_name: 'Фамилия покупателя',
    payment_method: 'Способ оплати',
    delivery_method: 'Способ получения заказа',
    delivery_address: 'Адрес получения заказа',
    basket: 'Корзина',
    items: 'Товары',
    empty: 'пустое',
    _schema: 'Схема запроса',
    offer_id: 'Идентификатор товара',
    activation_key: 'Ключ активации'
  },

  sale_activities: {
    store: 'Автомагазин (Интернет магазин)',
    service_station: 'СТО',
    warehouse: 'Оптовый склад дистрибьютора',
    disassembly: 'Разборка'
  },

  payment_terms: {
    full_prepayment: 'Полная предоплата (100%)',
    late_payment: 'Отсрочка платежа',
    on_credit: 'В кредит',
    partial_prepayment: 'Частичная предоплата',
    payment_upon_receipt: 'Оплата при получении в точке продажи',
    postpay_on_carrier: 'Наложенный платеж на перевозчике',
  },

  payment_methods: {
    card: 'Банковская карта',
    cashless_payment: 'Безналичный платеж',
    cash_payment: 'Наличные',
    card_to_card: 'С карты на карту',
    cash_on_delivery: 'Оплата при получении у перевозчика',
    on_credit: 'В кредит'
  },

  delivery_methods: {
    free_shipping: 'Бесплатная доставка',
    carrier_in_country: 'Транспортная компания',
    courier: 'Курьер продавца',
    pickup: 'Самовывоз из точки продажи',
    regular_bus: 'Рейсовые автобусы',
    taxi: 'Такси',
  },

  deliveryTypes: {
    service_office: 'Отделение службы доставки',
    address: 'Доставка по адресу'
  },

  deliveryServicesType: {
    service_office: 'Транспортная компания (на отделение)',
    address: 'Транспортная компания (на адрес)'
  },

  deliveryServices: {
    'novaposhta_ua': 'Новая почта',
    "nexpress_com_ua": 'Ночной экспресс',
    "ukrposhta_ua": 'Укрпочта',
    "gunsel_ua": 'Гюнсел',
    "autolux_ua": 'Автолюкс',
    "sat_ua": 'САТ',
    "justin_ua": 'Justin',
    'other': 'Другая',
    'meest_com': 'MeestExpress'
  },

  other_selling_terms: {
    vat_payer: 'Плательщик НДС',
    free_shipping: 'Бесплатная доставка',
    price_without_hidden_fees: 'Цена без скрытых платежей',
    return_possible: 'Возможен возврат',
    warranted: 'Гарантия',
    with_installation_only: 'Продажа вместе с установкой',
    former_use: 'Товар б/у',
    does_not_conduct_activities: 'Не ведет деятельность',
    return_not_possible: 'Возврат невозможен'
  },

  phoneChannels: {
    phone: 'Телефон',
    sms: 'SMS',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp'
  },

  routes: {
    menuBusiness: 'Меню Автобизнес',
    profileChange: 'Изменить профиль',
    city: 'Город',
    home: 'Главная',
    search: 'Поиск по артикулу',
    catalog: 'Каталог',
    catalog_search: 'Поиск по каталогу',

    finance: "Финансы",
    enter: 'Вход',
    exit: 'Выход',
    account: 'Учетная запись',

    article: '',
    sale_point: 'Точка продажи',
    errors: '',

    auth: 'Аутентификация',
    auth_activation: 'Активация учетной записи',
    auth_completion: 'Завершение аутентификации',
    auth_pass: 'Восстановление пароля',
    auth_pass_send: 'Восстановление пароля',
    auth_pass_recovery: 'Восстановление пароля',

    basket: 'Корзина',
    basket_order_create: 'Создание нового заказа на продавца',
    basket_order_created: '',
    basket_order_created_hash: '',
    basket_order: 'Заказ',
    basket_order_hash: 'Заказ',
    basket_orders: 'Мои заказы',

    profile: 'Профиль',
    profile_business: 'Бизнес',
    profile_business_create: 'Создание бизнес-профиля',
    profile_business_edit: 'Настройки бизнеса',
    profile_business_seller_orders: 'Заказы покупателей',
    profile_business_seller_orders_order: 'Заказ',

    profile_business_sale_points: 'Точки продаж',
    profile_business_sale_points_create: 'Создание точки продаж',
    profile_business_sale_points_edit: 'Редактирование точки продажи',

    profile_business_prices: 'Прайсы',
    profile_business_prices_upload: 'Загрузка',
    profile_business_prices_create: 'Создание',
    profile_business_prices_edit: 'Редактирование',

    profile_business_platforms: 'Площадки',
    profile_business_platforms_connect: 'Подача заявки',
    profile_business_platforms_contract: 'Договор публичной оферты',
    profile_business_balance: 'Баланс',

    profile_business_s_requests: 'Запросы на подбор по VIN',
    profile_business_s_requests_edit: 'Обработка запроса по VIN',

    garage: 'Гараж',
    garage_create: 'Создание авто',
    garage_edit: 'Редактирование авто',
    garage_requests: 'Запросы на подбор по VIN',
    garage_requests_create: 'Создание запроса',
    garage_requests_created: 'Запрос по VIN',
    garage_requests_response: 'Ответ на запрос по VIN',
    garage_responses: 'Ответи на запросы по VIN',

    vin_requests_master_create: 'Найти автозапчасти с помощью профессионалов',
    vin_requests_master_created: 'Запрос по VIN создано',
    vin_requests_hash: 'Ответи на запросы по VIN',

    test: 'Тестовая страница',
    development: '',
    docs: 'Документация',
    about_us: 'О нас',
  },

  buttons: {
    copy: 'Копировать',
    back: 'Назад',
    next: 'Далее',
    edit: 'Редактировать',
    no: 'Нет',
    yes: 'Да',
    create: 'Создать',
    goEnter: 'Войти',
    clear: 'Сбросить',
    apply: 'Применить',
    google: 'Sign in with Google',
    facebook: 'Sign in with Facebook',
    recoveryPassword: 'Восстановить пароль',
    send: 'Отправить',
    activate: 'Активировать',
    save: 'Сохранить',
    cancel: 'Отменить',
    finish: 'Завершить',
    remove: 'Удалить',
    nix: 'Отменить',
    confirm: 'Подтвердить',
    select: 'Выбрать',
    continue: 'Продолжить',
    continueShopping: 'Продолжить покупки',
    change: 'Изменить',
    addSome: 'Добавить {name}',
    clearBasket: 'Очистить корзину',
    selectDeliveryAndPayment: 'Выбрать доставку и оплату',
    copyTimeToAllDays: 'Скопировать время на все дни',
    createSalePoint: 'Создать точку продажи',
    createBusiness: 'Создать бизнес-профиль',
    addBusinessProfile: 'Добавить новый бизнес профиль',
    collapse: 'Свернуть',
    collapseAll: 'Свернуть все',
    unCollapseAll: 'Развернуть все',
    removeProfile: 'Удалить профиль',
    leaveProfile: 'Уйти из профиля',
    upload: 'Загрузить',
    joinedSocial: 'Связано',
    breakSocialLink: 'Разорвать связь.',
    uploadPhotoText: 'Загрузить фото',
    centered: 'По центру',
    searchGooglePhoto: 'Искать изображения в интернете',
    uploadPrice: 'Загрузить прайс',
    searchAgain: 'Повторить поиск',
    wrongTableData: 'Неверно отображено содержимое файла в таблице',
    getMoreRows: 'Показать еще {num} строк',
    createPrice: 'Создать новый прайс',
    uploadOtherFile: 'Загрузить другой файл',
    reloadInfo: 'Обновить данные',
    reload: 'Обновить',
    goBackHome: 'Вернуться на главную',
    createPriceScheme: 'Создать ценообразование',
    newPriceScheme: '+ Новое ценообразование',
    waitLoading: 'Файл загружается. Не закрывайте окно.',
    otherCity: 'Другой город',
    addTrademark: '+ Добавить ТМ',
    addDoc: 'Добавить документ',
    pushToProcessing: 'Отправить на переделку',
    createDeliveryRate: '+ Создать тариф доставки',
    search: 'Искать',
    toOrder: 'Заказать',
    cancelAllOrder: 'Отказаться от всего заказа',
    toOrderList: 'В список заказов',
    cancelOrderItem: 'Отменить заказ',
    setNextStatus: 'Перевести в следующий статус',
    setStatus: 'Перевести в "{status}"',
    addRecipient: '+ Создать получателя',
    likeList: 'Списком',
    onMap: 'На карте',
    clearAllFilters: 'Сбросить все фильтры',
    removePrice: 'Удалить прайс',
    submitAskToSeller: 'Отправить вопрос продавцу',
    loadMore: 'Загрузить еще',
    addLink: 'Добавить ссылку',
    buy: 'Купить',
    lessTrademarks: 'Меньше производителей',
    moreTrademarks: 'Більше призводителей',
    moreCategory: 'Больше категорий',
    lessCategory: 'Меньше категорий',
    comparePrices: 'Сравнить цены',
    addCar: 'Добавить авто',
    goToGarage: 'В гараж',
    orderToSelection: 'Оформить запрос на подбор',
    addMoreRow: 'Добавить еще строчку',
    emitRequest: 'Отправить запрос',
    toSelectionRequestsList: 'К списку запросов по VIN',
    newSelectionRequest: 'Создать запрос',
    selectionRequestHistory: 'История запросов',
    newRequest: 'Новый запрос',
    notebook: 'Блокнот',
    view: 'Просмотр',
    answer: 'Ответить',
    answerShow: 'Смотреть ответ',
    answerHide: 'Спрятать ответ',
    addWare: 'Добавить товар',
    addResponse: 'Добавить ответ',
    submitRequest: 'Отправить ответ',
    noPropositionUseCustom: 'Предложений нет в списке. Ввести самостоятельно',
    allRequests: 'Все запроси',
    processed: 'Обработаные',
    allPhones: 'Все телефони',
    allEmails: 'Все email',
    signContract: 'Подписать договор',
    bySelectedIn: 'Купить выбраное у {name}',
    subscribe: 'Подписаться',
    oneClickBuy: 'Купить в 1 клик',
  },

  placeholders: {
    email: 'Адрес электронной почты',
    password: 'Пароль',
    enterPartNumber: 'Введите каталожный номер запчасти',
    activation_key: 'Введите ключ активации',
    comment: 'Комментарий',
    setReturnTerms: 'Укажите условия возврата',
    setReturnTermsLink: 'Укажите ссилку на условия возврата',
    setWarrantyTerms: 'Укажите условия гарантии',
    setWarrantyTermsLink: 'Укажите ссилку на условия гарантии',
    days: 'Дней',
    hours: 'Часов',
    catalogNumInPrice: 'Каталожный номер в прайсе',
    chooseOrEnter: 'Выберите из списка или добавьте новое',
    filterByTrademark: 'Фильтр по торговой марке',
    filterByStatus: 'Фильтровать по статусу',
    setRecipient: 'Выберите получателя',
    setStatus: 'Выберите статус',
  },

  message: {
    warning: 'Внимание',
    success: 'Успех',
    isActivated: 'Ваш аккаунт активирован',
    checkEmail: 'Проверьте, пожалуйста, почту.',
    businessRemoved: 'Бизнес профиль удален.',
    businessLeaved: 'Профиль отвязан от бизнеса',
    priceUploadSuccess: 'Прайс загружен.',
    priceUploadProcess: 'Загрузка прайса',
    priceRemoved: 'Прайс удален.',
    priceAvailabilityUploadProcess: 'Загрузка настроек складов',
    priceAvailabilityUploadSuccess: 'Загрузка настроек складов завершено.',
    checkChangedAvailabilities: 'Настройка складов была изменена, проверьте обновленные данные.',
    orderCreated: 'Заказ создан, проверьте почту <strong>{email}</strong>. <strong>Тема письма:</strong> Заказ <strong>{id}</strong>, от Покупателя <strong>{buyer}</strong>',
    orderCreatedNotify: 'Заказ №<strong>{id}</strong> создан. В ближайшее время с вами свяжется наш менеджер',
    orderCanceled: 'Заказ {order} отменен',
    confirmSetNextStatus: 'Вы действительно хотите изменить статус строк заказов на "{status}" ?',
    confirmChangePriceActivity: 'Вы действительно желаете изменить режим отображения прайса в результатах поиска ?',
    checkMapping: 'Система обнаружила вероятновть невергоно маппынга. Пожалуйста проверьте правильность маппинга и повторите попитку сохранения.',
    invalidMapping: 'Мапинг прайса не валидный. Обратитесь к специалистам по технической поддержке для консультации.',
    businessSave: "Ваш бизнес-профиль успешно сохранен",
    platformConnect: "Подключение состоится только после получения подписанного бумажного договора в двух экземплярах",
    authIsRequired: 'Необходимо зарегестрироваться',
    authForInquire: 'Для оформления запроса на подбор необходимо зарегистрироваться',
    editModification: 'Для того что б фильтровать каталог, необходимо указать код модификации',
    confirmRemoveRequestSelection: 'Вы уверены что хотите удалить запрос на подбор №{id}?',
    salePointIsNotActive: 'К сожалению точка продажи временно не принимает заказы через сайт. Попробуйте подобрать другую точку продажи.',
    setDataPreviewSheen: 'Мапинг прайса не сохранен. Попробуйте еще раз.',
    changesSaved: 'Изменения сохранены'
  },

  errors: {
    api: {
      title: 'Ошибка',
      message: 'Ошибка сервера'
    },
    400: {
      title: 'Не валидный зпрос',
    },
    401: {
      title: '',
      message: 'Время сессии закончилось. Войдите в систему повторно'
    },
    404: {
      title: 'Ошибка',
      message: 'Такой страницы не существует'
    },
    500: {
      title: 'Ошибка',
      message: 'Ошибка сервера'
    },
    502: {
      title: 'Ошибка',
      message: 'Сервер перегружен'
    },
    503: {
      title: 'Ошибка',
      message: 'Сервис временно недоступен'
    },
    504: {
      title: 'Ошибка',
      message: 'Сервер не отвечает'
    },
    default: {
      warning: 'Внимание',
      title: 'Ошибка',
      message: 'Возникла непредвиденная ошибка'
    },
    captcha: {
      title: 'Системная ошибка',
      message: 'Отсутствует капча'
    },
    login: {
      title: '',
      message: 'Пожалуйста, зарегистрируйтесь в системе.'
    },
    logout: {
      title: 'Успех',
      message: 'Вы вышли из системы.'
    },
    businessAccess: {
      title: 'Ошибка доступа',
      message: 'Вы не имеете доступа к бизнес профилю'
    },
    price_status: {
      '404': 'Файл находится в обработке',
      empty: 'На данный момент данные отсутствуют'
    },
    upload: {
      title: 'Ошибка загрузки',
      message: 'При загрузке файла {name} возникла ошибка. Пожалуйста, повторите попытку загрузки.',
    },
    invalid_encoding: {
      message: 'Неправильное кодирование файла ({message})'
    },
    reloadPage: 'Данные устарели. Перезагрузите страницу, пожалуйста!',
    terms_changed: {
      title: 'Внимание'
    },
    emptyData: 'Данные отсутствуют',
    emptySearch: {
      label: 'У продавцов, к сожелению, отсутствуют предложения на {name}',
      message: `
        ЗПо результатам поиска {name} ничего не найдено. Попрабуйте: <br>
        <span class="ml-2">- Проверить каталожный номер - возможно в номере ошибка;</span> <br>
        <span class="ml-2">- воспользоватся каталогом подбора;</span>
      `
    }
  },

  validation: {
    simple: {
      required: 'Поле обязательное',
      min: 'Минимальное количество символов {num}',
      max: 'Превышено максимальное количество символов {num}',
      selectSome: 'Необходимо выбрать элементы',
      existedValue: 'Это значение уже используется.',
      number: 'Поле должно содержать только цифры',
      format: 'Неверный формат',
      minOneOption: 'Выберите один из пунктов',
      length: 'Поле должно содержать {num} символов'
    },
    first_name: {
      required: 'Введите имя.',
    },
    email: {
      required: 'Введите почтовый адрес.',
      pattern: 'Email невалидный.'
    },
    password: {
      required: 'Введите пароль.',
    },
    password2: {
      required: 'Пароли должны быть одинаковыми.',
      ident: 'Пароли должны совпадать. Введите пароль корректно.'
    },
    activation_key: {
      required: 'Для активации необходимо ввести ключ, указанный в письме.'
    },
    avatar: {
      format: 'Изображение должно быть формата - {format}',
      size: 'Размер изображения не может превышать {size}'
    },
    price: {
      format: 'Файл должен быть формата - {format}',
      size: 'Размер файла не может превышать {size}'
    },
    address: {
      required: 'Укажите адрес или укажите наглядно, что его нет'
    },
    contacts: {
      required: 'Данный раздел должен содержать по меньшей мере один контактный телефон'
    },
    phone_numbers: {
      duplicate: 'Номера телефонов должны быть уникальные'
    },
    phone_number: {
      pattern: 'Введите телефон в формате {format}.',
    },
    profile: {
      required: 'Необходимо выбрать соответствующий профиль, к которому относится точка продажи.'
    },
    trademark: {
      required: 'Укажите название торговой марки, или укажите соответствующий тип колонки',
      single: 'Укажите торговую марку в мапинге, ИЛИ в отдельном поле.'
    },
    article: {
      required: 'Укажите колонку под названием "Артикул"'
    },
    delivery_time: {
      required: 'Укажите срок поставки'
    },
    order_time_until: {
      required: 'Укажите срок поставки.'
    },
    priceQuantity: {
      required: 'Необходимо указать цену или остатки'
    },
    sellPrice: {
      required: 'Необходимо указать цену продажи'
    },
    purchasePriceActions: {
      required: 'Необходимо указать цену продажи в схеме.'
    },
    quantity: {
      required: 'Укажите остатки на складе.'
    },
    analogsAndAnalogsTrademark: {
      required: 'Если присутствуют, необходимо указать каталожный номер и название производителя.'
    },
    relationType: {
      required: 'Укажите тип связи с артикулом аналога.'
    },
    weightUnit: {
      required: 'Укажите тип веса.'
    },
    float: {
      invalidFormat: 'Укажите значение в формате 0,00.'
    },
    verification_url_or_document: {
      required: 'Необходимо указать ссылку или файл.'
    },
    search_on_site_url: {
      pattern: 'Ссылка не валидная',
    },
    site: {
      pattern: 'Ссылка не валидная. Введите значение в формате test_site.com',
    },
    urls: {
      pattern: 'Ссылка не валидна.'
    },
    warehouse: {
      required: 'Укажите склад в колонке "Количество на складе".'
    },
    confirm_mapping: {
      message: 'Ошибка мапинга'
    },
    weightType: {
      required: 'Укажите тип веса'
    },
    sizesType: {
      required: 'Укажите тип габарита'
    },
    agreeWithPublicOffer: 'Для регистрации продавца автозапчастей необходимо согласиться с условиями договора публичной оферты.',
    vinError: 'Возможно в VIN- номере есть ошибка. Пожалуйста, внимательно проверьте Ваш VIN. Только при вводе ПРАВИЛЬНОГО VIN можно корректно подобрать запчасти, подходящие на автомобиль!'
  },

  mapLegend: {
    priceIn: 'Цены в {cur}',
    green: 'Зеленый',
    greenDesk: 'эсть в наличии',
    black: 'Чорный',
    blackDesk: 'будет в течении дня',
    grey: 'Серый',
    greyDesk: 'более одного дня',
    red: 'Красный',
    redDesk: 'лучшая пропозиция',
    official_trademark: 'Со складов дистрибьютора',
  },

  orderCycleHelp: {
    title: 'В какие статусы может переходить заказ',
    description: 'Отказ от заказа со стороны Клиента или продавца негативно учитывается при расчете рейтинга Клиента и Продавца соответственно. Поэтому для сохранения высокого рейтинга советы',
    forBuyer: 'к <strong>Покупателям</strong> - заказывайте только то, что гарантированно планируете покупать. Отказываясь от заказа Вы генерируете дополнительные расходы Продавцу. Пожалуйста, цените работу других.',
    forSeller: 'к <strong>Продавцам</strong> - старайтесь выполнить заказы клиентов в те сроки и за ту цену, о которой в начале договорились с клиентом. Покупатель рассчитывает на Вас, а также именно через эти договоренности выбрал.'
  },

  el: {
    day1: 'День',
    day2: 'Дня',
    day3: 'Дней',
    colorpicker: {
      confirm: 'OK',
      clear: 'Очистить'
    },
    datepicker: {
      output: 'Выходной',
      now: 'Сейчас',
      today: 'Сегодня',
      cancel: 'Отмена',
      clear: 'Очистить',
      confirm: 'OK',
      selectDate: 'Выбрать дату',
      selectTime: 'Выбрать время',
      startDate: 'Дата начала',
      startTime: 'Время начала',
      endDate: 'Дата окончания',
      endTime: 'Время окончания',
      prevYear: 'Предыдущий год',
      nextYear: 'Следующий год',
      prevMonth: 'Предыдущий месяц',
      nextMonth: 'Следующий месяц',
      year: 'год',
      month1: 'Январь',
      month2: 'Февраль',
      month3: 'Март',
      month4: 'Апрель',
      month5: 'Май',
      month6: 'Июнь',
      month7: 'Июль',
      month8: 'Август',
      month9: 'Сентябрь',
      month10: 'Октябрь',
      month11: 'Ноябрь',
      month12: 'Декабрь',
      week: 'Воскресенье',
      weeks: {
        sun: 'Вс',
        mon: 'Пн',
        tue: 'Вт',
        wed: 'Ср',
        thu: 'Чт',
        fri: 'Пт',
        sat: 'Сб',
        sunday: 'Вс',
        monday: 'Пн',
        tuesday: 'Вт',
        wednesday: 'Ср',
        thursday: 'Чт',
        friday: 'Пт',
        saturday: 'Сб',
      },
      months: {
        jan: 'Янв',
        feb: 'Фев',
        mar: 'Мар',
        apr: 'Апр',
        may: 'Май',
        jun: 'Июн',
        jul: 'Июл',
        aug: 'Авг',
        sep: 'Сен',
        oct: 'Окт',
        nov: 'Ноя',
        dec: 'Дек'
      }
    },
    select: {
      loading: 'Загрузка...',
      noMatch: 'Совпадений не найдено',
      noData: 'Нет данных',
      placeholder: 'Выбрать'
    },
    cascader: {
      noMatch: 'Совпадений не найдено',
      loading: 'Загрузка...',
      placeholder: 'Выбрать',
      noData: 'Нет данных'
    },
    pagination: {
      goto: 'Перейти',
      pagesize: 'На странице',
      total: 'Всего {total}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'Сообщение',
      confirm: 'OK',
      cancel: 'Отмена',
      error: 'Недопустимый ввод данных'
    },
    upload: {
      deleteTip: 'Нажмите [Удалить] для удаления',
      delete: 'Удалить',
      preview: 'Предварительный просмотр',
      continue: 'Продолжить'
    },
    table: {
      emptyText: 'Нет данных',
      confirmFilter: 'Подтвердить',
      resetFilter: 'Сбросить',
      clearFilter: 'Все',
      sumText: 'Сумма'
    },
    tree: {
      emptyText: 'Нет данных'
    },
    transfer: {
      noMatch: 'Совпадений не найдено',
      noData: 'Нет данных',
      titles: ['Список 1', 'Список 2'],
      filterPlaceholder: 'Введите ключевое слово',
      noCheckedFormat: '{total} пунктов',
      hasCheckedFormat: '{checked}/{total} выбрано'
    },
    image: {
      error: 'Ошибка'
    },
    pageHeader: {
      title: 'Назад'
    }
  },
};
