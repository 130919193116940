<template>
  <el-table
    v-loading="loading"
    :cell-style="{ textAlign: 'center' }"
    class="seller-trademark-table"
    :data="value"
    :header-cell-style="{ textAlign: 'center' }"
    @row-click="$emit('row-click', $event)"
  >
    <el-table-column
      :label="$t('sellerTrademarks.trademark_name')"
      prop="trademark_name"
    />

    <el-table-column
      :label="$t('sellerTrademarks.verification_url')"
      prop="verification_url"
    >
      <template #default="{ row }">
        <div v-if="row.verification_url" @click.stop>
          <el-link
            :href="row.verification_url"
            target="_blank"
            type="primary"
          >
            {{ row.verification_url }}
          </el-link>
        </div>
      </template>
    </el-table-column>

    <el-table-column
      :label="$t('sellerTrademarks.verificationConfirmed')"
      prop="status"
      width="120px"
    >
      <template #default="{ row }">
        {{ $t(`sellerTrademarks.statuses.${row.status}`) }}
      </template>
    </el-table-column>

    <el-table-column
      :label="$t('sellerTrademarks.activeStatus')"
      prop="status"
      width="120px"
    >
      <template #default="{ row }">
        <TrademarkActivator
          :entity="row"
          :readonly="readonly"
          @update="row.is_active = $event"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import TrademarkActivator from './TrademarkActivator';
import asyncTableMixin from '@/lib/core/mixins/asyncTableMixin';

export default {
  name: 'SellerTrademarkTable',

  mixins: [asyncTableMixin],

  components: {
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column'),
    /* eslint-enable max-len */
    TrademarkActivator
  }
};
</script>
