import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import env from './envConstants';
import { langs } from '@/lang';

const BCUP = env.BASE_CLIENT_URL_PREFIX;
const MDL = env.MARKETPLACE_DEFAULT_LANG;

const _langs = langs.filter(i => i !== MDL);

const withCarSeoPages = {};
const seoNamesByParams = {};

const pagesWithPagination = ['article'];

const isPageWithPagination = name => !!(
  name &&
    (name.includes('lineId') || pagesWithPagination.includes(name))
);

const homes = ['home', ..._langs.map(i => `home__${i}`)];

const updateCollections = (routes) => {
  routes.forEach((route) => {
    if (get(route, 'meta.withCar')) {
      withCarSeoPages[route.name] = true;
    }

    if (get(route, 'meta.seoName')) {
      const name = route.name;
      const params = name.replace(/\./g, ',');

      if (homes.includes(name)) {
        seoNamesByParams[''] = name;

        _langs.forEach((it) => {
          seoNamesByParams[`__${it}`] = name;
        });
      } else {
        seoNamesByParams[params] = name;
      }
    }
  });

  return { withCarSeoPages, seoNamesByParams };
};

const getRouteWithPage = (route) => {
  return {
    ...route,
    name: `${route.name}.page`,
    path: route.path.endsWith('/')
      ? `${route.path}page-:page(\\d+)/`
      : `${route.path}/page-:page(\\d+)/`
  };
};

const getChildrenWithLang = (children, nameSuffix) => {
  return children.map((child) => {
    const _child = { ...child };

    if (!isEmpty(_child.children)) {
      _child.children = getChildrenWithLang(_child.children, nameSuffix);
    }

    return {
      ..._child,
      name: `${_child.name}${nameSuffix}`
    };
  });
};

const getWithLang = (route, lang) => {
  const pathPrefix = `/${lang}`;
  const nameSuffix = `__${lang}`;

  const _route = {
    ...route,
    path: `${pathPrefix}${route.path.startsWith('/') ? route.path : `/${route.path}`}`,
    name: route.name ? `${route.name}${nameSuffix}` : route.name
  };

  if (!isEmpty(_route.children)) {
    _route.children = getChildrenWithLang(_route.children, nameSuffix);
  }

  return _route;
};

const addlangs = (route) => {
  return _langs.map((lang) => {
    return getWithLang(route, lang);
  });
};

const getParsedRouting = (routes) => {
  return routes.reduce((acc, route) => {
    if (route.path === '*') {
      acc.push(route);
      return acc;
    }

    if (BCUP) {
      route.path = route.path === '/'
        ? BCUP
        : `${BCUP}${route.path.replace('/', '')}`;
    }

    if (route.name && isPageWithPagination(route.name)) {
      const _withPage = getRouteWithPage(route);
      acc.push(_withPage);
      acc.push(...addlangs(_withPage));
    }

    acc.push(route);

    acc.push(...addlangs(route));

    return acc;
  }, []);
};

export {
  getParsedRouting,
  updateCollections,
  withCarSeoPages,
  seoNamesByParams
};
