var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-select" }, [
    _c(
      "div",
      {
        staticClass: "fl-wrap pg-1",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            _vm.isMenu = !_vm.isMenu
          },
        },
      },
      [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
        _c("img", {
          staticClass: "icon-img ml-05",
          attrs: { src: require("@/assets/icons/filter.svg") },
        }),
      ]
    ),
    _vm._v(" "),
    _vm.isMenu
      ? _c(
          "div",
          { staticClass: "menu back-shadow" },
          [
            _vm._t(
              "menu",
              function () {
                return [
                  _c(
                    "div",
                    _vm._l(
                      _vm.values.concat(_vm.options),
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: _vm.label + "_" + index,
                            class: [
                              "menu__item",
                              {
                                "is-active": _vm.getIsActive(item),
                                "is-clear": item.value === "clear",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.emitItem(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.label || item) +
                                "\n        "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              },
              null,
              { isMenu: _vm.isMenu }
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }