var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheTooltip",
    { attrs: { content: _vm.$t("buttons.copy") } },
    [
      _c(
        "el-row",
        {
          staticClass: "buffer-copy",
          attrs: { align: "middle" },
          nativeOn: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.copy.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { class: { "mr-05": _vm.$scopedSlots.default } },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _c("div", [
            _c("img", {
              staticClass: "icon-img click-op",
              attrs: { src: require("@/assets/icons/copy.svg") },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }