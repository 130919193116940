<template>
  <BlockSize :alt="alt" class="file-preview" :size="size">
    <component
      v-bind="$attrs"
      :is="fileComponent"
      v-if="type"
      :alt="alt"
      class="preview"
      :playing="playing"
      :preview-icon="previewIcon"
      :style="boxStyle"
      :value="file"
      :img-path="imgPath"
      :default-img-path="defaultImgPath"
      @click.native="$emit('clickedFile', file)"
    />
  </BlockSize>
</template>

<script>
import ImagePreview from './Files/ImagePreview';
import VideoPreview from './Files/VideoPreview';
import AudioPreview from './Files/AudioPreview';
import PdfPreview from './Files/PdfPreview';
import BlockSize from './BlockSize';

const STYLES = {
  default: {
    background: 'transparent'
  },

  thumbs: {
    background: '#eee'
  }
};

const types = ['image', 'audio', 'video', 'pdf'];
const defaultFile = {
  title: '',
  type: 'image',
  url: require('@/assets/icons/avatar-placeholder-2.svg')
};

export default {
  name: 'FilePreview',

  components: {
    ImagePreview,
    VideoPreview,
    AudioPreview,
    PdfPreview,
    BlockSize
  },

  props: {
    file: { type: Object, default: () => (defaultFile) },
    previewIcon: Boolean,
    playing: Boolean,
    alt: String,
    disabled: Boolean,
    size: String,
    imgPath: String,
    defaultImgPath: String,
    theme: { type: String, default: 'default' }
  },

  computed: {
    type () {
      return types.find(type => this.file.type.includes(type));
    },

    fileComponent () {
      return `${this.type}-preview`;
    },

    boxStyle () {
      return this.previewIcon ? STYLES[this.theme] : '';
    }
  }
};
</script>

<style scoped lang="scss">
.file-preview {
  .preview {
    display: flex;
    margin: auto;
    height: 100%;
    cursor: pointer;
  }

  .preview-other {
    img {
      margin: auto;
      opacity: .7;
    }

    &:hover img {
      opacity: 1;
    }
  }
}
</style>
