import { email, requiredString, phone_number } from './common';
import { i18n } from '@/plugins/i18n';

export default {
  email,
  login: [requiredString],
  phone_number,
  first_name: [
    { required: true, message: i18n.t('validation.first_name.required'), trigger: 'blur' },
    { max: 30, message: i18n.t('validation.simple.max', { num: 30 }), trigger: 'blur' }
  ],
  password: [
    { required: true, message: i18n.t('validation.password.required'), trigger: 'blur' },
    { min: 5, message: i18n.t('validation.simple.min', { num: 5 }), trigger: 'blur' }
  ],
  password2: [
    { validator: 'isPasswordsIdent', trigger: 'blur' },
    { required: true, message: i18n.t('validation.password.required'), trigger: 'blur' },
    { min: 5, message: i18n.t('validation.simple.min', { num: 5 }), trigger: 'blur' }
  ],
  activation_key: [
    { required: true, message: i18n.t('validation.activation_key.required'), trigger: 'blur' }
  ],
  recovery_key: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'blur' }
  ]
};
