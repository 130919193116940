import cloneDeep from 'lodash/cloneDeep';
import { env, getDefault, parsers } from '@/lib/core';
import itemsEditMixin from '@/lib/orders/mixins/itemsEditMixin';

export default {
  mixins: [itemsEditMixin],

  middleware: ['dependencies'],

  data () {
    return {
      MARKETPLACE_CURRENCY: this.$t(`currencies.${env.MARKETPLACE_CURRENCY}`),
      basket: getDefault('basket'),
      items: [],
      isLoading: true
    };
  },

  methods: {
    getTotalAmountNum: parsers.getWareTotalAmountNum,
    getTotalQuantity: parsers.getWareTotalQuantity,

    getParsed (item) {
      const _item = cloneDeep(item);
      _item._notFound = _item.error_code === 'not_found';
      return _item;
    },

    updateLocalItem (item) {
      const index = this.items.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        this.$set(this.items, index, item);
        return this.updateLocalBasket();
      }
    },

    async refetchBasket () {
      this.isLoading = true;

      try {
        const basket = await this.$store.dispatch('baskets/fetchBasket');
        this.basket = cloneDeep(basket);
        this.items = cloneDeep(basket.items);
        return this.basket;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
