<template>
  <el-card class="activate-form">
    <nuxt-link class="close-icon" :to="{ name: getRouteName('auth') }">
      <i class="el-icon-close" />
    </nuxt-link>

    <span class="custom-headline custom-headline--level2-bold">
      {{ $t('auth.activateAccount') }}
    </span>

    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      size="large"
      @submit.native.prevent="submit"
    >
      <el-form-item prop="email">
        <el-input
          v-model.trim="formData.email"
          autofocus
          :placeholder="$t('placeholders.email')"
        />
      </el-form-item>

      <el-form-item prop="activation_key">
        <el-input
          v-model.trim="formData.activation_key"
          :placeholder="$t('placeholders.activation_key')"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          class="submit-btn"
          :loading="isLoading"
          native-type="submit"
          type="primary"
        >
          {{ $t('buttons.activate') }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { getValidationFor } from '@/lib/core';

export default {
  name: 'ActivateForm',

  data ({ $route }) {
    return {
      isLoading: false,
      rules: getValidationFor(['email', 'activation_key']),
      formData: {
        email: $route.query.email || '',
        activation_key: $route.query.activation_key || ''
      }
    };
  },

  methods: {
    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          return false;
        }

        this.isLoading = true;

        try {
          await this.$store.dispatch('users/postActivateUser', this.formData);

          return this.$router.push({
            name: this.getRouteName('auth.completion')
          });
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/auth-form.scss";
</style>
