import apolloErrorHandler from './apolloErrorHandler';

const addMiddlewares = (origin, app) => {
  return async (data) => {
    try {
      return await origin({ ...data });
    } catch (e) {
      apolloErrorHandler(e, app);
      throw e;
    }
  };
};

export default ({ app }, inject) => {
  const apollo = app.apolloProvider.defaultClient;

  apollo.query = addMiddlewares(apollo.query, app);
  apollo.mutate = addMiddlewares(apollo.mutate, app);

  inject('apollo', apollo);
};
