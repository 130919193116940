var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "documents-page" }, [
    _vm.textData
      ? _c("div", [
          _c(
            "h1",
            { staticClass: "custom-headline custom-headline--level2-bold" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.textData.h1_tag ||
                      _vm.textData.title_tag ||
                      _vm.textData.title ||
                      ""
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("section", {
            staticClass: "default-style",
            domProps: { innerHTML: _vm._s(_vm.textData.content) },
          }),
        ])
      : _c("p", { staticClass: "pg-2 text-center" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("errors.emptyData")) + "\n  "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }