<template>
  <div class="s-requests-processing-list-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.garage_requests') }}
    </h1>

    <SRequestFilter class="mb-2" @submit="load" />

    <TheLoader v-if="isLoading" />

    <SRequestsProcessingTable
      v-else
      :value="tableData.results"
      @select="goTo"
    />

    <el-row class="mt-2" justify="center">
      <el-pagination
        background
        hide-on-single-page
        layout="prev, pager, next"
        :current-page="page"
        :page-size="PAGINATION_SIZE"
        :pager-count="5"
        :total="tableData.count"
        @current-change="setPage"
      />
    </el-row>
  </div>
</template>

<script>
import SRequestFilter from './components/SRequestFilter';
import SRequestsProcessingTable from './components/SRequestsProcessingTable';
import { env } from '@/lib/core';

export default {
  name: 'SRequestsProcessingListPage',

  middleware: ['businessUserLoaded'],

  components: {
    /* eslint-disable max-len */
    ElPagination: () => import(/* webpackChunkName: "ElPagination" */ '@/lib/core/components/ElPagination'),
    /* eslint-enable max-len */
    SRequestFilter,
    SRequestsProcessingTable
  },

  data () {
    return {
      PAGINATION_SIZE: env.SMALL_PAGINATION_SIZE,
      isLoading: true,
      tableData: {
        count: 0,
        results: []
      }
    };
  },

  computed: {
    page () {
      return Number(this.$route.query.page || '0');
    }
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      this.isLoading = true;

      try {
        const params = {
          brand_id__in: this.$route.query.brand_id__in,
          ordering: '-created',
          limit: this.PAGINATION_SIZE,
          offset: this.PAGINATION_SIZE * (this.page - 1)
        };

        this.tableData = await this.$store.dispatch(
          'selectionRequests/getCommonList',
          params
        );
      } catch (e) {
        console.error(e);

        this.tableData = {
          count: 0,
          results: []
        };
      } finally {
        this.isLoading = false;
      }
    },

    async setPage (page) {
      await this.$router
        .push({ query: { ...this.$route.query, page } })
        .catch(e => e);

      return this.load();
    },

    goTo ({ id }) {
      return this.$router.push({
        name: this.getRouteName('profile.business.s-requests.edit'),
        params: { id }
      });
    }
  }
};
</script>
