<template>
  <div class="platforms-send-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_platforms') }}
    </h1>

    <TheLoader v-if="isLoading" />

    <template v-else>
      <!--eslint-disable-next-line vue/no-v-html-->
      <span v-if="platform" v-html="description" />
      <BusinessRequisitesTab :platform="platform" />
    </template>
  </div>
</template>

<script>
import { env } from '@/lib/core';
import BusinessRequisitesTab from '@/lib/profiles/components/BusinessRequisitesTab';

export default {
  name: 'PlatformsSendPage',

  middleware: ['development'],

  components: { BusinessRequisitesTab },

  data () {
    return {
      isLoading: true,
      platform: null
    };
  },

  computed: {
    description () {
      return this.$t('platform.connect_description', {
        business_name: this.platform.name,
        address: this.$t('core.companyAddress'),
        mp: env.APP_NAME
      });
    }
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      try {
        this.platform = await this.$store.dispatch(
          'platforms/get',
          { id: this.$route.params.id }
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  },

  validate ({ params }) {
    return /^\d+$/.test(params.id);
  }
};
</script>
