import { env } from '@/lib/core';

const counter = 0;

export default (vm) => {
  const getPreview = () => vm.$store.dispatch('priceUploads/getPrice', {
    id: vm.price.id
  });

  let interval = null;

  const clear = () => {
    clearInterval(interval);
    interval = null;
  };

  interval = setInterval(async () => {
    try {
      const { data_preview, status, error_code } = await getPreview();

      vm.price.status = status;

      if (status === 'error' && error_code === '1.1.1' && counter < 3) {
        // error_code === '1.1.1' она значит что загрузка еще не зарегистрировалась
        // и можно пару раз с делеем сделать гет
        // и упасть уже потом если все еще этот код
        return null;
      } else if (['error', 'invalid'].includes(status)) {
        clear();
      } else if (status === 'done' && data_preview) {
        vm.price.data_preview = data_preview;
        clear();
      }
    } catch (e) {
      console.error(e);
    }
  }, env.REQUEST_UPDATE_INTERVAL);

  return Promise.resolve({ clear });
};
