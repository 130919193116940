var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      class: ["the-price", _vm.size],
      attrs: { align: "bottom", justify: "center" },
    },
    [
      _c("span", { staticClass: "mr-05 prefix" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("span", { staticClass: "price mr-05" }, [
        _vm._v(_vm._s(_vm.getPriceNum(_vm.value))),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "currency" }, [
        _vm.num
          ? _c("span", [
              _vm._v(
                _vm._s(_vm.currency || _vm.MC) +
                  "/" +
                  _vm._s(_vm.$t("core.num"))
              ),
            ])
          : _c("span", [_vm._v(_vm._s(_vm.currency || _vm.MC))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }