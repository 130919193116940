<template>
  <div class="account-page form-tab">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile') }}
    </h1>

    <el-row class="mb-1" justify="end">
      <CollapseToggle v-model="activeBlock" :blocks="blocks" />
    </el-row>

    <el-collapse v-if="user && formData" v-model="activeBlock">
      <el-collapse-item
        v-for="block in blocks"
        :key="block"
        :name="block"
      >
        <template slot="title">
          <CollapseIcon :is-valid="validBlocks[block]" />
          {{ $t(`${entityName}.${block}Block`) }}
        </template>

        <div class="pg-2">
          <component
            :is="`${upperFirst(block)}Block`"
            :entity="user"
            :submit="submit"
            :value="formData"
            @validate="validBlocks[block] = $event"
          />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import cloneDeep from 'lodash/cloneDeep';
import RegistrationBlock from './components/formBlocks/RegistrationBlock';
import AccountInfoBlock from './components/formBlocks/AccountInfoBlock';
import ContactsBlock from './components/formBlocks/ContactsBlock';
import { env, getDefault } from '@/lib/core';
import CollapseIcon from '@/lib/core/components/CollapseIcon';
import CollapseToggle from '@/lib/core/components/CollapseToggle';

const blocks = ['registration', 'accountInfo', 'contacts'];

export default {
  name: 'AccountPage',

  middleware: ['userAuth'],

  components: {
    CollapseToggle,
    CollapseIcon,
    RegistrationBlock,
    AccountInfoBlock,
    ContactsBlock
  },

  data () {
    return {
      blocks,
      entityName: 'account',
      activeBlock: blocks.slice(0),
      validBlocks: this.getValidBlocks(),
      formData: null
    };
  },

  computed: {
    user () {
      return this.$store.getters['users/user'];
    }
  },

  watch: {
    user: {
      deep: true,
      immediate: true,
      handler: 'setFormData'
    }
  },

  methods: {
    upperFirst,

    setFormData () {
      if (!this.user) {
        return this.$router.push({ name: this.getRouteName('home') });
      }

      this.formData = cloneDeep(this.user.profile);

      if (!this.formData.emails.length) {
        this.formData.emails.push(getDefault('email'));
      }
    },

    async submit (formData) {
      try {
        const params = { id: this.user.id, formData };
        const profile = await this.$store
          .dispatch('personalProfiles/patchPersonProfile', params);

        await this.$store.dispatch(
          'users/setLocalUser',
          { ...this.user, profile }
        );

        return profile;
      } catch (e) {
        console.error(e);
      }
    },

    getValidBlocks () {
      return blocks.reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    }
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>
