<template>
  <TheTooltip :content="$t('buttons.copy')">
    <el-row class="buffer-copy" align="middle" @click.native.stop="copy">
      <div :class="{ 'mr-05': $scopedSlots.default }">
        <slot />
      </div>

      <div>
        <img class="icon-img click-op" src="@/assets/icons/copy.svg">
      </div>
    </el-row>
  </TheTooltip>
</template>

<script>
export default {
  name: 'BufferCopy',

  props: {
    value: { type: String, required: true }
  },

  methods: {
    copy () {
      if (!window) {
        return null;
      }

      if (window.clipboardData && window.clipboardData.setData) {
        return window.clipboardData.setData('Text', this.value);
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');

        textarea.textContent = this.value;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();

        try {
          return document.execCommand('copy');
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.buffer-copy {
  cursor: pointer;

  .icon-img {
    width: 14px;
    height: 14px;
  }
}
</style>
