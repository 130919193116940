<template>
  <el-tooltip
    v-if="content"
    :content="content"
    effect="light"
    :open-delay="openDelay"
    :placement="placement"
    v-bind="$attrs"
  >
    <slot />
  </el-tooltip>

  <div v-else>
    <slot />
  </div>
</template>

<script>
import { env } from '@/lib/core';

export default {
  name: 'TheTooltip',

  props: {
    content: [String, Boolean],
    placement: { type: String, default: 'top' },
    openDelay: { type: Number, default: env.DEBOUNCE }
  }
};
</script>
