<template>
  <el-container class="layout">
    <el-header
      v-observe-visibility="toggleScrollBtn"
      height="unset"
      class="scroll-target"
    >
      <PageHeader />
      <PageHeaderM />
    </el-header>

    <el-container class="main-section">
      <el-main class="mb-4"><nuxt /></el-main>
    </el-container>

    <el-footer height="unset" class="cv">
      <PageFooter />
    </el-footer>

    <template v-if="$store.getters.isAppLoaded">
      <ProgressNotifier />
      <AppVersion />
      <BackToTop v-if="isShowScroll" />
      <TestPanel v-if="isDev" />
      <TheBasket />
    </template>
  </el-container>
</template>

<script>
import ElFooter from 'element-ui/lib/footer';
import layoutMixin from '@/lib/core/mixins/layoutMixin';
import PageHeader from '@/lib/core/components/PageHeader';
import PageHeaderM from '@/lib/core/components/PageHeaderM';

export default {
  name: 'Default',

  mixins: [layoutMixin],

  middleware: ['versionMiddleware'],

  components: {
    PageHeader,
    PageHeaderM,
    ElFooter,
    /* eslint-disable */
    PageFooter: () => import(/* webpackChunkName: "PageFooter" */ '@/lib/core/components/PageFooter'),
    ProgressNotifier: () => import(/* webpackChunkName: "ProgressNotifier" */ '@/lib/core/components/ProgressNotifier'),
    AppVersion: () => import(/* webpackChunkName: "AppVersion" */ '@/lib/core/components/AppVersion'),
    BackToTop: () => import(/* webpackChunkName: "BackToTop" */ '@/lib/core/components/BackToTop'),
    TestPanel: () => import(/* webpackChunkName: "TestPanel" */ '@/lib/core/components/TestPanel'),
    TheBasket: () => import(/* webpackChunkName: "TheBasket" */ '@/lib/orders/components/TheBasket')
    /* eslint-enable */
  },

  data: () => ({
    isShowScroll: false
  }),

  computed: {
    isMobile () {
      return this.maxmq('xs');
    }
  },

  methods: {
    toggleScrollBtn (isVisible) {
      this.isShowScroll = !this.maxmq('xss') && !isVisible;
    }
  }
};
</script>

<style scoped lang="scss">
.layout {
  .el-main {
    padding-top: 0;
  }

  .page-header {
    display: block;

    &-m {
      display: none;
    }
  }

  @media only screen and (max-width: $--xs) {
    .page-header {
      display: none;

      &-m {
        display: block;
      }
    }

    .el-header, .el-footer {
      padding: 0 !important;
    }

    .el-main {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}
</style>
