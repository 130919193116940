<template>
  <div class="completion-auth">
    <span class="custom-headline custom-headline--level2-bold">
      {{ $t('auth.thanksForRegistration') }}
    </span>

    <el-row class="buttons mt-4 mb-4">
      <div class="block">
        <nuxt-link :to="{ name: getRouteName('home') }">
          <el-button type="primary">{{ $t('auth.iAmOnlyBy') }}</el-button>
        </nuxt-link>

        <div class="description">
          <p>{{ $t('auth.youCanByNow') }}</p>
        </div>
      </div>

      <div class="block">
        <nuxt-link :to="{ name: getRouteName('profile.business.create') }">
          <el-button type="danger">{{ $t('auth.iAmSeller') }}</el-button>
        </nuxt-link>

        <div class="description">
          <p>{{ $t('auth.anyShopCanBy') }}</p>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'CompletionAuth'
};
</script>

<style scoped lang="scss">
.completion-auth {
  .buttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    .yellow {
      font-weight: bold;
      color: $yellow-500;
    }

    .block {
      width: 250px;
      margin-bottom: 20px;
    }

    .el-button {
      width: 100%;
      min-height: 100px;
      margin-bottom: 20px;
      white-space: normal;
    }

    .description {
      max-width: 90%;
      margin: 0 auto;
    }

    @media all and (max-width: $--xs) {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
