import env from '../lib/core/services/envConstants';

export const langs = env.MARKETPLACE_LANGS || ['uk', 'ru', 'en'];

const langsCodes = {
  uk: 'uk-UA',
  ru: 'ru-RU',
  en: 'en-EN'
};

export const collection = langs.reduce((acc, code) => {
  acc[langsCodes[code]] = require(`./translations/${langsCodes[code]}`).default;
  return acc;
}, {});

const translations = {
  messages: {},
  descriptions: {},
  locales: []
};

export default Object
  .entries(collection)
  .reduce((acc, [code, lang]) => {
    const [langCode, countryCode] = code.split('-');

    acc.locales.push({
      langCode,
      countryCode,
      name: lang.langName,
      localeCode: code
    });

    Object.assign(acc.messages, { [langCode]: lang });

    return acc;
  }, translations);
