export default {
  trim: value => value && typeof value === 'string'
    ? value.replace(/\s/g, '')
    : value,

  fixPhone: value => value.replace(/[^+\d]/g, ''),
  vinReplacer: value => (value || '')
    .replace(/[Oo]/ig, '0')
    .toUpperCase()
};
