import sortBy from 'lodash/sortBy';

export const actions = {
  leaveBusinessUser (_, { id }) {
    return this.$axios.$delete(`/business-users/${id}`);
  },

  patchBusinessUser (_, { id, formData }) {
    return this.$axios.$patch(`/business-users/${id}/`, formData);
  }
};

export const getters = {
  profileId: (state, getters, rootState, { userCode }) => {
    return userCode && userCode[0] === 'b'
      ? Number.parseInt(userCode.slice(1))
      : null;
  },

  businessUsers: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['users/user'];

    return user && user.business_acl
      ? sortBy(user.business_acl, item => item.business.name.toLowerCase())
      : [];
  },

  currentBusinessUser: (state, getters) => {
    const profileId = getters.profileId;
    const business_acl = getters.businessUsers;

    if (profileId && business_acl) {
      return business_acl.find(({ id }) => id === profileId);
    }
  },

  isAdmin: (state, getters) => {
    return getters.currentBusinessUser && getters.currentBusinessUser.is_admin;
  }
};
