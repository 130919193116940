<template>
  <div class="anchors-nav">
    <div v-observe-visibility="togglePosition" />

    <nav ref="nav" :class="['nav scroll', { fixed: isFixed }]">
      <div
        v-for="id in value"
        :key="id"
        class="mr-scroll-btn nav__item"
        :data-scroll="id"
      >
        {{ $t(`${entity}.${id}`) }}
      </div>
    </nav>
  </div>
</template>

<script>
import animateScrollTo from 'animated-scroll-to';
import { debounce } from 'lodash/function';
import { env } from '@/lib/core';

const OFFSET = 32;

export default {
  name: 'AnchorsNav',

  props: {
    entity: { type: String, required: true },
    value: { type: Array, required: true }
  },

  data () {
    return { isFixed: false };
  },

  mounted () {
    this.initNavWatcher = debounce(this.initNavWatcher, env.DEBOUNCE);
    this.$watch('value', this.initNavWatcher, { deep: true, immediate: true });
  },

  methods: {
    togglePosition (isVisible) {
      this.isFixed = !isVisible;
    },

    initNavWatcher () {
      const headerHeight = this.$refs.nav.clientHeight;
      const links = Array.from(document.getElementsByClassName('mr-scroll-btn'));
      const blocks = [];
      const clear = () => {
        for (const link of links) {
          link.classList.remove('is-active');
        }
      };

      for (const link of links) {
        const el = link;
        const scrollTo = el.dataset?.scroll;

        if (!scrollTo) {
          return null;
        }

        const block = document.getElementById(scrollTo);

        if (block) {
          blocks.push({ link: el, name: scrollTo });

          el.addEventListener('click', () => {
            clear();
            el.classList.add('is-active');
            const params = { verticalOffset: -OFFSET - headerHeight };
            animateScrollTo(document.getElementById(scrollTo), params);
          });
        }
      }

      const scroll = () => {
        for (const { link, name } of blocks) {
          const el = document.getElementById(name);
          const { top, height } = el.getBoundingClientRect();

          if (top - OFFSET - headerHeight < 0 && top + height - OFFSET - headerHeight > 0) {
            link.classList.add('is-active');
          } else {
            link.classList.remove('is-active');
          }
        }
      };

      scroll();

      document.addEventListener('custom-scroll', scroll);
    }
  }
};
</script>

<style scoped lang="scss">
.anchors-nav {
  min-height: 32px;

  .nav {
    display: flex;
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 999;
    font-size: 13px;
    border-bottom: 1px solid $grey-300;
    overflow: auto;
    width: 100%;

    &.fixed {
      width: calc(100% - 40px);
      max-width: 1560px;
      position: fixed;
    }

    &__item {
      margin-right: 32px;
      cursor: pointer;
      white-space: nowrap;
      transition-duration: .3s;
      padding: 10px 0;

      .is-active {
        position: relative;
        color: $--color-primary;
      }
    }
  }

  @media all and (max-width: $--xs) {
    .nav__item {
      padding: 4px 0;
    }
  }
}
</style>
