var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wares-table" },
    [
      _vm.localError && _vm.load
        ? _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.reload },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("buttons.searchAgain")) +
                      "\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "el-table",
            {
              attrs: {
                data: _vm.value,
                "empty-text": _vm.emptyTitle,
                "header-cell-class-name": "header-cell",
                "header-cell-style": { textAlign: "center" },
                "row-key": _vm.rowKey,
                stripe: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("search.article"),
                  prop: "display_title",
                  width: "200px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "article-row" }, [
                          _c("div", { staticClass: "mr-1 bold" }, [
                            _vm._v(_vm._s(row.display_trademark)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "article-title" },
                            [
                              _c(
                                "el-link",
                                {
                                  class: [
                                    "link",
                                    "mr-1",
                                    { selected: _vm.article === row.article },
                                  ],
                                  attrs: { href: row._articleHref },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.goTo(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row.display_article) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("BufferCopy", {
                                attrs: { value: row.display_article },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        row.other_selling_terms.includes("former_use")
                          ? _c("div", { staticClass: "color-red" }, [
                              _vm._v(
                                "\n          (" +
                                  _vm._s(_vm.$t("search.formerUse")) +
                                  ")\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: _vm.$t("search.info"), width: "100px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.ware && row.ware.id
                          ? _c("WareInfoIcons", {
                              attrs: { position: "start", ware: row.ware },
                              on: {
                                show: function ($event) {
                                  return _vm.$emit("info:show", row)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("search.name"),
                  "min-width": "160px",
                  prop: "display_title",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: row.display_title,
                              effect: "light",
                              enterable: false,
                              placement: "top",
                            },
                          },
                          [
                            _c("div", { staticClass: "text-ellipsis" }, [
                              _vm._v(_vm._s(row.display_title)),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "delivery_time", width: "120px" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "sortable-header-col",
                            on: {
                              click: function ($event) {
                                _vm.ordering &&
                                  _vm.$emit("toggle-ordering", [
                                    "delivery_time",
                                    "-rank",
                                  ])
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("search.term")) +
                                "\n          "
                            ),
                            _vm.ordering && _vm.ordering[0] === "delivery_time"
                              ? _c("i", {
                                  staticClass: "sort-icon el-icon-caret-top",
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          { staticClass: "sortable-header-col" },
                          [
                            _c("DeliveryTimeString", {
                              attrs: {
                                value: row.delivery_time,
                                options: { ware: row },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", width: "100px" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "sortable-header-col",
                            on: {
                              click: function ($event) {
                                _vm.ordering &&
                                  _vm.$emit("toggle-ordering", [
                                    "price",
                                    "-rank",
                                  ])
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("search.price")) +
                                "\n          "
                            ),
                            _vm.ordering && _vm.ordering[0] === "price"
                              ? _c("i", {
                                  staticClass: "sort-icon el-icon-caret-top",
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-row",
                          { staticClass: "price", attrs: { justify: "end" } },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "DebugInfo",
                                  { attrs: { mode: "ware", value: row } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "price__value" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              row._price ||
                                                _vm.getPriceNum(row.price)
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                row._isShowRetail
                                  ? _c(
                                      "TheTooltip",
                                      {
                                        attrs: {
                                          content: _vm.$t("search.retailPrice"),
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "retail small" },
                                          [_vm._v("p")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row._isVatPayer
                                  ? _c(
                                      "el-row",
                                      {
                                        staticClass: "small",
                                        attrs: { justify: "end" },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("search.withVat")) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: { width: "200px" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._t(
                              "6",
                              function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "sale-point-col" },
                                    [
                                      row.point_of_sale
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: row.point_of_sale.name,
                                                effect: "light",
                                                enterable: false,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-link",
                                                {
                                                  staticClass:
                                                    "analytics-sale-point-info-link",
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$emit(
                                                        "sale-point:info",
                                                        {
                                                          id: row.point_of_sale
                                                            .id,
                                                          ware: row,
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        row.point_of_sale.name
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.is_official_trademark
                                        ? _c("div", { staticClass: "op-4" }, [
                                            _vm._v(
                                              "\n              (" +
                                                _vm._s(
                                                  _vm.$t("search.formDist")
                                                ) +
                                                ")\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.point_of_sale
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "city-col text-ellipsis text-center color-default",
                                            },
                                            [
                                              _c("small", [
                                                _vm._v(_vm._s(row._city)),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              null,
                              row
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _vm._t("6h", function () {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("search.point_of_sale"))),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                2
              ),
              _vm._v(" "),
              !_vm.readonly
                ? _c("el-table-column", {
                    attrs: { width: "120px", align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._t(
                                "options",
                                function () {
                                  return [
                                    _c(
                                      "TheTooltip",
                                      {
                                        attrs: {
                                          content: row._canBy
                                            ? _vm.$t("search.addToBasket")
                                            : _vm.$t("search.noSell"),
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            class: [
                                              "basket",
                                              {
                                                "in-basket": _vm.inBasket(row),
                                              },
                                            ],
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                              disabled:
                                                _vm.isDisabledBasket(row),
                                              icon: _vm.inBasket(row)
                                                ? "el-icon-shopping-cart-full"
                                                : "el-icon-shopping-cart-2",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.$emit(
                                                  "basket-click",
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(_vm.$t("buttons.buy")) +
                                                "\n            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                null,
                                row
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }