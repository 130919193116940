var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "platforms-table" },
    [
      _vm.value
        ? _c(
            "el-table",
            {
              attrs: {
                data: _vm.value,
                "header-cell-style": { textAlign: "center" },
              },
              on: { "row-click": _vm.navigate },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("platform.status"),
                  prop: "is_active",
                  width: "100px",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("PlatformActivator", {
                            attrs: {
                              id: row.id,
                              market_id: row.marketplace_detail.id,
                            },
                            model: {
                              value: row.is_active,
                              callback: function ($$v) {
                                _vm.$set(row, "is_active", $$v)
                              },
                              expression: "row.is_active",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  701847862
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "250px",
                  label: _vm.$t("platform.name"),
                  prop: "marketplace_detail.name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: _vm.$t("platform.contract"),
                  prop: "status",
                  width: "150px",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "platform.statuses." + (row.status || "empty")
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  786301104
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "150px",
                  label: _vm.$t("platform.subscriptionFee"),
                  prop: "marketplace_detail.name",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.getPriceWithCurrency(row.price)) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1676581869
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("el-row", { staticClass: "mt-1", attrs: { justify: "end" } }, [
        _c("div", { staticClass: "total-sum" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("platform.subscriptionFee")) +
              "*: " +
              _vm._s(_vm.totalSum) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-ghost mt-1" }, [
        _c("p", [
          _vm._v(_vm._s(_vm.$t("platform.subscriptionFeeDescription"))),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }