var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.basket
    ? _c("div", { class: ["basket-icon", { "only-icon": _vm.onlyIcon }] }, [
        _c(
          "div",
          {
            class: ["wrapper", { active: _vm.isActive }],
            on: {
              click: function ($event) {
                return _vm.goToBasket($event)
              },
            },
          },
          [
            _c("div", { staticClass: "icon-wrapper" }, [
              _c("img", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                staticClass: "icon",
                attrs: {
                  width: "50",
                  height: "50",
                  src: _vm.icon,
                  alt: "basket",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive,
                      expression: "isActive",
                    },
                  ],
                  staticClass: "quantity",
                },
                [_vm._v(_vm._s(_vm.quantity))]
              ),
            ]),
            _vm._v(" "),
            !_vm.onlyIcon
              ? _c("div", { staticClass: "price" }, [_vm._v(_vm._s(_vm.price))])
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }