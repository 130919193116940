var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule-info" },
    [
      _c("el-row", { staticClass: "mb-1 bold", attrs: { justify: "center" } }, [
        _vm._v("\n    " + _vm._s(_vm.$t("salePoint.scheduleBlock")) + ":\n  "),
      ]),
      _vm._v(" "),
      _vm.isEmpty
        ? _c("div", { staticClass: "pg-2 color-default text-center" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("errors.emptyData")) + "\n  "),
          ])
        : _vm._l(_vm.formData, function (times, day) {
            return _c("el-row", { key: day }, [
              _c("div", { class: ["day", { "text-ghost": !times }] }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("el.datepicker.weeks." + day)) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              times
                ? _c("div", { staticClass: "time" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("cutTime")(times.from)) +
                        "\n        -\n        " +
                        _vm._s(_vm._f("cutTime")(times.to)) +
                        "\n      "
                    ),
                  ])
                : _c("div", { staticClass: "text-ghost text-center time" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("el.datepicker.output")) +
                        "\n      "
                    ),
                  ]),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }