<template>
  <div class="entrepreneur-info">
    <el-form-item
      :label="$t('requisites.info.itn')"
      prop="itn"
    >
      <el-input
        class="max-w-182p"
        :value="value.itn"
        @input="emit('itn', $event)"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.lastName')"
      prop="last_name"
    >
      <el-input
        class="max-w-420p"
        :value="value.last_name"
        @input="setName({ last_name: $event })"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.firstName')"
      prop="first_name"
    >
      <el-input
        class="max-w-420p"
        :value="value.first_name"
        @input="setName({ first_name: $event })"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.middleName')"
      prop="middle_name"
    >
      <el-input
        class="max-w-420p"
        :value="value.middle_name"
        @input="setName({ middle_name: $event })"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.pdv')"
      label-width="300px" prop="is_vat_payer"
    >
      <el-radio-group
        :value="value.is_vat_payer"
        @input="emit('is_vat_payer', $event)"
      >
        <el-row class="mt-1">
          <el-radio :label="true">{{ $t('core.yes') }}</el-radio>
          <el-radio :label="false">{{ $t('core.no') }}</el-radio>
        </el-row>
      </el-radio-group>
    </el-form-item>

    <el-form-item
      v-if="value.is_vat_payer"
      :label="$t('requisites.info.certificate')"
      label-width="300px"
      prop="itn_of_vat_payer"
    >
      <el-input
        class="max-w-182p"
        :value="value.itn_of_vat_payer"
        @input="emit('itn_of_vat_payer', $event)"
      />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'EntrepreneurInfo',

  props: {
    value: { type: Object, required: true }
  },

  methods: {
    setName ({ last_name, first_name, middle_name }) {
      const _last_name = last_name || this.value.last_name || '';
      const _first_name = first_name || this.value.first_name || '';
      const _middle_name = middle_name || this.value.middle_name || '';

      this.emit('last_name', _last_name);
      this.emit('first_name', _first_name);
      this.emit('middle_name', _middle_name);

      const short_name = `${_last_name} ${_first_name[0] || ''}.${_middle_name[0] || ''}., ФОП`;
      const official_name = `${_last_name} ${_first_name} ${_middle_name}`;

      this.emit('short_name', short_name);
      this.emit('official_name', official_name);
    },

    emit (name, value) {
      this.$emit(`update:${name}`, value);
    }
  }
};
</script>
