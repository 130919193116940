<template>
  <div class="search-articles-table">
    <div v-if="isShowSelectedArticle" class="mb-4">
      <div class="mb-1 pg-0-1 table-title">
        <h4 class="custom-headline custom-headline--level4 pg-0">
          {{ $t('search.invitedArticle') }}
        </h4>
      </div>

      <component
        :is="tableName"
        :article="$route.params.article"
        :disabled-ids="basketOfferIds"
        :empty-text="emptyTexts.selectedArticle"
        :ordering="ordering"
        :prop-value="selectedArticlesRes.results"
        :value="selectedArticlesRes.results"
        @basket-click="$emit('basket-click', $event)"
        @info:show="$emit('info:show', $event)"
        @sale-point:info="$emit('sale-point:info', $event)"
        @toggle-ordering="$emit('toggle-ordering', $event)"
      />

      <el-row v-if="selectedArticlesRes.count > 4" class="mt-1" justify="end">
        <el-link type="primary" @click="loadOnlySelectedArticle">
          {{ $t('search.showAllSellers') }} >>
        </el-link>
      </el-row>
    </div>

    <div v-if="isShowSelectedArticle" class="mb-1 pg-0-1 table-title">
      <h4 class="custom-headline custom-headline--level4 pg-0">
        {{ $t(`search.${!$route.query.osa ? 'invitedArticleAndAnalogs' : 'invitedArticle'}`) }}
      </h4>
    </div>

    <component
      :is="tableName"
      :article="$route.params.article"
      :disabled-ids="basketOfferIds"
      :empty-text="emptyTexts.analogs"
      :ordering="ordering"
      :prop-value="analogsRes.results"
      :value="analogsRes.results"
      @basket-click="$emit('basket-click', $event)"
      @info:show="$emit('info:show', $event)"
      @sale-point:info="$emit('sale-point:info', $event)"
      @toggle-ordering="$emit('toggle-ordering', $event)"
    />

    <el-row class="mt-2" justify="center">
      <el-pagination
        background
        :current-page.sync="page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="PAGINATION_SIZE"
        :pager-count="5"
        :total="analogsRes.count"
      />
    </el-row>
  </div>
</template>

<script>
import keyBy from 'lodash/keyBy';
import { env, eventBus } from '@/lib/core';

export default {
  name: 'SearchArticlesTable',

  components: {
    /* eslint-disable max-len */
    ElPagination: () => import(/* webpackChunkName: "ElPagination" */ '@/lib/core/components/ElPagination'),
    WaresTable: () => import(/* webpackChunkName: "WaresTableNew" */ './WaresTableNew'),
    WaresTableM: () => import(/* webpackChunkName: "WaresTableM" */ './WaresTableM')
    /* eslint-enable max-len */
  },

  props: {
    ordering: Array,
    selectedArticlesRes: Object,
    analogsRes: { type: Object, required: true }
  },

  data () {
    return {
      PAGINATION_SIZE: env.PAGINATION_SIZE
    };
  },

  computed: {
    tableName () {
      return `WaresTable${this.maxmq('md') ? 'M' : ''}`;
    },

    basketOfferIds () {
      const basket = this.$store.getters['baskets/basket'] || { items: [] };
      return basket.items.map(({ offer_id }) => offer_id);
    },

    emptyTexts () {
      const article = this.$route.params.article || this.$route.query.q;

      return {
        selectedArticle: this.$t('search.noSelectedArticles', { article }),
        analogs: undefined
      };
    },

    page: {
      get () {
        return Number.parseInt(this.$route.params.page || this.$route.query.page) || 1;
      },
      set (page) {
        if (page !== this.page) {
          this.$emit('update:page', page);
        }
      }
    },

    trademarkById () {
      return keyBy(this.analogsRes.trademarks, 'id');
    },

    isShowSelectedArticle () {
      return !!this.selectedArticlesRes?.results?.length;
    },

    priceTags () {
      const tags = this.$store.getters['dependencies/priceTags'];

      return tags.reduce((acc, tag) => {
        acc[tag.label] = tag.id;
        return acc;
      }, {});
    }
  },

  methods: {
    loadOnlySelectedArticle () {
      eventBus.$emit('filter:part:update', { onlySelectArticle: true });
    }
  }
};
</script>
