import isEmpty from 'lodash/isEmpty';

const seoPages = {
  home: {
    catalog: true,
    topProductLines: true,
    topCarsBrands: true,
    topCarsModels: true,
    topTrademarks: true,
    seoInfo: true
  },
  lineId: {
    catalog: true,
    carsBrandsModels: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  },
  'lineId.regionId': {
    catalog: true,
    carsBrandsModels: true,
    topTrademarks: true,
    topRegions: true,
    seoInfo: true,
    questionAnswer: true
  },
  'lineId.trademarkId': {
    catalog: true,
    carsBrandsModels: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  },
  'lineId.regionId.trademarkId': {
    catalog: true,
    carsBrandsModels: true,
    topTrademarks: true,
    topRegions: true,
    seoInfo: true,
    questionAnswer: true
  },
  carBrand: {
    catalog: true,
    topProductLines: true,
    topCarsModels: true,
    seoInfo: true,
    questionAnswer: true
  },
  'carBrand.lineId': {
    catalog: true,
    topCarsModels: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  },
  'carBrand.lineId.trademarkId': {
    catalog: true,
    topCarsModels: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  },
  'carBrand.carModel': {
    catalog: true,
    topProductLines: true,
    topCarsGenerations: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  },
  'carBrand.carModel.lineId': {
    catalog: true,
    topCarsGenerations: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  },
  'carBrand.carModel.lineId.trademarkId': {
    catalog: true,
    topCarsGenerations: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  },
  'carBrand.carGeneration.carModel': {
    catalog: true,
    topProductLines: true,
    topCarsModifications: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  },
  'carBrand.carGeneration.carModel.lineId': {
    catalog: true,
    topCarsModifications: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  },
  'carBrand.carGeneration.carModel.lineId.trademarkId': {
    catalog: true,
    topCarsModifications: true,
    topTrademarks: true,
    seoInfo: true,
    questionAnswer: true
  }
};

export default function getBlocksOption (vm) {
  const pageName = vm.$route?.name
    .split('__')[0]
    .replace('.page', '');
  const options = seoPages[pageName];

  if (!options) {
    console.error('THERE IS NO BLOCK OPTIONS!!!');
    return {
      catalogTree: true,
      searchCatalogTile: true
    };
  }

  const isHomePage = /home/.test(pageName);

  const catalogTree = !!(options.catalog && !isEmpty(vm.catalog));
  const searchCatalogTile = !!(
    options.catalog &&
    !catalogTree &&
    vm.productLine
  );

  if (vm.$route.query.cm) {
    return {
      catalogTree,
      searchCatalogTile
    };
  }

  const topProductLines = !!(
    options.topProductLines &&
    !isEmpty(vm.topProductLines)
  );

  const carsBrandsModels = !!(
    options.carsBrandsModels &&
    !isEmpty(vm.carsBrands) &&
    searchCatalogTile
  );

  const topCarsBrands = !!(
    options.topCarsBrands &&
    !isEmpty(vm.topCarsBrands)
  );

  const topCarsModels = !!(
    options.topCarsModels &&
    !isEmpty(vm.topCarsModels)
  );

  const topCarsGenerations = !!(
    options.topCarsGenerations &&
    !isEmpty(vm.topCarsGenerations) &&
    // На промежуточных Модель+ТЛ не должно быть поколений
    (pageName !== 'carBrand.carModel.lineId' || searchCatalogTile)
  );

  const topCarsModifications = !!(
    options.topCarsModifications &&
    !isEmpty(vm.topCarsModifications)
  );

  const topTrademarks = !!(
    options.topTrademarks &&
    !isEmpty(vm.topTrademarks) &&
    (isHomePage || searchCatalogTile || (vm.productLine && vm.trademark))
  );

  const topRegions = !!options.topRegions;

  // С промежуточных ТЛ, pages with pagination, нужно убрать ФАК и цены
  const isFirstPage = !vm.$route.params.page;
  const topProductsPrices = !!(isFirstPage && options.seoInfo && searchCatalogTile);
  const questionAnswer = !!(isFirstPage && options.questionAnswer && searchCatalogTile);
  const seoInfo = isFirstPage && (topProductsPrices || vm.meta?.text);

  return {
    catalogTree,
    searchCatalogTile,
    topProductLines,
    carsBrandsModels,
    topCarsBrands,
    topCarsModels,
    topCarsGenerations,
    topCarsModifications,
    topTrademarks,
    topRegions,
    seoInfo,
    topProductsPrices,
    questionAnswer,
    hideSeoText: !isFirstPage
  };
};
