import { requiredString } from './common.js';
import { INT_FLOAT_REG } from './patterns';
import { i18n } from '@/plugins/i18n';

const _requiredString = { ...requiredString, trigger: 'change' };

export default {
  item: [_requiredString],
  article: [_requiredString],
  trademark: [_requiredString],
  price: [
    { ..._requiredString },
    {
      pattern: INT_FLOAT_REG,
      message: i18n.t('validation.float.invalidFormat'),
      trigger: 'blur'
    }
  ],
  delivery_time: [
    { validator: 'selectionResponseDeliveryTime' }
  ]
};
