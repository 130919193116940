<template>
  <div class="s-request-processing-items-table">
    <div
      v-for="reqItem in value"
      :key="reqItem.id"
      class="item-block mb-2"
    >
      <el-row justify="space-between" class="req__item">
        <div>
          <div class="mb-1 bold">{{ reqItem.raw_item }}</div>
          <div>{{ reqItem.comment }}</div>
        </div>

        <div>
          <el-button type="primary" @click="$emit('add-item', reqItem)">
            {{ $t('buttons.addWare') }}
          </el-button>
        </div>
      </el-row>

      <div class="responses">
        <table class="mr-table">
          <tbody>
            <tr
              v-for="(row, index) in reqItem.selection_responses"
              :key="`${index}${row.id}${row.article}${row.trademark}${row.price}`"
              class="mr-table-body-row res__item"
            >
              <el-row>
                <td class="mr-table-body-col">
                  <BlockSize :src="row.ware_image_url" />
                </td>

                <td class="mr-table-body-col">
                  <el-row class="mb-1">
                    <div class="mr-2 bold">{{ row.trademark }}</div>
                    <div>{{ row.article || '********' }}</div>
                  </el-row>

                  <div>
                    <small>{{ row.comment_public }}</small>
                  </div>
                </td>

                <td class="mr-table-body-col">
                  {{ row.name }}
                </td>

                <td class="mr-table-body-col">
                  <DeliveryTimeString
                    :value="row.delivery_time"
                    :options="{ suffix: true }"
                  />
                </td>

                <td class="mr-table-body-col">
                  {{ getPriceWithCurrency(row.price) }}
                </td>

                <td class="mr-table-body-col">
                  <el-row>
                    <el-button
                      circle
                      class="mr-1"
                      icon="el-icon-edit"
                      @click="$emit('edit', { item: reqItem, row })"
                    />

                    <el-button
                      type="danger"
                      circle
                      icon="el-icon-delete"
                      @click="$emit('remove', { item: reqItem, row })"
                    />
                  </el-row>

                </td>
              </el-row>

              <el-row v-if="isCommentPrivate && row.comment_private" class="comment-private">
                {{ row.comment_private }}
              </el-row>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { BlockSize, parsers } from '@/lib/core';
import DeliveryTimeString from '@/lib/orders/components/DeliveryTimeString';

export default {
  name: 'SRequestProcessingItemsTable',

  components: {
    BlockSize,
    DeliveryTimeString
  },

  props: {
    isCommentPrivate: Boolean,
    value: { type: Array, require: true }
  },

  methods: {
    getPriceWithCurrency: parsers.getPriceWithCurrency
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/components/ssr-wares-table.scss';

.s-request-processing-items-table {
  .item-block {
    background-color: $grey-100;

    .req__item {
      padding: 20px;
    }

    .responses {
      margin: 0 auto;
      padding-bottom: 10px;
      width: 98%;
      overflow: auto;
    }

    .res__item {
      display: block;
      background-color: $white;
      border-bottom: 1px solid $grey-300;

      .el-row {
        min-width: 100%;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .comment-private {
      padding: 10px 40px 10px 40px;
      background-color: $yellow-300;
    }
  }

  .mr-table .mr-table-body-col {
    &:nth-child(1) {
      width: 100px;
    }
    &:nth-child(2) {
      width: 250px;
    }
    &:nth-child(3) {
      min-width: 250px;
    }
    &:nth-child(4) {
      width: 110px;
      text-align: center;
    }
    &:nth-child(5) {
      width: 120px;
      text-align: center;
    }
    &:nth-child(6) {
      width: 120px;
      text-align: right;
      display: flex;

      .el-button {
        margin: auto;
      }
    }
  }
}
</style>
