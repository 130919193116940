<template>
  <div class="search-catalog-tile">
    <el-container>
      <el-header v-if="isMobile" class="mb-2 pg-0" height="fit-content">
        <el-row align="middle" class="filters-btns">
          <div class="fl-row mr-1" @click="isFilter = !isFilter">
            {{ $t("search.filter") }}
            <img class="icon-img ml-05" src="@/assets/icons/filter.svg">
          </div>

          <CustomSelect :label="$t('search.order')">
            <template #menu="{ isMenu }">
              <div v-if="isMenu">
                <div
                  :class="[
                    'menu__item',
                    { 'is-active': ordering[0] === 'delivery_time' },
                  ]"
                  @click="toggleOrdering(['delivery_time', '-rank'])"
                >
                  {{ $t("search.term") }}
                </div>

                <div
                  :class="[
                    'menu__item',
                    { 'is-active': ordering[0] === 'price' },
                  ]"
                  @click="toggleOrdering(['price', '-rank'])"
                >
                  {{ $t("search.price") }}
                </div>
                <div
                  v-if="ordering.length"
                  class="menu__item is-clear"
                  @click="toggleOrdering(ordering)"
                >
                  {{ $t("buttons.clear") }}
                </div>
              </div>
            </template>
          </CustomSelect>
        </el-row>
      </el-header>

      <TheLoader v-if="isArticlesLoading" />

      <el-container v-else class="relative">
        <el-aside
          v-if="
            $store.getters.isDomReadyState && !isMobile && !isArticlesLoading
          "
          class="filter-block"
          width="260px"
        >
          <CatalogSearchFilter
            :is-visible="isFilter"
            :params="result"
            :trademark="trademark"
          />
        </el-aside>

        <div class="el-main pg-0">
          <keep-alive>
            <WaresTile
              ref="table"
              :is-show-ordering="!isMobile"
              :analogs-res="result"
              @basket-click="addWareToBasket"
              @sale-point:info="showSalePointInfo"
            />
          </keep-alive>
        </div>
      </el-container>
    </el-container>

    <SearchFilterM
      v-if="isMobile"
      :visible="isFilter"
      @close="isFilter = false"
    >
      <CatalogSearchFilter
        is-manual
        :is-visible="isFilter"
        :params="result"
        :trademark="trademark"
        @submitted="isFilter = false"
      />
    </SearchFilterM>

    <TheDialog v-model="isSalePointInfo" :title="$t('orders.salePointInfo')">
      <SalePointInfo v-if="isSalePointInfo" :id="isSalePointInfo" />
    </TheDialog>
  </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import isEqual from 'lodash/isEqual';
import CustomSelect from '../../core/components/CustomSelect';
import CatalogSearchFilter from './CatalogSearchFilter';
import WaresTile from './WaresTile';
import env from '@/lib/core/services/envConstants';
import eventBus from '@/lib/core/services/eventBus';

import getSearchParams from '@/lib/search/services/getSearchParams';
import loadArticles from '@/lib/search/services/loadArticles';
import getParsedWares from '@/lib/search/services/getParsedWares';
import addWareToBasket from '@/lib/orders/services/addWareToBasket';
import SearchFilterM from '@/lib/search/components/SearchFilterM';

export default {
  name: 'SearchCatalogTile',

  components: {
    CustomSelect,
    WaresTile,
    SearchFilterM,
    CatalogSearchFilter,
    /* eslint-disable max-len */
    SalePointInfo: () =>
      import(
        /* webpackChunkName: "SalePointInfo" */ '@/lib/salePoints/components/SalePointInfo'
      )
    /* eslint-enable max-len */
  },

  props: {
    trademark: Object,
    analogsRes: { type: Object, required: true },
    dependency: { type: Object, required: true }
  },

  data () {
    return {
      isFilter: false,
      isOrderLoading: false,
      isArticlesLoading: false,
      searchAnalogsRes: null,
      isOrdering: false,
      isSalePointInfo: null
    };
  },

  computed: {
    isMobile () {
      return this.maxmq('md');
    },

    result () {
      return this.searchAnalogsRes || this.analogsRes;
    },

    isEmptyResult () {
      return isEmpty(this.$route.query) && isEmpty(this.result.results);
    },

    businessProfile () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'];
    },

    ordering: {
      get () {
        const og = this.$route.query.og;
        return og ? og.split(',') : [];
      },

      set (value) {
        // if result more than PAGINATION_SIZE
        const { og, ...query } = this.$route.query;

        if (!isEmpty(value)) {
          query.og = value.toString();
        }

        this.$router.push({ query });
      }
    }
  },

  watch: {
    '$route.query.cm': 'loadAnalogs'
  },

  mounted () {
    eventBus.$on('filter:updated', this.loadAnalogs);

    const { cm, og, ...query } = this.$route.query;

    if (!isEmpty(query)) {
      this.loadAnalogs();
    }
  },

  beforeDestroy () {
    eventBus.$off('filter:updated', this.loadAnalogs);
  },

  methods: {
    get,

    async loadAnalogs () {
      this.isArticlesLoading = true;

      const { params, query } = this.$route;
      const store = this.$store;
      const searchParams = getSearchParams(params, query, store);

      searchParams.filter.product_line = get(this.dependency, 'productLine.id');
      searchParams.PAGINATION_SIZE = env.SMALL_PAGINATION_SIZE;
      searchParams.filter.car_modification = Number(query.cm) || undefined;
      // use for count data on server 'trademarks,price,delivery_time'
      searchParams.aggs = 'trademarks,price';
      // this.$store.getters['carModifications/modificationId'] || undefined;

      const trademarkId = get(this.dependency, 'trademark.id');

      if (trademarkId) {
        searchParams.filter.trademarks = trademarkId;
        delete searchParams.trademark;
      }

      searchParams.filter.cityId = get(
        this.dependency,
        'region.id',
        searchParams.filter.cityId
      );

      const analogsParams = {
        params,
        query,
        store,
        searchParams,
        hideSelectedArticles: true
      };

      try {
        const { analogsRes } = await loadArticles(analogsParams);

        this.searchAnalogsRes = {
          ...analogsRes,
          trademarks: this.analogsRes.trademarks,
          results: getParsedWares(analogsRes.results)
        };
      } catch (e) {
        console.error(e);
        this.searchAnalogsRes = null;
      } finally {
        this.isArticlesLoading = false;
      }
    },

    toggleOrdering (_ordering) {
      this.isOrdering = false;

      const { page, og, ...query } = this.$route.query;

      if (!isEqual(_ordering, this.ordering)) {
        query.og = _ordering.toString();
      }

      eventBus.$emit('filter:reload-page', { query });
    },

    addWareToBasket (ware) {
      return addWareToBasket(
        this,
        ware,
        {},
        { from: 'Listing Page', emitViewItemEvent: true }
      );
    },

    showSalePointInfo (ware) {
      this.isSalePointInfo = ware.point_of_sale.id;

      this.$store.dispatch('analytics/pushEvent', {
        event: 'seller_info_show',
        price: ware.price,
        affiliation: ware?.seller?.business?.name || '',
        affiliation_list_name: 'Listing Page'
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/search-block.scss";

.fl-row {
  display: flex;
  align-items: center;
}

.filters-btns {
  border-top: 1px solid $grey-300 !important;
  border-bottom: 1px solid $grey-300 !important;
  padding: 10px 0;
}

.ordering-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 120px;
  background-color: $white;
  z-index: 200;
  border: 1px solid $grey-300;

  .el-button {
    width: 100%;
    margin: 0;
    border-left: none;
    border-top: none;
    border-right: none;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
