var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sale-point-page" }, [
    _c("h1", { staticClass: "custom-headline custom-headline--level2 mb-2" }, [
      _c("span", { staticClass: "bold" }, [_vm._v(_vm._s(_vm.pageTitle))]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.concatAddress))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "fl-wrap mb-4" },
      [
        _c("ContactsView", {
          staticClass: "mr-1 mb-1",
          attrs: { value: _vm.salePoint },
        }),
        _vm._v(" "),
        _c("ScheduleView", {
          staticClass: "mr-1 mb-1",
          attrs: { readonly: "", value: _vm.salePoint.schedule },
        }),
        _vm._v(" "),
        _c("LocationSelectMap", {
          attrs: {
            readonly: "",
            "query-string": _vm.concatAddress,
            value: _vm.location,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "fl-wrap mb-4" },
      [_c("SalePointOptionsView", { attrs: { value: _vm.salePoint } })],
      1
    ),
    _vm._v(" "),
    _vm.salePoint.description
      ? _c("div", { staticClass: "mb-4" }, [
          _c("div", { staticClass: "label mb-1" }, [
            _vm._v(_vm._s(_vm.$t("salePoint.description"))),
          ]),
          _vm._v(" "),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.salePoint.description) },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.photos.length
      ? _c(
          "div",
          [
            _c("div", { staticClass: "label mb-1" }, [
              _vm._v(_vm._s(_vm.$t("salePoint.photosBlock"))),
            ]),
            _vm._v(" "),
            _c("FilePreviewList", {
              attrs: {
                readonly: "",
                value: _vm.photos,
                "img-path": "photo_200",
                alt: "photo",
                "preview-size": "xxl",
                "default-img-path": "photo",
                "dialog-img-path": "photo",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }