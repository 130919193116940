import itemsEditMixin from './itemsEditMixin';

export default {
  mixins: [itemsEditMixin],

  data () {
    return {
      count: 0,
      items: []
    };
  },

  methods: {
    async updateItem (formData) {
      const { id, multiple, ..._formData } = formData;

      try {
        const item = await this.$store.dispatch(
          'orderItems/patchItem',
          { id, formData: _formData }
        );

        const parsedItem = this.getParsed(item);

        this.updateLocalItem(parsedItem, { ..._formData, multiple });

        return parsedItem;
      } catch (e) {
        console.error(e);
      }
    },

    async loadOrderItems (params) {
      try {
        const payload = {
          seller: this.sellerId,
          buyer: this.buyerId,
          ordering: '-order__created,-created',
          ...params
        };

        const {
          count,
          results
        } = await this.$store.dispatch('orderItems/getItems', payload);

        this.count = count;
        this.items = results.map(this.getParsed);

        return this.items;
      } catch (e) {
        console.error(e);
        this.items = [];
        return this.items;
      }
    },

    getParsed (item) {
      return item;
    }
  }
};
