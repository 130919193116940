import { i18n } from '@/plugins/i18n';

export default {
  trademark_name: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'blur' }
  ],
  verification_url: [
    { max: 500, message: i18n.t('validation.simple.max', { num: 500 }), trigger: 'blur' }
  ]
};
