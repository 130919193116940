<template>
  <div class="test-page">
    <div class="outline mb-4">
      <el-button size="mini" @click="clearCache">clearCache</el-button>
      <el-button size="mini" @click="showError(err_code)">Error</el-button>
    </div>
    HERE
    {{ $i18n.availableLocales }}
    <div>
      <el-input v-model="err_code" />
    </div>
  </div>
</template>

<script>
import env from '@/lib/core/services/envConstants';
import errorHandler from '@/plugins/errorHandler';
import { clearPageCache } from '@/middleware/pageCacheMiddleware';

export default {
  name: 'TestPage',

  middleware: ['development'],

  components: {
    /* eslint-disable max-len */
    // ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    // ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column'),
    /* eslint-enable max-len */
  },

  data: () => ({
    err_code: '',
    carId: '',
    isAuth: true,
    phoneNum: '',
    env,
    testData: null
  }),

  mounted () {
    this.$store.dispatch('dependencies/loadAll');
  },

  methods: {
    toHex (one, two, three) {
      const library = {
        10: 'A',
        11: 'B',
        12: 'C',
        13: 'D',
        14: 'E',
        15: 'F'
      };

      return [one, one, two, two, three, three].reduce((acc, num) => {
        const _num = num < 0 ? 0 : num;
        const index = Math.round(_num / 16);
        return index < 10 ? acc + index : acc + (library[index] || 'F');
      }, '');
    },

    async testVersion () {
      try {
        const isChanged = await this.$store.dispatch('isVersionChanged');
        console.info('check version. Is changed -', isChanged);

        if (isChanged) {
          setTimeout(() => window?.location?.reload(true), 0);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async showError (code) {
      try {
        await this.$store.dispatch('errors/getError', code || 404);
      } catch (e) {
        errorHandler(e);
      }
    },

    clearCache () {
      ['carBrands/clearQueryCache', 'cache/reset']
        .forEach(action => this.$store.dispatch(action));

      clearPageCache();
    }
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>

<style scoped lang="scss">
.test-page {
  display: block;

  .phone-input {
    width: 300px;
  }

  ul {
    width: 33%;

    li {
      width: 100%;
    }
  }
}
</style>
