var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FormBlock", {
    staticClass: "auto-update-block",
    attrs: {
      "entity-name": "price",
      fields: _vm.fields,
      submit: _vm.submit,
      value: _vm.dependency.price,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var formData = ref.formData
          var readonly = ref.readonly
          return [
            _c(
              "el-row",
              { attrs: { justify: "space-between" } },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "label-to-top",
                    attrs: {
                      label: _vm.$t("prices.selectUpdateMode"),
                      prop: "enable_auto_update",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "ml-2",
                        model: {
                          value: formData.enable_auto_update,
                          callback: function ($$v) {
                            _vm.$set(formData, "enable_auto_update", $$v)
                          },
                          expression: "formData.enable_auto_update",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "mb-1", attrs: { label: false } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("prices.manualUpdate")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: true } }, [
                          _vm._v(_vm._s(_vm.$t("prices.urlUpdate"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    !readonly
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", plain: "", type: "warning" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("reupload:file")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("buttons.uploadOtherFile")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            formData.enable_auto_update
              ? _c(
                  "div",
                  { staticClass: "outline-block" },
                  [
                    _c("div", { staticClass: "outline-block__title" }, [
                      _vm._v(_vm._s(_vm.$t("prices.manualSettings"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("labels.auto_update_url"),
                          "label-width": "150px",
                          prop: "auto_update_url",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "mb-1",
                          model: {
                            value: formData.auto_update_url,
                            callback: function ($$v) {
                              _vm.$set(formData, "auto_update_url", $$v)
                            },
                            expression: "formData.auto_update_url",
                          },
                        }),
                        _vm._v(" "),
                        _c("ul", { staticClass: "rules-list" }, [
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("prices.manualSettingsDescription1")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("prices.manualSettingsDescription2")
                              )
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }