<template>
  <div class="cars-brands-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ meta && meta.h1 || $t('catalog.trademarks') }}
    </h1>

    <TrademarksGroupedList class="mb-2" :value="trademarks" />

    <!--eslint-disable-next-line  vue/no-v-html-->
    <div v-if="meta && meta.text" class="pg-2 default-style" v-html="meta.text" />
  </div>
</template>

<script>
import TrademarksGroupedList from './components/TrademarksGroupedList';
import getSeoBreadcrumbs from './services/getSeoBreadcrumbs';
import { i18n } from '@/plugins/i18n';
import seoHeadMixin from '@/lib/catalog/mixins/seoHeadMixin';

export default {
  name: 'TrademarksPage',

  mixins: [seoHeadMixin],

  components: {
    TrademarksGroupedList
  },

  async asyncData ({ app, store, params, error }) {
    const loadTime = Date.now();

    try {
      const data = await store.dispatch('seo/getSeoTrademarks', params);

      const rowBreadcrumbs = [{
        name: 'brands.trademark',
        label: i18n.t('catalog.trademarks'),
        path: '/brands'
      }];

      const pl = {
        vm: { $store: store, $router: app.router },
        rowBreadcrumbs
      };

      const { breadcrumbs, seoBreadcrumbs } = getSeoBreadcrumbs(pl);

      store.dispatch('setBreadcrumbs', breadcrumbs);

      return {
        loadTime: Date.now() - loadTime,
        meta: data.meta,
        trademarks: data.trademarks,
        seoBreadcrumbs
      };
    } catch (e) {
      console.error(e);
      error({
        statusCode: e.statusCode || 404,
        message: e.message || app.i18n.t('labels.not_found')
      });
    }
  },

  mounted () {
    console.info(`LOAD_TIME: ${this.loadTime}ms`, this.$data);
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/default-style.scss";
</style>
