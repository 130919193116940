var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activate-resend-forms" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : [
            _c("div", { staticClass: "text-ghost mb-4 mt-2" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("auth.completionAuth")))]),
            ]),
            _vm._v(" "),
            _c(
              "el-row",
              { staticClass: "form-block", attrs: { gutter: 8 } },
              [
                _c("ActivateForm"),
                _vm._v(" "),
                _c("div", { staticClass: "splitter" }, [
                  _c("div", { staticClass: "line" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("auth.or"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "line" }),
                ]),
                _vm._v(" "),
                _c("ResendActivationForm"),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }