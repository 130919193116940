var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "price-preview-table" },
    [
      !_vm.dataPreview || !_vm.dataPreview.length
        ? _c("el-row", { staticClass: "pg-2 color-red" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("priceFile.emptyPreview")) + "\n  "
            ),
          ])
        : _c(
            "el-table",
            {
              staticClass: "mb-1 table",
              attrs: { border: "", "max-height": "400", data: _vm.dataRows },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "class-name": "index-col",
                  width: "50",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [
                        _c("span", { staticClass: "index" }, [
                          _vm._v(
                            _vm._s(_vm.headerRow ? $index + _vm.headerRow : "")
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [
                        _c("span", { staticClass: "index" }, [
                          _vm._v(_vm._s($index + _vm.dataStartRow)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm._l(_vm.headerRows, function (col, index) {
                return [
                  _c("el-table-column", {
                    key: index,
                    attrs: {
                      width: "180px",
                      label: _vm.headerRow ? col : "",
                      prop: String(index),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_vm._v(_vm._s(row[index]))]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              }),
            ],
            2
          ),
      _vm._v(" "),
      _vm.dataPreview && _vm.dataPreview.length
        ? _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.hasMoreRows,
                icon: "el-icon-plus",
                size: "mini",
              },
              on: {
                click: function ($event) {
                  _vm.rowsLimit += _vm.ROWS_OFFSET
                },
              },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("buttons.getMoreRows", { num: _vm.ROWS_OFFSET })
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }