var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticClass: "row-icon-controls fit-content" },
    [
      _c(
        "el-button-group",
        [
          _c("el-button", {
            attrs: {
              circle: "",
              disabled: !_vm.canRemove,
              icon: "el-icon-delete",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("remove", _vm.index)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }