<template>
  <el-row class="geo-inputs" :gutter="20">
    <el-col>
      <el-input v-model="geo.lat" size="mini" @input="emit('lat', $event)" />
    </el-col>
    <el-col>
      <el-input v-model="geo.lng" size="mini" @input="emit('lng', $event)" />
    </el-col>
  </el-row>
</template>

<script>
import debounce from 'lodash/debounce';
import isNaN from 'lodash/isNaN';
import { env } from '@/lib/core';

export default {
  name: 'GeoInputs',

  props: {
    value: Object
  },

  data () {
    return {
      geo: { lat: null, lng: null }
    };
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler () {
        this.geo = {
          lat: this.value && this.value.lat ? this.value.lat : null,
          lng: this.value && this.value.lng ? this.value.lng : null
        };
      }
    }
  },

  created () {
    this.emit = debounce(this.emit, env.DEBOUNCE);
  },

  methods: {
    emit (name, value) {
      if (isNaN(Number.parseFloat(value))) {
        this.geo[name] = this.value[name];
        return null;
      }

      const val = value ? Number.parseFloat(value).toFixed(env.GEO_TO_FIXED) : null;
      const event = { ...this.geo, [name]: val };

      if (val && event.lat && event.lng) {
        this.$emit('input', event);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.geo-inputs {
  width: 400px;
}
</style>
