<template>
  <div class="custom-select">
    <div class="fl-wrap pg-1" @click.stop="isMenu = !isMenu">
      {{ label }}
      <img class="icon-img ml-05" src="@/assets/icons/filter.svg">
    </div>

    <div v-if="isMenu" class="menu back-shadow">
      <slot v-bind="{ isMenu }" name="menu">
        <div>
          <div
            v-for="(item, index) in values.concat(options)"
            :key="`${label}_${index}`"
            :class="[
              'menu__item',
              {
                'is-active': getIsActive(item),
                'is-clear': item.value === 'clear'
              }
            ]"
            @click="emitItem(item)"
          >
            {{ item.label || item }}
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',

  props: {
    label: { type: String, required: true },
    values: { type: Array, default: () => ([]) },
    value: String,
    isActive: Function,
    useClear: Boolean
  },

  data () {
    return {
      isMenu: false
    };
  },

  computed: {
    options () {
      return this.useClear && this.value
        ? [{ label: this.$t('buttons.clear'), value: 'clear' }]
        : [];
    }
  },

  mounted () {
    document.addEventListener('click', this.closeMenu);
  },

  beforeDestroy () {
    document.removeEventListener('click', this.closeMenu);
  },

  methods: {
    emitItem (item) {
      if (item.value === 'clear') {
        const _item = this.values.find(({ value }) => value === this.value);

        if (_item) {
          this.$emit('input', _item);
        }
      } else {
        this.$emit('input', item);
      }

      this.isMenu = false;
    },

    closeMenu () {
      this.isMenu = false;
    },

    getIsActive (item) {
      return this.isActive
        ? this.isActive(item)
        : item.value === this.value;
    }
  }
};
</script>

<style scoped lang="scss">
.custom-select {
  position: relative;
  width: fit-content;

  .menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $white;
    border-radius: 4px;
    overflow: hidden;

    &__item {
      padding: 10px;

      &.is-active {
        background-color: $--color-primary;
        color: $white;
      }

      &.is-clear {
        background-color: $grey-500;
        color: $white;
      }
    }

    z-index: 100;
  }
}
</style>
