<template>
  <div class="price-preview-table">
    <el-row v-if="!dataPreview || !dataPreview.length" class="pg-2 color-red">
      {{ $t('priceFile.emptyPreview') }}
    </el-row>

    <el-table
      v-else
      border
      class="mb-1 table"
      max-height="400"
      :data="dataRows"
    >
      <el-table-column align="center" class-name="index-col" width="50">
        <template #header="{ $index }">
          <span class="index">{{ headerRow ? $index + headerRow : '' }}</span>
        </template>

        <template #default="{ $index }">
          <span class="index">{{ ($index + dataStartRow) }}</span>
        </template>
      </el-table-column>

      <template v-for="(col, index) in headerRows">
        <el-table-column
          :key="index"
          width="180px"
          :label="headerRow ? col : ''"
          :prop="String(index)"
        >
          <template #default="{ row }">{{ row[index] }}</template>
        </el-table-column>
      </template>
    </el-table>

    <el-button
      v-if="dataPreview && dataPreview.length"
      :disabled="!hasMoreRows"
      icon="el-icon-plus"
      size="mini"
      @click="rowsLimit += ROWS_OFFSET"
    >
      {{ $t('buttons.getMoreRows', { num: ROWS_OFFSET }) }}
    </el-button>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'PricePreviewTable',

  components: {
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column')
    /* eslint-enable max-len */
  },

  props: {
    headerRow: { type: Number, default: 0 },
    dataStartRow: { type: Number, default: 0 },
    mapping: { type: Array, default: () => [] },
    value: { type: Number, default: 1 },
    dataPreview: Array
  },

  data: () => ({
    rowsLimit: 10,
    ROWS_OFFSET: 10
  }),

  computed: {
    actions () {
      return this.mapping
        .filter(item => !isEmpty(item.price_actions))
        .reduce((acc, { column, price_actions }) => {
          acc[column] = price_actions.map(() => '-');
          return acc;
        }, {});
    },

    withActions () {
      const keys = Object.keys(this.actions);
      const dataClone = [...this.dataPreview];
      const _value = dataClone
        ? dataClone.slice(0, this.dataStartRow + this.rowsLimit)
        : [];

      return !keys.length
        ? _value
        : cloneDeep(_value)
          .map((row) => {
            keys.forEach(colIndex => row.splice(colIndex, 0, ...this.actions[colIndex]));
            return row;
          });
    },

    headerRows () {
      const row = typeof this.headerRow === 'number' ? this.headerRow - 1 : 0;
      return this.withActions[row];
    },

    dataRows () {
      const start = this.dataStartRow ? this.dataStartRow - 1 : 0;
      const end = this.dataStartRow ? this.dataStartRow + this.rowsLimit : this.rowsLimit;
      return this.withActions.slice(start, end);
    },

    hasMoreRows () {
      return this.dataPreview && this.dataPreview.length > this.rowsLimit;
    }
  }
};
</script>

<style scoped lang="scss">
.price-preview-table {
  width: 100%;

  .index {
    font-family: serif;
    font-weight: bold;
    font-style: italic;
  }

  ::v-deep {
    td, th {
      border-right: 1px solid $grey-400;
    }
  }
}
</style>
