var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "lang-links" },
    _vm._l(_vm.langList, function (it) {
      return _c(
        "el-link",
        {
          key: it.langCode,
          attrs: {
            href: _vm.getHrefBy(it.langCode),
            disabled: _vm.$store.getters.langCode === it.langCode,
            underline: false,
          },
        },
        [_vm._v("\n    " + _vm._s(it.label) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }