import env from '../services/envConstants';

export default {
  props: {
    load: Function,
    error: String,
    readonly: Boolean,
    usePage: Boolean,
    propValue: Array,
    propLoading: { type: null, default: null },
    propCount: Number,
    immediateLoadFalse: Boolean,
    rowKey: { type: String, default: 'id' },
    emptyText: String
  },

  data: () => ({
    watchParams: false,
    reloading: false,
    loadErr: null,
    loadValue: null,
    PAGE_SIZE: env.PAGINATION_SIZE
  }),

  computed: {
    localError () {
      return this.loadErr || this.error;
    },

    loading () {
      return (!this.value && !this.localError) || this.propLoading || this.reloading;
    },

    emptyTitle () {
      if (this.loading) {
        return ' ';
      } else if (this.localError) {
        const code = this.localError;

        return `${this.$t(`errors.${code}.title`)}: ${this.$t(`errors.${code}.message`)}`;
      }

      return this.emptyText || this.$t('search.emptyResult');
    },

    value () {
      return this.propValue || this.loadValue || [];
    },

    page: {
      get () {
        return Number.parseInt(this.$route.query.page) || 1;
      },
      set (page) {
        if (page !== this.page) {
          return this.$router.push({ query: { ...this.$route.query, page } });
        }
      }
    },

    params () {
      const { page, ...filter } = this.$route.query;

      return {
        offset: (this.page - 1) * this.PAGE_SIZE,
        limit: this.PAGE_SIZE,
        ...filter
      };
    }
  },

  mounted () {
    if (!this.immediateLoadFalse) {
      this.reload();
    }

    if (this.watchParams) {
      this.$watch(() => this.params, this.reload);
    }
  },

  watch: {
    propValue () {
      this.loadErr = null;
    }
  },

  methods: {
    async reload () {
      if (this.load) {
        this.loadErr = null;
        if (typeof this.propLoading !== 'boolean') {
          this.reloading = true;
        }

        try {
          this.loadValue = await this.load(this.params);
        } catch (e) {
          console.error(e);
          this.loadErr = 'server';
        } finally {
          this.reloading = false;
        }
      }
    },

    toggleFilter (name, value) {
      const { page, ...query } = this.$route.query;

      if (page) {
        query.page = page;
      }

      if (!query[name]) {
        query[name] = value;
      } else {
        delete query[name];
      }

      return this.$router.push({ query });
    }
  }
};
