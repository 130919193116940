import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import transform from 'lodash/transform';
import { library, defaultEntities } from '@/lib/core';

const { searchFilterAbbreviation, searchFilterKeys } = library;

export const getSearchFilterByRoute = (query) => {
  const formData = cloneDeep(defaultEntities.searchFilter);

  return transform(query, (acc, value, key) => {
    const int = Number.parseInt(value);

    if (key === 'osa') {
      acc[searchFilterAbbreviation.waa] = !value;
    }

    if (!searchFilterAbbreviation[key]) {
      return acc;
    } else if (Array.isArray(defaultEntities.searchFilter[searchFilterAbbreviation[key]])) {
      const list = value.split(',');
      const isNum = Number.parseInt(value[0]);

      acc[searchFilterAbbreviation[key]] = isNum
        ? list.map(int => Number.parseInt(int))
        : list;
    } else if (int || int === 0) {
      acc[searchFilterAbbreviation[key]] = int;
    } else if ([true, 'true'].includes(value)) {
      acc[searchFilterAbbreviation[key]] = true;
    }
  }, formData);
};

export const getSearchFilterForRoute = (formData) => {
  return transform(formData, (acc, value, filterKey) => {
    if (
      filterKey === 'ware_and_analogs' ||
      (
        value !== true &&
        !Number.parseInt(value) &&
        ![0, '0'].includes(value) &&
        (isEmpty(value) || !library.searchFilterKeys[filterKey])
      )
    ) {
      return acc;
    }

    acc[library.searchFilterKeys[filterKey]] = value.toString();
  }, {});
};

const checkDefault = ['price_gte', 'price_lte', 'delivery_time_gte', 'delivery_time_lte'];

export const getClearSearchFilter = (query) => {
  const {
    onlySelectArticle,
    onlyCity,
    ...formData
  } = getSearchFilterByRoute(query);

  const result = transform(formData, (acc, value, key) => {
    if (
      checkDefault.includes(key) &&
      query[searchFilterKeys[key]] !== 0 &&
      (
        !query[searchFilterKeys[key]] ||
        value === defaultEntities.searchFilter[key]
      )
    ) {
      return acc;
    }

    const int = Number.parseInt(value);

    if (key.startsWith('delivery_time')) {
      acc[key] = `${int} 00:00:00`;
    } else if (formData[key] === true) {
      acc[key] = true;
    } else if (Array.isArray(value) && value.length) {
      acc[key] = value.toString();
    } else if (int || int === 0) {
      acc[key] = int;
    } else if (!isEmpty(value)) {
      acc[key] = value;
    }
  }, {});

  // it used because we field like ware_and_analogs / city_and_delivery,
  // but in SearchFilter.vue we have inverted translation -
  // "Місцеві пропозиції" / "Тільки аналог що шукали"

  result.ware_and_analogs = !onlySelectArticle;
  result.city_and_delivery = !onlyCity;

  return result;
};
