<template>
  <div class="delivery-services-table">
    <el-table :data="value">
      <el-table-column
        :label="$t('delivery_methods.carrier_in_country')"
        prop="service_detail.label"
      >
        <template #default="{ row }">
          {{ getLabel(row) }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('salePoint.comment')"
        prop="comment"
        width="100px"
        align="center"
      >
        <template #default="{ row }">
          <i
            :class="`el-icon-chat-${row.comment ? 'line-round' : 'round'}`"
            @click="showCommentPopup(row)"
          />
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('delivery_methods.free_shipping')"
        prop="has_free_ship"
        width="130px"
        align="center"
      >
        <template #default="{ row }">
          <el-checkbox
            size="mini"
            :value="row.has_free_ship"
            @change="emit(row, 'has_free_ship', $event)"
          />
        </template>
      </el-table-column>

      <el-table-column
        :label="`${$t('salePoint.minPrice')}, ${MARKETPLACE_CURRENCY}`"
        prop="free_ship_amount"
        width="150px"
        align="center"
      >
        <template #default="{ row }">
          <el-input-number
            size="mini"
            :min="0"
            :value="row.free_ship_amount"
            @input="emit(
              row,
              'free_ship_amount',
              $event
            )"
          />
        </template>
      </el-table-column>

      <el-table-column prop="is_active" width="60px" align="center">
        <template #default="{ row }">
          <el-switch
            :value="row.is_active"
            @change="emit(row, 'is_active', $event)"
          />
        </template>
      </el-table-column>
    </el-table>

    <div class="text-right text-ghost mt-05">
      {{ $t('salePoint.minPriceDesc') }}
    </div>

    <TheDialog
      v-model="isShowComment"
      :title="`${$t('salePoint.comment')}: ${getLabel(isShowComment)}`"
    >
      <div v-if="isShowComment">
        <el-input
          v-model="tempComment"
          class="mb-2"
          type="textarea"
          rows="6"
        />

        <el-row justify="end">
          <el-button @click="isShowComment = null">
            {{ $t('buttons.cancel') }}
          </el-button>
          <el-button
            :disabled="isShowComment.comment === tempComment"
            type="primary"
            @click="saveComment(isShowComment)"
          >
            {{ $t('buttons.save') }}
          </el-button>
        </el-row>
      </div>
    </TheDialog>
  </div>
</template>

<script>
import ElSwitch from 'element-ui/lib/switch';
import env from '@/lib/core/services/envConstants';

export default {
  name: 'DeliveryServicesTable',

  components: {
    ElSwitch,
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column')
    /* eslint-enable max-len */
  },

  props: {
    value: { type: Array, required: true }
  },

  data: () => ({
    MARKETPLACE_CURRENCY: env.MARKETPLACE_CURRENCY,
    isShowComment: null,
    tempComment: ''
  }),

  methods: {
    getLabel (row) {
      const code = row?.service_detail?.label;
      return code
        ? this.$t(`deliveryServices.${code.replace(/\./g, '_')}`)
        : '';
    },

    emit (row, field, value) {
      this.$emit('input', { ...row, [field]: value });
    },

    showCommentPopup (row) {
      this.tempComment = row.comment;
      this.isShowComment = { ...row };
    },

    saveComment (row) {
      this.emit(row, 'comment', this.tempComment);
      this.isShowComment = null;
      this.tempComment = '';
      this.$emit('save');
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input-number ::v-deep {
  width: 80px;

  .el-input-number__increase, .el-input-number__decrease {
    display: none;
  }

  .el-input__inner {
    padding: 0 4px;
  }
}
</style>
