var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-articles-table" },
    [
      _vm.isShowSelectedArticle
        ? _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c("div", { staticClass: "mb-1 pg-0-1 table-title" }, [
                _c(
                  "h4",
                  {
                    staticClass: "custom-headline custom-headline--level4 pg-0",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("search.invitedArticle")) +
                        "\n      "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(_vm.tableName, {
                tag: "component",
                attrs: {
                  article: _vm.$route.params.article,
                  "disabled-ids": _vm.basketOfferIds,
                  "empty-text": _vm.emptyTexts.selectedArticle,
                  ordering: _vm.ordering,
                  "prop-value": _vm.selectedArticlesRes.results,
                  value: _vm.selectedArticlesRes.results,
                },
                on: {
                  "basket-click": function ($event) {
                    return _vm.$emit("basket-click", $event)
                  },
                  "info:show": function ($event) {
                    return _vm.$emit("info:show", $event)
                  },
                  "sale-point:info": function ($event) {
                    return _vm.$emit("sale-point:info", $event)
                  },
                  "toggle-ordering": function ($event) {
                    return _vm.$emit("toggle-ordering", $event)
                  },
                },
              }),
              _vm._v(" "),
              _vm.selectedArticlesRes.count > 4
                ? _c(
                    "el-row",
                    { staticClass: "mt-1", attrs: { justify: "end" } },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.loadOnlySelectedArticle },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("search.showAllSellers")) +
                              " >>\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowSelectedArticle
        ? _c("div", { staticClass: "mb-1 pg-0-1 table-title" }, [
            _c(
              "h4",
              { staticClass: "custom-headline custom-headline--level4 pg-0" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "search." +
                          (!_vm.$route.query.osa
                            ? "invitedArticleAndAnalogs"
                            : "invitedArticle")
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(_vm.tableName, {
        tag: "component",
        attrs: {
          article: _vm.$route.params.article,
          "disabled-ids": _vm.basketOfferIds,
          "empty-text": _vm.emptyTexts.analogs,
          ordering: _vm.ordering,
          "prop-value": _vm.analogsRes.results,
          value: _vm.analogsRes.results,
        },
        on: {
          "basket-click": function ($event) {
            return _vm.$emit("basket-click", $event)
          },
          "info:show": function ($event) {
            return _vm.$emit("info:show", $event)
          },
          "sale-point:info": function ($event) {
            return _vm.$emit("sale-point:info", $event)
          },
          "toggle-ordering": function ($event) {
            return _vm.$emit("toggle-ordering", $event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mt-2", attrs: { justify: "center" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.page,
              "hide-on-single-page": "",
              layout: "prev, pager, next",
              "page-size": _vm.PAGINATION_SIZE,
              "pager-count": 5,
              total: _vm.analogsRes.count,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.page = $event
              },
              "update:current-page": function ($event) {
                _vm.page = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }