var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-response-search-wares" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : [
            _c("div", { staticClass: "min-w-320 mb-1 pg-0-1" }, [
              _c(
                "h4",
                { staticClass: "custom-headline custom-headline--level4 pg-0" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("search.invitedArticle")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            !_vm.wares.length
              ? _c(
                  "el-row",
                  { staticClass: "pg-2", attrs: { justify: "center" } },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t("sRequests.emptySearchText", {
                            name: _vm.searchName,
                          })
                        ) +
                        "\n    "
                    ),
                  ]
                )
              : _c("WaresTable", {
                  attrs: { "prop-value": _vm.wares, ordering: _vm.ordering },
                  on: {
                    "info:show": function ($event) {
                      return _vm.$emit("info:show", $event)
                    },
                    "toggle-ordering": _vm.toggleOrdering,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "6h",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("priceFile.name")) +
                              "\n      "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "6",
                      fn: function (row) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                row.price_file ? row.price_file.name : ""
                              ) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                    {
                      key: "options",
                      fn: function (row) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("select", row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("buttons.select")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
            _vm._v(" "),
            _vm.limitedAnalogs.length
              ? _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("div", { staticClass: "min-w-320 mb-1 pg-0-1" }, [
                      _c(
                        "h4",
                        {
                          staticClass:
                            "custom-headline custom-headline--level4 pg-0",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("search.invitedArticleAndAnalogs")
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("WaresTable", {
                      attrs: {
                        "prop-value": _vm.limitedAnalogs,
                        ordering: _vm.ordering,
                      },
                      on: {
                        "info:show": function ($event) {
                          return _vm.$emit("info:show", $event)
                        },
                        "toggle-ordering": _vm.toggleOrdering,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "6h",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("priceFile.name")) +
                                    "\n        "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "6",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      row.price_file ? row.price_file.name : ""
                                    ) +
                                    "\n        "
                                ),
                              ]
                            },
                          },
                          {
                            key: "options",
                            fn: function (row) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.$emit("select", row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("buttons.select")) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1093597334
                      ),
                    }),
                    _vm._v(" "),
                    _vm.analogsLimit < _vm.analogs.length
                      ? _c(
                          "el-row",
                          { staticClass: "mt-2", attrs: { justify: "end" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    _vm.analogsLimit += 10
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t("buttons.getMoreRows", { num: 10 })
                                    ) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }