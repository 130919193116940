<template>
  <el-select
    ref="select"
    autocomplete="no"
    class="search-location-select"
    clearable
    filterable
    reserve-keyword
    value-key="id"
    remote
    :allow-create="allowCreate"
    :disabled="disabled"
    :loading="loading"
    :placeholder="$t(`labels.${field}`)"
    :remote-method="search"
    :value="value && value.name"
    @change="emitValue"
    @clear="options = []"
    @blur="setEntered()"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item[labelName]"
      :value="item"
    />
  </el-select>
</template>

<script>
import env from '@/lib/core/services/envConstants';

export default {
  name: 'SearchLocationSelect',

  props: {
    allowCreate: Boolean,
    disabled: Boolean,
    value: null,
    field: { type: String, required: true },
    params: {
      type: Object,
      default: () => ({ country: { id: env.MARKETPLACE_COUNTRY_ID } })
    },
    labelName: { type: String, default: 'name' }
  },

  data () {
    return {
      options: [],
      loading: false,
      searchMethods: {
        country: this.getCountries,
        admin1: this.getAdmin1s,
        city: this.getCities
      }
    };
  },

  watch: {
    value (value) {
      if (!value) {
        this.options = [];
      }
    }
  },

  methods: {
    emitValue (value) {
      this.$emit('input', value);
    },

    getCountries (search) {
      return this.$store.dispatch('searchLocation/getCountries', { search });
    },

    getAdmin1s (search) {
      const { country } = this.params;

      return this.$store.dispatch('searchLocation/getAdmin1s', {
        search,
        country: country && country.id
      });
    },

    getCities (search) {
      const { country, admin1 } = this.params;

      return this.$store.dispatch('searchLocation/getCities', {
        search,
        country: country && country.id,
        admin1: admin1 && admin1.id
      });
    },

    async search (search) {
      this.options = [];

      if (search !== '') {
        this.loading = true;

        try {
          this.options = await this.searchMethods[this.field](search);
        } catch (e) {
          console.error(e);
          this.options = [];
        } finally {
          this.loading = false;
        }
      }
    },

    setEntered () {
      if (this.allowCreate && this.$refs.select.query && !this.value) {
        this.$emit('input', this.$refs.select.query);
      }
    }
  }
};
</script>
