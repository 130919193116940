import env from '../lib/core/services/envConstants';

export default function (req, res, next) {
  const page = req.url.match(/page-\d+/g);

  if (page && page[0] === '/page-1') {
    // https://mriyar.atlassian.net/browse/MBP-4308
    res.writeHead(301, { location: req.url.replace('/page-1', '') });
    return res.end();
  } else if (req.url.includes('/page-')) {
    // https://mriyar.atlassian.net/browse/MBP-4307
    const param = req.url.match(/page-\d+/g)[0];

    const page = param?.split('-')[1];

    if (Number(page) > env.MAX_PAGE_PAGINATION) {
      res.writeHead(301,
        { location: req.url.replace(param, `page-${env.MAX_PAGE_PAGINATION}`) }
      );

      return res.end();
    }
  }

  next();
}
