<template>
  <div class="cars-brands-models-item">
    <el-row justify="space-between" class="mb-1">
      <div>
        <nuxt-link class="label" :to="getLink({ carBrand: value })">
          {{ value.name }}
        </nuxt-link>
      </div>
      <div>
        <el-link v-show="!isOpen" type="primary" @click="isOpen = true">
          {{ $t('buttons.unCollapseAll') }}
        </el-link>`
      </div>
    </el-row>

    <ul :class="['models', { open: isOpen }]">
      <li
        v-for="carModel in value.models"
        :key="carModel.id"
        class="model"
      >
        <nuxt-link :to="getLink({ carBrand: value, carModel })">
          {{ carModel.name }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CarsBrandsModelsItem',

  // TODO revert cache
  // serverCacheKey: props => props.value.id,

  props: {
    getLink: { type: Function, required: true },
    dependency: Object,
    value: { type: Object, required: true }
  },

  data: () => ({ isOpen: false })
};
</script>

<style scoped lang="scss">
.cars-brands-models-item {
  .label {
    font-weight: bold;
    font-size: 1.4rem;
  }

  .models {
    display: flex;
    flex-wrap: wrap;
    margin-left: 40px;
    max-width: 1300px;
    max-height: 35px;
    overflow: hidden;

    &.open {
      max-height: unset;
    }

    .model {
      min-width: 200px;
      padding: 10px;
    }
  }

  @media all and (max-width: $--sm) {
    .models {
      margin-left: 0;

      .model {
        min-width: unset;
      }
    }
  }
}
</style>
