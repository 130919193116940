export const actions = {
  postPriceFile ({ dispatch }, { formData, push }) {
    return this.$axios
      .$post(`/price-files/?push=${!!push}`, formData);
  },

  getPriceFile ({ commit }, { id }) {
    return this.$axios.$get(`/price-files/${id}`);
  },

  patchPrice ({ dispatch }, { id, formData, push }) {
    return this.$axios.$patch(
      `/price-files/${id}/?push=${!!push}`,
      formData,
      { timeout: 10000000 }
    );
  },

  async postPushPriceFile (_, { id }) {
    const { data } = await this.$axios({
      method: 'post',
      url: `/price-files/${id}/push/`,
      progress: false,
      timeout: 0
    });

    return data;
  },

  postAvailability (_, { formData }) {
    return this.$axios.$post('/price-file-availabilities/', formData);
  },

  patchAvailability (_, { id, formData }) {
    return this.$axios.$patch(
      `/price-file-availabilities/${id}/`,
      formData
    );
  }
};
