var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "photo-list" },
    _vm._l(_vm.photos, function (photo) {
      return _c("li", { key: photo.id, staticClass: "list-item" }, [
        _c(
          "div",
          { staticClass: "img-card" },
          [
            _c("el-row", [
              _c("img", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                attrs: { alt: photo.name, "data-src": _vm.getUrl(photo) },
              }),
              _vm._v(" "),
              !_vm.readonly
                ? _c("i", {
                    staticClass: "el-icon-close",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("remove", photo)
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-radio",
                  {
                    attrs: { disabled: _vm.readonly, label: true },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("primary", photo)
                      },
                    },
                    model: {
                      value: photo.is_primary,
                      callback: function ($$v) {
                        _vm.$set(photo, "is_primary", $$v)
                      },
                      expression: "photo.is_primary",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("salePoint.primary")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }