var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout" },
    [
      _c(
        "el-header",
        {
          directives: [
            {
              name: "observe-visibility",
              rawName: "v-observe-visibility",
              value: _vm.toggleScrollBtn,
              expression: "toggleScrollBtn",
            },
          ],
          staticClass: "scroll-target",
          attrs: { height: "unset" },
        },
        [_c("PageHeaderInfo")],
        1
      ),
      _vm._v(" "),
      _c(
        "el-container",
        { staticClass: "main-section" },
        [_c("el-main", { staticClass: "mb-4" }, [_c("nuxt")], 1)],
        1
      ),
      _vm._v(" "),
      _vm.$store.getters.isAppLoaded
        ? [
            _c("ProgressNotifier"),
            _vm._v(" "),
            _c("AppVersion"),
            _vm._v(" "),
            _vm.isDev ? _c("TestPanel") : _vm._e(),
            _vm._v(" "),
            _vm.isShowScroll ? _c("BackToTop") : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }