import env from '../services/envConstants';

const scrollTo = (top, left) => window?.scroll({
  top,
  left: left || 0
});

export default (target = 0, options = {}) => {
  const { offset = 50, left } = options;

  if (typeof target === 'string') {
    setTimeout(() => {
      const item = document.getElementsByClassName(target)[0];
      const parent = options.parentClassName
        ? document.getElementsByClassName(options.parentClassName)[0]
        : null;

      if (parent && item) {
        return parent.scrollTo({
          left: 0,
          top: item.offsetTop - (offset * 3)
        });
      }

      if (item) {
        const top = item.getBoundingClientRect().top;
        const _topWithOffset = top + (window?.scrollY || 0) - offset;
        scrollTo(_topWithOffset, left, parent);
      }
    }, env.DEBOUNCE);
  } else {
    scrollTo(target, null);
  }
};
