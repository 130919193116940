var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "social-registration", attrs: { loading: _vm.loading } },
    [
      _c(
        "el-row",
        { staticClass: "item" },
        [
          _c("el-row", { staticClass: "label", attrs: { align: "middle" } }, [
            _c("img", {
              staticClass: "icon-img",
              attrs: { alt: "facebook", src: require("@/assets/img/fb.svg") },
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t("labels.facebook")) + ":")]),
          ]),
          _vm._v(" "),
          _vm.statuses
            ? _c(
                "div",
                [
                  !_vm.statuses["facebook"]
                    ? [
                        _c(
                          "client-only",
                          [
                            _c("FacebookBtn", {
                              on: { registered: _vm.loadStatuses },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-link",
                          { staticClass: "info", attrs: { type: "info" } },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("buttons.joinedSocial", {
                                    name: "Facebook",
                                  })
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.removeLink("facebook")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("buttons.breakSocialLink")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c("el-row", { staticClass: "label", attrs: { align: "middle" } }, [
            _c("img", {
              staticClass: "icon-img",
              attrs: {
                alt: "google",
                src: require("@/assets/img/google1.png"),
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t("labels.google")) + ":")]),
          ]),
          _vm._v(" "),
          _vm.statuses
            ? _c(
                "div",
                [
                  !_vm.statuses["google-oauth2"]
                    ? [
                        _c(
                          "client-only",
                          [
                            _c("GoogleBtn", {
                              on: { registered: _vm.loadStatuses },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-link",
                          { staticClass: "info", attrs: { type: "info" } },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("buttons.joinedSocial", {
                                    name: "Google",
                                  })
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.removeLink("google-oauth2")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("buttons.breakSocialLink")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }