<template>
  <div class="social-registration" :loading="loading">
    <el-row class="item">
      <el-row align="middle" class="label">
        <img alt="facebook" class="icon-img" src="@/assets/img/fb.svg">
        <span>{{ $t('labels.facebook') }}:</span>
      </el-row>

      <div v-if="statuses">
        <template v-if="!statuses['facebook']">
          <client-only>
            <FacebookBtn @registered="loadStatuses" />
          </client-only>
        </template>

        <template v-else>
          <el-link class="info" type="info">
            {{ $t('buttons.joinedSocial', { name: 'Facebook' }) }}
          </el-link>
          <el-link type="danger" @click="removeLink('facebook')">
            {{ $t('buttons.breakSocialLink') }}
          </el-link>
        </template>
      </div>
    </el-row>

    <el-row>
      <el-row align="middle" class="label">
        <img alt="google" class="icon-img" src="@/assets/img/google1.png">
        <span>{{ $t('labels.google') }}:</span>
      </el-row>

      <div v-if="statuses">
        <template v-if="!statuses['google-oauth2']">
          <client-only>
            <GoogleBtn @registered="loadStatuses" />
          </client-only>
        </template>

        <template v-else>
          <el-link class="info" type="info">
            {{ $t('buttons.joinedSocial', { name: 'Google' }) }}
          </el-link>
          <el-link type="danger" @click="removeLink('google-oauth2')">
            {{ $t('buttons.breakSocialLink') }}
          </el-link>
        </template>
      </div>
    </el-row>
  </div>
</template>

<script>
import keyBy from 'lodash/keyBy';
import FacebookBtn from '../../../auth/plugins/FacebookBtn';
import GoogleBtn from '../../../auth/plugins/GoogleBtn';

export default {
  name: 'SocialRegistration',

  components: {
    FacebookBtn,
    GoogleBtn
  },

  props: {
    id: { type: Number, required: true }
  },

  data: () => ({
    loading: true,
    statuses: null
  }),

  created () {
    this.loadStatuses();
  },

  methods: {
    async loadStatuses () {
      this.loading = true;
      try {
        const res = await this.$store.dispatch('socialUsers/getSocialUser', { user: this.id });
        this.statuses = keyBy(res, 'provider');
      } finally {
        this.loading = false;
      }
    },

    async removeLink (name) {
      this.loading = true;

      try {
        const id = this.statuses[name].id;
        await this.$store.dispatch('socialUsers/deleteSocialUser', { id });
        delete this.statuses[name];
        this.loadStatuses();
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.social-registration {
  .item {
    margin-bottom: 18px;
  }

  .v-facebook-login, .google-btn {
    height: unset;
    padding: 2px 20px;
  }

  .label {
    min-width: 100px;
    color: $grey-700;
  }

  .icon-img {
    width: 14px;
    height: 14px;
  }

  .info {
    pointer-events: none;
  }
}
</style>
