var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticClass: "currency-selector w-100p",
      attrs: { disabled: _vm.readonly, value: _vm.value },
      on: {
        change: function ($event) {
          return _vm.$emit("input", $event)
        },
      },
    },
    _vm._l(_vm.currencies, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.name, value: item.url },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }