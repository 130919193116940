var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "platforms-send-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("routes.profile_business_platforms")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : [
            _vm.platform
              ? _c("span", { domProps: { innerHTML: _vm._s(_vm.description) } })
              : _vm._e(),
            _vm._v(" "),
            _c("BusinessRequisitesTab", { attrs: { platform: _vm.platform } }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }