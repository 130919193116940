<template>
  <el-form
    ref="form"
    :class="['form-block', { readonly }]"
    :disabled="disabled"
    :label-position="labelPosition"
    :label-width="labelWidth"
    :model="formData"
    :rules="validationRules"
    size="mini"
    style="display: block; height: fit-content"
    v-bind="$attrs"
    @submit.native.prevent="validateAndEmit"
  >
    <div>
      <!--TODO remove readonly-->
      <slot
        :disabled="disabled"
        :formData="formData"
        :isLoading="isLoading"
        :readonly="disabled"
        :validationRules="validationRules"
      />
    </div>

    <el-row v-if="!disabled && !hideControls" class="mt-2" justify="end">
      <el-button :disabled="isEqual && !showCancel" size="mini" @click="refresh">
        {{ $t('buttons.cancel') }}
      </el-button>
      <el-button
        :disabled="isEqual && !unlocked"
        :loading="isLoading"
        native-type="submit"
        size="mini"
        type="primary"
      >
        {{ $t('buttons.save') }}
      </el-button>
    </el-row>
  </el-form>
</template>

<script>
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import getValidationFor from '../validations';

export default {
  name: 'FormBlock',

  props: {
    fields: Array,
    submit: Function,
    completenessEvery: Boolean,
    completenessFields: [Array, Function],
    labelPosition: String,
    labelWidth: String,
    entityName: String,
    hideControls: Boolean,
    readonly: Boolean,
    value: { type: Object, required: true },
    defaultFields: { type: Object, default: () => ({}) },
    rules: [Object, Array],
    comparator: Function,
    showCancel: Boolean,
    unlocked: Boolean
  },

  data: () => ({
    autoEmit: true,
    formData: null,
    isLoading: false,
    valueCopy: {}
  }),

  computed: {
    validationRules () {
      const { rules, fields, entityName } = this;

      if (Array.isArray(rules)) {
        return getValidationFor(rules, entityName, this);
      }

      return fields && entityName && getValidationFor(fields, entityName, this);
    },

    disabled () {
      const isAdmin = this.$store.getters['businessUsers/isAdmin'];
      const editMode = this.$route.name.includes('edit');
      return this.readonly || Boolean(editMode && !isAdmin);
    },

    // valueCopy () {
    //   return this.fields
    //     ? this.getDestructingBy()
    //     : cloneDeep(this.value);
    // },

    isEqual () {
      return this.comparator
        ? this.comparator(this.valueCopy, this.formData)
        : isEqual(this.valueCopy, this.formData);
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler () {
        this.valueCopy = this.fields
          ? this.getDestructingBy()
          : cloneDeep(this.value);
      }
    }
  },

  created () {
    this.refresh();
  },

  methods: {
    getDestructingBy () {
      return this.fields.reduce((acc, key) => {
        const value = this.value[key] || [null, 0, false].includes(this.value[key])
          ? this.value[key]
          : this.defaultFields[key];

        acc[key] = cloneDeep(value);
        return acc;
      }, {});
    },

    refresh () {
      this.$set(this, 'formData', cloneDeep(this.valueCopy));
      this.checkForCompleteness();
      this.$emit('refresh', this.formData);
    },

    checkForCompleteness () {
      const { valueCopy, fields, completenessFields } = this;

      if (typeof completenessFields === 'function') {
        return this.$emit('validate', completenessFields(this.valueCopy, this.formData));
      }

      const checkList = completenessFields || fields || Object.keys(valueCopy);
      const result = this.getValidFields(checkList, valueCopy);
      const action = this.completenessEvery ? 'every' : 'some';
      const isCompleteness = Object.values(result)[action](Boolean);

      this.$emit('validate', isCompleteness);
    },

    getValidFields (checkList, valueCopy) {
      return checkList.reduce((acc, key) => {
        const field = valueCopy[key];

        if (Array.isArray(field)) {
          acc[key] = Boolean(field.length);
        } else if (field && typeof field === 'object') {
          acc[key] = Object.values(field).some(Boolean);
        } else {
          acc[key] = Boolean(field);
        }

        return acc;
      }, {});
    },

    validateAndEmit ({ force, forceFields } = {}) {
      this.$refs.form && this.$refs.form.validate((isValid) => {
        this.$emit('validate', isValid);

        if (isValid) {
          if (this.autoEmit || force) {
            let formData = null;

            if (this.unlocked || force) {
              formData = this.formData;
            } else {
              formData = this.getDiff(forceFields);
            }

            this.emit(formData);
          }
        }
      });
    },

    getDiff (forceFields = []) {
      return this.fields || !isEmpty(forceFields)
        ? this.fields.reduce((acc, key) => {
          if (!isEqual(this.valueCopy[key], this.formData[key]) || forceFields.includes(key)) {
            acc[key] = this.formData[key];
          }
          return acc;
        }, {})
        : this.formData;
    },

    watchValOnce () {
      const unwatch = this.$watch(() => this.value, (value) => {
        unwatch();

        setTimeout(this.refresh, 10);
      }, { deep: true });
    },

    async emit (formData = this.formData) {
      if (this.submit) {
        this.isLoading = true;

        try {
          this.watchValOnce();
          await this.submit(cloneDeep(formData), this.valueCopy);
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$emit('input', cloneDeep(formData));
      }
    }
  }
};
</script>

<style scoped lang="scss">
.form-block.readonly {
  ::v-deep .el-form-item__error {
    display: none;
  }
}
</style>
