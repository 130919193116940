import upperFirst from 'lodash/upperFirst';
import isEmpty from 'lodash/isEmpty';
import PriceActivator from '../components/PriceActivator';
import PriceFormatForm from '../components/PriceFormatForm';
import PriceReUploadDialog from '../components/PriceReUploadDialog';
import watchAndLoadPreview from '../services/watchAndLoadPreview';
import { eventBus, parsers } from '@/lib/core';

export default {
  components: {
    PriceActivator,
    PriceReUploadDialog,
    PriceFormatForm
  },

  data () {
    return {
      isFormatDialog: false,
      isUploadDialog: false,
      isConfirmMapping: false,
      isPageLoaded: false,
      mode: null,
      previewUpdate: null,
      price: null,
      salePoints: null,
      priceTags: null,
      priceSchemes: null
    };
  },

  computed: {
    dependency () {
      const data_sheet = this.formData
        ? this.formData?.data_sheet
        : this.price.price_file.data_sheet;

      return {
        mode: this.mode,
        price: {
          ...this.price,
          ...this.getCurrentPreview(data_sheet)
        },
        salePoints: this.salePoints,
        priceTags: this.priceTags,
        priceSchemes: this.priceSchemes,
        warehouses: this.warehouses,
        formData: {
          ...this.formData,
          data_sheet
        }
      };
    },

    isPriceError () {
      return ['error', 'invalid'].includes(this.price.status) &&
        !['invalid_mapping', 'confirm_mapping'].includes(this.price.error_code);
    },

    needUpdatePreview () {
      return !['new', 'validation'].includes(this.price.status);
    }
  },

  async mounted () {
    await this.load();

    if (this.price) {
      this.setDefaultData();
      this.checkStatus();
      this.loadWarehouses();

      if (!this.price.data_preview || this.needUpdatePreview) {
        this.startPreviewWatch();
      }

      eventBus.$on('price-schemes:create', this.pushToSchemes);
    }
  },

  beforeDestroy () {
    this.previewUpdate && this.previewUpdate.clear();
    eventBus.$off('price-schemes:create', this.pushToSchemes);
  },

  methods: {
    upperFirst,

    getCurrentPreview (data_sheet) {
      let data_preview = null;
      let data_preview_count;

      if (this.price?.data_preview) {
        const row = this.price.data_preview[data_sheet - 1];

        if (row) {
          data_preview = row.rows;
        } else {
          data_preview = this.price.data_preview[0].rows;

          setTimeout(() => {
            eventBus.$emit('set.data_sheet', 1);

            this.$notify.error({
              title: this.$t('errors.default.title'),
              message: this.$t('message.setDataPreviewSheen')
            });
          }, 500);
        }

        data_preview_count = this.price.data_preview.length;
      }

      return { data_preview, data_preview_count };
    },

    async load () {
      this.isPageLoaded = false;

      const business = this.$store.getters['businessProfiles/businessId'];
      const priceReq = this.$store.dispatch(
        'priceUploads/getPrice',
        { id: this.$route.params.id }
      );
      const salePointsReq = this.$store.dispatch(
        'salePoints/getSalePoints',
        { business }
      );
      const priceTagsReq = this.$store.dispatch(
        'priceTags/getPriceTags',
        { business_or_system: business }
      );
      const priceSchemesReq = this.$store.dispatch(
        'pricingSchemes/getPriceSchemes',
        { business }
      );
      const promises = [priceReq, salePointsReq, priceTagsReq, priceSchemesReq];

      try {
        const [priceUpload, salePoints, priceTags, priceSchemes] =
          await Promise.all(promises);

        const priceBusinessId = parsers.getIdFromUrl(priceUpload.business);

        if (business !== priceBusinessId || !priceUpload) {
          this.$nuxt.error({
            statusCode: 404,
            message: this.$t('labels.not_found')
          });
        }

        this.$set(this, 'price', this.getParsedPrice(priceUpload));
        this.$set(this, 'salePoints', salePoints);
        this.$set(this, 'priceTags', priceTags);
        this.$set(this, 'priceSchemes', priceSchemes);
      } catch (e) {
        console.error(e);
        this.$nuxt.error({ statusCode: 404 });
      } finally {
        this.isPageLoaded = true;
      }
    },

    getParsedPriceFile (price_file) {
      price_file.column_mapping = price_file.column_mapping
        .map((col) => {
          if (
            col.type === 'selling_price' &&
            Array.isArray(col.price_def.tags)
          ) {
            col.price_def.tags = col.price_def.tags[0];
          } else if (
            col.type === 'purchase_price' &&
            !isEmpty(col.price_actions)
          ) {
            col.price_actions = col.price_actions.map((it) => {
              if (it.price_def.tags && Array.isArray(it.price_def.tags)) {
                it.price_def.tags = it.price_def.tags[0];
              }

              return it;
            });
          }

          return col;
        });

      return price_file;
    },

    getParsedPrice (priceUpload) {
      if (priceUpload.price_file) {
        priceUpload.price_file = this.getParsedPriceFile(priceUpload.price_file);
      }

      return priceUpload;
    },

    loadWarehouses () { },
    setDefaultData () { },
    checkStatus () { },

    pushToSchemes (scheme) {
      this.priceSchemes.push(scheme);
    },

    async showFileReUploadConfirm () {
      const m = this.$t('errors.upload.message', { name: '' });
      const pl = {
        type: 'error',
        title: this.$t('errors.upload.title'),
        confirmButtonText: this.$t('buttons.upload')
      };

      this.isUploadDialog = await this.$confirm(m, pl);
    },

    updatePriceStatus (status) {
      this.price.status = status;

      if (this.isPriceError) {
        return this.showFileReUploadConfirm();
      }
    },

    updatePriceFile (priceFile) {
      this.price.price_file = priceFile;
    },

    updatePrice (price) {
      this.price = price;

      if (this.setDefaultData) {
        this.setDefaultData();
      }

      this.isFormatDialog = false;
      this.isUploadDialog = false;

      return this.startPreviewWatch();
    },

    async startPreviewWatch () {
      this.previewUpdate && this.previewUpdate.clear();

      if (!this.isPriceError) {
        this.previewUpdate = await watchAndLoadPreview(this);
      }
    },

    setActivity (isActive) {
      this.priceFile.is_active = isActive;
    },

    confirmMapping () {
      this.isConfirmMapping = true;

      this.$notify.warning({
        title: this.$t('message.warning'),
        message: this.$t('message.checkMapping')
      });
    },

    notifyInvalidMapping () {
      this.$notify.error({
        title: this.$t('errors.default.title'),
        message: this.$t('message.invalidMapping')
      });
    }
  },

  validate ({ params }) {
    return /^\d+$/.test(params.id);
  }
};
