<template>
  <div class="platforms-table">
    <el-table
      v-if="value"
      :data="value"
      :header-cell-style="{ textAlign: 'center' }"
      @row-click="navigate"
    >
      <el-table-column
        :label="$t('platform.status')"
        prop="is_active"
        width="100px"
      >
        <template #default="{ row }">
          <PlatformActivator
            :id="row.id"
            v-model="row.is_active"
            :market_id="row.marketplace_detail.id"
          />
        </template>
      </el-table-column>

      <el-table-column
        min-width="250px"
        :label="$t('platform.name')"
        prop="marketplace_detail.name"
      />

      <el-table-column
        align="center"
        :label="$t('platform.contract')"
        prop="status"
        width="150px"
      >
        <template #default="{ row }">
          {{ $t(`platform.statuses.${row.status || 'empty'}`) }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        width="150px"
        :label="$t('platform.subscriptionFee')"
        prop="marketplace_detail.name"
      >
        <template #default="{ row }">
          {{ getPriceWithCurrency(row.price) }}
        </template>
      </el-table-column>
    </el-table>

    <el-row justify="end" class="mt-1">
      <div class="total-sum">
        {{ $t('platform.subscriptionFee') }}*: {{ totalSum }}
      </div>
    </el-row>

    <div class="text-ghost mt-1">
      <p>{{ $t('platform.subscriptionFeeDescription') }}</p>
    </div>
  </div>
</template>

<script>
import PlatformActivator from './PlatformActivator';
import { getPriceWithCurrency } from '@/lib/core/services/parsers';

export default {
  name: 'PlatformsTable',

  components: {
    PlatformActivator,
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column')
    /* eslint-enable max-len */
  },

  props: {
    value: { type: Array, required: true }
  },

  computed: {
    totalSum () {
      const sum = this.value
        .reduce((acc, { price }) => acc + (Number(price) || 0), 0);

      return getPriceWithCurrency(sum);
    }
  },

  methods: {
    getPriceWithCurrency,

    navigate (row) {
      console.info(row);
      // this.$router.push({
      //   name: this.getRouteName('profile.finance.platforms.connect'),
      //   params: { id: row.marketplace_detail.id }
      // });
    }
  }
};
</script>

<style scoped lang="scss">
.platforms-table {
  max-width: 764px;

  .total-sum {
    font-size: 1rem;
    font-weight: bold;
  }
}
</style>
