<template>
  <div class="business-edit-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_edit') }}
    </h1>

    <TheLoader v-if="isLoading || !businessProfile" />

    <template v-else>
      <el-tabs
        type="card"
        :value="activeTab"
        @tab-click="toggleMenu($event.name)"
      >
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab"
          :label="$t(`profile.${tab}Tab`)"
          :name="tab"
        >
          <component
            :is="`Business${upperFirst(tab)}Tab`"
            class="mb-4"
            :entity="businessProfile"
            entity-name="profile"
            :submit="submit"
            @input="submit"
          />
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import BusinessMainInfoTab from './components/BusinessMainInfoTab';
import BusinessRequisitesTab from './components/BusinessRequisitesTab';
import { SalePointTrademarksTab as BusinessTrademarksTab } from '@/lib/salePoints';

export default {
  name: 'BusinessEditPage',

  components: {
    BusinessMainInfoTab,
    BusinessTrademarksTab,
    BusinessRequisitesTab
  },

  data: ({ $route }) => ({
    tabs: ['mainInfo', 'trademarks', 'requisites'],
    activeTab: $route.query.tab || 'mainInfo',
    isLoading: true,
    businessProfile: null
  }),

  beforeDestroy () {
    return this.updateCurrentUser();
  },

  mounted () {
    this.load();
  },

  methods: {
    upperFirst,

    async load () {
      this.isLoading = true;
      const businessId = this.$store.getters['businessProfiles/businessId'];

      try {
        this.businessProfile = await this.$store.dispatch(
          'businessProfiles/getBusinessProfile',
          { id: businessId }
        );

        this.isLoading = false;
        return this.businessProfile;
      } catch (e) {
        console.error(e);
        this.$nuxt.error(e);
      }
    },

    async submit (_formData) {
      try {
        this.businessProfile = await this.$store.dispatch(
          'businessProfiles/patchBusinessProfile',
          {
            id: this.businessProfile.id,
            formData: _formData
          });

        return this.businessProfile;
      } catch (e) {
        console.error(e);
      }
    },

    updateCurrentUser () {
      try {
        return this.$store.dispatch('users/updateCurrentUser');
      } catch (e) {
        console.error(e);
      }
    },

    toggleMenu (tab) {
      this.$router.push({ query: { tab } });
    }
  }
};
</script>
