<template>
  <div class="slider-block">
    <div class="mr-header">
      <div v-if="title">{{ title }}</div>
      <slot v-else name="title" />

      <el-button-group>
        <el-button icon="el-icon-arrow-left" @click="mainSwiper.slidePrev()" />
        <el-button icon="el-icon-arrow-right" @click="mainSwiper.slideNext()" />
      </el-button-group>
    </div>

    <div class="mr-body">
      <swiper ref="mainSwiper" class="gallery-top" :options="mainOptions">
        <swiper-slide v-for="slotName in slotNames" :key="slotName">
          <slot :name="slotName" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { breakpoints } from '@/plugins/breakpoints';

/**
 * <SliderBlock>
     <template #title>
       <div>
         <span class="custom-headline custom-headline--18 bold">
          {{ $t('ware.recommendedSubstitutes') }}
         </span>
         <br>
         <nuxt-link class="custom-link" :to="{ name: 'search', query: { q: ware.article }}">
          {{ $t('ware.showAllProposition') }}
         </nuxt-link>
       </div>
     </template>

     <template v-for="(analog, index) in analogs" v-slot:[`slide-${index}`]>
       <div class="mr-analogs" :key="index">
         <WareSmallCard
           :key="analog.is || analog._id"
           :value="analog"
           @basket-click="submitOrder"
         />
       </div>
     </template>
   </SliderBlock>
 *
 */

const epicOptions = {
  breakpoints: {
    [breakpoints.xl]: {
      slidesPerView: 5
    },
    [breakpoints.lg]: {
      slidesPerView: 4
    },
    [breakpoints.sm]: {
      slidesPerView: 3
    },
    [breakpoints.ss]: {
      slidesPerView: 2
    }
  }
};

export default {
  name: 'SliderBlock',

  components: {
    Swiper,
    SwiperSlide
  },

  directives: {
    swiper: directive
  },

  props: {
    title: { type: String, default: '' },
    options: { type: Object, default: () => ({}) }
  },

  data () {
    return {
      mainSwiper: null,
      mainOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        watchOverflow: true,
        ...epicOptions,
        ...this.options
      }
    };
  },

  computed: {
    slotNames () {
      return !isEmpty(this.$scopedSlots)
        ? Object.keys(this.$scopedSlots).filter(key => key.startsWith('slide-'))
        : ['slide-0'];
    }
  },

  mounted () {
    this.$nextTick(() => {
      const { mainSwiper } = this.$refs;
      this.mainSwiper = mainSwiper.$swiper;
    });
  }
};
</script>

<style scoped lang="scss">
.slider-block {
  ::v-deep .swiper-wrapper {
    height: unset;
  }

  .mr-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey-300;
  }

  .el-button-group {
    min-width: 116px;
  }
}
</style>
