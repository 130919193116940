export default (recipient, options = {}) => {
  const {
    last_name,
    first_name,
    middle_name,
    phone_number,
    recipient_first_name,
    recipient_last_name,
    recipient_middle_name,
    recipient_phone_number
  } = recipient;

  const last = recipient_last_name || last_name;
  const first = recipient_first_name || first_name;
  const middle = recipient_middle_name || middle_name;
  const phone = options.hidePhone
    ? { phone_number: '' }
    : recipient_phone_number || phone_number;

  return [last, first, middle, phone.phone_number].join(' ');
};
