export default function (vm, ware) {
  const {
    trademark,
    article_slug,
    ware_id,
    id
  } = ware;

  const tmSg = trademark?.slug || trademark || '';
  const acSl = article_slug || ware.ware?.article_slug || '';
  const wareId = ware.ware?.id || ware_id || id;

  if (!tmSg || !acSl || !wareId) {
    console.error(`THERE IS NO PARAM: tmSg-${tmSg}, acSl-${acSl}, wareId-${wareId}`);
    return vm.$route;
  }

  return {
    name: vm.getRouteName('wareId'),
    params: {
      meta: `${tmSg}-${acSl}`,
      wareId
    }
  };
};
