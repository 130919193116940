var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c(
        "div",
        { class: ["the-rate", { "disable-events": !_vm.$listeners.input }] },
        [
          _c("el-rate", {
            attrs: {
              "allow-half": "",
              value: Number(_vm.value) || 0,
              max: _vm.max,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }