export default {
  langName: 'English',
  countryCode: 'en',
  langCode: 'en',

  core: {
    yes: 'Yes',
    no: 'No',
    allReserved: '2018-{year} {mp}. Auto parts marketplace in Ukraine. Spare parts in your city.',
    loading: 'Loading',
    currency: "Currency",
    isYourCity: 'Is your city - {name}?',
    cityDescription: 'The availability of goods, methods and terms of delivery depends on the selected city.',
    selectCity: 'Select your city',
    pageInDevelopmentNow: 'Sorry, this section is under development.',
    grn: 'uah',
    num: 'units',
    siteRules: 'Terms of use of the site',
    offerRules: 'Offer contract',
    protectionPersonalInfo: 'Protection of personal data',
    questions: "Have you a question?",
    from: 'From',
    forSellers: 'For auto parts sellers',
    marketplaceAddress: 'Kyiv, st. Schumanovsky 2/1',
    language: 'Site language',
    contacts: 'Contacts',
    info: 'Information',
    payOnSite: 'Payment on the website',
    canIHelp: 'Need help?',
    weInSocial: 'We are in social networks',
    pageNum: 'page №{page}',
    companyAddress: 'Company address (admin parameter)',
    warning: 'warning',
    marketplaceDelivery: 'Delivery',
    marketplacePayment: 'Payment',
    marketplaceWarranty: 'Warranty and returns',
    on: 'on',
    purchase: 'Purchase'
  },

  currencies: {
    UAH: 'uah',
    RUB: 'rub'
  },

  auth: {
    enter: 'Enter',
    or: 'or',
    login: 'Email/phone',
    phone_number: 'Phone number',
    email: 'Email',
    password: 'Password',
    remember: 'Remember me',
    goRegister: 'Sign up',
    forgetPassword: 'Have you forgotten your password?',
    registerNewAccount: 'Registration',
    recoveryPassword: 'Password recovery',
    rememberPassword: 'I remembered the password',
    resendActivate: 'Resend the email',
    activateAccount: 'Account activation',
    iAmOnlyBy: 'I only buy auto parts for my cars',
    iAmSeller: 'I sell auto parts or repair cars',
    youCanByNow: 'Now you can buy on the website.',
    anyShopCanBy: 'Any auto shop or auto service without their prices can start trading on the platform.',
    registerAgreeWith: 'By registering, you agree to',
    siteRules: 'terms of the site',
    thanksForRegistration: 'Thank you for registering on our website! Account created',
    completionAuth: 'Thank you for registering on the site. To complete the registration, it remains to activate the account. This can be done by following the link that was sent to your mailbox, or by entering the ID by yourself. Now you can fully use the site. To log in to your account on the site, use the email and password specified during registration.',
  },

  profile: {
    selectProfile: 'Select a profile',
    buyer: 'Buyer',
    seller: 'Seller',
    sellerDescription: 'I am a seller of auto parts or a car service',
    addCompanyInfo: 'Add information about the company you work for. This will allow you to expand the possibilities on the site.',
    companyUserContacts: 'Contact details of the user in the company',
    setYourContactInfo: 'Enter your contact information, which will be used to contact you in cases where you will place an order or perform any actions on behalf of the company you are currently creating.',
    changeYourInfoToCompanyInfo: 'Personal data is entered by default. We recommend changing them to work contact data (corporate email and phone) so as not to mix personal with work.',
    sellIsEasily: 'Selling on the platform is easy!',
    mainInfoTab: 'General data',
    deliveryTab: 'Delivery',
    requisitesTab: 'Props',
    trademarksTab: 'Brand representative',
    mainInfoBlock: 'General information about the company',
    siteBlock: 'Site settings',
    mainRequisitesBlock: 'Details of the business entity',
    contactRequisitesBlock: 'Contact details for official communication',
    bankRequisitesBlock: 'Bank details',
    scanFilesRequisitesBlock: 'Downloading a scan copy of documents',
    removeBusinessConfirm: 'Are you sure you want to delete your business profile?',
    removeBusinessDescription: 'Delete profile forever. Only a profile user with administrator rights can delete a profile.',
    leaveBusinessConfirm: 'Are you sure you want to leave this business profile?',
    leaveBusinessDescription: 'If you are no longer an employee of the company, you can leave your profile. If you are the administrator of the profile, you can leave the profile only if you grant access rights to another user of the profile.',
    removeBusiness: 'Delete business profile',
    leaveBusiness: 'Leave business profile',
    name: 'Name',
    activities: 'Activity',
    isActive: 'Activated',
    isNotActive: 'Not activated',
    has_points_of_sale: 'Availability of points of sale',
    has_prices: 'Availability of prices',
    is_seller: 'Sale of spare parts',
    site: 'Site address',
    search_on_site_url: 'Link to search results',
    order_on_site: 'You can order goods on the website',
    searchOnSiteUrlDescription: 'Specify the URL in which it remains only to add the catalog number to search for the product. Then users will be able to go to your site from the platform site. For example, on the platform it is the URL <strong>{mp}/search?q=</strong>',
    _agreeWithPublicOffer: 'You agree with <a class="el-link el-link--primary" href="/docs/public-offer" target="_blank" @click.stop> terms of the public offer</a>',
  },

  account: {
    registrationBlock: 'Login to the site and communication',
    accountInfoBlock: 'Personal data',
    contactsBlock: 'Contact details in the profile',
    nameForSocialDescription: 'Appears to other users in comments and to sellers as a customer (if the name field is not filled in)',
    accountInfoDescription: 'It is used by default when placing orders, and is displayed to the seller when writing a review.',
    contactsDescription1: 'Enter your contact information, which will be used to contact you in cases when you will place an order or perform some actions.',
    contactsDescription2: 'Personal data is entered by default. We recommend changing them to work contact data (corporate email and phone) so as not to mix personal with work.',
  },

  salePoint: {
    setAddress: 'Indicate the address of the point of sale and add coordinates so that your point of sale is displayed on the map.',
    setPhones: 'Specify the organization\'s contact phone numbers, as well as their purpose (for example, Accounting).',
    setEmails: 'Specify the email that will receive notifications about profile events and explanations, if necessary (For example: for accounts).',
    salePoint: 'Sale Point',
    createSellPointFirst: 'Create a point of sale first.',
    setMinInfo: 'In order to create a point of sale, specify the minimum necessary data. Full data about the point of sale can be entered on the edit form after creation.',
    pointName: 'Name of point of sale',
    primary: 'Primary',
    status: 'Status',
    isActive: 'Store is active',
    mainInfoTab: 'General Data',
    deliveryTab: 'Delivery',
    saleTermsTab: 'Sale Terms',
    returnWarrantyTermsTab: 'Returns and Warranty',
    paymentAndDeliveryTab: 'Payment and Delivery',
    contactsTab: 'Contacts',
    trademarksTab: 'TM Representative',
    descriptionTab: 'Description',
    mainInfoBlock: 'General Point of Sale Data',
    addressBlock: 'Address',
    contactsBlock: 'Phones and Email',
    scheduleBlock: 'Working Hours',
    paymentTermsBlock: 'Order and Payment Terms',
    paymentMethodsBlock: 'Payment methods',
    deliveryMethodsBlock: 'Methods of delivery and delivery of goods',
    returnTermsBlock: 'Return Terms',
    warrantyTermsBlock: 'Warranty Terms',
    descriptionBlock: 'Description of the point in free form',
    photosBlock: 'Point of Sale Photos',
    otherSellingTermsBlock: 'Optional',
    allContactsBlock: 'Contacts',
    setCountryFirst: 'Specify the country first',
    setCountryAndAdminFirst: 'Set country and region first',
    selectSalePoint: 'Selection point of sale',
    description: 'About the company',
    comment: 'Comment',

    payment_methods_text: 'Additional payment terms',
    delivery_methods_text: 'Additional delivery terms',
    return_terms_text: 'Additional Return Terms',
    warranty_terms_text: 'Additional Warranty Terms',
    minPrice: 'Minimum Amount',
    minPriceDesc: 'Minimum amount for free shipping'
  },

  ware: {
    details: 'Details',
    wareCard: 'Ware Card',
    mainInfo: 'Main Info',
    characteristics: 'Characteristics',
    kits: 'Completeness',
    applicability: 'Application on cars',
    description: 'Description',
    wareId: 'CODE',
    wareSeller: 'Seller',
    mainCharacteristic: 'Main Characteristic',
    country_name: 'Country of manufacture',
    delivery: 'Delivery',
    payment: 'Payment',
    yourWare: 'Your Ware',
    characteristic: 'Characteristics',
    fromKits: '{name} is part of a kit in the following products',
    toKits: 'Kit {name}',
    showAllProposition: 'Show all prices',
    recommendedSubstitutes: 'Recommended Substitutes',
    article: 'Catalog number',
    trademark: 'Brand',
    yourShop: 'Your shop',
    photo: " — Photo #",
    selectCarFormApplicability: 'Select a make model to learn about applicability',
    characteristicDescription1: 'Characteristics and equipment may be changed by the manufacturer.',
    characteristicDescription2: 'Product color may vary due to monitor settings.',
    history: "History of article changes",
    is_former_use: 'used',
    isInOtherSalePoint: 'The product is available at other points of sale'
  },

  search: {
    filter: 'Filter',
    order: 'Sort',
    look: 'View',
    ware: 'Product',
    orderNumber: 'Order No. {id}',
    packageParams: 'Parameters of the product in the package',
    allCharacteristic: 'All Characteristics',
    selectProducer: 'Select a producer from the list',
    main: 'Main features',
    analogues: 'Analogues',
    kits: 'Kits',
    application: 'Auto application',
    variants: 'Variants',
    emptyResult: 'Unfortunately, we could not find any spare part by the IKN catalog number. Check the correctness of the catalog number. If you know that the auto part really exists, send the information to our specialists. After verification, it will be added to the search engine.',
    tm_name: 'Brand',
    nomenclature: 'Nomenclature',
    name: 'Name',
    article: 'Article',
    arc: 'Catalog Number',
    article_name: 'Description',
    amount: 'Amount',
    am: 'Quantity',
    city: 'City',
    address: 'Address',
    changed_from: 'Replaced from',
    changed_to: 'Changed to',
    brutto_x_sm: 'Size X (cm)',
    brutto_y_sm: 'Size Y (cm)',
    brutto_z_sm: 'Size Z (cm)',
    brutto_volume_sm: 'Volume, cm³',
    brutto_weight_kg: 'Weight, kg',
    size_sm: 'Dimensions, cm',
    formKits: '{name} is a kit that consists of elements',
    toKits: '{name} is a component in kits',
    optionsTitle: 'In what other form is the product found in information sources',
    foundOtherOptions: 'Found {count} other unchecked options',
    lineValue: '* - indicates the value of the product line',
    price: 'Price',
    priceInterval: 'Price Interval',
    seller: 'Seller',
    term: 'Term',
    delivery: 'Delivery',
    point_of_sale: 'Point of Sale',
    info: 'Info',
    formerUse: 'used goods',
    wareProp: 'Proposals for {name} and its substitutes',
    substitutes: 'Substitutes',
    in_stock: 'In stock',
    not_in_stock: 'Sold',
    todayIn: 'Today',
    tomorrow: 'Tomorrow',
    formDist: 'From the warehouses of the distributor',

    // SEARCH SETTINGS
    searchSettings: 'Advanced search settings',
    searchPriority: 'Map search priority',
    sortByDeliveryTime: 'Need fast, price secondary',
    sortByPrice: 'Need as cheap as possible, there is time to wait',
    '-rank-delivery_time-price': 'Priority by time',
    '-rank-price-delivery_time': 'Priority by price',
    showOnlyArticleOnMap: 'The map displays offers only from sellers who have physical points of sale',
    noResults: 'No offers found',
    searching: 'Searching...',
    noSell: 'Ordering is possible only through the sellers website',
    addToBasket: 'Add to basket',
    withVat: 'With VAT',
    showAllSellers: 'Show all sellers',
    noSelectedArticles: 'There are no offers for the requested article {article}',
    invitedArticle: 'Invited Article',
    invitedArticleAndAnalogs: 'Invited article and analogs',
    retailPrice: 'Retail Price',
    ascSellerAboutWare: 'Ask a question about the product',
    doSort: 'Sort',
    lowerCost: 'Cheaper',
    higherCost: 'More expensive',
    higher: 'Popular',
    searchTitle: "{search} - search results on {mp}",
    availabilityAtSeller: 'Availability at Seller',
    searchVariants: 'Search Variants',
    optimalPrice: 'Optimal Price'
  },

  orders: {
    order: 'Order',
    cancelOrderItem: 'Cancel order for this item',
    filter: 'Filter',
    onlyStatus: 'Only with status',
    resetFilters: 'Reset Filters',
    filterByStatus: 'Filter by status',
    orderActions: "Order Actions",
    contactAddress: 'Address and contact details',
    salePointInfo: 'Seller Info',
    thanksForOrder: 'Thank you for your order!',
    checkProgress: 'Track order status',
    forPermanentLink: 'For permanent link',
    forTelegramBot: 'In the Telegram channel. To do this, go to our bot now',
    forEmail: 'To email',
    deliveryMethod: 'Receive method',
    deliverer: 'Carrier',
    address: 'Address',
    service_office_number: 'Department',
    orderSum: 'Order Sum',
    stockQuantity: 'Maximum Quantity',
    fastOrder: 'Fast Order'
  },

  prices: {
    rePushConfirm: 'Are you sure that the file needs to be read again?',
    removeConfirm: 'Are you sure you want to remove price {name}?',
    saleYourWaresEasy: 'Sell off your leftovers quickly and conveniently.',
    priceLoadAction: 'Load your price and your vendor balances',
    priceLoadOption1Description2: 'For cases when the file being loaded is based on the price of a vendor unknown to the platform. Upload the price list with your balances and, if you wish, you can give permission to trade your goods to other sellers.',
    name: 'Price name',
    status: 'Status',
    finish: 'Finished, no errors',
    currency: 'Price currency',
    state: 'State',
    pushed: 'Update Date',
    lastUpdate: 'Last Update Date',
    delivery_time: 'Delivery time',
    formatting: 'File settings for importing data to the platform',
    uploadStatus: 'File Processing Status',
    uploadSource: 'Upload Source',
    fileSize: 'Size: {size} Mb',
    downloadFile: 'Download File',
    creationPriceScheme: 'Creating a new pricing scheme',
    order_time_until: 'Accepting orders until',
    warehousesRate: 'Delivery rate from warehouse',
    priceInQueueForUpdating: 'Price in queue for updating',
    step: 'Step',
    steps: [
      'File Upload',
      "Settings for working with the price",
      'Processing settings',
      "Inventories and delivery times",
      'Processing Results'
    ],
    statuses: {
      done: 'Downloaded',
      error: 'Error',
      in_progress: 'In progress',
      api_timeout: 'Timed out waiting for a response from the server',
      0: 'Unknown Priceload service error.',
      1: 'Unknown Priceload API service error.',
      '1_1_1': 'Error registering file download.',
      '2': 'Error processing price file.',
      '2_1': 'The file format is not supported.',
      '2_1_1': 'The archive format is not supported.',
      '2_1_1_1': 'Only 1 file in archive supported, {files_in_archive} received.',
      '2_1_2': 'File encoding {encoding} is not supported.',
      '2_1_3': 'Error reading CSV',
      '2_2': 'File mapping (mapping) error.',
      '2_2_1': 'Error mapping (mapping) file columns - mapping is not valid.',
      '2_2_2': 'The signature of the columns has changed in the price file.',
    },
    uploadSources: {
      handle: 'Manual',
      auto: 'Automatic'
    },
    selectUpdateMode: 'Select automatic/manual download method',
    manualUpdate: 'Manual',
    urlUpdate: 'By ftp or http, https',
    manualSettings: 'Setting automatic update via ftp or http, https',
    manualSettingsDescription1: 'Specify the full path to the file starting with ftp or http, https',
    manualSettingsDescription2: 'If you have ftp with a password, enter it in the URL according to the scheme ftp://login:password@<host>:port/path_to_the_file',
    docTypeMarkupDescription: 'Setting the automatic price upload to the platform can be done after successful processing of the file. You will also be able to change all other file settings.',
    error_code: 'Error code',
    error_message: 'Error message'
  },

  priceFile: {
    isActive: 'Show in search results',
    settingsTab: 'Price Settings',
    processingTab: 'File Processing',
    assortmentTab: 'Assortment',
    mainInfoBlock: 'General Info',
    mappingBlock: 'File handling settings',
    autoUpdateBlock: 'Auto Update',
    availabilitiesBlock: 'Setting warehouses and delivery time',
    uploadInfoBlock: 'Price file data',
    name: 'Price name',
    currency: 'Currency in file',
    dataStartRow: 'Number of the first data row',
    noHeaderCol: 'No column headers',
    rowWithTitles: 'Row number with column title',
    oneTradeMarkInPrice: 'One trade mark in price',
    noCounterInfoInPrice: 'No counter info in file',
    description: 'Description of the price',
    priceReUpload: 'Update price file',
    created: 'File creation date',
    updated: 'Upload date',
    quantity_warehouse_is_own: 'Price contains own balance',
    availabilitiesDescription: 'If the schedule of delivery of orders from the warehouse to the point of sale depends on the day of the week, then you can additionally configure these features of work using the calendar',
    availabilitiesDescription2: 'If the product is stored at the point of sale, then specify "Delivery time" 0 days 0 hours',
    firstTenRow: 'The first 10 rows of the file being loaded',
    auto: 'Automatically',
    file_encoding: 'File Encoding',
    csv_delimiter: 'Column delimiter',
    csvTitle: 'Configuring text file {name}',
    emptyPreview: 'No preview data available',
    waitPreview: 'File preview is generated. Do not close the window.',
    preview: "Preview page number",
    data_sheet: 'Processing page number',
    dataSheetDescription: 'Data will only be loaded from the specified page!',
    payment_terms_text: 'Subscription terms (terms will be displayed to buyers)',
    warrantedReturnTermsText: 'Warranty and Return Terms (terms will be displayed to buyers)',
    fullPrepaymentForPrice: 'Prepayment required to order at this price'
  },

  priceStatusesFields: {
    price_file_id: 'Price file ID',
    articles_count: 'Number of Articles',
    price_articles_count: 'Number of articles with prices',
    stock_articles_count: 'Number of articles with warehouses',
    updated: 'Update Date',
    recognized_articles_count: 'Number of recognized articles',
    offers_count: 'Number of offers',
    offers_refreshed: 'Updated offers',
    offers_fresh_count: 'Number of new offers',
    wares_count: "Number of identified goods",
    wareless_count: "Number of unidentified goods",
    stocks_count: "Number of items with non-zero balances"
  },

  orderStatuses: {
    'null': 'Cart',
    new: 'Created',
    in_progress: 'In progress',
    ready: 'Prepared',
    issued: 'Issued',
    autoended: 'Ended automatically',
    buyer_canceled: 'Cancelled by the buyer',
    buyer_rejected: 'Rejected by the buyer',
    seller_canceled: 'Cancelled by seller',
  },

  warehouses: {
    warehouse: 'Warehouse',
    creationWarehouses: 'Create warehouse',
    name: 'Name of warehouse'
  },

  priceMapping: {
    article: 'Catalog number',
    trademark: 'Trademark',
    title: 'Product name',
    description: 'Product description',
    selling_price: 'Selling price',
    purchase_price: 'Purchase Price',
    quantity: 'Quantity in stock',
    multiplicity: 'Multiplicity of sales',
    note: 'Additional product description',
    image: 'Link to product image',
    analog_article: 'Analog catalog number',
    analog_trademark: 'Analog trademark',
    barcode: 'Barcode',
    ucgfea: 'UCT ZED Code',
    wholesale: 'Wholesale',
    retail: 'Retail',
    createWarehouse: '+ Create Warehouse',
    remove_make_selling_price: 'Selling Price',
    isMappingProcessing: 'Mapping processing process. Wait...',
    image_type: 'Image Type',
    weight: 'Weight',
    weight_tare_type: 'Weight Type',
    sizes: 'Dimensions',
    sizes_tare_type: 'Type of dimensions'
  },

  weightUnitTypes: {
    'kg': 'Kilogram',
    'g': 'Gram'
  },

  sizesUnitTypes: {
    'mm': 'Millimetre',
    'cm': 'Centimeter',
    'm': 'Meter'
  },

  relationTypes: {
    'analog': 'Analog',
    'includes': 'Contains',
    'part_of': 'Is part of',
    'replaced_by': 'Replaced by',
    'replaces': 'Is a replacement',
    'improved_analog': 'Improved Analog',
    'simplified_analog': 'Simplified Analog',
    'universal_analog': 'Universal Analog',
    'specific_analog': 'Analog with refinement',
    'lays_inside': 'Lays inside',
    'is_container_for': 'Is a container',
    'couple': 'Pair article (left/right)',
    'used_alternative_code': 'Alternative code (used)',
    'unused_alternative_code': 'Alternative code (not used)'
  },

  tareTypes: {
    net: 'Net',
    brutto: 'Gross'
  },

  imageTypes: {
    general: 'Nomenclature Image',
    schema: 'Installation Schema',
    promo: 'Promo image',
  },

  deliveryRate: {
    deliveryRateCreating: 'Creating new rate',
    askAddRate: 'Add to the price of the product the price of delivery costs, from the suppliers warehouse to the point of sale?',
    notUseRate: 'Do not add',
    addByRate: 'Add by rate',

    creationNewRate: 'Creating a new rate',
    existName: 'Existing Tariff',
    name: 'The name of the tariff being created',
    currency: 'The currency of the parameters on the form',
    deliveryPrams: 'Parameters for accounting for logistics and other costs',

    correction_factor: 'Percentage of value',
    constant: 'Constant {name}',
    weight_rate: 'Rate for weight{name}',
    no_weight_factor: 'Percentage without weight',
    no_trade_without_weight: 'Do not trade without weight',
  },

  priceScheme: {
    type: 'Pricing scheme name',
    name: 'Pricing scheme name',
    currency: 'The currency of the parameters on the form',
    logisticParams: 'Parameters for accounting for logistic and other costs',
    selectPriceCreationScheme: 'Select a pricing scheme',
    dynamicPriceSchemeParams: 'Parameters for dynamic pricing',
    min_price: 'Minimum price, {name}',
    max_price: 'Maximum price, {name}',
    min_markup: 'Minimum markup, %',
    max_markup: 'Maximum markup, %',
    types: {
      static: 'Single markup regardless of price, %',
      dynamic: 'Markup depends on price'
    },
    removeConfirm: 'Are you sure you want to remove "{name}"?'
  },

  files: {
    uploadError: 'Upload error',
    validateError: 'The files are not valid',
    filePath: 'File path',
    fileName: 'File name',
    priceRules: {
      1: 'The file can be archived: zip, rar.',
      2: 'File size cannot be larger than 5 GB.',
      3: 'File formats accepted by our platform: {formats}.'
    },
    dropOrClick: 'Drag file here or click to download'
  },

  sellerTrademarks: {
    orAnd: 'and/or',
    sellerTrademarksDescription: 'If your organization is an official representative of the manufacturer and at the same time has direct supply contracts, then list the trademarks that your company represents. If possible, provide a link to the page of the official website of the manufacturer, on which there is confirmation of this fact, or provide a scan of the document.',
    sellerTrademarksConfirmDescription: 'After listing, please contact the platform representative to confirm official representative status. Only after confirmation on the platform, all offers from your personal inventory will be marked as goods from an official TM representative.',
    createTm: 'Add new trademark',
    editTm: 'Edit Trademark',
    trademark_name: 'Trademark Name',
    verification_url: 'Link to Trademark official site',
    verificationUrlDescription: 'Link to the page of Trademarks official website, where there is confirmation of the status of Trademarks official representative',
    verificationDocumentDescription: 'If there is no link to Trademarks official website to confirm the status, then download the document containing information about the official status of the scanned certificate, or a scan of the contract page with TM. The document will be available exclusively to the representative of the platform exclusively for the purpose of checking the status of the official representative!!!',
    verificationConfirmed: 'Confirmed',
    activeStatus: 'Status',
    statuses: {
      new: 'New',
      approved: 'Approved',
      rejected: 'Rejected'
    }
  },

  basket: {
    title: 'Basket',
    index: 'No',
    important: 'Important',
    orders: 'Order',
    doYouHaveAccount: 'Have an account on our site?',
    remove: {
      title: 'Removing the contents of the shopping cart',
      message: 'Are you sure you want to delete the entire contents of the shopping cart?'
    },
    count: 'Amount',
    sum: 'Sum: {sum}',
    toBePaid: 'To be paid',
    total: 'Total',
    sumAllOrders: 'Total Cart Value',
    tableDescription: {
      1: "Delivery time is specified in business days to the seller's point of sale.",
      2: "The final price may differ from the one displayed on the website due to the discrepancy in the exchange rate and/or due to the presence of additional payments for delivery and/or bank commission.",
      3: 'The item in the cart is not reserved by the seller.',
    },
    display_article: 'Nomenclature',
    display_title: 'Description',
    delivery_time: 'Term',
    paidStatus: 'Payment Status',
    is_paid: 'Paid',
    price: 'Price',
    quantity: 'Quantity',
    quantity2: 'Quantity',
    totalAmount: 'Total: {cur}',
    status: 'Status',
    buyer: 'Buyer',
    buyer_type: 'Who will be the buyer',
    freeShipping: 'Free Shipping',
    buyerTypes: {
      person: 'Private person',
      organization: 'Organization'
    },
    additionally: 'Additionally',
    sellerPhones: 'Phones',
    buyer_first_name: 'My first name',
    buyer_last_name: 'Last Name',
    buyer_phone_number: 'Buyer phone',
    buyer_email: 'Email of the buyer',
    salePointAddress: 'Address of point of sale',
    orderAddress: 'Order receiving address',
    delivery_method: 'Order delivery method',
    delivery_address: 'Order delivery address',
    createAddress: '+ Add new address',
    orderAddressCreation: 'Receive Address Creation',
    deliveryService: 'Delivery Service',
    deliveryServiceName: 'Service Name',
    deliveryRegion: 'Region',
    deliveryCity: 'City',
    orderRecipient: 'Recipient',
    recipient_phone_number: 'Phone',
    recipient_last_name: 'Last Name',
    recipient_first_name: 'My first name',
    paymentMethod: 'Payment',
    payment_method: 'Payment method',
    commentForm: 'Comment to the order',
    no_call_required: 'Call only if something goes wrong',
    agreementForm: 'Agreement to the terms of order execution',
    _agreeWithPublicOffer: 'By ordering, you agree to the <span class="el-link el-link--primary"> terms of the public offer</span>',
    selectAddressType: 'Select an address type',
    selectDeliveryService: 'Select Service',
    service_name: 'Service Name',
    setServiceName: 'Specify a name',
    service_office_number: 'Office Name',
    orderNum: 'order number',
    basketIsEmpty: 'Basket is empty',
    seller: 'Seller',
    ware: 'Product',
    priceFile: 'Price',
    shipment: 'TTN',
    availability: 'Availability',
    display_public_id: 'Order Number',
    orderDetailsAndTerms: 'Order details and terms',
    rejectOrderProcessing: 'Rejection of order processing',
    setCancelInitiator: 'Indicate who was the initiator of canceling the order',
    orderRecipientCreate: 'Create Order Recipient',
    orderShipment: 'Order Issuance',
    currentClientShipments: 'Current Client Shipments',
    waybillTitle: 'Waybill',
    waybill: 'Waybill number',
    waybillNum: 'Number',
    waybill_date: 'Created Date',
    addressesInItems: 'Addresses that were in orders',
    shipmentComment: 'Order execution note. Will be displayed to the Buyer',
    shipmentCreateDate: 'Issue Date',
    prev_price: 'Previous Price',
    addShipment: '+ Add new shipment',
    wareAddToBasket: 'Item added to basket',
    createOrder: 'Order creation',
    buyerData: 'Buyer Data',
    agreeWithPublicOffer: 'By placing an order, I agree <br> with the <a class="el-link el-link--primary" href="{href}" target="_blank" @click.stop>terms of the Public Offer Agreement</ a>',
    oneClickBuyDesc: 'Just leave your number, on which the seller will call you back and clarify the terms of the order.',
    setPhoneNumber: 'Specify phone number'
  },

  catalog: {
    brand: 'Car brand',
    model: 'Model',
    generation: 'Generation',
    production_year: 'Year of production',
    body_type: 'Body Type',
    drive_type: 'Drive',
    engine_type: 'Engine Type',
    engine_code: 'Engine Code',
    capacity: 'Engine volume',
    fuel_type: 'Fuel Type',
    fuel_types_and_capacity: 'Engine',
    modification: 'Modification',
    garage: 'Garage',
    nowTime: 'current time',
    ls: 'ls',
    catalog: 'Auto parts catalog',
    catalogFor: 'Catalog - ',
    autoParts: 'Parts',
    for: 'to',
    on: 'on',
    in: 'in',
    allCars: 'All Cars',
    autoPartsForAllCars: 'Parts for All Cars',
    autoPartsForCars: 'Car Parts',
    allCarsModels: 'All Models',
    allPartsFor: 'All parts for {name}',
    allTrademarks: 'All manufacturers',
    trademarks: 'Producers',
    topProducts: 'Top Products',
    topCarsBrands: 'top car brands',
    topCarsModels: 'top cars {name}',
    topCarsGenerations: 'top car generations {name}',
    topCarsModifications: 'top car modifications {name}',
    topProductLines: 'Popular parts | Popular parts for {name}',
    topTrademarks: 'Popular Manufacturers {name}',
    topRegions: 'Buy {name} in cities of Ukraine',
    pricesFor: 'Prices for',
    auto: 'cars',
    readMore: "Read full",
    vinInquiries: 'Inquiries by VIN for selection'
  },

  garage: {
    removeConfirm: 'Are you sure you want to remove {name} permanently? (the operation cannot be canceled)',
    requestToSelectionByVin: 'Request for selection of spare parts by VIN',
    requireVinForSelectionRequest: 'VIN of the vehicle must be specified to complete a request for the selection of spare parts',
    requireCarForVin: 'You need to select a car to make a request for selection by VIN',
    selectionRequest: 'Selection request',
    requestCreated: 'Request sent',
    answersCount: 'Total answers',
    expiry: 'Policy valid until {date}',
    autoPartsForTO: 'Parts for TO',
    originalCatalog: 'Original Catalog',
    engine: 'Engine (volume, power)',
    cubSm: 'Cube. see',
    characteristics: 'Characteristics',
    auto: 'car',
    statusSelect: 'Auto status selection',
    color: 'Color',
    chooseYourCar: 'Choose a car',
    chooseYourCarForStart: 'Start search for spare parts from car selection',
    startWithCarBrand: 'Start with car brand selection',

    selectTitles: {
      brand: 'Start searching for spare parts by selecting a car brand',
      model: 'Select a model',
      production_year: 'Select the year',
      body_type: 'Select the body',
      fuel_types_and_capacitie: 'Select the engine',
      drive_type: 'Select the drive',
      modification: 'Select the modification'
    },

    selectorDesc: {
      production_year: 'Produced in the following years:',
      body_type: 'It was produced in <strong>{year}</strong> with the following body types:',
      fuel_types_and_capacity: '<strong>{year}, {bodyType}</strong> were produced with the following engines:',
      drive_type: '<strong>{year}, {bodyType}, {fuelTypesAndCapacitie}</strong> were produced with the following drives:',
      modification: 'Released in the following modifications'
    },

    selectionRequests: {
      rawItem: 'Required spare part',
      itemComment: 'Comment to row',
      comment: 'Comment to request',
      answersCount: 'Answers'
    },

    factory: {
      brand: "Brand of the car manufacturer",
      model: "Model",
      productionYear: "Production Year",
      bodyType: "Body type",
      engineType: "Engine Type",
      fuelType: "Fuel Type",
      engineCapacity: "Engine capacity",
      modification: "Modification",
      engineCode: "Engine Code",
      powerPs: "Engine power",
      gearType: "Gearbox",
      driveType: "Drive type",
      brakeType: "Brake Type",
      cylinders: "Number of cylinders",
      gearSteps: "Number of gears",
    },

    main: {
      vin: "VIN",
      licensePlate: "Government number",
      insuranceExpiryDate: "Policy valid until",
      color: "Color",
      comment: "Comment to the car",
      commentDesc: '(no one will see but you)'
    },

    securityTitle: 'Security',

    security: {
      sHasAbs: 'ABS',
      sHasAsr: 'ASR',
      sHasEsp: 'ESP',
      sHasAbd: 'ABD',
      sCentralLock: 'Central Lock',
      sAirbag: 'Airbag',
      sSignalling: 'Signalling',
      sImmobilizer: 'Immobilizer',
      sHeadlight: 'Headlight Type',
      sHasBsm: 'Dead Zone Assistant',
      sHasNv: 'Night Vision',
      sHasGearLock: 'Gearlock Lock',
      sHasAirSuspension: 'Air suspension',
      sHasArmoredBody: 'Armored Vehicle',
    },

    securityOptions: {
      sCentralLock: {
        no: 'None',
        original: 'Full-time',
        unoriginal: 'Unoriginal'
      },

      Airbag: {
        no: 'None',
        driver_only: 'Driver only',
        several: "Several"
      },

      sSignalling: {
        no: 'None',
        original: 'Statna',
        unoriginal: 'Unoriginal'
      },

      sImmobilizer: {
        no: 'None',
        original: 'Statna',
        unoriginal: 'Unoriginal'
      },

      sHeadlight: {
        halogen: 'Halogen',
        xenon: 'Xenon',
        bixenon: 'Bixenon',
        adaptive_xenon: 'Adaptive Xenon',
        diode: 'Diode'
      }
    },

    multimediaTitle: 'Multimedia',

    multimedia: {
      mHasMp3: 'MP3',
      mHasCd: 'CD',
      mHasTapeRecorder: 'Tape Recorder',
      mHasAcoustics: 'Acoustics',
      mHasDvd: 'DVD',
      mHasGps: 'GPS Navigation System',
      mHasSubwoofer: 'Subwoofer',
      mHasBluetooth: 'Bluetooth',
      mHasCarPlay: 'CarPlay',
      mHasAndroidAuto: 'Android Auto',
    },

    comfortTitle: 'Comfort',

    comfort: {
      cHasPowerSteering: 'Power Steering',
      cWindowLifters: 'Glass lifter',
      cHasRoofRails: 'Rails',
      cHasParkAssist: 'Parking Assistant',
      cHasLaneAssist: 'Lane Assistant',
      cHasConditioner: 'Conditioner',
      cHasMirrorsHeating: 'Heating mirrors',
      cHasMirrorsDimming: 'Mirrors Dimming',
      cClimateControl: 'Climate Control',
      cSeatsHeating: 'Heated Seats',
      cHasMultimediaSteering: 'Multimedia steering wheel',
      cHasCruiseControl: "Cruise Control",
      cHasParktronic: 'Parktronic',
      cHasLightSensor: 'Light Sensor',
      cInteriorMaterial: 'Interior upholstery material',
      cHasRainSensor: 'Rain Sensor',
      cSunroof: 'Sunroof',
      cHasHeadlightWasher: 'Headlight Washer',
      cHasStartButton: 'Start button',
      cHasSeatMemory: 'Seat memory',
      cHasSteeringHeating: 'Steering wheel heating',
      cHasSummerTires: 'Summer Tires',
      cHasWinterTires: 'Winter Tires',
      cHasGlassTinting: 'Glass Tinting',
      cHasTowbar: 'Towbar',
      cHasGasInstallation: 'Gas Equipment',
    },

    comfortOptions: {
      cInteriorMaterial: {
        fabric: 'Fabric',
        velor: 'Velour',
        leather: 'Leather',
        alcantara: 'Alcantara'
      },

      Sunroof: {
        no: 'None',
        sunroof: 'Sunroof',
        panorama: 'Panorama'
      },

      cWindowLifters: {
        manual: 'Manual',
        mixed: 'Front electric, rear manual',
        electronic: 'Electro'
      },

      cSeatsHeating: {
        no: 'None',
        front: 'Front',
        back: 'Back',
        all: 'All'
      },

      cClimateControl: {
        no: 'None',
        '1-zone': 'One zone',
        '2-zone': 'Two zones'
      }
    },

    characteristicsTitle: 'Technical characteristics',

    // Display status
    statuses: {
      mine: 'Own to me now (initial status)',
      not_mine: 'The car does not belong to me',
      on_sale: 'Sale',
      given: 'Given (gave to spdoc)',
      stolen: 'Theft',
      broken: 'Road accident. Not subject to recovery',
      utilized: 'Utilization',
    }
  },

  sRequests: {
    requestSubmit: 'Selection request #{id} sent',
    requestSubmittedDesc: 'Wait for responses from auto parts sellers, and then choose the best one from the options offered.',
    showRequestLinkDesc: 'View responses without registration to a VIN request via a permanent link',
    createRequestByVin: 'Find auto parts with the help of professionals',
    createRequestByVinDesc: `
       <p>If you don't understand how to choose a spare part, or you are hesitating which part option is right for your car, then take advantage of the FREE help of professional auto parts sellers. Creating a selection request is very simple:</p>
       <ol>
         <li>Indicate data about your car</li>
         <li>Specify the parts you need</li>
         <li>Send your inquiry to professional auto parts sellers</li>
         <li>Get answers from multiple sellers</li>
         <li>Choose the best offer and order</li>
       </ol>
     `,
    myCity: 'My town',
    price: 'Sales price, {val}',
    delivery_time: 'Delivery time, days',
    requestSend: 'Sent',
    sRequestNum: 'Request #{id}',
    public_id: 'VIN Request Number',
    allCarsModels: 'All car brands',
    created: 'Request sent: {date}',
    comment: 'Buyers comment on the request',
    responseExitConfirm: 'Do you want to cancel the response?',
    responseProcessingExitConfirm: 'In case of cancellation, all offers will be deleted. Are you sure you want to cancel request processing?',
    comment_private: 'Supplier, purchase price, other conditions',
    commentPrivateDesc: 'The text will not be visible to the buyer. The field is available exclusively to the seller for internal use.',
    comment_public: 'Comment for buyers',
    hideArticlesForBuyer: 'Do not show catalog numbers to buyer',
    customWareSelect: 'The required product does not exist. Enter the data yourself',
    emptySearchText: 'No products found in your price lists for {name}',
    selectWareFromPrises: 'Select a product from prices',
    sellerComment: 'Sellers Comment',
    emptyResponse: 'No response',
    answerTime: 'Answer time',
    userContacts: 'Contact Details',
    userContactsDesc: 'Contact details will not be shared with auto parts sellers and will only be used to inform you of new seller responses',
    userContactsPhoneDesc: 'A notification will be sent about the result of processing the request',
    telegramBotDesc: 'Monitor Telegram channel request processing status',
    steps: [
      'Specify car data',
      'Specify required spare parts',
      'Specify contact details'
    ]
  },

  cleverSearch: {
    searchIn: 'Search in',
    searchPlaceholder: 'Product, car brand or model, manufacturer, article number',
    specifyCategory: 'Please specify a category',
    article: 'Found in articles',
    car_brand: 'Go to brands',
    car_model: 'Go to model',
    product_line: 'Go to category',
    tm: 'Go to trademarks',
  },

  questions: {
    title: 'Questions and answers',
    search: {
      title: '🚘 How to choose {tl} for your car?',
      answer: 'You can choose {tl} using the car selection form: choose the make, model of the car, the year of manufacture and the type of your engine. And you can also use the search form by the catalog number of the spare part.'
    },
    popular: {
      title: "🔝 Which brands are the most popular in the {tl} group?",
      answer: "The most popular manufacturers:"
    },
    topForYear: {
      title: "🥇 What are the most popular products in the {tl} group in {year}?",
      answer: "TOP-5 popular products in {year}:"
    },
    price: {
      title: "💵 What are the prices in {mp} for {tl}?",
      answer: "Price range for {tl} from {minPrice} to {maxPrice} UAH."
    }
  },

  requirements: {
    actualData: "By saving the data you confirm that it is valid and up-to-date",
    accept_term: "I agree to the terms of the contract",
    print_document: "Print contract",
    send_connect: "Send connection request",
    physical_person: "Physical Person ",
    entrepreneur: "Individual entrepreneur",
    juridical_person: "Legal Person",
    bank: {
      name: "Name of the bank",
      mfo: "Bank's MFO",
      iban: "IBAN"
    },
    contact: {
      address: "Actual address",
      email: "Email",
      phone: "Phone",
      copy: 'Copy'
    },
    scan: {
      stateRegister: "Extract from the Unified State Register (1 page)",
      stateRegisterDesc: '(or 1 + 2 pages in one file)',
      stateRegister2: "Extract from the Unified State Register (2 page)",
      accountOpen: "Account opening help",
      accountOpenDesc: '(or certificate of bank details)',
      pdv: "Extract from the register of VAT payers"
    },
    info: {
      type: 'Form of ownership',
      firstName: "First Name",
      lastName: "Last Name",
      middleName: "Patronymic",
      itn: "TIN",
      address: "Legal address",
      officialName: "Official Company Name",
      shortName: "Abbreviated name of the enterprise",
      pdv: "VAT payer",
      certificate: "Certificate number of VAT payer",
      egrpoy: "EDRPOU"
    },
    signatory_full_name: 'Signatory Full Name'
  },

  platform: {
    choiceOfWorkingConditionsOnSites: 'Choice of working conditions on sites',
    selectConditions: 'Select rates for sites',
    platformListDescription: 'Choose the rates at which you plan to work on the platforms. Tariffs and the number of lines in the prices can be changed at any time.',
    connect_description: "To start working on the site <strong>{business_name}</strong>, it is necessary to conclude an Agreement with the company {mp}. To conclude an agreement, enter the details of the company, print the agreement in two copies, sign and send it to the address <strong> {address}</strong>",
    name: "Name of the site",
    contract: "Agreement",
    status: "Status",
    subscriptionFee: 'Subscription fee',
    subscriptionFeeDescription: 'The subscription fee is charged for every 30 days of work on the site. Fees are paid separately for conversion actions according to the tariffs',
    statuses: {
      approved: "Active",
      await_confirmation: "Awaiting confirmation",
      rejected: "Rejected",
      empty: "Missing"
    },
    signContractDescription: 'By sending a connection request, you agree to the terms of the selected tariffs.'
  },

  balance: {
    currentBalance: 'Current balance on',
    upBalance: 'Top up balance',
    enterAmount: 'Enter the top-up amount',
    generateInvoice: 'Generate an invoice for cashless payment',
    balance: 'Balance',
    dateTime: 'Date and Time',
    type: 'Type',
    order: 'Order',
    article: 'Article',
    price: 'Price',
    quantity: 'Quantity',
    base: 'Amount',
    rate: 'Royalty%',
    rateAmount: 'Royalty',
    status: {
      refill: 'Replenishment',
      write_off: 'Writing off',
      accrual: 'Accrual',
      refund: 'Return'
    }
  },

  carBodyTypes: {
    1: 'Slanted Rear',
    2: 'Sedan',
    3: 'Coupe',
    4: 'Cabriolet',
    5: 'Universal',
    6: 'Wen',
    7: 'Special body',
    8: 'All-terrain vehicle closed',
    9: 'Pickup',
    10: 'Van',
    11: 'C on-board platform / undercarriage',
    12: 'Bus',
    13: 'Van / station wagon',
    14: 'Targa',
    15: "All-terrain vehicle is open",
    16: "Dump Truck",
    17: "A car for communal needs",
    18: 'Tractor',
    19: "Single-axle tractor",
    20: 'Body with a hard top',
    21: 'Van / hatchback',
    22: 'Motorcycle',
    23: 'Tractor',
    24: "Concrete mixer",
    25: 'Tractor'
  },

  carEngineTypes: {
    1: 'Gasoline engine',
    2: 'Diesel',
    3: 'Electric motor',
    4: 'Hybrid',
    5: 'Wankel engine',
    6: 'Gasoline engine (two-stroke)',
    7: 'Hybrid (carburetor engine/electric motor)'
  },

  carFuelTypes: {
    1: 'Gasoline',
    2: 'Diesel',
    3: 'Gasoline / natural gas (cng)',
    4: 'Gasoline / autogas (lpg)',
    5: 'Gasoline / electricity',
    6: 'Natural gas',
    7: 'Gasoline / ethanol',
    8: 'Mixture',
    9: 'Electric. - diesel fuel',
    10: 'Alcohol',
    11: 'Liquefied gas',
    12: 'Electricity',
    13: 'Gasoline / ethanol / electricity',
    14: 'Flexfuel / electric',
    15: 'Hydrogen',
    16: "Gasoline / ethanol / natural gas",
    17: 'Diesel / natural gas (cng)',
    18: 'Gasoline / electricity / autogas (lpg)',
    19: 'Gasoline / natural gas (lpg)'
  },

  carGearTypes: {
    2: 'Manual gearbox',
    // 1: '5-speed manual gearbox',
    // 3: 'Manual gearbox 4-speed',
    // 7: '6-speed manual gearbox',
    11: "Variator",
    // 5: 'Fully Automatic Transmission',
    8: "Automatic gearbox",
    // 13: 'Cvt-automatic transmission (no steps)',
    // 4: "3-speed automatic transmission",
    // 10: "4-speed automatic transmission",
    // 12: '5-speed automatic transmission',
    // 14: "6-speed automatic transmission",
    // 15: 'Transmissions with dual clutch 7-speed',
    // 16: '8-speed automatic transmission',
    // 9: 'Stepped / optional automatic transmission',
    // 6: 'Tape with push links (stepless)',
    17: 'The Robot'
  },

  carDriveTypes: {
    1: 'Front wheel drive',
    2: 'Rear wheel drive',
    3: 'All Wheel Drive',
    4: 'Direct',
    5: 'Chain',
    6: 'Cardan',
    7: 'Variator',
    8: 'The Belt'
  },

  labels: {
    error: 'Error',
    avatar: 'Avatar',
    logo: 'Logo',
    phone_number: 'Phone',
    last_name: 'Last Name',
    middle_name: 'Parental',
    first_name: 'Your first name',
    email: 'Email',
    password: 'Password',
    password2: 'Repeat password',
    recovery_key: 'Activation Key',
    captcha: 'Captcha',
    facebook: 'Facebook',
    google: 'Google',
    companyName: 'Company Name',
    isSeller: 'Does your company trade in auto parts?',
    point_sale_activities: 'Point of sale activities',
    address: 'Address',
    noAddress: 'No physical address for customer contact',
    country: 'Country',
    admin1: 'Area',
    city: 'City',
    street: 'Street',
    building: 'House',
    flat: 'Office',
    landmarks: 'Landmarks that will help you find the specified address faster',
    phone: 'Phone',
    phones: 'Phones', //
    recipientPhone: 'Recipients phone',
    main: 'Main',
    workDays: 'Work mode',
    startWork: 'Start Work',
    endWork: 'End of work',
    timeSeparator: '-',
    returnIsPossible: 'Return possible',
    guarantee: 'Guarantee',
    contact_name: 'Surname, first name',
    salePointName: 'Sale Point Name',
    is_return_possible: 'Return possible',
    is_warranted: 'Warranted',
    nameForSocial: 'Your name (For Social)',
    login: 'Login',
    detail: 'Details',
    non_field_errors: 'Invalid data',
    searchInPrice: 'Search in prices',
    exactArticle: 'Only the article you searched for',
    status: 'Status',
    mains: 'Main',
    priceGrn: 'Price',
    delivery_time: 'Delivery time',
    payment_methods: 'Payment methods',
    delivery_methods: 'Delivery methods',
    warrantedAndReturn: 'Warranty and Return',
    other_selling_terms: 'Additional',
    payment_terms: 'Payment Terms',
    sale_activities: 'Seller Activities',
    in_stock: 'In stock',
    localOffers: 'Local Offers',
    is_official_trademark: 'From the warehouse of the distributor',

    auto_update_url: 'File path',
    seller: 'Seller',
    buyer_phone_number: 'Buyer phone',
    recipient_phone_number: 'Recipients phone number',
    buyer_email: 'Email of the buyer',
    buyer_phone: 'Buyers phone',

    not_found: 'The product is no longer sold by this seller',
    price_changed: 'Price changed',
    quantity_changed: 'Quantity changed',
    terms_changed: 'Terms have changed',
    info_changed: 'Product information has changed',
    insufficient_stock: 'Quantity changed',
    buyer: 'Buyer',
    reloadPageByTermsChange: 'During placing an order, the terms have changed (prices, quantity, availability). Check that the new conditions suit you and send the order to work again. If the conditions do not suit you, a new search will be made.',
    created: 'Created',
    column_mapping: 'Mapping error',
    terms_urls: 'Resource posts',
    buyer_first_name: 'Buyers first name',
    buyer_last_name: 'Buyer last name',
    payment_method: 'Payment method',
    delivery_method: 'Order delivery method',
    delivery_address: 'Order delivery address',
    basket: 'Basket',
    items: 'Goods',
    empty: 'empty',
    _schema: 'Query Schema',
    offer_id: 'Product ID',
    activation_key: 'Activation key'
  },

  sale_activities: {
    store: 'Car store (Internet store)',
    service_station: 'STO',
    warehouse: 'Distributors wholesale warehouse',
    disassembly: 'Disassembly'
  },

  payment_terms: {
    full_prepayment: 'Full prepayment (100%)',
    late_payment: 'Delayed payment',
    on_credit: 'On credit',
    partial_prepayment: 'Partial prepayment',
    payment_upon_receipt: 'Payment upon receipt at the point of sale',
    postpay_on_carrier: 'Postpay on carrier',
  },

  payment_methods: {
    card: 'Bank card',
    cashless_payment: 'Cashless payment',
    cash_payment: 'Cash',
    card_to_card: 'Card to card',
    cash_on_delivery: 'Payment on delivery at the carrier',
    on_credit: 'On credit'
  },

  delivery_methods: {
    free_shipping: 'Free Shipping',
    carrier_in_country: 'Transport company',
    courier: 'Sellers courier',
    pickup: 'Self-pickup from the point of sale',
    regular_bus: 'Service buses',
    taxi: 'Taxi',
  },

  deliveryTypes: {
    service_office: 'Delivery Service Office',
    address: 'Delivery to address'
  },

  deliveryServicesType: {
    service_office: 'Transport company (per branch)',
    address: 'Transport company (at address)'
  },

  deliveryServices: {
    'novaposhta_ua': 'New Post',
    "nexpress_com_ua": 'Night Express',
    "ukrposhta_ua": 'Ukrposhta',
    "gunsel_ua": 'Günsel',
    "autolux_ua": 'Autolux',
    "sat_ua": 'SAT',
    "justin_ua": 'Justin',
    'meest_com': 'MeestExpress',
    'other': 'Other'
  },

  other_selling_terms: {
    vat_payer: 'VAT payer',
    free_shipping: 'Free Shipping',
    price_without_hidden_fees: 'Price without hidden fees',
    return_possible: 'Return possible',
    warranted: 'Warranty',
    with_installation_only: 'Sale with installation',
    former_use: 'Used product',
    does_not_conduct_activities: 'Does not conduct activities',
    return_not_possible: 'Return is not possible'
  },

  phoneChannels: {
    phone: 'Phone',
    sms: 'SMS',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp'
  },

  routes: {
    menuBusiness: 'Menu Autobusiness',
    profileChange: 'Change Profile',
    city: 'City',
    home: 'Home',
    search: 'Search by article',
    catalog: 'Catalog',
    catalog_search: 'Catalog Search',

    finance: "Finance",
    enter: 'Enter',
    exit: 'Exit',
    account: 'Account',

    article: '',
    sale_point: 'Sale Point',
    errors: '',

    auth: 'Authentication',
    auth_activation: 'Account activation',
    auth_completion: 'Authentication Completion',
    auth_pass: 'Password Recovery',
    auth_pass_send: 'Password Recovery',
    auth_pass_recovery: 'Password Recovery',

    basket: 'Basket',
    basket_order_create: 'Create new order for seller',
    basket_order_created: '',
    basket_order_created_hash: '',
    basket_order: 'Order',
    basket_order_hash: 'Order',
    basket_orders: 'My Orders',

    profile: 'Profile',
    profile_business: 'Business',
    profile_business_create: 'Create Business Profile',
    profile_business_edit: 'Business Settings',
    profile_business_seller_orders: 'Seller Orders',
    profile_business_seller_orders_order: 'Order',

    profile_business_sale_points: 'Points of Sale',
    profile_business_sale_points_create: 'Creating a point of sale',
    profile_business_sale_points_edit: 'Edit Point of Sale',

    profile_business_prices: 'Prices',
    profile_business_prices_upload: 'Upload',
    profile_business_prices_create: 'Create',
    profile_business_prices_edit: 'Edit',

    profile_business_platforms: 'Places',
    profile_business_platforms_connect: 'Apply',
    profile_business_platforms_contract: 'Public Offering Contract',
    profile_business_balance: 'Balance',

    profile_business_s_requests: 'Requests for matching by VIN',
    profile_business_s_requests_edit: 'VIN request processing',

    garage: 'Garage',
    garage_create: 'Create car',
    garage_edit: 'Edit car',
    garage_requests: 'Requests for selection by VIN',
    garage_requests_create: 'Create request',
    garage_requests_created: 'VIN Request',
    garage_requests_response: 'Response to VIN request',
    garage_responses: 'Responses to VIN queries',

    vin_requests_master_create: 'Find auto parts with the help of professionals',
    vin_requests_master_created: 'VIN request created',
    vin_requests_hash: 'Responses to VIN requests',

    test: 'Test Page',
    development: '',
    docs: 'Documentation',
    about_us: 'About us',
  },

  buttons: {
    copy: 'Copy',
    back: 'Back',
    next: 'Next',
    edit: 'Edit',
    no: 'No',
    yes: 'Yes',
    create: 'Create',
    goEnter: 'Enter',
    clear: 'Reset',
    apply: 'Apply',
    google: 'Sign in with Google',
    facebook: 'Sign in with Facebook',
    recoveryPassword: 'Recover Password',
    send: 'Send',
    activate: 'Activate',
    save: 'Save',
    cancel: 'Cancel',
    finish: 'Finish',
    remove: 'Remove',
    nix: 'Cancel',
    confirm: 'Confirm',
    select: 'Choose',
    continue: 'Continue',
    continueShopping: 'Continue shopping',
    change: 'Change',
    addSome: 'Add {name}',
    clearBasket: 'Clear Basket',
    selectDeliveryAndPayment: 'Select delivery and payment',
    copyTimeToAllDays: 'Copy time to all days',
    createSalePoint: 'Create a point of sale',
    createBusiness: 'Create Business Profile',
    addBusinessProfile: 'Add new business profile',
    collapse: 'Collapse',
    collapseAll: 'Collapse All',
    unCollapseAll: 'Expand All',
    removeProfile: 'Remove Profile',
    leaveProfile: 'Leave Profile',
    upload: 'Upload',
    joinedSocial: 'Joined',
    breakSocialLink: 'Break the link.',
    uploadPhotoText: 'Upload Photo',
    centered: 'Center',
    searchGooglePhoto: 'Search for images on the Internet',
    uploadPrice: 'Upload price',
    searchAgain: 'Repeat the search',
    wrongTableData: 'Incorrectly displayed file content in the table',
    getMoreRows: 'Show {num} more rows',
    createPrice: 'Create a new price',
    uploadOtherFile: 'Upload another file',
    reloadInfo: 'Refresh Data',
    reload: 'Refresh',
    goBackHome: 'Go back to the site',
    createPriceScheme: 'Create Pricing Scheme',
    newPriceScheme: '+ New Pricing',
    waitLoading: 'The file is loading. Do not close the window.',
    otherCity: 'Other City',
    addTrademark: '+ Add TM',
    addDoc: 'Add Document',
    pushToProcessing: 'Send for processing',
    createDeliveryRate: '+ Create Delivery Rate',
    search: 'Search',
    toOrder: 'Order',
    cancelAllOrder: 'Cancel all order',
    toOrderList: 'To Order List',
    cancelOrderItem: 'Cancel Order',
    setNextStatus: 'Move to next status',
    setStatus: 'Transfer to "{status}"',
    addRecipient: '+ Create Recipient',
    likeList: 'List',
    onMap: 'On the map',
    clearAllFilters: 'Clear all filters',
    removePrice: 'Remove price',
    submitAskToSeller: 'Send question to seller',
    loadMore: 'Load More',
    addLink: 'Add links',
    buy: 'Buy',
    lessTrademarks: 'Fewer manufacturers',
    moreTrademarks: 'More manufacturers',
    moreCategory: 'More Categories',
    lessCategory: 'Fewer Categories',
    comparePrices: 'Compare Prices',
    addCar: 'Add Car',
    goToGarage: 'To the garage',
    orderToSelection: 'Make selection request',
    addMoreRow: 'Add Row',
    emitRequest: 'Send Request',
    toSelectionRequestsList: 'To list of requests by VIN',
    newSelectionRequest: 'Create Request',
    selectionRequestHistory: 'Request History',
    newRequest: 'New Request',
    notebook: 'Notebook',
    view: 'View',
    answer: 'Answer',
    answerShow: 'View answer',
    answerHide: 'Hide answer',
    addWare: 'Add Product',
    addResponse: 'Add Response',
    submitRequest: 'Submit Response',
    noPropositionUseCustom: 'There are no propositions in the list. Enter yourself',
    allRequests: 'All Requests',
    processed: 'Processed',
    allPhones: 'All Phones',
    allEmails: 'All emails',
    signContract: 'Sign contract',
    bySelectedIn: 'Buy selected in {name}',
    subscribe: 'Subscribe',
    oneClickBuy: 'Buy in 1 click',
  },

  placeholders: {
    email: 'Email address',
    password: 'Password',
    enterPartNumber: 'Enter part number',
    activation_key: 'Enter activation key',
    comment: 'Comment',
    setReturnTerms: 'Specify return terms',
    setReturnTermsLink: 'Specify return terms link',
    setWarrantyTerms: 'Specify warranty terms',
    setWarrantyTermsLink: 'Specify link to warranty images',
    days: 'Days',
    hours: 'Hour',
    catalogNumInPrice: 'Catalog number in price',
    chooseOrEnter: 'Choose from list or add new',
    filterByTrademark: 'Filter by Trademark',
    filterByStatus: 'Filter by status',
    setRecipient: 'Select Recipient',
    setStatus: 'Select Status',
  },

  message: {
    warning: 'Attention',
    success: 'Success',
    isActivated: 'Your account is activated',
    checkEmail: 'Please check your email.',
    businessRemoved: 'Business profile removed.',
    businessLeaved: 'Profile left from business',
    priceUploadProcess: 'Upload price',
    priceUploadSuccess: 'Price uploaded.',
    priceRemoved: 'Price removed.',
    priceAvailabilityUploadProcess: 'Upload warehouse settings',
    priceAvailabilityUploadSuccess: 'Uploading warehouse settings completed.',
    checkChangedAvailabilities: 'The warehouse setting has been changed, check the updated data.',
    orderCreated: 'Order created, check your <strong>{email}</strong>. <strong>Subject of the letter:</strong> Order <strong>{id}</strong>, from Buyer <strong>{buyer}</strong>',
    orderCreatedNotify: 'Order #<strong>{id}</strong> has been created. Our manager will contact you soon',
    orderCanceled: 'Order {order} has been cancelled',
    confirmSetNextStatus: 'Do you really want to change the status of order lines to "{status}" ?',
    confirmChangePriceActivity: 'Do you really want to change the price display mode in the search results?',
    checkMapping: 'The system detected the probability of incorrect mapping. Please check that the mapping is correct and try saving again.',
    invalidMapping: 'The price mapping is invalid. Contact technical support specialists for advice.',
    businessSave: "Your business profile has been successfully saved",
    platformConnect: "The connection will take place only after receiving the signed paper contract in two copies",
    authIsRequired: 'You must register',
    authForInquire: 'You need to register to make a selection request',
    editModification: 'In order to filter the directory, you must specify the modification code',
    confirmRemoveRequestSelection: 'Are you sure you want to remove selection request #{id}?',
    salePointIsNotActive: 'Unfortunately, the point of sale is temporarily not accepting orders through the site. Try to choose another point of sale.',
    setDataPreviewSheen: 'Price mapping is not saved. Try again.',
    changesSaved: 'Changes saved'
  },

  errors: {
    api: {
      title: 'Error',
      message: 'Server error'
    },
    400: {
      title: 'Invalid request',
    },
    401: {
      title: '',
      message: 'Session timed out. Sign in again'
    },
    404: {
      title: 'Error',
      message: 'This page does not exist'
    },
    500: {
      title: 'Error',
      message: 'Server error'
    },
    502: {
      title: 'Error',
      message: 'Server overloaded'
    },
    503: {
      title: 'Error',
      message: 'The service is temporarily unavailable'
    },
    504: {
      title: 'Error',
      message: 'Server not responding'
    },
    default: {
      warning: 'Attention',
      title: 'Error',
      message: 'An unexpected error occurred'
    },
    captcha: {
      title: 'System Error',
      message: 'Missing captcha'
    },
    login: {
      title: '',
      message: 'Please register in the system.'
    },
    logout: {
      title: 'Success',
      message: 'You are logged out.'
    },
    businessAccess: {
      title: 'Access Error',
      message: 'You do not have access to the business profile'
    },
    price_status: {
      '404': 'File is being processed',
      empty: 'There is currently no data'
    },
    upload: {
      title: 'Loading Error',
      message: 'An error occurred while downloading the file {name}. Please try to download the file again.',
    },
    invalid_encoding: {
      message: 'Invalid file encoding ({message})'
    },
    reloadPage: 'The data is out of date. Please reload the page!',
    terms_changed: {
      title: 'Attention'
    },
    emptyData: 'No data',
    emptySearch: {
      label: 'Sellers unfortunately have no offers for {name}',
      message: `
         Nothing was found for {name}. Try: <br>
         <span class="ml-2">- check the catalog number - there may be an error in the number;</span> <br>
         <span class="ml-2">- use the selection directory;</span>
       `
    }
  },

  validation: {
    simple: {
      required: 'Field is required.',
      min: 'Minimum number of characters {num}.',
      max: 'Maximum number of characters {num}',
      selectSome: 'Need to select items.',
      existedValue: 'This value is already in use.',
      number: 'The field must contain only numbers',
      format: 'Invalid format',
      minOneOption: 'Choose one of the items',
      length: 'The field must contain {num} characters'
    },
    first_name: {
      required: 'Enter name.',
    },
    email: {
      required: 'Enter a postal address.',
      pattern: 'Email is invalid.'
    },
    password: {
      required: 'Enter a password.'
    },
    password2: {
      required: 'Passwords must be the same.',
      ident: 'Passwords must match. Enter the password correctly.'
    },
    activation_key: {
      required: 'For activation, you must enter the key specified in the letter.'
    },
    avatar: {
      format: 'The image must be in the format - {format}.',
      size: 'Image size cannot exceed {size}.'
    },
    price: {
      format: 'The file must be in the format - {format}.',
      size: 'File size cannot exceed {size}.'
    },
    address: {
      required: 'Specify an address, or state that there is none.'
    },
    contacts: {
      required: 'This section must contain at least one contact phone number.'
    },
    phone_numbers: {
      duplicate: 'Phone numbers must be unique.'
    },
    phone_number: {
      pattern: 'Enter phone in {format}.',
    },
    profile: {
      required: 'It is necessary to choose the appropriate profile to which the point of sale belongs.'
    },
    trademark: {
      required: 'Specify the brand name, or specify the appropriate column type.',
      single: 'Specify the trademark in the mapping OR in a separate field.'
    },
    article: {
      required: 'Specify a column called "Catalog number".'
    },
    delivery_time: {
      required: 'Indicate the delivery date.'
    },
    order_time_until: {
      required: 'Indicate the delivery date.'
    },
    priceQuantity: {
      required: 'Price or balances must be specified.'
    },
    sellPrice: {
      required: 'It is necessary to specify the selling price.'
    },
    purchasePriceActions: {
      required: 'It is necessary to specify the selling price in the scheme.'
    },
    quantity: {
      required: 'Indicate the balance in the warehouse.'
    },
    analogsAndAnalogsTrademark: {
      required: 'If present, it is necessary to specify the catalog number of the analogue and the name of the manufacturer.'
    },
    relationType: {
      required: 'Specify the type of connection with the analogue article.'
    },
    weightUnit: {
      required: 'Specify the weight type.'
    },
    float: {
      invalidFormat: 'Specify the value in the format 0.00.'
    },
    verification_url_or_document: {
      required: 'A link or file must be specified.'
    },
    search_on_site_url: {
      pattern: 'The link is not valid'
    },
    site: {
      pattern: 'The link is not valid. Enter a value in the format test_site.com'
    },
    urls: {
      pattern: 'The link is not valid.'
    },
    warehouse: {
      required: 'Indicate the stock in the "Quantity in stock" column.'
    },
    confirm_mapping: {
      message: 'Mapping error'
    },
    weightType: {
      required: 'Specify weight type'
    },
    sizesType: {
      required: 'Specify the dimension type'
    },
    agreeWithPublicOffer: 'To register as an auto parts seller, you must agree to the terms of the public offer contract.',
    vinError: 'There may be an error in the VIN number. Please check your VIN carefully. Only by entering the CORRECT VIN can you correctly select spare parts suitable for the car!'
  },

  mapLegend: {
    priceIn: 'Prices in {cur}',
    green: 'Green',
    greenDesk: 'available',
    black: 'Black',
    blackDesk: 'will be in a day',
    gray: 'Grey',
    greyDesk: 'more than one day',
    red: 'Red',
    redDesk: 'best offer',
    official_trademark: 'From the warehouses of the distributor',
  },

  orderCycleHelp: {
    title: 'What statuses can the order go to',
    description: 'Rejection of the order by the Client or the Seller is negatively taken into account when calculating the rating of the Client and the Seller, respectively. Therefore, in order to maintain a high rating of advice',
    forBuyer: 'to <strong>Buyers</strong> - order only what you are guaranteed to buy. By canceling the order, you generate additional costs for the Seller. Please appreciate the work of others.',
    forSeller: 'to <strong>Sellers</strong> - try to fulfill customer orders within the time frame and at the price agreed upon with the customer at the beginning. The buyer is counting on you, and it was because of these arrangements that he chose you.'
  },

  el: {
    day1: 'Day',
    day2: 'Day',
    day3: 'Days',
    colorpicker: {
      confirm: 'OK',
      clear: 'Clear'
    },
    datepicker: {
      output: 'Output',
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      selectDate: 'Select Date',
      selectTime: 'Select Time',
      startDate: 'Start Date',
      startTime: 'Start Time',
      endDate: 'End Date',
      endTime: 'End Time',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      year: 'year',
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      week: 'Sunday',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday'
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
      }
    },
    select: {
      loading: 'Loading...',
      noMatch: 'No matches found',
      noData: 'No data',
      placeholder: 'Choose'
    },
    cascader: {
      noMatch: 'No matches found',
      loading: 'Loading...',
      placeholder: 'Choose',
      noData: 'No data'
    },
    pagination: {
      goto: 'Go',
      pagesize: 'On page',
      total: 'Total {total}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'Message',
      confirm: 'OK',
      cancel: 'Cancel',
      error: 'Invalid data entry'
    },
    upload: {
      deleteTip: 'Click [Delete] to delete',
      delete: 'Delete',
      preview: 'Preview',
      continue: 'Continue'
    },
    table: {
      emptyText: 'No data',
      confirmFilter: 'Confirm',
      resetFilter: 'Reset',
      clearFilter: 'All',
      sumText: 'Sum'
    },
    tree: {
      emptyText: 'No data'
    },
    transfer: {
      noMatch: 'No matches found',
      noData: 'No data',
      titles: ['List 1', 'List 2'],
      filterPlaceholder: 'Enter keyword',
      noCheckedFormat: '{total} items',
      hasCheckedFormat: '{checked}/{total} selected'
    },
    image: {
      error: 'Error'
    },
    pageHeader: {
      title: 'Back'
    }
  },
};
