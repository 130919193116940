<template>
  <div class="ware-article-replacement">
    <div class="mb-1">
      <span class="custom-headline custom-headline--level3-bold">
        {{ $t('ware.history') }}
      </span>
    </div>

    <div class="ware-article-container">
      <el-row
        v-for="(item, index) in value.replacements_history"
        :key="item.id"
        align="middle"
        class="article-container"
      >
        <i v-if="index !== 0" class="el-icon-right" />
        <nuxt-link
          :class="['article', { disabled: item.article === value.article }]"
          :to="getLInkBy(item)"
        >
          {{ item.article }}
        </nuxt-link>
      </el-row>
    </div>
  </div>
</template>

<script>
import getWareLink from '@/lib/catalog/services/getWareLink';

export default {
  name: 'WareArticleReplacement',

  props: {
    value: { type: Object, required: true }
  },

  methods: {
    getLInkBy (item) {
      return getWareLink(this, item);
    }
  }
};
</script>

<style scoped lang="scss">
.ware-article-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  i {
    margin: 0 6px
  }
}

.article {
  border: 1px solid $grey-300;
  border-radius: 4px;
  padding: 5px 8px;

  &.disabled {
    color: $--color-text-secondary;
    pointer-events: none;
  }
}

.article-container {
  margin: 4px 0;
}
</style>
