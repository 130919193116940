<template>
  <ul class="photo-list">
    <li v-for="photo in photos" :key="photo.id" class="list-item">
      <div class="img-card">
        <el-row>
          <img
            v-lazy-load
            :alt="photo.name"
            :data-src="getUrl(photo)"
          >
          <i
            v-if="!readonly"
            class="el-icon-close"
            @click="$emit('remove', photo)"
          />
        </el-row>
        <div>
          <el-radio
            v-model="photo.is_primary"
            :disabled="readonly"
            :label="true"
            @change="$emit('primary', photo)"
          >
            {{ $t('salePoint.primary') }}
          </el-radio>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PhotoList',

  props: {
    readonly: Boolean,
    photos: { type: Array, required: true }
  },

  methods: {
    getUrl (file) {
      if (typeof file === 'string') {
        return file;
      } else if (file.photo) {
        return file.photo_200;
      } else if (file.raw && file.url) {
        return file.url;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.photo-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .img-card {
    padding: 10px 10px 0 10px;
    border: 1px solid $grey-300;
    border-radius: 4px;
    margin: 0 10px 10px 0;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
  .el-icon-close {
    display: flex;
    height: 20px;
    width: 20px;
    cursor: pointer;
    opacity: .5;
    margin: -8px -8px 0 0;

    &:before {
      font-size: 16px;
      margin: auto;
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>
