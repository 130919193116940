import sortBy from 'lodash/sortBy';
import { parsers } from '@/lib/core';

export const actions = {
  getBusinessProfile ({ commit }, { id }) {
    return this.$axios.$get(`/business-profiles/${id}/`);
  },

  getBusinessRequisites ({ commit }, { id }) {
    return this.$axios.$get(`/business-contractors/?business=${id}`);
  },

  createBusinessRequisites ({ commit }, { formData }) {
    return this.$axios.$post('/business-contractors/', formData);
  },

  updateBusinessRequisites ({ commit }, { formData, id }) {
    return this.$axios.$patch(`/business-contractors/${id}/`, formData);
  },

  async postBusinessProfile ({ commit, dispatch }, formData) {
    const { logo, ...data } = formData;
    let dataCopy = data;

    if (logo) {
      dataCopy = parsers.getMultipartFormData(data, logo);
    }

    const profile = await this.$axios
      .$post('/business-profiles/', dataCopy);

    const user = await dispatch(
      'users/updateCurrentUser',
      null,
      { root: true }
    );

    const businessId = user.business_acl
      .find(({ business }) => business.id === profile.id).id;

    dispatch('setUserCode', `b${businessId}`, { root: true });

    return profile;
  },

  async patchBusinessProfile ({ getters, commit, dispatch }, { id, formData }) {
    const { logo, ...data } = formData;
    let dataCopy = data;

    if (logo) {
      dataCopy = parsers.getMultipartFormData(data, logo, 'logo');
    } else {
      dataCopy.logo = logo;
    }

    const profile = await this.$axios
      .$patch(`/business-profiles/${id}/`, dataCopy);

    await dispatch('users/updateCurrentUser', null, { root: true });

    return profile;
  },

  removeBusinessProfile ({ state, dispatch }, { id }) {
    return this.$axios.$delete(`/business-profiles/${id}/`);
  }
};

export const getters = {
  businessId: (state, { currentBusinessProfile }) => {
    return currentBusinessProfile && currentBusinessProfile.id;
  },

  sellerId: (state, getters) => {
    const profile = getters.currentBusinessProfile;
    return profile && profile.seller ? profile.seller.id : null;
  },

  businessProfiles: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['users/user'];

    return user && user.business_acl
      ? sortBy(user.business_acl, item => item.business.name.toLowerCase())
      : [];
  },

  currentBusinessProfile: (state, getters, rootState, rootGetters) => {
    const currentBusinessUser =
      rootGetters['businessUsers/currentBusinessUser'];

    return currentBusinessUser && currentBusinessUser.business;
  },

  isSellBusiness: (state, getters) => {
    const profile = getters.currentBusinessProfile;
    return profile && Boolean(profile.is_seller);
  }
};
