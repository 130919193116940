var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("TheLoader")
        : [
            _c(
              "el-row",
              {
                staticClass: "mb-2",
                attrs: { justify: "space-between", align: "middle" },
              },
              [
                _c(
                  "h1",
                  {
                    staticClass:
                      "custom-headline custom-headline--level2-bold mr-2",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "routes.garage_" +
                              (_vm.formData.id ? "edit" : "create")
                          )
                        ) +
                        "\n        " +
                        _vm._s(_vm.formData.title) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isMobile
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.toGarage },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("buttons.goToGarage")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  "label-position": _vm.maxmq("ss") ? "top" : "right",
                  model: _vm.formData,
                  rules: _vm.rules,
                  "label-suffix": ":",
                  size: "medium",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.checkAndSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _vm.$store.getters.isAppLoaded
                  ? _c(
                      "CarFactorySelector",
                      _vm._b(
                        { attrs: { value: _vm.formData } },
                        "CarFactorySelector",
                        _vm.formData,
                        false,
                        true
                      )
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.step === 2
                  ? _c(
                      "div",
                      { staticClass: "mt-2 mb-4" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "130px",
                              label: _vm.$t("garage.main.vin"),
                              prop: "vin",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "vin-select",
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.formData.vin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "vin", $$v)
                                },
                                expression: "formData.vin",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.step === 3
                  ? _c(
                      "CarMetaInfo",
                      _vm._b(
                        {
                          staticClass: "mt-2 mb-4",
                          attrs: { value: _vm.formData },
                          on: {
                            "vin-edit": function ($event) {
                              _vm.isVinPopup = true
                            },
                          },
                        },
                        "CarMetaInfo",
                        _vm.formData,
                        false,
                        true
                      )
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.step === 3
                  ? _c(
                      "CarInfoCollapse",
                      _vm._b(
                        {
                          staticClass: "mt-2 mb-4",
                          attrs: { value: _vm.formData },
                        },
                        "CarInfoCollapse",
                        _vm.formData,
                        false,
                        true
                      )
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.formData.id
                  ? _c(
                      "el-row",
                      { attrs: { justify: "space-between" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.confirmAndRemove },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("buttons.remove")) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-button", { on: { click: _vm.toGarage } }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("buttons.cancel")) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  "native-type": "submit",
                                  type: "primary",
                                  loading: _vm.isVLoading,
                                  disabled: _vm.isEqual,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("buttons.save")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm.formData.modification
                  ? _c(
                      "el-row",
                      { attrs: { justify: _vm.isMobile ? "center" : "end" } },
                      [
                        _c(
                          "el-button",
                          {
                            class: { "w-100": _vm.isMobile },
                            attrs: {
                              "native-type": "submit",
                              type: "primary",
                              loading: _vm.isVLoading,
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("buttons.addCar")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "TheDialog",
              {
                attrs: { width: "600px", title: _vm.$t("garage.main.vin") },
                model: {
                  value: _vm.isVinPopup,
                  callback: function ($$v) {
                    _vm.isVinPopup = $$v
                  },
                  expression: "isVinPopup",
                },
              },
              [
                _c("VinConfirmForm", {
                  attrs: {
                    car: _vm.formData,
                    "hide-desc": "",
                    submit: _vm.checkCarDependencyAndSaveVin,
                    "submit-btn-text": _vm.$t("buttons.save"),
                  },
                  on: {
                    cancel: function ($event) {
                      _vm.isVinPopup = false
                    },
                  },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }