const listToIds = rawList => rawList
  ? rawList.split(',').map(Number)
  : [];

export default {
  APP_NAME: process.env.APP_NAME,
  APP_VERSION: process.env.APP_VERSION,

  NODE_ENV: process.env.NODE_ENV,
  ENVIRONMENT: process.env.environment,
  NPM_LIFECYCLE_EVENT: process.env.NPM_LIFECYCLE_EVENT,
  USE_SEO_ANALYTICS: !!process.env.USE_SEO_ANALYTICS,
  USE_SEO_GTM: !!process.env.USE_SEO_GTM,
  USE_SEO_GTAG: !!process.env.USE_SEO_GTAG,
  USE_SEO_PIXEL: !!process.env.USE_SEO_PIXEL,

  SESSION_ID_KEY: process.env.SESSION_ID_KEY,
  APOLLO_SESSION_KEY: process.env.APOLLO_SESSION_KEY,
  TOKEN_KEY: process.env.TOKEN_KEY,
  TOKEN_EXPIRES_KEY: process.env.TOKEN_EXPIRES_KEY,
  CAR_DATA_KEY: process.env.CAR_DATA_KEY,
  SAVED_CARS_KEY: process.env.SAVED_CARS_KEY,
  LAST_PHONE_NUM_KEY: process.env.LAST_PHONE_NUM_KEY,

  BASE_CLIENT_PATH: process.env.BASE_CLIENT_PATH,
  BASE_CLIENT_URL_PREFIX: process.env.BASE_CLIENT_URL_PREFIX,
  BASE_API_URL: process.env.BASE_API_URL,
  GRAPHQL_API_URL: process.env.GRAPHQL_API_URL,

  BASE_API: process.env.BASE_API,
  OS_API: process.env.OS_API,
  SEO_API: process.env.SEO_API,
  GARAGE_API: process.env.GARAGE_API,
  SEARCH_API: process.env.SEARCH_API,

  MARKETPLACE: process.env.MARKETPLACE,
  MARKETPLACE_ID: process.env.MARKETPLACE_ID,
  MARKETPLACE_PHONE_PREFIX: process.env.MARKETPLACE_PHONE_PREFIX,
  MARKETPLACE_PHONE_FORMAT: process.env.MARKETPLACE_PHONE_FORMAT,
  MARKETPLACE_LANGS: (process.env.MARKETPLACE_LANGS || 'uk').split(','),
  MARKETPLACE_DEFAULT_LANG: process.env.MARKETPLACE_DEFAULT_LANG || 'uk',
  MARKETPLACE_CURRENCY: process.env.MARKETPLACE_CURRENCY,
  MARKETPLACE_CURRENCY_ID: Number(process.env.MARKETPLACE_CURRENCY_ID),

  MARKETPLACE_COUNTRY_ID: Number(process.env.MARKETPLACE_COUNTRY_ID),
  ADMIN1_CAPITAL_ID: Number(process.env.ADMIN1_CAPITAL_ID),
  CAPITAL_ID: Number(process.env.CAPITAL_ID),
  REGIONAL_CENTERS: (process.env.REGIONAL_CENTERS || 'uk').split(','),

  PRICE_SPLITTER: process.env.PRICE_SPLITTER,
  PRICE_TO_FIXED: Number(process.env.PRICE_TO_FIXED),
  GEO_TO_FIXED: Number(process.env.GEO_TO_FIXED),
  DEFAULT_ORDER_TIME_UNTIL: process.env.DEFAULT_ORDER_TIME_UNTIL,
  DEFAULT_DELIVERY_TIME: process.env.DEFAULT_DELIVERY_TIME,
  PRICE_GAP: Number.parseFloat(process.env.PRICE_GAP),
  PAGINATION_SIZE: Number(process.env.PAGINATION_SIZE),
  SMALL_PAGINATION_SIZE: Number(process.env.SMALL_PAGINATION_SIZE),
  DEBOUNCE: Number(process.env.DEBOUNCE),
  TOKEN_UPDATE_INTERVAL: Number(process.env.TOKEN_UPDATE_INTERVAL),
  REQUEST_UPDATE_INTERVAL: Number(process.env.REQUEST_UPDATE_INTERVAL),
  VERSION_CHECK_INTERVAL: Number(process.env.VERSION_CHECK_INTERVAL),
  BEFORE_ORDER_CREATE_DEBOUNCE: Number(process.env.BEFORE_ORDER_CREATE_DEBOUNCE),
  STATUS_REVERT_DEBOUNCE: Number(process.env.STATUS_REVERT_DEBOUNCE),
  FILTER_AUTO_SUBMIT_DEBOUNCE: Number(process.env.FILTER_AUTO_SUBMIT_DEBOUNCE),
  QUERY_CACHE_SIZE: Number(process.env.QUERY_CACHE_SIZE),
  CACHE_MAX: Number(process.env.CACHE_MAX),
  COMPONENT_CACHE_TIME: Number(process.env.COMPONENT_CACHE_TIME),
  PAGE_CACHE_TIME: Number(process.env.PAGE_CACHE_TIME),
  DATA_CACHE_MAX: Number(process.env.DATA_CACHE_MAX),
  DATA_CACHE_TIME: Number(process.env.DATA_CACHE_TIME),
  MAX_PAGE_PAGINATION: Number(process.env.MAX_PAGE_PAGINATION) || 333,

  USE_PAGE_CACHE_MIDDLEWARE: process.env.USE_PAGE_CACHE_MIDDLEWARE,
  BUILD_TIME: process.env.BUILD_TIME,

  SOCIAL_AUTH_FACEBOOK_KEY: process.env.SOCIAL_AUTH_FACEBOOK_KEY,
  SOCIAL_AUTH_GOOGLE_OAUTH2_KEY: process.env.SOCIAL_AUTH_GOOGLE_OAUTH2_KEY,
  GOOGLE_RECAPTCHA_KEY: process.env.GOOGLE_RECAPTCHA_KEY,
  GOOGLE_GTAG_ID: process.env.GOOGLE_GTAG_ID,

  OSM_LAYER_URL: process.env.OSM_LAYER_URL,
  OSM_SEARCH_URL: process.env.OSM_SEARCH_URL,

  BINOTEL_WIDGET_ID: process.env.BINOTEL_WIDGET_ID,

  SENTRY_DSN: process.env.SENTRY_DSN,
  ROBOTS_VALUES: process.env.ROBOTS_VALUES,
  DISABLED_CITY_IDS: listToIds(process.env.DISABLED_CITY_IDS),
  MAIN_CITY_IDS: listToIds(process.env.MAIN_CITY_IDS)
};
