var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invoice
    ? _c(
        "div",
        { staticClass: "invoice invoice-ua" },
        [
          _c(
            _vm.component,
            _vm._b(
              { tag: "component" },
              "component",
              Object.assign({}, _vm.$data, { getDateTime: _vm.getDateTime }),
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }