<template>
  <div class="authentication-page"><nuxt /></div>
</template>

<script>
import { env } from '@/lib/core';

export default {
  name: 'AuthenticationPage',

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>
