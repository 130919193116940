var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.toggleView,
          expression: "toggleView",
        },
      ],
      class: ["open-street-map", "show-points-map"],
    },
    [
      _c("div", {
        staticClass: "map",
        attrs: { id: _vm.mapId },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }