import isEmpty from 'lodash/isEmpty';
import cookie from '@/lib/core/services/cookie';
import env from '@/lib/core/services/envConstants';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';
import { withCarSeoPages } from '@/lib/core/services/routeParser';

export default async function (ctx) {
  const { app, req, store, route, params, query, redirect, error } = ctx;

  if (!route || !route?.name || (route?.name || '')?.includes('ping')) {
    return null;
  } else if (query.goTo) {
    return redirect(query.goTo);
  }

  const lang = route?.name?.split('__')[1] || env.MARKETPLACE_DEFAULT_LANG;
  if (lang !== store.getters.langCode) {
    await store.dispatch('setLangCode', { lang });
  }

  if (process.server) {
    const viewPort = /Mobile|iPhone|Android|Chrome-Lighthouse/ig.test(req.headers['user-agent'])
      ? 'mobile'
      : 'desktop';
    store.dispatch('setViewPort', viewPort);

    if (req.headers.cookie) {
      const parsedCookie = cookie.parse(req.headers.cookie);
      const _cookie = {
        ...parsedCookie,
        token: parsedCookie[env.TOKEN_KEY],
        token_expires: parsedCookie[env.TOKEN_EXPIRES_KEY]
      };

      const {
        [env.CAR_DATA_KEY]: _carData,
        [env.SAVED_CARS_KEY]: _savedCars
      } = parsedCookie;

      const carData = JSON.parse(_carData || 'null');
      const pl = {};

      if (carData) {
        pl.carData = carData;
        pl.id = carData.carModification;
      }

      if (!isEmpty(_savedCars)) {
        pl.cars = typeof _savedCars === 'string'
          ? JSON.parse(_savedCars)
          : _savedCars;
      }

      try {
        // redirect to selected car or clear selected if is carBrand in route
        if (query.cm) {
          await store.dispatch('carModifications/setCarData', pl);
        } else {
          await store.dispatch(
            'carModifications/setCarData',
            { ids: pl.ids, cars: pl.cars }
          );
        }

        if (
          withCarSeoPages[route.name] &&
          !isEmpty(carData) &&
          carData.carModification &&
          !query.cm &&
          !params.carBrand
        ) {
          const _params = { ...params, ...carData };
          const vm = { $router: app.router, $store: store };
          const url = getSeoLinkBy(_params, vm, _params);

          redirect(url);
        }

        await store.dispatch('auth/autoLogin', { cookie: _cookie });
      } catch (e) {
        console.error(e);

        error({
          statusCode: 404,
          message: e.message || app.i18n.t('errors.404.message')
        });
      }
    }
  }
};
