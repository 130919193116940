var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      staticClass: "activities",
      attrs: {
        label: _vm.$t("labels.point_sale_activities"),
        prop: "activities",
      },
    },
    [
      _c(
        "el-checkbox-group",
        {
          staticClass: "cell",
          attrs: { disabled: _vm.readonly },
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v
            },
            expression: "formData",
          },
        },
        _vm._l(_vm.options, function (option) {
          return _c(
            "el-checkbox",
            { key: option.id, attrs: { label: option.label } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("sale_activities." + option.label)) +
                  "\n    "
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }