<template>
  <TheDialog width="1200px" top="10px" :value="isVisible" @cancel="closeBasket">
    <template #title>
      <el-row justify="space-between" align="bottom">
        <el-row>
          <h2 class="custom-headline custom-headline--level2-bold mr-2 pg-0">
            {{ $t("basket.title") }}
          </h2>

          <CollapseToggle
            v-if="isMobile"
            v-model="activeBlocks"
            class="pg-0"
            :blocks="blocks"
          />
        </el-row>

        <div v-if="!isMobile && items.length" class="mr-4">
          <el-row align="middle" class="sum-block">
            <div class="mr-1">
              <el-button
                icon="el-icon-delete"
                size="mini"
                @click="confirmClearBasket"
              >
                {{ $t("buttons.clearBasket") }}
              </el-button>
            </div>

            <ThePrice :value="getTotalAmount(items)" size="large">
              {{ $t("basket.count") }}:
            </ThePrice>

            <div class="ml-2">
              <CollapseToggle v-model="activeBlocks" :blocks="blocks" />
            </div>
          </el-row>
        </div>
      </el-row>
    </template>

    <TheLoader v-if="isLoading" />

    <div v-else class="the-basket">
      <div v-if="!isEmptyBasket && !isMobile" class="description pg-0-1 mb-2">
        <ul>
          <li>* {{ $t("basket.tableDescription.1") }}</li>
          <li>** {{ $t("basket.tableDescription.2") }}</li>
          <li>*** {{ $t("basket.tableDescription.3") }}</li>
        </ul>
      </div>

      <div v-if="isEmptyBasket" class="empty-basket">
        <div class="mb-4">
          <span class="custom-headline custom-headline--level2-bold">
            {{ $t("basket.basketIsEmpty") }}
          </span>
        </div>

        <el-link type="primary" @click="closeBasket(getRouteName('home'))">
          {{ $t("buttons.goBackHome") }}
        </el-link>
      </div>

      <el-collapse v-else v-model="activeBlocks">
        <el-collapse-item
          v-for="(value, id) in ordersByPoints"
          :key="id"
          :name="id"
        >
          <template slot="title">
            <div class="collapse-green-prefix" />

            <el-row
              class="collapse-header"
              :align="isMobile ? 'bottom' : 'middle'"
              justify="space-between"
            >
              <div class="collapse__title">
                <el-row align="bottom" class="mr-1">
                  <el-link
                    class="point-link mr-1"
                    @click.stop="toSalePoint(value)"
                  >
                    {{ value.name }}
                  </el-link>

                  <span class="text-ghost"> ({{ value.city }}) </span>
                </el-row>

                <TheRate :value="value.rank" />
              </div>

              <div v-if="!isMobile">
                <ThePrice :value="getTotalAmount(value.items)">
                  {{ $t("basket.count") }}:
                </ThePrice>
              </div>
            </el-row>
          </template>

          <div class="pg-0">
            <component
              :is="`BuyerOrderItemsTable${isMobile ? 'M' : ''}`"
              :key="id"
              class="mb-2"
              with-description
              :currency-name="MARKETPLACE_CURRENCY"
              :value="value.items"
              @item:remove="removeItem"
              @item:update="updateItem"
            />

            <el-row v-if="isMobile" justify="space-around">
              <el-button
                plain
                class="min-w-120 m-0"
                type="primary"
                :size="isMobile ? 'mini' : ''"
                @click="closeBasket"
              >
                {{ $t("buttons.continueShopping") }}
              </el-button>

              <el-button
                class="min-w-120 m-0"
                type="primary"
                :disabled="!isCanBuy(value.items)"
                :size="isMobile ? 'mini' : ''"
                @click="checkUserAndGoToOrder(value.items)"
              >
                {{ $t("buttons.buy") }}
              </el-button>
            </el-row>

            <el-row v-else justify="end">
              <el-button
                plain
                type="primary"
                :size="isMobile ? 'mini' : ''"
                @click="closeBasket"
              >
                {{ $t("buttons.continueShopping") }}
              </el-button>

              <el-button
                type="primary"
                :disabled="!isCanBuy(value.items)"
                :size="isMobile ? 'mini' : ''"
                @click="checkUserAndGoToOrder(value.items)"
              >
                {{ $t("buttons.buy") }}
              </el-button>
            </el-row>

            <OneClickOrder
              v-if="value.isEnableOneClickOrder"
              class="mt-2"
              :basket="basket"
              :items="value.items"
            />
          </div>
        </el-collapse-item>
      </el-collapse>

      <template v-if="isMobile">
        <el-row class="mt-2 mb-2" justify="space-between" align="bottom">
          <div>{{ $t("basket.sumAllOrders") }}:</div>
          <ThePrice :value="getTotalAmount(items)" size="large" />
        </el-row>

        <div>
          <el-button
            v-if="items.length"
            plain
            icon="el-icon-delete"
            size="mini"
            @click="confirmClearBasket"
          >
            {{ $t("buttons.clearBasket") }}
          </el-button>
        </div>

        <div v-if="!isEmptyBasket" class="description pg-1 mt-1 mb-1">
          <ul>
            <li>* {{ $t("basket.tableDescription.1") }}</li>
            <li>** {{ $t("basket.tableDescription.2") }}</li>
            <li>*** {{ $t("basket.tableDescription.3") }}</li>
          </ul>
        </div>
      </template>
    </div>
  </TheDialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import basketMixin from '../mixins/basketMixin';
import BasketNav from './BasketNav';
import CollapseToggle from '@/lib/core/components/CollapseToggle';
import { parsers, env, eventBus } from '@/lib/core';
import TheRate from '@/lib/core/components/TheRate';
import ThePrice from '@/lib/core/components/ThePrice';

export default {
  name: 'TheBasket',

  mixins: [basketMixin],

  components: {
    /* eslint-disable max-len */
    OneClickOrder: () =>
      import(/* webpackChunkName: "BuyerOrderItemsTable" */ './OneClickOrder'),
    BuyerOrderItemsTable: () =>
      import(
        /* webpackChunkName: "BuyerOrderItemsTable" */ './BuyerOrderItemsTable'
      ),
    BuyerOrderItemsTableM: () =>
      import(
        /* webpackChunkName: "BuyerOrderItemsTableM" */ './BuyerOrderItemsTableM'
      ),
    /* eslint-enable max-len */
    ThePrice,
    BasketNav,
    CollapseToggle,
    TheRate
  },

  data () {
    return {
      isVisible: false,
      activeBlocks: [],
      blocks: [],
      selectedPointId: null
    };
  },

  computed: {
    isMobile () {
      return this.maxmq('md');
    },

    ordersByPoints () {
      return this.items.reduce((acc, _item) => {
        const item = cloneDeep(_item);

        const { id, name, address } = item.point_of_sale_detail;

        if (!acc[id]) {
          acc[id] = {
            name,
            isEnableOneClickOrder: item.seller_detail?.enable_one_click_order,
            city: address?.city_detail?.name,
            rank: item.seller_detail?.rank,
            items: [this.getParsed(item)],
            total_amount: Number.parseFloat(item.price)
          };
        } else {
          acc[id].total_amount += Number.parseFloat(item.price);
          acc[id].items.push(this.getParsed(item));
        }

        return acc;
      }, {});
    },

    isEmptyBasket () {
      return !this.basket || !this.items.length;
    },

    userCode () {
      return this.$store.getters.userCode;
    }
  },

  watch: {
    isVisible (isVisible) {
      if (isVisible) {
        this.load();
      } else {
        this.updateLocalBasket();
        const { showBasket, ...query } = this.$route.query;

        if (showBasket) {
          this.$router.replace({ query }).catch(e => e);
        }
      }
    }
  },

  created () {
    eventBus.$on('basket:show', this.showBasket);
    this.updateItem = debounce(this.updateItem, env.DEBOUNCE);
  },

  mounted () {
    if (this.$route.query.showBasket) {
      this.showBasket();
    }
  },

  beforeDestroy () {
    eventBus.$off('basket:show', this.showBasket);
  },

  methods: {
    closeBasket (name) {
      this.isVisible = false;

      if (name && name !== this.$route.name) {
        return this.$router.push({ name });
      }
    },

    isCanBuy (items) {
      return items.some(it => !it._notFound && it.price);
    },

    showBasket () {
      this.isVisible = true;
    },

    async load () {
      await this.refetchBasket();

      this.blocks = Object.keys(this.ordersByPoints);
      this.activeBlocks = this.blocks.slice(0);
    },

    confirmClearBasket () {
      this.$confirm(this.$t('basket.remove.message'), {
        type: 'error',
        title: this.$t('basket.remove.title'),
        confirmButtonText: this.$t('buttons.remove')
      }).then(isAgree => isAgree && this.clearBasket());
    },

    async clearBasket () {
      try {
        const payload = { id: this.basket.id, formData: { items: [] } };

        await this.$store.dispatch('baskets/patchBasket', payload);

        await this.$store.dispatch('baskets/clearLocalBasket');

        this.items = [];
      } catch (e) {
        console.error(e);
      }
    },

    checkUserAndGoToOrder (items) {
      this.selectedPointId = items[0].point_of_sale_detail.id;

      if (!items[0].point_of_sale_detail?.is_active) {
        return this.$notify.error({
          title: this.$t('errors.default.title'),
          message: this.$t('message.salePointIsNotActive')
        });
      }

      return this.goToOrderPointItems();
    },

    async updateItem (formData) {
      const { id, ..._formData } = formData;

      try {
        const _item = await this.$store.dispatch('basketItems/patchItem', {
          id,
          formData: _formData
        });

        this.updateLocalItem(_item);

        return _item;
      } catch (e) {
        console.error(e);
        this.items = cloneDeep(this.basket.items);
      }
    },

    async updateLocalBasket ({ formData } = {}) {
      const items = this.items.slice(0);

      if (formData) {
        return this.$store.dispatch('baskets/updateLocalBasket', formData);
      } else {
        const price = Number.parseFloat(this.getTotalAmountNum(items)).toFixed(
          env.PRICE_TO_FIXED
        );

        const total_quantity = this.getTotalQuantity(items);
        const total_amount = Number.parseFloat(price);

        const basket = await this.$store.dispatch('baskets/setBasket', {
          basket: {
            ...cloneDeep(this.basket),
            total_quantity,
            total_amount,
            items: items.slice(0)
          }
        });

        this.basket = cloneDeep(basket);
        this.items = cloneDeep(basket.items);
      }
    },

    goToOrderPointItems () {
      this.closeBasket();

      return this.$router.push({
        name: this.getRouteName('basket.order-create'),
        params: { pointId: this.selectedPointId }
      });
    },

    async removeItem (item) {
      try {
        await this.$store.dispatch('basketItems/removeItem', { id: item.id });
        this.removeBasketItem(item);
      } catch (e) {
        console.error(e);
      }
    },

    removeBasketItem (item) {
      const index = this.items.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        this.items.splice(index, 1);

        this.updateLocalBasket();
      }
    },

    getTotalAmount (items) {
      const _items = items.filter(
        ({ error_code }) => error_code !== 'not_found'
      );
      return parsers.getPriceWithCurrency(this.getTotalAmountNum(_items));
    },

    toSalePoint (value) {
      const {
        point_of_sale_detail,
        price,
        ware_id,
        display_article,
        display_trademark
      } = value.items[0];

      this.$store.dispatch('analytics/postClick', {
        referer_url: `${env.BASE_CLIENT_PATH}${this.$route.fullPath}`,
        click_type: 'contacts',
        aprice: price,
        point_of_sale: point_of_sale_detail.url,
        ware_id,
        display_article,
        display_trademark
      });

      this.$router.blank({
        name: this.getRouteName('sale-point'),
        params: { id: point_of_sale_detail.id }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.the-basket {
  .collapse-toggle {
    font-size: 1.4rem;
  }

  .price {
    font-size: 1rem;
    font-weight: bold;
    color: $--color-text-primary;
  }

  .collapse-header {
    width: 100%;
    padding-right: 20px;
  }

  .empty-basket {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px;

    h2 {
      font-size: 2rem !important;
    }
  }

  .point-link {
    line-height: normal;
    font-size: 1.2rem;
  }

  ::v-deep {
    .collapse-toggle {
      span {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .collapse__title {
    display: flex;
    align-items: center;
    line-height: 1rem;
  }

  @media only screen and (max-width: $--xs) {
    .collapse__title {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
