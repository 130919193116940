<template>
  <FormBlock
    ref="form"
    class="availabilities-block"
    entity-name="price"
    label-position="left"
    label-width="300px"
    :comparator="comparator"
    :fields="['availabilities']"
    :submit="submit"
    :value="value"
    v-on="$listeners"
    @refresh="refresh"
  >
    <template #default="{ formData }">
      <Availabilities
        v-if="dependency.warehouses"
        :key="key"
        ref="availabilities"
        :dependency="{ ...dependency, column_mapping }"
        :value="formData.availabilities"
        v-bind.sync="formData"
      />
    </template>
  </FormBlock>
</template>

<script>
import get from 'lodash/get';
import Availabilities from '../formItems/Availabilities';
import { eventBus } from '@/lib/core';
import {
  isHasDiff
} from '@/lib/prices/services/getClearPriceFormData';
import FormBlock from '@/lib/core/components/FormBlock';

const getItemKey = it => `${it.point_of_sale}_${it.warehouse}`;

export default {
  name: 'AvailabilitiesBlock',

  components: {
    FormBlock,
    Availabilities
  },

  props: {
    submit: Function,
    dependency: Object,
    value: { type: Object, required: true }
  },

  data: () => ({ key: 0 }),

  computed: {
    column_mapping () {
      return get(this.dependency, 'price.price_file.column_mapping');
    }
  },

  created () {
    eventBus.$on('quantity:changed', this.updateData);
  },

  beforeDestroy () {
    eventBus.$off('quantity:changed', this.updateData);
  },

  methods: {
    refresh () {
      this.key++;

      setTimeout(() => {
        this.$refs.availabilities && this.$refs.availabilities.checkDeliveryRate();
      }, 100);
    },

    updateData () {
      // update availabilities after update price.price_file.column_mapping
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.refresh();

          setTimeout(() => {
            this.$refs.form.validateAndEmit({ forceFields: ['availabilities'] });
          }, 1500);
        }
      }, 100);
    },

    comparator (originValue, formData) {
      if (!formData) {
        return true;
      }

      const valueList = originValue.availabilities.sort();
      const formDataList2 = formData.availabilities.sort();

      const hasDiff = formDataList2.some((item) => {
        const _id = getItemKey(item);
        const item2 = valueList.find(it => getItemKey(it) === _id);

        return isHasDiff(item, item2);
      });

      return !hasDiff;
    }
  }
};
</script>
