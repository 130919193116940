<template>
  <el-card class="registration-form-card">
    <nuxt-link
      v-if="!hideCloseIcon"
      class="close-icon"
      :to="{ name: getRouteName('home') }"
    >
      <i class="el-icon-close" />
    </nuxt-link>

    <div class="mb-2">
      <span class="custom-headline custom-headline--level2-bold">
        {{ $t('auth.registerNewAccount') }}
      </span>
    </div>

    <RegistrationForm />
  </el-card>
</template>

<script>
import RegistrationForm from './RegistrationForm';

export default {
  name: 'RegistrationFormCard',

  components: {
    RegistrationForm
  },

  props: {
    hideCloseIcon: Boolean
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/auth-form.scss";
</style>
