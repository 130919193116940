<template>
  <div class="ware-main-info">
    <el-row v-if="!ware.price" justify="space-between">
      <span class="color-green">
        {{ $t("search.not_in_stock") }}
      </span>

      <el-button disabled type="primary">
        {{ $t("search.searchVariants") }}
      </el-button>
    </el-row>

    <div v-else>
      <div :class="isMobile ? 'mb-1' : 'mb-2'">
        <el-row
          justify="space-between"
          align="middle"
          :class="isMobile ? 'mb-1' : 'mb-2'"
        >
          <el-row align="middle" class="price">
            <div class="price__value mr-05">
              {{ getPriceNum(ware.price) }}
            </div>

            <div>
              <div
                v-if="ware.other_selling_terms.includes('former_use')"
                class="color-red"
              >
                {{ $t("other_selling_terms.former_use") }}
              </div>
              <div>
                {{ MARKETPLACE_CURRENCY }}
              </div>
            </div>
          </el-row>

          <div class="buy-btn">
            <el-button
              v-if="ware.price"
              v-observe-visibility="toggleBuyButton"
              type="primary"
              class="w-100 mb-05"
              :loading="isLoading"
              @click="$emit('basket-click', ware)"
            >
              {{ $t("buttons.buy") }}
            </el-button>

            <el-row
              v-if="
                ware.delivery_methods.includes('free_shipping') && !isMobile
              "
              justify="center"
              class="color-red"
            >
              {{ $t("basket.freeShipping") }}
            </el-row>
          </div>
        </el-row>

        <div class="delivery">
          <el-row align="middle" class="mr-05">
            <div class="mr-05">{{ $t("search.availabilityAtSeller") }}:</div>
            <DeliveryTimeString
              class="bold"
              :options="{
                suffix: true,
                isEmpty: !ware.price,
                ware,
                warning: true,
              }"
              :value="ware.delivery_time"
            />
          </el-row>

          <div
            v-if="ware.delivery_methods.includes('free_shipping') && isMobile"
            class="color-red"
          >
            {{ $t("basket.freeShipping") }}
          </div>
        </div>
      </div>

      <div v-if="get(ware, 'seller.business.name')" class="seller-section mb-2">
        <div class="mr-1 bold">{{ $t("ware.wareSeller") }}:</div>

        <div class="mr-1">
          <el-link
            type="primary"
            class="analytics-sale-point-info-link"
            @click="
              $emit('sale-point:info', {
                id: ware.point_of_sale.id,
                ware,
              })
            "
          >
            {{ get(ware, "seller.business.name", "") }}
          </el-link>
        </div>

        <div class="text-ghost mr-1">
          ({{ get(ware, "point_of_sale.address.city.name", "") }})
        </div>

        <TheRate :value="ware.seller.rank" />
      </div>

      <OneClickOrder
        v-if="ware.seller.enable_one_click_order"
        hide-desc
        class="mb-2"
        :title="$t('orders.fastOrder')"
        :items="[oneClickWare]"
        item-list-name="Product Page"
      />

      <div class="mb-1">
        <div class="option__label mb-1">{{ $t("ware.delivery") }}:</div>

        <ul class="mr-list">
          <li v-for="code in ware.delivery_methods" :key="code">
            {{ $t(`delivery_methods.${code}`) }}
          </li>
        </ul>
      </div>

      <div>
        <div class="option__label mb-1">{{ $t("ware.payment") }}:</div>

        <ul class="mr-list">
          <li v-for="code in ware.payment_methods" :key="code">
            {{ $t(`payment_methods.${code}`) }}
          </li>
        </ul>
      </div>

      <WareBuyFooter
        v-if="isBuyFooter"
        :ware="ware"
        @basket-click="$emit('basket-click', ware)"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getApiUrl } from '../../../core/services/parsers';
import { parsers, env } from '@/lib/core';

import DeliveryTimeString from '@/lib/orders/components/DeliveryTimeString';
import TheRate from '@/lib/core/components/TheRate';

export default {
  name: 'WareMainInfo',

  components: {
    DeliveryTimeString,
    TheRate,
    /* eslint-disable max-len */
    OneClickOrder: () =>
      import(
        /* webpackChunkName: "OneClickOrder" */ '@/lib/orders/components/OneClickOrder'
      ),
    WareBuyFooter: () =>
      import(/* webpackChunkName: "WareBuyFooter" */ './WareBuyFooter')
    /* eslint-enable max-len */
  },

  props: {
    isLoading: Boolean,
    ware: { type: Object, required: true }
  },

  data () {
    return {
      isBuyFooter: false,
      MARKETPLACE_CURRENCY: env.MARKETPLACE_CURRENCY
    };
  },

  computed: {
    isMobile () {
      return this.maxmq('xs');
    },

    oneClickWare () {
      return {
        ...this.ware,
        point_of_sale: getApiUrl('points-of-sale', this.ware.point_of_sale.id),
        seller: getApiUrl('sellers', this.ware.seller.id)
      };
    }
  },

  methods: {
    get,
    isEmpty,
    getPriceNum: parsers.getPriceNum,

    toggleBuyButton (isVisible) {
      if (
        this.$store.getters.isAppLoaded &&
        this.ware.price &&
        this.maxmq('md')
      ) {
        this.isBuyFooter = !isVisible;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.ware-main-info {
  .price {
    color: $black;
    font-weight: bold;

    &__value {
      font-size: 2.2rem;
      font-weight: bold;
    }
  }

  .buy-btn {
    width: 200px;
  }

  .delivery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .option__label {
    font-weight: bold;
    padding: 10px;
    background-color: $grey-100;
  }

  .seller-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    border: 1px solid $grey-300;
  }

  .mr-list {
    padding-left: 10px;

    li {
      list-style-type: none;
      margin-bottom: 10px;
    }
  }

  .one-click-oder {
    border-radius: 4px;
  }

  @media all and (max-width: $--xss) {
    .buy-btn {
      width: unset;
    }
  }
}
</style>
