<template>
  <div class="block-size" :style="boxSize">
    <slot>
      <img
        v-lazy-load
        class="img"
        :data-src="srcOrPh"
        :width="boxSize.width"
        :alt="alt"
        :title="alt"
        :height="boxSize.height"
      >
    </slot>
  </div>
</template>

<script>
const SIZES = {
  xs: 20,
  ml: 36,
  md: 60,
  ls: 80,
  lg: 100,
  xxl: 200,
  xxx: 250,
  120: 120,
  150: 150,
  400: 400,
  300: 300
};

export default {
  name: 'BlockSize',

  props: {
    src: String,
    alt: String,
    size: { type: String, default: 'md' },
    defaultSrc: {
      type: String,
      default: () => require('@/assets/icons/avatar-placeholder-2.svg')
    }
  },

  computed: {
    srcOrPh () {
      // eslint-disable-next-line
      return this.src || this.defaultSrc
    },

    boxSize () {
      if (this.size === 'max') {
        return { height: 'auto', width: '100%' };
      }

      const size = `${SIZES[this.size]}px`;

      return { height: size, width: size, maxWidth: '100%' };
    }
  }
};
</script>

<style scoped lang="scss">
.block-size {
  display: flex;

  .img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>
