<template>
  <div class="wares-tile">
    <div class="mr-table">
      <div v-if="isShowOrdering" class="mr-table-header">
        <div v-show="!maxmq('xs')" class="mr-1">
          {{ $t('search.doSort') }}
        </div>

        <el-button-group>
          <el-button
            size="mini"
            :type="activeOrdering === 'price,-rank' ? 'primary' : null"
            @click="toggleOrdering('price,-rank')"
          >
            {{ $t('search.lowerCost') }}
          </el-button>
          <el-button
            size="mini"
            :type="activeOrdering === '-price,-rank' ? 'primary' : null"
            @click="toggleOrdering('-price,-rank')"
          >
            {{ $t('search.higherCost') }}
          </el-button>
          <el-button
            size="mini"
            :type="activeOrdering === '-rank' ? 'primary' : null"
            @click="toggleOrdering('-rank')"
          >
            {{ $t('search.higher') }}
          </el-button>
        </el-button-group>
      </div>

      <section>
        <el-row
          v-if="!analogsRes.results.length"
          class="pg-2 text-center color-default"
          justify="center"
        >
          {{ $t('search.emptyResult') }}
        </el-row>

        <ul v-else class="list">
          <li v-for="ware in analogsRes.results" :key="ware.id">
            <WareSmallCard
              :value="ware"
              @basket-click="$emit('basket-click', $event)"
              @sale-point:info="$emit('sale-point:info', $event)"
            />
          </li>
        </ul>
      </section>
    </div>

    <el-row class="mt-2" justify="center">
      <el-pagination
        background
        hide-on-single-page
        use-seo-link
        layout="prev, pager, next"
        :current-page="page"
        :page-size="PAGINATION_SIZE"
        :pager-count="5"
        :total="analogsRes.count - 1"
      />
    </el-row>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import getSeoLinkBy from '../services/getSeoLinkBy';

import eventBus from '@/lib/core/services/eventBus';
import env from '@/lib/core/services/envConstants';

import WareSmallCard from '@/lib/catalog/components/WareSmallCard';

export default {
  name: 'WaresTile',

  components: {
    /* eslint-disable max-len */
    ElPagination: () => import(/* webpackChunkName: "ElPagination" */ '@/lib/core/components/ElPagination'),
    /* eslint-enable max-len */
    WareSmallCard
  },

  props: {
    isShowOrdering: Boolean,
    analogsRes: { type: Object, required: true }
  },

  data: () => ({
    PAGINATION_SIZE: env.SMALL_PAGINATION_SIZE
  }),

  computed: {
    activeOrdering () {
      return this.ordering && this.ordering.length
        ? this.ordering.toString()
        : '-rank';
    },

    page () {
      const page = this.$route.params.page || this.$route.query.page;
      return Number.parseInt(page) || 1;
    },

    ordering: {
      get () {
        const og = this.$route.query.og;
        return og ? og.split(',') : [];
      },

      set (value) {
        // if result more then PAGINATION_SIZE
        const { og, ...query } = this.$route.query;

        if (!isEmpty(value)) {
          query.og = value.toString();
        }

        this.$router.push({ query });
      }
    }
  },

  methods: {
    async toggleOrdering (ordering) {
      const { page, og, ...query } = this.$route.query;

      if (isEmpty(query)) {
        await this.$router.push({
          query: {
            og: ordering === og ? undefined : ordering
          }
        });

        return this.$nuxt.refresh();
      }

      if (this.ordering && ordering !== this.activeOrdering) {
        const _ordering = ordering.split(',');

        if (!isEqual(_ordering, this.ordering)) {
          query.og = _ordering.toString();
        }

        const _p = { ...this.$route.params, page: null };

        const data = getSeoLinkBy(_p, this, _p);

        eventBus.$emit('filter:reload-page', { ...data, query });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.mr-table {
  border: 1px solid $grey-300;

  .mr-table-header {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $grey-300;
  }

  .ware-small-card {
    display: block;
    width: 100%;
    border: none;
    border-right: 1px solid $grey-300;
    border-bottom: 1px solid $grey-300;
    padding: 20px;

    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
  }

  .list li {
    @media all and (min-width: 1500px) {
      width: 20%;
    }

    @media all and (max-width: 1500px) {
      width: 25%;
    }

    @media all and (max-width: $--lg) {
      width: 33.33%;
    }

    @media all and (max-width: 950px) {
      width: 50%;
    }

    @media all and (max-width: $--md) {
      width: 33.33%;
    }

    @media all and (max-width: $--xs) {
      width: 50%;
      padding: 10px;
    }
  }
}

</style>
