<template>
  <div>
    <TheLoader v-if="isLoading" />

    <template v-else>
      <el-row
        justify="space-between"
        align="middle"
        class="mb-2"
      >
        <h1 class="custom-headline custom-headline--level2-bold mr-2">
          {{ $t(`routes.garage_${formData.id ? "edit" : "create"}`) }}
          {{ formData.title }}
        </h1>

        <div v-if="!isMobile">
          <el-button size="mini" @click="toGarage">
            {{ $t("buttons.goToGarage") }}
          </el-button>
        </div>
      </el-row>

      <el-form
        ref="form"
        :label-position="maxmq('ss') ? 'top' : 'right'"
        :model="formData"
        :rules="rules"
        label-suffix=":"
        size="medium"
        @submit.native.prevent="checkAndSubmit"
      >
        <CarFactorySelector
          v-if="$store.getters.isAppLoaded"
          :value="formData"
          v-bind.sync="formData"
        />

        <div v-if="step === 2" class="mt-2 mb-4">
          <el-form-item
            label-width="130px"
            :label="$t('garage.main.vin')"
            prop="vin"
          >
            <el-input v-model="formData.vin" clearable class="vin-select" />
          </el-form-item>
        </div>

        <CarMetaInfo
          v-if="step === 3"
          class="mt-2 mb-4"
          :value="formData"
          v-bind.sync="formData"
          @vin-edit="isVinPopup = true"
        />

        <CarInfoCollapse
          v-if="step === 3"
          class="mt-2 mb-4"
          :value="formData"
          v-bind.sync="formData"
        />

        <el-row v-if="formData.id" justify="space-between">
          <el-button type="danger" @click="confirmAndRemove">
            {{ $t('buttons.remove') }}
          </el-button>

          <el-row>
            <el-button @click="toGarage">
              {{ $t('buttons.cancel') }}
            </el-button>
            <el-button
              native-type="submit"
              type="primary"
              :loading="isVLoading"
              :disabled="isEqual"
            >
              {{ $t('buttons.save') }}
            </el-button>
          </el-row>
        </el-row>

        <el-row
          v-else-if="formData.modification"
          :justify="isMobile ? 'center' : 'end'"
        >
          <el-button
            native-type="submit"
            type="primary"
            :class="{ 'w-100': isMobile }"
            :loading="isVLoading"
          >
            {{ $t('buttons.addCar') }}
          </el-button>
        </el-row>
      </el-form>

      <TheDialog v-model="isVinPopup" width="600px" :title="$t('garage.main.vin')">
        <VinConfirmForm
          :car="formData"
          hide-desc
          :submit="checkCarDependencyAndSaveVin"
          :submit-btn-text="$t('buttons.save')"
          @cancel="isVinPopup = false"
        />
      </TheDialog>
    </template>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import CarFactorySelector from './components/CarFactorySelector';
import { getDefault, getValidationFor, scrollToTop } from '@/lib/core';
import transforms from '@/lib/core/validations/transforms';

const strictFields = ['productionYear', 'engineCapacity'];

export default {
  name: 'CarEditPage',

  components: {
    CarFactorySelector,
    /* eslint-disable max-len */
    CarMetaInfo: () => import(/* webpackChunkName: "CarMetaInfo" */ './components/CarMetaInfo'),
    CarInfoCollapse: () => import(/* webpackChunkName: "CarInfoCollapse" */ './components/CarInfoCollapse'),
    VinConfirmForm: () => import(/* webpackChunkName: "VinConfirmForm" */ './components/VinConfirmForm')
    /* eslint-enable max-len */
  },

  asyncData ({ store }) {
    return store.dispatch('cache/get', { action: 'carBrands/fetchBrands' });
  },

  data () {
    return {
      isEqual: true,
      isLoading: true,
      isVLoading: false,
      isVinPopup: false,
      formData: getDefault('car'),
      originFormData: getDefault('car'),
      rules: getValidationFor(null, 'car', this)
    };
  },

  computed: {
    isMobile () {
      return this.maxmq('xs');
    },

    step () {
      if (!this.formData.modification) {
        return 1;
      } else if (!this.originFormData.vin) {
        return 2;
      }
      return 3;
    }
  },

  watch: {
    formData: {
      deep: true,
      handler () {
        this.isEqual = isEqual(this.originFormData, this.formData);
      }
    },

    '$route.params.id': {
      immediate: true,
      handler () {
        this.isLoading = true;

        if (this.$route.params.id) {
          this.load(Number(this.$route.params.id));
        } else {
          this.isLoading = false;
        }
      }
    }
  },

  methods: {
    async load (id) {
      this.isLoading = true;

      try {
        const car = await this.$store.dispatch('cars/getCarQL', { id });

        car.gearSteps = car.gearSteps || 0;

        this.originFormData = {
          ...this.formData,
          ...car
        };
        this.formData = cloneDeep(this.originFormData);
      } catch (e) {
        console.error(e);
        this.toGarage();
      } finally {
        this.isLoading = false;
      }
    },

    checkAndSubmit () {
      this.$refs.form.validate((isValid) => {
        if (isValid && this.formData.modification) {
          this.submit(this.formData);
        } else {
          scrollToTop('is-error');
          return false;
        }
      });
    },

    async submit (formData, options) {
      this.isVLoading = true;

      const {
        id,
        photo,
        ..._formData
      } = this.getClear(formData, options);

      const action = id ? 'update' : 'create';

      try {
        const car = await this.$store.dispatch(
          `cars/${action}CarQL`,
          { id, formData: _formData }
        );

        if (!this.formData.id) {
          return this.$router.push({
            name: this.getRouteName('garage.edit'),
            params: { id: car.id }
          });
        }

        car.gearSteps = car.gearSteps || 0;

        this.originFormData = car;
        this.formData = cloneDeep(this.originFormData);

        this.$notify.info({
          message: this.$t('message.changesSaved'),
          duration: 5000
        });

        scrollToTop();
        return car;
      } catch (e) {
        console.error(e);
      } finally {
        this.isVLoading = false;
      }
    },

    getClear (formData, options) {
      const _formData = Object.entries(formData).reduce((acc, [key, val]) => {
        // TODO clear it!!!
        if (options?.clone && !key.startsWith('_') && val) {
          if (key === 'vin') {
            acc[key] = transforms.vinReplacer(val);
          } else if (typeof val === 'object') {
            acc[key] = val.id;
          } else {
            acc[key] = val;
          }

          return acc;
        }

        if (
          (val && !isEqual(val, this.originFormData[key])) ||
          (typeof val === 'boolean' && this.originFormData[key] !== val) ||
          strictFields.includes(key) ||
          key === 'id'
        ) {
          if (key === 'vin') {
            acc[key] = transforms.vinReplacer(val);
          } else if (typeof val === 'object') {
            acc[key] = val.id;
          } else {
            acc[key] = val;
          }
        }

        return acc;
      }, {});

      _formData.name = _formData.name ||
        `${formData.productionYear} ${formData.brand?.name} ${formData.model?.name}`;

      return _formData;
    },

    async confirmAndRemove () {
      const { id, title } = this.formData;

      if (!id) {
        return this.toGarage();
      }

      const name = title || this.$t('garage.auto');
      const isConfirm = await this.$confirm(
        this.$t('garage.removeConfirm', { name }),
        { type: 'warning', title: this.$t('message.warning') }
      );

      if (isConfirm) {
        const isSuccess = await this.remove(this.formData);

        if (isSuccess) {
          this.toGarage();
        }
      }
    },

    async remove (car) {
      this.isLoading = true;

      try {
        await this.$store.dispatch('cars/deleteCarQl', car);

        if (car.modification) {
          this.removeIdFromCookie(car.modification);
        }

        return car;
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },

    removeIdFromCookie (id) {
      const pl = {};

      if (id === this.$store.getters['carModifications/modificationId']) {
        pl.carData = null;
      }

      pl.cars = this.$store.getters['carModifications/savedCars']
        .filter(it => it._modificationId !== id);

      return this.$store.dispatch('carModifications/setCarData', pl);
    },

    toGarage () {
      return this.$router.push({ name: this.getRouteName('garage') });
    },

    async checkCarDependencyAndSaveVin ({ car, vin }) {
      const newCarData = { ...this.formData, vin };

      try {
        const requests = await this.$store.dispatch(
          'selectionRequests/getVinRequestsQL',
          { car: car.id }
        );

        if (!requests.length) {
          await this.submit(newCarData);
          this.isVinPopup = false;
          return null;
        }

        const { id, ..._car } = newCarData;
        const newCar = await this.submit(_car, { clone: true });
        await this.remove(car);

        await this.$router.push({
          name: this.getRouteName('garage.edit'),
          params: { id: newCar.id }
        });

        this.isVinPopup = false;
      } catch (e) {
        console.error(e);
      }
    }
  },

  validate ({ route, params }) {
    return route.name.includes('create') || /^\d+$/.test(params.id);
  }
};
</script>

<style scoped lang="scss">
.vin-select {
  width: 180px;
  max-width: 100%;

  @media all and (max-width: $--ss) {
    width: 100%;
  }
}
</style>
