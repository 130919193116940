var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buyer-orders-page min-page-height" },
    [
      _c("BuyerOrdersTable" + (_vm.maxmq("md") ? "M" : ""), {
        tag: "component",
        attrs: {
          "use-page": "",
          load: _vm.loadOrderItems,
          "prop-count": _vm.count,
          "prop-value": _vm.items,
        },
        on: {
          "item:update": _vm.updateItem,
          "sale-point:info": _vm.showSalePoint,
        },
      }),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: { title: _vm.$t("orders.salePointInfo") },
          model: {
            value: _vm.isSalePointInfo,
            callback: function ($$v) {
              _vm.isSalePointInfo = $$v
            },
            expression: "isSalePointInfo",
          },
        },
        [
          _vm.isSalePointInfo
            ? _c("SalePointInfo", { attrs: { id: _vm.isSalePointInfo } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }