import { env } from '@/lib/core';

export const actions = {
  getTransactions (_, { limit, page, business } = {}) {
    return this.$axios.$get(
      '/balance/',
      {
        params: {
          offset: env.PAGINATION_SIZE * ((page || 1) - 1),
          limit: limit || env.PAGINATION_SIZE,
          business,
          ordering: '-created'
        }
      });
  },

  getContracts (_, { business }) {
    return this.$axios.$get('/contracts/', { params: { business } });
  },

  generateInvoice (_, formData) {
    return this.$axios.$post('/invoices/', formData);
  },

  getInvoice (_, { id, business }) {
    const params = { business };
    return this.$axios.$get(`/invoices/${id}`, { params });
  }
};
