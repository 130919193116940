<template>
  <div class="video-preview">
    <video v-if="!previewIcon" controls>
      <source :src="value.url">
    </video>
    <img v-else src="@/assets/fileTypes/video-icon.svg">
  </div>
</template>

<script>
export default {
  name: 'VideoPreview',

  props: {
    value: {
      type: Object, required: true
    },
    previewIcon: Boolean
  }
};
</script>

<style scoped lang="scss">
.video-preview {
  display: flex;
  justify-content: center;
  background: $grey-200;

  * {
    max-width: 100%;
  }

  video {
    height: auto;
  }

  img {
    opacity: .7;
  }

  &:hover img {
    opacity: 1;
  }
}
</style>
