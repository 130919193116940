<template>
  <div class="scan-files-requisites-block">
    <el-upload
      ref="upload"
      action="https://mp-api.dev.mriyar.ua/"
      :auto-upload="false"
      drag
      :on-change="changeHandler"
      :on-remove="clear"
      :show-file-list="false"
    >
      <div class="upload-input">{{ imgName }}</div>
      <i class="el-icon-upload2" />
    </el-upload>

    <div class="controls">
      <i v-if="imgSrc" class="el-icon-delete" @click="deleteImg" />
      <i
        v-if="imgSrc"
        class="el-icon-picture-outline"
        @click="dialogVisible = true"
      />
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img alt="IMG" :src="imgSrc" width="100%">
    </el-dialog>
  </div>
</template>

<script>
import ElUpload from 'element-ui/lib/upload';

const formats = ['image/*', 'application/pdf'];

export default {
  name: 'ScanFilesRequisitesBlock',

  components: {
    ElUpload
  },

  props: {
    label: String,
    value: null
  },

  data () {
    return {
      rules: [1, 2, 3].map(num => this.$t(`files.priceRules.${num}`)),
      dialogVisible: false
    };
  },

  computed: {
    imgSrc () {
      if (typeof this.value === 'string') {
        return this.value;
      }

      return this.value ? URL.createObjectURL(this.value) : null;
    },

    imgName () {
      if (!this.value) {
        return '';
      }

      const name = typeof this.value === 'string'
        ? this.value
        : this.value.name;

      return `...${name.substring(name.length - 30)}`;
    }
  },

  methods: {
    changeHandler (file) {
      if (!formats.includes(file.raw.type) && (!file.raw.type && !this.testName(file.name))) {
        this.$notify.error({
          message: this.$t('validation.price.format', { format: formats.toString() })
        });

        this.$refs.upload.clearFiles();
      } else {
        this.$emit('input', file.raw);
      }
    },

    deleteImg () {
      this.$emit('input', null);
    },

    clear () {
      this.price_file = null;
    },

    testName (name) {
      // eslint-disable-next-line no-undef,no-useless-escape
      return nameFormats.some(format => (new RegExp(`(\.${format})$`).test(name)));
    }
  }
};
</script>

<style scoped lang="scss">
.scan-files-requisites-block {
  display: flex;

  ::v-deep .el-upload {
    width: 100%;

    .el-upload-dragger {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 320px;
      border: none;

      .upload-input {
        height: 32px;
        display: flex;
        margin-left: 4px;
        align-items: center;
        justify-content: center;
        flex: 1;
        border-radius: 4px;
        border: 1px solid $--border-color-base;
      }
    }
  }

  .label {
    max-width: 40%;
    width: 400px;
    font-size: 14px;
    text-align: right;
  }

  i {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 24px;
    cursor: pointer;
  }

  .controls {
    display: flex;
    align-items: center;
  }
}
</style>
