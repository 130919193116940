<template>
  <el-time-picker
    v-model="time"
    align="center"
    :class="['time-selector', { readonly }]"
    :clearable="false"
    format="HH:mm"
    size="mini"
  />
</template>

<script>
import { env } from '@/lib/core';

export default {
  name: 'TimeSelector',

  components: {
    /* eslint-disable max-len */
    ElTimePicker: () => import(/* webpackChunkName: "ElTimePicker" */ 'element-ui/lib/time-picker')
    /* eslint-enable max-len */
  },

  props: {
    required: Boolean,
    readonly: Boolean,
    defaultTime: { type: String, default: env.DEFAULT_ORDER_TIME_UNTIL },
    value: String
  },

  computed: {
    time: {
      get () {
        if (!this.value) {
          if (this.required) {
            this.$emit('input', env.DEFAULT_ORDER_TIME_UNTIL);
            return env.DEFAULT_ORDER_TIME_UNTIL;
          } else {
            return env.DEFAULT_ORDER_TIME_UNTIL;
          }
        }

        const [hours, minutes] = this.value.split(':');
        return new Date(2020, 1, 1, hours, minutes);
      },

      set (rowTime) {
        if (!rowTime) {
          return this.$emit('input', this.required ? env.DEFAULT_ORDER_TIME_UNTIL : null);
        }

        const hours = rowTime.getHours();
        const minutes = rowTime.getMinutes();

        this.$emit('input', `${hours}:${minutes}`);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.time-selector {
  width: 100px !important;

  &.readonly {
    pointer-events: none;

    ::v-deep .el-input__inner {
      background-color: $--background-color-base;
    }
  }
}
</style>
