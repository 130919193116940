<template>
  <el-col class="row-icon-controls fit-content">
    <el-button-group>
      <el-button
        circle
        :disabled="!canRemove"
        icon="el-icon-delete"
        @click="$emit('remove', index)"
      />
    </el-button-group>
  </el-col>
</template>

<script>
export default {
  name: 'RowIconControls',

  props: {
    needOne: Boolean,
    valueName: { type: String, required: true },
    index: { type: Number, required: true },
    item: { type: Object, required: true },
    list: { type: Array, required: true }
  },

  computed: {
    hasAnalog () {
      const { list, item, valueName } = this;
      return list.some((listItem) => {
        return listItem.id !== item.id && listItem[valueName];
      });
    },

    canRemove () {
      return !this.needOne || this.hasAnalog;
    }
  }
};
</script>
