<template>
  <div class="page-header-m">
    <div class="wrapper">
      <div class="row-1">
        <div class="fl-al-center">
          <div class="hamburger" @click="isShowMenu = true">
            <img
              v-lazy-load
              alt="menu"
              class="icon-img"
              src="@/assets/icons/hamburger.svg"
            >
          </div>

          <LangLinks />
        </div>

        <UserNav show-basket :hide-list="['lang']" />
      </div>

      <div class="row-2">
        <TheLogo class="pg-1" />
        <!--        <MarketplacePhones v-if="isMriyar" select />-->
      </div>

      <div class="row-3">
        <CleverSearch row size="mini" />
      </div>
    </div>

    <BusinessMenuShort />
    <Breadcrumb />

    <TheDialog v-if="$store.getters.isDomReadyState" v-model="isShowMenu">
      <div class="dialog-wrapper">
        <ul>
          <li class="mb-1">
            <nuxt-link
              class="color-label"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'payment' }
              }"
            >
              {{ $t('core.marketplacePayment') }}
            </nuxt-link>
          </li>

          <li class="mb-1">
            <nuxt-link
              class="color-label"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'delivery' }
              }"
            >
              {{ $t('core.marketplaceDelivery') }}
            </nuxt-link>
          </li>

          <li class="mb-1">
            <nuxt-link
              class="color-label"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'warranty' }
              }"
            >
              {{ $t('core.marketplaceWarranty') }}
            </nuxt-link>
          </li>

          <li class="mb-1">
            <nuxt-link
              class="color-label"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'about-us' }
              }"
            >
              {{ $t('routes.about_us') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </TheDialog>
  </div>
</template>

<script>
import env from '../services/envConstants';
import UserNav from './UserNav';
import TheLogo from './TheLogo';
// import MarketplacePhones from './MarketplacePhones';
import Breadcrumb from './Breadcrumb';
import CleverSearch from './CleverSearch';
import LangLinks from './LangLinks';

export default {
  name: 'PageHeaderM',

  components: {
    UserNav,
    TheLogo,
    LangLinks,
    // MarketplacePhones,
    CleverSearch,
    Breadcrumb,
    /* eslint-disable max-len */
    BusinessMenuShort: () => import(/* webpackChunkName: "BusinessMenuShort" */ '@/lib/profiles/components/BusinessMenuShort')
    /* eslint-enable max-len */
  },

  data: () => ({
    isShowMenu: false,
    isMriyar: env.MARKETPLACE === 'mriyar'
  }),

  computed: {
    isShowLangs () {
      return this.$i18n.availableLocales.length > 1;
    }
  },

  watch: {
    '$route.path' () {
      this.isShowMenu = false;
    }
  },

  methods: {
    toForSeller () {
      const url = 'https://forseller.mriyar.ua/';
      const lang = this.$store.getters.langCode === 'ru' ? 'ru/' : '';
      window.open(`${url}${lang}`, '_blank');
    }
  }
};
</script>

<style scoped lang="scss">
.page-header-m {
  .wrapper {
    border-bottom: 1px solid $grey-300;
  }

  .row-1 {
    display: flex;
    justify-content: space-between;

    .hamburger {
      width: 30px;
      height: 30px;

      img {
        display: block;
        margin: 6px auto;
      }
    }
  }

  .row-2 {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .the-logo {
      margin-right: 4px;
    }
  }

  .row-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 10px;
    margin-bottom: 10px;

    .clever-search {
      width: 100%;
    }
  }

  .el-select {
    height: 19px!important;
  }
}
</style>
