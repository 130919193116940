<template>
  <div @click.stop>
    <el-switch
      :active-text="label"
      :class="['price-activator', { left }]"
      :disabled="readonly"
      :title="title"
      :value="priceFile.is_active"
      @change="confirmChange"
    />
  </div>
</template>

<script>
import ElSwitch from 'element-ui/lib/switch';

export default {
  name: 'PriceActivator',

  components: {
    ElSwitch
  },

  props: {
    left: Boolean,
    showLabel: Boolean,
    readonly: Boolean,
    priceFile: { type: Object, required: true },
    submit: Function
  },

  computed: {
    title () {
      return this.$t('priceFile.isActive');
    },
    label () {
      return this.showLabel ? this.title : '';
    }
  },

  methods: {
    async confirmChange (is_active) {
      const isAgree = await this.$confirm(
        this.$t('message.confirmChangePriceActivity'),
        {
          type: 'warning',
          title: this.$t('message.warning')
        }
      );

      if (isAgree) {
        return this.emitOrSubmit(is_active);
      }
    },

    async emitOrSubmit (is_active) {
      if (this.submit) {
        return this.submit(is_active);
      }

      try {
        const priceFile = await this.$store.dispatch('priceFiles/patchPrice', {
          id: this.priceFile.id,
          formData: { is_active },
          push: is_active
        });

        this.$emit('update', priceFile.is_active);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.price-activator {
  ::v-deep .is-active {
    color: $--color-text-primary;
  }

  &.left {
    display: flex;
    flex-direction: row-reverse;

    ::v-deep .el-switch__core {
      margin: 0 0 0 4px;
    }
  }
}
</style>
