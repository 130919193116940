<template>
  <div class="prices-upload-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_prices_upload') }}
    </h1>

    <PriceCreateSteps />

    <el-form :model="formData" @submit.native.prevent="submit">
      <div class="input-wrapper">
        <h3 class="mb-1 text-center custom-headline custom-headline--level3">
          {{ $t('prices.priceLoadAction') }}
        </h3>

        <div class="point-options">
          <p class="description mb-1">
            {{ $t('prices.priceLoadOption1Description2') }}
          </p>

          <PriceUploader class="mb-3" @input="setFile" />
        </div>
      </div>

      <el-button
        :disabled="!file"
        :loading="isLoading"
        native-type="submit"
        type="primary"
      >
        {{ $t('el.upload.continue') }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import PriceUploader from './components/PriceUploader';
import PriceCreateSteps from './components/PriceCreateSteps';
import { CUT_LAST_DOTH_REG } from '@/lib/core/validations/patterns';
import { getDefault } from '@/lib/core/services/defaultEntities';

export default {
  name: 'PriceUploadPage',

  components: {
    PriceCreateSteps,
    PriceUploader
  },

  data: () => ({
    file: null,
    isLoading: false,
    formData: getDefault('priceUpload')
  }),

  computed: {
    business () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'];
    }
  },

  methods: {
    setFile (file) {
      if (file) {
        this.file = file.raw;
        this.formData.filename = file.name.split(CUT_LAST_DOTH_REG)[0];
      } else {
        this.file = null;
        this.formData.filename = '';
      }

      if (this.file) {
        this.submit();
      }
    },

    async submit () {
      this.isLoading = true;

      try {
        const price = await this.$store.dispatch(
          'priceUploads/postPriceUpload',
          {
            formData: {
              ...this.formData,
              business: this.business.url
            }
          }
        );

        if (!price.upload_url) {
          this.showError(price);
          throw new Error('NO UPLOAD_URL!!!');
        }

        // do not wait https://mriyar.atlassian.net/browse/MBP-4425
        this.postFile(price, this.file);

        // debounce for fix upload small files on server
        setTimeout(() => {
          this.$router.push({
            name: this.getRouteName('profile.business.prices.create'),
            params: { id: price.id }
          });
        }, 1000);
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },

    showError (price) {
      this.$notify.error({
        dangerouslyUseHTMLString: true,
        title: this.$t('errors.default.title'),
        message: `
          ${this.$t('prices.status')}: ${price.status}, <br>
          ${this.$t('prices.error_code')}: ${price.error_code}, <br>
          ${this.$t('prices.error_message')}: ${price.error_message}
        `
      });
    },

    async postFile (price, file) {
      try {
        await this.$store.dispatch('priceUploads/postPriceToS3', {
          url: price.upload_url,
          file,
          upload_url_post_data: price.upload_url_post_data,
          businessId: this.business.id,
          priceUploadId: price.id
        });

        return await this.$store.dispatch('priceUploads/patchPrice', {
          id: price.id,
          formData: { status: 'done' },
          push: true
        });
      } catch (e) {
        console.error(e);

        this.$store.dispatch('setProgress');

        return await this.$store.dispatch('priceUploads/patchPrice', {
          id: price.id,
          formData: { status: 'error' }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.prices-upload-page {
  .input-wrapper {
    padding: 20px;
    margin-bottom: 40px;
    border: 1px dashed $grey-300;
  }

  .el-button {
    display: block;
    margin: 0 auto;
  }
}
</style>
