<template>
  <div class="aside-business-menu">
    <el-menu :collapse="isCollapsed" router>
      <header class="nav-header">
        <img
          class="nav-header-icon"
          src="@/assets/icons/hamburger.svg"
          @click="isCollapsed = !isCollapsed"
        >
        <span v-if="!isCollapsed">{{ businessProfile.name }}</span>
      </header>

      <el-menu-item
        index="profile.business.prices"
        :route="{ name: getRouteName('profile.business.prices') }"
      >
        <i class="el-icon-document" />
        <span v-if="!isCollapsed">
          {{ $t('routes.profile_business_prices') }}
        </span>
      </el-menu-item>

      <el-menu-item
        index="profile.business.sale-points"
        :route="{ name: getRouteName('profile.business.sale-points') }"
      >
        <i class="el-icon-location-outline" />
        <span v-if="!isCollapsed">
          {{ $t('routes.profile_business_sale_points') }}
        </span>
      </el-menu-item>

      <el-menu-item
        index="profile.business.edit"
        :route="{
          name: getRouteName('profile.business.edit'),
          params: { id: businessProfile.id }
        }"
      >
        <img class="el-icon- icon-img" src="@/assets/icons/currency-usd.svg">
        <span v-if="!isCollapsed">{{ $t('routes.profile_business_edit') }}</span>
      </el-menu-item>

      <el-submenu index="profile.finance">
        <template slot="title">
          <img class="el-icon- icon-img" src="@/assets/icons/earth.svg">
          <span v-if="!isCollapsed">{{ $t('routes.finance') }}</span>
        </template>

        <el-menu-item
          index="profile.finance.platforms"
          :route="{
            name: getRouteName('profile.business.platforms'),
            params: { id: businessProfile.id }
          }"
        >
          <span> - {{ $t('routes.profile_business_platforms') }}</span>
        </el-menu-item>

        <el-menu-item
          index="profile.finance.balance"
          :route="{
            name: getRouteName('profile.business.balance'),
            params: { id: businessProfile.id }
          }"
        >
          <span> - {{ $t('routes.profile_business_balance') }}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'BusinessMenu',

  computed: {
    businessProfile () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'];
    },

    isCollapsed: {
      get () {
        return this.$store.getters.isCollapsedMenu;
      },
      set (mode) {
        this.$store.dispatch('setMenuMode', mode);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.aside-business-menu {
  height: 100%;
  border-right: 1px solid $grey-300;

  hr {
    margin: 4px 0;
  }

  .nav-header {
    display: flex;
    align-items: center;
    width: 200px;
    cursor: pointer;
    border-bottom: 1px solid $grey-300;

    &-icon {
      padding: 4px 8px;
    }
  }

  .el-menu {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    height: 100%;
    overflow: hidden;
    user-select: none;
    width: 36px;

    .el-menu-item {
      padding-left: 6px !important;
      padding-right: 2px !important;
      display: flex;
      align-items: center;
      min-height: 29px;
      margin: 0;

      &.is-active {
        color: $grey-600;
      }

      &:hover,
      &:focus {
        background-color: $--menu-item-hover-fill;
      }
    }

    &:not(.el-menu--collapse) {
      width: 200px;
    }
  }

  ::v-deep {
    .el-submenu {
      &__title {
        display: flex;
        align-items: center;
        min-height: 27px;
        padding-left: 6px !important;
        padding-right: 0;
      }
    }

    .el-menu--inline {
      padding-left: 22px !important;
    }
  }
}
</style>
