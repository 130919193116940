<template>
  <div class="file-preview-list">
    <FilePreview
      :alt="altWithIndex(activeFileIndex)"
      class="mb-1 main-photo"
      :file="value[activeFileIndex || 0]"
      :size="previewSize"
      :img-path="imgPath"
      :default-img-path="defaultImgPath"
      @clickedFile="showSlider"
    />

    <div v-if="sortedFiles.length > 1" class="list-preview scroll">
      <div
        v-for="(file, index) in sortedFiles"
        :key="file.id"
        class="list-preview-item"
      >
        <FilePreview
          v-bind="$attrs"
          :alt="altWithIndex(index)"
          :class="['file', { 'disable-events': disableEvents }]"
          :file="file"
          preview-icon
          :size="size"
          :theme="theme"
          img-path="image_60x60"
          :default-img-path="defaultImgPath"
          @clickedFile="setActiveFileAt(index)"
        />
      </div>
    </div>

    <FilePreviewDialog
      v-if="isSliderShow && sortedFiles.length"
      :active-file-index="activeFileIndex || 0"
      :files="sortedFiles"
      :img-path="dialogImgPath"
      :default-img-path="defaultImgPath"
      @close="isSliderShow = false"
    >
      <template #title>
        <slot name="popupHeader" />
      </template>

      <template #footer>
        <slot name="popupFooter" />
      </template>
    </FilePreviewDialog>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import FilePreview from './FilePreview';
import FilePreviewDialog from './FilePreviewDialog';

export default {
  name: 'FilePreviewList',

  components: {
    FilePreview,
    FilePreviewDialog
  },

  props: {
    value: { type: Array, required: true },
    readonly: Boolean,
    disableEvents: Boolean,
    vertical: Boolean,
    theme: String,
    size: String,
    alt: String,
    imgPath: String,
    dialogImgPath: String,
    defaultImgPath: String,
    previewSize: { type: String, default: 'lg' }
  },

  data: () => ({
    activeFileIndex: null,
    isSliderShow: false
  }),

  computed: {
    sortedFiles () {
      return sortBy(this.value, 'type');
    }
  },

  methods: {
    showSlider () {
      this.isSliderShow = true;
    },

    altWithIndex (index) {
      return this.alt +
        `${Number(index) === index ? this.$t('ware.photo') + `${index + 1}` : ''}`;
    },

    setActiveFileAt (index) {
      this.activeFileIndex = index;
    }
  }
};
</script>

<style scoped lang="scss">
.file-preview-list {
  max-width: 100%;

  .file-preview {
    margin: 0 auto;
  }

  .list-preview {
    display: flex;
    width: 100%;
    padding: 2px;
    overflow: auto;

    &-item {
      margin-right: 4px;
    }
  }

  .el-input {
    margin-left: 10px;
  }
}
</style>
