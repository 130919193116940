<template>
  <div class="sale-point-options-view">
    <div class="fl-wrap">
      <div
        v-for="(data, listName) in options"
        :key="listName"
        class="mr-4"
      >
        <div class="label mb-1">
          {{ $t(`labels.${listName}`) }}:
        </div>

        <ul class="pl-2">
          <li v-for="item in data.list" :key="item">
            {{ $t(`${listName}.${item}`) }}
          </li>
        </ul>

        <div
          v-if="data.textField && value[data.textField]"
          :key="`${listName}_text`"
          class="mt-2"
        >
          <div class="text-ghost mb-05">
            {{ $t(`salePoint.${data.textField}`) }}
          </div>

          <el-input
            :rows="4"
            disabled
            type="textarea"
            :value="value[data.textField]"
          />
        </div>
      </div>
    </div>

    <div
      v-if="value.is_return_possible && value.return_terms_text"
      class="mt-2"
    >
      <div class="mb-05 text-ghost">
        {{ $t('salePoint.return_terms_text') }}:
      </div>
      <div>{{ value.return_terms_text }}</div>
    </div>

    <div v-if="value.is_warranted && value.warranty_terms_text" class="mt-2">
      <div class="mb-05 text-ghost">
        {{ $t('salePoint.warranty_terms_text') }}:
      </div>
      <div>{{ value.warranty_terms_text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalePointOptionsView',

  props: {
    value: { type: Object, required: true }
  },

  computed: {
    options () {
      const {
        payment_methods,
        delivery_methods,
        other_selling_terms
      } = this.value;

      return {
        payment_methods: {
          list: payment_methods,
          textField: 'payment_methods_text'
        },
        delivery_methods: {
          list: delivery_methods,
          textField: 'delivery_methods_text'
        },
        other_selling_terms: {
          list: other_selling_terms
        }
      };
    }
  }
};
</script>

<style scoped lang="scss">
.sale-point-options-view {
  .label {
    font-weight: bold;
    color: $--color-text-regular;
  }

  li {
    list-style: disc;
  }
}
</style>
