<template>
  <div v-if="mode === 'title'" class="ware-title">
    <nuxt-link :to="wareLink">
      <h1 class="custom-headline custom-headline--level2">
        <span class="bold">
          {{ tmName }} <br v-if="useTmBr"> {{ acName }}
        </span>

        <br v-if="useBr">

        <span :class="{ 'text-small-2': doubleName }">
          {{ ware.display_title || ware.name }}
        </span>

        <small>
          <el-link class="text-small-2" type="primary">
            ({{ $t('ware.details') }})
          </el-link>
        </small>
      </h1>
    </nuxt-link>
  </div>

  <div v-else-if="mode === 'info'" class="ware-title">
    <div class="tm bold color-black">
      {{ tmName }}
    </div>

    <el-row class="mb-05" align="middle">
      <el-link
        class="mr-1 block ac"
        type="primary"
        @click.stop="$router.push(wareLink)"
      >
        {{ acName }}
      </el-link>

      <WareInfoIcons
        v-if="isShowIcons"
        class="op-4"
        google-search
        :ware="icons || ware"
        @show="$emit('info:show', ware)"
      />
    </el-row>

    <div class="text-small-2">
      {{ ware.display_title || ware.name }}
    </div>
  </div>
</template>

<script>
import WareInfoIcons from './wareInfo/WareInfoIcons';
import getWareLink from '@/lib/catalog/services/getWareLink';

export default {
  name: 'WareTitle',

  components: {
    WareInfoIcons
  },

  props: {
    isShowIcons: Boolean,
    useBr: Boolean,
    useTmBr: Boolean,
    doubleName: Boolean,
    mode: { type: String, default: 'title' },
    ware: { type: Object, required: true },
    icons: Object
  },

  computed: {
    wareLink () {
      return getWareLink(this, this.ware);
    },

    tmName () {
      const { display_trademark, trademark } = this.ware;
      const name = typeof trademark === 'string'
        ? trademark
        : trademark?.name;

      return display_trademark || name || '';
    },

    acName () {
      const { display_article, article } = this.ware;
      return display_article || article || '';
    }
  }
};
</script>
<style scoped lang="scss">
.tm {
  font-size: 1.1rem;
}

.ac {
  font-size: 1rem;
}
</style>
