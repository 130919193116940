var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "price-txt-type-form" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("priceFile.csvTitle", { name: _vm.format.title }))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c(
          "el-form-item",
          {
            attrs: {
              label: _vm.format.attributes[0].title,
              "label-width": "40%",
              prop: _vm.format.attributes[0].code,
            },
          },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: _vm.$t("placeholders.chooseOrEnter"),
                  effect: "light",
                  manual: !_vm.format.attributes[0].allowCreate,
                  placement: "right",
                },
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      "allow-create": _vm.format.attributes[0].allowCreate,
                      "default-first-option": "",
                      filterable: "",
                      value: _vm.value[_vm.format.attributes[0].code],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.emit(_vm.format.attributes[0].code, $event)
                      },
                    },
                  },
                  _vm._l(_vm.format.attributes[0].options, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.value.file_encoding !== "auto"
          ? _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.format.attributes[1].title,
                  "label-width": "40%",
                  prop: _vm.format.attributes[1].code,
                },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("placeholders.chooseOrEnter"),
                      effect: "light",
                      manual: !_vm.format.attributes[1].allowCreate,
                      placement: "right",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          "allow-create": _vm.format.attributes[1].allowCreate,
                          "default-first-option": "",
                          filterable: "",
                          value: _vm.value[_vm.format.attributes[1].code],
                        },
                        on: {
                          change: function ($event) {
                            return _vm.emit(
                              _vm.format.attributes[1].code,
                              $event
                            )
                          },
                        },
                      },
                      _vm._l(_vm.format.attributes[1].options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }