var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ware
    ? _c(
        "div",
        { staticClass: "ware-page" },
        [
          _c(
            "h1",
            { staticClass: "custom-headline custom-headline--level2-bold" },
            [_vm._v("\n    " + _vm._s(_vm.wareTitle) + "\n  ")]
          ),
          _vm._v(" "),
          _c("AnchorsNav", {
            class: { "mb-2": !_vm.isMobile },
            attrs: { entity: "ware", value: _vm.anchors },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["mr-block main-info", _vm.isMobile ? "mb-2" : "mb-4"],
              attrs: { id: "mainInfo" },
            },
            [
              _c(
                "div",
                { staticClass: "left" },
                [
                  _c("WarePreviewList", {
                    staticClass: "photo",
                    attrs: {
                      alt: _vm.imgAlt,
                      title: _vm.wareTitle,
                      ware: _vm.ware,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c(
                    "div",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push(_vm.searchLink)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("ware.showAllProposition")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("WareMainInfo", {
                    attrs: {
                      ware: _vm.ware,
                      "is-loading": _vm.isSubmitOrderLoading,
                    },
                    on: {
                      "basket-click": _vm.submitOrder,
                      "sale-point:info": _vm.setSalePointInfo,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              !_vm.isEmpty(_vm.analogs)
                ? _c("WareArticlesTables", {
                    class: ["cv", _vm.isMobile ? "mb-2" : "mb-4"],
                    attrs: { title: _vm.wareTitle, wares: _vm.analogs },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-9" },
                [
                  _c("WareMainParams", {
                    class: ["cv", _vm.isMobile ? "mb-1" : "mb-2"],
                    attrs: { id: "characteristics", ware: _vm.ware },
                  }),
                  _vm._v(" "),
                  _vm.get(_vm.ware, "replacements_history.length")
                    ? _c("WareArticleReplacement", {
                        class: ["cv", _vm.isMobile ? "mb-1" : "mb-2"],
                        attrs: { value: _vm.ware },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ware.has_kits
                    ? _c("WareKits", {
                        class: ["cv", _vm.isMobile ? "mb-1" : "mb-2"],
                        attrs: { id: "kits", ware: _vm.ware },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isEmpty(_vm.ware.car_brands)
                ? _c("WareCarApplicabilitySelector", {
                    class: ["cv", _vm.isMobile ? "mb-1" : "mb-2"],
                    attrs: { id: "applicability", value: _vm.ware.car_brands },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.meta.text
                ? _c("WareDescription", {
                    class: ["cv", _vm.isMobile ? "mb-2" : "mb-4"],
                    attrs: { id: "description", text: _vm.meta.text },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isEmpty(_vm.ware.car_brands)
                ? _c("WareCarApplicability" + (_vm.isMobile ? "M" : ""), {
                    tag: "component",
                    class: ["cv", _vm.isMobile ? "mb-2" : "mb-4"],
                    attrs: {
                      "line-id": _vm.get(_vm.ware, "product_line.slug"),
                      value: _vm.ware.car_brands,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.$store.getters.isAppLoaded
                ? [
                    _c(
                      "TheDialog",
                      {
                        attrs: { width: "1400px" },
                        model: {
                          value: _vm.checkPoints,
                          callback: function ($$v) {
                            _vm.checkPoints = $$v
                          },
                          expression: "checkPoints",
                        },
                      },
                      [
                        _vm.checkPoints
                          ? _c("WareCheckPoints", {
                              attrs: {
                                ware: _vm.checkPoints.ware,
                                points: _vm.checkPoints.otherPoints,
                              },
                              on: {
                                select: function ($event) {
                                  _vm.checkPoints = null
                                  _vm.salePointInfo = $event
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("TheDialog", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.checkPoints,
                          expression: "!checkPoints",
                        },
                      ],
                      attrs: { width: "800px" },
                      scopedSlots: _vm._u(
                        [
                          _vm.salePointInfo
                            ? {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-headline custom-headline__dialog",
                                      },
                                      [
                                        _c("span", { staticClass: "bold" }, [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.get(
                                                  _vm.salePointInfo,
                                                  "ware.ware.trademark.name",
                                                  ""
                                                )
                                              ) +
                                              "\n              " +
                                              _vm._s(
                                                _vm.get(
                                                  _vm.salePointInfo,
                                                  "ware.ware.article",
                                                  ""
                                                )
                                              ) +
                                              ",\n            "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.get(
                                                _vm.salePointInfo,
                                                "ware.ware.name",
                                                ""
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _vm.salePointInfo
                                  ? _c("WareSalePointInfo", {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.isSubmitOrderLoading,
                                          expression: "isSubmitOrderLoading",
                                        },
                                      ],
                                      attrs: {
                                        id: _vm.salePointInfo.id,
                                        ware: _vm.salePointInfo.ware,
                                        "affiliation-list-name": "Product Page",
                                      },
                                      on: {
                                        "check-points": function ($event) {
                                          _vm.checkPoints = $event
                                        },
                                        "basket-click": _vm.submitOrder,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.salePointInfo,
                        callback: function ($$v) {
                          _vm.salePointInfo = $$v
                        },
                        expression: "salePointInfo",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }