var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "seller-trademark-table",
      attrs: {
        "cell-style": { textAlign: "center" },
        data: _vm.value,
        "header-cell-style": { textAlign: "center" },
      },
      on: {
        "row-click": function ($event) {
          return _vm.$emit("row-click", $event)
        },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("sellerTrademarks.trademark_name"),
          prop: "trademark_name",
        },
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("sellerTrademarks.verification_url"),
          prop: "verification_url",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                row.verification_url
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              href: row.verification_url,
                              target: "_blank",
                              type: "primary",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(row.verification_url) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("sellerTrademarks.verificationConfirmed"),
          prop: "status",
          width: "120px",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("sellerTrademarks.statuses." + row.status)) +
                    "\n    "
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("sellerTrademarks.activeStatus"),
          prop: "status",
          width: "120px",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("TrademarkActivator", {
                  attrs: { entity: row, readonly: _vm.readonly },
                  on: {
                    update: function ($event) {
                      row.is_active = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }