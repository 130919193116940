<template>
  <article class="ware-small-card">
    <component
      :is="isWareLink ? 'nuxt-link' : 'div'"
      class="max-w-100p"
      :to="wareLink"
    >
      <el-row class="mb-2" justify="center">
        <BlockSize
          :size="isMobile ? 'lg' : 'xxl'"
          :src="src"
          :alt="imgAlt"
        />
      </el-row>

      <div class="mb-1 w-100pr">
        <div class="mb-05 color-default">{{ value.display_title }}</div>

        <div class="tm-ac">
          <div class="bold mr-05">{{ value.display_trademark }}</div>
          <div>{{ value.display_article }}</div>
        </div>
      </div>
    </component>

    <template v-if="isCanBy">
      <ThePrice class="mb-1 w-100pr" :value="value.price" size="large" />

      <div class="text-center mb-1">
        {{ $t('labels.delivery_time') }}: <br>
        <DeliveryTimeString
          :options="{ suffix: true, isEmpty: !value.price, ware: value }"
          :value="value.delivery_time"
        />
      </div>

      <el-button
        class="mb-1 w-100"
        size="small"
        type="primary"
        @click="$emit('basket-click', value)"
      >
        {{ buttonTitle || $t('buttons.buy') }}
      </el-button>

      <div class="mb-05 text-center">
        <div class="seller">
          <el-link
            type="primary"
            @click="$emit('sale-point:info', value)"
          >
            {{ get(value, 'seller.business.name') }}
          </el-link>
        </div>

        <span class="text-ghost text-small">
          ({{ get(value, 'point_of_sale.address.city.name') }})
        </span>
      </div>

      <TheRate class="center" :value="get(value, 'seller.rank', 0)" />

      <div class="text-center">
        <el-link
          class="fit-content text-center"
          type="primary"
          @click="$router.push(searchLink)"
        >
          {{ $t('ware.showAllProposition') }}
        </el-link>
      </div>
    </template>

    <BlockSize
      v-else
      class="center"
      :size="isMobile ? 'lg' : 'xxl'"
      :src="require('@/assets/img/sold.svg')"
      :alt="imgAlt"
    />
  </article>
</template>

<script>
import get from 'lodash/get';
import BlockSize from '@/lib/core/components/BlockSize';
import ThePrice from '@/lib/core/components/ThePrice';
import DeliveryTimeString from '@/lib/orders/components/DeliveryTimeString';
import TheRate from '@/lib/core/components/TheRate';
import getWareLink from '@/lib/catalog/services/getWareLink';
import parsers from '@/lib/core/services/parsers';
import env from '@/lib/core/services/envConstants';

export default {
  name: 'WareSmallCard',

  // TODO revert cache
  // serverCacheKey: props => props.value.id,

  components: {
    BlockSize,
    ThePrice,
    DeliveryTimeString,
    TheRate
  },

  props: {
    buttonTitle: String,
    img: String,
    value: { type: Object, required: true }
  },

  data: () => ({
    MARKETPLACE_CURRENCY: env.MARKETPLACE_CURRENCY
  }),

  computed: {
    isMobile () {
      return this.maxmq('xs');
    },

    src () {
      return this.img ||
        get(this.value, 'images[0].image_200x200') ||
        this.value.ware?.image_url;
    },

    isWareLink () {
      return this.value.ware_id || this.value.ware?.id;
    },

    imgAlt () {
      const { display_trademark, display_article, display_title } = this.value;
      return `${display_trademark} ${display_article} ${display_title}`;
    },

    isCanBy () {
      return this.value.offer_id && this.value.price;
    },

    wareLink () {
      return getWareLink(this, this.value);
    },

    searchLink () {
      const params = { query: { utm_mriyar: 'all_offers_list' } };
      return parsers.getSearchRouteBy(this, this.value, params);
    }
  },

  methods: {
    get,
    getPriceNum: parsers.getPriceNum
  }
};
</script>

<style scoped lang="scss">
.ware-small-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  max-width: 350px;
  padding: 20px;
  border: 1px solid $grey-300;

  .block-size {
    max-width: 100% !important;
  }

  .tm-ac {
    display: flex;
    flex-wrap: wrap;
    min-height: 32px;
  }

  .seller {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 32px;
  }

  @media all and (max-width: $--xs) {
    padding: 10px;
  }
}
</style>
