import Vue from 'vue';
import locale from 'element-ui/lib/locale';

import Container from 'element-ui/lib/container';
import Header from 'element-ui/lib/header';
import Aside from 'element-ui/lib/aside';
import Main from 'element-ui/lib/main';
import Row from 'element-ui/lib/row';
import Col from 'element-ui/lib/col';
import Input from 'element-ui/lib/input';
import InputNumber from 'element-ui/lib/input-number';
import Form from 'element-ui/lib/form';
import FormItem from 'element-ui/lib/form-item';
import ButtonGroup from 'element-ui/lib/button-group';
import Button from 'element-ui/lib/button';
import Menu from 'element-ui/lib/menu';
import Submenu from 'element-ui/lib/submenu';
import MenuItem from 'element-ui/lib/menu-item';
import MenuItemGroup from 'element-ui/lib/menu-item-group';
import Notification from 'element-ui/lib/notification';
import MessageBox from 'element-ui/lib/message-box';
import Loading from 'element-ui/lib/loading';
import Card from 'element-ui/lib/card';
import CheckboxGroup from 'element-ui/lib/checkbox-group';
import Checkbox from 'element-ui/lib/checkbox';
import Select from 'element-ui/lib/select';
import Option from 'element-ui/lib/option';
import Tabs from 'element-ui/lib/tabs';
import TabPane from 'element-ui/lib/tab-pane';
import Dialog from 'element-ui/lib/dialog';
import Tooltip from 'element-ui/lib/tooltip';
import RadioGroup from 'element-ui/lib/radio-group';
import Radio from 'element-ui/lib/radio';
import Collapse from 'element-ui/lib/collapse';
import CollapseItem from 'element-ui/lib/collapse-item';
import Link from 'element-ui/lib/link';
import Cascader from 'element-ui/lib/cascader';

import VueObserveVisibility from 'vue-observe-visibility';

import { i18n } from './i18n.js';
import TheLoader from '@/lib/core/components/TheLoader';
import TheTooltip from '@/lib/core/components/TheTooltip';
import TheDialog from '@/lib/core/components/TheDialog';

// SET DEFAULT VALUE FOR ELEMENTS
Form.props.labelSuffix = { type: String, default: ':' };
Form.props.size = { type: String, default: 'medium' };
Dialog.props.closeOnClickModal = Boolean;
Row.props.type = { type: String, default: 'flex' };
// fix el-input not firing input event on android
Input.methods.handleCompositionStart = () => {};
Input.methods.handleCompositionUpdate = () => {};
Input.methods.handleCompositionEnd = () => {};
// END

const components = [
  Container,
  Header,
  Aside,
  Main,
  Row,
  Col,
  Input,
  InputNumber,
  Form,
  FormItem,
  ButtonGroup,
  Button,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Notification,
  Loading,
  Card,
  CheckboxGroup,
  Checkbox,
  Select,
  Option,
  Tabs,
  TabPane,
  Dialog,
  Tooltip,
  RadioGroup,
  Radio,
  Collapse,
  CollapseItem,
  Link,
  Cascader,

  VueObserveVisibility,

  TheLoader,
  TheTooltip,
  TheDialog
];

components.forEach((Component) => {
  if (typeof Component.install === 'function') {
    Vue.use(Component);
  } else if (Component.name) {
    Vue.component(Component.name, Component);
  } else {
    console.error('Unable to register component', Component); // eslint-disable-line no-console
  }
});

const defaultOptions = {
  duration: 20000,
  offset: 0
};

Object.assign(Vue.prototype, {
  $notify: {
    ...Notification,
    success: options =>
      Notification({ type: 'success', ...defaultOptions, ...options }),
    info: options =>
      Notification({ type: 'info', ...defaultOptions, ...options }),
    warning: options =>
      Notification({ type: 'warning', ...defaultOptions, ...options }),
    error: options =>
      Notification({ type: 'error', ...defaultOptions, ...options })
  },
  $msgbox: MessageBox,
  $confirm (message, options = {}) {
    const mergedOptions = {
      closeOnClickModal: false,
      confirmButtonText: i18n.t('buttons.confirm'),
      cancelButtonText: i18n.t('buttons.cancel'),
      ...options
    };

    const blur = () => setTimeout(() => document.activeElement.blur(), 1);

    return MessageBox.confirm(message, options.title || '', mergedOptions)
      .then(() => {
        blur();
        return true;
      })
      .catch(() => {
        blur();
        return false;
      });
  }
});

export default ({ app }) => {
  locale.i18n(app.i18n.t.bind(app.i18n));
};
