var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-facebook-login-scope",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (scope) {
                  return _c(
                    "button",
                    {
                      staticClass: "v-facebook-login",
                      style: _vm.computedButtonStyle,
                      attrs: { disabled: scope.disabled },
                      on: { click: scope.toggleLogin },
                    },
                    [
                      _vm._t("before"),
                      _vm._v(" "),
                      scope.working
                        ? _c("i", {
                            staticClass: "loader",
                            style: _vm.loaderStyle,
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      scope.enabled
                        ? _c("img", {
                            staticClass: "token",
                            style: _vm.tokenStyle,
                            attrs: { src: _vm.token },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        { style: _vm.textStyle },
                        [
                          scope.enabled && scope.disconnected
                            ? _vm._t("login", function () {
                                return [
                                  _vm._v(
                                    "\n        Sign in with Facebook\n      "
                                  ),
                                ]
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          scope.enabled && scope.connected
                            ? _vm._t("logout", function () {
                                return [
                                  _vm._v(
                                    "\n        Sign out with Facebook\n      "
                                  ),
                                ]
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          scope.working
                            ? _vm._t("working", function () {
                                return [
                                  _vm._v("\n        Please wait...\n      "),
                                ]
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._t("after"),
                    ],
                    2
                  )
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.vModel,
            callback: function ($$v) {
              _vm.vModel = $$v
            },
            expression: "vModel",
          },
        },
        "v-facebook-login-scope",
        _vm.$props,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }