import get from 'lodash/get';
import getSeoLinkBy from './getSeoLinkBy';
import getSeoBreadcrumbs from './getSeoBreadcrumbs';
import { i18n } from '@/plugins/i18n';

const paramsKeys = {
  carBrand: 'car_brand',
  carModel: 'car_model',
  carGeneration: 'car_generation',
  lineId: 'product_line',
  trademarkId: 'trademark'
};

const getProductLineLinks = ({ data, vm, params }) => {
  const result = [];
  const line = get(data, 'product_line');
  const parent1 = get(data, 'product_line.parent');
  const parent2 = get(data, 'product_line.parent.parent');
  const region = get(data, 'region.name');

  [parent2, parent1, line].forEach((item) => {
    if (item) {
      params.lineId = item.slug;

      const { name, path } = getSeoLinkBy(params, vm, params, { missCar: true });

      result.push({
        name,
        path,
        label: item.name + (region ? ` ${i18n.t('catalog.in')} ${region}` : '')
      });
    }
  });

  return result;
};

export default function getSeoPageData (payload) {
  const { data, vm, rowBreadcrumbs: _rowBreadcrumbs } = payload;

  const rowBreadcrumbs = _rowBreadcrumbs || [];
  const region = get(data, 'region.name');
  const params = {};

  for (const key in paramsKeys) {
    const dataKey = paramsKeys[key];

    if (data[dataKey]) {
      params[key] = data[dataKey].slug;

      if (key === 'lineId') {
        const links = getProductLineLinks({ data, vm, params });
        rowBreadcrumbs.push(...links);
      } else {
        const {
          name,
          path
        } = getSeoLinkBy(params, vm, params, { missCar: true });

        const _region = region ? ` ${i18n.t('catalog.in')} ${region}` : '';

        rowBreadcrumbs.push({
          name,
          path,
          label: data[dataKey].name + _region
        });
      }
    }
  }

  const _pl = {
    vm,
    rowBreadcrumbs,
    options: { passHome: vm.$router?.history?.current?.name?.includes('home') }
  };
  const { breadcrumbs, seoBreadcrumbs } = getSeoBreadcrumbs(_pl);

  return { breadcrumbs, seoBreadcrumbs };
}
