import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { parsers } from '@/lib/core';

const fieldToCheck = [
  'delivery_rate',
  'extra_delivery_time',
  'is_active',
  'order_time_until',
  'point_of_sale',
  'url',
  'warehouse'
];

export const isHasDiff = (item, originItem) => {
  if (!originItem) {
    return item.is_active;
  }

  return Object.keys(item).some((key) => {
    if (key === 'delivery_time') {
      const a = parsers.getDayHourBy(item[key]);
      const b = parsers.getDayHourBy(originItem[key]);
      return !isEqual(a, b);
    } else if (!fieldToCheck.includes(key)) {
      return false;
    }

    return !isEqual(item[key], originItem[key]);
  });
};

export const getFilteredAvailabilitiesByMapping = (formData, price, originData) => {
  const data_sheet = formData.data_sheet || get(price, 'price_file.data_sheet', 1) - 1;
  const data_preview = (price.data_preview || []);
  let length = 0;

  if (Array.isArray(data_preview[data_sheet])) {
    length = data_preview[data_sheet].length;
  } else if (data_preview[data_sheet].header) {
    length = data_preview[data_sheet].header.length;
  } else {
    console.error('ERROR IN PARSE FILE getFilteredAvailabilitiesByMapping');
    console.error('CHECK data_preview[data_sheet].length');
  }

  const mapping = price.price_file.column_mapping || [];
  const originAvailabilities = originData?.availabilities || price.price_file.availabilities;
  const availabilities = formData.availabilities || originAvailabilities || [];

  const warehousesUrls = mapping
    .slice(0, length)
    .reduce((acc, item) => {
      if (item.type === 'quantity' && item.quantity_warehouse) {
        const id = typeof item.quantity_warehouse === 'object'
          ? item.quantity_warehouse.id
          : parsers.getIdFromUrl(item.quantity_warehouse);

        acc[id] = true;
      }

      return acc;
    }, {});

  return availabilities.reduce((acc, item, index) => {
    const id = parsers.getIdFromUrl(item.warehouse);
    const _item = Object.keys(item).reduce((acc, key) => {
      return !key.startsWith('_')
        ? { ...acc, [key]: item[key] }
        : acc;
    }, {});

    const _id = `${item.point_of_sale}_${item.warehouse}`;
    const originItem = originAvailabilities
      .find(it => `${it.point_of_sale}_${it.warehouse}` === _id);

    if (!warehousesUrls[id] && !_item.id) {
      return acc;
    } else if (!warehousesUrls[id] && _item.id) {
      return [...acc, { ..._item, is_active: false }];
    } else if (!originItem && !_item.id && _item.is_active) {
      return [...acc, { ..._item, is_active: true }];
    } else if (originItem && _item.id && isHasDiff(_item, originItem)) {
      return [...acc, _item];
    }

    return acc;
  }, []);
};

const getQDiff = (mapping) => {
  return mapping
    .reduce((acc, col) => {
      if (col.type === 'quantity' && col.quantity_warehouse) {
        const url = typeof col.quantity_warehouse === 'string'
          ? col.quantity_warehouse
          : col.quantity_warehouse.url;

        acc.push(url);
      }
      return acc;
    }, []);
};

export const getClearFormData = (formData, price) => {
  const _formData = cloneDeep(formData);
  const _price = cloneDeep(price);

  if (_formData.trademark === null) {
    _formData.trademark = '';
  }

  if (
    _formData.data_sheet === _price.price_file.data_sheet &&
    (
      !_formData.column_mapping ||
      isEqual(Object.keys(_formData), ['availabilities'])
    )
  ) {
    return { clearFormData: _formData };
  } else if (
    !_formData.column_mapping &&
    !_formData.availabilities &&
    !_formData.data_sheet
  ) {
    return { clearFormData: _formData };
  }

  _formData.column_mapping = _formData.column_mapping ||
    _price.price_file.column_mapping ||
    [];

  const data_sheet = _formData.data_sheet || _price.price_file.data_sheet;

  _formData.column_mapping.forEach((col, index) => {
    if (col.type === 'selling_price' && !Array.isArray(col.price_def.tags)) {
      col.price_def.tags = [col.price_def.tags];
    } else if (col.type === 'quantity' && col.quantity_warehouse) {
      _formData.column_mapping[index].quantity_warehouse = col.quantity_warehouse.url;
    } else if (col.type === 'purchase_price') {
      const { column, sheet, type } = col;
      const price_actions = col.price_actions.map((it) => {
        if (it.price_def.tags && !Array.isArray(it.price_def.tags)) {
          it.price_def.tags = [it.price_def.tags];
        }

        return it;
      });

      _formData.column_mapping[index] = {
        column,
        sheet,
        type,
        price_actions
      };
    }

    _formData.column_mapping[index].sheet = (typeof Number(data_sheet) === 'number')
      ? data_sheet
      : _formData.column_mapping[index].sheet;
  });

  // For showing notify about change
  const oldQ = getQDiff(_price.price_file.column_mapping);
  const newQ = getQDiff(_formData.column_mapping);

  return {
    isQuantityChanged: !isEqual(oldQ, newQ),
    clearFormData: { ..._formData }
  };
};
