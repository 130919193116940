var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-info" },
    [
      _c("el-row", [
        _c("div", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$t("basket.display_public_id")) + ":"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fl-1" }, [
          _c("span", { staticClass: "bold" }, [
            _vm._v(_vm._s(_vm.value.display_public_id)),
          ]),
          _vm._v(
            "\n      (" +
              _vm._s(_vm.getDateTime(_vm.value.created)) +
              ")\n\n      "
          ),
          _vm.value.is_one_click_order
            ? _c(
                "span",
                [
                  _c(
                    "TheTooltip",
                    { attrs: { content: _vm.$t("orders.fastOrder") } },
                    [_c("span", { staticClass: "pg-05 required" })]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("el-row", [
        _c("div", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$t("basket.seller")) + ":"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "fl-1" },
          [
            _c(
              "el-link",
              {
                staticClass: "bold",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.$emit(
                      "sale-point:info",
                      _vm.get(_vm.value, "point_of_sale_detail.id")
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.get(_vm.value, "point_of_sale_detail.name", "")
                    ) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(
              "\n\n      (" +
                _vm._s(
                  _vm.get(
                    _vm.value,
                    "point_of_sale_detail.address.city_detail.name",
                    ""
                  )
                ) +
                ")\n    "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-row", [
        _c("div", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$t("basket.sellerPhones")) + ":"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fl-1" }, [_vm._v(_vm._s(_vm.phones))]),
      ]),
      _vm._v(" "),
      _vm.value.payment_method
        ? _c("el-row", [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("basket.payment_method")) + ":"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl-1" }, [
              _vm._v(
                _vm._s(_vm.$t("payment_methods." + _vm.value.payment_method))
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.value.delivery_method
        ? _c("el-row", [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("orders.deliveryMethod")) + ": "),
            ]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.deliveryMethod))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.get(_vm.value, "delivery_address.service_name")
        ? _c("el-row", [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("orders.deliverer")) + ": "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl-1" }, [
              _vm._v(
                _vm._s(_vm.get(_vm.value, "delivery_address.service_name", ""))
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.address
        ? _c("el-row", [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("orders.address")) + ": "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bold fl-1" }, [
              _vm._v(_vm._s(_vm.address)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.value.recipient_first_name
        ? _c("el-row", [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("basket.orderRecipient")) + ":"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bold fl-1" }, [
              _vm._v(_vm._s(_vm.buyer)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.buyerPhone
        ? _c("el-row", [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("basket.recipient_phone_number")) + ":"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bold fl-1" }, [
              _vm._v(_vm._s(_vm.buyerPhone)),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }