var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sale-point-edit-page" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : [
            _c(
              "el-row",
              {
                staticClass: "mb-1",
                attrs: { justify: "space-between", align: "middle" },
              },
              [
                _c(
                  "h1",
                  {
                    staticClass: "custom-headline custom-headline--level2-bold",
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.salePoint.name) + "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("SalePointActivator", {
                  attrs: { left: "", point: _vm.salePoint, "show-label": "" },
                  on: {
                    update: function ($event) {
                      _vm.salePoint.is_active = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tabs",
              {
                attrs: { type: "card", value: _vm.activeTab },
                on: { "tab-click": _vm.setActiveTab },
              },
              _vm._l(_vm.tabs, function (tab) {
                return _c(
                  "el-tab-pane",
                  {
                    key: tab,
                    attrs: {
                      label: _vm.$t("salePoint." + tab + "Tab"),
                      lazy: "",
                      name: tab,
                    },
                  },
                  [
                    _c("SalePoint" + _vm.upperFirst(tab) + "Tab", {
                      tag: "component",
                      staticClass: "mb-4",
                      attrs: {
                        entity: _vm.salePoint,
                        "entity-name": "salePoint",
                        submit: _vm.submit,
                      },
                      on: {
                        input: function ($event) {
                          _vm.salePoint = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }