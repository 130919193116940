<template>
  <div class="image-preview">
    <img
      v-lazy-load
      :alt="alt"
      :title="alt"
      :class="{ 'mini-image': previewIcon }"
      :data-src="src"
    >
  </div>
</template>

<script>
export default {
  name: 'ImagePreview',

  props: {
    previewIcon: Boolean,
    alt: { type: String, default: '' },
    imgPath: String,
    defaultImgPath: String,
    value: { type: Object, required: true }
  },

  computed: {
    src () {
      return this.value[this.imgPath] ||
        this.value[this.defaultImgPath] ||
        this.value.url;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-preview {
  display: flex;

  img {
    margin: 0 auto;
    max-width: 100%;
    object-fit: contain;
  }

  .mini-image {
    object-fit: contain;
  }
}
</style>
