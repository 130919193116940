<template>
  <el-form
    ref="form"
    class="price-format-form"
    label-position="left"
    label-width="150px"
    :model="formData"
    :rules="rules"
    size="mini"
    @submit.native.prevent="submit"
  >
    <el-row v-if="formData" class="mb-2">
      <el-form-item class="mr-1" label-width="0" prop="type">
        <el-radio-group :value="formData.file_type" @input="formData = { file_type: $event }">
          <el-radio
            v-for="format in formats"
            :key="format.type"
            :label="format.type"
          >
            {{ format.title }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <component :is="component" :value="formData" v-bind.sync="formData" />
    </el-row>

    <el-row v-if="!isChild" class="mt-2" justify="end">
      <el-button :disabled="loading" @click="$emit('cancel')">
        {{ $t('buttons.cancel') }}
      </el-button>
      <el-button
        :disabled="isEqual"
        :loading="loading"
        native-type="submit"
        type="primary"
        @click="submit"
      >
        {{ $t('buttons.save') }}
      </el-button>
    </el-row>
  </el-form>
</template>

<script>
import isEqual from 'lodash/isEqual';
import upperFirst from 'lodash/upperFirst';
import PriceCsvTypeForm from './priceTypesFormItems/PriceCsvTypeForm';
import { library, getValidationFor } from '@/lib/core';

const rules = ['file_type', 'file_encoding', 'csv_delimiter', 'excel_sheet'];

export default {
  name: 'PriceFormatForm',

  components: {
    PriceCsvTypeForm
  },

  props: {
    isChild: Boolean,
    value: Object,
    price: { type: Object, required: true }
  },

  data () {
    const { csv_delimiter, file_type, file_encoding } = this.price;

    return {
      formats: library.priceTypes,
      rules: getValidationFor(rules, 'price'),
      formData: { csv_delimiter, file_type, file_encoding },
      loading: false
    };
  },

  computed: {
    component () {
      return this.formData.file_type
        ? `Price${upperFirst(this.formData.file_type)}TypeForm`
        : null;
    },

    isEqual () {
      const { csv_delimiter, file_type, file_encoding } = this.price;
      return isEqual({ csv_delimiter, file_type, file_encoding }, this.formData);
    }
  },

  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler (formData) {
        if (formData.file_encoding === 'auto') {
          this.formData.csv_delimiter = '';
        }

        if (this.isChild && formData) {
          this.$emit('input', formData);
        }
      }
    }
  },

  methods: {
    validate (callback) {
      this.$refs.form.validate(callback);
    },

    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          if (this.isChild) {
            this.$emit('input', this.formData);
          }
          this.loading = true;

          try {
            const price = await this.$store.dispatch('priceUploads/patchPrice', {
              id: this.price.id,
              formData: this.formData,
              push: true
            });

            this.$emit('price:update', price);
          } catch (e) {
            console.error(e);
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.price-format-form {
  position: relative;

  .el-radio-group .el-radio {
    display: flex;
    align-items: center;
  }
}
</style>
