<template>
  <div class="prices-list-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_prices') }}
    </h1>

    <el-row class="mb-2" justify="end">
      <el-button
        :disabled="!isAdmin"
        size="mini"
        type="primary"
        @click="goToUpload"
      >
        {{ $t('buttons.createPrice') }}
      </el-button>
    </el-row>

    <TheLoader v-if="isLoading" />

    <PricesTable
      v-else
      immediate-load-false
      :load="load"
      :prop-loading="isLoading"
      :prop-value="tableData"
      :readonly="!isAdmin"
      @reupload:file="isUploadDialog = $event"
      @rowClick="goToPrice"
      @row-click-middle="goToPriceTab"
    />

    <PriceReUploadDialog
      v-if="isUploadDialog"
      :price="isUploadDialog"
      @cancel="isUploadDialog = false"
      @price:update="updatePrice(isUploadDialog, $event)"
      @status:update="updatePriceStatus(isUploadDialog, $event)"
    />
  </div>
</template>

<script>
import PricesTable from './components/PricesTable';
import PriceReUploadDialog from './components/PriceReUploadDialog';

export default {
  name: 'PricesListPage',

  components: {
    PricesTable,
    PriceReUploadDialog
  },

  data: () => ({
    isUploadDialog: null,
    isLoading: true,
    tableData: []
  }),

  computed: {
    isAdmin () {
      return this.$store.getters['businessUsers/isAdmin'];
    }
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      this.isLoading = true;

      try {
        const business = this.$store.getters['businessProfiles/businessId'];
        this.tableData = await this.$store.dispatch(
          'priceUploads/getPrices',
          { business }
        );

        return this.tableData;
      } catch (e) {
        this.tableData = [];
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    goToPrice ({ id, price_file }, options = {}) {
      if (!this.isAdmin && !price_file) {
        return;
      }

      const method = options.blank ? 'blank' : 'push';
      const action = price_file ? 'edit' : 'create';

      this.$router[method]({
        name: this.getRouteName(`profile.business.prices.${action}`),
        params: { id }
      });
    },

    goToPriceTab (event) {
      console.info(event);

      if (event.button === 1) {
        const row = event.path.find(it => /row-index__/g.test(it.className));

        if (row) {
          const _class = row.className
            .split(' ')
            .find(it => it.startsWith('row-index__'));

          if (_class) {
            const index = _class.split('__')[1];
            this.goToPrice(this.tableData[index], { blank: true });
          }
        }
      } else if (event.id) {
        this.goToPrice(event, { blank: true });
      }
    },

    goToUpload () {
      this.$router.push({
        name: this.getRouteName('profile.business.prices.upload')
      });
    },

    updatePrice (origin, formData) {
      const index = this.tableData.findIndex(({ id }) => id === origin.id);

      if (index > -1) {
        this.tableData[index] = formData;
      }

      this.isUploadDialog = false;
    },

    updatePriceStatus (origin, formData) {
      const index = this.tableData.findIndex(({ id }) => id === origin.id);

      if (index > -1) {
        this.tableData[index].status = formData;
      }
    }
  }
};
</script>
