<template>
  <div class="column-mapping hide-error-border">
    <el-row :gutter="12">
      <el-col
        v-for="(item, index) in formData"
        :key="item._id"
        class="cols"
      >
        <ColItems
          :config="config"
          :dependency="mapDependency"
          :form-data="formData"
          :used-types="usedTypes"
          :value="formData[index]"
          v-on="listeners"
          @input="setAndEmit(index, $event)"
        />
      </el-col>
    </el-row>

    <TheDialog
      v-model="warehouseCreateData"
      width="400px"
      :title="$t('warehouses.creationWarehouses')"
    >
      <WarehouseForm
        :dependency="dependency"
        :params="warehouseCreateData"
        @cancel="warehouseCreateData = null"
        @created="updateWarehouse({ params: warehouseCreateData, warehouse: $event })"
      />
    </TheDialog>

    <TheDialog
      v-model="priceSchemeCreateData"
      width="850px"
      :title="$t('prices.creationPriceScheme')"
    >
      <PriceSchemeForm
        @cancel="priceSchemeCreateData = null"
        @created="updatePriceScheme('priceSchemeCreateData', $event)"
      />
    </TheDialog>
  </div>
</template>

<script>
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';
import transform from 'lodash/transform';
import PriceSchemeForm from '../PriceSchemeForm';
import getPriceCollMapping from '../../services/getPriceCollMapping';
import ColItems from './ColItems';
import { WarehouseForm } from '@/lib/warehouses';
import { parsers, eventBus } from '@/lib/core';

export default {
  name: 'ColumnMapping',

  components: {
    ColItems,
    WarehouseForm,
    PriceSchemeForm
  },

  props: {
    dependency: { type: Object, required: true },
    value: { type: Array, required: true }
  },

  data () {
    return {
      warehouseCreateData: null,
      priceSchemeCreateData: null
    };
  },

  computed: {
    formData: {
      get () {
        return this.getParsedList(cloneDeep(this.value));
      }
    },

    usedTypes () {
      return this.formData.reduce((acc, { type, column }) => {
        if (type) {
          acc[type] = column;
        }
        return acc;
      }, {});
    },

    mapDependency () {
      return {
        ...this.dependency,
        column_mapping: this.value
      };
    },

    config () {
      return getPriceCollMapping(this.dependency);
    },

    listeners () {
      return {
        'quantity:remove': this.emitRemoveWarehouse,
        'warehouse:update': this.updateWarehouse,
        warehouseCreate: data => this.setTempData('warehouseCreateData', data),
        priceSchemeCreate: data => this.setTempData('priceSchemeCreateData', data)
      };
    },

    defaultPriceTag () {
      const tag = this.dependency.priceTags.find(it => it.label === 'retail');
      return tag ? { tags: tag.url } : {};
    }
  },

  methods: {
    setTempData (name, data) {
      this[name] = data;
    },

    emitRemoveWarehouse () {
      eventBus.$emit('quantity:remove');
    },

    updateWarehouse ({ params, warehouse }) {
      this.setAndEmit(null, {
        _parent: params._parent,
        value: {
          ...params._parent,
          quantity_warehouse: warehouse
        }
      });

      this.warehouseCreateData = null;
    },

    updatePriceScheme (name, scheme) {
      const params = this[name];

      if (params._parent && !isNumber(params._parent._actionIndex)) {
        const index = this.formData.findIndex(({ column }) => column === params._parent.column);

        if (index > -1) {
          const price_actions = this.formData[index].price_actions || [];

          price_actions.unshift({
            action: 'make_selling_price',
            pricing: scheme.url
          });

          const payload = {
            ...this.formData[index],
            price_actions
          };

          this.setAndEmit(index, payload);
        }
      }

      get(this, `${name}.callback`, () => {})(scheme.url);
      this[name] = null;
    },

    setAndEmit (index, value) {
      if (value._parent && value._parent.column) {
        const _index = this.formData.findIndex(item => item.column === value._parent.column);

        if (_index > -1) {
          this.formData[_index] = value.value;
        }
      } else {
        const originType = this.formData[index].type;
        this.formData[index] = value;

        // set default "Роздріб" for "Ціна продажу"
        if (originType !== 'selling_price' && value.type === 'selling_price') {
          this.formData[index].price_def = { ...this.defaultPriceTag };
        }

        if (
          value.type === 'purchase_price' &&
          value.price_actions?.some(({ action }) => action === 'make_selling_price')
        ) {
          this.formData[index].price_actions.forEach((it) => {
            if (it.action === 'make_selling_price') {
              it.price_def = {
                ...(it.price_def || {}),
                ...this.defaultPriceTag
              };
            }
          });
        }
        // ===
      }

      const formData = transform(this.formData, (acc, value) => {
        if (value.type) {
          const clearItem = transform(value, (acc, val, key) => {
            if (!key.startsWith('_')) {
              acc[key] = val;
            }
          }, {});

          acc.push(clearItem);
        }
      }, []);

      this.$emit('input', formData);
    },

    getParsedList (mapping) {
      const { price } = this.dependency;
      const previewLength = get(price, `data_preview.${price.header_row || 0}.length`, 0);

      if (!previewLength) {
        return this.decomposeMapping([], 5);
      } else {
        return this.decomposeMapping(mapping, previewLength);
      }
    },

    decomposeMapping (mapping, length) {
      const collection = keyBy(mapping, 'column');
      const result = [];

      for (let i = 1; i <= length; i++) {
        if (collection[i]) {
          result.push({ ...collection[i], _id: parsers.getDefaultId() });

          if (!isEmpty(collection[i].price_actions)) {
            collection[i].price_actions.forEach((item, index) => {
              result.push({
                ...item,
                _id: parsers.getDefaultId(),
                _actionIndex: index,
                _parent: collection[i],
                _isAction: true
              });
            });
          }
        } else {
          result.push({ column: i, sheet: 1 });
        }
      }

      return result;
    }
  }
};
</script>

<style scoped lang="scss">
.column-mapping {
  margin-left: 50px;
  font-size: 0.8rem;

  .cols {
    width: 180px;
  }

  ::v-deep .el-input__inner {
    height: 30px;
    border-color: $--border-color-base !important;
  }

  ::v-deep .invalid ::v-deep .el-input__inner {
    border: 1px solid $--color-danger !important;
    border-radius: 4px;
  }
}
</style>
