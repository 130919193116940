var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-entity-page" },
    [
      _vm.isLoading || _vm.isCatalogLoading
        ? _c("TheLoader")
        : _vm.isEmpty(_vm.options)
        ? _c(
            "div",
            [_c("EmptySearchResult", { attrs: { name: _vm.$route.query.q } })],
            1
          )
        : _c(
            "div",
            _vm._l(Object.keys(_vm.options), function (key) {
              return _c("div", { key: key, staticClass: "mb-2" }, [
                _c("div", { staticClass: "title bold mb-2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("cleverSearch." + key)) +
                      ":\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list" },
                  _vm._l(_vm.getList(key), function (item) {
                    return _c(
                      "li",
                      { key: item.related_object_id, staticClass: "item" },
                      [
                        key === "product_line"
                          ? _c("CatalogItem", {
                              attrs: { "title-center": "", value: item },
                            })
                          : _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelect(key, item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item._label) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }