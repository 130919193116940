<template>
  <div class="development-page">
    <h1 class="custom-headline custom-headline--level2-bold mb-4">
      {{ $t('core.pageInDevelopmentNow') }}
    </h1>

    <nuxt-link :to="link">{{ $t('buttons.goBackHome') }}</nuxt-link>
  </div>
</template>

<script>
import env from './services/envConstants';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';

export default {
  name: 'DevelopmentPage',

  data () {
    return {
      link: getSeoLinkBy({}, this, {})
    };
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>

<style scoped lang="scss">
.development-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
