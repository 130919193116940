var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-requests-list-page" },
    [
      _c("div", { staticClass: "header mb-2" }, [
        _c(
          "h1",
          { staticClass: "custom-headline custom-headline--level2-bold" },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("routes.garage_requests")) + "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "success" },
                on: { click: _vm.toRequestCreate },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("buttons.newSelectionRequest")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : _c("SRequestsTable" + (_vm.maxmq("xs") ? "M" : ""), {
            tag: "component",
            attrs: { value: _vm.tableData },
            on: { select: _vm.goToResponseBy },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }