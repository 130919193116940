var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sale-point-main-info-tab" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "left",
            model: _vm.formData,
            rules: _vm.rules,
            size: "mini",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.theSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("labels.salePointName"),
                "label-width": "200px",
                prop: "name",
              },
            },
            [
              _c("el-input", {
                staticClass: "max-w-350p",
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Address",
            _vm._b(
              {
                ref: "address",
                staticClass: "label-to-top mb-2",
                attrs: { value: _vm.formData.address },
              },
              "Address",
              _vm.formData.address,
              false,
              true
            )
          ),
          _vm._v(" "),
          _c(
            "Contacts",
            _vm._b(
              {
                staticClass: "mb-2",
                attrs: {
                  "hide-description": "",
                  "hide-emails": "",
                  rules: _vm.rules,
                  value: _vm.formData,
                },
              },
              "Contacts",
              _vm.formData,
              false,
              true
            )
          ),
          _vm._v(" "),
          _c(
            "SalePointOptions",
            _vm._b(
              { attrs: { value: _vm.formData } },
              "SalePointOptions",
              _vm.formData,
              false,
              true
            )
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "mt-4", attrs: { justify: "center" } },
            [
              _c("el-button", { on: { click: _vm.cloneOrigin } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("buttons.cancel")) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isEqual,
                    "native-type": "submit",
                    type: "primary",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("buttons.save")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }