import { gql } from 'graphql-tag';

export const GET_CAR_ELEMENT_TYPES = gql`
  query GetCarElementTypes {
    carBodyTypes(limit: 1000) {
      id
      name
    }
    carBrakeSystemTypes(limit: 1000) {
      id
      name
    }
    carBrakeTypes(limit: 1000) {
      id
      name
    }
    carCarburationTypes(limit: 1000) {
      id
      name
    }
    carCatalyzerTypes(limit: 1000) {
      id
      name
    }
    carDriveTypes(limit: 1000) {
      id
      name
    }
    carEngineTypes(limit: 1000) {
      id
      name
    }
    carFuelTypes(limit: 1000) {
      id
      name
    }
    carGearTypes(limit: 1000) {
      id
      name
    }
    carSuspensionTypes(limit: 1000) {
      id
      name
    }
  }
`;
