import { phoneNumberObject } from './common';
import { i18n } from '@/plugins/i18n';

const required = i18n.t('validation.simple.required');
const requiredByBlur = { required: true, message: required, trigger: 'blur' };

export default {
  phone_number: phoneNumberObject,
  first_name: [requiredByBlur],
  last_name: [requiredByBlur]
};
