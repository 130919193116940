export const actions = {
  getShipment (_, { id }) {
    return this.$axios.$get(`/shipments/${id}/`);
  },

  postShipment (_, { formData }) {
    return this.$axios.$post('/shipments/', formData);
  },

  getShipments (_, params) {
    return this.$axios.$get('/shipments/', { params });
  }
};
