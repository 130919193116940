var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-request-created-page" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : [
            _c(
              "el-row",
              {
                staticClass: "mb-1",
                attrs: { justify: "space-between", align: "middle" },
              },
              [
                _c(
                  "h1",
                  {
                    staticClass: "custom-headline custom-headline--level2-bold",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("sRequests.requestSubmit", {
                            id: _vm.value.publicId,
                          })
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isMobile && _vm.isAuth
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.$router.push({
                                  name: _vm.getRouteName("garage.requests"),
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("buttons.toSelectionRequestsList")
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pg-0-1 mb-2" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("sRequests.requestSubmittedDesc")) +
                  " "
              ),
              _c("br"),
              _vm._v(" "),
              _vm.requestHashLink
                ? _c(
                    "span",
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("sRequests.showRequestLinkDesc")) +
                          "\n        "
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", href: _vm.requestHashLink },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.requestHashLink) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("SRequestItemsView", {
                  staticClass: "mb-2",
                  attrs: {
                    car: _vm.value.car,
                    items: _vm.value.items,
                    commend: _vm.value.comment,
                  },
                }),
                _vm._v(" "),
                _vm.isMobile && _vm.isAuth
                  ? _c(
                      "div",
                      { staticClass: "nav text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.$router.push({
                                  name: _vm.getRouteName("garage.requests"),
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("buttons.toSelectionRequestsList")
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }