<template>
  <el-row align="middle" justify="center">
    <i
      v-if="value._canRevertStatus"
      class="icon el-icon-refresh-left c-pointer"
      @click="value._revertStatusTimer.cancel"
    />

    <div class="text-ellipsis pg-0-1">
      <TheTooltip :content="$t(`orderStatuses.${value.status}`)">
        <span class="status">
          {{ $t(`orderStatuses.${value.status}`) }}
        </span>
      </TheTooltip>
    </div>

    <TheTooltip
      :content="$t('buttons.setStatus', {
        status: $t(`orderStatuses.${getNextStatus(value.status)}`)
      })"
    >
      <img
        v-if="canSetNextStatus(value.status)"
        class="icon-img c-pointer"
        src="@/assets/icons/redo.svg"
        @click="setNextStatus(value)"
      >
    </TheTooltip>
  </el-row>
</template>

<script>
export default {
  name: 'OrderStatusToggle',

  props: {
    value: { type: Object, required: true },
    canSetNextStatus: { type: Function, required: true },
    getNextStatus: { type: Function, required: true },
    setNextStatus: { type: Function, required: true }
  }
};
</script>
