var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ware-info-variants" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : _c("WaresSmallTable", {
            attrs: { value: _vm.variants, "row-key": "_id" },
            scopedSlots: _vm._u([
              {
                key: "description",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      _vm._l(row.nomenclature, function (item, index) {
                        return _c(
                          "li",
                          { key: "name_" + index, staticClass: "text-left" },
                          [
                            _vm._v(
                              "\n          - " +
                                _vm._s(item.product_line) +
                                "\n          "
                            ),
                            _c("i", { staticClass: "text-ghost" }, [
                              _vm._v("(" + _vm._s(item.name) + ")"),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }