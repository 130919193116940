export const actions = {
  async getPlatforms (_, { business }) {
    const contracts = await this.$axios.$get(
      '/contract-states/',
      { params: { business } }
    );

    if (contracts.length) {
      return contracts;
    } else {
      return await this.$axios.$get(
        '/contract-states/placeholders/',
        { params: { business } }
      );
    }
  },

  changeStatus (_, { status, id }) {
    return this.$axios.$patch(
      `/contracts/${id}/`,
      { is_active: status }
    );
  },

  connect (_, { formData }) {
    return this.$axios.$post('/contracts/', formData);
  },

  get (_, { id }) {
    return this.$axios.$get(`/marketplaces/${id}/`);
  }
};
