<template>
  <div class="catalog-search-filter">
    <div class="filter">
      <div class="item">
        <TrademarkFilter
          v-model="formData.trademarks"
          :get-link="getSeoLink"
          :trademarks="trademarks"
          :use-link="!formData.trademarks.length"
          @clear="clearTrademarks"
        />
      </div>

      <div class="item">
        <div class="item__label">{{ $t('labels.priceGrn') }}</div>

        <el-slider
          :key="key + 1"
          :max="price_lte"
          :min="price_gte"
          range
          size="mini"
          :value="price"
          @input="setPrice"
        />

        <el-row justify="space-between">
          <el-col :span="10">
            <el-input
              class="price-input"
              placeholder="min"
              size="mini"
              :value="formData.price_gte"
              @input="setMin('price', $event)"
            />
          </el-col>

          <el-col :span="10">
            <el-input
              class="price-input"
              placeholder="max"
              size="mini"
              :value="formData.price_lte"
              @input="setMax('price', $event)"
            />
          </el-col>
        </el-row>
      </div>

      <el-row class="buttons" justify="space-between">
        <el-button @click="clear">
          {{ $t("buttons.clear") }}
        </el-button>
        <el-button type="primary" @click="submit">
          {{ $t("buttons.apply") }}
        </el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import SearchFilter from '@/lib/search/components/SearchFilter';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';

export default {
  name: 'CatalogSearchFilter',

  extends: SearchFilter,

  props: {
    trademark: Object
  },

  created () {
    if (this.trademark) {
      this.formData.trademarks.push(this.trademark.id);
    }
  },

  methods: {
    getSeoLink ({ slug }) {
      return getSeoLinkBy({ trademarkId: slug }, this);
    },

    clearTrademarks () {
      const selectedId = get(this, 'formData.trademarks[0]');

      if (this.trademark && this.trademark.id === selectedId) {
        const _pl = { carModification: this.$route.query.cm, trademarkId: null };
        const link = getSeoLinkBy(_pl, this);

        if (link) {
          this.$router.push(link);
        }
      } else {
        this.formData.trademarks = [];
        return this.submit();
      }
    },

    submit ({ clearQuery } = {}) {
      this.isLoaded = false;

      this.autoSubmit.cancel && this.autoSubmit.cancel();

      const { cm } = this.$route.query;
      const filter = clearQuery
        ? {}
        : this.getFilterFrom(this.formData);

      const page = 1;

      const routeParams = {
        query: { cm, ...filter },
        params: { ...this.$route.params, page }
      };

      if (
        this.formData.trademarks.length > 1 ||
        (!this.formData.trademarks.length && this.$route.params.trademarkId)
      ) {
        delete routeParams.params.trademarkId;
        this.goToTmPage(routeParams);
      } else if (
        this.formData.trademarks.length === 1 ||
        (!this.formData.trademarks.length && !this.$route.params.trademarkId)
      ) {
        const tm = this.trademarks.find(({ id }) => {
          return id === this.formData.trademarks[0];
        });

        routeParams.params.trademarkId = tm?.slug;

        this.goToTmPage(routeParams);
      } else {
        this.reloadPage(routeParams);
      }

      this.isLoaded = true;

      this.$emit('submitted');
    },

    goToTmPage (routeParams) {
      const route = getSeoLinkBy(routeParams.params, this, routeParams.params);

      this.reloadPage({ ...route, query: routeParams.query });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/search-filter.scss";
</style>
