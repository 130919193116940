import { render, staticRenderFns } from "./SearchTrademarksPage.vue?vue&type=template&id=530bd150&scoped=true&"
import script from "./SearchTrademarksPage.vue?vue&type=script&lang=js&"
export * from "./SearchTrademarksPage.vue?vue&type=script&lang=js&"
import style0 from "./SearchTrademarksPage.vue?vue&type=style&index=0&id=530bd150&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530bd150",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('530bd150')) {
      api.createRecord('530bd150', component.options)
    } else {
      api.reload('530bd150', component.options)
    }
    module.hot.accept("./SearchTrademarksPage.vue?vue&type=template&id=530bd150&scoped=true&", function () {
      api.rerender('530bd150', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "lib/search/SearchTrademarksPage.vue"
export default component.exports