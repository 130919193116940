<template>
  <el-cascader
    :class="['multiple-select', { invalid }]"
    :disabled="readonly"
    :options="options"
    :props="{ checkStrictly: true, multiple: true }"
    size="mini"
    :value="formData"
    @change="setFormData"
    @remove-tag="remove"
    @visible-change="emit"
  />
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';

export default {
  name: 'MultipleSelect',

  props: {
    value: Array,
    readonly: Boolean,
    options: { type: Array, default: () => [] },
    validate: Function
  },

  data () {
    return {
      formData: []
    };
  },

  computed: {
    flattenVal () {
      return flatten(this.formData);
    },

    invalid () {
      return this.validate && !this.validate(this.flattenVal);
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (value) {
        this.formData = cloneDeep(value);
      }
    }
  },

  methods: {
    setFormData (val) {
      this.$set(this, 'formData', val);
    },

    remove () {
      setTimeout(() => this.$emit('change', this.formData), 100);
    },

    emit (isVisible) {
      if (!isVisible) {
        this.$emit('change', this.formData);
      }
    }
  }
};
</script>
