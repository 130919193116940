var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sale-points-table" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.value,
            "header-cell-style": { textAlign: "center" },
          },
          on: {
            "row-click": function ($event) {
              return _vm.$emit("row-click", $event)
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("salePoint.pointName"),
              prop: "name",
              width: "200px",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("labels.city"),
              prop: "address",
              width: "160px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.get(row, "address.city_detail.name")) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("labels.address"),
              "min-width": "300px",
              prop: "address",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getConcatedAddress(row.address)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("salePoint.status"),
              width: "80px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("SalePointActivator", {
                      attrs: { point: row, readonly: _vm.readonly },
                      on: {
                        update: function ($event) {
                          row.is_active = $event
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }