<template>
  <el-row class="business-name-photo" justify="space-between">
    <div>
      <el-form-item :label="$t('labels.companyName')" prop="name">
        <el-input
          class="max-w-210p"
          :disabled="readonly"
          :value="value.name"
          @input="$emit('update:name', $event)"
        />
      </el-form-item>

      <el-form-item
        label-width="unset"
        :label="$t('labels.isSeller')"
        prop="is_seller"
      >
        <el-radio-group
          :disabled="readonly"
          :value="value.is_seller"
          @input="$emit('update:is_seller', $event)"
        >
          <el-row>
            <el-radio :label="true">{{ $t('core.yes') }}</el-radio>
            <el-radio :label="false">{{ $t('core.no') }}</el-radio>
          </el-row>
        </el-radio-group>

        <div v-if="value.is_seller">
          <!--eslint-disable-next-line-->
          <span v-html="$t('profile._agreeWithPublicOffer')" />
        </div>
      </el-form-item>
    </div>

    <div v-if="mode !== 'create'">
      <el-form-item class="relative" label-width="0" prop="avatar">
        <AvatarUploader
          circle
          :readonly="readonly"
          :value="value.logo"
          @input="$emit('update:logo', $event)"
        />
      </el-form-item>
    </div>
  </el-row>
</template>

<script>
import AvatarUploader from '@/lib/core/components/AvatarUploader';

export default {
  name: 'BusinessNamePhoto',

  components: {
    AvatarUploader
  },

  props: {
    readonly: Boolean,
    mode: String,
    value: { type: Object, required: true }
  }
};
</script>

<style scoped lang="scss">
.business-name-photo {
  .el-upload {
    border: 1px dashed $white;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  ::v-deep .el-upload-dragger {
    border-radius: 50%;
  }

  .avatar-uploader .el-upload:hover {
    border-color: $blue-500;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .remove-logo {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}

@media all and (max-width: $--md) {
  .business-name-photo {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}
</style>
