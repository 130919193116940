var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "clever-search relative",
        ((_obj = {}), (_obj[_vm.size] = _vm.size), _obj),
      ],
      on: {
        mouseenter: function ($event) {
          _vm.isFocus = true
        },
        mouseleave: function ($event) {
          _vm.isFocus = false
        },
        keydown: _vm.onKeyDown,
      },
    },
    [
      _c(
        "el-input",
        {
          ref: "input",
          attrs: {
            autocomplete: "on",
            clearable: "",
            size: _vm.size,
            placeholder: _vm.$t("cleverSearch.searchPlaceholder"),
            value: _vm.query,
          },
          on: {
            input: _vm.onInput,
            blur: function ($event) {
              return _vm.clearOptions()
            },
          },
          nativeOn: {
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.applySelection.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                ) {
                  return null
                }
                return _vm.clearOptions()
              },
            ],
          },
        },
        [
          _c("el-button", {
            staticClass: "main-btn--icon",
            attrs: {
              slot: "append",
              type: "primary",
              icon: "el-icon-search",
              loading: _vm.isLoading,
              size: _vm.size,
            },
            on: { click: _vm.applySelection },
            slot: "append",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isCategoryPopup
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass:
                "custom-autocomplete back-shadow scroll category-popup",
            },
            [
              _c("div", { staticClass: "mb-2 bold" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("cleverSearch.specifyCategory")) +
                    ":\n    "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "ul",
                  [
                    _c(
                      "li",
                      {
                        class: [
                          "item",
                          { "is-active": _vm.isActive === _vm.doubleItem._id },
                        ],
                        on: {
                          mouseenter: function ($event) {
                            return _vm.setActive(_vm.doubleItem)
                          },
                          click: function ($event) {
                            return _vm.applySelection()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.doubleItem._label) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.options.search.product_line, function (item) {
                      return _c(
                        "li",
                        {
                          key: item._id,
                          class: [
                            "item",
                            { "is-active": _vm.isActive === item._id },
                          ],
                          on: {
                            mouseenter: function ($event) {
                              return _vm.setActive(item)
                            },
                            click: function ($event) {
                              return _vm.onSelect(item, "product_line")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item._label) + "\n        "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "btn-block", attrs: { justify: "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        loading: _vm.isLoading,
                        size: _vm.size,
                      },
                      on: { click: _vm.applySelection },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("buttons.search")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.isValues
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "custom-autocomplete back-shadow",
            },
            [
              _c(
                "div",
                { staticClass: "content scroll" },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "item",
                        { "is-active": _vm.isActive === _vm.doubleItem._id },
                      ],
                      on: {
                        mouseenter: function ($event) {
                          return _vm.setActive(_vm.doubleItem)
                        },
                        click: function ($event) {
                          return _vm.applySelection()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.doubleItem._label) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.options, function (value, key) {
                    return _c(
                      "div",
                      { key: key },
                      [
                        key === "autocomplete" &&
                        !_vm.options.wares_search.length
                          ? _vm._l(value, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item._id,
                                  class: [
                                    "item",
                                    { "is-active": _vm.isActive === item._id },
                                  ],
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.setActive(item)
                                    },
                                    click: function ($event) {
                                      return _vm.onInput(item.full_query, {
                                        focus: true,
                                        isAutocomplete: true,
                                        apply: true,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        item._label || item.name
                                      ),
                                    },
                                  }),
                                ]
                              )
                            })
                          : key === "wares_search" && value.length
                          ? _vm._l(value, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item._id,
                                  class: [
                                    "item",
                                    { "is-active": _vm.isActive === item._id },
                                  ],
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.setActive(item)
                                    },
                                    click: function ($event) {
                                      return _vm.onSelect(item, "ware")
                                    },
                                  },
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(item._label),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-ghost" }, [
                                    _c("small", [
                                      _vm._v(_vm._s(item.display_title)),
                                    ]),
                                  ]),
                                ]
                              )
                            })
                          : key === "search" && !_vm.options.wares_search.length
                          ? [
                              _vm._l(value, function (list, _key) {
                                return [
                                  list.length && _key === "product_line"
                                    ? _c(
                                        "div",
                                        { key: _key },
                                        [
                                          _c("div", { staticClass: "label" }, [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("cleverSearch." + _key)
                                                ) +
                                                "\n              "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(list, function (item) {
                                            return _c(
                                              "div",
                                              {
                                                key: item._id,
                                                class: [
                                                  "item",
                                                  {
                                                    "is-active":
                                                      _vm.isActive === item._id,
                                                  },
                                                ],
                                                on: {
                                                  mouseenter: function (
                                                    $event
                                                  ) {
                                                    return _vm.setActive(item)
                                                  },
                                                  click: function ($event) {
                                                    return _vm.onSelect(
                                                      item,
                                                      item._key
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item._label || item.name
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          }),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }