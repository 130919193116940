<template>
  <div class="order-create-page">
    <TheLoader v-if="isLoader" />

    <div v-else>
      <h1 class="custom-headline custom-headline--level2-bold">
        {{ $t("basket.createOrder") }}
      </h1>

      <div class="mb-1">
        {{ $t("basket.seller") }}:
        <span class="bold">{{ salePoint.name }}</span>
        ({{ salePoint.address.city_detail.name }})
      </div>

      <el-form
        ref="form"
        label-position="top"
        :disabled="disabled"
        :model="formData"
        :rules="rules"
        size="medium"
        @submit.native.prevent="submit"
      >
        <div class="mb-2">
          <BuyerData
            :is-form="isBuyerForm"
            :is-show-last-first="!!user"
            :value="formData"
            :set-field="setField"
            v-bind.sync="formData"
            @is-form:change="isBuyerForm = $event"
          >
            <template #options="{ isMobile: _isMobile }">
              <div
                :class="[
                  'buyer-form__options',
                  { center: _isMobile, 'ml-2': !_isMobile },
                ]"
              >
                <el-row justify="center" align="bottom" class="mb-1">
                  {{ $t("basket.toBePaid") }}:

                  <ThePrice :value="totalAmount" />
                </el-row>

                <el-button
                  class="mb-1"
                  native-type="submit"
                  type="primary"
                  :loading="isLoading"
                >
                  {{ $t("buttons.selectDeliveryAndPayment") }}
                </el-button>

                <!--eslint-disable-next-line-->
                <div
                  class="text-center text-small"
                  v-html="
                    $t('basket.agreeWithPublicOffer', { href: publicOfferHref })
                  "
                />
              </div>
            </template>
          </BuyerData>
        </div>

        <template v-if="!isBuyerForm">
          <OrderDelivery
            class="form-block mb-1"
            :dependency="{ ...dependency, rules }"
            :value="formData"
            :set-field="setField"
            v-bind.sync="formData"
          />

          <div class="form-block mb-1">
            <OrderPayment
              :dependency="dependency"
              :value="formData"
              v-bind.sync="formData"
            />
          </div>

          <div class="form-block pg-1-0 mb-1">
            <el-form-item label-width="0" prop="comment">
              <el-input
                v-model="formData.comment"
                type="textarea"
                :placeholder="$t('basket.commentForm')"
                :rows="4"
              />
            </el-form-item>
          </div>

          <div class="buy-block">
            <div class="mb-1 flex fit-content">
              {{ $t("basket.toBePaid") }}:
              <ThePrice :value="totalAmount" />
            </div>

            <div class="mb-1">
              <el-button
                class="w-200p"
                native-type="submit"
                type="primary"
                :loading="isLoading"
                :disabled="!canSubmit"
              >
                {{ $t("buttons.toOrder") }}
              </el-button>
            </div>

            <!--eslint-disable-next-line-->
            <div
              v-if="!isBuyerForm"
              class="public-offer"
              v-html="
                $t('basket.agreeWithPublicOffer', { href: publicOfferHref })
              "
            />
          </div>
        </template>

        <div class="mt-2">
          <div class="mb-1 block-title">{{ $t("basket.orders") }}</div>

          <component
            :is="`OrderItemsTable${isMobile ? 'M' : ''}`"
            :key="formData.id"
            :class="{ 'form-block pg-1-0': !isMobile }"
            :currency-name="MARKETPLACE_CURRENCY"
            :value="formData.items"
            @item:update="updateItem"
            @item:remove="removeItem"
          />
        </div>
      </el-form>
    </div>

    <LiqPayConfirmForm
      v-if="isLiqPay"
      :order="isLiqPay"
      @done="closeLiqPay"
      @cancel="closeLiqPay"
    />
  </div>
</template>

<script>
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';

import { getOrderLink } from '../core/services/parsers';
import BuyerData from './components/formItems/BuyerData';
import OrderDelivery from './components/formItems/OrderDelivery';
import OrderPayment from './components/formItems/OrderPayment';
import basketMixin from '@/lib/orders/mixins/basketMixin';
import ThePrice from '@/lib/core/components/ThePrice';
import {
  defaultEntities,
  env,
  eventBus,
  getDefault,
  getValidationFor,
  parsers,
  scrollToTop,
  useAsyncDebounce
} from '@/lib/core';

export default {
  name: 'OrderCreatePage',

  mixins: [basketMixin],

  layout: 'light',

  components: {
    ThePrice,
    BuyerData,
    OrderPayment,
    OrderDelivery,
    /* eslint-disable max-len */
    OrderItemsTable: () =>
      import(
        /* webpackChunkName: "OrderItemsTable" */ './components/OrderItemsTable'
      ),
    OrderItemsTableM: () =>
      import(
        /* webpackChunkName: "OrderItemsTableM" */ './components/OrderItemsTableM'
      ),
    LiqPayConfirmForm: () =>
      import(
        /* webpackChunkName: "LiqPayConfirmForm" */ '@/lib/core/components/LiqPayConfirmForm'
      )
    /* eslint-enable max-len */
  },

  data () {
    return {
      isLoader: true,
      isLoading: true,
      isBuyerForm: true,
      isLiqPay: false,
      salePoint: null,
      lastOrder: null,
      rules: getValidationFor(null, 'order', this),
      formData: getDefault('order'),
      items: [],
      totalAmount: 0,
      regionDetail: null,
      isThankPage: false
    };
  },

  computed: {
    ...mapGetters({
      user: 'users/user',
      businessUser: 'businessUsers/currentBusinessUser',
      city: 'city'
    }),

    isMobile () {
      return this.maxmq('md');
    },

    disabled () {
      return this.isLoading || this.formData.id;
    },

    dependency () {
      const { delivery_methods, address, payment_methods } = this.salePoint;

      return {
        basket: this.basket,
        delivery_methods,
        payment_methods,
        address,
        salePoint: this.salePoint,
        city: this.city,
        regionDetail: this.regionDetail
      };
    },

    isEqual () {
      return isEqual(this.formData, defaultEntities.order);
    },

    hasItemsForSubmit () {
      return (
        this.formData.items.length &&
        this.items.some(({ error_code }) => error_code !== 'not_found')
      );
    },

    canSubmit () {
      return !this.isEqual && this.hasItemsForSubmit;
    },

    publicOfferHref () {
      return this.$router.resolve({
        name: this.getRouteName('docs'),
        params: { id: 'public-offer' }
      }).href;
    }
  },

  watch: {
    'formData.items': {
      deep: true,
      immediate: true,
      handler () {
        const _price = this.formData.items.reduce(
          (acc, { price, quantity, error_code }) => {
            return error_code !== 'not_found'
              ? acc + Number.parseFloat(price) * quantity
              : acc;
          },
          0
        );

        this.totalAmount = parsers.getPriceNum(_price);
      }
    }
  },

  async mounted () {
    await this.loadData();
    this.emitCheckoutAnalytic({ step: this.isBuyerForm ? 1 : 2 });
  },

  methods: {
    async loadData () {
      await this.refetchBasket();

      if (!this.basket.updated) {
        console.error('THERE IS NOT BASKET updated FIELD');
        return this.$router.push({ name: this.getRouteName('home') });
      }

      const id = this.$route.params.pointId;
      const promises = [this.loadPoint(id)];

      if (this.user) {
        promises.push(this.loadLastOrder(id));
      }

      try {
        const [salePoint, lastOrder] = await Promise.all(promises);

        this.salePoint = salePoint;
        this.lastOrder = lastOrder;

        this.setDefaultData();

        const watch = this.$watch(
          () => this.city,
          (newVal) => {
            if (newVal) {
              watch && watch();
              this.loadRegion();
            }
          },
          { immediate: true }
        );

        this.isLoader = false;
      } catch (e) {
        console.error(e);

        eventBus.$emit('basket:show');

        return this.$router.push({ name: this.getRouteName('home') });
      }
    },

    async loadPoint (id) {
      try {
        return await this.$store.dispatch('salePoints/getSalePoint', { id });
      } catch (e) {
        console.error(e);
      }
    },

    async loadLastOrder (id) {
      try {
        const { results } = await this.$store.dispatch('orders/getOrders', {
          limit: 1,
          point_of_sale: id,
          ordering: '-created'
        });

        return results[0] || null;
      } catch (e) {
        console.error(e);
      }
    },

    async loadRegion () {
      try {
        this.regionDetail = await this.$store.dispatch('cache/get', {
          action: 'searchLocation/getAdmin1ById',
          payload: { id: parsers.getIdFromUrl(this.city.admin1) }
        });
      } catch (e) {
        console.error(e);
      }
    },

    getItemsByPoint () {
      const pointId = Number.parseInt(this.$route.params.pointId);

      const _items = (
        this.items?.length ? this.items : this.basket.items
      ).reduce((acc, item) => {
        if (item.point_of_sale_detail.id === pointId) {
          acc.push(cloneDeep(item));
        }

        return acc;
      }, []);

      return _items.map(this.getParsed);
    },

    setDefaultData () {
      this.formData.items = this.getItemsByPoint();

      if (this.user) {
        const { first_name, last_name, phone_numbers } =
          this.businessUser || this.user.profile;

        this.formData.buyer_first_name = first_name;
        this.formData.buyer_last_name = last_name;
        this.formData.buyer_phone_number = parsers.getPrimaryOrFirst(
          phone_numbers,
          'phone'
        );

        if (this.formData.buyer_phone_number?.phone_number?.length > 4) {
          this.isBuyerForm = false;
        }

        if (this.lastOrder) {
          this.setDataFrom(this.lastOrder);
        }
      }
    },

    setDataFrom (source) {
      this.formData.delivery_method = source.delivery_method;

      this.formData.delivery_address = source.delivery_address;

      this.formData.payment_method = source.payment_method;

      if (source.recipient_first_name) {
        this.formData.recipient_first_name = source.recipient_first_name;
      }

      if (source.recipient_last_name) {
        this.formData.recipient_last_name = source.recipient_last_name;
      }

      if (source.recipient_middle_name) {
        this.formData.recipient_middle_name = source.recipient_middle_name;
      }

      if (source.recipient_phone_number?.phone_number?.length > 4) {
        this.formData.recipient_phone_number = source.recipient_phone_number;
      }
    },

    getParsed (item) {
      item._notFound = item.error_code === 'not_found';
      item._articleHref = parsers.getSearchRouteBy(this, item);
      item._canRevertStatus = item._canRevertStatus || false;
      item._revertStatusTimer =
        item._revertStatusTimer ||
        useAsyncDebounce({ debounce: env.STATUS_REVERT_DEBOUNCE });

      return item;
    },

    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          scrollToTop('is-error');
          return false;
        }

        if (this.isBuyerForm) {
          this.$store.dispatch('analytics/pushEvent', 'checkout_1_step');

          if (!this.isStep2Emited) {
            this.isStep2Emited = true;
            await this.emitCheckoutAnalytic({ step: 2 });
          }

          this.isBuyerForm = false;
          return null;
        }

        if (!this.basket.updated) {
          console.error('THERE IS NOT BASKET UPDATED FIELD');

          await this.$router.push({ name: this.getRouteName('home') });

          eventBus.$emit('basket:show');

          return false;
        }

        this.isLoading = true;

        const formData = this.getClearData(this.formData);

        try {
          // FIXME after fix on server 2030 sprint
          if (this.$store.getters.buyer && !formData.delivery_address.id) {
            await this.$store.dispatch('buyerRecipientAddresses/postAddress', {
              formData: {
                ...formData.delivery_address,
                buyer: this.$store.getters.buyer?.url
              }
            });
          }

          const order = await this.$store.dispatch('orders/postOrder', {
            formData
          });

          if (this.user) {
            await this.updateUserFields(order);
          } else if (order.new_registered_user) {
            await this.setUser(order.new_registered_user);
          }

          this.$store.dispatch('setCookie', {
            name: env.LAST_PHONE_NUM_KEY,
            value: order.buyer_phone_number.phone_number
          });

          await this.emitAnalytic({
            order,
            formData,
            totalAmount: this.totalAmount
          });

          await this.refetchBasket();

          if (
            order.payment_method === 'card' &&
            order.seller_detail.has_liqpay_account
          ) {
            this.isLiqPay = order;
            return;
          }

          this.submitContinue(order);
          this.notifyAboutSuccessOrder(order);
        } catch (e) {
          console.error(e);

          await this.refetchBasket();

          this.formData.items = this.getItemsByPoint();
        } finally {
          this.isLoading = false;
        }
      });
    },

    emitAnalytic ({ order, formData, totalAmount }) {
      // await this.$store.dispatch("analytics/pushOrderToDatalayer", {
      //   step: 3,
      //   order,
      // });

      // await this.$store.dispatch("analytics/pushGtagEvent", {
      //   event: "purchase",
      //   id: formData.basket_items?.map(({ id }) => id),
      //   category: formData.basket_items?.map(
      //     ({ display_title }) => display_title
      //   ),
      //   value: totalAmount?.split(" ")[0]?.replace(",", "."),
      //   vm: this,
      // });

      return this.$store.dispatch('analytics/pushPurchaseEvent', {
        rawData: { order },
        options: {}
      });
    },

    async setUser (userData) {
      try {
        await Promise.all([
          this.$store.dispatch('auth/setTokenData', userData),
          this.$store.dispatch('users/setUserData', { user: userData })
        ]);

        return await this.$store.dispatch('baskets/mergeBaskets');
      } catch (e) {
        console.error(e);
      }
    },

    async updateUserFields (order) {
      const { phone_numbers, emails } = this.businessUser || this.user.profile;
      const orderPhone = order.buyer_phone_number.phone_number;
      const profilePhone = parsers.getPrimaryOrFirst(
        phone_numbers,
        'phone'
      ).phone_number;
      const orderEmail = order.buyer_email?.email;
      const profileEmail = parsers.getPrimaryOrFirst(emails, 'email').email;

      const formData = {};

      if (orderPhone !== profilePhone) {
        formData.phone_numbers = [order.buyer_phone_number];
      }

      if (orderEmail && orderEmail !== profileEmail) {
        formData.emails = [order.buyer_email];
      }

      const promises = [];
      const submitName = this.businessUser
        ? 'updateProfileData'
        : 'updateUserData';

      if (!this.user.profile.last_name && order.buyer_last_name) {
        if (submitName === 'updateUserData') {
          formData.last_name = order.buyer_last_name;
        } else {
          promises.push(
            this.updateUserData({ last_name: order.buyer_last_name })
          );
        }
      }

      if (Object.keys(formData).length) {
        promises.push(this[submitName](formData));
      }

      try {
        return await Promise.all(promises);
      } catch (e) {
        console.error(e);
      }
    },

    async updateUserData (formData) {
      const profile = await this.$store.dispatch(
        'personalProfiles/patchPersonProfile',
        { id: this.user.id, formData }
      );

      await this.$store.dispatch('users/setLocalUser', {
        ...this.user,
        profile
      });

      return profile;
    },

    async updateProfileData (formData) {
      formData.business = this.businessUser.business.url;
      formData.user = this.user.url;

      await this.$store.dispatch('businessUsers/patchBusinessUser', {
        id: this.businessUser.id,
        formData
      });

      return await this.$store.dispatch('users/updateCurrentUser');
    },

    getClearItems (items, options) {
      return items.reduce((acc, it) => {
        if (it.error_code !== 'not_found') {
          acc.push(options?.onlyUrl ? it.url : it);
        }

        return acc;
      }, []);
    },

    getClearData (formData) {
      const _formData = cloneDeep(formData);
      _formData.agree_until = this.basket.updated;
      _formData.point_of_sale = this.salePoint.url;
      _formData.basket = this.basket.url;

      _formData.basket_items = this.getClearItems(_formData.items, {
        onlyUrl: true
      });

      delete _formData.items;
      delete _formData.delivery_address.region_detail;
      delete _formData.delivery_address.city_detail;

      if (!_formData.recipient_first_name) {
        delete _formData.recipient_phone_number;
        delete _formData.recipient_first_name;
        delete _formData.recipient_last_name;
        delete _formData.recipient_middle_name;
      }

      if (!_formData.buyer_email.email) {
        delete _formData.buyer_email;
      }

      Object.keys(_formData).forEach((key) => {
        if (typeof _formData[key] === 'string' && !_formData[key]) {
          delete _formData[key];
        }
      });

      return _formData;
    },

    setField (path, val) {
      set(this.formData, path, val);
    },

    closeLiqPay (order) {
      this.submitContinue(order);
      this.notifyAboutSuccessOrder(order);
    },

    submitContinue (order) {
      const link = getOrderLink(this, order, {
        suffix: 'order-created',
        params: { propOrder: order }
      });
      return this.$router.push(link);
    },

    notifyAboutSuccessOrder (order) {
      const {
        buyer_email,
        buyer_first_name,
        buyer_last_name,
        display_public_id
      } = order;

      if (this.user) {
        this.$notify.success({
          title: this.$t('message.success'),
          message: this.$t('message.orderCreated', {
            id: display_public_id,
            buyer: `${buyer_last_name} ${buyer_first_name}`,
            email: buyer_email && buyer_email.email
          }),
          dangerouslyUseHTMLString: true
        });
      } else {
        this.$notify.success({
          title: this.$t('message.success'),
          message: this.$t('message.orderCreatedNotify', {
            id: display_public_id
          }),
          dangerouslyUseHTMLString: true
        });
      }
    },

    async updateItem (_formData) {
      const { id, ...formData } = _formData;

      try {
        const _item = await this.$store.dispatch('basketItems/patchItem', {
          id,
          formData
        });

        this.updateLocalItem(_item);

        return _item;
      } catch (e) {
        console.error(e);
        this.items = cloneDeep(this.basket.items);
      }
    },

    async removeItem (item) {
      try {
        await this.$store.dispatch('basketItems/removeItem', { id: item.id });

        this.removeBasketItem(item);
      } catch (e) {
        console.error(e);
      }
    },

    removeBasketItem (item) {
      const index = this.items.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        this.items.splice(index, 1);

        this.updateLocalBasket();
      }
    },

    async updateLocalBasket ({ formData } = {}) {
      const items = this.items.slice(0);

      if (formData) {
        return this.$store.dispatch('baskets/updateLocalBasket', formData);
      } else {
        const price = Number.parseFloat(this.getTotalAmountNum(items)).toFixed(
          env.PRICE_TO_FIXED
        );

        const total_quantity = this.getTotalQuantity(items);
        const total_amount = Number.parseFloat(price);

        const basket = await this.$store.dispatch('baskets/setBasket', {
          basket: {
            ...cloneDeep(this.basket),
            total_quantity,
            total_amount,
            items: items.slice(0)
          }
        });

        this.basket = cloneDeep(basket);
        this.items = cloneDeep(basket.items);
      }
    },

    async emitCheckoutAnalytic ({ step }) {
      const products = this.getClearItems(this.formData.items).map(it => ({
        id: it.id,
        name: it.article,
        brand: it.trademark,
        category: it.display_title,
        price: it.price,
        quantity: it.quantity
      }));

      return await this.$store.dispatch('analytics/pushCheckoutEvent', {
        step,
        event: 'checkout',
        products
      });
    }
  },

  head () {
    return {
      title: this.$t('basket.createOrder'),
      meta: [{ name: 'robots', content: env.ROBOTS_VALUES }]
    };
  },

  validate ({ params }) {
    return /^\d+$/.test(String(params.pointId));
  }
};
</script>

<style scoped lang="scss">
.order-create-page {
  padding-bottom: 100px;

  .el-form {
    max-width: 930px;
  }

  ::v-deep {
    .block-title {
      font-weight: bold;
      font-size: 1rem;

      & > .el-form-item__label {
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .el-form-item {
      margin-bottom: 12px;

      &__error {
        padding-top: 0;
      }

      &__label {
        padding: 0;
      }
    }

    .white-back {
      background-color: $white;
      padding: 10px;
      border-radius: 4px;
    }

    .form-block {
      background-color: $grey-100;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      border-radius: 10px;
    }
  }

  .order-payment {
    max-width: 300px;
  }

  .public-offer ::v-deep * {
    font-size: 0.8rem !important;
  }

  .buy-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }

  ::v-deep .buyer-form__options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.center {
      align-items: center;
    }
  }

  @media all and (max-width: $--xss) {
    ::v-deep {
      .buyer-form__options {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .delivery-method,
      .order-recipient {
        display: block;

        & > .mr-2,
        .el-form-item {
          margin-right: 0 !important;
        }
      }

      .el-select,
      .el-input {
        width: 100%;
      }
    }

    .buy-block {
      align-items: center;
      text-align: center;
    }
  }
}
</style>
