<template>
  <div class="sale-point-options">
    <Activities
      class="label-to-top mr-2"
      :value="value.activities"
      @input="emit('activities', $event)"
    />

    <Schedule
      class="mr-2"
      hide-label
      :value="value.schedule"
      @input="emit('schedule', $event)"
    />

    <div class="fl-wrap">
      <div
        v-for="(data, listName) in options"
        :key="listName"
        class="mr-2 options"
      >
        <el-form-item
          :label="$t(`labels.${listName}`)"
          :prop="listName"
        >
          <el-checkbox-group
            class="cell"
            :value="value[listName]"
            @input="emit(listName, $event)"
          >
            <el-checkbox
              v-for="item in data.list"
              :key="item"
              :label="item"
            >
              {{ $t(`${listName}.${item}`) }}
            </el-checkbox>
          </el-checkbox-group>

          <template v-if="listName === 'delivery_methods'">
            <el-checkbox
              :value="value.delivery_methods.includes('carrier_in_country')"
              label="carrier_in_country"
              @change="setCarrierInCountry"
            >
              {{ $t('delivery_methods.carrier_in_country') }}
            </el-checkbox>

            <div v-if="
              isDeliveryServices &&
                value.delivery_methods.includes('carrier_in_country')
            "
            >
              <el-form-item
                label-width="0"
                prop="delivery_services"
              >
                <div class="delivery-services flex-cell">
                  <el-checkbox
                    v-for="it in deliveryServices"
                    :key="it.id"
                    :checked="isActiveDeliveryService[it.id]"
                    @change="setToggleDeliveryService(it, $event)"
                  >
                    {{ $t(`deliveryServices.${it.label.replace(/\./g, '_')}`) }}
                  </el-checkbox>
                </div>
              </el-form-item>
            </div>
          </template>
        </el-form-item>

        <div v-if="data.textField" :key="data.textField">
          <div class="mb-1">
            <el-link
              type="primary"
              @click="toggleTextField(data.textField)"
            >
              {{ $t(`salePoint.${data.textField}`) }}
            </el-link>
          </div>

          <el-form-item
            v-show="isVisibleFields[data.textField]"
            class="mr-2"
            :prop="data.textField"
            label-width="0"
          >
            <el-input
              :rows="4"
              type="textarea"
              :value="value[data.textField]"
              @input="emit(data.textField, $event)"
            />
          </el-form-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDefault } from '../../../core';
import Activities from './Activities';
import Schedule from './Schedule';
import {
  toggleInList,
  getApiUrl,
  getIdFromUrl
} from '@/lib/core/services/parsers';

export default {
  name: 'SalePointOptions',

  components: {
    Activities,
    Schedule
  },

  props: {
    value: { type: Object, required: true },
    isDeliveryServices: Boolean
  },

  data () {
    return {
      isVisibleFields: {}
    };
  },

  computed: {
    isActiveDeliveryService () {
      return this.value.delivery_services.reduce((acc, it) => {
        acc[getIdFromUrl(it.service)] = it.is_active;
        return acc;
      }, {});
    },

    deliveryServices () {
      return this.$store.getters['dependencies/deliveryServices'];
    },

    options () {
      const getList = name => this.$store
        .getters[`dependencies/${name}`]
        .filter(({ in_point_of_sale }) => in_point_of_sale)
        .map(({ label }) => label);

      return {
        payment_methods: {
          list: getList('paymentMethods'),
          textField: 'payment_methods_text'
        },
        delivery_methods: {
          list: getList('deliveryMethods')
            .filter(name => name !== 'carrier_in_country'),
          textField: 'delivery_methods_text'
        },
        other_selling_terms: {
          list: getList('otherSellingTerms')
        }
      };
    }
  },

  methods: {
    toggleTextField (fieldName) {
      this.$set(
        this.isVisibleFields,
        fieldName,
        !this.isVisibleFields[fieldName]
      );
    },

    emit (name, value) {
      this.$emit(`update:${name}`, value);
    },

    setCarrierInCountry () {
      this.emit(
        'delivery_methods',
        toggleInList(this.value.delivery_methods, 'carrier_in_country')
      );
    },

    setToggleDeliveryService (service, isActive) {
      const index = this.value.delivery_services
        .findIndex(it => it.service_detail?.id === service.id);

      const list = [...this.value.delivery_services];

      if (index > -1) {
        list[index].is_active = isActive;
        this.emit('delivery_services', list);
      } else {
        this.emit('delivery_services', list.concat({
          ...getDefault('deliveryService'),
          is_active: isActive,
          service: getApiUrl('delivery-services', service.id),
          service_detail: service
        }));
      }
    }
  }
};
</script>

<style scoped lang="scss">
.sale-point-options {
  display: flex;
  flex-wrap: wrap;

  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  .options {
    min-width: 200px;
  }

  .delivery-services {
    padding-left: 16px
  }
}
</style>
