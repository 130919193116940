<template>
  <el-row align="middle" class="interval-selector" justify="center">
    <template v-if="readonly && !showControls">
      <span v-if="value">{{ `${days}d` }} {{ `${hours}h` }}</span>
    </template>

    <template v-else>
      <el-input-number
        v-model="days"
        controls-position="right"
        :disabled="readonly"
        :min="0"
        size="mini"
        @keyup.native="emitByKey('days', $event)"
      />
      {{ $t('placeholders.days') }}
      <el-input-number
        v-model="hours"
        controls-position="right"
        :disabled="readonly || disabledHours"
        :max="23"
        :min="0"
        size="mini"
        @keyup.native="emitByKey('hours', $event)"
      />
      {{ $t('placeholders.hours') }}
    </template>
  </el-row>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import { getDayHourBy } from '../services/parsers';

export default {
  name: 'IntervalSelector',

  props: {
    readonly: Boolean,
    showControls: Boolean,
    loading: Boolean,
    value: String,
    isPriceWarehouse: Boolean
  },

  computed: {
    disabledHours () {
      return this.isPriceWarehouse ? this.days >= 1 : false;
    },

    days: {
      get () {
        return getDayHourBy(this.value).days;
      },

      set (days) {
        this.emitDays(days);
      }
    },

    hours: {
      get () {
        return getDayHourBy(this.value).hours;
      },

      set (hours) {
        this.emitHours(hours);
      }
    }
  },

  methods: {
    emitByKey (name, event) {
      this[`emit${upperFirst(name)}`](event.target.value);
    },

    getFixedHours (hours) {
      const _hours = hours || this.hours;
      return _hours > 9 ? _hours : `0${_hours}`;
    },

    emitDays (days) {
      if (!this.loading) {
        const hours = this.isPriceWarehouse
          ? `${days >= 1 ? '00' : this.getFixedHours(this.hours)}:00:00`
          : `${this.getFixedHours(this.hours)}:00:00`;

        this.emit(`${days || '00'} ${hours}`);
      }
    },

    emitHours (hours) {
      if (!this.loading) {
        const days = this.days || this.days === 0 ? this.days + ' ' : '';
        this.emit(`${days}${this.getFixedHours(hours)}:00:00`);
      }
    },

    emit (date) {
      this.$emit('input', date);
    }
  }
};
</script>

<style scoped lang="scss">
.interval-selector {
  .el-input-number {
    width: 60px;
    margin: 0 10px;

    ::v-deep .el-input__inner {
      text-align: left;
      padding: 0 4px;
    }
  }
}
</style>
