var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slider-block" }, [
    _c(
      "div",
      { staticClass: "mr-header" },
      [
        _vm.title ? _c("div", [_vm._v(_vm._s(_vm.title))]) : _vm._t("title"),
        _vm._v(" "),
        _c(
          "el-button-group",
          [
            _c("el-button", {
              attrs: { icon: "el-icon-arrow-left" },
              on: {
                click: function ($event) {
                  return _vm.mainSwiper.slidePrev()
                },
              },
            }),
            _vm._v(" "),
            _c("el-button", {
              attrs: { icon: "el-icon-arrow-right" },
              on: {
                click: function ($event) {
                  return _vm.mainSwiper.slideNext()
                },
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mr-body" },
      [
        _c(
          "swiper",
          {
            ref: "mainSwiper",
            staticClass: "gallery-top",
            attrs: { options: _vm.mainOptions },
          },
          _vm._l(_vm.slotNames, function (slotName) {
            return _c("swiper-slide", { key: slotName }, [_vm._t(slotName)], 2)
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }