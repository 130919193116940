<template>
  <div class="s-response-form">
    <div class="mb-2 req-item">
      <div class="mb-1 bold">{{ value.raw_item }}</div>
      <div>{{ value.comment }}</div>
    </div>

    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-position="left"
      @submit.native.prevent="submit"
    >
      <el-row class="form-row">
        <el-form-item
          :label="$t('search.article')"
          prop="article"
          class="article mr-2"
        >
          <el-select
            ref="article"
            remote
            filterable
            default-first-option
            :value="formData.article"
            :remote-method="searchWares"
            :loading="isVLoading"
            @change="onSetArticle"
          >
            <el-option
              v-for="option in brandsOptions"
              :key="option._id"
              :value="option"
              :label="option.name"
              :class="['search-article__option', { custom: option._id === 'custom' }]"
            >
              <el-row v-if="option._id !== 'custom'">
                <div class="trademark bold">
                  {{ option.origin.display_trademark }}
                </div>
                <div class="article">{{ option.origin.display_article }}</div>
                <div class="name">{{ option.origin.display_title }}</div>
              </el-row>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="trademark"
          class="trademark mr-2"
          :label="$t('ware.trademark')"
        >
          <el-input
            ref="trademark"
            v-model="formData.trademark"
            :disabled="readonly"
          />
        </el-form-item>

        <el-form-item prop="name" class="name" :label="$t('search.name')">
          <el-input ref="name" v-model="formData.name" />
        </el-form-item>
      </el-row>

      <el-row class="form-row">
        <el-form-item
          prop="price"
          class="mr-2 price"
          :label="$t('sRequests.price', { val: MARKETPLACE_CURRENCY })"
        >
          <el-input
            ref="price"
            :disabled="!!formData.offer_id"
            :value="formData.price"
            @input="formData.price = ($event || '').replace(/,/g, '.')"
          />
        </el-form-item>

        <el-form-item
          :label="$t('sRequests.delivery_time')"
          prop="delivery_time"
          class="mr-2 delivery-time"
        >
          <el-input
            ref="delivery_time"
            class="delivery-time"
            :disabled="!!formData.offer_id"
            :value="deliveryTime"
            @input="setDeliveryTime"
          />
        </el-form-item>

        <el-form-item prop="is_former_use" class="mr-2 is-former-use">
          <el-checkbox v-model="formData.is_former_use">
            {{ $t('ware.is_former_use') }}
          </el-checkbox>
        </el-form-item>

        <el-form-item
          prop="comment_private"
          class="comment-private"
          :label="$t('sRequests.comment_private')"
        >
          <el-input v-model="formData.comment_private" />
          <div class="meta">
            <small>{{ $t('sRequests.commentPrivateDesc') }}</small>
          </div>
        </el-form-item>
      </el-row>

      <el-form-item
        :label="$t('sRequests.comment_public')"
        prop="comment_public"
      >
        <el-input
          ref="comment_public"
          v-model="formData.comment_public"
          type="textarea"
        />
      </el-form-item>

      <el-row justify="end" class="mt-4">
        <el-button @click="confirmCancel">
          {{ $t('buttons.cancel') }}
        </el-button>

        <el-button type="primary" native-type="submit">
          {{ $t('buttons.addResponse') }}
        </el-button>
      </el-row>
    </el-form>

    <TheDialog v-show="isWareInfoId" v-model="isWareInfoId">
      <WareInfo :id="isWareInfoId" @cancel="isWareInfoId = null" />
    </TheDialog>

    <TheDialog
      v-show="!isWareInfoId"
      v-model="isSearchArticles"
      :title="$t('sRequests.selectWareFromPrises')"
      width="1100px"
    >
      <SResponseSearchWares
        v-if="isSearchArticles"
        class="mb-4"
        :value="isSearchArticles"
        @select="setDataFromWare"
        @cancel="isSearchArticles = null"
        @info:show="showWareInfo"
      />

      <el-row justify="end">
        <el-button @click="isSearchArticles = null">
          {{ $t('buttons.noPropositionUseCustom') }}
        </el-button>
      </el-row>
    </TheDialog>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import SResponseSearchWares from './SResponseSearchWares';
import {
  parsers,
  getDefault,
  getValidationFor,
  env
} from '@/lib/core';
import WareInfo from '@/lib/search/components/wareInfo/WareInfo';

const fields = [
  'article',
  'trademark',
  'name',
  'price',
  'delivery_time',
  'comment_public'
];

export default {
  name: 'SResponseForm',

  components: {
    WareInfo,
    SResponseSearchWares
  },

  props: {
    value: { type: Object, require: true },
    editableRow: Object
  },

  data () {
    return {
      MARKETPLACE_CURRENCY: env.MARKETPLACE_CURRENCY,
      isVLoading: false,
      isSearchArticles: null,
      isWareInfoId: null,
      brandsOptions: [],
      formData: getDefault('selectionResponse'),
      rules: getValidationFor(null, 'selectionResponse', this)
    };
  },

  computed: {
    readonly () {
      return Boolean(!this.formData.article || this.formData.ware_id);
    },

    deliveryTime () {
      if (this.formData.offer_id && !this.formData.delivery_time) {
        return this.$t('search.todayIn');
      }

      return this.formData.delivery_time
        ? parsers.getDayHourBy(this.formData.delivery_time).days
        : this.formData.delivery_time;
    }
  },

  created () {
    if (this.editableRow) {
      this.formData = cloneDeep(this.editableRow);
    } else {
      this.formData.item = this.value.url;
    }
  },

  methods: {
    setDeliveryTime (val) {
      if (!val) {
        this.formData.delivery_time = '';
        return null;
      }

      const _val = Number(val || '0');

      if (!Number.isNaN(_val)) {
        this.formData.delivery_time = `${_val} 00:00:00`;
      }
    },

    onSetArticle (value) {
      if (value._id === 'custom') {
        this.formData.offer_id = null;
        this.formData.article = this.$refs.article?.query || '';
        this.formData.ware_id = null;
      } else {
        this.formData.article = value.origin.display_article;
        this.formData.trademark = value.origin.display_trademark;
        this.formData.name = value.origin.display_title;
        this.formData.ware_id = value.origin.ware?.id;

        this.isSearchArticles = value.origin;
      }

      this.setFocusOnNext();
    },

    async searchWares (query = '') {
      const customOption = {
        _id: 'custom',
        name: this.$t('sRequests.customWareSelect'),
        value: 'custom'
      };

      if (query.length < 3) {
        this.brandsOptions = [];
        this.brandsOptions.push(customOption);
        return null;
      }

      this.isVLoading = true;

      try {
        const { results } = await this.$store.dispatch(
          'cache/get',
          {
            action: 'search/searchInWares',
            payload: { query, hideCity: true }
          }
        );

        this.brandsOptions = results
          .filter(({ ware }) => ware)
          .map(item => ({
            _id: parsers.getDefaultId(),
            name: item.article,
            value: item.article,
            origin: item
          }));

        this.brandsOptions.push(customOption);
      } catch (e) {
        console.error(e);
        this.brandsOptions = [];
        this.brandsOptions.push(customOption);
      } finally {
        this.isVLoading = false;
      }
    },

    setDataFromWare (ware) {
      this.formData.offer_id = ware.id;
      this.formData.ware_id = ware.ware?.id;
      this.formData.ware_image_url = ware.ware?.image_url;
      this.formData.article = ware.display_article;
      this.formData.trademark = ware.display_trademark;
      this.formData.name = ware.display_title;
      this.formData.price = ware.price;
      this.formData.delivery_time = ware.delivery_time;
      this.formData.is_former_use = ware.other_selling_terms?.includes('former_use');
      this.formData.point_of_sale = ware.point_of_sale?.id;

      const priceName = ware.price_file?.name ? `${ware.price_file?.name}. ` : '';
      this.formData.comment_private = `${priceName}${this.formData.comment_private}`;

      this.isSearchArticles = null;

      this.setFocusOnNext();
    },

    submit () {
      this.$refs.form.validate((isValid) => {
        if (!isValid) {
          return false;
        }

        const action = this.editableRow ? 'update' : 'submit';
        this.$emit(action, {
          item: this.value,
          value: this.formData,
          origin: this.editableRow
        });
      });
    },

    setFocusOnNext () {
      this.$nextTick(() => {
        for (let i = 0; i < fields.length; i++) {
          if (!this.formData[fields[i]] && this.$refs[fields[i]]) {
            this.$refs[fields[i]].$el.firstElementChild.focus();
            break;
          }
        }
      });
    },

    async confirmCancel () {
      const isConfirm = await this.$confirm(
        this.$t('sRequests.responseExitConfirm'),
        {
          type: 'warning',
          title: this.$t('message.warning'),
          confirmButtonText: this.$t('buttons.yes'),
          cancelButtonText: this.$t('buttons.no')
        }
      );

      if (isConfirm) {
        this.$emit('cancel');
      }
    },

    showWareInfo (ware) {
      this.isWareInfoId = ware.ware_id || ware.ware?.id || ware.id;
    }
  }
};
</script>

<style scoped lang="scss">
.s-response-form {
  .form-row {
    flex-wrap: wrap;
  }

  .req-item {
    background-color: $grey-100;
    padding: 20px;
  }

  .article, .trademark, .price {
    width: 250px;
  }

  .name, .comment-private {
    padding-top: 2px;
    flex: 1;
    min-width: 250px;
  }

  .price {
    padding-right: 0;
  }

  .delivery-time {
    width: 190px;
  }

  .is-former-use {
    margin-top: 42px;
  }

  .meta {
    line-height: 1rem;
  }
}

.search-article__option {
  .trademark {
    min-width: 200px;
    margin-right: 4px;
  }

  .article {
    min-width: 100px;
    margin-right: 4px;
  }

  .name {
    min-width: 150px;
  }

  &.custom {
    background-color: $blue-50;
  }
}
</style>
