import Cookie from 'cookie';
import Cookies from 'js-cookie';

const NEXT_YEAR = 364;

const remove = key => process.client && Cookies.remove(key);

const set = (key, value) => {
  return process.client && Cookies.set(key, value, { expires: NEXT_YEAR });
};

const get = key => process.client && Cookies.get(key);

const parse = data => Cookie.parse(data);

export default {
  remove,
  set,
  get,
  parse
};
