import { i18n } from '@/plugins/i18n';

const getChildrenBy = entity => Object
  .keys(i18n.t(entity))
  .map(code => ({ value: code, label: i18n.t(`${entity}.${code}`) }));

const relationTypes = getChildrenBy('relationTypes');
const weightUnitTypes = Object.keys(i18n.t('weightUnitTypes'));
const sizesUnitTypes = Object.keys(i18n.t('sizesUnitTypes'));
const tareTypes = getChildrenBy('tareTypes');
const imageTypes = getChildrenBy('imageTypes');

const getCreateQuantity = () => ({
  value: 'quantity',
  label: i18n.t('priceMapping.quantity'),
  field: 'quantity_warehouse.url',
  element: 'WarehouseInput'
});

const getQuantitySelection = parsedWarehouses => ({
  value: 'quantity',
  label: i18n.t('priceMapping.quantity'),
  field: 'quantity_warehouse.url',

  children: [
    ...parsedWarehouses,
    {
      isAction: true,
      value: 'warehouseCreate',
      label: i18n.t('priceMapping.createWarehouse')
    }
  ]
});

export const nonFiltered = [
  'purchase_price',
  'selling_price',
  'quantity',
  'image'
];

const getPriceCollMapping = (dependency) => {
  const { priceTags = [], warehouses = [], priceSchemes = [], mode } = dependency;

  const parsedPriceTags = priceTags.map(({ name, url }) => ({
    value: url,
    label: i18n.t(`priceMapping.${name}`)
  }));

  const parsedWarehouses = warehouses.map(({ name, url }) => ({
    value: url,
    label: name
  }));

  const quantity = mode === 'create'
    ? getCreateQuantity()
    : getQuantitySelection(parsedWarehouses);

  const parsedPriceSchemes = priceSchemes.map(({ name, url }) => ({
    value: url,
    label: name,
    field: 'price_def.tags',
    // multiple: true,
    children: parsedPriceTags
  }));

  return {
    types: [
      {
        value: 'article',
        label: i18n.t('priceMapping.article')
      },
      {
        value: 'trademark',
        label: i18n.t('priceMapping.trademark')
      },
      {
        value: 'title',
        label: i18n.t('priceMapping.title')
      },
      {
        value: 'selling_price',
        label: i18n.t('priceMapping.selling_price'),
        field: 'price_def.tags',
        // multiple: true,
        children: parsedPriceTags
      },
      {
        value: 'purchase_price',
        label: i18n.t('priceMapping.purchase_price'),
        field: 'price_def.tags',
        buttons: [
          {
            isAction: true,
            value: 'priceSchemeCreate',
            label: i18n.t('buttons.createPriceScheme')
          }
        ]
      },
      quantity,
      {
        value: 'multiplicity',
        label: i18n.t('priceMapping.multiplicity')
      },
      {
        value: 'description',
        label: i18n.t('priceMapping.description')
      },
      {
        value: 'note',
        label: i18n.t('priceMapping.note')
      },
      {
        value: 'image',
        label: i18n.t('priceMapping.image'),
        field: 'image_type',
        childIsOptional: true,
        children: imageTypes
      },
      {
        value: 'analog_article',
        label: i18n.t('priceMapping.analog_article'),
        field: 'relation_type',
        children: relationTypes
      },
      {
        value: 'analog_trademark',
        label: i18n.t('priceMapping.analog_trademark')
      },
      {
        value: 'weight',
        label: i18n.t('priceMapping.weight'),
        field: 'unit',
        children: weightUnitTypes.map(code => ({
          value: code,
          label: i18n.t(`weightUnitTypes.${code}`),
          field: 'tare_type',
          childIsOptional: true,
          children: tareTypes
        }))
      },
      {
        value: 'sizes',
        label: i18n.t('priceMapping.sizes'),
        field: 'unit',
        children: sizesUnitTypes.map(code => ({
          value: code,
          label: i18n.t(`sizesUnitTypes.${code}`),
          field: 'tare_type',
          childIsOptional: true,
          children: tareTypes
        }))
      },
      {
        value: 'barcode',
        label: i18n.t('priceMapping.barcode')
      },
      {
        value: 'ucgfea',
        label: i18n.t('priceMapping.ucgfea')
      }
    ],
    price_actions: [
      {
        value: 'make_selling_price',
        label: i18n.t('priceMapping.selling_price'),
        field: 'pricing',
        readonly: true,
        children: [
          ...parsedPriceSchemes,
          {
            isAction: true,
            value: 'priceSchemeCreate',
            label: i18n.t('buttons.createPriceScheme')
          }
        ]
      }
    ]
  };
};

export default getPriceCollMapping;
