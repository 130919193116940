var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { attrs: { align: "middle", justify: "center" } },
    [
      _vm.value._canRevertStatus
        ? _c("i", {
            staticClass: "icon el-icon-refresh-left c-pointer",
            on: { click: _vm.value._revertStatusTimer.cancel },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-ellipsis pg-0-1" },
        [
          _c(
            "TheTooltip",
            { attrs: { content: _vm.$t("orderStatuses." + _vm.value.status) } },
            [
              _c("span", { staticClass: "status" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("orderStatuses." + _vm.value.status)) +
                    "\n      "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "TheTooltip",
        {
          attrs: {
            content: _vm.$t("buttons.setStatus", {
              status: _vm.$t(
                "orderStatuses." + _vm.getNextStatus(_vm.value.status)
              ),
            }),
          },
        },
        [
          _vm.canSetNextStatus(_vm.value.status)
            ? _c("img", {
                staticClass: "icon-img c-pointer",
                attrs: { src: require("@/assets/icons/redo.svg") },
                on: {
                  click: function ($event) {
                    return _vm.setNextStatus(_vm.value)
                  },
                },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }