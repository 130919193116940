<template>
  <el-button
    class="price-remover"
    plain
    size="mini"
    type="danger"
    :loading="isLoading"
    :disabled="readonly"
    @click="onRemove"
  >
    {{ $t('buttons.removePrice') }}
  </el-button>
</template>

<script>
export default {
  name: 'PriceRemover',

  props: {
    readonly: Boolean,
    price: { type: Object, required: true }
  },

  data: () => ({ isLoading: false }),

  methods: {
    async onRemove () {
      const { filename, price_file } = this.price;
      const name = price_file && price_file.name ? price_file.name : filename;

      const isAgreed = await this.$confirm(
        this.$t('prices.removeConfirm', { name }),
        { type: 'warning', title: this.$t('message.warning') }
      );

      if (isAgreed) {
        return this.removePrice();
      }
    },

    async removePrice () {
      this.isLoading = true;

      try {
        await this.$store.dispatch(
          'priceUploads/removePrice',
          { id: this.price.id }
        );

        this.$notify.success({
          title: this.$t('message.success'),
          message: this.$t('message.priceRemoved')
        });

        await this.$router.push({
          name: this.getRouteName('profile.business.prices')
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
