<template>
  <div class="rate-warehouse-table">
    <el-table
      ref="table"
      :data="value"
      :header-cell-style="{ textAlign: 'center' }"
      row-key="_id"
    >
      <el-table-column width="55">
        <template #header>
          <el-checkbox v-model="isSelectionAll" />
        </template>

        <template #default="{ row }">
          <el-checkbox v-model="row.is_active" @change="updateRow(row)" />
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('salePoint.salePoint')"
        min-width="250px"
        prop="_salePointName"
      >
        <template #default="{ row, $index }">
          <el-row align="middle" justify="space-between">
            {{ row._salePointName }}
            <el-button
              v-if="!$index"
              circle
              icon="el-icon-document-copy"
              size="mini"
              @click="$emit('copy-value', row)"
            />
          </el-row>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('salePoint.addressBlock')"
        width="120px"
        prop="_salePointCity"
        align="center"
      >
        <template #default="{ row }">
          {{ row._salePointCity }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :label="$t('prices.order_time_until')"
        min-width="150px"
        prop="delivery_time"
      >
        <template #default="{ row }">
          <TimeSelector
            v-model="row.order_time_until"
            :readonly="!row.is_active"
            required
            @input="updateRow(row)"
          />
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :label="$t('prices.delivery_time')"
        min-width="250px"
        prop="delivery_time"
      >
        <template #default="{ row }">
          <IntervalSelector
            v-model="row.delivery_time"
            is-price-warehouse
            :readonly="!row.is_active"
            show-controls
            @input="updateRow(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import TimeSelector from './TimeSelector';
import { IntervalSelector } from '@/lib/core';

export default {
  name: 'RateWarehouseTable',

  components: {
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column'),
    /* eslint-enable max-len */
    TimeSelector,
    IntervalSelector
  },

  props: {
    value: { type: Array, required: true }
  },

  computed: {
    isSelectionAll: {
      get () {
        return this.value.every(({ is_active }) => is_active);
      },

      set (isSelected) {
        this.$emit('toggle-selection', isSelected);
      }
    }
  },

  methods: {
    updateRow (row) {
      this.$emit('update-row', row);
    }
  }
};
</script>

<style scoped lang="scss">
.rate-warehouse-table {
  .order_time_until {
    width: 50px;

    ::v-deep .el-input__inner {
      text-align: center;
      padding: 0;
    }
  }
}
</style>
