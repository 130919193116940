import {
  seoNamesByParams,
  withCarSeoPages
} from '@/lib/core/services/routeParser';

import parsers from '@/lib/core/services/parsers';

const getFilteredParams = (innerParams, params, vm, options) => {
  const result = { ...params, ...innerParams };

  delete result.pathMatch;

  if (!options.page) {
    delete result.page;
  }

  Object.keys(innerParams).forEach((key) => {
    if (!result[key]) {
      delete result[key];
    }
  });

  const { carBrand, carModel, carGeneration } = innerParams;

  if (carBrand && !carModel && !carGeneration) {
    delete result.carModel;
    delete result.carGeneration;
    delete result.carModification;
  } else if (carModel && !carGeneration) {
    delete result.carGeneration;
    delete result.carModification;
  }

  /**
     * carModification use like query if withCarSeoPages
     */
  // else if (carGeneration && !carModification) {
  delete result.carModification;
  // }

  return result;
};

const getKey = (params) => {
  const { page, ..._params } = params;
  let key = Object.keys(_params).sort().toString();

  if (page) {
    key += ',page';
  }
  return key;
};

const getRouteName = (params, vm, options) => {
  let _params = params;
  let key = getKey(_params);
  let name = seoNamesByParams[key];

  /**
   * IT used for add selected car data to links,
   * it works only for route with meta: { withCar } in router.js.
   * If you want to miss selected car use:
   * getSeoLinkBy(params, vm, params, { missCar: true });
   */
  const carData = vm.$store.getters['carModifications/carData'];

  if (!options.missCar && withCarSeoPages[name] && carData) {
    const { carModification, ..._carData } = carData;
    _params = { ..._params, ..._carData };
    key = getKey(_params);
    name = seoNamesByParams[key];
  }

  return {
    name: key !== '' ? name : name.replace('__ru', ''),
    key,
    params: _params
  };
};

const getPath = ({ vm, name, params, query }) => {
  if (!vm.$router) {
    return null;
  }
  const { href } = vm.$router.resolve({ name, params, query });

  return href;

  // const routes = vm.$router.options.routes;
  // const route = routes.find(route => route.name === name);
  //
  // if (!route) {
  //   console.error(`THERE IS NO ROUTE WITH NAME ${name}`);
  // }
  //
  // const path = route.path.replace(/\(.+\)/g, '');
  //
  // return Object.keys(params).reduce((acc, key) => {
  //   return acc.replace(`:${key}`, params[key]);
  // }, path);
};

/**
 * @param innerParams Object
 * @param vm {{ $router, $store }}
 * @param vmParams Object
 * @param options Object
 * @returns {{path: string, name, query, params}|{path, name: (string|*|string), query, params}}
 */

// TODO clear it
const getSeoLinkBy = (innerParams, vm, vmParams, options = {}) => {
  const { carModification, ...p } = vmParams || vm.$router.currentRoute.params;
  const filteredParams = getFilteredParams(innerParams, p, vm, options);
  const data = getRouteName(filteredParams, vm, options);
  const _carModification = carModification || innerParams.carModification;

  const query = {};
  let _name = data.name;

  const mId = vm.$store.getters['carModifications/modificationId'];

  if (withCarSeoPages[_name] && (_carModification || mId) && !options.missCar) {
    query.cm = (_carModification || mId);
  }

  if (options.gclid) {
    query.gclid = options.gclid;
  }

  if (data.params.regionId && !_name) {
    const { id, regionId, ..._params } = data.params;
    const _vm = {
      ...vm,
      $router: {
        ...vm.$router,
        currentRoute: {
          ...vm.$router.currentRoute,
          params: _params
        }
      }
    };

    const { name } = getSeoLinkBy(innerParams, _vm);
    _name = name;
  }

  if (!_name) {
    console.error(`THERE IS NO LINK FOR ${data.key}`);

    return {
      name: vm.$router.currentRoute.name,
      params: data.params,
      query,
      path: '#'
    };
  }

  const { name } = parsers.getRouteByLang({ name: _name });

  const path = getPath({ name, vm, params: data.params, query });

  return {
    name,
    params: data.params,
    query,
    path
  };
};

export default getSeoLinkBy;
