import { env } from '@/lib/core';

import {
  GET_GARAGE_CARS,
  GET_GARAGE_CAR,
  CREATE_GARAGE_CAR,
  UPDATE_GARAGE_CAR
} from '@/schemes/carsGql';
import { GET_CAR_ELEMENT_TYPES } from '@/schemes/dependencyGql';

const getUserId = ({ rootGetters }) => {
  const business = rootGetters['businessProfiles/businessId'];
  const person = rootGetters['users/userId'];
  const res = {};

  if (business) {
    res.business = business;
  } else if (person) {
    res.person = person;
  }

  return res;
};

const getCarsQLOptions = ({ rootGetters }) => ({
  query: GET_GARAGE_CARS,
  variables: {
    isActive: true,
    ...getUserId({ rootGetters })
  }
});

const getRefetchCarsOptions = ({ rootGetters }) => ({
  refetchQueries: [getCarsQLOptions({ rootGetters })],
  awaitRefetchQueries: true
});

export const state = () => ({
  brands: [],
  cars: [],
  elementTypes: {}
});

export const mutations = {
  setBrands: (state, brands) => { state.brands = brands; },
  setCars: (state, cars) => { state.cars = cars; },
  setElementTypes: (state, types) => { state.elementTypes = types; }
};

export const actions = {
  async fetchCarsQL ({ getters, commit, rootGetters }, options) {
    try {
      const { data } = await this.$apollo.query(
        {
          ...getCarsQLOptions({ rootGetters }),
          fetchPolicy: options?.force ? 'network-only' : undefined
        });

      commit('setCars', data.garageCars);

      return getters.cars;
    } catch (e) {
      console.error(e);
      return getters.cars;
    }
  },

  async getCarQL ({ rootGetters }, { id }) {
    const { data } = await this.$apollo.query({
      query: GET_GARAGE_CAR,
      variables: { garageCarId: id }
    });

    return data.garageCar;
  },

  async createCarQL ({ dispatch, rootGetters }, { formData }) {
    const { data } = await this.$apollo.mutate({
      mutation: CREATE_GARAGE_CAR,
      variables: {
        input: {
          ...formData,
          ...getUserId({ rootGetters })
        }
      },
      ...getRefetchCarsOptions({ rootGetters })
    });

    return data.createGarageCar?.garageCar;
  },

  async updateCarQL ({ dispatch, rootGetters }, { id, formData }) {
    const { data } = await this.$apollo.mutate({
      mutation: UPDATE_GARAGE_CAR,
      variables: {
        updateGarageCarId: id,
        input: formData
      },
      ...getRefetchCarsOptions({ rootGetters })
    });

    return data.updateGarageCar?.garageCar;
  },

  async deleteCarQl ({ dispatch, rootGetters }, { id }) {
    const { data } = await this.$apollo.mutate({
      mutation: UPDATE_GARAGE_CAR,
      variables: {
        updateGarageCarId: id,
        input: { isActive: false }
      },
      ...getRefetchCarsOptions({ rootGetters })
    });

    return data.deleteGarageCar;
  },

  async fetchElementTypesQL ({ commit, getters }) {
    try {
      const { data } = await this.$apollo.query({
        query: GET_CAR_ELEMENT_TYPES
      });

      commit('setElementTypes', data);

      return getters.elementTypes;
    } catch (e) {
      return getters.elementTypes;
    }
  },

  setCarsData ({ commit, getters }, { cars } = {}) {
    commit('setCars', cars || []);
    return { cars: getters.cars };
  },

  async fetchBrands ({ getters, commit }) {
    if (getters.brands.length) {
      return getters.brands;
    }

    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.GARAGE_API,
      url: '/car-brands/'
    });

    commit('setBrands', data.results);

    return data.results;
  }
};

export const getters = {
  brands: state => state.brands,
  cars: state => state.cars.filter(({ isActive }) => isActive),
  elementTypes: state => state.elementTypes
};
