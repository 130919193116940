var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-request-processing-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("routes.profile_business_s_requests_edit")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : [
            _c(
              "el-row",
              { staticClass: "mb-2", attrs: { justify: "space-between" } },
              [
                _c("div", [_vm._v("№" + _vm._s(_vm.originFormData.public_id))]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("sRequests.created", {
                          date: _vm.getDate(_vm.originFormData.created),
                        })
                      ) +
                      "\n      "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-1" },
              [
                _c(
                  "el-collapse",
                  {
                    staticClass: "mb-2",
                    attrs: { accordion: "" },
                    model: {
                      value: _vm.isCarInfo,
                      callback: function ($$v) {
                        _vm.isCarInfo = $$v
                      },
                      expression: "isCarInfo",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { name: "car" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "custom-headline custom-headline--level3-bold pg-0-1",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getShortCarTitleOld(
                                      _vm.originFormData.car_detail
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("CarFullInfoItem" + (_vm.maxmq("sm") ? "M" : ""), {
                          tag: "component",
                          attrs: {
                            value: _vm.originFormData.car_detail,
                            readonly: "",
                            old: "",
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "BufferCopy",
                  { attrs: { value: _vm.originFormData.car_detail.vin } },
                  [
                    _c("span", { staticClass: "text-ghost" }, [_vm._v("VIN:")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.originFormData.car_detail.vin) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isResponseForm
              ? _c("SResponseForm", {
                  attrs: {
                    value: _vm.isResponseForm,
                    "editable-row": _vm.editableRow,
                  },
                  on: {
                    submit: _vm.addResponseToItem,
                    update: _vm.updateResponseItem,
                    cancel: function ($event) {
                      _vm.isResponseForm = false
                    },
                  },
                })
              : [
                  _vm.originFormData.comment
                    ? _c("div", { staticClass: "comment mb-2" }, [
                        _c("div", { staticClass: "mb-1 bold" }, [
                          _vm._v(_vm._s(_vm.$t("sRequests.comment"))),
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.originFormData.comment))]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("SRequestProcessingItemsTable", {
                    attrs: {
                      "is-comment-private": "",
                      value: _vm.formData.items,
                    },
                    on: {
                      "add-item": function ($event) {
                        _vm.editableRowData = null
                        _vm.isResponseForm = $event
                      },
                      remove: _vm.removeRowFromItem,
                      edit: _vm.editRowItem,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticClass: "mt-4 mb-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { value: _vm.isArticleHidden },
                          on: { change: _vm.setIsArticleHidden },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("sRequests.hideArticlesForBuyer")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { justify: "end" } },
                    [
                      _c("el-button", { on: { click: _vm.confirmCancel } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("buttons.cancel")) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.isVLoading,
                            disabled: _vm.isEqual,
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.submitRequest")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }