var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "address-select" },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("basket.deliveryCity"),
            prop: "delivery_address.city",
          },
        },
        [
          _vm.cityReadonly
            ? _c("div", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.value.city_detail ? _vm.value.city_detail.name : ""
                    ) +
                    "\n    "
                ),
              ])
            : _c("el-input", { attrs: { value: _vm.value.city_detail.name } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("basket.delivery_address"),
            prop: "delivery_address",
          },
        },
        [
          _vm.addressReadonly
            ? _c(
                "TheTooltip",
                { attrs: { content: _vm.value._label } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", value: _vm.value._label },
                  }),
                ],
                1
              )
            : !_vm.parsedAddresses.length
            ? _c("el-input", {
                attrs: { value: _vm.value._label },
                on: {
                  input: function ($event) {
                    return _vm.setField("delivery_address._label", $event)
                  },
                },
              })
            : _c(
                "el-select",
                {
                  staticClass: "w-100",
                  attrs: {
                    filterable: "",
                    "allow-create": "",
                    "value-key": "_label",
                    value: _vm.value,
                  },
                  on: { change: _vm.setAddress },
                },
                _vm._l(_vm.parsedAddresses, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item._label, value: item },
                  })
                }),
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }