var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "development-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold mb-4" },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("core.pageInDevelopmentNow")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("nuxt-link", { attrs: { to: _vm.link } }, [
        _vm._v(_vm._s(_vm.$t("buttons.goBackHome"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }