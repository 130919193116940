var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "steps-panel" },
    [
      _c(
        "el-steps",
        {
          attrs: {
            active: _vm.active,
            "finish-status": "success",
            "align-center": "",
          },
        },
        _vm._l(_vm.$t(_vm.entity + "s.steps"), function (step, index) {
          return _c("el-step", { key: index, attrs: { description: step } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }