import isNaN from 'lodash/isNaN';
import { env, library, parsers } from '@/lib/core';

const getUrlBy = (params) => {
  // TODO clear it
  const keys = Object.keys(parsers.getClearSeoParams(params));
  delete keys.page;

  const key = keys.sort().toString();
  let url = library.seoUrlsByParams[key] || '';

  keys.forEach((key) => {
    if (params[key]) {
      const field = key === 'lineId' && !isNaN(Number(params[key]))
        ? params[key]
        : `:${params[key]}`;

      url = url.replace(key, field);
    }
  });

  return { key, url };
};

export const actions = {
  async getSeoData ({ rootGetters }, params = {}) {
    const {
      products_limit,
      products_offset,
      car_modification,
      products_ordering,
      ..._params
    } = params;

    const { url, key } = getUrlBy(_params);

    if (!url) {
      throw new Error(`THERE IS NO API FOR PARAMS: ${key}`);
    }

    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.SEO_API,
      url,
      params: {
        products_limit,
        products_offset,
        car_modification,
        products_ordering,
        lang: rootGetters.langCode
      }
    });

    return data;
  },

  async getSeoTrademarks (_, params = {}) {
    const id = params.trademarkId;

    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.SEO_API,
      url: `/trademarks/${id ? `:${id}/` : ''}`
    });

    return data;
  },

  async getSeoCars () {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.SEO_API,
      url: '/car-brands/'
    });

    return data;
  },

  async getWareMeta ({ commit, rootGetters, getters }, payload) {
    const { id, trademarkSlug, articleSlug } = payload;
    const url = id
      ? `/wares/${id}/`
      : `/wares/${trademarkSlug}/${articleSlug}/`;

    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.SEO_API,
      url
    });

    // TODO what can we do whit this?
    data.images.forEach((item) => {
      item.type = 'image';
      item.url = item.image_url;
    });

    return data;
  }
};
