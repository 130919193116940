<template>
  <div class="activate-resend-forms">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <div class="text-ghost mb-4 mt-2">
        <p>{{ $t('auth.completionAuth') }}</p>
      </div>

      <el-row class="form-block" :gutter="8">
        <ActivateForm />
        <div class="splitter">
          <div class="line" />
          <div class="text">{{ $t('auth.or') }}</div>
          <div class="line" />
        </div>
        <ResendActivationForm />
      </el-row>
    </template>
  </div>
</template>

<script>
import ResendActivationForm from './ResendActivationForm';
import ActivateForm from './ActivateForm';

export default {
  name: 'ActivateResendForms',

  components: {
    ActivateForm,
    ResendActivationForm
  },

  data: () => ({ isLoading: true }),

  async mounted () {
    if (this.$store.getters['auth/isAuthenticated']) {
      await this.$router.push({ name: this.getRouteName('auth.completion') });
    } else {
      await this.autoActivate();
    }

    this.isLoading = false;
  },

  methods: {
    async autoActivate () {
      const { email, activation_key } = this.$route.query;

      if (email && activation_key) {
        try {
          await this.$store.dispatch(
            'users/postActivateUser',
            { email, activation_key }
          );

          this.showSuccess();

          return this.$router.push({
            name: this.getRouteName('auth.completion')
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        this.$notify.info({ message: this.$t('message.checkEmail') });
      }
    },

    showSuccess () {
      this.$notify.success({
        title: this.$t('message.success'),
        message: this.$t('message.isActivated')
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/auth-forms.scss";
</style>
