<template>
  <div class="collapse-icon" :class="{ valid: isValid }">
    <div :class="['border', { valid: isValid }]" />
    <img :src="src">
  </div>
</template>

<script>
export default {
  name: 'CollapseIcon',

  props: {
    isValid: {
      type: Boolean
    }
  },

  computed: {
    src () {
      return this.isValid
        ? require('@/assets/icons/verified-success.svg')
        : require('@/assets/icons/verified.svg');
    }
  }
};
</script>

<style scoped lang="scss">
.collapse-icon {
  display: flex;
  align-items: center;
  position: relative;
  width: 40px;
  height: 100%;
  margin-right: 10px;
  line-height: 0;

  .border {
    height: 100%;
    width: 10px;
    background-color: $grey-400;
    margin-right: 10px;

    &.valid {
      background-color: $green-400;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }
}
</style>
