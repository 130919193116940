<template>
  <el-row class="delivery-rate-params" justify="space-between">
    <el-form-item label-width="0" prop="correction_factor">
      <NumInput
        field="correction_factor"
        :readonly="readonly"
        :to-fixed="3"
        :value="value.correction_factor"
        @input="emit('correction_factor', $event)"
      />
    </el-form-item>

    <el-form-item label-width="0" prop="constant">
      <NumInput
        field="constant"
        :label="$t('deliveryRate.constant', { name: l_currencyName })"
        :readonly="readonly"
        :value="value.constant"
        @input="emit('constant', $event)"
      />
    </el-form-item>

    <el-form-item label-width="0" prop="weight_rate">
      <NumInput
        field="constant"
        :label="$t('deliveryRate.weight_rate', { name: l_currencyName })"
        :readonly="readonly"
        :value="value.weight_rate"
        @input="emit('weight_rate', $event)"
      />
    </el-form-item>

    <el-form-item label-width="0" prop="no_weight_factor">
      <NumInput
        field="no_weight_factor"
        :readonly="readonly"
        :to-fixed="3"
        :value="value.no_weight_factor"
        @input="emit('no_weight_factor', $event)"
      />
    </el-form-item>

    <el-form-item label-width="0" prop="no_trade_without_weight">
      <el-checkbox
        :disabled="readonly"
        :label="$t('deliveryRate.no_trade_without_weight')"
        :value="value.no_trade_without_weight"
        @change="emit('no_trade_without_weight', $event)"
      />
    </el-form-item>
  </el-row>
</template>

<script>
import NumInput from './NumInput';

NumInput.data = () => ({ scope: 'deliveryRate' });

export default {
  name: 'DeliveryRateParams',

  components: {
    NumInput
  },

  props: {
    readonly: Boolean,
    currencyName: String,
    value: { type: Object, required: true }
  },

  computed: {
    l_currencyName () {
      return this.currencyName ? `, ${this.currencyName}` : '';
    }
  },

  methods: {
    emit (name, value) {
      this.$emit(`update:${name}`, value);
    }
  }
};
</script>

<style scoped lang="scss">
.delivery-rate-params {
  ::v-deep .num-input {
    height: 100%;
    width: 120px;

    .label {
      height: 30px;
    }
  }

  .el-checkbox {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    ::v-deep .el-checkbox__label {
      margin-bottom: 28px;
    }
  }
}
</style>
