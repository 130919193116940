<script>
import DescriptionBlock from './formBlocks/DescriptionBlock';
import PhotosBlock from './formBlocks/PhotosBlock';
import FormTab from '@/lib/core/components/FormTab';

export default {
  name: 'SalePointDescriptionTab',

  extends: FormTab,

  components: {
    DescriptionBlock,
    PhotosBlock
  },

  data: () => ({
    blocks: ['description', 'photos']
  })
};
</script>
