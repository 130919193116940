var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "main-info",
        {
          "highlight-required": _vm.dependency.mode === "create",
        },
      ],
    },
    [
      _c(
        "el-row",
        { staticClass: "fl-wrap mb-2" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "mr-2",
              attrs: { label: _vm.$t("priceFile.name"), prop: "name" },
            },
            [
              _c("el-input", {
                staticClass: "w-350p",
                attrs: { value: _vm.value.name },
                on: {
                  input: function ($event) {
                    return _vm.emit("name", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "no-wrap-error mr-4",
              attrs: { label: _vm.$t("priceFile.currency"), prop: "currency" },
            },
            [
              _c("CurrencySelector", {
                attrs: { value: _vm.value.currency },
                on: {
                  input: function ($event) {
                    return _vm.emit("currency", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "checkboxes mr-2" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "mb-0",
                  attrs: {
                    "label-width": "0",
                    prop: "quantity_warehouse_is_own",
                  },
                },
                [
                  _c("el-checkbox", {
                    attrs: {
                      label: _vm.$t("priceFile.quantity_warehouse_is_own"),
                      value: _vm.value.quantity_warehouse_is_own,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.emit("quantity_warehouse_is_own", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mb-0",
                  attrs: { "label-width": "0", prop: "other_selling_terms" },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "cell",
                      attrs: { value: _vm.value.other_selling_terms },
                      on: {
                        input: function ($event) {
                          return _vm.emit("other_selling_terms", $event)
                        },
                      },
                    },
                    _vm._l(_vm.otherSellingTerms, function (key) {
                      return _c(
                        "el-checkbox",
                        { key: key, attrs: { label: key, value: key } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("other_selling_terms." + key)) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "mb-0",
              attrs: { "label-width": "0", prop: "payment_terms" },
            },
            [
              _c("el-checkbox", {
                attrs: {
                  label: _vm.$t("priceFile.fullPrepaymentForPrice"),
                  value: (_vm.value.payment_terms || []).includes(
                    "full_prepayment"
                  ),
                },
                on: {
                  change: function ($event) {
                    return _vm.updateList(
                      "payment_terms",
                      "full_prepayment",
                      $event
                    )
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("div", { staticClass: "mb-1 text-ghost" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("priceFile.payment_terms_text")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { "label-width": "0", prop: "payment_terms_text" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      disabled: !_vm.isIn("payment_terms", "full_prepayment"),
                      value: _vm.value.payment_terms_text,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.emit("payment_terms_text", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  staticClass: "mb-0 mr-2",
                  attrs: { "label-width": "0", prop: "other_selling_terms" },
                },
                [
                  _c("el-checkbox", {
                    attrs: {
                      label: _vm.$t("labels.is_warranted"),
                      value: (_vm.value.other_selling_terms || []).includes(
                        "warranted"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.updateList(
                          "other_selling_terms",
                          "warranted",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mb-0",
                  attrs: { "label-width": "0", prop: "other_selling_terms" },
                },
                [
                  _c("el-checkbox", {
                    attrs: {
                      label: _vm.$t("other_selling_terms.return_possible"),
                      value: (_vm.value.other_selling_terms || []).includes(
                        "return_possible"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.updateList(
                          "other_selling_terms",
                          "return_possible",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("div", { staticClass: "mb-1 text-ghost" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("priceFile.warrantedReturnTermsText")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { "label-width": "0", prop: "warranty_terms_text" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      disabled: !_vm.isIn("other_selling_terms", [
                        "warranted",
                        "return_possible",
                      ]),
                      value: _vm.value.warranty_terms_text,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.emit("warranty_terms_text", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.dependency.mode !== "create"
            ? _c(
                "el-form-item",
                { attrs: { "label-width": "0", prop: "description" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 4, maxRows: 20 },
                      placeholder: _vm.$t("priceFile.description"),
                      type: "textarea",
                      value: _vm.value.description,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.emit("description", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }