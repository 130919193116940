var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        staticClass: "file-processing-block",
        attrs: {
          "completeness-every": "",
          "completeness-fields": ["data_start_row", "column_mapping"],
          "entity-name": "price",
          fields: _vm.fields,
          "label-position": "left",
          "label-width": "320px",
          submit: _vm.submit,
          unlocked: _vm.unlocked,
          value: _vm.value,
        },
        on: {
          refresh: function ($event) {
            return _vm.$emit("page:update", $event.data_sheet)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var formData = ref.formData
              var readonly = ref.readonly
              var isLoading = ref.isLoading
              return [
                ["error", "invalid"].includes(_vm.dependency.price.status)
                  ? [
                      _c("div", { staticClass: "mb-2" }, [
                        _c("span", { staticClass: "color-red" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("prices.status")) +
                              ":\n          " +
                              _vm._s(
                                _vm.getPriceErrorTranslation(
                                  _vm.dependency.price.error_code
                                )
                              ) +
                              "\n        "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("p", { staticClass: "color-red" }, [
                          _vm._v(_vm._s(_vm.dependency.price.error_message)),
                        ]),
                      ]),
                    ]
                  : _vm.isEmpty(_vm.dependency.price.data_preview)
                  ? [
                      _c("div", { staticClass: "pg-2 text-center" }, [
                        _vm._v(_vm._s(_vm.$t("priceFile.waitPreview"))),
                      ]),
                      _vm._v(" "),
                      _c("TheLoader"),
                    ]
                  : [
                      _c(
                        "FileProcessing",
                        _vm._b(
                          {
                            attrs: {
                              dependency: _vm.dependency,
                              readonly: readonly,
                              value: formData,
                              "data-preview-count":
                                _vm.dependency.price.data_preview_count,
                            },
                            on: {
                              "page:update": function ($event) {
                                formData.column_mapping = []
                                _vm.$emit("page:update", $event)
                              },
                            },
                          },
                          "FileProcessing",
                          formData,
                          false,
                          true
                        )
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "mb-2",
                          attrs: { align: "middle", justify: "space-between" },
                        },
                        [
                          _c("span", { staticClass: "color-label" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("priceFile.firstTenRow")) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { disabled: readonly, size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("error:format")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("buttons.wrongTableData")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mapping" }, [
                        _c(
                          "div",
                          { staticClass: "table-container" },
                          [
                            _c(
                              "el-form-item",
                              {
                                ref: "mapping",
                                attrs: {
                                  "label-width": "0",
                                  prop: "column_mapping",
                                },
                              },
                              [
                                !isLoading
                                  ? _c("ColumnMapping", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        dependency: _vm.dependency,
                                        readonly: readonly,
                                      },
                                      on: { input: _vm.clearMapValidation },
                                      model: {
                                        value: formData.column_mapping,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            formData,
                                            "column_mapping",
                                            $$v
                                          )
                                        },
                                        expression: "formData.column_mapping",
                                      },
                                    })
                                  : _c("div", { staticClass: "pg-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "priceMapping.isMappingProcessing"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("PricePreviewTable", {
                              attrs: {
                                "data-preview":
                                  _vm.dependency.price.data_preview,
                                "data-start-row": formData.data_start_row,
                                "header-row": formData.header_row,
                                mapping: formData.column_mapping,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
              ]
            },
          },
        ]),
      },
      Object.assign({}, _vm.$listeners, { validate: _vm.scrollToStart })
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }