var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prices-list-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("routes.profile_business_prices")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb-2", attrs: { justify: "end" } },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: !_vm.isAdmin, size: "mini", type: "primary" },
              on: { click: _vm.goToUpload },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("buttons.createPrice")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : _c("PricesTable", {
            attrs: {
              "immediate-load-false": "",
              load: _vm.load,
              "prop-loading": _vm.isLoading,
              "prop-value": _vm.tableData,
              readonly: !_vm.isAdmin,
            },
            on: {
              "reupload:file": function ($event) {
                _vm.isUploadDialog = $event
              },
              rowClick: _vm.goToPrice,
              "row-click-middle": _vm.goToPriceTab,
            },
          }),
      _vm._v(" "),
      _vm.isUploadDialog
        ? _c("PriceReUploadDialog", {
            attrs: { price: _vm.isUploadDialog },
            on: {
              cancel: function ($event) {
                _vm.isUploadDialog = false
              },
              "price:update": function ($event) {
                return _vm.updatePrice(_vm.isUploadDialog, $event)
              },
              "status:update": function ($event) {
                return _vm.updatePriceStatus(_vm.isUploadDialog, $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }