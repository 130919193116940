var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "warehouse-form",
      attrs: { model: _vm.formData, rules: _vm.rules },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("warehouses.name"), prop: "name" } },
        [
          _c("el-input", {
            ref: "name",
            model: {
              value: _vm.formData.name,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt-2" },
        [
          _c(
            "el-row",
            { attrs: { justify: "end" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    "native-type": "submit",
                    type: "primary",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("buttons.create")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }