var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-input", {
    staticClass: "phone-input",
    attrs: {
      type: "tel",
      clearable: _vm.clearable,
      value: _vm.phoneNumber,
      placeholder: _vm.placeholder,
    },
    on: { input: _vm.setNum },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }