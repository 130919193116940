<template>
  <el-dialog
    class="price-re-upload-dialog"
    :title="$t('priceFile.priceReUpload')"
    visible
    width="800px"
    @close="close"
  >
    <div class="point-options">
      <p class="description mb-1">
        {{ $t('prices.priceLoadOption1Description2') }}
      </p>
      <PriceUploader class="mb-3" @input="setFile" />
      <PriceFormatForm
        ref="priceFormat"
        is-child
        :price="price"
        :value="formData"
        @input="update"
      />
    </div>

    <el-row class="mt-2" justify="end">
      <el-button :disabled="isLoading" @click="$emit('cancel')">
        {{ $t('buttons.cancel') }}
      </el-button>
      <el-button
        :disabled="!file"
        :loading="isLoading"
        native-type="submit"
        type="primary"
        @click="submit"
      >
        {{ $t('buttons.save') }}
      </el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import PriceUploader from './PriceUploader';
import PriceFormatForm from './PriceFormatForm';
import { CUT_LAST_DOTH_REG, getDefault } from '@/lib/core';

export default {
  name: 'PriceReUploadDialog',

  components: {
    PriceUploader,
    PriceFormatForm
  },

  props: {
    price: { type: Object, required: true }
  },

  data () {
    return {
      isLoading: false,
      file: null,
      formData: getDefault('priceFileEncoding')
    };
  },

  computed: {
    businessId () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'].id;
    }
  },

  methods: {
    setFile (file) {
      if (file) {
        this.file = file.raw;
        this.formData.filename = file.name.split(CUT_LAST_DOTH_REG)[0];
      } else {
        this.file = null;
        this.formData.filename = '';
      }
    },

    update (formData) {
      Object.assign(this.formData, formData);
    },

    submit () {
      this.$refs.priceFormat.validate(async (isValid) => {
        if (isValid && this.file) {
          this.isLoading = true;

          const { formData, file, price } = this;

          this.$emit('status:update', 'in_progress');

          try {
            const _price = await this.$store
              .dispatch('priceUploads/getPrice', { id: price.id });

            const newPrice = await this.$store.dispatch('priceUploads/reUploadPrice', {
              formData,
              upload_url: _price.upload_url,
              upload_url_post_data: _price.upload_url_post_data,
              file,
              businessId: this.businessId,
              priceUploadId: _price.id
            });

            newPrice.data_preview = null;

            // debounce for fix upload small files on server
            await new Promise((resolve) => {
              setTimeout(() => resolve(true), 1000);
            });

            this.$emit('price:update', newPrice);
          } catch (e) {
            console.error(e);

            await this.$store.dispatch('priceUploads/patchPrice', {
              id: price.id,
              formData: { status: 'error' }
            });

            await this.$store.dispatch('setProgress');

            this.$emit('status:update', 'error');
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    close () {
      if (!this.isLoading) {
        this.$emit('cancel');
      }
    }
  }
};
</script>
