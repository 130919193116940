var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCustomMeta
    ? _c(
        "el-breadcrumb",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.breadcrumbs.length,
              expression: "breadcrumbs.length",
            },
          ],
          ref: "breadcrumb",
          staticClass: "breadcrumb scroll",
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        _vm._l(_vm.breadcrumbs, function (crumb, index) {
          return _c(
            "el-breadcrumb-item",
            {
              key: "breadcrumb_" + (index + 1),
              attrs: {
                id: "breadcrumb_" + (index + 1),
                title: crumb.label || _vm.$t("routes." + crumb.name),
              },
            },
            [
              index + 1 !== _vm.breadcrumbs.length
                ? _c("nuxt-link", { attrs: { to: crumb.path } }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(crumb.label || _vm.$t("routes." + crumb.name)) +
                        "\n    "
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      "\n      " +
                        _vm._s(crumb.label || _vm.$t("routes." + crumb.name)) +
                        "\n    "
                    ),
                  ]),
            ],
            1
          )
        }),
        1
      )
    : _vm.canShow
    ? _c(
        "el-breadcrumb",
        {
          ref: "breadcrumb",
          staticClass: "breadcrumb",
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        _vm._l(_vm.listCrumbs, function (crumb) {
          return _c(
            "el-breadcrumb-item",
            { key: crumb.name || crumb.label || crumb.fullName },
            [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.navigate(crumb)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        crumb.fullName
                          ? crumb.label
                          : _vm.$t("routes." + crumb.label)
                      ) +
                      "\n    "
                  ),
                ]
              ),
            ]
          )
        }),
        1
      )
    : _c("div", { staticClass: "pg-1" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }