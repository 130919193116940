<template>
  <div class="catalog-item">
    <div class="top-line">
      <nuxt-link :to="getLineLink(value)">
        <BlockSize
          :size="maxmq('xs') ? 'lg' : 'xxl'"
          :src="value.image_url"
          :alt="catalogItemTitle"
        />

        <div :class="['shop-category__title', { 'text-center': titleCenter }]">
          {{ catalogItemTitle }}
        </div>
      </nuxt-link>
    </div>

    <div class="middle-lines">
      <div
        v-if="!isEmpty(value.children)"
        ref="wrapper"
        class="middle-lines-wrapper"
      >
        <ul :class="[
          'middle-lines-list',
          { 'is-open': visibleChildren[value.id] }
        ]"
        >
          <li v-for="middleLine in value.children" :key="middleLine.id">
            <el-row
              align="middle"
              class="middle-line"
            >
              <nuxt-link
                class="custom-link custom-link--inverted color-black"
                :to="getLineLink(middleLine)"
              >
                {{ middleLine.name }}
              </nuxt-link>

              <div v-if="!isEmpty(middleLine.children)" class="ml-1">
                <a
                  :class="[
                    'shop-category__open',
                    { 'is-open': visibleChildren[middleLine.id] }
                  ]"
                  @click="toggleShowChildren(middleLine)"
                >
                  <i :class="getArrowIcon(middleLine.id)" />
                </a>
              </div>
            </el-row>

            <ul v-show="visibleChildren[middleLine.id]" class="ml-2">
              <li
                v-for="lowerLine in middleLine.children"
                :key="`${middleLine.id}-${lowerLine.id}`"
                class="line"
              >
                <nuxt-link
                  class="custom-link custom-link--inverted color-black"
                  :to="getLineLink(lowerLine)"
                >
                  {{ lowerLine.name }}
                </nuxt-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <a
      v-if="canShowMore"
      :class="[
        'show-all-btn shop-category__open',
        { 'is-open': visibleChildren[value.id] }
      ]"
      @click="toggleShowChildren(value)"
    >
      <i :class="getArrowIcon(value.id)" />

      <span class="shop-category-toggle">
        {{ getBtnText(value.id) }}
      </span>
    </a>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import BlockSize from '@/lib/core/components/BlockSize';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';

export default {
  name: 'CatalogItem',

  // TODO revert cache
  // serverCacheKey: props => `${props.value.id}_${props.value.name}_${props.value.slug}`,

  components: {
    BlockSize
  },

  props: {
    titleCenter: Boolean,
    value: { type: Object, required: true },
    limit: { type: Number, default: 5 },
    dependency: { type: Object, default: () => ({}) }
  },

  data: () => ({
    visibleChildren: {}
  }),

  computed: {
    canShowMore () {
      return (this.value.children || []).length > this.limit;
    },

    catalogItemTitle () {
      const { isCarSelected, carTitle } = this.dependency;
      const suffix = !isCarSelected && carTitle
        ? `${this.$t('catalog.on')} ${carTitle}`
        : '';

      return `${this.value.name} ${suffix}`;
    }
  },

  methods: {
    isEmpty,

    getLineLink ({ slug }) {
      return getSeoLinkBy({ lineId: slug }, this);
    },

    toggleShowChildren ({ id }) {
      this.$set(this.visibleChildren, id, !this.visibleChildren[id]);
    },

    getArrowIcon (id) {
      return `el-icon-arrow-${this.visibleChildren[id] ? 'up' : 'down'}`;
    },

    getBtnText (id) {
      const text = this.visibleChildren[id] ? 'lessCategory' : 'moreCategory';
      return this.$t(`buttons.${text}`);
    }
  }
};
</script>

<style scoped lang="scss">
.catalog-item {
  width: 300px;
  padding: 20px;
  height: fit-content;
  min-height: 168px;

  .shop-category__title {
    margin-top: 0;
    margin-bottom: 8px;
    color: $blue-900;
    font-size: 1.1rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .custom-link {
    line-height: 1.4rem;
  }

  .show-all-btn {
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  }

  .el-icon-arrow-down, .el-icon-arrow-up {
    cursor: pointer;
    color: $grey-700;
  }

  .top-line {
    margin-bottom: 4px;

    .block-size {
      margin: 0 auto 20px;
    }
  }

  .middle-lines-wrapper {
    padding: 0 0 0 14px;

    .middle-lines-list > li:nth-of-type(1n+6) {
      display: none;
    }

    .middle-lines-list.is-open > li {
      display: block;
    }

    .middle-line {
      .line {
        font-size: .9rem;
        line-height: 1.6rem;
      }
    }
  }

  .shop-category-toggle {
    margin-left: 16px;
    color: $grey-500 !important;
    font-size: 13px;
  }
}

@media all and (max-width: $--xs) {
  .catalog-item {
    width: 167px;
    min-height: 224px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #EBF0F8;
    border-radius: 2px;

    .middle-lines, .show-all-btn  {
      display: none !important;
    }

    .shop-category__title {
      font-size: 14px;
    }

    .top-line {
      justify-content: center;
      text-align: center;
    }
  }
}
</style>
