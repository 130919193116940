export default (context) => {
  return {
    httpEndpoint: context.env.GRAPHQL_API_URL,
    credentials: 'include',
    httpLinkOptions: {
      credentials: 'include'
    },
    getAuth: () => {
      const token = context.store.getters['auth/token'];
      return token ? `JWT ${token}` : '';
    }
  };
};
