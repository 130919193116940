var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "business-name-photo", attrs: { justify: "space-between" } },
    [
      _c(
        "div",
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("labels.companyName"), prop: "name" } },
            [
              _c("el-input", {
                staticClass: "max-w-210p",
                attrs: { disabled: _vm.readonly, value: _vm.value.name },
                on: {
                  input: function ($event) {
                    return _vm.$emit("update:name", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "unset",
                label: _vm.$t("labels.isSeller"),
                prop: "is_seller",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.readonly, value: _vm.value.is_seller },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("update:is_seller", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v(_vm._s(_vm.$t("core.yes"))),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v(_vm._s(_vm.$t("core.no"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.value.is_seller
                ? _c("div", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("profile._agreeWithPublicOffer")
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.mode !== "create"
        ? _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  staticClass: "relative",
                  attrs: { "label-width": "0", prop: "avatar" },
                },
                [
                  _c("AvatarUploader", {
                    attrs: {
                      circle: "",
                      readonly: _vm.readonly,
                      value: _vm.value.logo,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("update:logo", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }