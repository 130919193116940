<script>
import MainInfoBlock from './formBlocks/MainInfoBlock';
import SiteBlock from './formBlocks/SiteBlock';
import FormTab from '@/lib/core/components/FormTab';

export default {
  name: 'BusinessMainInfoTab',

  extends: FormTab,

  components: {
    MainInfoBlock,
    SiteBlock
  },

  data: () => ({
    blocks: ['mainInfo', 'site']
  })
};
</script>
