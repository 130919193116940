<template>
  <div class="basket-page">
    <el-row justify="space-between" align="bottom">
      <el-row>
        <h2 class="custom-headline custom-headline--level2-bold mr-2 pg-0">
          {{ $t("routes.basket") }}
        </h2>

        <CollapseToggle
          v-if="isMobile"
          v-model="activeBlocks"
          class="pg-0"
          :blocks="blocks"
        />
      </el-row>

      <div v-if="!isMobile && items.length" class="mr-4">
        <el-row align="middle" class="sum-block">
          <div class="mr-1">
            <el-button
              icon="el-icon-delete"
              size="mini"
              @click="confirmClearBasket"
            >
              {{ $t("buttons.clearBasket") }}
            </el-button>
          </div>

          <ThePrice :value="getTotalAmount(items)" size="large">
            {{ $t("basket.count") }}:
          </ThePrice>

          <div class="ml-2">
            <CollapseToggle v-model="activeBlocks" :blocks="blocks" />
          </div>
        </el-row>
      </div>
    </el-row>

    <TheLoader v-if="isLoading" />

    <div v-else class="the-basket mt-4">
      <div
        v-if="!isEmptyBasket && !maxmq('md')"
        class="description pg-0-1 mb-2"
      >
        <ul>
          <li>* {{ $t("basket.tableDescription.1") }}</li>
          <li>** {{ $t("basket.tableDescription.2") }}</li>
          <li>*** {{ $t("basket.tableDescription.3") }}</li>
        </ul>
      </div>

      <div v-if="isEmptyBasket" class="empty-basket">
        <div class="mb-4">
          <span class="custom-headline custom-headline--level2-bold">
            {{ $t("basket.basketIsEmpty") }}
          </span>
        </div>

        <el-link type="primary" @click="closeBasket(getRouteName('home'))">
          {{ $t("buttons.goBackHome") }}
        </el-link>
      </div>

      <el-collapse v-else v-model="activeBlocks">
        <el-collapse-item
          v-for="(value, id) in ordersByPoints"
          :key="id"
          :name="id"
        >
          <template slot="title">
            <div class="collapse-green-prefix" />

            <el-row
              class="collapse-header"
              :align="isMobile ? 'bottom' : 'middle'"
              justify="space-between"
            >
              <div class="collapse__title">
                <el-row align="bottom" class="mr-1">
                  <el-link
                    class="point-link mr-1"
                    @click.stop="toSalePoint(value)"
                  >
                    {{ value.name }}
                  </el-link>

                  <span class="text-ghost"> ({{ value.city }}) </span>
                </el-row>

                <TheRate :value="value.rank" />
              </div>

              <div v-if="!isMobile">
                <ThePrice :value="getTotalAmount(value.items)">
                  {{ $t("basket.count") }}:
                </ThePrice>
              </div>
            </el-row>
          </template>

          <div class="pg-0">
            <component
              :is="`BuyerOrderItemsTable${isMobile ? 'M' : ''}`"
              :key="id"
              class="mb-2"
              with-description
              :currency-name="MARKETPLACE_CURRENCY"
              :value="value.items"
              @item:remove="removeItem"
              @item:update="updateItem"
            />

            <el-row v-if="isMobile" justify="space-around">
              <el-button
                plain
                class="min-w-120 m-0"
                type="primary"
                :size="isMobile ? 'mini' : ''"
                @click="closeBasket"
              >
                {{ $t("buttons.continueShopping") }}
              </el-button>

              <el-button
                class="min-w-120 m-0"
                type="primary"
                :disabled="!isCanBuy(value.items)"
                :size="isMobile ? 'mini' : ''"
                @click="checkUserAndGoToOrder(value.items)"
              >
                {{ $t("buttons.buy") }}
              </el-button>
            </el-row>

            <el-row v-else justify="end">
              <el-button
                plain
                type="primary"
                :size="isMobile ? 'mini' : ''"
                @click="closeBasket"
              >
                {{ $t("buttons.continueShopping") }}
              </el-button>

              <el-button
                type="primary"
                :disabled="!isCanBuy(value.items)"
                :size="isMobile ? 'mini' : ''"
                @click="checkUserAndGoToOrder(value.items)"
              >
                {{ $t("buttons.buy") }}
              </el-button>
            </el-row>

            <OneClickOrder
              v-if="value.isEnableOneClickOrder"
              class="mt-2"
              :basket="basket"
              :items="value.items"
            />
          </div>
        </el-collapse-item>
      </el-collapse>

      <template v-if="isMobile">
        <el-row class="mt-2 mb-2" justify="space-between" align="bottom">
          <div>{{ $t("basket.sumAllOrders") }}:</div>
          <ThePrice :value="getTotalAmount(items)" size="large" />
        </el-row>

        <div>
          <el-button
            v-if="items.length"
            plain
            icon="el-icon-delete"
            size="mini"
            @click="confirmClearBasket"
          >
            {{ $t("buttons.clearBasket") }}
          </el-button>
        </div>

        <div v-if="!isEmptyBasket" class="description pg-1 mt-1 mb-1">
          <ul>
            <li>* {{ $t("basket.tableDescription.1") }}</li>
            <li>** {{ $t("basket.tableDescription.2") }}</li>
            <li>*** {{ $t("basket.tableDescription.3") }}</li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TheBasket from './components/TheBasket';
import { env } from '@/lib/core';

export default {
  name: 'BasketPage',

  extends: TheBasket,

  data: () => ({ isVisible: true }),

  mounted () {
    this.load();
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>
