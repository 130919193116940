import noop from 'lodash/noop';
import env from '../services/envConstants';

const _second = 1000;

class AsyncDebounce {
  constructor ({ apply, cancel, debounce, id } = {}) {
    this.id = id;
    this.isActive = false;
    this.time = 0;
    this.apply = noop;
    this.cancel = noop;
    this._time = 0;
    this._interval = null;

    this.setOptions({
      apply: apply || noop,
      cancel: cancel || noop,
      debounce: debounce || env.BEFORE_ORDER_CREATE_DEBOUNCE
    });
  }

  _clearDebounce () {
    this.isActive = false;
    this.time = 0;

    this._time = 0;
    this._interval && clearInterval(this._interval);
    this._interval = null;

    this.apply = noop;
    this.cancel = noop;
  }

  setOptions ({ apply, cancel, debounce } = {}) {
    if (apply) {
      this._apply = apply || noop;
    }

    if (cancel) {
      this._cancel = cancel || noop;
    }

    if (debounce) {
      this._debounce = debounce;
    }
  }

  stop () {
    this._clearDebounce();
  }

  start (options = {}) {
    this.setOptions(options);

    return new Promise((resolve, reject) => {
      this.isActive = true;

      this._time = this._debounce + _second;

      this.cancel = () => {
        this._cancel();
        this._clearDebounce();
        reject(new Error('AsyncDebounce: cancel'));
      };

      this.apply = () => {
        this._apply();
        this._clearDebounce();
        resolve(true);
      };

      this._update();

      this._interval = setInterval(() => this._update(), _second);
    });
  }

  _update () {
    this._time -= _second;

    if (!this._time) {
      return this.apply();
    }

    this.time = this._time / _second;
  }
}

export function useAsyncDebounce (options) {
  return new AsyncDebounce(options);
}
