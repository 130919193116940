var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "catalog-item" }, [
    _c(
      "div",
      { staticClass: "top-line" },
      [
        _c(
          "nuxt-link",
          { attrs: { to: _vm.getLineLink(_vm.value) } },
          [
            _c("BlockSize", {
              attrs: {
                size: _vm.maxmq("xs") ? "lg" : "xxl",
                src: _vm.value.image_url,
                alt: _vm.catalogItemTitle,
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "shop-category__title",
                  { "text-center": _vm.titleCenter },
                ],
              },
              [_vm._v("\n        " + _vm._s(_vm.catalogItemTitle) + "\n      ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "middle-lines" }, [
      !_vm.isEmpty(_vm.value.children)
        ? _c("div", { ref: "wrapper", staticClass: "middle-lines-wrapper" }, [
            _c(
              "ul",
              {
                class: [
                  "middle-lines-list",
                  { "is-open": _vm.visibleChildren[_vm.value.id] },
                ],
              },
              _vm._l(_vm.value.children, function (middleLine) {
                return _c(
                  "li",
                  { key: middleLine.id },
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "middle-line",
                        attrs: { align: "middle" },
                      },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass:
                              "custom-link custom-link--inverted color-black",
                            attrs: { to: _vm.getLineLink(middleLine) },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(middleLine.name) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.isEmpty(middleLine.children)
                          ? _c("div", { staticClass: "ml-1" }, [
                              _c(
                                "a",
                                {
                                  class: [
                                    "shop-category__open",
                                    {
                                      "is-open":
                                        _vm.visibleChildren[middleLine.id],
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleShowChildren(middleLine)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    class: _vm.getArrowIcon(middleLine.id),
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.visibleChildren[middleLine.id],
                            expression: "visibleChildren[middleLine.id]",
                          },
                        ],
                        staticClass: "ml-2",
                      },
                      _vm._l(middleLine.children, function (lowerLine) {
                        return _c(
                          "li",
                          {
                            key: middleLine.id + "-" + lowerLine.id,
                            staticClass: "line",
                          },
                          [
                            _c(
                              "nuxt-link",
                              {
                                staticClass:
                                  "custom-link custom-link--inverted color-black",
                                attrs: { to: _vm.getLineLink(lowerLine) },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(lowerLine.name) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.canShowMore
      ? _c(
          "a",
          {
            class: [
              "show-all-btn shop-category__open",
              { "is-open": _vm.visibleChildren[_vm.value.id] },
            ],
            on: {
              click: function ($event) {
                return _vm.toggleShowChildren(_vm.value)
              },
            },
          },
          [
            _c("i", { class: _vm.getArrowIcon(_vm.value.id) }),
            _vm._v(" "),
            _c("span", { staticClass: "shop-category-toggle" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.getBtnText(_vm.value.id)) + "\n    "
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }