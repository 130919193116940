<template>
  <FormBlock
    class="account-info-block"
    :fields="fields"
    label-width="150px"
    :submit="submit"
    :value="value"
    v-on="$listeners"
  >
    <template #default="{ formData }">
      <div class="description mb-1">
        <p>{{ $t('account.accountInfoDescription') }}</p>
      </div>

      <el-form-item
        v-for="field in fields"
        :key="field"
        :label="$t(`labels.${field}`)"
      >
        <el-input v-model="formData[field]" />
      </el-form-item>
    </template>
  </FormBlock>
</template>

<script>
import FormBlock from '@/lib/core/components/FormBlock';

export default {
  name: 'AccountInfoBlock',

  components: { FormBlock },

  props: {
    submit: Function,
    value: { type: Object, required: true }
  },

  data: () => ({
    fields: ['last_name', 'first_name', 'middle_name']
  })
};
</script>

<style scoped lang="scss">
.account-info-block {
  .el-form-item {
    max-width: 500px;
  }
}
</style>
