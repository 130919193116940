<template>
  <div class="s-request-created-page">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <el-row justify="space-between" align="middle" class="mb-1">
        <h1 class="custom-headline custom-headline--level2-bold">
          {{ $t('sRequests.requestSubmit', { id: value.publicId }) }}
        </h1>

        <div v-if="!isMobile && isAuth">
          <el-button
            size="mini"
            @click="$router.push({ name: getRouteName('garage.requests') })"
          >
            {{ $t('buttons.toSelectionRequestsList') }}
          </el-button>
        </div>
      </el-row>

      <div class="pg-0-1 mb-2">
        {{ $t('sRequests.requestSubmittedDesc') }} <br>

        <span v-if="requestHashLink">
          {{ $t('sRequests.showRequestLinkDesc') }}
          <el-link type="primary" :href="requestHashLink">
            {{ requestHashLink }}
          </el-link>
        </span>
      </div>

      <div class="container">
        <SRequestItemsView
          class="mb-2"
          :car="value.car"
          :items="value.items"
          :commend="value.comment"
        />

        <div
          v-if="isMobile && isAuth"
          class="nav text-center"
        >
          <el-button
            size="mini"
            @click="$router.push({ name: getRouteName('garage.requests') })"
          >
            {{ $t('buttons.toSelectionRequestsList') }}
          </el-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SRequestItemsView from './components/SRequestItemsView';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';
import { filters, env } from '@/lib/core';
import getSeoBreadcrumbs from '@/lib/catalog/services/getSeoBreadcrumbs';

export default {
  name: 'SRequestCreatedPage',

  components: {
    SRequestItemsView
  },

  props: {
    propCar: Object,
    propVinRequest: Object
  },

  asyncData ({ app, store, route }) {
    const vm = { $store: store, $router: app.router };
    const rowBreadcrumbs = [
      {
        name: route.name,
        label: app.i18n.t('routes.garage_requests'),
        path: route.fullPath
      }
    ];

    const { breadcrumbs } = getSeoBreadcrumbs({ vm, rowBreadcrumbs });

    store.dispatch('setBreadcrumbs', breadcrumbs);
  },

  data () {
    return {
      isLoading: true,
      value: null
    };
  },

  computed: {
    isAuth () {
      return this.$store.getters['auth/isAuthenticated'];
    },

    isMobile () {
      return this.maxmq('xs');
    },

    requestHashLink () {
      if (this.value.hashId) {
        const href = this.$router.resolve({
          name: this.getRouteName('vin-requests-hash'),
          query: { hashId: this.value.hashId }
        }).href;

        return `${env.BASE_CLIENT_PATH}${href}`;
      }

      return '';
    }
  },

  mounted () {
    if (this.propVinRequest) {
      this.value = this.propVinRequest;
      this.isLoading = false;
    } else if (this.$store.getters['auth/isAuthenticated']) {
      this.loadRequest();
    } else {
      this.$router.push(getSeoLinkBy({}, this, {}));
    }
  },

  methods: {
    getDate: filters.getDate,

    async loadRequest () {
      this.isLoading = true;

      try {
        const value = await this.$store.dispatch(
          'selectionRequests/getVinRequestQL',
          { id: Number(this.$route.params.requestId) }
        );

        if (!value) {
          this.$nuxt.error({
            statusCode: 404,
            message: this.$t('labels.not_found')
          });
        }

        this.value = value;
      } catch (e) {
        console.error(e);
        this.$nuxt.error({
          statusCode: 404,
          message: this.$t('labels.not_found')
        });
      } finally {
        this.isLoading = false;
      }
    }
  },

  validate ({ params }) {
    return /^\d+$/.test(params.requestId);
  }
};
</script>

<style scoped lang="scss">
.s-request-created-page {
  .container {
    max-width: 980px;
    margin: 0 auto;
  }

  .grey-block {
    padding: 20px;
    background-color: $grey-200;
    border-radius: 4px;

    &.comment {
      padding: 10px;
      background-color: $blue-50;
    }
  }
}
</style>
