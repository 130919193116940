<template>
  <div v-show="visible" class="search-filter-m">
    <el-row justify="space-between" class="top pg-2">
      <div class="bold">{{ $t('search.filter') }}</div>
      <div>
        <i class="el-icon-close" @click="$emit('close')" />
      </div>
    </el-row>

    <slot>
      <SearchFilter
        is-manual
        :is-visible="visible"
        v-on="$listeners"
      />
    </slot>
  </div>
</template>

<script>
import { scrollToTop } from '../../core';

export default {
  name: 'SearchFilterM',

  components: {
    SearchFilter: () => import(/* webpackChunkName: "SearchFilter" */ './SearchFilter')
  },

  props: {
    visible: { type: Boolean, required: true }
  },

  watch: {
    visible () {
      scrollToTop(
        'top',
        { parentClassName: 'search-filter-m' }
      );
    }
  }
};
</script>

<style scoped lang="scss">
.search-filter-m {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: $white;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
}
</style>
