var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-created-page" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : [
            _c("img", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              staticClass: "dene-img mb-4",
              attrs: { src: require("@/assets/img/done.svg"), alt: "done" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "title mb-1" }, [
              _vm._v(_vm._s(_vm.$t("orders.thanksForOrder"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title title--description mb-4" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("orders.order")) +
                  " №" +
                  _vm._s(_vm.order.public_id) +
                  "\n      "
              ),
              _vm.maxmq("xs") ? _c("br") : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("core.on")) +
                  "\n      " +
                  _vm._s(
                    _vm.get(_vm.order, "point_of_sale_detail.name") ||
                      _vm.get(_vm.order, "seller_detail.name", "")
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c(
                  "nuxt-link",
                  { attrs: { to: { name: _vm.getRouteName("home") } } },
                  [
                    _c(
                      "el-button",
                      { staticClass: "w-200p", attrs: { type: "primary" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("buttons.goBackHome")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "w-100 " }, [
              _c("div", { staticClass: "check-progress mb-1" }, [
                _vm._v(_vm._s(_vm.$t("orders.checkProgress")) + ":"),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c(
                  "li",
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("orders.forPermanentLink")) +
                        ":\n          "
                    ),
                    _c(
                      "el-link",
                      { attrs: { type: "primary", href: _vm.orderLink } },
                      [_vm._v(_vm._s(_vm.orderLink))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.infoBotLink
                  ? _c(
                      "li",
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("orders.forTelegramBot")) +
                            ":\n          "
                        ),
                        _c(
                          "el-link",
                          { attrs: { type: "primary", href: _vm.infoBotLink } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.infoBotLink) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }