var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ware-car-applicability-selector" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideLabel,
              expression: "!hideLabel",
            },
          ],
          staticClass: "mb-1",
        },
        [
          _c(
            "span",
            { staticClass: "custom-headline custom-headline--level3-bold" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("ware.selectCarFormApplicability")) +
                  "\n    "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          attrs: { type: "card", value: _vm.activeTab },
          on: {
            "tab-click": function ($event) {
              _vm.activeTab = $event.name
            },
          },
        },
        _vm._l(_vm.menuTabs, function (name) {
          return _c(
            "el-tab-pane",
            {
              key: name,
              attrs: {
                label: _vm.getTitleBy(name) || _vm.$t("catalog." + name),
                name: name,
                disabled: _vm.disabled[name],
              },
            },
            [
              _c("CarDefaultList", {
                attrs: {
                  value: _vm.collection[name + "s"],
                  "is-active": _vm.isActiveItem,
                  readonly: _vm.activeTab === "modification",
                },
                on: { "set-item": _vm.setItem },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }