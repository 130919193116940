import { phone_number, phone_numbers, email, emails } from './common';
import { i18n } from '@/plugins/i18n';

export default {
  phone_number,
  phone_numbers,
  email,
  emails,
  profile_name: [
    { required: true, message: i18n.t('validation.simple.required'), trigger: 'blur' }
  ]
};
