import isEmpty from 'lodash/isEmpty';
import { env } from '@/lib/core';

const checkList = [
  'currencies',
  'deliveryMethods',
  'paymentMethods',
  'paymentTerms',
  'saleActivities',
  'otherSellingTerms',
  'deliveryServices',
  'communicationChannels'
];

const loadList = [
  'fetchCurrencies',
  'fetchDeliveryMethods',
  'fetchPaymentMethods',
  'fetchPaymentTerms',
  'fetchSaleActivities',
  'fetchOtherSellingTerms',
  'fetchDeliveryServices',
  'fetchCommunicationChannels'
];

const params = { is_active: true };

export const state = () => ({
  currencies: [],
  deliveryMethods: [],
  paymentMethods: [],
  paymentTerms: [],
  saleActivities: [],
  otherSellingTerms: [],
  deliveryServices: [],
  priceTags: [],
  communicationChannels: []
});

export const mutations = {
  setCurrencies: (state, payload) => { state.currencies = payload; },
  setDeliveryMethods: (state, payload) => { state.deliveryMethods = payload; },
  setPaymentMethods: (state, payload) => { state.paymentMethods = payload; },
  setPaymentTerms: (state, payload) => { state.paymentTerms = payload; },
  setSaleActivities: (state, payload) => { state.saleActivities = payload; },
  setOtherSellingTerms: (state, payload) => {
    state.otherSellingTerms = payload;
  },
  setDeliveryServices: (state, payload) => {
    state.deliveryServices = payload;
  },
  setPriceTags: (state, payload) => { state.priceTags = payload; },
  setCommunicationChannels: (state, payload) => {
    state.communicationChannels = payload;
  }
};

export const actions = {
  loadAll ({ dispatch }) {
    try {
      return Promise.all(loadList.map(name => dispatch(name)));
    } catch (e) {
      console.error(e);
    }
  },

  async fetchCurrencies ({ commit, getters }) {
    if (!isEmpty(getters.currencies)) {
      return getters.currencies;
    }

    try {
      const currencies = await this.$axios.$get('/currencies/');
      commit('setCurrencies', currencies);
      return getters.currencies;
    } catch (e) {
      console.error(e);
      return getters.currencies;
    }
  },

  async fetchDeliveryMethods ({ commit, getters }) {
    if (!isEmpty(getters.deliveryMethods)) {
      return getters.deliveryMethods;
    }

    try {
      const payload = await this.$axios
        .$get('/delivery-methods/', { params });

      commit('setDeliveryMethods', payload);

      return getters.deliveryMethods;
    } catch (e) {
      console.error(e);
      return getters.deliveryMethods;
    }
  },

  async fetchPaymentMethods ({ commit, getters }) {
    if (!isEmpty(getters.paymentMethods)) {
      return getters.paymentMethods;
    }

    try {
      const payload = await this.$axios
        .$get('/payment-methods/', { params });

      commit('setPaymentMethods', payload);

      return getters.paymentMethods;
    } catch (e) {
      console.error(e);
      return getters.paymentMethods;
    }
  },

  async fetchPaymentTerms ({ commit, getters }, filter = {}) {
    if (!isEmpty(getters.paymentTerms)) {
      return getters.paymentTerms;
    }

    try {
      const payload = await this.$axios
        .$get('/payment-terms/', { params });

      commit('setPaymentTerms', payload);

      return getters.paymentTerms;
    } catch (e) {
      console.error(e);
      return getters.paymentTerms;
    }
  },

  async fetchSaleActivities ({ commit, getters }) {
    if (!isEmpty(getters.saleActivities)) {
      return getters.saleActivities;
    }

    try {
      const payload = await this.$axios
        .$get('/sale-activities/', { params });

      commit('setSaleActivities', payload);

      return getters.saleActivities;
    } catch (e) {
      console.error(e);
      return getters.saleActivities;
    }
  },

  async fetchOtherSellingTerms ({ commit, getters }) {
    if (!isEmpty(getters.otherSellingTerms)) {
      return getters.otherSellingTerms;
    }

    try {
      const payload = await this.$axios
        .$get('/other-selling-terms/', { params });

      commit('setOtherSellingTerms', payload);

      return getters.otherSellingTerms;
    } catch (e) {
      console.error(e);
      return getters.otherSellingTerms;
    }
  },

  async fetchDeliveryServices ({ commit, getters }) {
    if (!isEmpty(getters.deliveryServices)) {
      return getters.deliveryServices;
    }

    try {
      const payload = await this.$axios
        .$get('/delivery-services/', {
          params: {
            ...params,
            marketplace: env.MARKETPLACE_ID
          }
        });

      commit('setDeliveryServices', payload);

      return getters.deliveryServices;
    } catch (e) {
      console.error(e);
      return getters.deliveryServices;
    }
  },

  async fetchPriceTags ({ commit, getters }) {
    if (!isEmpty(getters.priceTags)) {
      return getters.priceTags;
    }

    try {
      const payload = await this.$axios
        .$get('/price-tags/', { params: { system: true } });

      commit('setPriceTags', payload);

      return getters.priceTags;
    } catch (e) {
      console.error(e);
      return getters.priceTags;
    }
  },

  async fetchCommunicationChannels ({ commit, getters }) {
    if (!isEmpty(getters.communicationChannels)) {
      return getters.communicationChannels;
    }

    try {
      const payload = await this.$axios
        .$get('/communication-channels/', { params });

      commit('setCommunicationChannels', payload);

      return getters.communicationChannels;
    } catch (e) {
      console.error(e);
      return getters.communicationChannels;
    }
  }
};

export const getters = {
  loaded: state => !checkList.some(name => isEmpty(state[name])),
  currencies: state => state.currencies,
  deliveryMethods: state => state.deliveryMethods,
  paymentMethods: state => state.paymentMethods,
  paymentTerms: state => state.paymentTerms,
  saleActivities: state => state.saleActivities,
  otherSellingTerms: state => state.otherSellingTerms,
  deliveryServices: state => state.deliveryServices,
  priceTags: state => state.priceTags,
  communicationChannels: state => state.communicationChannels
};
