<template>
  <div class="sale-point-activator" @click.stop>
    <el-switch
      :active-text="label"
      :class="{ left }"
      :disabled="readonly"
      :title="title"
      :value="point.is_active"
      @change="change"
    />
  </div>
</template>

<script>
import ElSwitch from 'element-ui/lib/switch';

export default {
  name: 'SalePointActivator',

  components: {
    ElSwitch
  },

  props: {
    left: Boolean,
    showLabel: Boolean,
    readonly: Boolean,
    point: { type: Object, required: true }
  },

  computed: {
    label () {
      return this.showLabel ? this.title : '';
    },

    title () {
      return this.$t('salePoint.isActive');
    }
  },

  methods: {
    async change (is_active) {
      try {
        const formData = {
          is_active,
          business: this.point.business,
          name: this.point.name
        };

        const point = await this.$store
          .dispatch('salePoints/patchSalePoint', {
            id: this.point.id,
            formData
          });

        this.$emit('update', point.is_active);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.sale-point-activator {
  /deep/ .is-active {
    color: $--color-text-primary;
  }

  & .left {
    display: flex;
    flex-direction: row-reverse;

    /deep/ .el-switch__core {
      margin: 0 0 0 4px;
    }
  }
}
</style>
