<template>
  <div class="page-header-info">
    <TheLogo class="mr-1" />
    <!--    <MarketplacePhones-->
    <!--      row-->
    <!--      can-i-help-->
    <!--      service-name-->
    <!--      class="mt-05"-->
    <!--      :select="maxmq('sm')"-->
    <!--    />-->
  </div>
</template>

<script>
import TheLogo from './TheLogo';
// import MarketplacePhones from './MarketplacePhones';

export default {
  name: 'PageHeaderInfo',

  components: {
    TheLogo
    // MarketplacePhones
  }
};
</script>

<style scoped lang="scss">
.page-header-info {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  border-bottom: 1px solid $grey-300;

  @media all and (max-width: $--sm) {
    justify-content: space-between;
  }
}
</style>
