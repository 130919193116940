<template>
  <div @click.stop>
    <el-switch
      :disabled="disabled || value === null"
      :value="value"
      @change="changeStatus"
    />
  </div>
</template>

<script>
import ElSwitch from 'element-ui/lib/switch';

export default {
  name: 'PlatformActivator',

  components: {
    ElSwitch
  },

  props: {
    value: Boolean,
    id: Number
  },

  data () {
    return { disabled: false };
  },

  methods: {
    async changeStatus (status) {
      this.disabled = true;
      try {
        await this.$store.dispatch('platforms/changeStatus', { id: this.id, status });
        this.$emit('input', status);
      } catch (e) {
        this.$notify.error({
          title: this.$t('errors.default.title'),
          message: this.$t('errors.default.message')
        });
      } finally {
        this.disabled = false;
      }
    }
  }
};
</script>
