var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        staticClass: "main-info-block",
        attrs: {
          "completeness-fields": ["name", "currency"],
          "entity-name": "price",
          fields: _vm.fields,
          "label-position": "left",
          "label-width": "150px",
          submit: _vm.submit,
          value: _vm.value,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var formData = ref.formData
              var readonly = ref.readonly
              return [
                _c(
                  "MainInfo",
                  _vm._b(
                    {
                      attrs: {
                        dependency: _vm.dependency,
                        readonly: readonly,
                        value: formData,
                      },
                    },
                    "MainInfo",
                    formData,
                    false,
                    true
                  )
                ),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }