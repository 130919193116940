<template>
  <div class="form-tab">
    <el-row align="middle" class="mb-1" justify="space-between">
      <div>{{ tabTitle }}</div>
      <CollapseToggle v-model="activeBlocks" :blocks="blocks" />
    </el-row>

    <el-collapse v-model="activeBlocks">
      <el-collapse-item
        v-for="block in blocks"
        :key="block"
        :name="block"
      >
        <template slot="title">
          <CollapseIcon :is-valid="validBlocks[block]" />
          {{ $t(`${entityName}.${block}Block`) }}
        </template>

        <div class="pg-2">
          <component
            :is="`${upperFirst(block)}Block`"
            :dependency="dependency"
            :readonly="readonly"
            :submit="submit"
            :value="entity"
            v-on="$listeners"
            @validate="validBlocks[block] = $event"
          />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import noop from 'lodash/noop';
import CollapseIcon from './CollapseIcon';
import CollapseToggle from './CollapseToggle';

/**
 * This component use only like extends !!!
 */

export default {
  name: 'FormTab',

  components: {
    CollapseToggle,
    CollapseIcon
  },

  props: {
    submit: { type: Function, default: noop },
    dependency: null,
    tabTitle: String,
    readonly: Boolean,
    entity: { type: Object, default: () => ({}) },
    entityName: { type: String, required: true }
  },

  data () {
    return {
      upperFirst,
      blocks: [],
      activeBlocks: [],
      validBlocks: {}
    };
  },

  created () {
    this.activeBlocks = this.getActiveBlock();
    this.validBlocks = this.getValidBlocks();
  },

  methods: {
    getActiveBlock () {
      return this.blocks.slice(0);
    },

    getValidBlocks () {
      return this.blocks.reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    }
  }
};
</script>
