<template>
  <div class="contacts-view color-default">
    <div v-if="value.phone_numbers.length" class="phones mb-1">
      <div class="label mb-1">
        {{ $t('labels.phones') }}:
      </div>

      <template v-for="(phone, index) in value.phone_numbers">
        <el-row
          v-if="!collapse || isVisiblePhones || !index"
          :key="phone.id"
          class="row"
        >
          <el-row align="middle">
            <div class="fit-content mr-1">
              <el-link
                class="phone"
                :href="getHrefFor(null, phone.phone_number)"
              >
                {{ phone.phone_number }}
              </el-link>
            </div>

            <el-row class="fit-content mr-1">
              <template v-for="channel in phone.channels">
                <div v-if="channel !== 'phone'" :key="channel">
                  <TheTooltip :content="$t(`phoneChannels.${channel}`)">
                    <el-link
                      class="phone"
                      :href="getHrefFor(channel, phone.phone_number)"
                    >
                      <img class="icon-img" :src="getIcon(channel)">
                    </el-link>
                  </TheTooltip>
                </div>
              </template>
            </el-row>
          </el-row>

          <div class="description text-ellipsis">
            <TheTooltip :content="phone.description">
              <span>{{ phone.description }}</span>
            </TheTooltip>
          </div>
        </el-row>
      </template>

      <div v-if="collapse && !isVisiblePhones && value.phone_numbers.length > 1">
        <el-link type="primary" @click="isVisiblePhones = !isVisiblePhones">
          {{ $t('buttons.allPhones') }}...
        </el-link>
      </div>
    </div>

    <div v-if="value.emails.length" class="emails">
      <div class="label mb-1">
        {{ $t('labels.email') }}:
      </div>

      <template v-for="(email, index) in value.emails">
        <el-row
          v-if="!collapse || isVisibleEmails || !index"
          :key="email.id + email.email"
          class="row fl-wrap"
        >
          <div class="fit-content mr-2">
            <el-link :href="`mailto:${email.email}`">
              {{ email.email }}
            </el-link>
          </div>
          <div class="description text-ellipsis">
            <TheTooltip :content="email.description">
              <span>{{ email.description }}</span>
            </TheTooltip>
          </div>
        </el-row>
      </template>

      <div v-if="collapse && !isVisibleEmails && value.emails.length > 1">
        <el-link type="primary" @click="isVisibleEmails = !isVisibleEmails">
          {{ $t('buttons.allEmails') }}...
        </el-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactsView',

  props: {
    value: { type: Object, required: true },
    collapse: Boolean
  },

  data: () => ({
    isVisiblePhones: false,
    isVisibleEmails: false
  }),

  methods: {
    getIcon (channel) {
      return require(`@/assets/channelsIcons/${channel}.svg`);
    },

    getHrefFor (app, num) {
      const _num = num.replace('+', '%2B');

      if (app === 'viber') {
        return `viber://chat/?number=${_num}`;
      } else if (app === 'whatsapp') {
        return `https://wa.me/${_num}`;
      }

      return `tel:${_num}`;
    }
  }
};
</script>

<style scoped lang="scss">
.label {
  font-weight: bold;
  color: $--color-text-regular;
}

.contacts-view {
  width: 250px;
}

.row {
  margin-bottom: 4px;
}

.description {
  flex: 1;
  min-width: 40px;
}
</style>
