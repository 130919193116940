<template>
  <el-form-item
    class="order-payment block-title"
    prop="payment_method"
    :label="$t('basket.payment_method')"
  >
    <el-radio-group
      class="white-back"
      :disabled="readonly"
      :value="value.payment_method"
      @input="emit('payment_method', $event)"
    >
      <el-radio
        v-for="method in paymentMethods"
        :key="method"
        :label="method"
      >
        {{ $t(`payment_methods.${method}`) }}
      </el-radio>
    </el-radio-group>
  </el-form-item>
</template>

<script>
import orderFormMixin from '../../mixins/orderFormMixin';

export default {
  name: 'OrderPayment',

  mixins: [orderFormMixin],

  computed: {
    paymentMethods () {
      return this.dependency.payment_methods.length
        ? this.dependency.payment_methods
        : ['cash_payment'];
    }
  },

  mounted () {
    if (this.paymentMethods.length === 1) {
      this.emit('payment_method', this.paymentMethods[0]);
    }
  }
};
</script>
