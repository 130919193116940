<template>
  <el-row
    v-observe-visibility="toggleView"
    :class="['open-street-map', 'show-points-map']"
  >
    <div :id="mapId" class="map" @click="$emit('click')" />
  </el-row>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { env } from '@/lib/core';

const defaultZoom = 10;
const defaultLocation = { lat: 50.45113, lng: 30.52242 };
const defaultOptions = {
  maxZoom: 18,
  attribution: `
  &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>,
  &copy; <a href="https://carto.com/attribution">CARTO</a>
`,
  center: defaultLocation
};

export default {
  name: 'ShowPointsMap',

  props: {
    value: { type: Array, default: () => [] }
  },

  data: () => ({
    mapId: 'map',
    tileLayer: null,
    map: null,
    isVisible: false
  }),

  computed: {
    cityLocation () {
      const { latitude, longitude } = this.$store.getters.city || {};

      return {
        lat: latitude || defaultLocation.lat,
        lng: longitude || defaultLocation.lng
      };
    },

    center () {
      if (!this.value.length) {
        return this.cityLocation;
      } else if (this.value.length === 1) {
        return this.value[0];
      } else {
        return this.value[0] || {};
      }
    }
  },

  mounted () {
    this.initMap();
  },

  methods: {
    initMap () {
      const leaflet = require('leaflet');
      const container = leaflet.DomUtil.get(this.mapId);
      this.L = leaflet;

      if (container != null) {
        container._leaflet_id = null;
      }

      this.map = this.L.map(this.mapId);
      this.map.setView(defaultLocation, defaultZoom);
      this.tileLayer = this.L.tileLayer(env.OSM_LAYER_URL, defaultOptions);
      this.tileLayer.addTo(this.map);
    },

    drawPoints (points) {
      if (!this.layerGroup) {
        this.layerGroup = this.L.layerGroup().addTo(this.map);
      }

      this.layerGroup.clearLayers();

      if (!isEmpty(points)) {
        points.forEach(this.drawPoint);
      }
    },

    drawPoint (coords) {
      if (coords && coords.lat && coords.lng) {
        const marker = this.L.marker([coords.lat, coords.lng]);

        if (coords.tooltipLabel) {
          marker.bindTooltip(coords.tooltipLabel, {
            direction: 'top',
            sticky: true
          });
        }

        marker.addTo(this.layerGroup);
      }
    },

    toggleView (isVisible) {
      this.isVisible = isVisible;

      if (isVisible && this.map) {
        this.map.invalidateSize();

        const val = this.value.slice(0);

        this.drawPoints(val);

        const _points = val.map(({ lat, lng }) => [lat, lng]);
        const _bounds = new this.L.LatLngBounds(_points);

        this.map.fitBounds(_bounds, { padding: [100, 100] });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.open-street-map {
  height: 40vh;
  width: 100%;
  border: 1px solid $grey-200;

  .map {
    height: 100%;
    width: 100%;
  }
}
</style>
