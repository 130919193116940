var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ware-life-cycle-help" },
    [
      _c("img", {
        staticClass: "icon-img c-pointer",
        attrs: { src: require("@/assets/icons/help-circle-outline.svg") },
        on: {
          click: function ($event) {
            _vm.showInfo = !_vm.showInfo
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            center: "",
            "close-on-click-modal": "",
            title: _vm.$t("orderCycleHelp.title"),
            visible: _vm.showInfo,
            width: "80vw",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showInfo = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "mb-4", attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("i", { staticClass: "icon el-icon-shopping-cart-full" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v(_vm._s(_vm.$t("orderStatuses.null"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "arrow" }, [_vm._v("↓")]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: require("@/assets/icons/emoticon-sad-outline.svg"),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v(_vm._s(_vm.$t("orderStatuses.buyer_canceled"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", [_c("hr", { staticClass: "left" })]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "item" }, [
                    _c("i", { staticClass: "icon el-icon-document-copy" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v(_vm._s(_vm.$t("orderStatuses.new"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-row", [
                    _c("div", { staticClass: "arrow to-left" }, [_vm._v("↓")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "arrow to-right" }, [_vm._v("↓")]),
                  ]),
                  _vm._v(" "),
                  _c("el-row", [
                    _c("div", { staticClass: "item mr-2" }, [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/icons/emoticon-sad-outline.svg"),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("orderStatuses.buyer_canceled"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item" }, [
                      _c("i", { staticClass: "icon el-icon-circle-close" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("orderStatuses.seller_canceled"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", [_c("hr", { staticClass: "center" })]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "item" }, [
                    _c("i", { staticClass: "icon el-icon-box" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v(_vm._s(_vm.$t("orderStatuses.in_progress"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-row", [
                    _c("div", { staticClass: "arrow to-left" }, [_vm._v("↓")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "arrow to-right" }, [_vm._v("↓")]),
                  ]),
                  _vm._v(" "),
                  _c("el-row", [
                    _c("div", { staticClass: "item mr-2" }, [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/icons/emoticon-sad-outline.svg"),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("orderStatuses.buyer_canceled"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item" }, [
                      _c("i", { staticClass: "icon el-icon-circle-close" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("orderStatuses.seller_canceled"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", [_c("hr", { staticClass: "center" })]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "item" }, [
                    _c("i", { staticClass: "icon el-icon-goods" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v(_vm._s(_vm.$t("orderStatuses.ready"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-row", [
                    _c("div", { staticClass: "arrow to-left" }, [_vm._v("↓")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "arrow to-right" }, [_vm._v("↓")]),
                  ]),
                  _vm._v(" "),
                  _c("el-row", [
                    _c("div", { staticClass: "item mr-2" }, [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/icons/emoticon-sad-outline.svg"),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("orderStatuses.buyer_canceled"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item" }, [
                      _c("i", { staticClass: "icon el-icon-circle-close" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(_vm.$t("orderStatuses.seller_canceled"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", [_c("hr", { staticClass: "right" })]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 3 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("i", { staticClass: "icon el-icon-check" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v(_vm._s(_vm.$t("orderStatuses.issued"))),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "advices" }, [
            _c("span", { staticClass: "color-red" }, [
              _vm._v(_vm._s(_vm.$t("basket.important")) + "!"),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("orderCycleHelp.description")) + ":"),
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "list mt-1" }, [
              _c("li", {
                staticClass: "list-item",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("orderCycleHelp.forBuyer")),
                },
              }),
              _vm._v(" "),
              _c("li", {
                staticClass: "list-item",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("orderCycleHelp.forSeller")),
                },
              }),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }