<template>
  <el-dialog
    v-if="value"
    ref="dialog"
    append-to-body
    center
    visible
    :show-close="!hideClose"
    class="the-dialog"
    :top="top"
    :fullscreen="fullscreen || maxmq('xs')"
    :title="title"
    :width="width"
    @close="cancel"
  >
    <template #title>
      <slot name="title" />
    </template>
    <template #default>
      <slot name="default" v-bind="$attrs" />
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'TheDialog',

  props: {
    hideClose: Boolean,
    useHidden: Boolean,
    fullscreen: Boolean,
    top: String,
    title: String,
    value: null,
    width: { type: String, default: '800px' }
  },

  methods: {
    cancel () {
      if (this.$listeners.cancel) {
        this.$emit('cancel');
      } else {
        this.$emit('input', false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.hidden {
  width: 0;
  height: 0;
}

.the-dialog {
  @media all and (max-width: $--xs) {
    ::v-deep .el-dialog__body {
      padding: 10px!important;
    }
  }
}
</style>
