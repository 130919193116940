import { env } from '@/lib/core';

export const actions = {
  async getResponse (_, { id }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.GARAGE_API,
      url: `/selection-responses/${id}/`
    });

    return data;
  },

  async postResponses (_, { responses }) {
    const { data } = await this.$axios({
      method: 'post',
      baseURL: env.GARAGE_API,
      url: '/selection-responses/',
      data: responses
    });

    return data;
  },

  async patchResponses ({ dispatch }, { responses }) {
    const promises = responses.map(res => dispatch('patchResponse', {
      id: res.id,
      formData: res
    }));

    return await Promise.all(promises);
  },

  async patchResponse (_, { id, formData }) {
    const { data } = await this.$axios({
      method: 'patch',
      baseURL: env.GARAGE_API,
      url: `/selection-responses/${id}/`,
      data: formData
    });

    return data;
  },

  removeResponse (_, { id }) {
    return this.$axios({
      method: 'delete',
      baseURL: env.GARAGE_API,
      url: `/selection-responses/${id}/`
    });
  }
};
