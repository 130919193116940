var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        staticClass: "main-info-block",
        attrs: {
          "label-position": "left",
          "entity-name": "profile",
          fields: ["name", "is_seller", "logo"],
          value: _vm.value,
          submit: _vm.submit,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var formData = ref.formData
              var readonly = ref.readonly
              return [
                _c("div", { staticClass: "description mb-2" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("profile.addCompanyInfo")))]),
                ]),
                _vm._v(" "),
                _c(
                  "BusinessNamePhoto",
                  _vm._b(
                    { attrs: { readonly: readonly, value: formData } },
                    "BusinessNamePhoto",
                    formData,
                    false,
                    true
                  )
                ),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }