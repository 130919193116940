var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "price-assortment-tab" },
    [
      _c(
        "el-input",
        {
          staticClass: "mb-2",
          attrs: {
            autofocus: "",
            placeholder: _vm.$t("placeholders.catalogNumInPrice"),
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.search.apply(null, arguments)
            },
          },
          model: {
            value: _vm.query,
            callback: function ($$v) {
              _vm.query = $$v
            },
            expression: "query",
          },
        },
        [
          _c(
            "template",
            { slot: "append" },
            [
              _c("el-button", {
                attrs: { icon: "el-icon-search", type: "primary" },
                on: { click: _vm.search },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("WaresTable", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        ref: "table",
        attrs: { readonly: "", "row-key": "_id", load: _vm.load },
        on: { "info:show": _vm.showInfo },
      }),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          model: {
            value: _vm.wareInfoId,
            callback: function ($$v) {
              _vm.wareInfoId = $$v
            },
            expression: "wareInfoId",
          },
        },
        [
          _c("WareInfo", {
            attrs: { id: _vm.wareInfoId },
            on: {
              cancel: function ($event) {
                _vm.wareInfoId = null
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }