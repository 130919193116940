var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-mapping hide-error-border" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        _vm._l(_vm.formData, function (item, index) {
          return _c(
            "el-col",
            { key: item._id, staticClass: "cols" },
            [
              _c(
                "ColItems",
                _vm._g(
                  {
                    attrs: {
                      config: _vm.config,
                      dependency: _vm.mapDependency,
                      "form-data": _vm.formData,
                      "used-types": _vm.usedTypes,
                      value: _vm.formData[index],
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setAndEmit(index, $event)
                      },
                    },
                  },
                  _vm.listeners
                )
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: {
            width: "400px",
            title: _vm.$t("warehouses.creationWarehouses"),
          },
          model: {
            value: _vm.warehouseCreateData,
            callback: function ($$v) {
              _vm.warehouseCreateData = $$v
            },
            expression: "warehouseCreateData",
          },
        },
        [
          _c("WarehouseForm", {
            attrs: {
              dependency: _vm.dependency,
              params: _vm.warehouseCreateData,
            },
            on: {
              cancel: function ($event) {
                _vm.warehouseCreateData = null
              },
              created: function ($event) {
                return _vm.updateWarehouse({
                  params: _vm.warehouseCreateData,
                  warehouse: $event,
                })
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: {
            width: "850px",
            title: _vm.$t("prices.creationPriceScheme"),
          },
          model: {
            value: _vm.priceSchemeCreateData,
            callback: function ($$v) {
              _vm.priceSchemeCreateData = $$v
            },
            expression: "priceSchemeCreateData",
          },
        },
        [
          _c("PriceSchemeForm", {
            on: {
              cancel: function ($event) {
                _vm.priceSchemeCreateData = null
              },
              created: function ($event) {
                return _vm.updatePriceScheme("priceSchemeCreateData", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }