<template>
  <div class="login-registration-forms">
    <el-row class="form-block" :gutter="8">
      <LoginFormCard hide-close-icon show-social-links />
      <div class="splitter">
        <div class="line" />
        <div class="text">{{ $t('auth.or') }}</div>
        <div class="line" />
      </div>
      <RegistrationFormCard hide-close-icon />
    </el-row>
  </div>
</template>

<script>
import LoginFormCard from './LoginFormCard';
import RegistrationFormCard from './RegistrationFormCard';

export default {
  name: 'LoginRegistrationForms',

  components: {
    LoginFormCard,
    RegistrationFormCard
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/auth-forms.scss";
</style>
