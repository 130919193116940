export default {
  methods: {
    updateLocalItem (item, formData = {}) {
      if (!formData.multiple && formData.status && formData.status !== 'issued') {
        this.addStartRevertStatusTimer(item);
      }

      const index = this.items.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        this.$set(this.items, index, item);
      }
    },

    addStartRevertStatusTimer (item) {
      const { updateItem } = this;
      const apply = () => { item._canRevertStatus = false; };
      const cancel = () => updateItem({ id: item.id, revert_status: true });

      item._canRevertStatus = true;
      item._revertStatusTimer.start({ cancel, apply });
    }
  }
};
