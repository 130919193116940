<template>
  <div class="platforms-contract-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_platforms_contract') }}
    </h1>

    <div />
  </div>
</template>

<script>
export default {
  name: 'PlatformsContractPage',

  data () {
    return {

    };
  },

  methods: {}
};
</script>

<style scoped lang="scss">
.platforms-contract-page {
}
</style>
