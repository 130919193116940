var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-recipient" },
    [
      _c(
        "el-form-item",
        {
          staticClass: "mr-1 relative",
          attrs: {
            prop: "recipient_last_name",
            label: _vm.$t("basket.recipient_last_name"),
          },
        },
        [
          _c("el-input", {
            attrs: { clearable: "", value: _vm.value.recipient_last_name },
            on: { input: _vm.onInput, blur: _vm.clearResult },
          }),
          _vm._v(" "),
          _vm.searchResult.length
            ? _c(
                "div",
                { staticClass: "custom-autocomplete back-shadow scroll" },
                _vm._l(_vm.searchResult, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "item",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.setRecipient(item)
                        },
                      },
                    },
                    [_vm._v("\n        " + _vm._s(item._label) + "\n      ")]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "mr-1",
          attrs: {
            prop: "recipient_first_name",
            label: _vm.$t("basket.recipient_first_name"),
          },
        },
        [
          _c("el-input", {
            attrs: { value: _vm.value.recipient_first_name },
            on: {
              input: function ($event) {
                return _vm.setField("recipient_first_name", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "mr-1",
          attrs: {
            prop: "recipient_phone_number",
            label: _vm.$t("basket.recipient_phone_number"),
          },
        },
        [
          _c("PhoneInput", {
            attrs: { value: _vm.value.recipient_phone_number.phone_number },
            on: {
              input: function ($event) {
                return _vm.setField(
                  "recipient_phone_number.phone_number",
                  $event
                )
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }