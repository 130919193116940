<template>
  <div class="s-request-create-master-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('sRequests.createRequestByVin') }}
    </h1>

    <!--eslint-disable vue/no-v-html-->
    <div
      v-if="!step"
      class="default-style mb-2"
      v-html="$t('sRequests.createRequestByVinDesc')"
    />

    <StepsPanel
      class="center fit-content mb-2"
      entity="sRequest"
      :active="step"
    />

    <TheLoader v-if="isLoading" />

    <div v-else class="car-container">
      <el-form
        v-if="step === 0"
        ref="carForm"
        :model="carFormData"
        :rules="carRules"
        label-suffix=":"
        size="medium"
        @submit.native.prevent="checkAndSaveCar(carFormData)"
      >
        <GarageWidget
          v-if="$store.getters.isAppLoaded"
          ref="garageWidget"
          hide-options
          save-to-list-only
          class="mb-4"
          :prop-car="carFormData"
          @select="setCarData($event.data)"
        />

        <div
          v-if="carFormData.modification && !masterFormData.vin"
          class="mt-2 mb-4"
        >
          <el-form-item
            label-width="50px"
            :label="$t('garage.main.vin')"
            prop="vin"
          >
            <el-input v-model="carFormData.vin" clearable class="vin-select" />
          </el-form-item>

          <el-row justify="center" class="mb-1">
            <el-button @click="goBack">
              {{ $t('buttons.cancel') }}
            </el-button>
            <el-button
              native-type="submit"
              type="primary"
              :disabled="carFormData.vin === masterFormData.vin"
            >
              {{ $t('buttons.continue') }}
            </el-button>
          </el-row>
        </div>
      </el-form>

      <SRequestForm
        v-else-if="step === 1"
        :car="carFormData"
        hide-city
        :submit-btn-text="$t('buttons.continue')"
        :submit="saveVinRequestData"
        @cancel="countStep(step - 1)"
      />

      <div v-else-if="step === 2">
        <h2 class="custom-headline custom-headline--level2-bold">
          {{ $t('sRequests.userContacts') }}
        </h2>

        <p class="mb-1">{{ $t('sRequests.userContactsDesc') }}</p>

        <el-row :justify="isMobile ? 'center' : ''" class="mb-2 city">
          <span>{{ $t('sRequests.myCity') }}:</span>
          <el-link class="ml-05" type="primary" @click="showCitySelector">
            {{ city.name }}
          </el-link>
        </el-row>

        <el-form
          label-suffix=":"
          size="medium"
          :model="userFormData"
          label-position="left"
          @submit.native.prevent="checkUserDataAndSubmit(userFormData)"
        >
          <el-form-item prop="phoneNumber" :label="$t('labels.phone')">
            <div>
              <PhoneInput
                v-model="userFormData.phoneNumber"
                clearable
                class="max-w-182p"
              />
              <br>
              <span class="text-small-2">
                ({{ $t('sRequests.userContactsPhoneDesc') }})
              </span>
            </div>
          </el-form-item>

          <SRequestItemsView
            class="mb-2"
            :car="carFormData"
            :items="vinRequestData.itemsAdd"
          />

          <el-row justify="center">
            <el-button @click="$router.push({ name: getRouteName('home')})">
              {{ $t('buttons.cancel') }}
            </el-button>

            <el-button @click="countStep(step - 1)">
              {{ $t('buttons.back') }}
            </el-button>

            <el-button
              native-type="submit"
              type="primary"
              :disabled="!userFormData.phoneNumber"
              @click="checkUserDataAndSubmit(userFormData)"
            >
              {{ $t('buttons.emitRequest') }}
            </el-button>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { getClearFromEmpty, getPrimaryOrFirst } from '../core/services/parsers';
import SRequestForm from './components/SRequestForm';
import SRequestItemsView from './components/SRequestItemsView';
import getSeoBreadcrumbs from '@/lib/catalog/services/getSeoBreadcrumbs';
import { getDefault, getValidationFor, scrollToTop, env } from '@/lib/core';
import PhoneInput from '@/lib/core/components/PhoneInput';
import StepsPanel from '@/lib/core/components/StepsPanel';
import GarageWidget from '@/lib/garage/components/garageWidget/GarageWidget';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';

export default {
  name: 'SRequestCreateMasterPage',

  components: {
    StepsPanel,
    SRequestForm,
    PhoneInput,
    SRequestItemsView,
    GarageWidget
  },

  props: {
    propCar: Object
  },

  asyncData ({ app, store }) {
    const vm = { $store: store, $router: app.router };
    const rowBreadcrumbs = [
      {
        name: 'vin-requests-master_create',
        label: app.i18n.t('routes.garage_requests'),
        path: '/vin-requests-master/create'
      }
    ];

    const { breadcrumbs } = getSeoBreadcrumbs({ vm, rowBreadcrumbs });

    store.dispatch('setBreadcrumbs', breadcrumbs);
  },

  data ({ $route }) {
    return {
      widgetCar: null,

      step: 0,
      isLoading: true,
      modificationId: Number($route.query.cm) || '',
      carFormData: getDefault('car'),
      carRules: getValidationFor(null, 'car', this),
      userFormData: {
        phoneNumber: '',
        city: ''
      },
      vinRequestData: getDefault('selectionRequest'),
      masterFormData: getDefault('selectionRequestMaster')
    };
  },

  computed: {
    isMobile () {
      return this.maxmq('xs');
    },

    city () {
      return this.$store.getters.city || {};
    }
  },

  watch: {
    city: {
      immediate: true,
      handler () {
        this.userFormData.city = this.city?.id;
      }
    }
  },

  async mounted () {
    await this.loadCar();
    await this.setDefaultData();
    this.countStep();
    this.isLoading = false;
  },

  methods: {
    setDefaultData () {
      const pp = this.$store.getters['users/user'];

      if (pp) {
        this.userFormData.phoneNumber = pp.phone_number ||
          getPrimaryOrFirst(pp.profile.phone_numbers, 'phone')?.phone_number;
      }

      this.userFormData.city = this.city.id;
    },

    async loadCar () {
      if (!this.modificationId) {
        return null;
      }

      try {
        if (this.propCar) {
          return this.setCarData(cloneDeep(this.propCar));
        }

        const savedCars = this.$store.getters['carModifications/savedCars'];
        const car = savedCars.find(({ _modificationId, modification }) =>
          (
            _modificationId === this.modificationId ||
            modification === this.modificationId
          )
        );

        if (car) {
          const { isActive, ..._car } = car;
          return this.setCarData(cloneDeep(_car));
        }

        const modification = await this.$store.dispatch(
          'cache/get',
          {
            action: 'carModifications/getModificationQL',
            payload: { id: this.modificationId }
          }
        );

        if (modification) {
          const {
            id: modificationId,
            capacityL,
            productionStart,
            productionEnd,
            model,
            ..._modification
          } = modification;

          const _car = {
            ..._modification,
            model: {
              id: model.id,
              name: model.name
            },
            brand: model.brand,
            modification: modificationId,
            productionYear: Number(productionStart.split('-')[0]),
            engineCapacity: capacityL,
            _modificationId: modificationId
          };

          this.setCarData(_car);
        }
      } catch (e) {
        console.error(e);
      }
    },

    countStep (propStep) {
      if (typeof propStep === 'number') {
        this.step = propStep;

        if (this.step === 0) {
          this.masterFormData.vin = '';
        }
      } else if (!this.carFormData?.vin) {
        this.step = 0;
      } else if (
        !this.vinRequestData.itemsAdd.length ||
        this.vinRequestData.itemsAdd.some(({ rawItem }) => !rawItem)
      ) {
        this.step = 1;
      } else if (this.step === 1 || !this.userFormData.city) {
        this.step = 2;
        this.setDefaultPhoneNum();
      } else {
        this.step = 3;
        this.checkAndSubmitMaster();
      }
    },

    async setDefaultPhoneNum () {
      const num = await this.$store.dispatch('getCookie', env.LAST_PHONE_NUM_KEY);

      if (num) {
        this.userFormData.phoneNumber = num;
      }
    },

    checkAndSaveCar (carFormData) {
      if (this.$refs.carForm) {
        this.$refs.carForm.validate((isValid) => {
          if (!isValid) {
            scrollToTop('is-error');
            return false;
          }

          this.carFormData = carFormData;

          this.masterFormData.modification = carFormData.modification;
          this.masterFormData.vin = carFormData.vin;
          this.countStep();
        });
      } else {
        this.carFormData = carFormData;
        this.masterFormData.modification = carFormData.modification;
        this.masterFormData.vin = carFormData.vin;
        this.countStep();
      }
    },

    saveVinRequestData (formData) {
      this.vinRequestData = formData;
      this.countStep();
    },

    checkUserDataAndSubmit (formData) {
      this.userFormData.city = this.city.id;
      this.userFormData.phoneNumber = formData.phoneNumber;

      this.countStep();
    },

    async checkAndSubmitMaster () {
      this.isLoading = true;

      const formData = this.getClearMasterData();

      try {
        const requestMaster = await this.$store.dispatch(
          'selectionRequests/postVinRequestMasterQL',
          { formData }
        );

        const vinRequest = await this.$store.dispatch(
          'selectionRequests/processVinRequestMasterQL',
          { id: requestMaster.id }
        );

        this.checkAndSaveVinForCar(formData);
        this.savePhoneNumToStorage();

        return this.$router.push({
          name: this.getRouteName('vin-requests-master_created'),
          params: {
            requestId: vinRequest.id,
            propVinRequest: vinRequest
          }
        });
      } catch (e) {
        console.error(e);
        this.countStep(this.step - 1);
      } finally {
        this.isLoading = false;
      }
    },

    getClearMasterData () {
      const formData = {
        ...this.masterFormData,
        ...this.userFormData,
        ...this.carFormData,
        ...this.vinRequestData
      };

      return Object
        .entries(formData)
        .reduce((acc, [key, value]) => {
          if (['isActive'].includes(key)) {
            return acc;
          } else if (key !== 'id' && !key.startsWith('_') && value) {
            acc[key] = value;
          }

          return acc;
        }, {});
    },

    savePhoneNumToStorage () {
      return this.$store.dispatch('setCookie',
        {
          name: env.LAST_PHONE_NUM_KEY,
          value: this.userFormData.phoneNumber
        });
    },

    showCitySelector () {
      this.$store.dispatch('setIsCitySelector', true);
    },

    setCarData (data) {
      if (data.id || data._modificationId) {
        const clearData = getClearFromEmpty(data);

        this.checkAndSaveCar({
          bodyType: clearData.bodyType,
          brand: clearData.brand,
          engineCapacity: clearData.engineCapacity || Number.parseFloat(clearData.capacity),
          cylinders: clearData.cylinders,
          driveType: clearData.driveType,
          engineCode: clearData.engineCode,
          engineType: clearData.engineType,
          fuelType: clearData.fuelType,
          model: clearData.model,
          name: clearData.name,
          powerPs: clearData.powerPs,
          valves: clearData.valves,
          productionYear: data.productionYear,
          modification: data._modificationId,
          vin: data.vin || ''
        });
      } else {
        this.checkAndSaveCar(getDefault('car'));
      }
    },

    goBack () {
      if (this.carFormData.vin) {
        this.carFormData.vin = '';
        this.masterFormData.vin = '';
      } else if (this.carFormData.modification) {
        this.carFormData = getDefault('car');
        this.masterFormData = getDefault('selectionRequestMaster');
        this.$refs.garageWidget?.clear();
        this.$router.push({ query: {} }).catch(e => e);
      } else {
        this.$router.push(getSeoLinkBy({}, this, {}));
      }
    },

    checkAndSaveVinForCar () {
      if (this.$store.getters['auth/isAuthenticated']) {
        return null;
      }

      const { modification, vin } = this.carFormData;
      const savedCars = this.$store.getters['carModifications/savedCars'];
      const index = savedCars.findIndex(({ _modificationId }) => _modificationId === modification);

      if (index > -1 && !savedCars[index].vin) {
        const newSavedCars = savedCars.slice(0);

        newSavedCars[index] = {
          ...newSavedCars[index],
          vin
        };

        this.$store.dispatch(
          'carModifications/setCarData',
          { cars: newSavedCars }
        );
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/default-style.scss";

.s-request-create-master-page {
  .car-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .vin-select {
    width: 180px;
    max-width: 100%;

    @media all and (max-width: $--ss) {
      width: 100%;
    }
  }
}
</style>
