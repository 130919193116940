var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sign-in-links" },
    [
      _c(
        "client-only",
        [
          _c(
            "el-row",
            { attrs: { align: "middle", gutter: 8, justify: "center" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [_c("GoogleBtn", _vm._g({}, _vm.$listeners))],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [_c("FacebookBtn", _vm._g({}, _vm.$listeners))],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }