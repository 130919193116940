<template>
  <el-row v-if="!value.length" class="pg-2" justify="center">
    {{ $t('errors.emptyData') }}
  </el-row>

  <ul v-else class="mr-list car-default-list">
    <li v-for="item in value" :key="item.id" class="mr-list-item">
      <el-button
        :class="{ 'disable-events': readonly }"
        size="mini"
        :type="isActive(item) ? 'primary' : ''"
        @click="$emit('set-item', item)"
      >
        {{ item.name }}
      </el-button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CarDefaultList',

  props: {
    readonly: Boolean,
    value: { type: Array, required: true },
    isActive: { type: Function, required: true }
  }
};
</script>

<style scoped lang="scss">
.mr-list {
  display: flex;
  flex-wrap: wrap;
}

.mr-list-item .el-button {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 150px;
  line-height: 2;
  border-radius: unset;
  position: relative;

  &.mw-unset {
    min-width: unset;
  }

  &:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
}

.mr-label {
  padding: 10px;
  font-weight: bold;
  min-width: 180px;
  text-align: right;
}

.car-engine-list .mr-label {
  min-width: 200px;
}

.car-years-list .el-button{
  min-width: 50px;
}
</style>
