import { parsers } from '@/lib/core';

export const actions = {
  getTrademarks (_, params) {
    return this.$axios
      .$get('/seller-official-trademarks/', { params });
  },

  postTrademark (_, { formData }) {
    const { verification_document, ...data } = formData;

    const multipart = parsers.getMultipartFormData(
      data,
      verification_document,
      'verification_document'
    );

    return this.$axios
      .$post('/seller-official-trademarks/', multipart);
  },

  patchTrademark (_, { id, formData }) {
    const { verification_document, ...data } = formData;
    let payload = data;

    if (verification_document && typeof verification_document !== 'string') {
      payload = parsers.getMultipartFormData(
        data,
        verification_document,
        'verification_document'
      );
    } else if (!verification_document) {
      payload.verification_document = verification_document;
    }

    return this.$axios.$patch(`/seller-official-trademarks/${id}/`, payload);
  },

  removeTrademark (_, { id }) {
    return this.$axios.$delete(`/seller-official-trademarks/${id}/`);
  }
};
