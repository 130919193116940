var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-requests-processing-list-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [_vm._v("\n    " + _vm._s(_vm.$t("routes.garage_requests")) + "\n  ")]
      ),
      _vm._v(" "),
      _c("SRequestFilter", { staticClass: "mb-2", on: { submit: _vm.load } }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : _c("SRequestsProcessingTable", {
            attrs: { value: _vm.tableData.results },
            on: { select: _vm.goTo },
          }),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mt-2", attrs: { justify: "center" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "hide-on-single-page": "",
              layout: "prev, pager, next",
              "current-page": _vm.page,
              "page-size": _vm.PAGINATION_SIZE,
              "pager-count": 5,
              total: _vm.tableData.count,
            },
            on: { "current-change": _vm.setPage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }