var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts no-wrap-error" },
    [
      !_vm.readonly
        ? _c("div", { staticClass: "description mb-2" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("account.contactsDescription1")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("account.contactsDescription2")))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "row-item mb-2", attrs: { prop: "phone_numbers" } },
        _vm._l(_vm.formData.phone_numbers, function (phone, index) {
          return _c(
            "el-row",
            {
              key: index,
              staticClass: "row",
              attrs: { align: "middle", gutter: 20 },
            },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "mb-0",
                      attrs: {
                        "label-width": _vm.labelWidth,
                        prop: "phone_numbers." + index + ".phone_number",
                        rules: _vm.rules.phone_number,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "bold" }, [
                                  _vm._v(_vm._s(_vm.$t("labels.phone")) + ":"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("PhoneInput", {
                        staticClass: "w-250p",
                        attrs: { disabled: _vm.readonly },
                        on: {
                          blur: function ($event) {
                            return _vm.fixPhoneNumber(phone)
                          },
                          input: function ($event) {
                            return _vm.emit(
                              "phone_numbers",
                              _vm.formData.phone_numbers
                            )
                          },
                        },
                        model: {
                          value: phone.phone_number,
                          callback: function ($$v) {
                            _vm.$set(phone, "phone_number", $$v)
                          },
                          expression: "phone.phone_number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "fit-content" },
                [
                  index === 0
                    ? _c(
                        "el-row",
                        { staticClass: "w-250p checkbox-labels" },
                        _vm._l(_vm.channels, function (channel) {
                          return _c(
                            "el-col",
                            {
                              key: channel,
                              attrs: { span: 24 / _vm.channels.length },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("phoneChannels." + channel))
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { disabled: _vm.readonly },
                      on: {
                        input: function ($event) {
                          return _vm.emit(
                            "phone_numbers",
                            _vm.formData.phone_numbers
                          )
                        },
                      },
                      model: {
                        value: phone.channels,
                        callback: function ($$v) {
                          _vm.$set(phone, "channels", $$v)
                        },
                        expression: "phone.channels",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "w-250p", attrs: { justify: "center" } },
                        _vm._l(_vm.channels, function (channel) {
                          return _c(
                            "el-col",
                            {
                              key: channel,
                              attrs: { span: 24 / _vm.channels.length },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: channel } }, [
                                _c("span"),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col"),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "row-item", attrs: { prop: "emails" } },
        _vm._l(_vm.formData.emails, function (email, index) {
          return _c(
            "el-row",
            {
              key: index,
              staticClass: "row",
              attrs: { align: "middle", gutter: 20 },
            },
            [
              _c(
                "el-col",
                { staticClass: "fit-content" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "mb-0",
                      attrs: {
                        "label-width": _vm.labelWidth,
                        prop: "emails." + index + ".email",
                        rules: _vm.rules.email,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "bold" }, [
                                  _vm._v(_vm._s(_vm.$t("labels.email")) + ":"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "w-250p",
                        attrs: { disabled: _vm.readonly, type: "email" },
                        on: {
                          input: function ($event) {
                            return _vm.emit("emails", _vm.formData.emails)
                          },
                        },
                        model: {
                          value: email.email,
                          callback: function ($$v) {
                            _vm.$set(email, "email", $$v)
                          },
                          expression: "email.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }