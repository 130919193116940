var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        staticClass: "upload-info-block",
        attrs: {
          "entity-name": "price",
          fields: ["price_file"],
          "hide-controls": "",
          "label-position": "left",
          "label-width": "0",
          value: _vm.dependency.price,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var readonly = ref.readonly
              return [
                _c(
                  "UploadInfo",
                  _vm._g(
                    {
                      attrs: {
                        price: _vm.dependency.price,
                        readonly: readonly,
                      },
                    },
                    _vm.$listeners
                  )
                ),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }