import getSeoBreadcrumbs from '@/lib/catalog/services/getSeoBreadcrumbs';

export default function (vm, { ware } = {}) {
  const { q, article, trademark } = vm.$route.query;
  const tm = ware?.trademark?.name || trademark || '';
  const arc = ware?.article || article || '';

  const rowBreadcrumbs = [
    {
      name: 'search',
      label: arc,
      path: `/search?q=${q || ''}`
    }
  ];

  if (ware) {
    rowBreadcrumbs.push({
      name: 'article',
      label: `${tm} ${arc}`,
      path: vm.$route.fullPath
    });
  }

  const pl = { vm, rowBreadcrumbs };
  const { breadcrumbs, seoBreadcrumbs } = getSeoBreadcrumbs(pl);

  vm.$store.dispatch('setBreadcrumbs', breadcrumbs);

  return { breadcrumbs, seoBreadcrumbs };
}
