<template>
  <div class="buyer-order-items-table">
    <el-table
      row-key="id"
      :cell-style="{ textAlign: 'center' }"
      :data="value"
      :show-header="false"
      :header-cell-style="{ textAlign: 'center' }"
    >
      <el-table-column width="100px">
        <template #default="{ row }">
          <BlockSize size="ls" :src="row.image_url" />
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('basket.display_article')"
        prop="display_article"
        width="250px"
      >
        <template #default="{ row }">
          <el-row class="article-row">
            <div class="mr-1 bold">{{ row.display_trademark }}</div>

            <el-row align="middle" class="article-title">
              <el-link
                class="mr-1"
                target="_blank"
                type="primary"
                :href="row.ware_card_url"
              >
                {{ row.article ? row.display_article : '*****' }}
              </el-link>

              <BufferCopy
                v-if="row.display_article"
                :value="row.display_article"
              />
            </el-row>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('basket.display_title')"
        min-width="200px"
        prop="display_title"
      />

      <el-table-column
        :label="`${$t('basket.delivery_time')}${withDescription ? '*' : ''}`"
        prop="delivery_time"
        width="120px"
      >
        <template #default="{ row }">
          <DeliveryTimeString
            hide-color
            :class="row._notFound ? 'line-through' : ''"
            :value="row.delivery_time"
            :options="{ ware: row, useDateIfDaysMore: 2 }"
          />
        </template>
      </el-table-column>

      <el-table-column
        :label="priceLabel"
        prop="price"
        align="right"
        width="150px"
      >
        <template #default="{ row }">
          <el-row justify="end">
            <ThePrice
              :class="{ 'line-through': row._notFound }"
              :value="getPriceNum(row.price)"
            />

            <TheTooltip
              :content="`${$t('basket.prev_price')}: ${getPriceNum(row.prev_price)}`"
            >
              <span
                v-if="!row._notFound && (row.price && row.prev_price)"
                class="price-suffix"
              >
                <i
                  v-if="row.price > row.prev_price"
                  class="el-icon-caret-top color-red"
                />

                <i v-else class="el-icon-caret-bottom color-green" />
              </span>
            </TheTooltip>
          </el-row>

        </template>
      </el-table-column>

      <el-table-column
        :label="`${$t('basket.quantity')}${withDescription ? '***' : ''}`"
        prop="quantity"
        width="150px"
      >
        <template #default="{ row }">
          <TheTooltip
            :content="`
            ${$t('orders.stockQuantity')}:
             ${row.stock_quantity === null ? 1000000 : row.stock_quantity}
             `"
          >
            <el-input-number
              v-model="row.quantity"
              :disabled="row._notFound"
              :max="row.stock_quantity === null ? 1000000 : row.stock_quantity"
              :min="row._notFound ? 0 : 1"
              size="mini"
              @change="emitUpdateQuantity(row)"
            />
          </TheTooltip>
        </template>
      </el-table-column>

      <el-table-column width="50px">
        <template #default="{ row }">
          <el-button
            circle
            class="delete"
            icon="el-icon-delete"
            size="mini"
            :type="row._notFound ? 'danger' : 'default'"
            @click="$emit('item:remove', row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-row justify="end" align="middle" class="mt-1">
      <ThePrice :value="total" size="large">
        {{ $t('basket.toBePaid') }}:
      </ThePrice>
    </el-row>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import DeliveryTimeString from './DeliveryTimeString';
import BufferCopy from '@/lib/core/components/BufferCopy';
import BlockSize from '@/lib/core/components/BlockSize';
import { parsers, env } from '@/lib/core';
import ThePrice from '@/lib/core/components/ThePrice';

export default {
  name: 'BuyerOrderItemsTable',

  components: {
    ThePrice,
    /* eslint-disable max-len */
    ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
    ElTableColumn: () => import(/* webpackChunkName: "ElTableColumn" */ 'element-ui/lib/table-column'),
    /* eslint-enable max-len */
    BufferCopy,
    BlockSize,
    DeliveryTimeString
  },

  props: {
    readonly: Boolean,
    withDescription: Boolean,
    currencyName: String,
    value: { type: Array, required: true }
  },

  data () {
    return {
      total: '',
      MARKETPLACE_CURRENCY: env.MARKETPLACE_CURRENCY
    };
  },

  computed: {
    priceLabel () {
      const { currencyName, withDescription } = this;
      const cn = currencyName ? `, ${currencyName}` : '';

      return `${this.$t('basket.price')}${withDescription ? '**' : ''}${cn}`;
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (value) {
        this.total = this.getTotalAmount(value);
      }
    }
  },

  created () {
    this.emitUpdateQuantity = debounce(this.emitUpdateQuantity, env.DEBOUNCE);
  },

  methods: {
    getPriceNum: parsers.getPriceNum,

    getMaxTime (items) {
      const _item = items.filter(({ error_code }) => error_code !== 'not_found');

      if (!_item.length) {
        return null;
      }

      return sortBy(_item, ({ delivery_time }) => {
        const { days, hours } = parsers.getDayHourBy(delivery_time);
        return `${days}${hours}`;
      }).reverse()[0].delivery_time;
    },

    getTotalAmount (items) {
      return items
        .reduce((acc, { price, quantity, error_code }) => {
          return error_code !== 'not_found'
            ? acc + (Number.parseFloat(price) * quantity)
            : acc;
        }, 0);
    },

    getPriceByQuantity (row) {
      return !row._notFound ? parsers.getPriceNum(row.price * row.quantity) : 0;
    },

    emitUpdateQuantity (row) {
      this.$emit('item:update', { id: row.id, quantity: row.quantity });
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/components/order-tables.scss';
@import '@/styles/components/mobile-tables.scss';
</style>
