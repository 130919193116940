var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "trademarks-grouped-list" }, [
    _c(
      "div",
      { staticClass: "mr-filter" },
      _vm._l(_vm.groupedKeys, function (_keys, index) {
        return _c(
          "ul",
          { key: index },
          _vm._l(_keys, function (key) {
            return _c(
              "li",
              { key: key, class: { "is-active": _vm.isActiveLetter(key) } },
              [
                _c("nuxt-link", { attrs: { to: _vm.getLitterRoute(key) } }, [
                  _vm._v(
                    "\n          " + _vm._s(key.toUpperCase()) + "\n        "
                  ),
                ]),
              ],
              1
            )
          }),
          0
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.activeItem
      ? _c(
          "ul",
          { staticClass: "mr-list mr-list__selected" },
          _vm._l(_vm.contentList, function (trademark) {
            return _c(
              "li",
              { key: trademark.id, staticClass: "mr-list-item" },
              [
                _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: {
                        name: _vm.getRouteName("trademarkId"),
                        params: { trademarkId: trademark.slug },
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(trademark.name) + "\n      ")]
                ),
              ],
              1
            )
          }),
          0
        )
      : _c(
          "ul",
          { staticClass: "mr-list" },
          _vm._l(_vm.keys, function (key) {
            return _c(
              "li",
              { key: key, staticClass: "mr-list-item" },
              [
                _c("el-row", [
                  _c(
                    "div",
                    { staticClass: "mr-1 mr-list-item-title" },
                    [
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "bold",
                          attrs: { to: _vm.getLitterRoute(key) },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(key.toUpperCase()) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "mr-list-item-brands" },
                    [
                      _vm._l(_vm.groupedByName[key], function (item, index) {
                        return [
                          (_vm.limit && index <= _vm.limit) ||
                          _vm.visibleChildren[key]
                            ? _c(
                                "li",
                                { key: item.id },
                                [
                                  _c(
                                    "nuxt-link",
                                    { attrs: { to: _vm.getLinkBy(item) } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.name) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                      _vm._v(" "),
                      _vm.limit && _vm.groupedByName[key].length > _vm.limit
                        ? _c(
                            "a",
                            {
                              class: [
                                "show-all-btn shop-category__open",
                                { "is-open": _vm.visibleChildren[key] },
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.toggleShowChildren(key)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "shop-category-text" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "buttons." +
                                            (_vm.visibleChildren[key]
                                              ? "lessTrademarks"
                                              : "moreTrademarks")
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ],
              1
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }