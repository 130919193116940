import { parsers } from '@/lib/core';

export const actions = {
  async patchPersonProfile ({ commit, rootGetters }, { id, formData }) {
    const { avatar, ...other } = formData;
    let profile = null;

    if (avatar) {
      const parsedFormData = parsers
        .getMultipartFormData(other, avatar, 'avatar');

      profile = await this.$axios
        .$patch(`personal-profiles/${id}/`, parsedFormData);
    } else {
      profile = await this.$axios
        .$patch(`personal-profiles/${id}/`, formData);
    }

    const user = rootGetters['users/user'];

    commit('users/setUser', { ...user, profile }, { root: true });

    return profile;
  }
};
