<template>
  <div class="sale-points-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_sale_points') }}
    </h1>

    <el-row class="mb-2" justify="end">
      <nuxt-link
        :to="{ name: getRouteName('profile.business.sale-points.create') }"
      >
        <el-button :disabled="!isAdmin" size="mini" type="primary">
          {{ $t('buttons.createSalePoint') }}
        </el-button>
      </nuxt-link>
    </el-row>

    <TheLoader v-if="isLoading" />

    <SalePointsTable
      v-else
      :value="tableData"
      :readonly="!isAdmin"
      @row-click="goSalePoint"
    />
  </div>
</template>

<script>
import SalePointsTable from './components/SalePointsTable';

export default {
  name: 'SalePointsPage',

  components: {
    SalePointsTable
  },

  data: () => ({
    isLoading: true,
    tableData: []
  }),

  computed: {
    isAdmin () {
      return this.$store.getters['businessUsers/isAdmin'];
    }
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      this.isLoading = true;

      try {
        const business = this.$store.getters['businessProfiles/businessId'];

        this.tableData = await this.$store.dispatch(
          'salePoints/getSalePoints',
          { business }
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    goSalePoint (point) {
      this.$router.push({
        name: this.getRouteName('profile.business.sale-points.edit'),
        params: { id: point.id }
      });
    }
  }
};
</script>
