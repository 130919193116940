var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sale-point-create-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("routes.profile_business_sale_points_create")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-ghost mb-2" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("salePoint.setMinInfo")))]),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            autocomplete: "off",
            "label-position": "left",
            model: _vm.formData,
            rules: _vm.rules,
            size: "mini",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit()
            },
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("labels.salePointName"),
                "label-width": "200px",
                prop: "name",
              },
            },
            [
              _c("el-input", {
                staticClass: "max-w-350p",
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Address",
            _vm._b(
              {
                ref: "address",
                staticClass: "label-to-top mb-2",
                attrs: { value: _vm.formData.address },
              },
              "Address",
              _vm.formData.address,
              false,
              true
            )
          ),
          _vm._v(" "),
          _c(
            "Contacts",
            _vm._b(
              {
                staticClass: "mb-2",
                attrs: {
                  "hide-description": "",
                  "hide-emails": "",
                  rules: _vm.rules,
                  value: _vm.formData,
                },
              },
              "Contacts",
              _vm.formData,
              false,
              true
            )
          ),
          _vm._v(" "),
          _c(
            "SalePointOptions",
            _vm._b(
              { attrs: { "is-delivery-services": "", value: _vm.formData } },
              "SalePointOptions",
              _vm.formData,
              false,
              true
            )
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "mt-4", attrs: { justify: "center" } },
            [
              _vm.isBusinessCreateMode
                ? _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.$router.push({
                                name: _vm.getRouteName("profile.business.edit"),
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.cancel")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.isLoading,
                            "native-type": "submit",
                            type: "primary",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.continue")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        "native-type": "submit",
                        type: "primary",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("buttons.createSalePoint")) +
                          "\n      "
                      ),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }