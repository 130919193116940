<template>
  <TheTooltip :content="$t('buttons.pushToProcessing')">
    <el-button
      circle
      class="price-re-push"
      :disabled="disableWithPushDate || readonly"
      icon="el-icon-refresh"
      @click="confirmAndPush"
    />
  </TheTooltip>
</template>

<script>
const FIVE_MINUTES = 300000;
const MINUTE = 60000;

export default {
  name: 'PriceRePush',

  props: {
    readonly: Boolean,
    priceFile: { type: Object, required: true }
  },

  data () {
    return {
      now: Date.now(),
      interval: null
    };
  },

  computed: {
    disableWithPushDate () {
      return this.now - new Date(this.priceFile.pushed) < FIVE_MINUTES;
    }
  },

  created () {
    this.interval = setInterval(() => { this.now = Date.now(); }, MINUTE);
  },

  beforeDestroy () {
    clearInterval(this.interval);
  },

  methods: {
    confirmAndPush () {
      this.$confirm(
        this.$t('prices.rePushConfirm'),
        {
          cancelButtonText: this.$t('buttons.no'),
          confirmButtonText: this.$t('buttons.yes')
        }
      ).then(isAgree => isAgree && this.pushToProcessing());
    },

    async pushToProcessing () {
      try {
        const priceFile = await this.$store.dispatch('priceFiles/postPushPriceFile', {
          id: this.priceFile.id
        });

        this.$emit('price-file:update', priceFile);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
