var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "business-hot-keys",
      attrs: { mode: "horizontal", router: "" },
    },
    _vm._l(_vm.routes, function (route) {
      return _c(
        "el-menu-item",
        {
          key: route.name,
          attrs: {
            index: route.name,
            route: { name: _vm.getRouteName(route.name) },
            router: "",
          },
        },
        [
          _c("el-row", { attrs: { align: "middle" } }, [
            route.img
              ? _c("img", {
                  staticClass: "img icon",
                  attrs: { src: route.img, alt: route.title },
                })
              : _c("i", { class: route.icon }),
            _vm._v(" "),
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("routes." + route.title))),
            ]),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }