var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      staticClass: "order-payment block-title",
      attrs: { prop: "payment_method", label: _vm.$t("basket.payment_method") },
    },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "white-back",
          attrs: { disabled: _vm.readonly, value: _vm.value.payment_method },
          on: {
            input: function ($event) {
              return _vm.emit("payment_method", $event)
            },
          },
        },
        _vm._l(_vm.paymentMethods, function (method) {
          return _c("el-radio", { key: method, attrs: { label: method } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("payment_methods." + method)) +
                "\n    "
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }