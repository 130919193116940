var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-request-create-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("sRequests.createRequestByVin")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "default-style mb-2",
        domProps: {
          innerHTML: _vm._s(_vm.$t("sRequests.createRequestByVinDesc")),
        },
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : _c("SRequestForm", {
            staticClass: "container",
            attrs: { car: _vm.car, submit: _vm.submit },
            on: { cancel: _vm.toRequests },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }