<template>
  <el-form
    ref="form"
    class="delivery-rate-form"
    label-position="left"
    label-width="250px"
    :model="formData"
    :rules="rules"
    size="mini"
    @submit.native.prevent="submit"
  >
    <el-form-item :label="$t('deliveryRate.existName')">
      <el-select v-model="formData" class="name" value-key="url">
        <el-option
          v-for="rate in rates"
          :key="rate.url"
          :label="rate.name"
          :value="rate"
        />
        <el-option :label="$t('deliveryRate.creationNewRate')" :value="defaultRate" />
      </el-select>
    </el-form-item>

    <el-form-item :label="$t('deliveryRate.name')" prop="name">
      <el-input v-model="formData.name" class="name" :disabled="isExisted" />
    </el-form-item>

    <el-form-item :label="$t('deliveryRate.currency')" prop="currency">
      <CurrencySelector v-model="formData.currency" :readonly="isExisted" />
    </el-form-item>

    <h4 class="mb-2 bold">{{ $t('deliveryRate.deliveryPrams') }}:</h4>

    <DeliveryRateParams
      :currency-name="currencyName"
      :readonly="isExisted"
      :value="formData"
      v-bind.sync="formData"
    />

    <el-row class="mt-2" justify="end">
      <el-button :disabled="loading" size="large" @click="$emit('cancel')">
        {{ $t('buttons.cancel') }}
      </el-button>

      <el-button
        :loading="loading"
        native-type="submit"
        size="large"
        type="primary"
        @click="submit"
      >
        {{ $t('buttons.save') }}
      </el-button>
    </el-row>
  </el-form>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import transform from 'lodash/transform';
import DeliveryRateParams from './formItems/DeliveryRateParams';
import { getValidationFor, getDefault } from '@/lib/core';
import CurrencySelector from '@/lib/core/components/CurrencySelector';

const floats = ['correction_factor', 'constant', 'weight_rate', 'no_weight_factor'];
const defaultRate = getDefault('deliveryRate');

export default {
  name: 'DeliveryRateForm',

  components: {
    DeliveryRateParams,
    CurrencySelector
  },

  props: {
    rates: Array
  },

  data () {
    return {
      loading: false,
      defaultRate,
      formData: cloneDeep(defaultRate),
      rules: getValidationFor(null, 'deliveryRate', this)
    };
  },

  computed: {
    isExisted () {
      return Boolean(this.formData.url);
    },

    currencies () {
      return this.$store.getters['dependencies/currencies'];
    },

    currencyName () {
      const currency = this.currencies.find(({ url }) => url === this.formData.currency);
      return currency ? currency.name : '';
    },

    businessUrl () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'].url;
    }
  },

  methods: {
    submit () {
      if (this.formData.url) {
        return this.$emit('input', this.formData);
      }

      this.formData.business = this.businessUrl;

      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          this.loading = true;

          try {
            const scheme = await this.$store.dispatch('deliveryRates/postDeliveryRate', {
              formData: this.getFilteredData(this.formData)
            });

            this.$emit('created', scheme);
          } catch {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },

    getFilteredData (formData) {
      return transform(formData, (acc, value, key) => {
        acc[key] = floats.includes(key) ? Number.parseFloat(value) : value;
      }, {});
    }
  }
};
</script>

<style scoped lang="scss">
.delivery-rate-form {
  .name {
    width: 200px;
  }
}
</style>
