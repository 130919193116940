import cloneDeep from 'lodash/cloneDeep';
import { getDefault, parsers } from '@/lib/core';

const isActualBasket = (oldVal, newVal) => {
  if (
    (!newVal || !oldVal) ||
    (newVal.updated !== oldVal.updated) ||
    (Number(newVal.total_amount) !== parsers.getWareTotalAmountNum(oldVal.items))
  ) {
    return null;
  }

  return oldVal;
};

export const state = () => ({
  basket: getDefault('basket')
});

export const mutations = {
  setBasket: (state, basket) => { state.basket = basket; }
};

export const actions = {
  async fetchBasket ({ dispatch, getters, rootGetters }, options) {
    const _options = options || {};
    const sessionId = await dispatch('getSessionId', null, { root: true });

    const formData = {
      session_key: sessionId,
      buyer_user: rootGetters['users/userId'],
      buyer_business: rootGetters['businessProfiles/businessId'],
      user: rootGetters['users/userId']
    };

    if (!Object.values(formData).filter(Boolean).length) {
      await dispatch('clearLocalBasket');
      return getDefault('basket');
    }

    try {
      const _basket = await dispatch('getPrimaryByUser');

      if (isActualBasket(getters.basket, _basket)) {
        await dispatch('setBasket', { basket: getters.basket });
        return getters.basket;
      }

      const basket = await dispatch('getBasketByFilter', formData);

      if (basket) {
        return await dispatch('setBasket', { basket });
      } else if (_options.clear) {
        return await dispatch('clearLocalBasket');
      }
    } catch (e) {
      console.error(e);
      return await dispatch('clearLocalBasket');
    }
  },

  async getPrimaryByUser ({ dispatch, rootGetters }) {
    try {
      const sessionId = await dispatch('getSessionId', null, { root: true });

      const { basket } = await this.$axios
        .$get('/baskets/get_primary_of_user/', {
          headers: {
            'X-SessionID': sessionId || undefined
          },
          params: {
            business: rootGetters['businessProfiles/businessId'] || undefined
          }
        });

      return { items: [], ...basket };
    } catch (e) {
      console.error(e);
      return { items: [] };
    }
  },

  async clearLocalBasket ({ dispatch }) {
    return await dispatch('setBasket', { basket: getDefault('basket') });
  },

  async getBasketByFilter ({ rootGetters, dispatch }, formData) {
    if (!Object.values(formData).filter(Boolean).length) {
      await dispatch('clearLocalBasket');
      return getDefault('basket');
    }

    const params = { is_primary: true };

    if (formData.buyer_business && formData.user) {
      params.buyer_business = formData.buyer_business;
      params.user = formData.user;
    } else if (formData.buyer_user) {
      params.buyer_user = formData.buyer_user;
    } else if (formData.session_key) {
      params.session_key = formData.session_key;
    }

    const [basket] = await this.$axios.$get('/baskets/', { params });

    return basket || getDefault('basket');
  },

  setBasket ({ commit }, { basket }) {
    commit('setBasket', basket);
    return basket;
  },

  async updateLocalBasket ({ dispatch, getters }, { formData, ware }) {
    const copy = cloneDeep(getters.basket || {});
    const basket = { ...copy, ...formData };

    if (ware) {
      basket.items.push(ware);
    }

    return await dispatch('setBasket', { basket });
  },

  async patchBasket ({ commit }, { id, formData }) {
    return await this.$axios.$patch(`/baskets/${id}/`, formData);
  },

  async mergeBaskets ({ rootGetters, dispatch }) {
    const userCode = rootGetters.userCode;
    const sessionId = await dispatch('getSessionId', null, { root: true });

    if (!userCode || !sessionId) {
      return null;
    }

    const payload = {
      source_session_key: sessionId,
      target_buyer: userCode.startsWith('u')
        ? rootGetters['users/user'].profile.buyer.url
        : rootGetters['businessProfiles/currentBusinessProfile'].buyer.url
    };

    return await this.$axios.$post('/baskets/merge/', payload);
  }
};

export const getters = {
  basket: state => state.basket
};
