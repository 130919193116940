export default {
  props: {
    readonly: Boolean,
    dependency: { type: Object, default: () => ({}) },
    value: { type: Object, required: true }
  },

  methods: {
    emit (name, value) {
      this.$emit(`update:${name}`, value);
    },

    emitNestedField (entityName, name, value) {
      this.$emit(`update:${entityName}`, { ...this.value[entityName], [name]: value });
    }
  }
};
