var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collapse-icon", class: { valid: _vm.isValid } },
    [
      _c("div", { class: ["border", { valid: _vm.isValid }] }),
      _vm._v(" "),
      _c("img", { attrs: { src: _vm.src } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }