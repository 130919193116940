import { requiredString, requiredNumber, notEmptyArray } from './common.js';

export default {
  vin: [requiredString],
  brand: [requiredNumber],
  model: [requiredNumber],
  modification: [requiredNumber],
  productionYear: [requiredNumber],
  itemsAdd: [notEmptyArray]
};
