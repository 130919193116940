var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-tab" },
    [
      _c(
        "el-row",
        {
          staticClass: "mb-1",
          attrs: { align: "middle", justify: "space-between" },
        },
        [
          _c("div", [_vm._v(_vm._s(_vm.tabTitle))]),
          _vm._v(" "),
          _c("CollapseToggle", {
            attrs: { blocks: _vm.blocks },
            model: {
              value: _vm.activeBlocks,
              callback: function ($$v) {
                _vm.activeBlocks = $$v
              },
              expression: "activeBlocks",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeBlocks,
            callback: function ($$v) {
              _vm.activeBlocks = $$v
            },
            expression: "activeBlocks",
          },
        },
        _vm._l(_vm.blocks, function (block) {
          return _c(
            "el-collapse-item",
            { key: block, attrs: { name: block } },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c("CollapseIcon", {
                    attrs: { "is-valid": _vm.validBlocks[block] },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(_vm.entityName + "." + block + "Block")) +
                      "\n      "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pg-2" },
                [
                  _c(
                    _vm.upperFirst(block) + "Block",
                    _vm._g(
                      {
                        tag: "component",
                        attrs: {
                          dependency: _vm.dependency,
                          readonly: _vm.readonly,
                          submit: _vm.submit,
                          value: _vm.entity,
                        },
                        on: {
                          validate: function ($event) {
                            _vm.validBlocks[block] = $event
                          },
                        },
                      },
                      _vm.$listeners
                    )
                  ),
                ],
                1
              ),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }