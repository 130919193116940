<template>
  <div class="profile-page">
    <el-container>
      <el-aside v-if="isSellBusiness && !isHideAsideNav" width="200xp">
        <BusinessMenu />
      </el-aside>

      <el-main :class="{ 'pg-0': isHideAsideNav }"><nuxt /></el-main>
    </el-container>
  </div>
</template>

<script>
import BusinessMenu from './components/BusinessMenu';
import { env } from '@/lib/core';

export default {
  name: 'ProfilePage',

  middleware: ['userAuth', 'businessUserLoaded', 'dependencies'],

  components: {
    BusinessMenu
  },

  computed: {
    isHideAsideNav () {
      return this.$route.meta?.isHideAsideNav;
    },

    isSellBusiness () {
      return this.$store.getters['businessProfiles/isSellBusiness'];
    }
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>

<style scoped lang="scss">
.profile-page {
  display: flex;
  min-height: 100%;

  .el-main {
    padding-top: 0;
    padding-right: 0;
  }
}
</style>
