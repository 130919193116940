var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BlockSize",
    { staticClass: "file-preview", attrs: { alt: _vm.alt, size: _vm.size } },
    [
      _vm.type
        ? _c(
            _vm.fileComponent,
            _vm._b(
              {
                tag: "component",
                staticClass: "preview",
                style: _vm.boxStyle,
                attrs: {
                  alt: _vm.alt,
                  playing: _vm.playing,
                  "preview-icon": _vm.previewIcon,
                  value: _vm.file,
                  "img-path": _vm.imgPath,
                  "default-img-path": _vm.defaultImgPath,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$emit("clickedFile", _vm.file)
                  },
                },
              },
              "component",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }