<template>
  <div class="ware-info-application mb-4">
    <WareCarApplicabilitySelector hide-label :value="ware.car_brands" />
  </div>
</template>

<script>
import WareCAS from '@/lib/catalog/components/ware/WareCarApplicabilitySelector';

export default {
  name: 'WareInfoApplication',

  components: {
    WareCarApplicabilitySelector: WareCAS
  },

  props: {
    ware: { type: Object, required: true }
  }
};
</script>
