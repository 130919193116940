<template>
  <FormBlock
    class="main-info-block"
    label-position="left"
    entity-name="profile"
    :fields="['name', 'is_seller', 'logo']"
    :value="value"
    :submit="submit"
    v-on="$listeners"
  >
    <template #default="{ formData, readonly }">
      <div class="description mb-2">
        <p>{{ $t('profile.addCompanyInfo') }}</p>
      </div>

      <BusinessNamePhoto
        :readonly="readonly"
        :value="formData"
        v-bind.sync="formData"
      />
    </template>
  </FormBlock>
</template>

<script>
import BusinessNamePhoto from '../formItems/BusinessNamePhoto';
import FormBlock from '@/lib/core/components/FormBlock';

export default {
  name: 'MainInfoBlock',

  components: {
    FormBlock,
    BusinessNamePhoto
  },

  props: {
    submit: Function,
    value: { type: Object, required: true }
  }
};
</script>
