<template>
  <FormBlock
    class="site-block"
    entity-name="profile"
    :fields="['site', 'order_on_site', 'search_on_site_url']"
    label-position="left"
    :rules="rules"
    :submit="submitSeller"
    :value="value.seller || {}"
    v-on="$listeners"
  >
    <template #default="{ formData, readonly }">
      <SiteInfo :readonly="readonly" :value="formData" v-bind.sync="formData" />
    </template>
  </FormBlock>
</template>

<script>
import SiteInfo from '../formItems/SiteInfo';
import { getValidationFor } from '@/lib/core';
import FormBlock from '@/lib/core/components/FormBlock';

export default {
  name: 'SiteBlock',

  components: {
    FormBlock,
    SiteInfo
  },

  props: {
    submit: Function,
    value: { type: Object, required: true }
  },

  data () {
    return {
      rules: getValidationFor(['site', 'search_on_site_url'], 'profile')
    };
  },

  methods: {
    submitSeller (seller) {
      return this.submit({ seller });
    }
  }
};
</script>
