<template>
  <div class="schedule-info">
    <el-row class="mb-1 bold" justify="center">
      {{ $t('salePoint.scheduleBlock') }}:
    </el-row>

    <div v-if="isEmpty" class="pg-2 color-default text-center">
      {{ $t('errors.emptyData') }}
    </div>

    <template v-else>
      <el-row v-for="(times, day) in formData" :key="day">
        <div :class="['day', { 'text-ghost': !times }]">
          {{ $t(`el.datepicker.weeks.${day}`) }}
        </div>

        <div v-if="times" class="time">
          {{ times.from | cutTime }}
          -
          {{ times.to | cutTime }}
        </div>

        <div v-else class="text-ghost text-center time">
          {{ $t('el.datepicker.output') }}
        </div>
      </el-row>
    </template>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { getDefault } from '@/lib/core/services/defaultEntities';

const days = Object.keys(getDefault('schedule'));

export default {
  name: 'ScheduleView',

  props: {
    value: { type: Object, default: () => ({}) }
  },

  computed: {
    isEmpty () {
      return isEmpty(this.value);
    },

    formData () {
      return days.reduce((acc, day) => {
        acc[day] = (this.value || {})[day] ? this.value[day] : null;

        return acc;
      }, {});
    }
  }
};
</script>

<style scoped lang="scss">
.schedule-info {
  width: fit-content;

  .day {
    width: 100px;
    text-align: right;
    margin-right: 10px;
  }

  .time {
    width: 120px;
    text-align: center;
  }
}
</style>
