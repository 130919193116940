var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prices-upload-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("routes.profile_business_prices_upload")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("PriceCreateSteps"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          attrs: { model: _vm.formData },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "input-wrapper" }, [
            _c(
              "h3",
              {
                staticClass:
                  "mb-1 text-center custom-headline custom-headline--level3",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("prices.priceLoadAction")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "point-options" },
              [
                _c("p", { staticClass: "description mb-1" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("prices.priceLoadOption1Description2")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("PriceUploader", {
                  staticClass: "mb-3",
                  on: { input: _vm.setFile },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.file,
                loading: _vm.isLoading,
                "native-type": "submit",
                type: "primary",
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("el.upload.continue")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }