var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "seller-order-items-table" },
    [
      _vm.localError && _vm.load
        ? _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.reload },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("buttons.searchAgain")) +
                      "\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb-2", attrs: { align: "middle", justify: "end" } },
        [
          _c("StatusCycleHelp"),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "mr-1",
              attrs: {
                clearable: "",
                placeholder: _vm.$t("placeholders.setStatus"),
                size: "mini",
              },
              model: {
                value: _vm.selectedStatusCollection,
                callback: function ($$v) {
                  _vm.selectedStatusCollection = $$v
                },
                expression: "selectedStatusCollection",
              },
            },
            _vm._l(_vm.groupedByStatus, function (value, status) {
              return _c(
                "el-option",
                {
                  key: status,
                  attrs: {
                    disabled: value.disabled,
                    label: status
                      ? _vm.$t("orderStatuses." + status) +
                        " (" +
                        value.items.length +
                        ")"
                      : "",
                    value: status,
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("orderStatuses." + status)) +
                      " (" +
                      _vm._s(value.items.length) +
                      ")\n      "
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.selectedStatusCollection,
                size: "mini",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.setNextStatusForGroup(_vm.selectedStatusCollection)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.apply")) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            "cell-style": { textAlign: "center" },
            data: _vm.value,
            "header-cell-style": { textAlign: "center" },
          },
          on: {
            "row-click": function ($event) {
              return _vm.$emit("rowClick", $event)
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("basket.index"),
              type: "index",
              width: "50",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "min-width": "200px",
              prop: "_ware",
              label: _vm.$t("search.nomenclature"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-row", { staticClass: "article-row" }, [
                      _c("div", { staticClass: "mr-1" }, [
                        _c("strong", { staticClass: "text-ellipsis" }, [
                          _vm._v(_vm._s(row.display_trademark)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "article-title" }, [
                        _c("div", { staticClass: "mr-1" }, [
                          _vm._v(_vm._s(row.display_article)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-row", [
                      _c("div", { staticClass: "small" }, [
                        _vm._v(_vm._s(row.display_title)),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "delivery_time",
              width: "100px",
              label: _vm.$t("basket.delivery_time"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row._deliveryDate))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("basket.quantity"),
              prop: "quantity",
              width: "100px",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("search.price") + ", " + _vm.MARKETPLACE_CURRENCY,
              prop: "price",
              width: "100px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(_vm._s(row._priceSum))]),
                    _vm._v(" "),
                    _c("div", { staticClass: "small" }, [
                      _vm._v("× " + _vm._s(row._price)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "priceFile",
              width: "150px",
              label: _vm.$t("basket.priceFile"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-row",
                      { attrs: { align: "middle", justify: "center" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-ellipsis pg-0-1" },
                          [
                            _c(
                              "TheTooltip",
                              { attrs: { content: row._priceFileName } },
                              [_c("span", [_vm._v(_vm._s(row._priceFileName))])]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "shipment_detail",
              width: "150px",
              label: _vm.$t("basket.shipment"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-row",
                      {
                        staticClass: "text-ellipsis",
                        attrs: { justify: "space-between" },
                      },
                      [
                        _c("div"),
                        _vm._v(" "),
                        _vm.hasWaybill(row)
                          ? _c(
                              "div",
                              { staticClass: "text-ellipsis" },
                              [
                                row.shipment_detail.waybill
                                  ? _c(
                                      "TheTooltip",
                                      {
                                        attrs: {
                                          content: row.shipment_detail.waybill,
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(row.shipment_detail.waybill)
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                row.shipment_detail.waybill_date
                                  ? _c("span", { staticClass: "small" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row._waybillDate) +
                                          "\n            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("div", [_vm._v("-")]),
                        _vm._v(" "),
                        _c("div", [
                          row.shipment_detail
                            ? _c("img", {
                                staticClass: "icon-img c-pointer",
                                attrs: {
                                  src: require("@/assets/icons/information-variant-primary.svg"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showShipmentInfo(row)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("basket.status"), width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-row",
                      {
                        staticClass: "color-green",
                        attrs: { justify: "center" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              row.is_paid ? _vm.$t("basket.is_paid") : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("OrderStatusToggle", {
                      attrs: {
                        value: row,
                        "can-set-next-status": _vm.canSetNextStatus,
                        "get-next-status": _vm.getNextStatus,
                        "set-next-status": _vm.setNextStatus,
                      },
                    }),
                    _vm._v(" "),
                    _vm.canCancel(row.status)
                      ? _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                _vm.itemFormCancel = row
                              },
                            },
                          },
                          [
                            _c(
                              "TheTooltip",
                              {
                                attrs: {
                                  content: _vm.$t("buttons.cancelOrderItem"),
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-close c-pointer",
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "40px" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.isAllChecked,
                        callback: function ($$v) {
                          _vm.isAllChecked = $$v
                        },
                        expression: "isAllChecked",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      attrs: {
                        disabled: _vm.orderItemEndStatuses.includes(row.status),
                        value: _vm.checkedIds.includes(row.id),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.toggleItem(row, $event)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.hideBottomStatusSelect
        ? _c(
            "el-row",
            { staticClass: "mt-2", attrs: { align: "middle", justify: "end" } },
            [
              _c("StatusCycleHelp"),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "mr-1",
                  attrs: {
                    clearable: "",
                    placeholder: _vm.$t("placeholders.setStatus"),
                    size: "mini",
                  },
                  model: {
                    value: _vm.selectedStatusCollection,
                    callback: function ($$v) {
                      _vm.selectedStatusCollection = $$v
                    },
                    expression: "selectedStatusCollection",
                  },
                },
                _vm._l(_vm.groupedByStatus, function (value, status) {
                  return _c(
                    "el-option",
                    {
                      key: status,
                      attrs: {
                        disabled: value.disabled,
                        label: status
                          ? _vm.$t("orderStatuses." + status) +
                            " (" +
                            value.items.length +
                            ")"
                          : "",
                        value: status,
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("orderStatuses." + status)) +
                          " (" +
                          _vm._s(value.items.length) +
                          ")\n      "
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.selectedStatusCollection,
                    size: "mini",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setNextStatusForGroup(
                        _vm.selectedStatusCollection
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("buttons.apply")) + "\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.usePage && _vm.propCount > _vm.PAGE_SIZE
        ? _c(
            "el-row",
            { staticClass: "mt-2", attrs: { justify: "center" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.page,
                  layout: "prev, pager, next",
                  "page-size": _vm.PAGE_SIZE,
                  total: _vm.propCount,
                },
                on: {
                  "update:currentPage": function ($event) {
                    _vm.page = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.page = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: {
            width: "600px",
            title: _vm.$t("basket.rejectOrderProcessing"),
          },
          model: {
            value: _vm.itemFormCancel,
            callback: function ($$v) {
              _vm.itemFormCancel = $$v
            },
            expression: "itemFormCancel",
          },
        },
        [
          _c("OrderCancelConfirm", {
            attrs: { value: _vm.itemFormCancel },
            on: {
              cancel: function ($event) {
                _vm.itemFormCancel = null
              },
              "item:update": _vm.emitCancel,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !!_vm.forShipments
        ? _c("ShipmentFormDialog", {
            attrs: { value: _vm.forShipments },
            on: {
              cancel: function ($event) {
                _vm.forShipments = null
              },
              created: _vm.updateItemsByShipment,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: { width: "600px", title: _vm.$t("basket.orderShipment") },
          model: {
            value: _vm.shipmentInfo,
            callback: function ($$v) {
              _vm.shipmentInfo = $$v
            },
            expression: "shipmentInfo",
          },
        },
        [
          _vm.shipmentInfo
            ? _c("ShipmentInfo", {
                attrs: { id: _vm.shipmentInfo },
                on: {
                  cancel: function ($event) {
                    _vm.shipmentInfo = null
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }