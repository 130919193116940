<template>
  <el-dialog
    append-to-body
    center
    class="dialog-wrapper file-preview-dialog"
    top="50px"
    :visible.sync="isVisible"
    width="90%"
    height="90%"
    @closed="closeSwiper"
  >
    <slot name="title" />
    <client-only>
      <swiper ref="mainSwiper" class="gallery-top" :options="mainSwiperOptions">
        <swiper-slide v-for="(file, index) in files" :key="file.fileId">
          <FilePreview
            class="file-preview"
            :file="file"
            :playing="isActiveIndex(index)"
            :default-img-path="defaultImgPath"
            size="max"
            :img-path="imgPath"
          />
        </swiper-slide>
        <div slot="button-prev" class="swiper-button-prev" @click="prevSlide" />
        <div slot="button-next" class="swiper-button-next" @click="nextSlide" />
      </swiper>

      <swiper ref="thumbsSwiper" class="gallery-thumbs" :options="swiperOptionThumbs">
        <swiper-slide v-for="file in files" :key="file.fileId">
          <FilePreview
            disabled
            img-path="image_200x200"
            :file="file"
            :default-img-path="defaultImgPath"
            preview-icon
          />
        </swiper-slide>
      </swiper>
    </client-only>

    <slot name="footer" />
  </el-dialog>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import FilePreview from './FilePreview';

export default {
  name: 'FilePreviewDialog',

  components: {
    Swiper,
    SwiperSlide,
    FilePreview
  },

  directives: {
    swiper: directive
  },

  props: {
    files: { type: Array, required: true },
    activeFileIndex: { type: [String, Number], required: true },
    imgPath: String,
    defaultImgPath: String
  },

  data () {
    return {
      isVisible: false,
      mainSwiper: null,
      thumbsSwiper: null,
      mainSwiperOptions: {
        initialSlide: this.activeFileIndex,
        keyboard: {
          enabled: true
        },
        centeredSlides: true,
        spaceBetween: 10,
        watchOverflow: true
      },
      swiperOptionThumbs: {
        initialSlide: this.activeFileIndex,
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      }
    };
  },

  mounted () {
    this.isVisible = true;

    this.$nextTick(() => {
      const { mainSwiper, thumbsSwiper } = this.$refs;

      mainSwiper.$swiper.controller.control = thumbsSwiper.$swiper;
      thumbsSwiper.$swiper.controller.control = mainSwiper.$swiper;
      this.mainSwiper = mainSwiper.$swiper;
      this.thumbsSwiper = thumbsSwiper.$swiper;
    });
  },

  methods: {
    isActiveIndex (index) {
      return this.mainSwiper && index === this.mainSwiper.activeIndex;
    },

    closeSwiper () {
      this.$emit('close');
    },

    prevSlide () {
      this.mainSwiper?.slidePrev();
    },

    nextSlide () {
      this.mainSwiper?.slideNext();
    }
  }
};
</script>

<style scoped lang="scss">
$--height: 60vh;

.file-preview-dialog {
  .file-preview {
    display: flex;
    justify-content: center;
    //TODO: fix this spike
    width: 100% !important;
    height: 100% !important;
  }

  ::v-deep {
    .swiper-container {
      max-height: $--height;

      .swiper-wrapper {
        max-height: $--height;
      }

      .swiper-slide {
        max-height: $--height;
      }

      .block-size {
        height: $--height !important;
      }
    }
  }

  .dialog-wrapper {
    display: flex;
    align-items: center;

    ::v-deep .el-dialog__headerbtn .el-dialog__close {
      color: white;
      font-size: 2rem;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;

    div {
      width: 100%;
    }
  }

  .gallery-top {
    width: 100%;

    .swiper-slide .preview-other {
      margin: auto;
      height: 60px;
      width: 60px;
    }
  }

  .gallery-thumbs {
    width: 90%;
    height: 120px;
    box-sizing: border-box;
    padding: 10px 0;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      width: 15%;
      opacity: 0.4;

      div {
        width: auto;
      }
    }

    .swiper-slide-active {
      opacity: 1;
    }
  }
}
</style>
