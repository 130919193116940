<template>
  <div class="search-articles-page" @click="isOrdering = false">
    <el-row justify="space-between" align="middle">
      <div>
        <WareTitle
          v-if="!isMobile && ware"
          v-loading="isWareLoading"
          :use-br="isMobile"
          :double-name="isMobile"
          :ware="ware"
        />
      </div>

      <el-row
        v-if="!isMobile && !isArticlesLoading && !isEmptyResult"
        align="middle"
      >
        <template v-if="tab === 'map'">
          <el-button
            circle
            icon="el-icon-setting"
            round
            size="mini"
            @click="isSearchSettings = true"
          />

          <div
            v-if="!$route.query.waa && !isMobile"
            class="description map-description ml-1 mr-1 bold"
          >
            {{
              $t(
                `search.${mapSearchSettings.limit_by_order_by.replace(
                  /,/g,
                  "-"
                )}`
              )
            }}.
          </div>
        </template>

        <el-button-group>
          <el-button
            icon="el-icon-s-grid"
            size="mini"
            :type="tab === 'tables' ? 'primary' : ''"
            @click="tab = 'tables'"
          >
            {{ $t("buttons.likeList") }}
          </el-button>

          <el-button
            icon="el-icon-map-location"
            size="mini"
            :type="tab === 'map' ? 'primary' : ''"
            @click="tab = 'map'"
          >
            {{ $t("buttons.onMap") }}
          </el-button>
        </el-button-group>
      </el-row>
    </el-row>

    <TheLoader v-if="isArticlesLoading" />

    <el-container v-else>
      <el-header v-if="isMobile" class="pg-0" height="fit-content">
        <WareShortInfo
          v-if="ware"
          v-loading="isWareLoading"
          is-mobile
          row
          class="mb-2"
          :ware="ware"
          @info:show="showFullInfo"
        />

        <el-row align="middle" justify="space-between" class="filters-btns">
          <div class="fl-row mr-2">
            <div class="fl-row mr-1" @click="isFilter = !isFilter">
              {{ $t("search.filter") }}
              <img class="icon-img ml-05" src="@/assets/icons/filter.svg">
            </div>

            <CustomSelect :label="$t('search.order')">
              <template #menu="{ isMenu }">
                <div v-if="isMenu">
                  <div
                    :class="[
                      'menu__item',
                      { 'is-active': ordering[0] === 'delivery_time' },
                    ]"
                    @click="toggleOrdering(['delivery_time', '-rank'])"
                  >
                    {{ $t("search.term") }}
                  </div>

                  <div
                    :class="[
                      'menu__item',
                      { 'is-active': ordering[0] === 'price' },
                    ]"
                    @click="toggleOrdering(['price', '-rank'])"
                  >
                    {{ $t("search.price") }}
                  </div>
                </div>
              </template>
            </CustomSelect>
          </div>

          <el-row align="middle" class="buttons-container">
            <div class="mr-05">{{ $t("search.look") }}</div>

            <div v-if="tab === 'map'" @click="isSearchSettings = true">
              <img
                class="icon-img ml-05"
                src="@/assets/icons/icon-setting.svg"
              >
            </div>

            <div class="active-svg" @click="tab = 'tables'">
              <img
                v-if="tab === 'tables'"
                class="icon-img ml-05"
                src="@/assets/icons/list-view__active.svg"
              >
              <img
                v-else
                class="icon-img ml-05"
                src="@/assets/icons/list-view.svg"
              >
            </div>

            <div @click="tab = 'map'">
              <img
                v-if="tab === 'map'"
                class="icon-img ml-05"
                src="@/assets/icons/map-view__active.svg"
              >
              <img
                v-else
                class="icon-img ml-05"
                src="@/assets/icons/map-view.svg"
              >
            </div>
          </el-row>
        </el-row>
      </el-header>

      <el-container>
        <el-aside
          v-if="!isArticlesLoading && !isMobile"
          width="260px"
          class="filter-block"
        >
          <WareShortInfo
            v-if="ware"
            class="mb-2"
            :ware="ware"
            @info:show="showFullInfo"
          />
          <SearchFilter is-visible />
        </el-aside>

        <div class="el-main pg-0">
          <EmptySearchResult
            v-if="isEmptyResult"
            :params="$route.params"
            :ware="ware"
          />

          <template v-else>
            <keep-alive>
              <SearchArticlesTable
                v-show="tab === 'tables'"
                ref="table"
                :analogs-res="analogsRes"
                :selected-articles-res="selectedArticlesRes"
                :ordering="ordering"
                :class="{ 'is-mobile': isMobile }"
                @basket-click="submitOrder"
                @info:show="showFullInfo"
                @sale-point:info="salePointInfo = $event"
                @update:page="updatePage"
                @toggle-ordering="toggleOrdering"
              />
            </keep-alive>

            <keep-alive>
              <WareSalePointsMap
                v-if="tab === 'map' && !isArticlesLoading"
                v-show="tab === 'map'"
                ref="map"
                :trademarks="trademarkById"
              />
            </keep-alive>
          </template>
        </div>
      </el-container>
    </el-container>

    <SearchFilterM
      v-if="isMobile"
      :visible="isFilter"
      @close="isFilter = false"
      @submitted="isFilter = false"
    />

    <template v-if="$store.getters.isAppLoaded">
      <TheDialog v-model="wareInfoId">
        <WareInfo :id="wareInfoId" @cancel="wareInfoId = null" />
      </TheDialog>

      <TheDialog
        v-model="isSearchSettings"
        width="585px"
        :title="$t('search.searchSettings')"
      >
        <SearchMapSettings @cancel="isSearchSettings = false" />
      </TheDialog>

      <TheDialog v-model="checkPoints" width="1400px">
        <WareCheckPoints
          v-if="checkPoints"
          :ware="checkPoints.ware"
          :points="checkPoints.otherPoints"
          @select="
            checkPoints = null;
            salePointInfo = $event;
          "
        />
      </TheDialog>

      <TheDialog v-show="!checkPoints" v-model="salePointInfo" width="800px">
        <template v-if="salePointInfo" #title>
          <div class="custom-headline custom-headline__dialog">
            <span class="bold">
              {{ get(salePointInfo, "ware.display_trademark", "") }}
              {{ get(salePointInfo, "ware.display_article", "") }},
            </span>
            <span>{{ get(salePointInfo, "ware.display_title", "") }}</span>
          </div>
        </template>

        <template #default>
          <WareSalePointInfo
            v-if="salePointInfo"
            :id="salePointInfo.id"
            v-loading="isSubmitOrderLoading"
            :ware="salePointInfo.ware"
            affiliation-list-name="Search Results"
            @check-points="checkPoints = $event"
            @basket-click="submitOrder"
          />
        </template>
      </TheDialog>
    </template>
  </div>
</template>

<script>
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';

import isEqual from 'lodash/isEqual';
import CustomSelect from '../core/components/CustomSelect';
import getSearchParams from './services/getSearchParams';
import loadArticles from './services/loadArticles';
import emitArticleBreadcrumbs from './services/emitArticleBreadcrumbs';

import SearchFilter from './components/SearchFilter';
import SearchArticlesTable from './components/SearchArticlesTable';
import WareTitle from './components/WareTitle';
import addWareToBasket from '@/lib/orders/services/addWareToBasket';
import { eventBus, env, getDefault } from '@/lib/core';

export default {
  name: 'SearchArticlesPage',

  middleware: ['dependencies'],

  components: {
    CustomSelect,
    WareTitle,
    SearchFilter,
    SearchArticlesTable,
    /* eslint-disable max-len */
    WareShortInfo: () =>
      import(
        /* webpackChunkName: "WareShortInfo" */ './components/WareShortInfo'
      ),
    WareSalePointsMap: () =>
      import(
        /* webpackChunkName: "WareSalePointsMap" */ './components/WareSalePointsMap'
      ),
    WareInfo: () =>
      import(
        /* webpackChunkName: "WareInfo" */ './components/wareInfo/WareInfo'
      ),
    SearchFilterM: () =>
      import(
        /* webpackChunkName: "SearchFilterM" */ './components/SearchFilterM'
      ),
    SearchMapSettings: () =>
      import(
        /* webpackChunkName: "SearchMapSettings" */ './components/SearchMapSettings'
      ),
    WareSalePointInfo: () =>
      import(
        /* webpackChunkName: "WareSalePointInfo" */ '@/lib/salePoints/components/WareSalePointInfo'
      ),
    WareCheckPoints: () =>
      import(
        /* webpackChunkName: "WareCheckPoints" */ '@/lib/salePoints/components/WareCheckPoints'
      ),
    EmptySearchResult: () =>
      import(
        /* webpackChunkName: "EmptySearchResult" */ '@/lib/core/components/EmptySearchResult'
      )
    /* eslint-enable max-len */
  },

  props: {
    wareIndex: String
  },

  data () {
    return {
      isOrdering: false,
      isSubmitOrderLoading: false,
      isWareLoading: false,
      isArticlesLoading: true,
      isSearchSettings: false,
      isFilter: false,
      isShowScroll: false,

      tab: 'tables',
      wareId: null,
      ware: null,

      wareInfoId: null,
      salePointInfo: null,
      checkPoints: null,

      selectedArticlesRes: getDefault('articlesRes'),
      analogsRes: getDefault('articlesRes')
    };
  },

  computed: {
    ...mapGetters({
      businessProfile: 'businessProfiles/currentBusinessProfile',
      mapSearchSettings: 'search/mapSearchSettings',
      user: 'users/user'
    }),

    isMobile () {
      return this.maxmq('md');
    },

    ordering: {
      get () {
        const og = this.$route.query.og;
        return og ? og.split(',') : [];
      },

      set (value) {
        // if result more then PAGINATION_SIZE
        const { og, ...query } = this.$route.query;

        if (!isEmpty(value)) {
          query.og = value.toString();
        }

        this.$router.push({ query });
      }
    },

    priceTags () {
      const tags = this.$store.getters['dependencies/priceTags'];

      return tags.reduce((acc, tag) => {
        acc[tag.label] = tag.id;
        return acc;
      }, {});
    },

    userCode () {
      return this.$store.getters.userCode;
    },

    trademarkById () {
      return keyBy(this.analogsRes.trademarks, 'id');
    },

    isEmptyResult () {
      return (
        isEmpty(this.analogsRes.results) &&
        isEmpty(this.selectedArticlesRes.results)
      );
    },

    wareTitle () {
      const { trademark, article, name } = this.ware;
      return this.ware ? `${trademark?.name} ${article} ${name}` : '';
    },

    watchQuery () {
      const { wareId, wareIndex, trademark, article } = this.$route.query;
      return [wareId, wareIndex, trademark, article];
    }
  },

  watch: {
    watchQuery () {
      if (!this.isArticlesLoading) {
        this.load();
      }
    }
  },

  async mounted () {
    eventBus.$on('filter:updated', this.loadArticles);
    await this.load();
  },

  beforeDestroy () {
    eventBus.$off('filter:updated', this.loadArticles);
  },

  methods: {
    get,

    getParsed (list) {
      const isWholesale = this.isWholesaleBuyer();
      const isTheSameSeller = item =>
        this.businessProfile &&
        item.seller?.id === this.businessProfile?.seller?.id;

      return list.map((item) => {
        return {
          ...item,
          _rank: item.seller?.rank || 0,
          _isShowRetail: isWholesale && this.canShowRetailSuffix(item),
          _priceFileName:
            isTheSameSeller(item) && get(item, 'price_file.name', '')
        };
      });
    },

    toggleOrdering (_ordering) {
      this.isOrdering = false;

      const { page, og, ...query } = this.$route.query;

      if (!isEqual(_ordering, this.ordering)) {
        query.og = _ordering.toString();
      }

      eventBus.$emit('filter:reload-page', { query });
    },

    canShowRetailSuffix (item) {
      const priceTagsIds = get(item, 'price_def.tags', []).map(({ id }) => id);
      return priceTagsIds.includes(this.priceTags.retail);
    },

    async load () {
      const [ware] = await Promise.all([this.loadWare(), this.loadArticles()]);

      emitArticleBreadcrumbs(this, { ware });
    },

    async loadWare () {
      const id = this.$route.query.wareId;
      let ware = null;

      if (!id || this.wareId === Number(id)) {
        return null;
      }

      this.isWareLoading = true;

      try {
        ware = await this.$store.dispatch('cache/get', {
          action: 'seo/getWareMeta',
          payload: { id }
        });

        this.wareId = id;
        this.ware = ware;
      } catch (e) {
        console.error(e);
        this.wareId = null;
        this.ware = null;
      } finally {
        this.isWareLoading = false;
      }

      return this.ware;
    },

    async loadArticles () {
      const { params, query } = this.$route;

      if (isEmpty(query)) {
        this.isArticlesLoading = false;
        return null;
      }

      this.isArticlesLoading = true;

      const searchParams = getSearchParams(params, query, this.$store);

      const resParams = {
        params,
        query,
        store: this.$store,
        searchParams,
        hideAnalogs: !query.wareId
      };

      // use for count data on server
      const aggs = 'trademarks,price,delivery_time';

      if (resParams.hideAnalogs) {
        resParams.searchParams.selectedArticlesAggs = aggs;
        resParams.searchParams.selectedArticlesLimit = 0;
      } else {
        resParams.searchParams.aggs = aggs;
      }

      const { selectedArticlesRes, analogsRes } = await loadArticles(resParams);

      if (this.user && isEmpty(this.priceTags)) {
        await this.$store.dispatch('dependencies/fetchPriceTags');
      }

      analogsRes.results = this.getParsed(analogsRes.results);
      selectedArticlesRes.results = this.getParsed(selectedArticlesRes.results);

      if (resParams.hideAnalogs) {
        this.analogsRes = selectedArticlesRes;
      } else {
        this.selectedArticlesRes = selectedArticlesRes;

        this.analogsRes = analogsRes;
      }

      this.isArticlesLoading = false;

      this.$nextTick(() => {
        eventBus.$emit('filter:update:res-params', this.analogsRes);
      });

      return { selectedArticlesRes, analogsRes };
    },

    isWholesaleBuyer () {
      let tags = [];

      if (!this.userCode) {
        return false;
      } else if (this.userCode.startsWith('u')) {
        tags = get(this.user, 'profile.buyer.price_tags', []);
      } else {
        const business =
          this.$store.getters['businessProfiles/currentBusinessProfile'];
        tags = get(business, 'buyer.price_tags', []);
      }

      return tags.some(({ label }) => label === 'wholesale');
    },

    showFullInfo (ware) {
      this.wareInfoId = ware.ware_id || get(ware, 'ware.id') || ware.id;
    },

    toggleScrollBtn (isVisible) {
      this.isShowScroll = !isVisible;
    },

    async submitOrder (ware) {
      this.isSubmitOrderLoading = ware.id;

      await addWareToBasket(
        this,
        ware,
        {},
        { from: 'Search Results', emitViewItemEvent: true }
      );

      this.salePointInfo = null;
      this.isSubmitOrderLoading = false;
    },

    updatePage (page) {
      this.$router
        .replace({ query: { ...this.$route.query, page } })
        .then(() => this.loadArticles())
        .catch(e => e);
    },

    isActiveOrdering (value) {
      return this.ordering[0] === value;
    }
  },

  head () {
    const { trademark, article } = this.$route.query;

    const search = trademark && article ? `${trademark} ${article} ` : '';

    return {
      title: this.$t('search.searchTitle', {
        search,
        mp: env.BASE_CLIENT_PATH
      }),
      meta: [{ name: 'robots', content: env.ROBOTS_VALUES }]
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/search-block.scss";

.icon-img {
  width: 16px;
  height: 16px;
}

.fl-row {
  display: flex;
  align-items: center;
}

::v-deep {
  .is-mobile .table-title {
    background-color: $grey-300;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

.filters-btns {
  border-top: 1px solid $grey-300 !important;
  border-bottom: 1px solid $grey-300 !important;
  padding: 10px 0;
}
</style>
