var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "juridical-person-info" },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("requisites.info.egrpoy"), prop: "udrpou" } },
        [
          _c("el-input", {
            staticClass: "max-w-140p",
            attrs: { value: _vm.value.udrpou },
            on: {
              input: function ($event) {
                return _vm.emit("udrpou", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.officialName"),
            prop: "official_name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-420p",
            attrs: { value: _vm.value.official_name },
            on: {
              input: function ($event) {
                _vm.emit("official_name", $event)
                _vm.setShortName(_vm.value.short_name)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.shortName"),
            prop: "short_name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-210p",
            attrs: { value: _vm.value.short_name },
            on: { input: _vm.setShortName },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: { label: _vm.$t("requisites.info.pdv"), prop: "is_vat_payer" },
        },
        [
          _c(
            "el-radio-group",
            {
              attrs: { value: _vm.value.is_vat_payer },
              on: {
                input: function ($event) {
                  return _vm.emit("is_vat_payer", $event)
                },
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "mt-1" },
                [
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v(_vm._s(_vm.$t("core.yes"))),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v(_vm._s(_vm.$t("core.no"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.value.is_vat_payer
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("requisites.info.certificate"),
                prop: "itn_of_vat_payer",
              },
            },
            [
              _c("el-input", {
                staticClass: "max-w-182p",
                attrs: { value: _vm.value.itn_of_vat_payer },
                on: {
                  input: function ($event) {
                    return _vm.emit("itn_of_vat_payer", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.address"),
            prop: "juridical_address",
            required: "",
          },
        },
        [
          _c(
            "Address",
            _vm._g(
              {
                attrs: {
                  "hide-description-input": "",
                  "hide-label": "",
                  "hide-map": "",
                  "hide-no-address": "",
                  "has-physical-address": "",
                  prop: "juridical_address",
                  value: _vm.value.juridical_address,
                },
              },
              _vm.onAddressUpdate
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.signatory_full_name"),
            prop: "signatory_full_name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-420p",
            attrs: { value: _vm.value.signatory_full_name },
            on: {
              input: function ($event) {
                return _vm.emit("signatory_full_name", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }