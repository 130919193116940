var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-form" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.formData, rules: _vm.rules, size: "medium" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("auth.login"), prop: "login" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    _vm.MARKETPLACE_PHONE_PREFIX +
                    "... " +
                    _vm.$t("auth.or") +
                    " " +
                    _vm.$t("auth.email"),
                },
                model: {
                  value: _vm.formData.login,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "login",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.login",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("auth.password"), prop: "password" } },
            [
              _c(
                "el-input",
                {
                  attrs: { type: _vm.isShowPass ? "" : "password" },
                  model: {
                    value: _vm.formData.password,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "password",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formData.password",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-view c-pointer",
                    attrs: { slot: "suffix" },
                    on: {
                      click: function ($event) {
                        _vm.isShowPass = !_vm.isShowPass
                      },
                    },
                    slot: "suffix",
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mb-2" },
            [
              _c(
                "el-row",
                { attrs: { align: "middle", justify: "space-between" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formData.remember_me,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "remember_me", $$v)
                        },
                        expression: "formData.remember_me",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("auth.remember")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: {
                          name: _vm.getRouteName("auth.pass-send"),
                          query: { login: _vm.formData.login },
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("auth.forgetPassword")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-row",
                {
                  attrs: {
                    justify: _vm.showCancelBtn ? "space-between" : "center",
                  },
                },
                [
                  _vm.showCancelBtn
                    ? _c(
                        "el-button",
                        {
                          staticClass: "submit-btn",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("cancel")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.nix")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "submit-btn",
                      attrs: {
                        loading: _vm.isLoading,
                        "native-type": "submit",
                        type: "primary",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("buttons.goEnter")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSocialLinks
        ? [
            _c("ElDivider", [_vm._v(_vm._s(_vm.$t("auth.or")))]),
            _vm._v(" "),
            _c("SignInLinks", { on: { registered: _vm.showProfilesOrGoHome } }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isProfiles
        ? _c("ProfileSelect", {
            on: { close: _vm.onCloseProfile, select: _vm.onSelectProfile },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }