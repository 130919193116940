var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buyer-order-items-table" },
    [
      _c(
        "el-table",
        {
          attrs: {
            "row-key": "id",
            "cell-style": { textAlign: "center" },
            data: _vm.value,
            "show-header": false,
            "header-cell-style": { textAlign: "center" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { width: "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("BlockSize", {
                      attrs: { size: "ls", src: row.image_url },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("basket.display_article"),
              prop: "display_article",
              width: "250px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-row",
                      { staticClass: "article-row" },
                      [
                        _c("div", { staticClass: "mr-1 bold" }, [
                          _vm._v(_vm._s(row.display_trademark)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          {
                            staticClass: "article-title",
                            attrs: { align: "middle" },
                          },
                          [
                            _c(
                              "el-link",
                              {
                                staticClass: "mr-1",
                                attrs: {
                                  target: "_blank",
                                  type: "primary",
                                  href: row.ware_card_url,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.article
                                        ? row.display_article
                                        : "*****"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            row.display_article
                              ? _c("BufferCopy", {
                                  attrs: { value: row.display_article },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("basket.display_title"),
              "min-width": "200px",
              prop: "display_title",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label:
                "" +
                _vm.$t("basket.delivery_time") +
                (_vm.withDescription ? "*" : ""),
              prop: "delivery_time",
              width: "120px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("DeliveryTimeString", {
                      class: row._notFound ? "line-through" : "",
                      attrs: {
                        "hide-color": "",
                        value: row.delivery_time,
                        options: { ware: row, useDateIfDaysMore: 2 },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.priceLabel,
              prop: "price",
              align: "right",
              width: "150px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-row",
                      { attrs: { justify: "end" } },
                      [
                        _c("ThePrice", {
                          class: { "line-through": row._notFound },
                          attrs: { value: _vm.getPriceNum(row.price) },
                        }),
                        _vm._v(" "),
                        _c(
                          "TheTooltip",
                          {
                            attrs: {
                              content:
                                _vm.$t("basket.prev_price") +
                                ": " +
                                _vm.getPriceNum(row.prev_price),
                            },
                          },
                          [
                            !row._notFound && row.price && row.prev_price
                              ? _c("span", { staticClass: "price-suffix" }, [
                                  row.price > row.prev_price
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-caret-top color-red",
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-caret-bottom color-green",
                                      }),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label:
                "" +
                _vm.$t("basket.quantity") +
                (_vm.withDescription ? "***" : ""),
              prop: "quantity",
              width: "150px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "TheTooltip",
                      {
                        attrs: {
                          content:
                            "\n          " +
                            _vm.$t("orders.stockQuantity") +
                            ":\n           " +
                            (row.stock_quantity === null
                              ? 1000000
                              : row.stock_quantity) +
                            "\n           ",
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            disabled: row._notFound,
                            max:
                              row.stock_quantity === null
                                ? 1000000
                                : row.stock_quantity,
                            min: row._notFound ? 0 : 1,
                            size: "mini",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.emitUpdateQuantity(row)
                            },
                          },
                          model: {
                            value: row.quantity,
                            callback: function ($$v) {
                              _vm.$set(row, "quantity", $$v)
                            },
                            expression: "row.quantity",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-button", {
                      staticClass: "delete",
                      attrs: {
                        circle: "",
                        icon: "el-icon-delete",
                        size: "mini",
                        type: row._notFound ? "danger" : "default",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("item:remove", row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mt-1", attrs: { justify: "end", align: "middle" } },
        [
          _c("ThePrice", { attrs: { value: _vm.total, size: "large" } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("basket.toBePaid")) + ":\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }