var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "completion-auth" },
    [
      _c(
        "span",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("auth.thanksForRegistration")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("el-row", { staticClass: "buttons mt-4 mb-4" }, [
        _c(
          "div",
          { staticClass: "block" },
          [
            _c(
              "nuxt-link",
              { attrs: { to: { name: _vm.getRouteName("home") } } },
              [
                _c("el-button", { attrs: { type: "primary" } }, [
                  _vm._v(_vm._s(_vm.$t("auth.iAmOnlyBy"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("auth.youCanByNow")))]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block" },
          [
            _c(
              "nuxt-link",
              {
                attrs: {
                  to: { name: _vm.getRouteName("profile.business.create") },
                },
              },
              [
                _c("el-button", { attrs: { type: "danger" } }, [
                  _vm._v(_vm._s(_vm.$t("auth.iAmSeller"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("auth.anyShopCanBy")))]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }