<template>
  <div class="sale-point-trademarks-tab">
    <div class="description mb-1">
      <p>{{ $t('sellerTrademarks.sellerTrademarksDescription') }}</p>
      <p>{{ $t('sellerTrademarks.sellerTrademarksConfirmDescription') }}</p>
    </div>

    <el-row class="mb-2" justify="end">
      <el-button size="mini" type="primary" @click="isDialog = true">
        {{ $t('buttons.addTrademark') }}
      </el-button>
    </el-row>

    <SellerTrademarkTable
      :load="loadTrademarks"
      :prop-value="tableData"
      @row-click="showTrademark"
    />

    <TheDialog
      v-model="isDialog"
      :title="$t(`sellerTrademarks.${trademark ? 'edit' : 'create'}Tm`)"
    >
      <SellerTrademarkForm
        :value="trademark"
        @cancel="hidePopup"
        @created="addItemToList"
        @removed="removeItem"
        @updated="updateElem"
      />
    </TheDialog>
  </div>
</template>

<script>
import SellerTrademarkForm from './SellerTrademarkForm';
import SellerTrademarkTable from './SellerTrademarkTable';

export default {
  name: 'SalePointTrademarksTab',

  components: {
    SellerTrademarkForm,
    SellerTrademarkTable
  },

  data: () => ({
    isDialog: false,
    tableData: [],
    trademark: null
  }),

  computed: {
    businessId () {
      return this.$store.getters['businessProfiles/businessId'];
    }
  },

  methods: {
    async loadTrademarks () {
      try {
        this.tableData = await this.$store
          .dispatch('sellerTrademarks/getTrademarks', {
            business: this.businessId
          });
      } catch (e) {
        console.error(e);
      }
    },

    showTrademark (trademark) {
      this.trademark = trademark;
      this.isDialog = true;
    },

    hidePopup () {
      this.trademark = null;
      this.isDialog = false;
    },

    addItemToList (trademark) {
      this.tableData.push(trademark);
      this.hidePopup();
    },

    removeItem (trademark) {
      const index = this.tableData.findIndex(it => it.id === trademark.id);
      this.tableData.splice(index, 1);
      this.hidePopup();
    },

    updateElem (trademark) {
      const index = this.tableData.findIndex(it => it.id === trademark.id);

      if (index > -1) {
        this.$set(this.tableData, index, trademark);
      }

      this.hidePopup();
    }
  }
};
</script>

<style scoped lang="scss">
.sale-point-trademarks-tab {
  .el-button {
    min-width: 150px;
  }
}
</style>
