var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "shipment-form-dialog",
      attrs: {
        title: _vm.$t("basket.orderShipment"),
        visible: "",
        width: "1000px",
      },
      on: { close: _vm.close },
    },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "top",
                model: _vm.formData,
                disabled: !!_vm.formData.id || _vm.isVLoading,
                rules: _vm.rules,
                size: "large",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "mb-2 bold" }, [
                  _vm._v(_vm._s(_vm.$t("basket.orderAddress"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-block" },
                  [
                    _c(
                      "ShipmentOrderDelivery",
                      _vm._b(
                        {
                          ref: "orderDelivery",
                          attrs: {
                            value: _vm.formData,
                            dependency: _vm.dependency,
                            "set-field": _vm.setField,
                          },
                        },
                        "ShipmentOrderDelivery",
                        _vm.formData,
                        false,
                        true
                      )
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "mb-2 bold" }, [
                  _vm._v(_vm._s(_vm.$t("placeholders.comment"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-block" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0", prop: "comment" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("basket.shipmentComment"),
                            rows: "5",
                            type: "textarea",
                          },
                          model: {
                            value: _vm.formData.comment,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "comment", $$v)
                            },
                            expression: "formData.comment",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cancel")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("buttons.cancel")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.isVLoading,
                        "native-type": "submit",
                        type: "primary",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("buttons.save")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }