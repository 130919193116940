var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-time-picker", {
    class: ["time-selector", { readonly: _vm.readonly }],
    attrs: { align: "center", clearable: false, format: "HH:mm", size: "mini" },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v
      },
      expression: "time",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }