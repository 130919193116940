<template>
  <div class="order-delivery">
    <div class="delivery-method">
      <div class="mr-1">
        <el-form-item
          class="block-title"
          :label="$t('basket.delivery_method')"
          prop="delivery_method"
        >
          <el-radio-group
            :value="deliveryMethod"
            @input="toggleDeliveryMethod"
          >
            <el-radio
              v-for="method in deliveryMethods"
              :key="method.key"
              :label="method.key"
            >
              {{ method.title }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div v-if="componentInfo">
        <component
          :is="componentInfo.component"
          ref="component"
          class="mb-1"
          :set-field="setField"
          :value="value.delivery_address"
          :type="value.delivery_address && value.delivery_address.type"
          :dependency="{ ...dependency, addresses: addressesByType }"
          v-bind="componentInfo.attrs"
        />
      </div>
    </div>

    <div v-if="componentInfo && componentInfo.isRecipient">
      <div class="mb-1 bold">{{ $t('basket.orderRecipient') }}</div>

      <OrderRecipient
        ref="recipient"
        :value="value"
        :recipients="recipients"
        :set-field="setField"
        :dependency="dependency"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import AddressSelect from './AddressSelect';
import DeliveryServiceSelect from './DeliveryServiceSelect';
import OrderRecipient from './OrderRecipient';
import TaxiDelivery from './TaxiDelivery';
import SalePointAddress from './SalePointAddress';
import orderFormMixin from '@/lib/orders/mixins/orderFormMixin';
import { getDefault, parsers } from '@/lib/core';
import getAddressLabel from '@/lib/orders/services/getConcatedDeliveryAddress';
import getCRLabel from '@/lib/orders/services/getConcatedRecipientLabel';
import BlockSize from '@/lib/core/components/BlockSize';

const setMethodsNames = {
  pickup_address: 'setDefaultPickupData',
  taxi_address: 'setDefaultTaxiData',
  courier_address: 'setDefaultTaxiData',
  carrier_in_country_service_office: 'setDefaultServiceOfficeData',
  carrier_in_country_address: 'setDefaultServiceOfficeAddressData',
  regular_bus_address: 'setDefaultServiceOfficeAddressData'
};

export default {
  name: 'OrderDelivery',

  mixins: [orderFormMixin],

  components: {
    BlockSize,
    AddressSelect,
    DeliveryServiceSelect,
    OrderRecipient,
    TaxiDelivery,
    SalePointAddress
  },

  props: {
    value: { type: Object, required: true },
    setField: { type: Function, required: true },
    dependency: { type: Object, required: true }
  },

  data () {
    return {
      addresses: {
        address: [],
        service_office: []
      },
      recipients: []
    };
  },

  computed: {
    addressesByType () {
      return this.addresses[this.value.delivery_address?.type] || [];
    },

    deliveryMethod () {
      return this.value.delivery_method
        ? `${this.value.delivery_method}.${this.value.delivery_address?.type}`
        : null;
    },

    deliveryOptions () {
      return [
        {
          title: this.$t('delivery_methods.pickup'),
          method: 'pickup',
          type: 'address',
          key: 'pickup.address',
          component: 'SalePointAddress',
          attrs: {
            address: this.dependency.salePoint?.address
          }
        },
        {
          title: this.$t('delivery_methods.taxi'),
          method: 'taxi',
          type: 'address',
          key: 'taxi.address',
          component: 'TaxiDelivery',
          attrs: {
            'sale-point-address': this.dependency.salePoint?.address
          }
        },
        {
          title: this.$t('delivery_methods.courier'),
          method: 'courier',
          type: 'address',
          key: 'courier.address',
          component: 'TaxiDelivery',
          attrs: {
            'sale-point-address': this.dependency.salePoint?.address
          }
        },
        {
          title: this.$t('deliveryServicesType.service_office'),
          method: 'carrier_in_country',
          type: 'service_office',
          key: 'carrier_in_country.service_office',
          component: 'DeliveryServiceSelect',
          isRecipient: true
        },
        {
          title: this.$t('deliveryServicesType.address'),
          method: 'carrier_in_country',
          type: 'address',
          key: 'carrier_in_country.address',
          component: 'DeliveryServiceSelect',
          isRecipient: true
        },
        {
          title: this.$t('delivery_methods.regular_bus'),
          method: 'regular_bus',
          type: 'address',
          key: 'regular_bus.address',
          component: 'DeliveryServiceSelect',
          isRecipient: true
        }
      ];
    },

    componentInfo () {
      const key = `${this.value.delivery_method}.${this.value.delivery_address?.type}`;
      return this.deliveryOptions.find(item => item.key === key);
    },

    deliveryMethods () {
      const { delivery_methods, address } = this.dependency;

      if (delivery_methods.length) {
        return this.deliveryOptions
          .filter(({ method }) => delivery_methods.includes(method));
      } else if (address && address.has_physical_address) {
        return ['pickup'];
      }

      return ['carrier_in_country'];
    },

    city () {
      return this.$store.getters.city;
    }
  },

  mounted () {
    if (this.$store.getters.buyerId || this.forceLoad) {
      this.loadAddresses();
      this.loadRecipients();
    }
  },

  methods: {
    toggleDeliveryMethod (value) {
      const [method, type] = value.split('.');

      this.setField('delivery_method', method);
      this.setField('delivery_address.type', type);
      this.setField('delivery_address.id');

      this[setMethodsNames[`${method}_${type}`]]({ method, type });
    },

    setDefaultPickupData ({ type }) {
      if (!this.dependency.salePoint?.address) {
        return null;
      }

      const { id, url, ...address } = this.dependency.salePoint?.address;

      const payload = {
        ...cloneDeep(address),
        type,
        _label: getAddressLabel(address)
      };

      this.setAddress(payload);
    },

    setDefaultTaxiData ({ type }) {
      if (
        this.addresses.address.length === 1 &&
        this.addresses.address[0].city === this.city.url
      ) {
        return this.setAddress(cloneDeep(this.addresses.address[0]));
      }

      const payload = { ...getDefault('deliveryAddress'), type };

      this.setAddress(payload);
      this.setCurrentCity();
    },

    setDefaultServiceOfficeData ({ type }) {
      if (
        this.city?.url &&
        (
          this.addresses.service_office.length === 1 &&
          this.addresses.service_office[0]?.city === this.city?.url
        )
      ) {
        this.setAddress(cloneDeep(this.addresses.service_office[0]));
      } else {
        const payload = { ...getDefault('deliveryAddress'), type };
        this.setAddress(payload);
        this.setCurrentCity();
      }

      this.setDefaultRecipient();
    },

    setDefaultServiceOfficeAddressData ({ type }) {
      const city = this.dependency.salePoint.address.city;

      if (
        this.addresses.address.length === 1 &&
        this.addresses.address[0].city === city
      ) {
        this.setAddress(cloneDeep(this.addresses.address[0]));
      } else {
        const payload = { ...getDefault('deliveryAddress'), type };
        this.setAddress(payload);
        this.setCurrentCity();
      }

      this.setDefaultRecipient();
    },

    setCurrentCity () {
      const city = this.city;
      const region = this.dependency.regionDetail;

      if (city) {
        this.setField('delivery_address.city', city?.url);
        this.setField('delivery_address.city_detail', {
          name: city.name,
          full_name: city.full_name,
          id: city.id
        });
      }

      if (region) {
        this.setField('delivery_address.region', region.url);
        this.setField('delivery_address.region_detail', {
          name: region.name,
          full_name: region.full_name,
          id: region.id
        });
      }
    },

    setDefaultRecipient () {
      const user = this.$store.getters['users/user'];
      const business = this.$store.getters['businessUsers/currentBusinessUser'];

      if (!user) {
        return this.setField(
          'recipient_phone_number',
          cloneDeep(this.value.buyer_phone_number)
        );
      }

      const {
        last_name,
        first_name
      } = this.recipients[0] || business || user.profile;

      const { phone_numbers } = business || user.profile;

      let phone = null;

      if (this.recipients[0]?.phone_number) {
        phone = this.recipients[0].phone_number;
      } else if (phone_numbers.length) {
        phone = parsers.getPrimaryOrFirst(phone_numbers, 'phone');
      } else {
        phone = this.value.buyer_phone_number;
      }

      this.setField('recipient_first_name', first_name);
      this.setField('recipient_last_name', last_name);
      this.setField('recipient_phone_number', cloneDeep(phone));
    },

    async loadAddresses () {
      try {
        const addresses = await this.$store.dispatch(
          'buyerRecipientAddresses/getAddresses',
          { buyer: this.$store.getters.buyerId }
        );

        addresses.forEach((address) => {
          address._label = getAddressLabel(address);
          const _type = address?.type;
          this.addresses[_type] = this.addresses[_type] || [];
          this.addresses[_type]?.push(address);
        });
      } catch (e) {
        console.error(e);

        this.addresses = {
          address: [],
          service_office: []
        };
      }
    },

    async loadRecipients () {
      try {
        const recipients = await this.$store.dispatch(
          'buyerRecipients/getRecipients',
          { buyer: this.$store.getters.buyerId }
        );

        this.recipients = recipients.map(item => ({
          ...item,
          _label: getCRLabel(item)
        })
        );
      } catch (e) {
        console.error(e);
        this.recipients = [];
      }
    },

    setAddress (address) {
      this.emit('delivery_address', address);
    }
  }
};
</script>

<style scoped lang="scss">
.order-delivery {
  .el-radio-group {
    width: fit-content;
    background-color: $white;
    padding: 10px;
    border-radius: 10px;
  }

  .delivery-method {
    display: flex;
    flex-wrap: wrap;
  }

  ::v-deep {
    .sale-point-address, .taxi-delivery {
      .address {
        color: $black;
      }
    }

    .el-radio {
      display: flex;
      align-items: center;
    }
    .el-radio__label {
      display: inline-flex;
      flex-wrap: wrap;
    }
  }
}
</style>
