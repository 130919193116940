<template>
  <el-row :class="['open-street-map', 'search-point-map', { 'readonly' : readonly }]">
    <div :id="mapId" class="map" @click="$emit('click')" />

    <GeoInputs v-if="showInputs" :value="center" @input="emit" />
  </el-row>
</template>

<script>
import sortBy from 'lodash/sortBy';
import GeoInputs from './GeoInputs';
import { env } from '@/lib/core';

const defaultZoom = 8;
const defaultCloserZoom = 16;
const defaultLocation = { lat: 50.45113, lng: 30.52242 };
const duration = 0.5;
const defaultOptions = {
  maxZoom: 18,
  attribution: `
    &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>,
    &copy; <a href="https://carto.com/attribution">CARTO</a>
  `,
  center: defaultLocation
};

const toFix = val => val ? Number.parseFloat(val).toFixed(env.GEO_TO_FIXED) : null;

export default {
  name: 'SearchPointMap',

  components: {
    GeoInputs
  },

  props: {
    readonly: Boolean,
    showSearch: Boolean,
    queryString: String,
    showInputs: Boolean,
    value: { type: Object, default: () => ({}) },
    zoom: { type: Number, default: defaultCloserZoom },
    mapId: { type: String, default: 'preview' }
  },

  data () {
    return {
      l_queryString: ''
    };
  },

  computed: {
    center () {
      return this.value && this.value.lng && this.value.lat
        ? this.value
        : null;
    }
  },

  created () {
    this.l_queryString = this.queryString;
  },

  mounted () {
    this.initMap();
  },

  methods: {
    initMap () {
      const leaflet = require('leaflet');

      const container = leaflet.DomUtil.get(this.mapId);
      this.L = leaflet;

      if (container != null) {
        container._leaflet_id = null;
      }

      this.map = this.L.map(this.mapId);
      this.map.setView(defaultLocation, defaultZoom);
      this.tileLayer = this.L.tileLayer(env.OSM_LAYER_URL, defaultOptions);
      this.tileLayer.addTo(this.map);

      if (!this.readonly) {
        this.map.on('click', this.emitGeo);
      } else {
        this.disableEvents();
      }

      this.startValueWatch();
      this.goBySearch();
    },

    disableEvents () {
      this.map.touchZoom.disable();
      this.map.doubleClickZoom.disable();
      // this.map.scrollWheelZoom.disable();
      this.map.boxZoom.disable();
      // this.map.keyboard.disable();
    },

    startValueWatch () {
      this.$watch(() => this.value, () => {
        this.drawPoint(this.center);
      }, { deep: true, immediate: true });
    },

    goBySearch () {
      if (!this.center && this.l_queryString) {
        this.searchBy(this.l_queryString);
      }
    },

    emit (value) {
      this.$emit('input', value);
    },

    emitGeo (event) {
      this.emit({ lat: event.latlng.lat, lng: event.latlng.lng });
    },

    drawPoint (coords) {
      if (!this.layerGroup) {
        this.layerGroup = this.L.layerGroup().addTo(this.map);
      }

      this.layerGroup.clearLayers();

      if (coords) {
        const marker = this.L.marker([coords.lat, coords.lng]);

        if (coords.display_name) {
          marker.bindTooltip(coords.display_name, { direction: 'top' });
        }

        marker.addTo(this.layerGroup);
      }

      this.flyTo(coords || defaultLocation, coords ? this.zoom : defaultZoom);
    },

    flyTo ({ lat, lng }, zoom = this.zoom) {
      this.map.flyTo(new this.L.LatLng(lat, lng), zoom, { animate: true, duration });
    },

    async searchBy (query) {
      try {
        const points = await this.$store.dispatch('searchLocation/searchByQuery', { query });
        const [point] = sortBy(points, 'importance');

        if (point) {
          const lat = toFix(point.lat);
          const lng = toFix(point.lon);

          const marker = {
            place_id: point.place_id,
            display_name: point.display_name,
            lat,
            lng,
            icon: point.icon
          };

          this.drawPoint(marker);

          this.$emit('update:search:result', { lat, lng });
          return { lat, lng };
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.open-street-map {
  position: relative;
  height: 600px;
  width: 100%;
  border: 1px solid $grey-200;

  .map {
    height: 100%;
    width: 100%;
  }

  .geo-inputs {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 500;
  }
}
</style>
