<template>
  <div class="ware-info-variants">
    <TheLoader v-if="isLoading" />

    <WaresSmallTable v-else :value="variants" row-key="_id">
      <template #description="{ row }">
        <ul>
          <li
            v-for="(item, index) in row.nomenclature"
            :key="`name_${index}`"
            class="text-left"
          >
            - {{ item.product_line }}
            <i class="text-ghost">({{ item.name }})</i>
          </li>
        </ul>
      </template>
    </WaresSmallTable>
  </div>
</template>

<script>
import WaresSmallTable from '../WaresSmallTable';
import { getDefaultId } from '../../../core/services/parsers';

export default {
  name: 'WareInfoVariants',

  components: {
    WaresSmallTable
  },

  props: {
    ware: { type: Object, required: true }
  },

  data () {
    return {
      isLoading: true,
      variants: []
    };
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      this.isLoading = true;

      try {
        const variants = await this.$store.dispatch(
          'cache/get',
          {
            action: 'search/searchVariants',
            payload: { id: this.ware.id }
          }
        );

        this.variants = variants.map(it => ({
          _id: getDefaultId(),
          ...it,
          trademark: { name: it.tm_name }
        }));
      } catch (e) {
        console.error(e);
        this.variants = [];
      } finally {
        this.isLoading = false;
      }
    },

    isObject (field) {
      return typeof field === 'object';
    }

  }
};
</script>
