import { env } from '@/lib/core';

export const actions = {
  async getPriceStatus (_, { id }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: `/price-status/?price_file=${id}`
    });

    return data.results;
  }
};
