<template>
  <div class="sale-point-create-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_sale_points_create') }}
    </h1>

    <div class="text-ghost mb-2">
      <p>{{ $t('salePoint.setMinInfo') }}</p>
    </div>

    <el-form
      ref="form"
      autocomplete="off"
      label-position="left"
      :model="formData"
      :rules="rules"
      size="mini"
      @submit.native.prevent="submit()"
    >
      <el-form-item
        :label="$t('labels.salePointName')"
        label-width="200px"
        prop="name"
      >
        <el-input v-model="formData.name" class="max-w-350p" />
      </el-form-item>

      <Address
        ref="address"
        class="label-to-top mb-2"
        :value="formData.address"
        v-bind.sync="formData.address"
      />

      <Contacts
        class="mb-2"
        hide-description
        hide-emails
        :rules="rules"
        :value="formData"
        v-bind.sync="formData"
      />

      <SalePointOptions
        is-delivery-services
        :value="formData"
        v-bind.sync="formData"
      />

      <el-row class="mt-4" justify="center">
        <div v-if="isBusinessCreateMode">
          <el-button
            @click="$router.push({
              name: getRouteName('profile.business.edit')
            })"
          >
            {{ $t('buttons.cancel') }}
          </el-button>
          <el-button
            :loading="isLoading"
            native-type="submit"
            type="primary"
          >
            {{ $t('buttons.continue') }}
          </el-button>
        </div>

        <el-button
          v-else
          :loading="isLoading"
          native-type="submit"
          type="primary"
        >
          {{ $t('buttons.createSalePoint') }}
        </el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';
import Address from './components/formItems/Address';
import Contacts from './components/formItems/Contacts';
import SalePointOptions from './components/formItems/SalePointOptions';
import {
  getValidationFor,
  getDefault,
  scrollToTop
} from '@/lib/core';

import {
  fixPhoneNumber,
  getPrimaryOrFirst,
  getIdFromUrl
} from '@/lib/core/services/parsers';

export default {
  name: 'SalePointCreatePage',

  middleware: ['dependencies'],

  components: {
    Address,
    Contacts,
    SalePointOptions
  },

  data () {
    return {
      isLoading: false,
      rules: getValidationFor(null, 'salePoint', this),
      formData: getDefault('salePoint')
    };
  },

  computed: {
    ...mapGetters({
      user: 'users/user',
      businessUser: 'businessUsers/currentBusinessUser',
      businessId: 'businessProfiles/businessId',
      isSellBusiness: 'businessProfiles/isSellBusiness',
      city: 'city'
    }),

    isBusinessCreateMode () {
      return this.$route.query.mode === 'business-create';
    }
  },

  mounted () {
    this.setDefaultData();
  },

  methods: {
    fixPhoneNumber,

    setDefaultData () {
      if (this.businessUser) {
        const { phone_numbers, business } = this.businessUser;

        this.formData.name = business.name;

        const phone = getPrimaryOrFirst(phone_numbers, 'phone');

        this.$set(this.formData.phone_numbers, 0, phone);
      }

      this.loadDefaultAddress();
    },

    loadDefaultAddress () {
      this.unwatchCity = this.$watch('city', async (city) => {
        if (city) {
          this.unwatchCity && this.unwatchCity();
          await this.loadAdmin1(city);
          this.loadCity(city);
        }
      }, { immediate: true });
    },

    async loadAdmin1 (city) {
      if (!city.admin1) {
        return null;
      }

      const id = getIdFromUrl(city.admin1);

      try {
        const admin1 = await this.$store.dispatch(
          'searchLocation/getAdmin1ById',
          { id }
        );

        this.$refs.address.setAdmin1(admin1);
      } catch (e) {
        console.error(e);
      }
    },

    loadCity (city) {
      this.$refs.address.setCity(city);
    },

    submit (data) {
      if (this.isBusinessCreateMode && !data?.nextRoute) {
        return this.submit({
          nextRoute: {
            name: this.getRouteName('profile.business.platforms')
          }
        });
      }

      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          scrollToTop('is-error');
          return false;
        }

        this.isLoading = true;

        const formData = this.getClearFormData(this.formData);

        try {
          const { id } = await this.$store.dispatch(
            'salePoints/createSalePoint',
            formData
          );

          const route = this.businessUser?.business?.has_points_of_sale
            ? {
                name: this.getRouteName('profile.business.sale-points.edit'),
                params: { id }
              }
            : {
                name: this.getRouteName('profile.business.prices.upload')
              };

          return await this.$router.push(data?.nextRoute || route);
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      });
    },

    getClearFormData (rawFormData) {
      const { city_detail, ...address } = rawFormData.address || {};

      const formData = Object
        .entries(rawFormData)
        .reduce((acc, [key, value]) => {
          if (
            (typeof value !== 'object' && value) ||
            !isEmpty(value)
          ) {
            acc[key] = value;
          }

          return acc;
        }, {});

      return {
        ...formData,
        address,
        business: this.businessUser?.business.url
      };
    }
  }
};
</script>

<style scoped lang="scss">
.sale-point-create-page {
  .address {
    & > ::v-deep .el-form-item__content {
      padding-left: 40px;
    }
  }
}
</style>
