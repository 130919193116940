var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "trademark-filter" },
    [
      _c("el-input", {
        attrs: { placeholder: _vm.$t("placeholders.filterByTrademark") },
        model: {
          value: _vm.query,
          callback: function ($$v) {
            _vm.query = $$v
          },
          expression: "query",
        },
      }),
      _vm._v(" "),
      _c("ul", { staticClass: "list scroll" }, [
        _c(
          "li",
          { staticClass: "list-item" },
          _vm._l(_vm.filteredTrademarks, function (trademark) {
            return _c(
              "el-row",
              { key: trademark.id },
              [
                _vm.useLink
                  ? _c(
                      "nuxt-link",
                      { attrs: { to: _vm.getLink(trademark) } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { value: _vm.value.includes(trademark.id) },
                            on: {
                              change: function ($event) {
                                return _vm.toggleTm($event, trademark)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(trademark.name))])]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-checkbox",
                      {
                        attrs: { value: _vm.value.includes(trademark.id) },
                        on: {
                          change: function ($event) {
                            return _vm.toggleTm($event, trademark)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(trademark.name))])]
                    ),
              ],
              1
            )
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c("TheTooltip", { attrs: { content: _vm.$t("buttons.clear") } }, [
        _c("i", {
          staticClass: "clear el-icon-circle-close",
          on: { click: _vm.clear },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }