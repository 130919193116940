var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-request-create-master-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("sRequests.createRequestByVin")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.step
        ? _c("div", {
            staticClass: "default-style mb-2",
            domProps: {
              innerHTML: _vm._s(_vm.$t("sRequests.createRequestByVinDesc")),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("StepsPanel", {
        staticClass: "center fit-content mb-2",
        attrs: { entity: "sRequest", active: _vm.step },
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : _c(
            "div",
            { staticClass: "car-container" },
            [
              _vm.step === 0
                ? _c(
                    "el-form",
                    {
                      ref: "carForm",
                      attrs: {
                        model: _vm.carFormData,
                        rules: _vm.carRules,
                        "label-suffix": ":",
                        size: "medium",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.checkAndSaveCar(_vm.carFormData)
                        },
                      },
                    },
                    [
                      _vm.$store.getters.isAppLoaded
                        ? _c("GarageWidget", {
                            ref: "garageWidget",
                            staticClass: "mb-4",
                            attrs: {
                              "hide-options": "",
                              "save-to-list-only": "",
                              "prop-car": _vm.carFormData,
                            },
                            on: {
                              select: function ($event) {
                                return _vm.setCarData($event.data)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.carFormData.modification && !_vm.masterFormData.vin
                        ? _c(
                            "div",
                            { staticClass: "mt-2 mb-4" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "50px",
                                    label: _vm.$t("garage.main.vin"),
                                    prop: "vin",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "vin-select",
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.carFormData.vin,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.carFormData, "vin", $$v)
                                      },
                                      expression: "carFormData.vin",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                {
                                  staticClass: "mb-1",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { on: { click: _vm.goBack } },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("buttons.cancel")) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        "native-type": "submit",
                                        type: "primary",
                                        disabled:
                                          _vm.carFormData.vin ===
                                          _vm.masterFormData.vin,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("buttons.continue")) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.step === 1
                ? _c("SRequestForm", {
                    attrs: {
                      car: _vm.carFormData,
                      "hide-city": "",
                      "submit-btn-text": _vm.$t("buttons.continue"),
                      submit: _vm.saveVinRequestData,
                    },
                    on: {
                      cancel: function ($event) {
                        return _vm.countStep(_vm.step - 1)
                      },
                    },
                  })
                : _vm.step === 2
                ? _c(
                    "div",
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "custom-headline custom-headline--level2-bold",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("sRequests.userContacts")) +
                              "\n      "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-1" }, [
                        _vm._v(_vm._s(_vm.$t("sRequests.userContactsDesc"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "mb-2 city",
                          attrs: { justify: _vm.isMobile ? "center" : "" },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("sRequests.myCity")) + ":"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              staticClass: "ml-05",
                              attrs: { type: "primary" },
                              on: { click: _vm.showCitySelector },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.city.name) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          attrs: {
                            "label-suffix": ":",
                            size: "medium",
                            model: _vm.userFormData,
                            "label-position": "left",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.checkUserDataAndSubmit(
                                _vm.userFormData
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "phoneNumber",
                                label: _vm.$t("labels.phone"),
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("PhoneInput", {
                                    staticClass: "max-w-182p",
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.userFormData.phoneNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userFormData,
                                          "phoneNumber",
                                          $$v
                                        )
                                      },
                                      expression: "userFormData.phoneNumber",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "text-small-2" }, [
                                    _vm._v(
                                      "\n              (" +
                                        _vm._s(
                                          _vm.$t(
                                            "sRequests.userContactsPhoneDesc"
                                          )
                                        ) +
                                        ")\n            "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("SRequestItemsView", {
                            staticClass: "mb-2",
                            attrs: {
                              car: _vm.carFormData,
                              items: _vm.vinRequestData.itemsAdd,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.$router.push({
                                        name: _vm.getRouteName("home"),
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("buttons.cancel")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.countStep(_vm.step - 1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("buttons.back")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    "native-type": "submit",
                                    type: "primary",
                                    disabled: !_vm.userFormData.phoneNumber,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkUserDataAndSubmit(
                                        _vm.userFormData
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("buttons.emitRequest")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }