<template>
  <el-select
    class="currency-selector w-100p"
    :disabled="readonly"
    :value="value"
    @change="$emit('input', $event)"
  >
    <el-option
      v-for="item in currencies"
      :key="item.id"
      :label="item.name"
      :value="item.url"
    />
  </el-select>
</template>

<script>
export default {
  name: 'CurrencySelector',

  props: {
    readonly: Boolean,
    value: String
  },

  data: ({ $store }) => ({
    currencies: $store.getters['dependencies/currencies']
  })
};
</script>
