<template>
  <el-form
    ref="form"
    class="price-scheme-form"
    label-position="left"
    label-width="230px"
    :model="formData"
    :rules="rules"
    size="mini"
    @submit.native.prevent="submit"
  >
    <el-form-item :label="$t('priceScheme.name')">
      <el-select
        v-model="formData"
        class="name"
        value-key="url"
        @change="setScheme"
      >
        <el-option
          v-for="scheme in schemes"
          :key="scheme.url"
          :label="scheme.name"
          :value="scheme"
        />
        <el-option
          :label="$t('buttons.newPriceScheme')"
          :value="defaultScheme"
        />
      </el-select>
    </el-form-item>

    <el-form-item :label="$t('priceScheme.name')" prop="name">
      <el-input v-model="formData.name" :disabled="isExisted" />
    </el-form-item>

    <div class="block">
      <el-form-item
        class="scheme-type"
        :label="$t('priceScheme.selectPriceCreationScheme')"
        label-width="300px"
        prop="scheme_type"
      >
        <el-radio-group v-model="formData.scheme_type" :disabled="isExisted">
          <el-radio
            :label="schemeTypes[0]"
            @click.native="formData.scheme_type = 'static'"
          >
            <el-row align="middle" class="w-100">
              {{ $t(`priceScheme.types.${schemeTypes[0]}`) }}

              <template v-if="formData.scheme_type === schemeTypes[0]">
                <el-form-item label-width="0" class="mb-0">
                  <NumInput
                    v-model="formData.static_coefficient"
                    field="static_coefficient"
                    hide-label
                    :readonly="isExisted"
                    validation-prop="static_coefficient"
                  />
                </el-form-item>
              </template>
            </el-row>
          </el-radio>

          <el-radio :label="schemeTypes[1]" label-width="320px">
            {{ $t(`priceScheme.types.${schemeTypes[1]}`) }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </div>

    <div v-if="formData.scheme_type === schemeTypes[1]" class="block ml-1">
      <div class="block-label">
        {{ $t('priceScheme.dynamicPriceSchemeParams') }}:
      </div>

      <div class="ml-4">
        <el-form-item :label="$t('priceScheme.currency')" prop="currency">
          <CurrencySelector v-model="formData.currency" :readonly="isExisted" />
        </el-form-item>

        <el-row class="dynamic-params" :gutter="40">
          <el-col>
            <el-form-item
              :label="$t('priceScheme.min_price', { name: currencyName })"
              label-width="220px"
              prop="min_price"
            >
              <NumInput
                v-model="formData.min_price"
                field="min_price"
                hide-label
                :readonly="isExisted"
              />
            </el-form-item>

            <el-form-item
              :label="$t('priceScheme.max_price', { name: currencyName })"
              label-width="220px"
              prop="max_price"
            >
              <NumInput
                v-model="formData.max_price"
                field="max_price"
                hide-label
                :readonly="isExisted"
              />
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item
              :label="$t('priceScheme.max_markup')"
              label-width="220px"
              prop="max_markup"
            >
              <NumInput
                v-model="formData.max_markup"
                field="max_markup"
                hide-label
                :readonly="isExisted"
              />
            </el-form-item>

            <el-form-item
              :label="$t('priceScheme.min_markup')"
              label-width="220px"
              prop="min_markup"
            >
              <NumInput
                v-model="formData.min_markup"
                field="min_markup"
                hide-label
                :readonly="isExisted"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-row justify="end">
      <el-button
        :disabled="loading"
        size="large"
        @click="$emit('cancel')"
      >
        {{ $t('buttons.cancel') }}
      </el-button>
      <el-button
        :loading="loading"
        native-type="submit"
        size="large"
        type="primary"
      >
        {{ $t('buttons.create') }}
      </el-button>
    </el-row>
  </el-form>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import transform from 'lodash/transform';
import NumInput from './formItems/NumInput';
import { getValidationFor, library, getDefault, eventBus } from '@/lib/core';
import CurrencySelector from '@/lib/core/components/CurrencySelector';

const core = ['business', 'name', 'currency', 'scheme_type'];
const filters = {
  static: ['static_coefficient'].concat(core),
  dynamic: ['min_price', 'max_price', 'max_markup', 'min_markup'].concat(core),
  int: ['static_coefficient', 'min_price', 'max_price', 'max_markup', 'min_markup']
};

export default {
  name: 'PriceSchemeForm',

  components: {
    CurrencySelector,
    NumInput
  },

  data () {
    const defaultScheme = getDefault('priceScheme');

    return {
      loading: false,
      schemes: [],
      rules: getValidationFor(null, 'priceScheme', this),
      schemeTypes: library.priceSchemeTypes,
      defaultScheme,
      formData: cloneDeep(defaultScheme)
    };
  },

  computed: {
    business () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'];
    },

    isExisted () {
      return Boolean(this.formData.url);
    },

    currencies () {
      return this.$store.getters['dependencies/currencies'];
    },

    currencyName () {
      const currency = this.currencies
        .find(({ url }) => url === this.formData.currency);
      return currency ? currency.name : this.$t('core.currency');
    }
  },

  created () {
    this.loadSchemes();
  },

  methods: {
    async loadSchemes () {
      try {
        this.schemes = await this.$store.dispatch(
          'pricingSchemes/getPriceSchemes',
          { business: this.business.id }
        );
      } catch (e) {
        console.error(e);
      }
    },

    setScheme (scheme) {
      this.formData = scheme;

      this.$nextTick(() => this.$refs.form.clearValidate());
    },

    submit () {
      this.formData.business = this.business.url;

      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          return false;
        }

        // TODO remove spike after fix server https://mriyar.atlassian.net/browse/MBP-4391
        if (this.formData.scheme_type === 'static') {
          this.formData.currency = 'https://dev.mriyar.ua/api/mp/v1/currencies/2/';
        }

        if (this.formData.url) {
          return this.$emit('created', this.formData);
        }

        this.loading = true;

        const formData = this.getClearData(this.formData);

        try {
          const scheme = await this.$store.dispatch(
            'pricingSchemes/postPricingScheme',
            { formData }
          );

          eventBus.$emit('price-schemes:create', scheme);

          this.$emit('created', scheme);
        } catch {
          this.loading = false;
        }
      });
    },

    getClearData (formData) {
      return transform(formData, (acc, value, key) => {
        if (filters[formData.scheme_type].includes(key)) {
          acc[key] = filters.int.includes(key)
            ? Number.parseFloat(value)
            : value;
        }
      }, {});
    }
  }
};
</script>

<style scoped lang="scss">
.price-scheme-form {
  .el-input {
    width: 200px;
  }

  .name {
    width: 200px;
  }

  .block {
    margin-bottom: 10px;
  }

  .block-label {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .log-params {
    .el-col {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .scheme-type {
    margin-left: 10px;
    display: flex;
    flex-direction: column;

    & ::v-deep  > .el-form-item__label {
      font-weight: bold !important;

      &:after {
        font-weight: normal;
      }
    }

    ::v-deep .el-form-item__content {
      margin-left: 20px!important;
    }
  }

  .el-radio-group {
    ::v-deep .el-form-item__label {
      cursor: pointer;
    }

    .el-radio {
      display: flex;
      align-items: center;
      height: 30px;
      margin-bottom: 0;
    }
  }

  ::v-deep .el-form-item__error {
    white-space: nowrap;
    overflow: visible;
  }
}
</style>
