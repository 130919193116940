<template>
  <el-card class="password-recovery-form">
    <nuxt-link class="close-icon" :to="{ name: getRouteName('auth') }">
      <i class="el-icon-close" />
    </nuxt-link>
    <span class="custom-headline custom-headline--level2-bold">
      {{ $t('auth.recoveryPassword') }}
    </span>

    <el-form ref="form" :model="formData" :rules="rules" @submit.native.prevent="submit">
      <el-form-item :label="$t('labels.email')" prop="email">
        <el-input v-model.trim="formData.email" />
      </el-form-item>

      <el-form-item :label="$t('labels.recovery_key')" prop="recovery_key">
        <el-input v-model.trim="formData.recovery_key" />
      </el-form-item>

      <el-form-item :label="$t('labels.password')" prop="password">
        <el-input v-model.trim="formData.password" type="password" />
      </el-form-item>

      <el-form-item :label="$t('labels.password2')" prop="password2">
        <el-input v-model.trim="formData.password2" type="password" />
      </el-form-item>

      <el-form-item>
        <el-button class="submit-btn" :loading="loading" native-type="submit" type="primary">
          {{ $t('buttons.recoveryPassword') }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { getValidationFor } from '@/lib/core';

const rules = ['email', 'recovery_key', 'password', 'password2'];

export default {
  name: 'PasswordRecoveryForm',

  data ({ $route }) {
    return {
      loading: false,
      rules: getValidationFor(rules, 'user', this),
      formData: {
        email: $route.query.email,
        recovery_key: $route.query.recovery_key,
        password: '',
        password2: ''
      }
    };
  },

  methods: {
    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          this.loading = true;
          const { email, password } = this.formData;

          try {
            await this.$store.dispatch('users/postPasswordRecovery', this.formData);
            await this.$store.dispatch('auth/login', { email, password });

            await this.$router.push({ name: this.getRouteName('home') });
          } catch (e) {
            console.error(e);
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/auth-form.scss";
</style>
