var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "delivery-rate-form",
      attrs: {
        "label-position": "left",
        "label-width": "250px",
        model: _vm.formData,
        rules: _vm.rules,
        size: "mini",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("deliveryRate.existName") } },
        [
          _c(
            "el-select",
            {
              staticClass: "name",
              attrs: { "value-key": "url" },
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            },
            [
              _vm._l(_vm.rates, function (rate) {
                return _c("el-option", {
                  key: rate.url,
                  attrs: { label: rate.name, value: rate },
                })
              }),
              _vm._v(" "),
              _c("el-option", {
                attrs: {
                  label: _vm.$t("deliveryRate.creationNewRate"),
                  value: _vm.defaultRate,
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("deliveryRate.name"), prop: "name" } },
        [
          _c("el-input", {
            staticClass: "name",
            attrs: { disabled: _vm.isExisted },
            model: {
              value: _vm.formData.name,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("deliveryRate.currency"), prop: "currency" } },
        [
          _c("CurrencySelector", {
            attrs: { readonly: _vm.isExisted },
            model: {
              value: _vm.formData.currency,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "currency", $$v)
              },
              expression: "formData.currency",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("h4", { staticClass: "mb-2 bold" }, [
        _vm._v(_vm._s(_vm.$t("deliveryRate.deliveryPrams")) + ":"),
      ]),
      _vm._v(" "),
      _c(
        "DeliveryRateParams",
        _vm._b(
          {
            attrs: {
              "currency-name": _vm.currencyName,
              readonly: _vm.isExisted,
              value: _vm.formData,
            },
          },
          "DeliveryRateParams",
          _vm.formData,
          false,
          true
        )
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mt-2", attrs: { justify: "end" } },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.loading, size: "large" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                "native-type": "submit",
                size: "large",
                type: "primary",
              },
              on: { click: _vm.submit },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.save")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }