import env from '../services/envConstants';
import { EMAIL_REG, INT_FLOAT_REG, PHONE_REG } from './patterns';
import { i18n } from '@/plugins/i18n';

export const requiredString = {
  required: true,
  message: i18n.t('validation.simple.required'),
  trigger: 'blur'
};

export const requiredStringChange = {
  ...requiredString,
  trigger: 'change'
};

export const requiredNumber = {
  required: true,
  pattern: INT_FLOAT_REG,
  message: i18n.t('validation.simple.number'),
  trigger: 'blur'
};

export const notEmptyArray = {
  type: 'array',
  min: 1,
  required: true,
  message: i18n.t('validation.simple.minOneOption'),
  trigger: 'blur'
};

export const phoneNumberObject = [
  requiredString,
  { validator: 'phoneNumberObject', trigger: 'blur' }
];

export const emailObject = [
  requiredString,
  { validator: 'emailObject', trigger: 'blur' }
];

export const phone_number = [
  requiredString,
  {
    pattern: PHONE_REG,
    message: i18n.t(
      'validation.phone_number.pattern',
      { format: env.MARKETPLACE_PHONE_FORMAT }
    ),
    trigger: 'blur',
    transform: 'fixPhone'
  },
  {
    len: env.MARKETPLACE_PHONE_FORMAT.length,
    message: i18n.t(
      'validation.phone_number.pattern',
      { format: env.MARKETPLACE_PHONE_FORMAT }
    ),
    trigger: 'blur'
  }
];

export const phone_numbers = [
  {
    type: 'array',
    required: true,
    message: i18n.t('validation.simple.required'),
    trigger: 'change'
  },
  { validator: 'phone_numbers' }
];

export const email = [
  {
    required: true,
    message: i18n.t('validation.simple.required'),
    trigger: 'blur',
    email: true
  },
  {
    pattern: EMAIL_REG,
    message: i18n.t('validation.email.pattern'),
    trigger: 'blur',
    transform: 'trim'
  }
];

export const emails = [
  {
    type: 'array',
    required: true,
    message: i18n.t('validation.simple.required'),
    trigger: 'change'
  },
  { validator: 'emails' }
];

export const terms_url = [{ validator: 'termsUrl', trigger: 'blur' }];
