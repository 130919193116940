<template>
  <div class="s-request-filter">
    <el-row class="mb-2">
      <el-button-group>
        <el-button
          disabled
          size="mini"
          :type="!$route.query.isProcessed ? 'primary' : ''"
          @click="setFilter({ isProcessed: null })"
        >
          {{ $t('buttons.allRequests') }}
        </el-button>

        <el-button
          disabled
          size="mini"
          :type="$route.query.isProcessed ? 'primary' : ''"
          @click="setFilter({ isProcessed: true })"
        >
          {{ $t('buttons.processed') }}
        </el-button>
      </el-button-group>
    </el-row>

    <el-row justify="space-between">
      <div class="mr-2">
        <el-select
          clearable
          multiple
          size="mini"
          :value="brand"
          :placeholder="$t('sRequests.allCarsModels')"
          @change="setBrands"
        >
          <el-option
            v-for="item in brands"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>

      <el-row>
        <el-input
          v-model="public_id"
          disabled
          clearable
          size="mini"
          :placeholder="$t('sRequests.public_id')"
          @clear="setFilter({ public_id: '' })"
        >
          <el-button
            slot="append"
            type="primary"
            icon="el-icon-search"
            @click="setFilter({ public_id })"
          />
        </el-input>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { env } from '@/lib/core';

export default {
  name: 'SRequestFilter',

  data: () => ({
    public_id: ''
  }),

  computed: {
    brand () {
      return this.$route.query.brand_id__in
        ? this.$route.query.brand_id__in.split(',').map(Number)
        : null;
    },

    brands () {
      return this.$store.getters['carBrands/brands']?.results || [];
    }
  },

  created () {
    this.setBrands = debounce(this.setBrands, env.DEBOUNCE);
    this.public_id = this.$route.query.public_id || '';

    this.$store.dispatch(
      'cache/get',
      { action: 'carBrands/fetchBrands' }
    );
  },

  methods: {
    setBrands (val) {
      this.setFilter({ brand_id__in: val.toString() });
    },

    async setFilter (_query = {}) {
      const query = Object
        .entries({ ...this.$route.query, ..._query })
        .reduce((acc, [key, val]) => {
          if (val && key !== 'page') {
            acc[key] = val;
          }
          return acc;
        }, {});

      await this.$router.push({ query }).catch(e => e);

      this.$emit('submit', query);
    }
  }
};
</script>

<style scoped lang="scss">
.s-request-filter {

}
</style>
