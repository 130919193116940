var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "file-processing",
        { "highlight-required": _vm.dependency.mode === "create" },
      ],
    },
    [
      _c("div", { staticClass: "description mb-2" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("prices.docTypeMarkupDescription")))]),
      ]),
      _vm._v(" "),
      _vm.dataPreviewCount > 1
        ? _c(
            "div",
            { staticClass: "fit-content" },
            [
              _c(
                "TheTooltip",
                {
                  attrs: { content: _vm.$t("priceFile.dataSheetDescription") },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "data_sheet",
                        "label-width": "210px",
                        label: _vm.$t("priceFile.data_sheet"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "data-sheet",
                          attrs: { value: _vm.value.data_sheet },
                          on: { change: _vm.pageUpdate },
                        },
                        _vm._l(_vm.dataPreviewCount, function (index) {
                          return _c("el-option", {
                            key: "sheet_" + index,
                            attrs: { label: index, value: index },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "header-row" }, [
        _c(
          "div",
          { staticClass: "mr-2" },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("priceFile.rowWithTitles"),
                  "label-width": "300px",
                  prop: "header_row",
                },
              },
              [
                _c("el-input-number", {
                  staticClass: "mr-2",
                  attrs: {
                    disabled: !_vm.value.header_row,
                    max: _vm.dependency.price.data_preview
                      ? _vm.dependency.price.data_preview.length
                      : 1,
                    min: 0,
                    value: _vm.value.header_row,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.emit("header_row", $event ? $event : null)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mr-2" },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "header_row", "label-width": "0" } },
              [
                _c("el-checkbox", {
                  staticClass: "no-head-row",
                  attrs: {
                    label: _vm.$t("priceFile.noHeaderCol"),
                    value: !Boolean(_vm.value.header_row),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.emit(
                        "header_row",
                        _vm.value.header_row ? null : 1
                      )
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "data_start_row mr-2" },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("priceFile.dataStartRow"),
                  "label-width": "300px",
                  prop: "data_start_row",
                },
              },
              [
                _c("el-input-number", {
                  attrs: {
                    max: _vm.dependency.price.data_preview
                      ? _vm.dependency.price.data_preview.length
                      : 1,
                    min: _vm.value.header_row + 1,
                    value: _vm.value.data_start_row,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.emit("data_start_row", $event)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-form-item",
            {
              ref: "trademark",
              attrs: {
                label: _vm.$t("priceFile.oneTradeMarkInPrice"),
                "label-width": "266px",
                prop: "trademark",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-checkbox", {
                    attrs: { value: _vm.hasOneTradeMark },
                    on: { change: _vm.toggleTrademark },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "ml-2 max-w-350p",
                    attrs: {
                      disabled: !_vm.hasOneTradeMark,
                      value: _vm.value.trademark,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.emit("trademark", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("priceFile.noCounterInfoInPrice"),
                "label-width": "300px",
                prop: "noCounterInPrice",
              },
            },
            [
              _c("el-checkbox", {
                attrs: { value: _vm.value.noCounterInPrice },
                on: {
                  change: function ($event) {
                    return _vm.emit("noCounterInPrice", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }