<template>
  <div class="trademark-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ meta && meta.h1 || trademark.name }}
    </h1>

    <div v-if="!isEmpty(productLines)" class="mb-2">
      <CatalogTree :value="productLines" />
    </div>

    <div v-if="!isEmpty(products)" class="mb-2">
      <TopProducts
        :title="`${$t('catalog.topProducts')} ${trademark.name}`"
        :value="products"
        @submit-order="submitOrder"
      />
    </div>

    <!--eslint-disable-next-line  vue/no-v-html-->
    <div v-if="meta && meta.text" class="pg-2 default-style" v-html="meta.text" />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

import getSeoPageData from './services/getSeoPageData';
import CatalogTree from './components/CatalogTree';
import seoHeadMixin from '@/lib/catalog/mixins/seoHeadMixin';
import getParsedSeoProducts from '@/lib/catalog/services/getParsedSeoProducts';
import addWareToBasket from '@/lib/orders/services/addWareToBasket';

export default {
  name: 'TrademarkPage',

  mixins: [seoHeadMixin],

  components: {
    CatalogTree,
    TopProducts: () => import('./components/TopProducts')
  },

  async asyncData ({ app, store, params, error }) {
    const loadTime = Date.now();

    try {
      const data = await store.dispatch(
        'cache/get',
        { action: 'seo/getSeoTrademarks', payload: params }
      );

      const {
        meta,
        trademark,
        product_lines,
        products
      } = data;

      const rowBreadcrumbs = [{
        name: 'brands',
        label: app.i18n.t('catalog.trademarks'),
        path: '/brands'
      }];

      const seoPayload = {
        data,
        vm: { $store: store, $router: app.router },
        rowBreadcrumbs
      };

      const { seoBreadcrumbs, breadcrumbs } = getSeoPageData(seoPayload);

      store.dispatch('setBreadcrumbs', breadcrumbs);

      return {
        loadTime: Date.now() - loadTime,
        meta,
        trademark,
        productLines: product_lines,
        products: getParsedSeoProducts(products || []),
        seoBreadcrumbs
      };
    } catch (e) {
      console.error(e);
      error({
        statusCode: e.statusCode || 404,
        message: e.message || app.i18n.t('errors.404.message')
      });
    }
  },

  mounted () {
    console.info(`LOAD_TIME: ${this.loadTime}ms`, this.$data);
  },

  methods: {
    isEmpty,

    async submitOrder (ware) {
      this.isOrderLoading = true;
      await addWareToBasket(this, ware);
      this.isOrderLoading = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/default-style.scss";
</style>
