<template>
  <div class="availabilities-rate outline-block">
    <div class="outline-block__title">
      <el-select size="mini" :value="rate" value-key="url" @change="setRate">
        <el-option
          v-for="rateItem in rates"
          :key="rateItem.url"
          :label="rateItem.name"
          :value="rateItem"
        />
        <el-option
          :label="$t('buttons.createDeliveryRate')"
          value=""
          @click.native="isDialog = true"
        />
      </el-select>
    </div>

    <DeliveryRateParams
      v-if="rate"
      :currency-name="rate.currency_detail.name"
      readonly
      :value="rate"
    />

    <TheDialog
      v-model="isDialog"
      :title="$t('deliveryRate.deliveryRateCreating')"
    >
      <DeliveryRateForm
        :rates="rates"
        @cancel="isDialog = false"
        @created="setCreatedRate"
        @input="setRate"
      />
    </TheDialog>
  </div>
</template>

<script>
import DeliveryRateForm from './DeliveryRateForm';
import DeliveryRateParams from './formItems/DeliveryRateParams';

export default {
  name: 'AvailabilitiesRate',

  components: {
    DeliveryRateParams,
    DeliveryRateForm
  },

  props: {
    value: String
  },

  data: () => ({
    rate: null,
    rates: [],
    isDialog: false
  }),

  computed: {
    businessId () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'].id;
    }
  },

  async created () {
    await this.loadRates();
    this.setDefaultFirst();
  },

  methods: {
    setDefaultFirst () {
      if (!this.value && this.rates[0]) {
        this.rate = this.rates[0];
        this.$emit('input', this.rates[0].url);
      }
    },

    setCreatedRate (rate) {
      this.rates.push(rate);
      this.setRate(rate);
      this.isDialog = false;
    },

    setRate (rate) {
      if (typeof rate === 'object') {
        this.rate = rate;
        this.$emit('input', rate.url);
      }

      this.isDialog = false;
    },

    async loadRates () {
      try {
        this.rates = await this.$store.dispatch('deliveryRates/getDeliveryRates', {
          business: this.businessId
        });

        if (this.value) {
          this.rate = this.rates.find(({ url }) => url === this.value);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.availabilities-rate {
  .delivery-rate-params {
    width: 800px;
  }
}
</style>
