import transform from 'lodash/transform';

const _actions = {
  added: 'postPointPhotos',
  removed: 'deletePointPhotos',
  updated: 'patchPointPhotos'
};

export const actions = {
  async createSalePoint ({ store, commit, dispatch }, formData) {
    const point = await this.$axios.$post('/points-of-sale/', formData);
    dispatch('users/updateCurrentUser', null, { root: true });
    return point;
  },

  getSalePoints (_, params) {
    return this.$axios.$get('/points-of-sale/', { params });
  },

  getSalePoint ({ commit }, { id }) {
    return this.$axios.$get(`/points-of-sale/${id}/`);
  },

  patchSalePoint ({ state, commit, dispatch }, { id, formData }) {
    return this.$axios.$patch(`/points-of-sale/${id}/`, formData);
  },

  async submitPhotos ({ dispatch }, { pointUrl, pointId, ...formData }) {
    const promises = transform(_actions, (acc, value, key) => {
      if (formData[key] && formData[key].length) {
        const params = { pointUrl, pointId, photos: formData[key] };
        const promise = dispatch(
          `sellerPhotos/${value}`,
          params,
          { root: true }
        );

        acc.push(promise);
      }

      return acc;
    }, []);

    await Promise.all(promises);

    return await dispatch('getSalePoint', { id: pointId });
  }
};
