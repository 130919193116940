var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-catalog-tile" },
    [
      _c(
        "el-container",
        [
          _vm.isMobile
            ? _c(
                "el-header",
                { staticClass: "mb-2 pg-0", attrs: { height: "fit-content" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "filters-btns", attrs: { align: "middle" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "fl-row mr-1",
                          on: {
                            click: function ($event) {
                              _vm.isFilter = !_vm.isFilter
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("search.filter")) +
                              "\n          "
                          ),
                          _c("img", {
                            staticClass: "icon-img ml-05",
                            attrs: {
                              src: require("@/assets/icons/filter.svg"),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("CustomSelect", {
                        attrs: { label: _vm.$t("search.order") },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "menu",
                              fn: function (ref) {
                                var isMenu = ref.isMenu
                                return [
                                  isMenu
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "menu__item",
                                              {
                                                "is-active":
                                                  _vm.ordering[0] ===
                                                  "delivery_time",
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleOrdering([
                                                  "delivery_time",
                                                  "-rank",
                                                ])
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("search.term")) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "menu__item",
                                              {
                                                "is-active":
                                                  _vm.ordering[0] === "price",
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleOrdering([
                                                  "price",
                                                  "-rank",
                                                ])
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("search.price")) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.ordering.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "menu__item is-clear",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleOrdering(
                                                      _vm.ordering
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.$t("buttons.clear")
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1028998698
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isArticlesLoading
            ? _c("TheLoader")
            : _c(
                "el-container",
                { staticClass: "relative" },
                [
                  _vm.$store.getters.isDomReadyState &&
                  !_vm.isMobile &&
                  !_vm.isArticlesLoading
                    ? _c(
                        "el-aside",
                        {
                          staticClass: "filter-block",
                          attrs: { width: "260px" },
                        },
                        [
                          _c("CatalogSearchFilter", {
                            attrs: {
                              "is-visible": _vm.isFilter,
                              params: _vm.result,
                              trademark: _vm.trademark,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "el-main pg-0" },
                    [
                      _c(
                        "keep-alive",
                        [
                          _c("WaresTile", {
                            ref: "table",
                            attrs: {
                              "is-show-ordering": !_vm.isMobile,
                              "analogs-res": _vm.result,
                            },
                            on: {
                              "basket-click": _vm.addWareToBasket,
                              "sale-point:info": _vm.showSalePointInfo,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMobile
        ? _c(
            "SearchFilterM",
            {
              attrs: { visible: _vm.isFilter },
              on: {
                close: function ($event) {
                  _vm.isFilter = false
                },
              },
            },
            [
              _c("CatalogSearchFilter", {
                attrs: {
                  "is-manual": "",
                  "is-visible": _vm.isFilter,
                  params: _vm.result,
                  trademark: _vm.trademark,
                },
                on: {
                  submitted: function ($event) {
                    _vm.isFilter = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: { title: _vm.$t("orders.salePointInfo") },
          model: {
            value: _vm.isSalePointInfo,
            callback: function ($$v) {
              _vm.isSalePointInfo = $$v
            },
            expression: "isSalePointInfo",
          },
        },
        [
          _vm.isSalePointInfo
            ? _c("SalePointInfo", { attrs: { id: _vm.isSalePointInfo } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }