import $notify from 'element-ui/lib/notification';
import isEmpty from 'lodash/isEmpty';
import { i18n } from './i18n';

const t = key => i18n.t(`labels.${key}`);

const defaultOptions = {
  duration: 20000,
  offset: 0
};

const visibleErrors = {};

const printError = ({ title, message, type }) => {
  const notification = {
    ...defaultOptions,
    type: type || 'error',
    title,
    message,
    dangerouslyUseHTMLString: true,
    onClose: () => {
      delete visibleErrors[message];
    }
  };

  if (visibleErrors[message]) {
    return null;
  } else {
    setTimeout(() => {
      visibleErrors[message] = $notify(notification);
    });
  }
};

const emitSentryError = (e, { $sentry }) => {
  const status = e.extensions?.response?.status;

  if (status === 401) {
    console.error('ERROR STATUS: ', status);
  } else if (status === 408) {
    const url = e.extensions?.response?.url;
    $sentry.setTag('type', 'api');
    $sentry.setTag('status', status);
    $sentry.captureException(
      new Error(`API Endpoint ${url} timeout\returned ${status}`)
    );
  } else if ([500, 503, 504].includes(status) || status !== 404) {
    $sentry.setTag('type', 'api');
    $sentry.setTag('status', status);
    $sentry.captureException(e);
  } else {
    $sentry.setTag('status', status);
    $sentry.captureException(e);
  }
};

// {
//   "message": "404: Not Found",
//   "extensions": {
//   "code": "INTERNAL_SERVER_ERROR",
//     "serviceName": "common",
//     "response": {
//     "url": "http://web1.dev.int.mriyar.ua:8100/api/garage/v1/cars/1/",
//       "status": 404,
//       "statusText": "Not Found",
//       "body": {
//       "detail": "Not found."
//     }
//   },
//   "exception": {
//     "message": "404: Not Found",
//       "locations": [
//       {
//         "line": 1,
//         "column": 26
//       }
//     ],
//       "path": [
//       "garageCar"
//     ]
//   }
// }
// }

// {
//   "message": "400: Bad Request",
//   "extensions": {
//   "code": "INTERNAL_SERVER_ERROR",
//     "serviceName": "common",
//     "response": {
//     "url": "http://web1.dev.int.mriyar.ua:8100/api/garage/v1/cars/",
//       "status": 400,
//       "statusText": "Bad Request",
//       "body": {
//       "production_year": [
//         {
//           "message": "This field may not be null.",
//           "code": "null"
//         }
//       ],
//         "engine_capacity": [
//         {
//           "message": "This field may not be null.",
//           "code": "null"
//         }
//       ]
//     }
//   },
//   "exception": {
//     "message": "400: Bad Request",
//       "locations": [
//       {
//         "line": 1,
//         "column": 34
//       }
//     ],
//       "path": [
//       "createGarageCar"
//     ]
//   }
// }
// }

const concatMessage = (body) => {
  return Object.entries(body).reduce((acc, [key, value]) => {
    let _val = value;

    if (Array.isArray(value)) {
      _val = value
        .map(it => it.message)
        .join(' ')
        .trim();
    }

    return acc + `<strong>${t(key)}:</strong> ${_val}<br>`;
  }, '');
};

const showGqlErrors = (errors, nuxtContext) => {
  Array.from(errors).forEach((e) => {
    const statusCode = e.extensions?.response?.status || e.extensions?.status;
    const key = `errors.${statusCode}.title`;
    const _title =
      !i18n.t(key) || i18n.t(key) === key
        ? i18n.t('errors.default.title')
        : i18n.t(`errors.${statusCode}.title`);

    printError({
      title: `${statusCode}: ${_title}`,
      message: concatMessage(e.extensions?.response?.body || {}) || e.message
    });

    emitSentryError(e, nuxtContext);
  });
};

const showNetworkError = (e, nuxtContext) => {
  const statusCode = e.statusCode;
  const key = `errors.${statusCode}.title`;
  const _title =
    !i18n.t(key) || i18n.t(key) === key
      ? i18n.t('errors.default.title')
      : i18n.t(`errors.${statusCode}.title`);

  printError({
    title: `${statusCode}: ${_title}`,
    message: concatMessage(e.result || {}) || e.message
  });

  emitSentryError(e, nuxtContext);
};

export default (error, nuxtContext) => {
  console.error(JSON.stringify(error, null, 2));

  if (!isEmpty(error.graphQLErrors)) {
    showGqlErrors(error.graphQLErrors, nuxtContext);
  }

  if (!isEmpty(error.networkError)) {
    showNetworkError(error.networkError, nuxtContext);
  }

  if (isEmpty(error.graphQLErrors) && isEmpty(error.networkError)) {
    printError({
      title: i18n.t('errors.default.title'),
      message:
        error.message ||
        error.name ||
        'ApolloError !' + JSON.stringify(error, null, 2)
    });
  }
};
