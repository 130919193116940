<template>
  <div class="user-nav">
    <el-menu
      ref="menu"
      :key="key"
      background-color="white"
      mode="horizontal"
      text-color="black"
      @select="select"
    >
      <el-menu-item
        class="btn btn-city"
        index="btn-city"
        @click="isCitySelector = true"
      >
        <i class="el-icon-location-outline" />
        <span class="city-name">
          {{ city ? city.name : $t('routes.city') }}
        </span>
      </el-menu-item>

      <el-menu-item v-if="isAuth" index="btn-garage" class="btn">
        <img
          alt="profile_garage"
          class="el-icon- icon-img car"
          src="@/assets/icons/car-1.svg"
        >
        <span class="label">{{ $t('routes.garage') }}</span>
      </el-menu-item>

      <el-menu-item v-show="showBasket" index="btn.basket">
        <BasketIcon only-icon />
      </el-menu-item>

      <template v-if="isAuth">
        <el-menu-item index="basket.orders">
          <i class="el-icon-document" />
          <span class="label">{{ $t('basket.orders') }}</span>
        </el-menu-item>

        <el-submenu
          ref="profileMenu"
          class="btn"
          index="profile-menu"
          :show-timeout="100"
          :hide-timeout="300"
        >
          <template slot="title">
            <i class="el-icon-user" />
            <span class="label">{{ profileName }}</span>
          </template>

          <el-menu-item
            v-if="isSellBusiness"
            class="btn"
            index="btn.profile.business.edit"
            @click="goToBusiness"
          >
            <el-row align="middle">
              <img
                alt="profile_business_edit"
                class="el-icon- icon-img"
                src="@/assets/icons/currency-usd.svg"
              >
              <span>{{ $t('routes.profile_business_edit') }}</span>
            </el-row>
          </el-menu-item>

          <el-menu-item index="profile">
            <i class="el-icon-user" />
            <span>{{ $t('routes.account') }}</span>
          </el-menu-item>

          <el-menu-item class="btn" index="btn-profile">
            <i class="el-icon-refresh" />
            <span>{{ $t('routes.profileChange') }}</span>
          </el-menu-item>

          <el-menu-item class="btn-exit" index="btn" @click="logout">
            <i class="el-icon-circle-close" />
            <span>{{ $t('routes.exit') }}</span>
          </el-menu-item>

          <template v-if="!$store.getters['businessProfiles/businessProfiles'].length">
            <hr class="m-0">

            <el-menu-item class="mt-1 mb-1" index="profile.business.create">
              <el-button type="success" class="m">
                {{ $t('buttons.createBusiness') }}
              </el-button>
            </el-menu-item>
          </template>
        </el-submenu>
      </template>

      <template v-else>
        <el-menu-item class="btn" index="auth">
          <i class="el-icon-user" />
          <span class="label">{{ $t('routes.enter') }}</span>
        </el-menu-item>
      </template>
    </el-menu>

    <template v-if="isDomReady">
      <TheDialog v-model="isAuthDialog" width="400px">
        <AuthDialog @close="isAuthDialog = false" @done="isAuthDialog = false" />
      </TheDialog>

      <ProfileSelect
        v-if="isProfiles"
        update-basket
        @close="closeProfiles"
        @select="selectProfile"
      />

      <CityConfirm
        v-if="city && isShowCityConfirm"
        :city="city"
        @city:change="isCitySelector = true"
      />

      <TheDialog
        v-model="isCitySelector"
        :title="$t('core.selectCity')"
        width="400px"
      >
        <CitySelector
          v-if="isCitySelector"
          @cancel="isCitySelector = false"
          @city:changed="isCitySelector = false"
        />
      </TheDialog>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserNav',

  components: {
    /* eslint-disable max-len */
    CityConfirm: () => import(/* webpackChunkName: "CityConfirm" */ './CityConfirm'),
    BasketIcon: () => import(/* webpackChunkName: "BasketIcon" */ '@/lib/orders/components/BasketIcon'),
    AuthDialog: () => import(/* webpackChunkName: "AuthDialog" */ '@/lib/auth/components/AuthDialog'),
    CitySelector: () => import(/* webpackChunkName: "CitySelector" */ './CitySelector'),
    ProfileSelect: () => import(/* webpackChunkName: "ProfileSelect" */ '@/lib/profiles/components/ProfileSelect')
    /* eslint-enable max-len */
  },

  props: {
    showBasket: Boolean,
    hideList: { type: Array, default: () => [] }
  },

  data: () => ({
    key: 0,
    isAuthDialog: false,
    isProfiles: false
  }),

  computed: {
    ...mapGetters({
      isDomReady: 'isDomReadyState',
      isAuth: 'auth/isAuthenticated',
      profileId: 'businessUsers/profileId',
      isSellBusiness: 'businessProfiles/isSellBusiness',
      user: 'users/user',
      businessProfile: 'businessProfiles/currentBusinessProfile',
      isShowCityConfirm: 'isShowCityConfirm',
      city: 'city',
      isDev: 'isDev'
    }),

    // fix bug with hide dialog by second click
    isCitySelector: {
      get () {
        return this.$store.getters.isCitySelector;
      },
      set (val) {
        return this.$store.dispatch('setIsCitySelector', val);
      }
    },

    profileName () {
      return this.businessProfile?.name || this.user?.profile?.profile_name || '';
    }
  },

  methods: {
    async logout () {
      await this.$store.dispatch('auth/logoutAndGo');
      this.goHome();
    },

    async select (key) {
      if (!key.startsWith('btn')) {
        if (key === 'auth' && this.$route.name !== 'auth') {
          this.isAuthDialog = true;
        } else {
          this.go({ name: this.getRouteName(key) });
        }
      } else if (key === 'btn-profile') {
        this.isProfiles = true;

        // spike to fix submenu click on mobile
        if (this.maxmq('md')) {
          this.key++;
        }
      } else if (key === 'btn-garage') {
        const garage = { name: this.getRouteName('garage') };

        if (this.isAuth) {
          return this.go(garage);
        } else {
          const isConfirm = await this.$confirm(
            this.$t('message.authIsRequired'),
            {
              type: 'warning',
              confirmButtonText: this.$t('buttons.goEnter')
            }
          );

          if (isConfirm) {
            const redirect = this.$router.resolve(garage).href;

            return this.go({
              name: this.getRouteName('auth'),
              query: { redirect }
            });
          }
        }
      }
    },

    closeProfiles () {
      this.isProfiles = false;
    },

    selectProfile () {
      this.closeProfiles();
      this.goHome();
    },

    goToBusiness () {
      this.go({ name: this.getRouteName('profile.business.edit') });
    },

    goHome () {
      const route = { name: this.getRouteName('home') };

      // TODO fix this spike for home page
      return this.$route.name.includes('home')
        ? this.$router.reload(route)
        : this.go(route);
    },

    go (route) {
      return this.$router.push(route).catch(e => e);
    }
  }
};
</script>

<style scoped lang="scss">
.user-nav {
  .label {
    color: $black;
  }

  .el-menu {
    display: flex;
    justify-content: flex-end;

    .el-menu-item {
      display: flex;
      align-items: center;
    }

    .icon-text ::v-deep {
      * {
        color: $grey-500;
      }

      .active * {
        color: $blue-500;
      }

      padding: 0 4px 0 0;
    }

    ::v-deep .is-active {
      border-color: transparent!important;
    }

    .el-submenu ::v-deep .el-submenu__title {
      border-color: transparent;
    }

    i {
      color: $grey-500;
    }

    .basket-icon {
      margin: 0 4px;
    }

    .btn-city {
      padding-top: 1px;
    }

    .city-name {
      min-width: 33px;
    }

    .car {
      height: 20px;
    }

    @media all and (max-width: $--md) {
      ::v-deep {
        .el-submenu__icon-arrow, .label {
          display: none;
        }

        .el-menu-item, .el-submenu__title {
          padding: 0 4px;
        }
      }
    }
  }
}
</style>
