<template>
  <div class="login-form">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      size="medium"
      @submit.native.prevent="submit"
    >
      <el-form-item :label="$t('auth.login')" prop="login">
        <el-input
          v-model.trim="formData.login"
          :placeholder="`${MARKETPLACE_PHONE_PREFIX}... ${$t('auth.or')} ${$t('auth.email')}`"
        />
      </el-form-item>

      <el-form-item :label="$t('auth.password')" prop="password">
        <el-input
          v-model.trim="formData.password"
          :type="isShowPass ? '' : 'password'"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-view c-pointer"
            @click="isShowPass = !isShowPass"
          />
        </el-input>
      </el-form-item>

      <el-form-item class="mb-2">
        <el-row align="middle" justify="space-between">
          <el-checkbox v-model="formData.remember_me">
            {{ $t('auth.remember') }}
          </el-checkbox>

          <nuxt-link
            :to="{
              name: getRouteName('auth.pass-send'),
              query: { login: formData.login }
            }"
          >
            {{ $t('auth.forgetPassword') }}
          </nuxt-link>
        </el-row>
      </el-form-item>

      <el-form-item>
        <el-row :justify="showCancelBtn ? 'space-between' : 'center'">
          <el-button
            v-if="showCancelBtn"
            class="submit-btn"
            @click="$emit('cancel')"
          >
            {{ $t('buttons.nix') }}
          </el-button>

          <el-button
            class="submit-btn"
            :loading="isLoading"
            native-type="submit"
            type="primary"
          >
            {{ $t('buttons.goEnter') }}
          </el-button>
        </el-row>
      </el-form-item>
    </el-form>

    <template v-if="showSocialLinks">
      <ElDivider>{{ $t('auth.or') }}</ElDivider>
      <SignInLinks @registered="showProfilesOrGoHome" />
    </template>

    <ProfileSelect
      v-if="isProfiles"
      @close="onCloseProfile"
      @select="onSelectProfile"
    />
  </div>
</template>

<script>
import loginMixin from '../mixins/loginMixin';
import { env } from '@/lib/core';
import getValidationFor from '@/lib/core/validations/index';

export default {
  name: 'LoginForm',

  mixins: [loginMixin],

  props: {
    showSocialLinks: Boolean,
    showCancelBtn: Boolean
  },

  data ({ $route }) {
    return {
      MARKETPLACE_PHONE_PREFIX: env.MARKETPLACE_PHONE_PREFIX,
      isLoading: false,
      isShowPass: false,
      rules: getValidationFor(['login', 'password'], 'user'),
      formData: {
        login: $route.query.email,
        password: '',
        remember_me: false
      }
    };
  },

  methods: {
    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          return false;
        }

        this.isLoading = true;

        try {
          const user = await this.$store.dispatch('auth/login', this.formData);
          this.showProfilesOrGoHome(user);
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/theme/divider.scss";

.login-form {
  text-align: center;
}
</style>
