<template>
  <el-form
    ref="form"
    class="seller-trademark-form"
    label-position="left"
    label-width="280px"
    :model="formData"
    :rules="rules"
    size="mini"
    @submit.native.prevent="submit"
  >
    <el-form-item :label="$t('sellerTrademarks.trademark_name')" prop="trademark_name">
      <el-input v-model="formData.trademark_name" />
    </el-form-item>

    <div class="description">
      {{ $t('sellerTrademarks.verificationUrlDescription') }}
    </div>

    <el-form-item
      :label="$t('sellerTrademarks.verification_url')"
      prop="verification_url"
    >
      <el-input v-model="formData.verification_url" />
    </el-form-item>

    <el-row class="text-ghost mb-2" justify="center">
      {{ $t('sellerTrademarks.orAnd') }}
    </el-row>

    <el-form-item prop="verification_document">
      <DocUploader
        v-model="formData.verification_document"
        :label="$t('buttons.addDoc')"
      />

      <el-row v-if="docName" align="middle" justify="space-between">
        <el-link :href="formData.verification_document">{{ docName }}</el-link>
        <el-button
          circle
          icon="el-icon-delete"
          @click="formData.verification_document = null"
        />
      </el-row>
    </el-form-item>

    <div class="description">
      {{ $t('sellerTrademarks.verificationDocumentDescription') }}
    </div>

    <el-row class="mt-2" justify="end">
      <el-button
        v-if="formData.id"
        :disabled="isLoading"
        type="danger"
        @click="remove"
      >
        {{ $t('buttons.remove') }}
      </el-button>

      <el-button :disabled="isLoading" @click="$emit('cancel')">
        {{ $t('buttons.cancel') }}
      </el-button>

      <el-button
        :disabled="isEqual"
        :loading="isLoading"
        native-type="submit"
        type="primary"
      >
        {{ $t('buttons.save') }}
      </el-button>
    </el-row>
  </el-form>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import DocUploader from './DocUploader';
import { getValidationFor, getDefault, AFTER_LAST_SLASH_REG } from '@/lib/core';

const rules = ['trademark_name', 'verification_url'];

export default {
  name: 'SellerTrademarkForm',

  components: {
    DocUploader
  },

  props: {
    value: Object
  },

  data () {
    return {
      isLoading: false,
      rules: getValidationFor(rules, 'sellerTrademark', this),
      formData: cloneDeep(this.value || getDefault('sellerTrademark'))
    };
  },

  computed: {
    businessUrl () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'].url;
    },

    isEqual () {
      return isEqual(this.value, this.formData);
    },

    docName () {
      const { verification_document } = this.formData;
      return verification_document && typeof verification_document === 'string'
        ? verification_document.match(AFTER_LAST_SLASH_REG)[0]
        : null;
    }
  },

  methods: {
    removeFile () {
      this.formData.verification_document = null;
    },

    async remove () {
      try {
        await this.$store
          .dispatch('sellerTrademarks/removeTrademark', {
            id: this.formData.id
          });

        this.$emit('removed', this.formData);
      } catch (e) {
        console.error(e);
      }
    },

    submit () {
      if (this.isEqual) {
        return null;
      }

      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          this.isLoading = true;

          this.formData.business = this.businessUrl;
          const method = this.formData.id ? 'patch' : 'post';

          try {
            const data = await this.$store
              .dispatch(`sellerTrademarks/${method}Trademark`, {
                id: this.formData.id,
                formData: this.formData
              });

            if (this.formData.id) {
              this.$emit('updated', data);
            } else {
              this.$emit('created', data);
            }
          } catch (e) {
            console.error(e);
          } finally {
            this.isLoading = false;
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.seller-trademark-form {
  .description {
    margin-left: 280px;
  }

  .file-uploader-block {
    margin-left: 280px;
  }
}
</style>
