export default {
  langName: 'Українська',
  countryCode: 'ua',
  langCode: 'uk',

  core: {
    yes: 'Так',
    no: 'Ні',
    allReserved: '2018-{year} {mp}. маркетплейс автозапчастин в Україні. Запчастини в твоєму місті.',
    loading: 'Завантаження',
    currency: "Валюта",
    isYourCity: 'Ваше місто - {name}?',
    cityDescription: 'Від обраного міста залежить наявність товару, способи і терміни доставки.',
    selectCity: 'Виберіть місто',
    pageInDevelopmentNow: 'Вибачте, даний розділ знаходиться в розробці.',
    grn: 'грн',
    num: 'шт',
    siteRules: 'Умови використання сайту',
    offerRules: 'Договір оферти',
    protectionPersonalInfo: 'Захист персональних даних',
    questions: "Є питання?",
    from: 'Від',
    forSellers: 'Для продавців автозапчастин',
    marketplaceAddress: 'м.Київ, вул. Шимановського 2/1',
    language: 'Мова сайту',
    contacts: 'Контакти',
    info: 'Інформація',
    payOnSite: 'Оплата на сайті',
    canIHelp: 'Потрібна допомога?',
    weInSocial: 'Ми в соц мережах',
    pageNum: 'сторінка №{page}',
    companyAddress: 'Адреса підприємства (адмінпараметер)',
    warning: 'Увага',
    marketplaceDelivery: 'Доставка',
    marketplacePayment: 'Оплата',
    marketplaceWarranty: 'Гарантія та повернення',
    on: 'на',
    purchase: 'Покупка'
  },

  currencies: {
    UAH: 'грн',
    RUB: 'руб'
  },

  auth: {
    enter: 'Вхід',
    or: 'Або',
    login: 'Email/телефон',
    phone_number: 'Телефон',
    email: 'Email',
    password: 'Пароль',
    remember: 'Запам\'ятати мене',
    goRegister: 'Зареєструватися',
    forgetPassword: 'Забули пароль?',
    registerNewAccount: 'Реєстрація',
    recoveryPassword: 'Відновлення паролю',
    rememberPassword: 'Я згадав пароль',
    resendActivate: 'Повторно надіслати листа',
    activateAccount: 'Активація облікового запису',
    iAmOnlyBy: 'Я тільки купую автозапчастини на свої авто',
    iAmSeller: 'Я займаюсь продажем автозапчастин або ремонтом автомобілей',
    youCanByNow: 'Тепер Ви можете купувати на сайті.',
    anyShopCanBy: 'Будь-який автомагазин чи автосервіс не маючи своїх прайсів може почати торгувати на платформі.',
    registerAgreeWith: 'Рееструючись, ви погоджуєтесь з',
    siteRules: 'умовами сайту',
    thanksForRegistration: 'Дякуємо за реєстрацію на нашому сайті! Обліковий запис створено',
    completionAuth: 'Дякуємо за реєстрацію на сайті. Для завершення реєстрації залишилось активувати обліковий запис. Це можна зробити, якщо перейти за посиланням, що було відправлено вам на поштову скриньку, або ввести ідентифікатор самостійно. Тепер ви можете повною мірою користуватися сайтом. Для входу в свій обліковий запис на сайт використовується email та пароль, вказаний при реєстрації.',
  },

  profile: {
    selectProfile: 'Виберіть профіль',
    buyer: 'Покупець',
    seller: 'Продавець',
    sellerDescription: 'Я продавець автозапчастин або автосервіс',
    addCompanyInfo: 'Додайте інформацію про компанію, в котрій ви працюєте. Це дозволить розширити можливості на сайті.',
    companyUserContacts: 'Контактні дані користувача в компанії',
    setYourContactInfo: 'Вкажіть свої контактні дані, які будуть використовуватися для зв\'язку з Вами у випадках, коли Ви будете оформлювати замовлення або виконувати якісь дії від імені компанії, яку Ви зараз створюєте.',
    changeYourInfoToCompanyInfo: 'За замовчуванням внесені особисті дані. Рекомендуємо їх змінити на робочі контактні дані (корпоративну пошту та телефон), щоб не змішувати особисте з робочим.',
    sellIsEasily: 'Продавати на платформі легко!',
    mainInfoTab: 'Загальні дані',
    deliveryTab: 'Доставка',
    requisitesTab: 'Реквізити',
    trademarksTab: 'Представник ТМ',
    mainInfoBlock: 'Загальні дані про компанію',
    siteBlock: 'Налаштування сайту',
    mainRequisitesBlock: 'Реквізити суб\'єкта підприємницької діяльності',
    contactRequisitesBlock: 'Контактні данні для офіційного спілкування',
    bankRequisitesBlock: 'Банківські реквізити',
    scanFilesRequisitesBlock: 'Завантаження сканкпопії документів',
    removeBusinessConfirm: 'Ви впевнені, що бажаєте видалити бізнес профіль?',
    removeBusinessDescription: 'Видалення профілю назавжди. Видалити профіль може тільки користувач профілю з правами адміністратора.',
    leaveBusinessConfirm: 'Ви впевнені, що бажаєте покинути цей бізнес профіль?',
    leaveBusinessDescription: 'Якщо ви більше не є працівником компанії, то можете піти з профілю. Якщо Ви - адміністратор профілю, то можете залишити профіль тільки, якщо надасте права доступу іншому користувачу профіля.',
    removeBusiness: 'Видалити бізнес профіль',
    leaveBusiness: 'Піти з бізнес профілю',
    name: 'Назва',
    activities: 'Активність',
    isActive: 'Активовано',
    isNotActive: 'Не активовано',
    has_points_of_sale: 'Наявність точок продажу',
    has_prices: 'Наявність прайсів',
    is_seller: 'Продаж запчастин',
    site: 'Адреса сайту',
    search_on_site_url: 'Посилання на результати пошуку',
    order_on_site: 'На сайті можна зробити замовлення товару',
    searchOnSiteUrlDescription: 'Вкажіть URL в яку залишиться тільки додати каталожний номер для пошуку товару. Тоді користувачі зможуть переходити до вас на сайт з сайту платформи. Наприклад на платформі це URL <strong>{mp}/search?q=</strong>',
    _agreeWithPublicOffer: 'Ви погоджуєтесь з <a class="el-link el-link--primary" href="/docs/public-offer" target="_blank" @click.stop> умовами публічної оферти</a>',
  },

  account: {
    registrationBlock: 'Вхід на сайт та спілкування',
    accountInfoBlock: 'Особисті дані',
    contactsBlock: 'Контактні дані в профілі',
    nameForSocialDescription: 'Відображається іншим користувачам в коментарах та продавцям, як замовник (якщо не заповнено поле ім\'я)',
    accountInfoDescription: 'Використовується за замовчуванням при оформленні замовлень, та відображається продавцю під час написання йому відгуку.',
    contactsDescription1: 'Вкажіть свої контактні дані, які будуть використовуватися для зв\'язку з вами у випадках, коли Ви будете оформлювати замовлення або виконувати якісь дії.',
    contactsDescription2: 'За замовчуванням внесені особисті дані. Рекомендуємо їх змінити на робочі контактні дані (корпоративну пошту та телефон), щоб не змішувати особисте з робочим.',
  },

  salePoint: {
    setAddress: 'Вкажіть адресу точки продажу та додайте координати, щоб ваша точка продажу відображалась на мапі.',
    setPhones: 'Вкажіть контактні номери телефонів організації, а також їх призначення (наприклад, Бухгалтерія).',
    setEmails: 'Вкажіть email, на який будуть приходити повідомлення про події профілю, і пояснення, якщо необхідно (Наприклад: для рахунків).',
    salePoint: 'Точка продажу',
    createSellPointFirst: 'Спочатку створіть точку продажу.',
    setMinInfo: 'Для створення точки продажу вкажіть мінімальні необхідні дані. Повні дані про точку продажу можна буде ввести на формі редагування після створення.',
    pointName: 'Назва точки продажу',
    primary: 'Основний',
    status: 'Статус',
    isActive: 'Магазин працює',
    mainInfoTab: 'Загальні дані',
    deliveryTab: 'Доставка',
    saleTermsTab: 'Умови продажу',
    returnWarrantyTermsTab: 'Повернення та гарантія',
    paymentAndDeliveryTab: 'Оплата та доставка',
    contactsTab: 'Контакти',
    trademarksTab: 'Представник ТМ',
    descriptionTab: 'Опис',
    mainInfoBlock: 'Загальні дані про точку продажу',
    addressBlock: 'Адреса',
    contactsBlock: 'Телефони та електронна пошта',
    scheduleBlock: 'Години роботи',
    paymentTermsBlock: 'Умови замовлення та оплата',
    paymentMethodsBlock: 'Способи оплати',
    deliveryMethodsBlock: 'Способи доставки, та видачі товару',
    returnTermsBlock: 'Умови повернення',
    warrantyTermsBlock: 'Умови гарантії',
    descriptionBlock: 'Опис точки в вільній формі',
    photosBlock: 'Фотографії точки продажу',
    otherSellingTermsBlock: 'Додатково',
    allContactsBlock: 'Контактні дані',
    setCountryFirst: 'Спочатку вкажіть країну',
    setCountryAndAdminFirst: 'Спочатку вкажіть країну та область',
    selectSalePoint: 'Вибір точки продажу',
    description: 'Про компанию',
    comment: 'Коментар',

    payment_methods_text: 'Додаткові умови оплати',
    delivery_methods_text: 'Додаткові умови доставки',
    return_terms_text: 'Додаткові умови повернення',
    warranty_terms_text: 'Додаткові умови гарантії',
    minPrice: 'Мінімальна сума',
    minPriceDesc: 'Мінімальна сума для того, щоб доставка була безкоштовною'
  },

  ware: {
    details: 'Детальна інформація',
    wareCard: 'Картка товару',
    mainInfo: 'Основна інформація',
    characteristics: 'Характеристики',
    kits: 'Комплектність',
    applicability: 'Застосування на автомобілях',
    description: 'Опис',
    wareId: 'КОД',
    wareSeller: 'Продавець',
    mainCharacteristic: 'Основні характеристики',
    country_name: 'Країна-виробник',
    delivery: 'Доставка',
    payment: 'Оплата',
    yourWare: 'Ваш товар',
    characteristic: 'Характеристики',
    fromKits: '{name} є частиною комплекта в наступних товарах',
    toKits: 'Комплектація {name}',
    showAllProposition: 'Показати всі ціни',
    recommendedSubstitutes: 'Рекомендовані замінники',
    article: 'Каталожний номер',
    trademark: 'Бренд',
    yourShop: 'Ваш магазин',
    photo: " — Фото №",
    selectCarFormApplicability: 'Оберіть марку модель, щоб дізнатися про застосування',
    characteristicDescription1: 'Характеристики та комплектація можуть бути змінені виробником.',
    characteristicDescription2: 'Колір виробу може відрізнятися через налаштування монітора.',
    history: "Історія змін артикулу",
    is_former_use: 'б/у',
    isInOtherSalePoint: 'Товар доступний в інших точках продажу'
  },

  search: {
    filter: 'Фільтр',
    order: 'Сортувати',
    look: 'Вигляд',
    ware: 'Товар',
    orderNumber: 'Замовлення № {id}',
    packageParams: 'Параметри товару в упаковці',
    allCharacteristic: 'Всі характеристики',
    selectProducer: 'Оберіть виробника зі списку',
    main: 'Основні характеристики',
    analogues: 'Аналоги',
    kits: 'Комплекти',
    application: 'Застосування на авто',
    variants: 'Варіанти',
    emptyResult: 'На жаль, ми не змогли знайти жодної запчастини за каталожним номером ИКН. Перевірте коректність каталожного номеру. Якщо Вам відомо, що автозапчастина дійсно існує – відправте інформацію нашим спеціалістам. Після перевірки вона буде додана до пошукової системи.',
    tm_name: 'TM',
    nomenclature: 'Номенклатура',
    name: 'Назва',
    article: 'Артикул',
    arc: 'Кат. ном.',
    article_name: 'Опис',
    amount: 'Кількість',
    am: 'Кіл-ть',
    city: 'Місто',
    address: 'Адреса',
    changed_from: 'Замінений з',
    changed_to: 'Замінений на',
    brutto_x_sm: 'Розмір Х (см)',
    brutto_y_sm: 'Розмір Y (см)',
    brutto_z_sm: 'Розмір Z (см)',
    brutto_volume_sm: 'Об\'єм, см³',
    brutto_weight_kg: 'Вага, кг',
    size_sm: 'Габарити, см',
    formKits: '{name} є комплектом, який складається з елементів',
    toKits: '{name} є составляючою в комплектах',
    optionsTitle: 'В якому ще вигляді зустрічається товар в джерелах інформації',
    foundOtherOptions: 'Знайдено інших неперевірених варіантів {count}',
    lineValue: '* - вказує на значення товарної лінії',
    price: 'Ціна',
    priceInterval: 'Інтервал цін',
    seller: 'Продавець',
    term: 'Термін',
    delivery: 'Доставка',
    point_of_sale: 'Точка продажу',
    info: 'Інфо',
    formerUse: 'товар б/в',
    wareProp: 'Пропозиції на {name} та його замінників',
    substitutes: 'Замінники',
    in_stock: 'В наявності',
    not_in_stock: 'Продано',
    todayIn: 'Сьогодні',
    tomorrow: 'Завтра',
    formDist: 'Зі складів дистриб\'ютера',

    // SEARCH SETTINGS
    searchSettings: 'Додаткові налаштування пошуку',
    searchPriority: 'Пріорітет пошуку на мапі',
    sortByDeliveryTime: 'Необхідно якнайшвидше, ціна вторична',
    sortByPrice: 'Необхідно якнайдешевше, є час зачекати',
    '-rank-delivery_time-price': 'Пріоритет за терміном',
    '-rank-price-delivery_time': 'Пріоритет за ціною',
    showOnlyArticleOnMap: 'На карті відображені пропозиції тільки від продавців, які мають фізичні точки продажу',
    noResults: 'Пропозицій не знайдено',
    searching: 'Пошук...',
    noSell: 'Замовлення можливе лише через сайт продавця',
    addToBasket: 'Додати в кошик',
    withVat: 'З ПДВ',
    showAllSellers: 'Показати всіх продавців',
    noSelectedArticles: 'Відсутні пропозиції за запрошеним артикулем {article}',
    invitedArticle: 'Запрошений товар',
    invitedArticleAndAnalogs: 'Запрошений товар та аналоги',
    retailPrice: 'Роздрібна ціна',
    ascSellerAboutWare: 'Задати питання про товар',
    doSort: 'Сортувати',
    lowerCost: 'Дешевше',
    higherCost: 'Дорожче',
    higher: 'Популярне',
    searchTitle: "{search} - результати пошуку на {mp}",
    availabilityAtSeller: 'Наявність у продавця',
    searchVariants: 'Шукати варіанти',
    optimalPrice: 'Оптимальна ціна'
  },

  orders: {
    order: 'Замовлення',
    cancelOrderItem: 'Скасувати замовлення на цей товар',
    filter: 'Фільтрувати',
    onlyStatus: 'Тільки зі статусом',
    resetFilters: 'Скинути фільтри',
    filterByStatus: 'Фільтрувати за статусом',
    orderActions: "Дії з замовленням",
    contactAddress: 'Адреса та контактні дані',
    salePointInfo: 'Інформація про продавця',
    thanksForOrder: 'Дякуємо за замовлення!',
    checkProgress: 'Відслідковувати стан замовлення',
    forPermanentLink: 'За постійним посиланням',
    forTelegramBot: 'В Telegram каналі. Для цього зараз зайдіть до нашого бота',
    forEmail: 'На email',
    deliveryMethod: 'Спосіб отримання',
    deliverer: 'Перевізник',
    address: 'Адреса',
    service_office_number: 'Відділення',
    orderSum: 'Сума замовлення',
    stockQuantity: 'Максимальна кіькість',
    fastOrder: 'Швидке замовлення'
  },

  prices: {
    rePushConfirm: 'Ви впевнені що файл необхідно перечитати?',
    removeConfirm: 'Ви впевнені що бажаєте видалити прайс {name}?',
    saleYourWaresEasy: 'Розпродайте свої залишки швидко і зручно.',
    priceLoadAction: 'Завантажте свій прайс та залишки свого постачальника',
    priceLoadOption1Description2: 'Для випадків, коли файл, що завантажується виконаний на основі прайсу постачальника, невідомого платформі. Завантажте прайс з вашими залишками і, при бажанні, ви зможете дати дозвіл на торгівлю вашими товарами іншим продавцям.',
    name: 'Назва прайсу',
    status: 'Статус',
    finish: 'Завершено, помилок немає',
    currency: 'Валюта прайсу',
    state: 'Стан',
    pushed: 'Дата оновлення',
    lastUpdate: 'Дата останнього оновлення',
    delivery_time: 'Термін поставки',
    formatting: 'Налаштування файлу для імпорту даних на платформу',
    uploadStatus: 'Статус обробки файлу',
    uploadSource: 'Джерело завантаження',
    fileSize: 'Розмір: {size} Mb',
    downloadFile: 'Скачати файл',
    creationPriceScheme: 'Створення нового ціноутворення',
    order_time_until: 'Прийом замовлень до',
    warehousesRate: 'Графік поставки зі складу',
    priceInQueueForUpdating: 'Прайс в черзі на оновлення',
    step: 'Крок',
    steps: [
      'Завантаження файлу',
      'Налаштування роботи з прайсом',
      'Налаштування обробки',
      'Склади і терміни поставки',
      'Результати обробки'
    ],
    statuses: {
      done: 'Завантажено',
      error: 'Помилка',
      in_progress: 'В обробці',
      api_timeout: 'Час очікування відповіді з сервера вичерпано',
      0: 'Невідома помилка сервісу Priceload.',
      1: 'Невідома помилка сервісу Priceload API.',
      '1_1_1': 'Помилка при реєстрації завантаження файла.',
      '2': 'Помилка обробки прайс-файлу.',
      '2_1': 'Формат файлу не підтримується.',
      '2_1_1': 'Формат архіву не підтримується.',
      '2_1_1_1': 'Підтримується тільки 1 файл у архіві, отримано {files_in_archive}.',
      '2_1_2': 'Кодування файлу {encoding} не підтримується.',
      '2_1_3': 'Помилка читання CSV',
      '2_2': 'Помилка розмінки (маппінгу) файлу.',
      '2_2_1': 'Помилка розмінки (маппінгу) колонок файлу - маппінг не валідний.',
      '2_2_2': 'Сігнатура колонок змінилась в прайс-вайлі.',
    },
    uploadSources: {
      handle: 'Ручне',
      auto: 'Автоматичне'
    },
    selectUpdateMode: 'Оберіть спосіб автоматичного/ручного завантаження',
    manualUpdate: 'Ручне',
    urlUpdate: 'По ftp або http, https',
    manualSettings: 'Налаштування автоматичного оновлення по ftp або http, https',
    manualSettingsDescription1: 'Вкажіть повний шлях до файлу, починаючи з ftp або http, https',
    manualSettingsDescription2: 'Якщо у вас ftp з паролем, то вкажіть її в URL по схемі ftp://логін:пароль@<хост>:порт/шлях_до_файлу',
    docTypeMarkupDescription: 'Налаштування автоматичного завантаження прайсу на платформу можна буде зробити після вдалого опрацювання файлу. Також можна буде змінити всі інші налаштування файлу.',
    error_code: 'Код помилки',
    error_message: 'Сповіщення помилки'
  },

  priceFile: {
    isActive: 'Показувати в результатах пошуку',
    settingsTab: 'Налаштування прайсу',
    processingTab: 'Обробка файлу',
    assortmentTab: 'Асортимент',
    mainInfoBlock: 'Загальна інформація',
    mappingBlock: 'Налаштування обробки файлу',
    autoUpdateBlock: 'Автоматичне оновлення',
    availabilitiesBlock: 'Налаштування складів та терміну доставки',
    uploadInfoBlock: 'Дані файла прайсу',
    name: 'Назва прайсу',
    currency: 'Валюта в файлі',
    dataStartRow: 'Номер першого рядка з даними',
    noHeaderCol: 'Немає заголовків стовпчиків',
    rowWithTitles: 'Номер рядка із заголовком стовпчиків',
    oneTradeMarkInPrice: 'В прайсі одна торгова марка',
    noCounterInfoInPrice: 'Інформація про кількість у файлі відсутня',
    description: 'Опис прайсу',
    priceReUpload: 'Оновлення файла прайсу',
    created: 'Дата створення файлу',
    updated: 'Дата завантаження',
    quantity_warehouse_is_own: 'Прайс містить власні залишки',
    availabilitiesDescription: 'Якщо графік доставки замовлень зі складу до точки продажу залежить від дня тижня, то можна додатково налаштувати ці особливості роботи, скориставшись календарем',
    availabilitiesDescription2: 'Якщо товар зберігається в точці продажу, то вкажіть "Термін поставки" 0 діб 0 годин',
    firstTenRow: 'Перші 10 рядків файлу, що завантажується',
    auto: 'Автоматично',
    file_encoding: 'Кодування файлу',
    csv_delimiter: 'Символ розділення стовпчиків',
    csvTitle: 'Налаштування текстового файлу {name}',
    emptyPreview: 'Дані попереднього перегляду відсутні',
    waitPreview: 'Генерується превью файлу. Не закривайте вікно.',
    preview: "Номер сторінки для попереднього перегляду",
    data_sheet: 'Номер сторінки обробки',
    dataSheetDescription: 'Дані будуть завантажені лише з вказаної сторінки!',
    payment_terms_text: 'Умови передплати (умови будуть відображені покупцям)',
    warrantedReturnTermsText: 'Умови гарантії та повернення (умови будуть відображені покупцям)',
    fullPrepaymentForPrice: 'Потрібна передоплата для замовлення з цього прайсу'
  },

  priceStatusesFields: {
    price_file_id: 'Ідентифікатор прайс файла',
    articles_count: 'Кількість артикулів',
    price_articles_count: 'Кількість артикулів з цінами',
    stock_articles_count: 'Кількість артикулів з складами',
    updated: 'Дата оновлення',
    recognized_articles_count: 'Кількість розпізнаних артикулів',
    offers_count: 'Кількість пропозицій',
    offers_refreshed: 'Оновлені пропозиції',
    offers_fresh_count: 'Кількість нових пропозицій',
    wares_count: "Кількість ідентифікованої номенклатури",
    wareless_count: "Кількість не ідентифікованої номенклатури",
    stocks_count: "Кількість номенклатури із ненульовими залишками"
  },

  orderStatuses: {
    'null': 'Кошик',
    new: 'Створено',
    in_progress: 'В роботі',
    ready: 'Підготовлено',
    issued: 'Видано',
    autoended: 'Завершено автоматично',
    buyer_canceled: 'Відмінено покупцем',
    buyer_rejected: 'Відхилено покупцем',
    seller_canceled: 'Знято продавцем',
  },

  warehouses: {
    warehouse: 'Склад',
    creationWarehouses: 'Створення складу',
    name: 'Назва складу'
  },

  priceMapping: {
    article: 'Каталожний номер',
    trademark: 'Торгова марка',
    title: 'Назва товару',
    description: 'Опис товару',
    selling_price: 'Ціна продажу',
    purchase_price: 'Ціна закупівлі',
    quantity: 'Кількість на складі',
    multiplicity: 'Кратність продажу',
    note: 'Додатковий опис товару',
    image: 'Посилання на зображення товару',
    analog_article: 'Каталожний номер аналога',
    analog_trademark: 'Торгова марка аналогу',
    barcode: 'Штрихкод',
    ucgfea: 'УКТ ЗЕД Код',
    wholesale: 'Опт',
    retail: 'Роздріб',
    createWarehouse: '+ Створити склад',
    remove_make_selling_price: 'Ціна продажу',
    isMappingProcessing: 'Процес обробки мапінгу. Зачекайте...',
    image_type: 'Тип картинки',
    weight: 'Вага',
    weight_tare_type: 'Тип ваги',
    sizes: 'Габарити',
    sizes_tare_type: 'Тип габаритів'
  },

  weightUnitTypes: {
    'kg': 'Кілограм',
    'g': 'Грам'
  },

  sizesUnitTypes: {
    'mm': 'Міліметр',
    'cm': 'Сантиметр',
    'm': 'Метр'
  },

  relationTypes: {
    'analog': 'Аналог',
    'includes': 'Містить в собі',
    'part_of': 'Є частиною',
    'replaced_by': 'Замінений на',
    'replaces': 'Є заміною',
    'improved_analog': 'Покращений аналог',
    'simplified_analog': 'Спрощений аналог',
    'universal_analog': 'Універсальний аналог',
    'specific_analog': 'Аналог з уточненням',
    'lays_inside': 'Лежить всередині',
    'is_container_for': 'Є упаковкою',
    'couple': 'Парний артикул (лівий/правий)',
    'used_alternative_code': 'Альтернативний код (використовується)',
    'unused_alternative_code': 'Альтернативний код (не використовується)'
  },

  tareTypes: {
    netto: 'Нетто',
    brutto: 'Брутто'
  },

  imageTypes: {
    general: 'Зображення номенклатури',
    schema: 'Схема встановлення',
    promo: 'Промо зображення',
  },

  deliveryRate: {
    deliveryRateCreating: 'Створення нового тарифу',
    askAddRate: 'Додавати в ціну товару ціну витрат на доставку, від складу постачальника до точки продажу?',
    notUseRate: 'Не додавати',
    addByRate: 'Додавати згідно тарифу',

    creationNewRate: 'Створення нового тарифу',
    existName: 'Існуючий тариф',
    name: 'Назва тарифу що створюється',
    currency: 'Валюта параметрів на формі',
    deliveryPrams: 'Параметри для урахування логістичних та інших витрат',

    correction_factor: 'Процент від вартості',
    constant: 'Константа{name}',
    weight_rate: 'Тариф за вагу{name}',
    no_weight_factor: 'Процент без ваги',
    no_trade_without_weight: 'Не торгувати без ваги',
  },

  priceScheme: {
    type: 'Назва схеми ціноутворення',
    name: 'Назва схеми ціноутворення',
    currency: 'Валюта параметрів на формі',
    logisticParams: 'Параметри для урахування логістичних та інших витрат',
    selectPriceCreationScheme: 'Оберіть схему ціноутворення',
    dynamicPriceSchemeParams: 'Параметри для динамічного ціноутворення',
    min_price: 'Мінімальна ціна, {name}',
    max_price: 'Максимальна ціна, {name}',
    min_markup: 'Мінімальна націнка, %',
    max_markup: 'Максимальна націнка, %',
    types: {
      static: 'Єдина націнка незалежно від ціни, %',
      dynamic: 'Націнка залежить від ціни'
    },
    removeConfirm: 'Ви впевнені, що бажаєте видалити "{name}"?'
  },

  files: {
    uploadError: 'Полмилка завантаження',
    validateError: 'Файли не валідні',
    filePath: 'Шлях до файла',
    fileName: 'Ім\'я файла',
    priceRules: {
      1: 'Файл може бути архівованим: zip, rar.',
      2: 'Розмір файлу не може бути більше ніж 5 Гб.',
      3: 'Формати файла, що приймає наша платформа: {formats}.'
    },
    dropOrClick: 'Перетягніть файл сюди, або натисніть для завантаження'
  },

  sellerTrademarks: {
    orAnd: 'та/або',
    sellerTrademarksDescription: 'Якщо ваша організація є офіційним представником виробника і при цьому має прямі контракти на поставки, то перерахуйте торгові марки, які Ваша компанія представляє. По можливості вкажіть посилання на сторінку офіційного сайту виробника, на котрій є підтвердження цього факту або надайте скан документу.',
    sellerTrademarksConfirmDescription: 'Після створення списку, будь ласка, зв\'яжіться з представником платформи, щоб він підтвердив статуси офіційного представника. Тільки після підтвердження на платформі всі пропозиції з Вашого особистого складу будуть відмічені, як товари від офіційного представника ТМ.',
    createTm: 'Додати нову торгову марку',
    editTm: 'Редагування торгової марки',
    trademark_name: 'Назва ТМ',
    verification_url: 'Посилання на офіційний сайт ТМ',
    verificationUrlDescription: 'Посилання на сторінку офіційного сайту ТМ, де є підтвердження статусу офіційного представника ТМ',
    verificationDocumentDescription: 'Якщо немає посилання на офіційний сайт ТМ для підтвердження статусу, то завантажте документ, де вказана інформація про офіційний статус скан сертифікату, або скан сторінки договору з ТМ. Документ буде доступний виключно представнику платформи виключноз метою перевірки статусу офіційного представника!!!',
    verificationConfirmed: 'Підтверджено',
    activeStatus: 'Статус',
    statuses: {
      new: 'Новий',
      approved: 'Підтверджено',
      rejected: 'Відхилено'
    }
  },

  basket: {
    title: 'Кошик',
    index: '№',
    important: 'Важливо',
    orders: 'Замовлення',
    doYouHaveAccount: 'Маєте обліковий запис на нашому сайті?',
    remove: {
      title: 'Видалення вмісту кошика',
      message: 'Ви впевнені, що хочете видалити весь вміст кошика?'
    },
    count: 'Сума',
    sum: 'Сума: {sum}',
    toBePaid: 'До сплати',
    total: 'Всього',
    sumAllOrders: 'Вартість всього кошика',
    tableDescription: {
      1: 'Термін доставки вказаний в робочих днях до точки продажу продавця.',
      2: 'Кінцева ціна може відрізнятися від тієї, що відображена на сайті через невідповідність курсу валют та/або через наявність додаткових платежів за доставку та/або комісію банку.',
      3: 'Товар в кошику не зарезервовано у продавця.',
    },
    display_article: 'Номенклатура',
    display_title: 'Опис',
    delivery_time: 'Термін',
    paidStatus: 'Статус оплати',
    is_paid: 'Оплачено',
    price: 'Ціна',
    quantity: 'Кіл-ть',
    quantity2: 'Кількість',
    totalAmount: 'Загалом: {cur}',
    status: 'Статус',
    buyer: 'Покупець',
    buyer_type: 'Хто буде покупцем',
    freeShipping: 'Безкоштовна доставка',
    buyerTypes: {
      person: 'Приватна особа',
      organization: 'Організація'
    },
    additionally: 'Додатково',
    sellerPhones: 'Телефони',
    buyer_first_name: 'Ім\'я',
    buyer_last_name: 'Прізвище',
    buyer_phone_number: 'Телефон покупця',
    buyer_email: 'Email покупця',
    salePointAddress: 'Адреса точки продажу',
    orderAddress: 'Адреса отримання замовлення',
    delivery_method: 'Спосіб отримання замовлення',
    delivery_address: 'Адреса отримання замовлення',
    createAddress: '+ Додати нову адресу',
    orderAddressCreation: 'Створення адреси отримання',
    deliveryService: 'Служба доставки',
    deliveryServiceName: 'Назва служби',
    deliveryRegion: 'Область',
    deliveryCity: 'Населений пункт',
    orderRecipient: 'Отримувач',
    recipient_phone_number: 'Телефон',
    recipient_last_name: 'Прізвище',
    recipient_first_name: 'Ім\'я',
    paymentMethod: 'Оплата',
    payment_method: 'Спосіб оплати',
    commentForm: 'Коментар до замовлення',
    no_call_required: 'Дзвонити тільки у випадку, якщо щось пішло не так',
    agreementForm: 'Згода з умовами виконання замовлення',
    _agreeWithPublicOffer: 'Замовивши ви погоджуєтесь з <span class="el-link el-link--primary"> умовами публічної оферти</span>',
    selectAddressType: 'Оберіть тип адреси',
    selectDeliveryService: 'Оберіть службу',
    service_name: 'Назва служби',
    setServiceName: 'Вкажіть назву',
    service_office_number: 'Назва відділення',
    orderNum: '№ замовлення',
    basketIsEmpty: 'Кошик порожній',
    seller: 'Продавець',
    ware: 'Товар',
    priceFile: 'Прайс',
    shipment: 'ТТН',
    availability: 'Наявність',
    display_public_id: 'Номер замовлення',
    orderDetailsAndTerms: 'Реквізити та умови замовлення',
    rejectOrderProcessing: 'Відмова від обробки замовлення',
    setCancelInitiator: 'Вкажіть, хто був ініціатором відмови від замовлення',
    orderRecipientCreate: 'Створення Отримувача замовлення',
    orderShipment: 'Видача замовлення',
    currentClientShipments: 'Поточні відвантаження на клієнта',
    waybillTitle: 'Транспортна накладна',
    waybill: 'Номер транспортної накладної',
    waybillNum: 'Номер',
    waybill_date: 'Дата створення',
    addressesInItems: 'Адреси, що були в замовленнях',
    shipmentComment: 'Примітка виконання замовлення. Буде відображатися Покупцю',
    shipmentCreateDate: 'Дата видачі',
    prev_price: 'Попередня ціна',
    addShipment: '+ Додати нове відвантаження',
    wareAddToBasket: 'Товар додано в кошик',
    createOrder: 'Оформлення замовлення',
    buyerData: 'Дані покупця',
    agreeWithPublicOffer: 'Оформляючи замовлення, я погоджуюсь <br> з <a class="el-link el-link--primary" href="{href}" target="_blank" @click.stop>умовами договору публічної оферти</a>',
    oneClickBuyDesc: 'Просто залиште свій номер, на який Вам передзвонить продавець та уточнить умови замовлення.',
    setPhoneNumber: 'Вкажіть номер телефону'
  },

  catalog: {
    brand: 'Марка авто',
    model: 'Модель',
    generation: 'Покоління',
    production_year: 'Рік випуску',
    body_type: 'Тип кузова',
    drive_type: 'Привід',
    engine_type: 'Тип двигуна',
    engine_code: 'Код двигуна',
    capacity: 'Об\'єм двигуна',
    fuel_type: 'Тип палива',
    fuel_types_and_capacitie: 'Двигун',
    modification: 'Модифікація',
    garage: 'Гараж',
    nowTime: 'поточний час',
    ls: 'лс',
    catalog: 'Каталог автозапчастин',
    catalogFor: 'Каталог - ',
    autoParts: 'Запчастини',
    for: 'до',
    on: 'на',
    in: 'в',
    allCars: 'Всі автомобілі',
    autoPartsForAllCars: 'Запчастини на всі автомобілі',
    autoPartsForCars: 'Запчастини до автомобілів',
    allCarsModels: 'Всі модели',
    allPartsFor: 'Всі запчастини до {name}',
    allTrademarks: 'Всі виробники',
    trademarks: 'Виробники',
    topProducts: 'Популярні пропозиції',
    topCarsBrands: 'популярні марки автомобілів',
    topCarsModels: 'популярні моделі {name}',
    topCarsGenerations: 'популярні покоління авто {name}',
    topCarsModifications: 'популярні модифікації авто {name}',
    topProductLines: 'Популярні запчастини | Популярні запчастини до {name}',
    topTrademarks: 'Популярні виробники {name}',
    topRegions: 'Купити {name} в містах України',
    pricesFor: 'Ціни на',
    auto: 'автомобілів',
    readMore: "Читати повністю",
    vinInquiries: 'Запити по VIN на підбір'
  },

  garage: {
    removeConfirm: 'Ви впевнені що бажаєте видалити {name} назавжди? (операція не може бути відмінена)',
    requestToSelectionByVin: 'Запит на підбір запчастин по VIN',
    requireVinForSelectionRequest: 'Для оформлення запиту на підбір запчастин необхідно вказати VIN транспортного засобу',
    requireCarForVin: 'Для оформлення запиту на підбір по VIN необхідно обрати авто',
    selectionRequest: 'Запит на підбір',
    requestCreated: 'Запит відправлено',
    answersCount: 'Всього відповідей',
    expiry: 'Поліс дійсний до {date}',
    autoPartsForTO: 'Запчастини для ТО',
    originalCatalog: 'Оригінальний каталог',
    engine: 'Двигун (об`єм, потужність)',
    cubSm: 'Куб. см.',
    characteristics: 'Характеристики',
    auto: 'автомобіль',
    statusSelect: 'Вибір статусу авто',
    color: 'Колір',
    chooseYourCar: 'Оберіть авто',
    chooseYourCarForStart: 'Почніть пошук запчастин з вибору авто',
    startWithCarBrand: 'Почніть з вибору марки авто',

    selectTitles: {
      brand: 'Почніть пошук запчастин з вибору марки авто',
      model: 'Оберіть модель',
      production_year: 'Оберіть рік',
      body_type: 'Оберіть кузов',
      fuel_types_and_capacitie: 'Оберіть двигун',
      drive_type: 'Оберіть привід',
      modification: 'Оберіть модифікацію'
    },

    selectorDesc: {
      production_year: 'Випускались в наступних роках:',
      body_type: 'В <strong>{year}</strong> році випускалась з такими типами кузова:',
      fuel_types_and_capacitie: '<strong>{year}, {bodyType}</strong> випускались з такими двигунами:',
      drive_type: '<strong>{year}, {bodyType}, {fuelTypesAndCapacitie}</strong> випускались з такими приводами:',
      modification: 'Випускались в наступних модифікаціях'
    },

    selectionRequests: {
      rawItem: 'Необхідна запчастина',
      itemComment: 'Коментар до рядка',
      comment: 'Коментар до запиту',
      answersCount: 'Відповідей'
    },

    factory: {
      brand: "Марка автовиробника",
      model: "Модель",
      productionYear: "Рік випуску",
      bodyType: "Тип кузова",
      engineType: "Тип двигуна",
      fuelType: "Тип палива",
      engineCapacity: "Об'єм двигуна",
      modification: "Модифікація",
      engineCode: "Код двигуна",
      powerPs: "Потужність двигуна",
      gearType: "Коробка передач",
      driveType: "Тип приводу",
      brakeType: "Тип гальм",
      cylinders: "Кількість циліндрів",
      gearSteps: "Кількість передач",
    },

    main: {
      vin: "VIN",
      licensePlate: "Госномер",
      insuranceExpiryDate: "Поліс дійсний до",
      color: "Колір",
      comment: "Коментар до авто",
      commentDesc: '(ніхто не побачить, крім вас)'
    },

    securityTitle: 'Безпека',

    security: {
      sHasAbs: 'ABS',
      sHasAsr: 'ASR',
      sHasEsp: 'ESP',
      sHasAbd: 'ABD',
      sCentralLock: 'Центральний замок',
      sAirbag: 'Подушка безпеки',
      sSignalling: 'Сигналізація',
      sImmobilizer: 'Іммобілайзер',
      sHeadlight: 'Тип фар',
      sHasBsm: 'Асистент мертвих зон',
      sHasNv: 'Нічне бачення',
      sHasGearLock: 'Замок на КПП',
      sHasAirSuspension: 'Пневмопідвіска',
      sHasArmoredBody: 'Броньований автомобіль',
    },

    securityOptions: {
      sCentralLock: {
        no: 'Відсутне',
        original: 'Штатний',
        unoriginal: 'Нештатний'
      },

      sAirbag: {
        no: 'Відсутне',
        driver_only: 'Тільки водія',
        several: "Декілька"
      },

      sSignalling: {
        no: 'Відсутне',
        original: 'Штатна',
        unoriginal: 'Нештатна'
      },

      sImmobilizer: {
        no: 'Відсутне',
        original: 'Штатна',
        unoriginal: 'Нештатна'
      },

      sHeadlight: {
        halogen: 'Галогенні',
        xenon: 'Ксенон',
        bixenon: 'Біксенон',
        adaptive_xenon: 'Адаптивний ксенон',
        diode: 'Діодні'
      }
    },

    multimediaTitle: 'Мультимедіа',

    multimedia: {
      mHasMp3: 'MP3',
      mHasCd: 'CD',
      mHasTapeRecorder: 'Магнітола',
      mHasAcoustics: 'Акустика',
      mHasDvd: 'DVD',
      mHasGps: 'Система навігації GPS',
      mHasSubwoofer: 'Сабвуфер',
      mHasBluetooth: 'Bluetouth',
      mHasCarPlay: 'CarPlay',
      mHasAndroidAuto: 'Android Auto',
    },

    comfortTitle: 'Комфорт',

    comfort: {
      cHasPowerSteering: 'Підсилювач керма',
      cWindowLifters: 'Скло підіймач',
      cHasRoofRails: 'Рейлінги',
      cHasParkAssist: 'Ассистент парковки',
      cHasLaneAssist: 'Ассистент полос',
      cHasConditioner: 'Кондиціонер',
      cHasMirrorsHeating: 'Підігрів зеркал',
      cHasMirrorsDimming: 'Затемнення зеркал',
      cClimateControl: 'Клімат контроль',
      cSeatsHeating: 'Підігрів сидінь',
      cHasMultimediaSteering: 'Мультимедійний руль',
      cHasCruiseControl: "Круіз-контроль",
      cHasParktronic: 'Парктронік',
      cHasLightSensor: 'Датчик світла',
      cInteriorMaterial: 'Матеріал оббивки салону',
      cHasRainSensor: 'Датчик дождя',
      cSunroof: 'Люк',
      cHasHeadlightWasher: 'Омивач фар',
      cHasStartButton: 'Запуск кнопкою',
      cHasSeatMemory: 'Пам`ять сидінь',
      cHasSteeringHeating: 'Підігрів руля',
      cHasSummerTires: 'Летні шини',
      cHasWinterTires: 'Зимні шини',
      cHasGlassTinting: 'Тонування скла',
      cHasTowbar: 'Фаркоп',
      cHasGasInstallation: 'Газове обладнання',
    },

    comfortOptions: {
      cInteriorMaterial: {
        fabric: 'Ткань',
        velor: 'Велюр',
        leather: 'Кожа',
        alcantara: 'Алькантара'
      },

      cSunroof: {
        no: 'Відсутне',
        sunroof: 'Люк',
        panorama: 'Панорама'
      },

      cWindowLifters: {
        manual: 'Ручні',
        mixed: 'Перед електро, зад ручні',
        electronic: 'Електро'
      },

      cSeatsHeating: {
        no: 'Відсутне',
        front: 'Передні',
        back: 'Задні',
        all: 'Всі'
      },

      cClimateControl: {
        no: 'Відсутне',
        '1-zone': 'Одна зона',
        '2-zone': 'Дві зони'
      }
    },

    characteristicsTitle: 'Технічні характеристики',

    // Статус відображення
    statuses: {
      mine: 'Належить мені зараз (початковий статус)',
      not_mine: 'Авто мені не належить',
      on_sale: 'Продаж',
      given: 'Подарував (віддав у спдок)',
      stolen: 'Крадіжка',
      broken: 'ДТП. Відновленню не підлягає',
      utilized: 'Утилізація',
    }
  },

  sRequests: {
    requestSubmit: 'Запит на підбір №{id} відправлено',
    requestSubmittedDesc: 'Зачекайте на відповіді від продавців автозапчастин, та потім виберіть з запропонованих варінтів найкращій.',
    showRequestLinkDesc: 'Дивитись відповіді без реєстрації на запит по VIN за постояним посиланням',
    createRequestByVin: 'Знайти автозапчастини за допомогою професіоналів',
    createRequestByVinDesc: `
      <p>Якщо ви не розумієте, як підбирати запчастину, або вагаєтесь, який саме варіант запчастини підходить саме на вашу автівку, то скористайтесь БЕЗКОШТОВНОЮ допомогою професіональних продавців автозапчастин. Створити запит на підбір дуже просто:</p>
      <ol>
        <li>Вкажіть данні про своє авто</li>
        <li>Вкажіть запчастини, які вам потрібні</li>
        <li>Відправте свій запит професіоналам - продавцям автозапчастин</li>
        <li>Отримайте відповіді від декілька продавців</li>
        <li>Оберіть найкращу пропозицію та замовте</li>
      </ol>
    `,
    myCity: 'Мій населений пункт',
    price: 'Ціна продажу, {val}',
    delivery_time: 'Термін поставки, дні',
    requestSend: 'Відправлено',
    sRequestNum: 'Запит №{id}',
    public_id: 'Номер запиту по VIN',
    allCarsModels: 'Всі марки автомобілів',
    created: 'Запит відправлено: {date}',
    comment: 'Коментар покупця до запиту',
    responseExitConfirm: 'Ви бажаєте відмінити відповідь?',
    responseProcessingExitConfirm: 'У випадку відміни всі пропозиції будуть видалені. Ви дійсно хочете відмінити обробку запиту?',
    comment_private: 'Постачальник, ціна закупки, інші умови',
    commentPrivateDesc: 'Текст не буде бачити покупець. Поле доступно виключно продавцю для внутрішнього застосування.',
    comment_public: 'Коментар для покупців',
    hideArticlesForBuyer: 'Не показувати покупцю каталожні номери',
    customWareSelect: 'Потрібний товар відсутній. Вписати дані самостійно',
    emptySearchText: 'У ваших прайсах не знайдено товарів за пошуком по {name}',
    selectWareFromPrises: 'Виберіть товар з прайсів',
    sellerComment: 'Коментар продавця',
    emptyResponse: 'Відповідь відсутня',
    answerTime: 'Час відповіді',
    userContacts: 'Контактні дані',
    userContactsDesc: 'Контактні дані не будуть передані продавцям автозапчастин і будуть використані лише для інформування про нові відповіді продавців',
    userContactsPhoneDesc: 'Буде відправлено сповіщення про результат обробки запиту',
    telegramBotDesc: 'Відслідковувати стан оброблення запиту Telegram каналі',
    steps: [
      'Вказати данні по авто',
      'Вказати потрібні запчастини',
      'Вказати контактні данні'
    ]
  },

  cleverSearch: {
    searchIn: 'Шукати в',
    searchPlaceholder: 'Товар, марка або модель авто, виробник, артикул',
    specifyCategory: 'Будь ласка, вкажіть категорію',
    article: 'Знайдено в артикулах',
    car_brand: 'Перейти в бренди',
    car_model: 'Перейти в моделі',
    product_line: 'Перейти в категорію',
    tm: 'Перейти в торгові марки',
  },

  questions: {
    title: 'Запитання та відповіді',
    search: {
      title: '🚘 Як вибрати {tl} для свого авто?',
      answer: 'Ви можете підібрати {tl} скориставшись формою підбору по автомобілю: вибирайте марку, модель авто, рік випуску і тип вашого мотора. А також можна скористатися формою пошуку по каталожним номеру запчастини.'
    },
    popular: {
      title: "🔝 Які бренди найпопулярніші в групі {tl}?",
      answer: "Найпопулярніші виробники:"
    },
    topForYear: {
      title: "🥇 Які найпопулярніші товари в групі {tl} у {year} році?",
      answer: "ТОП-5 популярних товарів в {year} році:"
    },
    price: {
      title: "💵 Які ціни в {mp} на {tl}?",
      answer: "Діапазон цін на {tl} від {minPrice}, до {maxPrice} грн."
    }
  },

  requisites: {
    actualData: "Зберігаючи дані ви підтверджуєте, що вони є достовірними та актуальними",
    accept_term: "Згоден з умовами договору",
    print_document: "Роздрукувати договір",
    send_connect: "Надіслати запит на підключення",
    physical_person: "Фізична особа ",
    entrepreneur: "Фізична особа підприємець",
    juridical_person: "Юридична особа",
    bank: {
      name: "Назва банку",
      mfo: "МФО банку",
      iban: "IBAN"
    },
    contact: {
      address: "Фактична адреса",
      email: "Email",
      phone: "Телефон",
      copy: 'Копіювати'
    },
    scan: {
      stateRegister: "Виписка з Єдиного державного реєстру (1 сторінка)",
      stateRegisterDesc: '(або 1 + 2 сторінки в одному файлі)',
      stateRegister2: "Виписка з Єдиного державного реєстру (2 сторінка)",
      accountOpen: "Довідка про відкриття рахунку",
      accountOpenDesc: '(або справка про банківські реквізити)',
      pdv: "Витяг з реєстру платників ПДВ"
    },
    info: {
      type: 'Форма власності',
      firstName: "Ім'я",
      lastName: "Прізвище",
      middleName: "По батькові",
      itn: "ІПН",
      address: "Юридична адреса",
      officialName: "Офіційна назва підприємства",
      shortName: "Скорочене найменування підприємства",
      pdv: "Платник ПДВ",
      certificate: "№ Свідоцтва платника ПДВ",
      egrpoy: "ЄДРПОУ"
    },
    signatory_full_name: 'ПІБ підписанта'
  },

  platform: {
    choiceOfWorkingConditionsOnSites: 'Вибір умов роботи на майданчиках',
    selectConditions: 'Оберіть тарифи для майданчиків',
    platformListDescription: 'Оберіть тарифи, за якими ви плануєте працювати на майданчиках. Тарифи та кількість рядків в прайсах можна змінити в будь-який час.',
    connect_description: "Для початку роботи на майданчику <strong>{business_name}</strong>, необхідно укласти Договір з компанією {mp}. Для укладання договору, введіть реквізити компанії, роздрукуйте договір у двох примірниках, підпишіть та  відправте за адресою <strong>{address}</strong>",
    name: "Назва майданчика",
    contract: "Договір",
    status: "Статус",
    subscriptionFee: 'Абонплата',
    subscriptionFeeDescription: 'Абонплата нараховується за кожні 30 діб роботи на сайті. Окремо сплачуються кошти за конверсіні дії згідно тарифів',
    statuses: {
      approved: "Активний",
      await_confirmation: "Очікує підтвердження",
      rejected: "Відхилено",
      empty: "Відсутній"
    },
    signContractDescription: 'Надсилаючи запит на підключення ви погоджуєтесь з умовами обраних тарифів.'
  },

  balance: {
    currentBalance: 'Поточний баланс на',
    upBalance: ' Поповнити баланс',
    enterAmount: 'Вкажіть суму поповнення',
    generateInvoice: 'Сформувати рахунок для безготівкової оплати',
    balance: 'Баланс',
    dateTime: 'Дата та час',
    type: 'Тип',
    order: 'Замовлення',
    article: 'Артикул',
    price: 'Ціна',
    quantity: 'Кількість',
    base: 'Сума',
    rate: 'Роялті%',
    rateAmount: 'Роялті',
    status: {
      refill: 'Поповнення',
      write_off: 'Списання',
      accrual: 'Нарахування',
      refund: 'Повернення'
    }
  },

  carBodyTypes: {
    1: 'Похила задня частина',
    2: 'Седан',
    3: 'Купе',
    4: 'Кабріолет',
    5: 'Універсал',
    6: 'Вен',
    7: 'Особливий кузов',
    8: 'Всюдихід закритий',
    9: 'Пікап',
    10: 'Фургон',
    11: 'C бортовою платформою / ходова частина',
    12: 'Автобус',
    13: 'Фургон / універсал',
    14: 'Тарга',
    15: 'Всюдихід відкритий',
    16: 'Самоскид',
    17: 'Автомобіль для потреб коммунальног',
    18: 'Тягач',
    19: 'Одноосний тягач',
    20: 'Кузов з твердим верхом',
    21: 'Фургон / хетчбек',
    22: 'Мотоцикл',
    23: 'Сідловий тягач',
    24: 'Бетономішалка',
    25: 'Трактор'
  },

  carEngineTypes: {
    1: 'Бензиновий двигун',
    2: 'Дизель',
    3: 'Электричий двигун',
    4: 'Гібрид',
    5: 'Двигун ванкеля',
    6: 'Бензиновий двигун (двухтактний)',
    7: 'Гібрид (карбюраторний двигун/электродвигун)'
  },

  carFuelTypes: {
    1: 'Бензин',
    2: 'Дизель',
    3: 'Бензин / природній газ (cng)',
    4: 'Бензин / автогаз (lpg)',
    5: 'Бензин / электрика',
    6: 'Природній газ',
    7: 'Бензин / етанол',
    8: 'Суміш',
    9: 'Електричний. - дизельне паливо',
    10: 'Спирт',
    11: 'Зріджений газ',
    12: 'Електрика',
    13: 'Бензин / етанол / електрика',
    14: 'Flexfuel / електро',
    15: 'Водень',
    16: 'Бензин / етанол / природній газ',
    17: 'Дизель / природній газ (cng)',
    18: 'Бензин / електрика / автогаз (lpg)',
    19: 'Бензин / природній газ (lpg)'
  },

  carGearTypes: {
    2: 'Механічна коробка передач',
    // 1: 'Ручна коробка передач 5-ступінчаста',
    // 3: 'Ручна коробка передач 4-ступінчаста',
    // 7: 'Ручна коробка передач 6-ступінчаста',
    11: "Варіатор",
    // 5: 'Повністю автоматична коробка передач',
    8: "Автоматична коробка передач",
    // 13: 'Cvt-автоматична коробка передач (без сходинок)',
    // 4: "Автоматична коробка передач 3-ступінчаста",
    // 10: "Автоматична коробка передач 4-ступінчаста",
    // 12: 'Автоматична коробка передач 5-ступінчаста',
    // 14: "Автоматична коробка передач 6-ступінчаста",
    // 15: 'Передачі з подвійним зчепленням 7-ступінчаста',
    // 16: 'Автоматична коробка передач 8-ступінчаста',
    // 9: 'Ступенева / факультативна автоматична коробка передач',
    // 6: 'Стрічка з штовхають ланками (безступінчата)',
    17: 'Робот'
  },

  carDriveTypes: {
    1: 'Передній привід',
    2: 'Задній привід',
    3: 'Повний привід',
    4: 'Прямий',
    5: 'Цепь',
    6: 'Кардан',
    7: 'Варіатор',
    8: 'Ремінь'
  },

  labels: {
    error: 'Помилка',
    avatar: 'Аватарка',
    logo: 'Логотип',
    phone_number: 'Телефон',
    last_name: 'Прізвище',
    middle_name: 'По-батькові',
    first_name: 'Ваше ім\'я',
    email: 'Email',
    password: 'Пароль',
    password2: 'Повторити пароль',
    recovery_key: 'Ключ активації',
    captcha: 'Капча',
    facebook: 'Facebook',
    google: 'Google',
    companyName: 'Назва компанії',
    isSeller: 'Ваша компанія займається торгівлею автозапчастинами?',
    point_sale_activities: 'Види діяльності точки продажу',
    address: 'Адреса',
    noAddress: 'Немає фізичної адреси для контакту з клієнтами',
    country: 'Країна',
    admin1: 'Область',
    city: 'Населений пункт',
    street: 'Вулиця',
    building: 'Будинок',
    flat: 'Офіс',
    landmarks: 'Орієнтири, які допоможуть швидше знайти вказану адресу',
    phone: 'Телефон',
    phones: 'Телефони', //
    recipientPhone: 'Телефоон отримувача',
    main: 'Основний',
    workDays: 'Режим роботи',
    startWork: 'Початок роботи',
    endWork: 'Кінець роботи',
    timeSeparator: '-',
    returnIsPossible: 'Можливе повернення',
    guarantee: 'Гарантія',
    contact_name: 'Прізвище, ім\'я',
    salePointName: 'Назва точки продажу',
    is_return_possible: 'Можливе повернення',
    is_warranted: 'Надається гарантія',
    nameForSocial: 'Ваше ім\'я (Для спілкування)',
    login: 'Логін',
    detail: 'Відомості',
    non_field_errors: 'Невірні дані',
    searchInPrice: 'Шукати в прайсах',
    exactArticle: 'Тільки артикул що шукали',
    status: 'Статус',
    mains: 'Основні',
    priceGrn: 'Ціна',
    delivery_time: 'Термін поставки',
    payment_methods: 'Способи оплати',
    delivery_methods: 'Способи доставки',
    warrantedAndReturn: 'Гарантія та повернення',
    other_selling_terms: 'Додатково',
    payment_terms: 'Умови оплати',
    sale_activities: 'Види діяльності продавця',
    in_stock: 'В наявності',
    localOffers: 'Місцеві пропозиції',
    is_official_trademark: 'Зі складів дистриб\'ютера',

    auto_update_url: 'Шлях до файлу',
    seller: 'Продавець',
    buyer_phone_number: 'Телефон покупця',
    recipient_phone_number: 'Телефон отримувача',
    buyer_email: 'Email покупця',
    buyer_phone: 'Телефон покупця',

    not_found: 'Товар вже не продається у даного продавця',
    price_changed: 'Ціна змінилась',
    quantity_changed: 'Кількість змінилась',
    terms_changed: 'Умови змінились',
    info_changed: 'Інформація про товар змінилась',
    insufficient_stock: 'Кількість змінено',
    buyer: 'Покупець',
    reloadPageByTermsChange: 'Під час оформлення замовлення умови змінилися (ціни, кількість, наявність). Перевірте, що нові умови вас влаштовують та знову відправте заказ в роботу. Якщо умови Вас не влаштовують, то зробить новий пошук.',
    created: 'Створено',
    column_mapping: 'Помилка маппінгу',
    terms_urls: 'Постлання на ресурс',
    buyer_first_name: 'Ім`я покупця',
    buyer_last_name: 'Прізвище покупця',
    payment_method: 'Спосіб оплати',
    delivery_method: 'Спосіб отримання замовлення',
    delivery_address: 'Адреса отримання замовлення',
    basket: 'Кошик',
    items: 'Товари',
    empty: 'пусте',
    _schema: 'Схема запиту',
    offer_id: 'Ідентифікатор товару',
    activation_key: 'Ключ активації'
  },

  sale_activities: {
    store: 'Автомагазин (Інтернет магазин)',
    service_station: 'СТО',
    warehouse: 'Оптовий склад дистриб\'ютора',
    disassembly: 'Розборка'
  },

  payment_terms: {
    full_prepayment: 'Повна передплата (100%)',
    late_payment: 'Відтермінування платежу',
    on_credit: 'У кредит',
    partial_prepayment: 'Часткова передплата',
    payment_upon_receipt: 'Оплата при отриманні в точці продажу',
    postpay_on_carrier: 'Післяплата на перевізнику',
  },

  payment_methods: {
    card: 'Банківська карта',
    cashless_payment: 'Безготівковий платіж',
    cash_payment: 'Готівка',
    card_to_card: 'З карти на карту',
    cash_on_delivery: 'Оплата при отриманні на перевізнику',
    on_credit: 'В кредит'
  },

  delivery_methods: {
    free_shipping: 'Безкоштовна доставка',
    carrier_in_country: 'Транспортна компанія',
    courier: 'Кур\'єр продавця',
    pickup: 'Самовивіз з точки продажу',
    regular_bus: 'Рейсові автобуси',
    taxi: 'Таксі',
  },

  deliveryTypes: {
    service_office: 'Відділення служби доставки',
    address: 'Доставка на адресу'
  },

  deliveryServicesType: {
    service_office: 'Транспортна компанія (на відділення)',
    address: 'Транспортна компанія (на адресу)'
  },

  deliveryServices: {
    'novaposhta_ua': 'Нова пошта',
    "nexpress_com_ua": 'Нічний експрес',
    "ukrposhta_ua": 'Укрпошта',
    "gunsel_ua": 'Гюнсел',
    "autolux_ua": 'Автолюкс',
    "sat_ua": 'САТ',
    "justin_ua": 'Justin',
    'meest_com': 'MeestExpress',
    'other': 'Інша'
  },

  other_selling_terms: {
    vat_payer: 'Платник ПДВ',
    free_shipping: 'Безкоштовна доставка',
    price_without_hidden_fees: 'Ціна без прихованих платежів',
    return_possible: 'Можливе повернення',
    warranted: 'Гарантія',
    with_installation_only: 'Продаж разом з установкою',
    former_use: 'Товар б/в',
    does_not_conduct_activities: 'Не веде діяльність',
    return_not_possible: 'Повернення неможливе'
  },

  phoneChannels: {
    phone: 'Телефон',
    sms: 'SMS',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp'
  },

  routes: {
    menuBusiness: 'Меню Автобізнес',
    profileChange: 'Змінити профіль',
    city: 'Місто',
    home: 'Головна',
    search: 'Пошук по артикулу',
    catalog: 'Каталог',
    catalog_search: 'Пошук по каталогу',

    finance: "Фінанси",
    enter: 'Вхід',
    exit: 'Вихід',
    account: 'Обліковий запис',

    article: '',
    sale_point: 'Точка продажу',
    errors: '',

    auth: 'Аутентифікація',
    auth_activation: 'Активація облікового запису',
    auth_completion: 'Завершення аутентифікації',
    auth_pass: 'Відновлення паролю',
    auth_pass_send: 'Відновлення паролю',
    auth_pass_recovery: 'Відновлення паролю',

    basket: 'Кошик',
    basket_order_create: 'Створення нового замовлення на продавця',
    basket_order_created: '',
    basket_order_created_hash: '',
    basket_order: 'Замовлення',
    basket_order_hash: 'Замовлення',
    basket_orders: 'Мої замовлення',

    profile: 'Профіль',
    profile_business: 'Бізнес',
    profile_business_create: 'Створення бізнес-профіля',
    profile_business_edit: 'Налаштування бізнесу',
    profile_business_seller_orders: 'Замовлення покупців',
    profile_business_seller_orders_order: 'Замовлення',

    profile_business_sale_points: 'Точки продажу',
    profile_business_sale_points_create: 'Створення точки продажу',
    profile_business_sale_points_edit: 'Редагування точки продажу',

    profile_business_prices: 'Прайси',
    profile_business_prices_upload: 'Завантаження',
    profile_business_prices_create: 'Створення',
    profile_business_prices_edit: 'Редагування',

    profile_business_platforms: 'Майданчики',
    profile_business_platforms_connect: 'Подача заявки',
    profile_business_platforms_contract: 'Договір публічної оферти',
    profile_business_balance: 'Баланс',

    profile_business_s_requests: 'Запити на підбір по VIN',
    profile_business_s_requests_edit: 'Обробка запиту по VIN',

    garage: 'Гараж',
    garage_create: 'Створення авто',
    garage_edit: 'Редагування авто',
    garage_requests: 'Запити на підбір по VIN',
    garage_requests_create: 'Створення запиту',
    garage_requests_created: 'Запит по VIN',
    garage_requests_response: 'Відповідь на запит по VIN',
    garage_responses: 'Відповіді на запити по VIN',

    vin_requests_master_create: 'Знайти автозапчастини за допомогою професіоналів',
    vin_requests_master_created: 'Запит по VIN створено',
    vin_requests_hash: 'Відповіді на запити по VIN',

    test: 'Тестова сторінка',
    development: '',
    docs: 'Документація',
    about_us: 'Про нас',
  },

  buttons: {
    copy: 'Копіювати',
    back: 'Назад',
    next: 'Далі',
    edit: 'Редагувати',
    no: 'Ні',
    yes: 'Так',
    create: 'Створити',
    goEnter: 'Увійти',
    clear: 'Скинути',
    apply: 'Застосувати',
    google: 'Sign in with Google',
    facebook: 'Sign in with Facebook',
    recoveryPassword: 'Відновити пароль',
    send: 'Надіслати',
    activate: 'Активувати',
    save: 'Зберегти',
    cancel: 'Скасувати',
    finish: 'Завершити',
    remove: 'Видалити',
    nix: 'Відмінити',
    confirm: 'Підтвердити',
    select: 'Вибрати',
    continue: 'Продовжити',
    continueShopping: 'Продовжити покупки',
    change: 'Змінити',
    addSome: 'Додати {name}',
    clearBasket: 'Очистити кошик',
    selectDeliveryAndPayment: 'Вибрати доставку і оплату',
    copyTimeToAllDays: 'Скопіювати час на всі дні',
    createSalePoint: 'Створити точку продажу',
    createBusiness: 'Створити Бізнес-профіль',
    addBusinessProfile: 'Додати новий бізнес профіль',
    collapse: 'Згорнути',
    collapseAll: 'Згорнути все',
    unCollapseAll: 'Розгорнути все',
    removeProfile: 'Видалити профіль',
    leaveProfile: 'Піти з профілю',
    upload: 'Завантажити',
    joinedSocial: 'Зв\'язано',
    breakSocialLink: 'Розірвати зв\'язок.',
    uploadPhotoText: 'Завантажити фото',
    centered: 'Центрувати',
    searchGooglePhoto: 'Шукати зображення в інтернеті',
    uploadPrice: 'Завантажити прайс',
    searchAgain: 'Повторити пошук',
    wrongTableData: 'Невірно відображений вміст файлу в таблиці',
    getMoreRows: 'Показати ще {num} рядків',
    createPrice: 'Створити новий прайс',
    uploadOtherFile: 'Завантажити інший файл',
    reloadInfo: 'Оновити дані',
    reload: 'Оновити',
    goBackHome: 'Повернутися на сайт',
    createPriceScheme: 'Створити ціноутворення',
    newPriceScheme: '+ Нове ціноутворення',
    waitLoading: 'Файл завантажується. Не закривайте вікно.',
    otherCity: 'Інше місто',
    addTrademark: '+ Додати ТМ',
    addDoc: 'Додати документ',
    pushToProcessing: 'Відправити на переопрацювання',
    createDeliveryRate: '+ Створити тариф доставки',
    search: 'Шукати',
    toOrder: 'Замовити',
    cancelAllOrder: 'Відмовитись від усього замовлення',
    toOrderList: 'До списку замовлень',
    cancelOrderItem: 'Відмінити замовлення',
    setNextStatus: 'Перевести до наступного статусу',
    setStatus: 'Перевести у "{status}"',
    addRecipient: '+ Створити отримувача',
    likeList: 'Списком',
    onMap: 'На мапі',
    clearAllFilters: 'Скинути всі фільтри',
    removePrice: 'Видалити прайс',
    submitAskToSeller: 'Відправити питання продавцю',
    loadMore: 'Заватнтажити ще',
    addLink: 'Додати посиланни',
    buy: 'Купити',
    lessTrademarks: 'Меньше виробників',
    moreTrademarks: 'Більше виробників',
    moreCategory: 'Більше категорий',
    lessCategory: 'Меньше категорій',
    comparePrices: 'Порівняти ціни',
    addCar: 'Додати авто',
    goToGarage: 'До гаража',
    orderToSelection: 'Оформити запит на підбір',
    addMoreRow: 'Додати рядок',
    emitRequest: 'Відправити запит',
    toSelectionRequestsList: 'До списку запитів по VIN',
    newSelectionRequest: 'Створити запит',
    selectionRequestHistory: 'Історія запитів',
    newRequest: 'Новий запит',
    notebook: 'Блокнот',
    view: 'Перегляд',
    answer: 'Відповісти',
    answerShow: 'Дивитись відповідь',
    answerHide: 'Сховати відповідь',
    addWare: 'Додати товар',
    addResponse: 'Додати відповідь',
    submitRequest: 'Відправити відповідь',
    noPropositionUseCustom: 'Пропозицій немає в списку. Ввести самостійно',
    allRequests: 'Всі запити',
    processed: 'Оброблені',
    allPhones: 'Всі телефони',
    allEmails: 'Всі email',
    signContract: 'Підписати договір',
    bySelectedIn: 'Купити обране в {name}',
    subscribe: 'Підписатись',
    oneClickBuy: 'Купити в 1 клік',
  },

  placeholders: {
    email: 'Адреса електронної пошти',
    password: 'Пароль',
    enterPartNumber: 'Введіть каталожний номер запчастини',
    activation_key: 'Введіть ключ активації',
    comment: 'Коментар',
    setReturnTerms: 'Вкажіть умови повернення',
    setReturnTermsLink: 'Вкажіть посилання на умови повернення',
    setWarrantyTerms: 'Вкажіть умови гарантії',
    setWarrantyTermsLink: 'Вкажіть посилання на уиови гарантії',
    days: 'Днів',
    hours: 'Годин',
    catalogNumInPrice: 'Каталожний номер в прайсі',
    chooseOrEnter: 'Оберіть зі списку або додайте нове',
    filterByTrademark: 'Фільтр по торговій марці',
    filterByStatus: 'Фільтрувати за статусом',
    setRecipient: 'Оберіть отримувача',
    setStatus: 'Оберіть статус',
  },

  message: {
    warning: 'Увага',
    success: 'Успіх',
    isActivated: 'Ваш акаунт активовано',
    checkEmail: 'Перевірте, будь ласка, пошту.',
    businessRemoved: 'Бізнес профіль видалено.',
    businessLeaved: 'Профіль відв\'язано від бізнесу',
    priceUploadProcess: 'Завантаження прайсу',
    priceUploadSuccess: 'Прайс завантажено.',
    priceRemoved: 'Прайс видалено.',
    priceAvailabilityUploadProcess: 'Завантаження налаштувань складів',
    priceAvailabilityUploadSuccess: 'Завантаження налаштувань складів завершено.',
    checkChangedAvailabilities: 'Налаштування складів було змінено, перевірте оновлені дані.',
    orderCreated: 'Замовлення створено, перевірте пошту <strong>{email}</strong>. <strong>Тема листа:</strong> Замовлення <strong>{id}</strong>, від Покупця <strong>{buyer}</strong>',
    orderCreatedNotify: 'Замовлення №<strong>{id}</strong> створено. Найближчим часом з вами зв\'яжеться наш менеджер',
    orderCanceled: 'Замовлення {order} скасовано',
    confirmSetNextStatus: 'Ви дійсно хочете змінити статус рядків замовлень на "{status}" ?',
    confirmChangePriceActivity: 'Ви дійсно бажаєте змінити режим відображення прайсу в результатах пошуку ?',
    checkMapping: 'Система виявила вірогідність невірного маппінгу. Будьласка перевірте правильність маппінгу та повторіть спробу збереження.',
    invalidMapping: 'Маппінг прайса не валідний. Зверніться до спеціалістів з технічної підтримки для консультації.',
    businessSave: "Ваш бізнес-профіль успішно збережений",
    platformConnect: "Підключення відбудеться тільки після отримання підписаного паперового договору в двох примірникахы",
    authIsRequired: 'Необхідно зарееструватися',
    authForInquire: 'Для оформлення запиту на підбір необхідно зареєструватися',
    editModification: 'Для того щоб фільтрувати каталог, необхідно вказати код модифікації',
    confirmRemoveRequestSelection: 'Ви впевнені що бажаєте видалити запит на підбір №{id}?',
    salePointIsNotActive: 'Нажаль точка продажу тимчасово не приймає замовлення через сайт. Спробуйте підібрати іншу точку продажу.',
    setDataPreviewSheen: 'Мапінг прайсу не збережени. Спробуйте ще раз.',
    changesSaved: 'Зміни збережено'
  },

  errors: {
    api: {
      title: 'Помилка',
      message: 'Помилка сервера'
    },
    400: {
      title: 'Не валідний запит',
    },
    401: {
      title: '',
      message: 'Час сесії скінчився. Увійдіть в систему повторно'
    },
    404: {
      title: 'Помилка',
      message: 'Такої сторінки не існує'
    },
    500: {
      title: 'Помилка',
      message: 'Помилка сервера'
    },
    502: {
      title: 'Помилка',
      message: 'Сервер перевантажено'
    },
    503: {
      title: 'Помилка',
      message: 'Сервіс тимчасово недоступний'
    },
    504: {
      title: 'Помилка',
      message: 'Сервер не відповідає'
    },
    default: {
      warning: 'Увага',
      title: 'Помилка',
      message: 'Виникла неочікувана помилка'
    },
    captcha: {
      title: 'Системна помилка',
      message: 'Відсутня капча'
    },
    login: {
      title: '',
      message: 'Будь ласка, зареєструйтесь в системі.'
    },
    logout: {
      title: 'Успіх',
      message: 'Ви вийшли з системи.'
    },
    businessAccess: {
      title: 'Помилка доступу',
      message: 'Ви не маєте доступу до бізнес профілю'
    },
    price_status: {
      '404': 'Файл знаходиться в обробці',
      empty: 'На даний момент дані відсутні'
    },
    upload: {
      title: 'Помилка завантаження',
      message: 'Під час завантаження файлу {name} виникла помилка. Будь ласка, повторіть спробу завантажити файл.',
    },
    invalid_encoding: {
      message: 'Невірне кодування файлу ({message})'
    },
    reloadPage: 'Дані застаріли. Перезавантажте сторінку, будь ласка!',
    terms_changed: {
      title: 'Увага'
    },
    emptyData: 'Дані відсутні',
    emptySearch: {
      label: 'У продавців, на жаль, відсутні пропозиції на {name}',
      message: `
        За результатами пошуку {name} нічого не знайдено. Спробуйте: <br>
        <span class="ml-2">- перевірити каталожний номер - можливо в номері помилка;</span> <br>
        <span class="ml-2">- скористатися каталогом підбору;</span>
      `
    }
  },

  validation: {
    simple: {
      required: 'Поле обов\'язкове.',
      min: 'Мінімальна кількість символів {num}.',
      max: 'Максимальна кількість символів {num}',
      selectSome: 'Необхідно вибрати елементи.',
      existedValue: 'Це значення вже використовується.',
      number: 'Поле має містити тільки цифри',
      format: 'Невірний формат',
      minOneOption: 'Виберіть один з пунктів',
      length: 'Поле має містити {num} символів'
    },
    first_name: {
      required: 'Введіть ім\'я.',
    },
    email: {
      required: 'Введіть поштову адресу.',
      pattern: 'Email невалідний.'
    },
    password: {
      required: 'Введіть пароль.'
    },
    password2: {
      required: 'Паролі мають бути однаковими.',
      ident: 'Паролі мають співпадати. Введіть пароль коректно.'
    },
    activation_key: {
      required: 'Для активації необхідно ввести ключ, вказаний в листі.'
    },
    avatar: {
      format: 'Зображення має бути формату - {format}.',
      size: 'Розмір зображення не може перевищувати {size}.'
    },
    price: {
      format: 'Файл має бути формату - {format}.',
      size: 'Розмір файлу не може перевищувати {size}.'
    },
    address: {
      required: 'Вкажіть адресу, або вкажіть наявно, що її немає.'
    },
    contacts: {
      required: 'Даний розділ має містити щонайменше один контактний телефон.'
    },
    phone_numbers: {
      duplicate: 'Номери телефонів мають бути унікальні.'
    },
    phone_number: {
      pattern: 'Введіть телефон у форматі {format}.',
    },
    profile: {
      required: 'Необхідно обрати відповідний профіль, до якого відноситься точка продажу.'
    },
    trademark: {
      required: 'Вкажіть назву торгової марки, або вкажіть відповідний тип колонки.',
      single: 'Вкажіть торгову марку у мапінгу, АБО у окремому полі.'
    },
    article: {
      required: 'Вкажіть колонку з назвою "Каталожний номер".'
    },
    delivery_time: {
      required: 'Вкажіть термін поставки.'
    },
    order_time_until: {
      required: 'Вкажіть термін поставки.'
    },
    priceQuantity: {
      required: 'Необхідно вказати ціну або залишки.'
    },
    sellPrice: {
      required: 'Необхідно вказати ціну продажу.'
    },
    purchasePriceActions: {
      required: 'Необхідно вказати ціну продажу в схемі.'
    },
    quantity: {
      required: 'Вкажіть залишки на складі.'
    },
    analogsAndAnalogsTrademark: {
      required: 'Якщо присутні, необхідно вказати каталожний номер аналога та назву виробника.'
    },
    relationType: {
      required: 'Вкажіть тип звьязку з артикулом аналогу.'
    },
    weightUnit: {
      required: 'Вкажіть тип ваги.'
    },
    float: {
      invalidFormat: 'Вкажіть значення в форматі 0.00.'
    },
    verification_url_or_document: {
      required: 'Необхідно вказати посилання або файл.'
    },
    search_on_site_url: {
      pattern: 'Посилання не валідне'
    },
    site: {
      pattern: 'Посилання не валідне. Введіть значення в формат test_site.com'
    },
    urls: {
      pattern: 'Посилання не валідне.'
    },
    warehouse: {
      required: 'Вкажіть склад в колонці "Кількість на складі".'
    },
    confirm_mapping: {
      message: 'Помилка мапінгу'
    },
    weightType: {
      required: 'Вкажіть тип ваги'
    },
    sizesType: {
      required: 'Вкажіть тип габариту'
    },
    agreeWithPublicOffer: 'Для реєстрації продавця автозапчастин необхідно погодитися з умовами договору публічної оферти.',
    vinError: 'Можливо, у VIN- номері є помилка. Будь ласка, уважно перевірте Ваш VIN. Тільки при введенні ПРАВИЛЬНОГО VIN можна коректно підібрати запчастини, що підходять на автомобіль!'
  },

  mapLegend: {
    priceIn: 'Ціни в {cur}',
    green: 'Зелений',
    greenDesk: 'є в наявності',
    black: 'Чорний',
    blackDesk: 'буде на протязі дня',
    grey: 'Сірий',
    greyDesk: 'більше одного дня',
    red: 'Червоний',
    redDesk: 'найкраща пропозиція',
    official_trademark: 'Зі складів дистриб\'ютера',
  },

  orderCycleHelp: {
    title: 'В які статуси може переходити замовлення',
    description: 'Відмова від замовлення з боку Клієнта або Продавця негативно враховується при розрахунку рейтингу Клієнта та Продавця відповідно. Тому для збереження високого рейтингу поради',
    forBuyer: 'до <strong>Покупців</strong> - замовляйте тільки те, що гарантовано плануєте купувати. Відмовляючись від замовлення Ви генеруєте додаткові витрати Продавцю. Будь ласка, цінуйте роботу інших.',
    forSeller: 'до <strong>Продавців</strong> - намагайтесь виконати замовлення клієнтів у ті сроки та за ту ціну, про яку на початку домовилися з клієнтом. Покупець розраховує на Вас, а також саме через ці домовленості обрав.'
  },

  el: {
    day1: 'День',
    day2: 'Дня',
    day3: 'Днів',
    colorpicker: {
      confirm: 'OK',
      clear: 'Очистити'
    },
    datepicker: {
      output: 'Вихідний',
      now: 'Зараз',
      today: 'Сьогодні',
      cancel: 'Відміна',
      clear: 'Очистити',
      confirm: 'OK',
      selectDate: 'Обрати дату',
      selectTime: 'Обрати час',
      startDate: 'Дата початку',
      startTime: 'Час початку',
      endDate: 'Дата завершення',
      endTime: 'Час завершення',
      prevYear: 'Попередній рік',
      nextYear: 'Наступний рік',
      prevMonth: 'Попередній місяць',
      nextMonth: 'Наступний місяць',
      year: 'рік',
      month1: 'Січень',
      month2: 'Лютий',
      month3: 'Березень',
      month4: 'Квітень',
      month5: 'Травень',
      month6: 'Червень',
      month7: 'Липень',
      month8: 'Серпень',
      month9: 'Вересень',
      month10: 'Жовтень',
      month11: 'Листопад',
      month12: 'Грудень',
      week: 'Неділя',
      weeks: {
        sun: 'Нд',
        mon: 'Пн',
        tue: 'Вт',
        wed: 'Ср',
        thu: 'Чт',
        fri: 'Пт',
        sat: 'Сб',
        sunday: 'Неділя',
        monday: 'Понеділок',
        tuesday: 'Вівторок',
        wednesday: 'Середа',
        thursday: 'Четверг',
        friday: 'Пятниця',
        saturday: 'Субота'
      },
      months: {
        jan: 'Січ',
        feb: 'Лют',
        mar: 'Бер',
        apr: 'Кві',
        may: 'Тра',
        jun: 'Чер',
        jul: 'Лип',
        aug: 'Сер',
        sep: 'Вер',
        oct: 'Жов',
        nov: 'Лис',
        dec: 'Гру'
      }
    },
    select: {
      loading: 'Завантаження...',
      noMatch: 'Співпадінь не знайдено',
      noData: 'Немає даних',
      placeholder: 'Обрати'
    },
    cascader: {
      noMatch: 'Співпадінь не знайдено',
      loading: 'Завантаження...',
      placeholder: 'Обрати',
      noData: 'Немає даних'
    },
    pagination: {
      goto: 'Перейти',
      pagesize: ' На сторінці',
      total: 'Всього {total}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'Повідомлення',
      confirm: 'OK',
      cancel: 'Відміна',
      error: 'Неприпустиме введення даних'
    },
    upload: {
      deleteTip: 'Натисніть [Видалити] для видалення',
      delete: 'Видалити',
      preview: 'Попередній перегляд',
      continue: 'Продовжити'
    },
    table: {
      emptyText: 'Немає даних',
      confirmFilter: 'Підтвердити',
      resetFilter: 'Скинути',
      clearFilter: 'Все',
      sumText: 'Сума'
    },
    tree: {
      emptyText: 'Немає даних'
    },
    transfer: {
      noMatch: 'Співпадінь не знайдено',
      noData: 'Немає даних',
      titles: ['Список 1', 'Список 2'],
      filterPlaceholder: 'Введіть ключове слово',
      noCheckedFormat: '{total} пунктів',
      hasCheckedFormat: '{checked}/{total} обрано'
    },
    image: {
      error: 'Помилка'
    },
    pageHeader: {
      title: 'Назад'
    }
  },
};
