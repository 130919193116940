export default (address, options = {}) => {
  if (!address) {
    return '-';
  }

  const country = options.hideCountry
    ? ''
    : address.city_detail?.country?.name || '';

  const city = options.hideCity
    ? ''
    : address.city_detail?.name || address.city_text || '';
  const admin = options.useAdmin
    ? address.city_detail?.admin1?.name || address.region_detail?.name || ''
    : '';
  const street = address.street || '';
  const building = address.building || '';
  const text = address.text || '';

  return [country, city, admin, street, building, text]
    .filter(Boolean)
    .join(', ');
};
