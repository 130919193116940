var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-requisites-block mt-2" },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("requisites.info.type"), prop: "type" } },
        [
          _c(
            "el-radio-group",
            {
              attrs: { prop: "mainInfo.type", value: _vm.value.type },
              on: {
                input: function ($event) {
                  return _vm.$emit("update:type", $event)
                },
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "mt-1" },
                _vm._l(Object.keys(_vm.componentsNames), function (field) {
                  return _c(
                    "el-radio",
                    { key: field, attrs: { label: field } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("requisites." + field)) +
                          "\n        "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        _vm.componentsNames[_vm.value.type],
        _vm._g(
          { tag: "component", attrs: { value: _vm.value } },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }