<template>
  <div class="taxi-delivery">
    <div class="mr-2 mb-2">
      <div class="el-form-item__label">
        {{ $t('basket.salePointAddress') }}:
      </div>
      <div class="address">
        {{ getConcatedAddress(salePointAddress, { hideCountry: true }) }}
      </div>
    </div>

    <div>
      <el-form-item
        :label="$t('basket.delivery_address')"
        prop="delivery_address.text"
        :rules="dependency.rules['delivery_address.text']"
      >
        <el-input
          :value="value.text"
          @input="setField('delivery_address.text', $event)"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
import getConcatedAddress from '@/lib/salePoints/services/getConcatedAddress';

export default {
  name: 'TaxiDelivery',

  props: {
    salePointAddress: { type: Object, required: true },
    value: { type: Object, required: true },
    setField: { type: Function, required: true },
    dependency: { type: Object, required: true }
  },

  methods: {
    getConcatedAddress
  }
};
</script>
