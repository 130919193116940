<template>
  <div class="s-response-page">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <div class="header">
        <h1 class="custom-headline custom-headline--level2-bold">
          {{ $t('sRequests.sRequestNum', { id: vinRequest.publicId }) }}
          <span class="text-ghost text-small-2">
            ({{ getDateTime(vinRequest.created) }})
          </span>
        </h1>

        <div v-if="!$route.name.includes('hash')">
          <el-button
            size="mini"
            @click="$router.push({ name: getRouteName('garage.requests') })"
          >
            {{ $t('buttons.toSelectionRequestsList') }}
          </el-button>
        </div>
      </div>

      <div class="container">
        <div class="mb-2">
          <span class="bold text-ghost">{{ $t('garage.answersCount') }}:</span>
          <span class="answers-count">{{ vinRequest.responsesCount }}</span>
        </div>

        <component
          :is="`CarInfoCard${isMobile ? 'M' : ''}`"
          :value="vinRequest.car"
          class="mb-2"
        />

        <div v-if="!vinRequest.responsesCount">
          <div class="bold color-label raw-items mb-2">
            {{ getComputedRawItems(vinRequest.items) }}
          </div>

          <div class="text-center">
            {{ $t('sRequests.emptyResponse') }}
          </div>
        </div>

        <el-collapse
          v-else
          v-model="activeBlocks"
          :class="{ 'is-desktop': !isMobile }"
        >
          <el-collapse-item
            v-for="(value, sellerId) in tableData"
            :key="sellerId"
            :name="sellerId"
          >
            <template slot="title">
              <el-row justify="space-between" align="middle" class="w-100 pg-0-1">
                <div>
                  <span class="seller">
                    <span class="bold">{{ $t('basket.seller') }}:</span>
                    <el-link type="primary" class="bold">{{ value.sellerName }}</el-link>
                  </span>

                  <br>
                  <span v-if="!isMobile" class="text-ghost text-small-2">
                    {{ $t('sRequests.answerTime') }}:
                    {{ getDateTime(value.lastUpdate) }}
                  </span>
                </div>

                <div v-if="isMobile" class="date text-ghost">
                  {{ getDateTime(value.lastUpdate) }}
                </div>

                <div v-else>
                  <el-button
                    v-if="activeBlocks.includes(sellerId)"
                    class="btn"
                    type="success"
                    plain
                  >
                    {{ $t('buttons.answerHide') }}
                  </el-button>
                  <el-button v-else type="success" class="btn">
                    {{ $t('buttons.answerShow') }}
                  </el-button>
                </div>
              </el-row>
            </template>

            <component
              :is="`SResponseItemsTable${isMobile ? 'M' : ''}`"
              :value="value.items"
              @ware-info:show="wareInfoId = $event"
              @sale-point:info="isSalePointInfo = $event"
              @buy="addToBasket"
            />
          </el-collapse-item>
        </el-collapse>
      </div>
    </template>

    <TheDialog v-model="isSalePointInfo" width="1100px">
      <SalePointInfo v-if="isSalePointInfo" :id="isSalePointInfo.id" />
    </TheDialog>

    <TheDialog v-model="wareInfoId">
      <WareInfo v-if="wareInfoId" :id="wareInfoId" @cancel="wareInfoId = null" />
    </TheDialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import { filters } from '@/lib/core';
import addWareToBasket from '@/lib/orders/services/addWareToBasket';
import getSeoBreadcrumbs from '@/lib/catalog/services/getSeoBreadcrumbs';

export default {
  name: 'SResponsePage',

  components: {
    /* eslint-disable max-len */
    WareInfo: () => import(/* webpackChunkName: "WareInfo" */ '@/lib/search/components/wareInfo/WareInfo'),
    CarInfoCard: () => import(/* webpackChunkName: "CarInfoCard" */ '@/lib/garage/components/CarInfoCard'),
    CarInfoCardM: () => import(/* webpackChunkName: "CarInfoCardM" */ '@/lib/garage/components/CarInfoCardM'),
    SResponseItemsTable: () => import(/* webpackChunkName: "SResponseItemsTable" */ './components/SResponseItemsTable'),
    SResponseItemsTableM: () => import(/* webpackChunkName: "SResponseItemsTableM" */ './components/SResponseItemsTableM'),
    SalePointInfo: () => import(/* webpackChunkName: "SalePointInfo" */ '@/lib/salePoints/components/SalePointInfo')
    /* eslint-enable max-len */
  },

  asyncData ({ app, store, route }) {
    const vm = { $store: store, $router: app.router };
    const rowBreadcrumbs = [
      {
        name: route.name,
        label: app.i18n.t('routes.garage_requests_created'),
        path: route.fullPath
      }
    ];

    const { breadcrumbs } = getSeoBreadcrumbs({ vm, rowBreadcrumbs });

    store.dispatch('setBreadcrumbs', breadcrumbs);
  },

  data () {
    return {
      isLoading: true,
      vinRequest: null,
      wareInfoId: null,
      isSalePointInfo: false,
      activeBlocks: []
    };
  },

  computed: {
    isMobile () {
      return this.maxmq('md');
    },

    tableData () {
      const items = this.vinRequest.items || [];
      const rawTitles = items.map(res => ({ ...res, selectionResponses: [] }));

      return items.reduce((acc, item) => {
        item.selectionResponses.forEach((res) => {
          const key = res.seller.id;

          acc[key] = acc[key] || {
            sellerName: res.seller.name,
            sellerId: res.seller.id,
            lastUpdate: '',
            items: cloneDeep(rawTitles)
          };

          const index = acc[key].items.findIndex(({ id }) => id === item.id);

          if (index > -1) {
            acc[key].lastUpdate = this.getLastDate(acc[key].items[index].lastUpdate, res.created);
            acc[key].items[index].selectionResponses.push(res);
          } else {
            console.error('ERROR IN tableData');
          }
        });

        return acc;
      }, {});
    }
  },

  mounted () {
    if (this.$route.query.hashId) {
      this.loadByHash(this.$route.query.hashId);
    } else {
      this.load();
    }
  },

  methods: {
    getDateTime: filters.getDateTime,

    async loadByHash (hashId) {
      this.isLoading = true;

      try {
        this.vinRequest = await this.$store.dispatch(
          'selectionRequests/getVinRequestByHashQL',
          { hashId }
        );

        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.$nuxt.error({
          statusCode: 404,
          message: this.$t('labels.not_found')
        });
      }
    },

    async load () {
      this.isLoading = true;

      try {
        this.vinRequest = await this.$store.dispatch(
          'selectionRequests/getVinRequestQL',
          {
            id: Number(this.$route.params.id),
            response: true
          }
        );

        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.$nuxt.error({
          statusCode: 404,
          message: this.$t('labels.not_found')
        });
      }
    },

    getComputedRawItems (items) {
      return items.map(({ rawItem }) => rawItem).join(', ');
    },

    async addToBasket (row) {
      return await addWareToBasket(
        this,
        row,
        { selection_response_id: Number(row.id) });
    },

    getLastDate (date1, date2) {
      if (!date1) {
        return date2;
      }

      return dayjs(date1).diff(dayjs(date2)) < 0 ? date2 : date1;
    }
  },

  validate ({ params, query }) {
    return !!query.hashId || /^\d+$/.test(params.id);
  }
};
</script>

<style scoped lang="scss">
.s-response-page {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .answers-count {
    color: $--color-success;
    font-size: 1rem;
  }

  .container {
    max-width: 980px;
    margin: 0 auto;
  }

  ::v-deep {
    .el-collapse-item__header {
      line-height: 1.2;
      background-color: $grey-200;

      .date {
        min-width: 105px;
      }

      .btn {
        min-width: 160px;
      }
    }

    .el-collapse.is-desktop .el-collapse-item__arrow {
      display: none;
    }
  }

  .el-collapse-item {
    border-bottom: 1px solid $grey-300;
  }

  .seller {
    height: fit-content;

    .el-link {
      height: 18px;
      margin-bottom: 1px;
    }

    * {
      font-size: 1.2rem;
    }
  }

  .raw-items {
    padding: 20px;
    font-size: 1rem;
    border-radius: 4px;
    background-color: $grey-100;
  }

  @media all and (max-width: $--md) {
    .header {
      flex-direction: column;

      .el-button {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
