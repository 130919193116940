var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "price-edit-page" },
    [
      !_vm.isPageLoaded
        ? _c("TheLoader")
        : [
            _c(
              "el-row",
              {
                staticClass: "mb-2",
                attrs: { justify: "space-between", align: "middle" },
              },
              [
                _c(
                  "h1",
                  {
                    staticClass: "custom-headline custom-headline--level2-bold",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.price.price_file.name || _vm.price.filename
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("PriceActivator", {
                  attrs: {
                    left: "",
                    "price-file": _vm.price.price_file,
                    "show-label": "",
                  },
                  on: {
                    update: function ($event) {
                      _vm.price.price_file.is_active = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tabs",
              {
                attrs: { type: "card", value: _vm.activeTab },
                on: { "tab-click": _vm.setActiveTab },
              },
              _vm._l(_vm.tabs, function (tab) {
                return _c(
                  "el-tab-pane",
                  {
                    key: tab,
                    attrs: {
                      label: _vm.$t("priceFile." + tab + "Tab"),
                      name: tab,
                    },
                  },
                  [
                    _c("Price" + _vm.upperFirst(tab) + "Tab", {
                      key: _vm.key,
                      ref: "component",
                      refInFor: true,
                      tag: "component",
                      staticClass: "mb-4",
                      attrs: {
                        "entity-name": "priceFile",
                        dependency: _vm.dependency,
                        entity: _vm.price.price_file,
                        submit: _vm.submit,
                      },
                      on: {
                        "error:format": function ($event) {
                          _vm.isFormatDialog = true
                        },
                        "page:update": _vm.updatePage,
                        "price-file:update": _vm.updatePriceFile,
                        "price:update": _vm.updatePrice,
                        "reupload:file": function ($event) {
                          _vm.isUploadDialog = true
                        },
                        "status:update": function ($event) {
                          _vm.price.status = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
            _vm._v(" "),
            _vm.price && _vm.isUploadDialog
              ? _c("PriceReUploadDialog", {
                  attrs: { price: _vm.price },
                  on: {
                    cancel: function ($event) {
                      _vm.isUploadDialog = false
                    },
                    "price:update": _vm.updatePrice,
                    "status:update": _vm.updatePriceStatus,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "TheDialog",
              {
                attrs: {
                  title: _vm.$t("prices.formatting"),
                  value: _vm.price && _vm.isFormatDialog,
                },
                on: {
                  cancel: function ($event) {
                    _vm.isFormatDialog = false
                  },
                },
              },
              [
                _c("PriceFormatForm", {
                  attrs: { price: _vm.price },
                  on: {
                    cancel: function ($event) {
                      _vm.isFormatDialog = false
                    },
                    "price:update": _vm.updatePrice,
                  },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }