export default {
  components: {
    /* eslint-disable max-len */
    ElDivider: () => import(/* webpackChunkName: "ElDivider" */ 'element-ui/lib/divider'),
    SignInLinks: () => import(/* webpackChunkName: "SignInLinks" */ '../components/SignInLinks'),
    ProfileSelect: () => import(/* webpackChunkName: "ProfileSelect" */ '@/lib/profiles/components/ProfileSelect')
    /* eslint-enable max-len */
  },

  data: () => ({
    isProfiles: false
  }),

  methods: {
    showProfilesOrGoHome (user) {
      if (this.$listeners.login) {
        this.$emit('login', user);
      } else if (user.business_acl.length) {
        this.isProfiles = true;
      } else {
        this.goHome();
      }
    },

    async onCloseProfile (strict) {
      await this.mergeBaskets();

      if (!strict) {
        this.goHome();
      }

      this.isProfiles = false;
    },

    async onSelectProfile () {
      await this.mergeBaskets();
      this.goHome();
      this.isProfiles = false;
    },

    async mergeBaskets () {
      try {
        await this.$store.dispatch('baskets/mergeBaskets');
        await this.$store.dispatch('baskets/fetchBasket');
      } catch (e) {
        console.error(e);
      }
    },

    goHome (user) {
      if (this.$listeners.login) {
        this.$emit('login', user);
      } else {
        const { redirect } = this.$route.query;

        const route = redirect && redirect !== '/'
          ? redirect
          : { name: this.getRouteName('home') };

        this.$router.push(route).catch(e => e);
      }

      this.$emit('done');
    }
  }
};
