var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ware-info" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : !_vm.ware
        ? _c("el-row", { staticClass: "pg-2", attrs: { justify: "center" } }, [
            _vm._v("\n    " + _vm._s(_vm.$t("errors.emptyData")) + "\n  "),
          ])
        : [
            _c("WareTitle", { staticClass: "mb-2", attrs: { ware: _vm.ware } }),
            _vm._v(" "),
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: { "tab-click": _vm.setTab },
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              _vm._l(_vm.tabs, function (tab) {
                return _c(
                  "el-tab-pane",
                  {
                    key: tab,
                    attrs: {
                      lazy: "",
                      label: _vm.$t("search." + tab),
                      name: tab,
                    },
                  },
                  [
                    _c("WareInfo" + _vm.upperFirst(tab), {
                      tag: "component",
                      attrs: { ware: _vm.ware, "ware-title": _vm.wareTitle },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }