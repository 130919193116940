import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  if (typeof store === 'function') {
    return console.warn('Classic mode for store/ is deprecated and will be removed in Nuxt 3.')
  }

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/analytics.js'), 'analytics.js')
  resolveStoreModules(require('../store/auth.js'), 'auth.js')
  resolveStoreModules(require('../store/balance.js'), 'balance.js')
  resolveStoreModules(require('../store/basketItems.js'), 'basketItems.js')
  resolveStoreModules(require('../store/baskets.js'), 'baskets.js')
  resolveStoreModules(require('../store/businessProfiles.js'), 'businessProfiles.js')
  resolveStoreModules(require('../store/businessUsers.js'), 'businessUsers.js')
  resolveStoreModules(require('../store/buyerRecipientAddresses.js'), 'buyerRecipientAddresses.js')
  resolveStoreModules(require('../store/buyerRecipients.js'), 'buyerRecipients.js')
  resolveStoreModules(require('../store/cache.js'), 'cache.js')
  resolveStoreModules(require('../store/carBrands.js'), 'carBrands.js')
  resolveStoreModules(require('../store/carModels.js'), 'carModels.js')
  resolveStoreModules(require('../store/carModifications.js'), 'carModifications.js')
  resolveStoreModules(require('../store/cars.js'), 'cars.js')
  resolveStoreModules(require('../store/deliveryRates.js'), 'deliveryRates.js')
  resolveStoreModules(require('../store/dependencies.js'), 'dependencies.js')
  resolveStoreModules(require('../store/errors.js'), 'errors.js')
  resolveStoreModules(require('../store/orderDeliveryAddresses.js'), 'orderDeliveryAddresses.js')
  resolveStoreModules(require('../store/orderItems.js'), 'orderItems.js')
  resolveStoreModules(require('../store/orders.js'), 'orders.js')
  resolveStoreModules(require('../store/pages.js'), 'pages.js')
  resolveStoreModules(require('../store/payments.js'), 'payments.js')
  resolveStoreModules(require('../store/personalProfiles.js'), 'personalProfiles.js')
  resolveStoreModules(require('../store/platforms.js'), 'platforms.js')
  resolveStoreModules(require('../store/priceFiles.js'), 'priceFiles.js')
  resolveStoreModules(require('../store/priceStatus.js'), 'priceStatus.js')
  resolveStoreModules(require('../store/priceTags.js'), 'priceTags.js')
  resolveStoreModules(require('../store/priceUploads.js'), 'priceUploads.js')
  resolveStoreModules(require('../store/pricingSchemes.js'), 'pricingSchemes.js')
  resolveStoreModules(require('../store/productLines.js'), 'productLines.js')
  resolveStoreModules(require('../store/salePoints.js'), 'salePoints.js')
  resolveStoreModules(require('../store/search.js'), 'search.js')
  resolveStoreModules(require('../store/searchLocation.js'), 'searchLocation.js')
  resolveStoreModules(require('../store/selectionRequests.js'), 'selectionRequests.js')
  resolveStoreModules(require('../store/selectionResponses.js'), 'selectionResponses.js')
  resolveStoreModules(require('../store/sellerPhotos.js'), 'sellerPhotos.js')
  resolveStoreModules(require('../store/sellerTrademarks.js'), 'sellerTrademarks.js')
  resolveStoreModules(require('../store/seo.js'), 'seo.js')
  resolveStoreModules(require('../store/shipments.js'), 'shipments.js')
  resolveStoreModules(require('../store/socialUsers.js'), 'socialUsers.js')
  resolveStoreModules(require('../store/users.js'), 'users.js')
  resolveStoreModules(require('../store/warehouses.js'), 'warehouses.js')

  // If the environment supports hot reloading...

  if (process.client && module.hot) {
    // Whenever any Vuex module is updated...
    module.hot.accept([
      '../store/analytics.js',
      '../store/auth.js',
      '../store/balance.js',
      '../store/basketItems.js',
      '../store/baskets.js',
      '../store/businessProfiles.js',
      '../store/businessUsers.js',
      '../store/buyerRecipientAddresses.js',
      '../store/buyerRecipients.js',
      '../store/cache.js',
      '../store/carBrands.js',
      '../store/carModels.js',
      '../store/carModifications.js',
      '../store/cars.js',
      '../store/deliveryRates.js',
      '../store/dependencies.js',
      '../store/errors.js',
      '../store/index.js',
      '../store/orderDeliveryAddresses.js',
      '../store/orderItems.js',
      '../store/orders.js',
      '../store/pages.js',
      '../store/payments.js',
      '../store/personalProfiles.js',
      '../store/platforms.js',
      '../store/priceFiles.js',
      '../store/priceStatus.js',
      '../store/priceTags.js',
      '../store/priceUploads.js',
      '../store/pricingSchemes.js',
      '../store/productLines.js',
      '../store/salePoints.js',
      '../store/search.js',
      '../store/searchLocation.js',
      '../store/selectionRequests.js',
      '../store/selectionResponses.js',
      '../store/sellerPhotos.js',
      '../store/sellerTrademarks.js',
      '../store/seo.js',
      '../store/shipments.js',
      '../store/socialUsers.js',
      '../store/users.js',
      '../store/warehouses.js',
    ], () => {
      // Update `root.modules` with the latest definitions.
      updateModules()
      // Trigger a hot update in the store.
      window.$nuxt.$store.hotUpdate(store)
    })
  }
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
