var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-trademarks-page" },
    [
      _vm.isLoading
        ? _c("TheLoader")
        : _vm.isEmptyResult
        ? _c("EmptySearchResult", { attrs: { name: _vm.$route.query.q } })
        : [
            _c(
              "h1",
              {
                staticClass:
                  "custom-headline custom-headline--level2-bold mb-2",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("search.selectProducer")) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("TrademarksTable" + (_vm.maxmq("md") ? "M" : ""), {
                  tag: "component",
                  attrs: { value: _vm.trademarks.catalog },
                  on: {
                    "row-click": _vm.goToArticle,
                    "info:show": _vm.showFullInfo,
                  },
                }),
              ],
              1
            ),
          ],
      _vm._v(" "),
      _vm.$store.getters.isAppLoaded
        ? [
            _c(
              "TheDialog",
              {
                model: {
                  value: _vm.wareInfoId,
                  callback: function ($$v) {
                    _vm.wareInfoId = $$v
                  },
                  expression: "wareInfoId",
                },
              },
              [
                _c("WareInfo", {
                  attrs: { id: _vm.wareInfoId },
                  on: {
                    cancel: function ($event) {
                      _vm.wareInfoId = null
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }