<template>
  <div class="search-trademarks-page">
    <TheLoader v-if="isLoading" />

    <EmptySearchResult v-else-if="isEmptyResult" :name="$route.query.q" />

    <template v-else>
      <h1 class="custom-headline custom-headline--level2-bold mb-2">
        {{ $t('search.selectProducer') }}
      </h1>

      <div class="content">
        <component
          :is="`TrademarksTable${maxmq('md') ? 'M' : ''}`"
          :value="trademarks.catalog"
          @row-click="goToArticle"
          @info:show="showFullInfo"
        />
      </div>
    </template>

    <template v-if="$store.getters.isAppLoaded">
      <TheDialog v-model="wareInfoId">
        <WareInfo :id="wareInfoId" @cancel="wareInfoId = null" />
      </TheDialog>
    </template>
  </div>
</template>

<script>
import partition from 'lodash/partition';
import get from 'lodash/get';
import { env, parsers, getDefault } from '@/lib/core';
import getSeoBreadcrumbs from '@/lib/catalog/services/getSeoBreadcrumbs';

export default {
  name: 'SearchTrademarksPage',

  components: {
    /* eslint-disable max-len */
    WareInfo: () => import(/* webpackChunkName: "WareInfo" */ './components/wareInfo/WareInfo'),
    TrademarksTable: () => import(/* webpackChunkName: "TrademarksTable" */ './components/TrademarksTable'),
    TrademarksTableM: () => import(/* webpackChunkName: "TrademarksTableM" */ './components/TrademarksTableM'),
    EmptySearchResult: () => import(/* webpackChunkName: "EmptySearchResult" */ '@/lib/core/components/EmptySearchResult')
    /* eslint-enable max-len */
  },

  asyncData ({ app, store, query }) {
    const q = query.q;

    const rowBreadcrumbs = [{
      name: 'search',
      label: q,
      path: `/search?q=${q}`
    }];

    const pl = {
      vm: { $store: store, $router: app.router },
      rowBreadcrumbs
    };
    const { breadcrumbs, seoBreadcrumbs } = getSeoBreadcrumbs(pl);

    store.dispatch('setBreadcrumbs', breadcrumbs);

    return { seoBreadcrumbs };
  },

  data: () => ({
    isLoading: true,
    isShowCollection: false,
    trademarks: getDefault('trademarks'),
    wareInfoId: null
  }),

  computed: {
    isEmptyResult () {
      return !this.trademarks.catalog.length;
    }
  },

  watch: {
    '$route.query.q': {
      immediate: true,
      handler: 'searchInWares'
    }
  },

  methods: {
    async searchInWares () {
      const { q } = this.$route.query;

      if (!q) {
        this.isLoading = false;
        return null;
      }

      this.isLoading = true;

      try {
        const res = await this.$store.dispatch(
          'cache/get',
          { action: 'search/searchInWares', payload: { query: q } }
        );

        const wares = parsers.addIdsTo(res.results);

        this.setData(wares);
      } catch (e) {
        console.error(e);
        this.clearTrademarks();
        this.isLoading = false;
      }
    },

    setData (wares) {
      if (wares.length > 1) {
        const [catalog, collection] = partition(wares, ({ ware }) => ware);

        this.trademarks = {
          catalog,
          collection,
          collectionCount: collection && collection.length
        };

        if (catalog.length === 1) {
          return this.goToArticle(catalog[0]);
        }

        this.customizeUi();
        this.isLoading = false;
      } else if (wares.length === 1) {
        this.goToArticle(wares[0]);
      } else {
        this.clearTrademarks();
        this.customizeUi();
        this.isLoading = false;
      }
    },

    clearTrademarks () {
      this.trademarks = getDefault('trademarks');
    },

    customizeUi () {
      this.isShowCollection =
        this.trademarks.collectionCount &&
        !this.trademarks.catalog.length
          ? 'collection'
          : '';
    },

    goToArticle (row) {
      this.$router.push(parsers.getSearchRouteBy(this, row));
    },

    showFullInfo (ware) {
      this.wareInfoId = ware.ware_id || get(ware, 'ware.id') || ware.id;
    }
  },

  head () {
    return {
      meta: [{ name: 'robots', content: env.ROBOTS_VALUES }],
      script: [{ type: 'application/ld+json', json: this.seoBreadcrumbs }],
      title: this.$t(
        'search.searchTitle',
        { search: `${this.$route.query.q}`, mp: env.BASE_CLIENT_PATH }
      )
    };
  }
};
</script>

<style scoped lang="scss">
.search-trademarks-page {
  .trademarks-table {
    min-width: 860px;
    margin: 0 auto;
  }

  .content {
    display: flex;
    justify-content: center;
  }
}
</style>
