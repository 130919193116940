var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "block-size", style: _vm.boxSize },
    [
      _vm._t("default", function () {
        return [
          _c("img", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            staticClass: "img",
            attrs: {
              "data-src": _vm.srcOrPh,
              width: _vm.boxSize.width,
              alt: _vm.alt,
              title: _vm.alt,
              height: _vm.boxSize.height,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }