<template>
  <el-input
    class="phone-input"
    type="tel"
    :clearable="clearable"
    :value="phoneNumber"
    :placeholder="placeholder"
    @input="setNum"
  />
</template>

<script>
import env from '@/lib/core/services/envConstants';

export default {
  name: 'PhoneInput',

  props: {
    clearable: Boolean,
    value: { type: String, required: true },
    placeholder: String
  },

  computed: {
    phoneNumber: {
      get () {
        const num = this.value;

        return num.startsWith(env.MARKETPLACE_PHONE_PREFIX)
          ? num
          : `${env.MARKETPLACE_PHONE_PREFIX}${num}`;
      },

      set (num) {
        const _num = !num || num.startsWith(env.MARKETPLACE_PHONE_PREFIX)
          ? num
          : `${env.MARKETPLACE_PHONE_PREFIX}${num}`;

        this.$emit('input', _num.trim());
      }
    }
  },

  methods: {
    setNum (value) {
      if (value === env.MARKETPLACE_PHONE_PREFIX) {
        this.phoneNumber = '';
      } else if (value.startsWith(env.MARKETPLACE_PHONE_PREFIX)) {
        this.phoneNumber = value;
      }
    }
  }
};
</script>
