<template>
  <div class="s-request-items-view">
    <component
      :is="`CarInfoCard${isMobile ? 'M' : ''}`"
      v-if="car"
      :value="car"
      class="mb-2"
    />

    <div class="mb-2">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item-row grey-block mb-2"
      >
        <div class="bold">{{ item.rawItem }}</div>
        <div v-if="item.comment" class="mt-1">{{ item.comment }}</div>
      </div>
    </div>

    <div v-if="comment" class="grey-block comment">
      <div class="mb-1 bold">
        {{ $t('garage.selectionRequests.comment') }}
      </div>
      <div>{{ comment }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SRequestItemsView',

  components: {
    /* eslint-disable max-len */
    CarInfoCard: () => import(/* webpackChunkName: "CarInfoCard" */ '@/lib/garage/components/CarInfoCard'),
    CarInfoCardM: () => import(/* webpackChunkName: "CarInfoCardM" */ '@/lib/garage/components/CarInfoCardM')
    /* eslint-enable max-len */
  },

  props: {
    car: Object,
    items: { type: Array, required: true },
    comment: String
  },

  computed: {
    isMobile () {
      return this.maxmq('xs');
    }
  }
};
</script>

<style scoped lang="scss">
.grey-block {
  padding: 20px;
  background-color: $grey-200;
  border-radius: 4px;

  &.comment {
    padding: 10px;
    background-color: $blue-50;
  }
}
</style>
