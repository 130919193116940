var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sale-points-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("routes.profile_business_sale_points")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb-2", attrs: { justify: "end" } },
        [
          _c(
            "nuxt-link",
            {
              attrs: {
                to: {
                  name: _vm.getRouteName("profile.business.sale-points.create"),
                },
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.isAdmin,
                    size: "mini",
                    type: "primary",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("buttons.createSalePoint")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("TheLoader")
        : _c("SalePointsTable", {
            attrs: { value: _vm.tableData, readonly: !_vm.isAdmin },
            on: { "row-click": _vm.goSalePoint },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }