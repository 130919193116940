import { parsers } from '@/lib/core';

export const actions = {
  postPriceUpload ({ dispatch }, { formData }) {
    return this.$axios.$post('/price-uploads/', formData);
  },

  async reUploadPrice ({ dispatch }, payload) {
    const {
      formData,
      upload_url,
      upload_url_post_data,
      file,
      businessId,
      priceUploadId
    } = payload;

    const fileData = {
      url: upload_url,
      upload_url_post_data,
      file,
      businessId,
      priceUploadId
    };

    if (formData.status !== 'in_progress') {
      await dispatch('patchPrice', {
        id: priceUploadId,
        formData: { status: 'in_progress' }
      });
    }

    await dispatch('postPriceToS3', fileData);

    return await dispatch('patchPrice', {
      id: priceUploadId,
      push: true,
      formData: {
        ...formData,
        status: 'done'
      }
    });
  },

  postPriceToS3 ({ getters, commit, dispatch }, payload) {
    const {
      url,
      file,
      method = 'post',
      upload_url_post_data,
      businessId,
      priceUploadId
    } = payload;

    const data = parsers.getMultipartFormData(
      upload_url_post_data || {},
      file,
      'file'
    );

    return this.$axios({
      method,
      baseURL: url,
      data,
      headers: {
        'x-amz-meta-business': businessId,
        'x-amz-meta-price-upload': priceUploadId
      },
      withCredentials: false,
      progress: false,
      timeout: 0,
      onUploadProgress (progressEvent) {
        const percent = Math
          .round((progressEvent.loaded * 100) / progressEvent.total);

        const data = { percent, code: 'price', suffix: '%' };

        dispatch('setProgress', data, { root: true });
      }
    });
  },

  getPrices ({ commit }, params) {
    return this.$axios.$get('/price-uploads/', { params });
  },

  getPrice ({ commit, dispatch }, { id }) {
    return this.$axios.$get(`/price-uploads/${id}/`);
  },

  patchPrice ({ commit, dispatch }, { id, formData, push }) {
    return this.$axios
      .$patch(`/price-uploads/${id}/?push=${!!push}`, formData);
  },

  async getPricePreview (_, { id }) {
    const { data_preview } = await this.$axios
      .$get(`/price-uploads/${id}/`, null, { progress: false });

    return data_preview;
  },

  removePrice (_, { id }) {
    return this.$axios.$delete(`/price-uploads/${id}/`);
  }
};
