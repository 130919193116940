import cloneDeep from 'lodash/cloneDeep';
import env from './envConstants';

const trademarks = {
  catalog: [],
  collection: [],
  collectionCount: 0
};

const sellerTrademark = {
  business: null,
  trademark_name: '',
  verification_url: '',
  verification_document: null,
  is_active: true
};

const time = [
  new Date(2019, 0, 1, 9, 0),
  new Date(2019, 0, 1, 19, 0)
];

const schedule = {
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null
};

const phone = {
  phone_number: env.MARKETPLACE_PHONE_PREFIX,
  is_primary: true,
  channels: ['phone'],
  description: ''
};

const email = {
  email: null,
  is_primary: true,
  description: ''
};

const termsUrl = {
  page_url: '',
  url: '',
  id: null,
  is_verified: false,
  type: ''
};

const businessProfile = {
  name: '',
  site: '',
  is_seller: null,
  logo: null,
  acl: [{
    phone_numbers: [cloneDeep(phone)],
    emails: [cloneDeep(email)]
  }]
};

const address = {
  city: null,
  street: '',
  building: '',
  room: '',
  description: '',
  city_detail: {
    country: null,
    admin1: null,
    city: null
  },
  longitude: null,
  latitude: null,
  has_physical_address: true
};

const salePoint = {
  name: null,
  address: cloneDeep(address),
  activities: [],
  schedule: {},
  phone_numbers: [cloneDeep(phone)],
  emails: [],
  payment_methods: ['cash_payment'],
  payment_terms: [],
  delivery_methods: ['pickup'],
  other_selling_terms: [],
  payment_methods_text: '',
  payment_terms_text: '',
  delivery_methods_text: '',
  delivery_services: []
};

const deliveryService = {
  service: '', // http://dev.mriyar.ua/api/mp/v1/delivery-services/1/',
  free_ship_amount: 0,
  service_detail: {
    // "id": 1,
    // "label": "novaposhta.ua",
    // "weight": 10,
    // "is_active": true
  },
  url: '',
  is_active: true,
  has_free_ship: false,
  comment: ''
};

const priceUpload = {
  file_type: 'csv',
  file_encoding: 'auto',
  csv_delimiter: '',
  filename: ''
};

const priceFile = {
  is_active: true,
  name: '',
  trademark: null,
  type: 'own',
  quantity_warehouse_is_own: false,
  currency: null,
  payment_terms: [],
  payment_methods: [],
  delivery_methods: [],
  other_selling_terms: [],
  payment_terms_text: '',
  warranty_terms_text: '',
  header_row: 1,
  data_sheet: 1,
  data_start_row: 2,
  column_mapping: [],
  availabilities: [],
  description: ''
};

const priceScheme = {
  business: null,
  name: '',
  currency: null,
  scheme_type: null,
  static_coefficient: '30.00',
  min_price: '0.50',
  max_price: '50.00',
  max_markup: '80.00',
  min_markup: '7.00'
};

const deliveryRate = {
  business: null,
  name: null,
  currency: null,
  correction_factor: '0.000',
  constant: '0.00',
  weight_rate: '0.00',
  no_weight_factor: '0.000',
  no_trade_without_weight: false
};

const searchFilter = {
  onlySelectArticle: false, // use only in route
  onlyCity: false, // use only in route
  ware_and_analogs: true,
  city_and_delivery: true,
  trademarks: [],
  in_stock: false,
  is_return_possible: false,
  is_warranted: false,
  price_gte: 0,
  price_lte: 9999999,
  delivery_time_gte: 0,
  delivery_time_lte: 360,
  payment_methods: [],
  delivery_methods: [],
  other_selling_terms: [],
  payment_terms: [],
  sale_activities: [],
  is_official_trademark: false,
  limit_by_order_by: ['-rank'],
  ordering: ['-rank']
};

const mapSearchSettings = {
  limit_by_point_of_sale: 1,
  has_physical_address: true,
  limit_by_order_by: '-rank,delivery_time,price'
};

const articlesRes = {
  count: 0,
  trademarks: [],
  results: [],
  min_price: searchFilter.price_gte,
  max_price: searchFilter.price_lte
};

const basket = {
  items: [],
  total_amount: 0,
  total_quantity: 0
};

const { longitude, latitude, has_physical_address, ..._address } = address;

const deliveryAddress = {
  ...cloneDeep(_address),
  type: null,
  service_office_number: '',
  service_name: '',
  service: '',
  buyer: null,
  text: '',
  region: null,
  city_text: '',
  region_detail: null
};

const order = {
  buyer_first_name: '',
  buyer_last_name: '',
  buyer_phone_number: cloneDeep(phone),
  buyer_email: cloneDeep(email),
  delivery_method: null,
  delivery_address: cloneDeep(deliveryAddress),
  recipient_first_name: '',
  recipient_last_name: '',
  recipient_middle_name: '',
  recipient_phone_number: cloneDeep(phone),
  payment_method: null,
  no_call_required: false,
  comment: '',
  agree_until: '',
  basket_items: [],
  items: []
};

const shipment = {
  point_of_sale: null,
  buyer: null,
  waybill_date: null,
  waybill: '',
  delivery_method: null,
  delivery_address: cloneDeep(deliveryAddress),
  recipient_first_name: '',
  recipient_last_name: '',
  recipient_phone_number: cloneDeep(phone),
  comment: ''
};

const recipient = {
  first_name: '',
  last_name: '',
  middle_name: '',
  buyer: null,
  phone_number: cloneDeep(phone)
};

const catalogOptions = {
  level: 0, // 33.6, // Reliability level of communication pair
  status: 3, // Validation mode
  display_mode: 2 // Universal Product Display Mode
};

const carModificationFilter = {
  brand: null,
  model: null,
  production_year: null,
  body_type: null,
  engine_type: null,
  capacity: null,
  fuel_type: null,
  modification: null
};

const priceFileEncoding = {
  file_encoding: 'auto',
  file_type: 'csv',
  csv_delimiter: '',
  filename: ''
};

const bankRequisites = {
  iban: '',
  name: '',
  bic: '',
  iban_page: null
};

const businessRequisites = {
  type: null,
  first_name: '',
  last_name: '',
  middle_name: '',
  itn: '',
  udrpou: '',
  official_name: '',
  short_name: '',
  is_vat_payer: null,
  itn_of_vat_payer: '',
  signatory_full_name: '',
  juridical_address: cloneDeep(address),
  actual_address: cloneDeep(address),
  bank_requisites: cloneDeep(bankRequisites),
  phone_number: {
    ...cloneDeep(phone),
    channels: ['mobile']
  },
  email: cloneDeep(email),
  udrpou_page_1: null,
  udrpou_page_2: null,
  itn_of_vat_payer_page: null
};

const car = {
  brand: null,
  model: null,
  photo: null,
  productionYear: null,
  bodyType: null,
  fuelType: null,
  engineCapacity: null,
  modification: null,
  engineCode: '',
  powerPs: null,
  valves: null,
  vin: '',
  insuranceExpiryDate: '',
  licensePlate: '',
  comment: '',
  color: '',
  driveType: null,
  driveTypeText: '',
  gearSteps: null,
  engineType: null,
  gearType: null,
  gearTypeText: '',
  name: null
};

const selectionRequestItem = {
  rawItem: '',
  comment: ''
};

const selectionRequest = {
  ...car,
  itemsAdd: [{ ...selectionRequestItem }]
};

const selectionRequestMaster = {
  person: null,
  phoneNumber: null,
  phoneNumberChannels: ['phone'],
  city: null,
  business: null,

  name: null,
  brand: null,
  model: null,
  modification: null,
  bodyType: null,
  driveType: null,
  fuelType: null,
  engineCapacity: null,
  valves: null,
  powerPs: null,
  productionYear: null,
  gearType: null,
  engineCode: null,
  engineType: null,
  vin: null,

  itemsAdd: [{ ...selectionRequestItem }]
};

const selectionResponse = {
  item: null,
  ware_id: null,
  ware_image_url: null,
  offer_id: null,
  article: '',
  trademark: '',
  name: '',
  price: '',
  delivery_time: '',
  is_former_use: false,
  comment_private: '',
  comment_public: '',
  is_article_hidden: false
};

export const defaultEntities = {
  address,
  phone,
  email,
  termsUrl,
  searchFilter,
  businessProfile,
  salePoint,
  priceUpload,
  priceFile,
  priceScheme,
  trademarks,
  sellerTrademark,
  time,
  schedule,
  deliveryRate,
  mapSearchSettings,
  articlesRes,
  basket,
  bankRequisites,
  order,
  deliveryAddress,
  recipient,
  shipment,
  catalogOptions,
  carModificationFilter,
  priceFileEncoding,
  businessRequisites,
  car,
  selectionRequestItem,
  selectionRequest,
  selectionRequestMaster,
  selectionResponse,
  deliveryService
};

export const getDefault = (name) => {
  const entity = defaultEntities[name];

  return entity
    ? cloneDeep(entity)
    : console.error(`THERE IS NO DEFAULT ENTITY: ${name}`) && null;
};
