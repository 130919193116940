<script>
import MappingBlock from './formBlocks/MappingBlock';
import AutoUpdateBlock from './formBlocks/AutoUpdateBlock';
import UploadInfoBlock from './formBlocks/UploadInfoBlock';
import FormTab from '@/lib/core/components/FormTab';

export default {
  name: 'PriceProcessingTab',

  extends: FormTab,

  components: {
    UploadInfoBlock,
    AutoUpdateBlock,
    MappingBlock
  },

  data: () => ({
    blocks: ['uploadInfo', 'autoUpdate', 'mapping']
  })
};
</script>
