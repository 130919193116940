var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentProfile && _vm.isSeller
    ? _c(
        "el-row",
        {
          staticClass: "business-menu-short",
          class: ["business-menu-short", "border-bottom"],
          attrs: { align: "middle", justify: "space-between", type: "flex" },
        },
        [
          !_vm.maxmq("xs")
            ? _c("div", { staticClass: "label bold" }, [
                _vm._v(
                  "\n    " + _vm._s(_vm.$t("routes.menuBusiness")) + ":\n  "
                ),
              ])
            : _c("div"),
          _vm._v(" "),
          !_vm.hasPrices && !_vm.maxmq("lg")
            ? _c(
                "div",
                [
                  !_vm.hasSellPoint
                    ? _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: {
                              name: _vm.getRouteName(
                                "profile.business.sale-points.create"
                              ),
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(_vm.$t("profile.sellIsEasily"))),
                          ]),
                          _vm._v(
                            "\n      " +
                              _vm._s(_vm.$t("salePoint.createSellPointFirst")) +
                              "\n    "
                          ),
                        ]
                      )
                    : !_vm.hasPrices
                    ? _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: {
                              name: _vm.getRouteName(
                                "profile.business.prices.upload"
                              ),
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(_vm.$t("profile.sellIsEasily"))),
                          ]),
                          _vm._v(
                            "\n      " +
                              _vm._s(_vm.$t("prices.saleYourWaresEasy")) +
                              "\n    "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c("div"),
          _vm._v(" "),
          _c("BusinessHotKeys"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }