var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "entrepreneur-info" },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("requisites.info.itn"), prop: "itn" } },
        [
          _c("el-input", {
            staticClass: "max-w-182p",
            attrs: { value: _vm.value.itn },
            on: {
              input: function ($event) {
                return _vm.emit("itn", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.lastName"),
            prop: "last_name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-420p",
            attrs: { value: _vm.value.last_name },
            on: {
              input: function ($event) {
                return _vm.setName({ last_name: $event })
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.firstName"),
            prop: "first_name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-420p",
            attrs: { value: _vm.value.first_name },
            on: {
              input: function ($event) {
                return _vm.setName({ first_name: $event })
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.middleName"),
            prop: "middle_name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-420p",
            attrs: { value: _vm.value.middle_name },
            on: {
              input: function ($event) {
                return _vm.setName({ middle_name: $event })
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.info.pdv"),
            "label-width": "300px",
            prop: "is_vat_payer",
          },
        },
        [
          _c(
            "el-radio-group",
            {
              attrs: { value: _vm.value.is_vat_payer },
              on: {
                input: function ($event) {
                  return _vm.emit("is_vat_payer", $event)
                },
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "mt-1" },
                [
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v(_vm._s(_vm.$t("core.yes"))),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v(_vm._s(_vm.$t("core.no"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.value.is_vat_payer
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("requisites.info.certificate"),
                "label-width": "300px",
                prop: "itn_of_vat_payer",
              },
            },
            [
              _c("el-input", {
                staticClass: "max-w-182p",
                attrs: { value: _vm.value.itn_of_vat_payer },
                on: {
                  input: function ($event) {
                    return _vm.emit("itn_of_vat_payer", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }