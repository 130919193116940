var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "price-create-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("routes.profile_business_prices_create")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("PriceCreateSteps", { attrs: { active: _vm.step } }),
      _vm._v(" "),
      !_vm.isPageLoaded
        ? _c("TheLoader")
        : [
            _vm.step !== 3
              ? _c(
                  "div",
                  { staticClass: "price-label" },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "custom-headline custom-headline--level2-bold mr-1",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.formData.name) + "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.step === 1
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr-1",
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    _vm.isUploadDialog = true
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("buttons.uploadPrice")) +
                                    "\n        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("PriceRemover", {
                              attrs: { price: _vm.dependency.price },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "form-block",
                attrs: {
                  "label-position": "right",
                  "label-width": "160px",
                  model: _vm.formData,
                  rules: _vm.rules,
                  size: "mini",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _vm.step === 1
                  ? _c(
                      "div",
                      { staticClass: "outline-block" },
                      [
                        _c("div", { staticClass: "outline-block__title" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("priceFile.mainInfoBlock")) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "MainInfo",
                          _vm._b(
                            {
                              attrs: {
                                dependency: _vm.dependency,
                                value: _vm.formData,
                              },
                            },
                            "MainInfo",
                            _vm.formData,
                            false,
                            true
                          )
                        ),
                      ],
                      1
                    )
                  : _vm.step === 2
                  ? _c(
                      "div",
                      { staticClass: "outline-block" },
                      [
                        _c("div", { staticClass: "outline-block__title" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("priceFile.mappingBlock")) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.isPriceError
                          ? [
                              _c("div", { staticClass: "mb-2" }, [
                                _c("h4", { staticClass: "color-red" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("prices.status")) +
                                      ": " +
                                      _vm._s(
                                        _vm.getPETranslation(_vm.errorStatus)
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", { staticClass: "color-red" }, [
                                  _vm._v(_vm._s(_vm.price.error_message)),
                                ]),
                              ]),
                            ]
                          : !_vm.get(_vm.dependency, "price.data_preview")
                          ? [
                              _c("div", { staticClass: "pg-2 text-center" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("priceFile.waitPreview")) +
                                    "\n          "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("TheLoader"),
                            ]
                          : [
                              _c(
                                "FileProcessing",
                                _vm._b(
                                  {
                                    attrs: {
                                      dependency: _vm.dependency,
                                      value: _vm.formData,
                                      "data-preview-count":
                                        _vm.dependency.price.data_preview_count,
                                    },
                                    on: {
                                      "page:update": function ($event) {
                                        _vm.formData.column_mapping = []
                                      },
                                    },
                                  },
                                  "FileProcessing",
                                  _vm.formData,
                                  false,
                                  true
                                )
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    align: "middle",
                                    justify: "space-between",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "color-label" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("priceFile.firstTenRow")
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          _vm.isFormatDialog = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("buttons.wrongTableData")
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "mapping mb-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "table-container" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        ref: "mapping",
                                        attrs: {
                                          "label-width": "0",
                                          prop: "column_mapping",
                                        },
                                      },
                                      [
                                        !_vm.isLoading
                                          ? _c("ColumnMapping", {
                                              staticClass: "mb-2",
                                              attrs: {
                                                dependency: _vm.dependency,
                                              },
                                              on: {
                                                input: _vm.clearMapValidation,
                                              },
                                              model: {
                                                value:
                                                  _vm.formData.column_mapping,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "column_mapping",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.column_mapping",
                                              },
                                            })
                                          : _c("div", { staticClass: "pg-2" }, [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "priceMapping.isMappingProcessing"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("PricePreviewTable", {
                                      attrs: {
                                        "data-preview":
                                          _vm.dependency.price.data_preview,
                                        "data-start-row":
                                          _vm.formData.data_start_row,
                                        "header-row": _vm.formData.header_row,
                                        mapping: _vm.formData.column_mapping,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                      ],
                      2
                    )
                  : !_vm.isEmpty(
                      _vm.get(_vm.dependency, "price.data_preview")
                    ) && _vm.step === 3
                  ? _c(
                      "div",
                      { staticClass: "outline-block" },
                      [
                        _c("div", { staticClass: "outline-block__title" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("priceFile.availabilitiesBlock")) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "0",
                              prop: "availabilities",
                            },
                          },
                          [
                            _c(
                              "Availabilities",
                              _vm._b(
                                {
                                  attrs: {
                                    dependency: Object.assign(
                                      {},
                                      _vm.dependency,
                                      {
                                        column_mapping:
                                          _vm.formData.column_mapping,
                                      }
                                    ),
                                    value: _vm.formData.availabilities,
                                  },
                                },
                                "Availabilities",
                                _vm.formData,
                                false,
                                true
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm.step === 4
                  ? _c("PriceCreateStatus", { attrs: { errors: _vm.errors } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step === 4
                  ? _c(
                      "el-row",
                      { staticClass: "mt-2", attrs: { justify: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.next },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("buttons.finish")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-row",
                      {
                        staticClass: "mt-2",
                        attrs: { justify: "space-between" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _vm.step !== 4
                              ? _c("el-button", { on: { click: _vm.cancel } }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("buttons.cancel")) +
                                      "\n          "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _vm.step !== 4
                              ? _c("el-button", { on: { click: _vm.back } }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("buttons.back")) +
                                      "\n          "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.isNextDisabled,
                                  loading: _vm.isLoading,
                                  type: "primary",
                                },
                                on: { click: _vm.next },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("buttons.next")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
          ],
      _vm._v(" "),
      _vm.dependency.price && _vm.isUploadDialog
        ? _c("PriceReUploadDialog", {
            attrs: { price: _vm.dependency.price },
            on: {
              cancel: function ($event) {
                _vm.isUploadDialog = false
              },
              "price:update": _vm.updatePrice,
              "status:update": _vm.updatePriceStatus,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: {
            title: _vm.$t("prices.formatting"),
            value: _vm.dependency.price && _vm.isFormatDialog,
          },
          on: {
            cancel: function ($event) {
              _vm.isFormatDialog = false
            },
          },
        },
        [
          _c("PriceFormatForm", {
            attrs: { price: _vm.dependency.price },
            on: {
              cancel: function ($event) {
                _vm.isFormatDialog = false
              },
              "price:update": _vm.updatePrice,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }