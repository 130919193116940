import { render, staticRenderFns } from "./BuyerOrderPage.vue?vue&type=template&id=554fe46c&scoped=true&"
import script from "./BuyerOrderPage.vue?vue&type=script&lang=js&"
export * from "./BuyerOrderPage.vue?vue&type=script&lang=js&"
import style0 from "./BuyerOrderPage.vue?vue&type=style&index=0&id=554fe46c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "554fe46c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('554fe46c')) {
      api.createRecord('554fe46c', component.options)
    } else {
      api.reload('554fe46c', component.options)
    }
    module.hot.accept("./BuyerOrderPage.vue?vue&type=template&id=554fe46c&scoped=true&", function () {
      api.rerender('554fe46c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "lib/orders/BuyerOrderPage.vue"
export default component.exports