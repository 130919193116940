import { render, staticRenderFns } from "./VFacebookLogin.vue?vue&type=template&id=50dd387c&scoped=true&"
import script from "./VFacebookLogin.vue?vue&type=script&lang=js&"
export * from "./VFacebookLogin.vue?vue&type=script&lang=js&"
import style0 from "./VFacebookLogin.vue?vue&type=style&index=0&id=50dd387c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50dd387c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50dd387c')) {
      api.createRecord('50dd387c', component.options)
    } else {
      api.reload('50dd387c', component.options)
    }
    module.hot.accept("./VFacebookLogin.vue?vue&type=template&id=50dd387c&scoped=true&", function () {
      api.rerender('50dd387c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "lib/auth/plugins/FB/VFacebookLogin.vue"
export default component.exports