import get from 'lodash/get';
import getSeoBreadcrumbs from './getSeoBreadcrumbs';
import getSeoLinkBy from './getSeoLinkBy';

const getProductLineLinks = ({ ware, vm }) => {
  const result = [];
  const parent1 = get(ware, 'product_line.parent');
  const parent2 = get(ware, 'product_line.parent.parent');

  [parent2, parent1, ware.product_line].forEach((item) => {
    if (get(item, 'slug')) {
      const params = { lineId: item.slug };

      const { name, path } = getSeoLinkBy(params, vm, params);

      result.push({
        name,
        path,
        label: item.name || ware.name
      });
    }
  });

  return result;
};

export default function ({ ware, vm }) {
  const rowBreadcrumbs = [];

  if (ware) {
    const lines = getProductLineLinks({ ware, vm });

    rowBreadcrumbs.push(...lines);

    const {
      id,
      article,
      name,
      article_slug,
      product_line
    } = ware;

    const tm_sl = ware.trademark?.slug || '';
    const params = {
      trademarkId: tm_sl,
      lineId: get(product_line, 'slug')
    };

    const tmLink = getSeoLinkBy(params, vm, params);
    const tmName = ware.trademark?.name || tm_sl || '';
    const meta = `${tm_sl}-${article_slug}`;

    rowBreadcrumbs.push({
      name: tmLink.name,
      label: tmName,
      path: vm.$router.resolve(tmLink).href
    });

    rowBreadcrumbs.push({
      name: 'wareId',
      label: `${name} ${tmName} ${article}`,
      path: `/part-${meta}-${id}`
    });
  }

  const pl = { vm, rowBreadcrumbs };
  const { breadcrumbs, seoBreadcrumbs } = getSeoBreadcrumbs(pl);

  return { breadcrumbs, seoBreadcrumbs };
}
