import { gql } from 'graphql-tag';

export const GARAGE_FULL_CAR_FRAGMENT = `
fragment GARAGE_FULL_CAR_FRAGMENT on GarageCar {
  sHasAbd
  sCentralLock
  sHasAbs
  sHasAsr
  sHasEsp
  sAirbag
  sSignalling
  sImmobilizer
  sHeadlight
  sHasBsm
  sHasNv
  sHasGearLock
  sHasAirSuspension
  sHasArmoredBody
  mHasMp3
  mHasCd
  mHasTapeRecorder
  mHasAcoustics
  mHasDvd
  mHasGps
  mHasSubwoofer
  mHasBluetooth
  mHasCarPlay
  mHasAndroidAuto
  cHasPowerSteering
  cWindowLifters
  cHasRoofRails
  cHasParkAssist
  cHasLaneAssist
  cHasConditioner
  cHasMirrorsHeating
  cHasMirrorsDimming
  cClimateControl
  cSeatsHeating
  cHasMultimediaSteering
  cHasCruiseControl
  cHasParktronic
  cHasLightSensor
  cInteriorMaterial
  cHasRainSensor
  cSunroof
  cHasHeadlightWasher
  cHasStartButton
  cHasSeatMemory
  cHasSteeringHeating
  cHasSummerTires
  cHasWinterTires
  cHasGlassTinting
  cHasTowbar
  cHasGasInstallation
}
`;

export const GARAGE_CAR_MAIN_FRAGMENT = `
fragment GARAGE_CAR_MAIN_FRAGMENT on GarageCar {
  id
  powerPs
  name
  engineCapacity
  productionYear
  vin
  
  brand {
    id
    name
  }
  model {
    id
    name
  }
  modification {
    id
    name
  }
  fuelType {
    id
    name
  }
  bodyType {
    id
    name
  }
}
`;

export const GARAGE_CAR_FRAGMENT = `
fragment GARAGE_CAR_FRAGMENT on GarageCar {
  id
  name
  name
  isActive
  vin
  productionYear
  engineCode
  valves
  gearSteps
  powerPs
  engineCapacity
  color
  comment
  licensePlate
  insuranceExpiryDate
    
  business {
    id
  }
  brand {
    id
    name
  }
  model {
    id
    name
  }
  modification {
    id
    name
  }
  driveType {
    id
    name
  }
  engineType {
    id
    name
  }
  gearType {
    id
    name
  }
  fuelType {
    id
    name
  }
  bodyType {
    id
    name
  }
}
`;

export const GET_GARAGE_CARS = gql`
  query GetGarageCars($person: ID, $business: ID, $garageCarsId: ID, $isActive: Boolean) {
    garageCars(person: $person, business: $business, id: $garageCarsId, isActive: $isActive) {
      ...GARAGE_CAR_FRAGMENT
      ...GARAGE_FULL_CAR_FRAGMENT
    }
  }
  ${GARAGE_CAR_FRAGMENT}
  ${GARAGE_FULL_CAR_FRAGMENT}
`;

export const GET_GARAGE_CAR = gql`
  query GetGarageCar($garageCarId: ID!) {
    garageCar(id: $garageCarId) {
      ...GARAGE_CAR_FRAGMENT
      ...GARAGE_FULL_CAR_FRAGMENT
    }
  }
  ${GARAGE_CAR_FRAGMENT}
  ${GARAGE_FULL_CAR_FRAGMENT}
`;

export const CREATE_GARAGE_CAR = gql`
  mutation CreateGarageCar($input: CreateGarageCarInput!) {
    createGarageCar(input: $input) {
      garageCar {
        ...GARAGE_CAR_FRAGMENT
      }
    }
  }
  ${GARAGE_CAR_FRAGMENT}
`;

export const UPDATE_GARAGE_CAR = gql`
  mutation UpdateGarageCar($updateGarageCarId: ID!, $input: UpdateGarageCarInput!) {
    updateGarageCar(id: $updateGarageCarId, input: $input) {
      garageCar {
        ...GARAGE_CAR_FRAGMENT
        ...GARAGE_FULL_CAR_FRAGMENT
      }
    }
  }
  ${GARAGE_CAR_FRAGMENT}
  ${GARAGE_FULL_CAR_FRAGMENT}
`;

export const DELETE_GARAGE_CAR = gql`
  mutation DeleteGarageCar($deleteGarageCarId: ID!) {
    deleteGarageCar(id: $deleteGarageCarId) {
      id
    }
  }
`;

export const GET_CAR_MODIFICATION_FULL = gql`
  query CarModification($carModificationId: ID!) {
    carModification(id: $carModificationId) {
      id
      name
      powerPs
      productionEnd
      productionStart
      engineCapacity
      engineCode
      valves
      capacityL
      
      model {
        brand {
          id
          name
        }
        id
        name
      }
      bodyType {
        id
        name
      }
      driveType {
        id
        name
      }
      engineType {
        id
        name
      }
      fuelType {
        id
        name
      }
      gearType {
        id
        name
      }
      suspensionType {
        id
        name
      }
    }
  }
`;
