import $notify from 'element-ui/lib/notification';
import isEmpty from 'lodash/isEmpty';
import { i18n } from './i18n';

const t = key => i18n.t(`labels.${key}`);

const defaultOptions = {
  duration: 20000,
  offset: 0
};

const visibleErrors = {};

const isValidMes = mes => ['string', 'number'].includes(typeof mes);

let _result = '';
let _status = '';
let _message = '';

function getClear (message) {
  return message
    .replace(/null/, t('empty'))
    .replace('Item price changed', t('price_changed'))
    .replace('Item quantity changed', t('quantity_changed'))
    .replace('Item not found', t('not_found'))
    .trim();
}

function printError (message, { type, title } = {}) {
  if (!isValidMes(message)) {
    console.error('INVALID MESSAGE', message);
    return null;
  }

  const _titleKey = _status ? `errors.${_status}.title` : '';
  let _title = _titleKey ? i18n.t(_titleKey) : '';
  _title = _title === _titleKey
    ? i18n.t('errors.default.title')
    : _title;

  _message = getClear(message);

  const notification = {
    ...defaultOptions,
    type: type || 'error',
    title: title || _title,
    message: _message,
    dangerouslyUseHTMLString: true,
    onClose: () => { delete visibleErrors[message]; }
  };

  if (visibleErrors[message]) {
    return null;
  } else {
    setTimeout(() => {
      visibleErrors[message] = $notify(notification);
    });
  }
  _result = '';
}

function mapArray (arr) {
  arr.forEach(useByType);
}

function mapObject (obj) {
  _result = _result.trim();

  if (obj.basket) {
    const options = {
      type: 'warning',
      title: i18n.t('errors.default.warning')
    };

    printError(t('reloadPageByTermsChange'), options);

    return null;
  }

  if (obj.message) {
    printError(`${_result}: ${obj.message}`);
  } else if (isEmpty(obj)) {
    printError(`${_result.trim()}: ${i18n.t('errors.default.message')}`);
  } else {
    Object.entries(obj).forEach(([key, value]) => {
      _result = `${_result} <strong>${t(key)}</strong> `;
      useByType(value);
    });
  }
}

function useByType (error) {
  if ((!error || isEmpty(error)) && _status) {
    const _title = `${i18n.t(`errors.${_status}.title`)} ${_status}`;
    const _message = i18n.t(`errors.${_status}.message`);
    printError(_message, { title: _title });
  } else if (Array.isArray(error)) {
    mapArray(error);
  } else if (typeof error === 'object') {
    mapObject(error);
  } else if (typeof error === 'string') {
    printError(`${_result} ${error}`);
  } else {
    console.info('UNKNOWN ERROR TYPE', error);
  }
}

export default function (error, { status } = {}) {
  _status = status || '';
  _result = '';
  _message = '';

  useByType(error);

  return _message;
};
