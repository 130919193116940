var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-items" },
    [
      _vm.value.column
        ? _c(
            "ColItemSelect",
            _vm._g(
              {
                attrs: {
                  config: _vm.configTypes,
                  dependency: _vm.dependency,
                  "origin-val": _vm.value,
                },
                model: {
                  value: _vm.selectData,
                  callback: function ($$v) {
                    _vm.selectData = $$v
                  },
                  expression: "selectData",
                },
              },
              _vm.listeners
            )
          )
        : _vm.value._isAction
        ? _c(
            "ColItemSelect",
            _vm._g(
              {
                attrs: {
                  config: _vm.config.price_actions,
                  dependency: _vm.dependency,
                  "origin-val": _vm.value,
                },
                model: {
                  value: _vm.priceActionData,
                  callback: function ($$v) {
                    _vm.priceActionData = $$v
                  },
                  expression: "priceActionData",
                },
              },
              _vm.listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }