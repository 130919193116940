var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "cars-brands-models" }, [
    _vm.title
      ? _c("div", { staticClass: "pg-0-1 mb-2" }, [
          _c(
            "span",
            { staticClass: "custom-headline custom-headline--level2" },
            [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.value, function (carBrand) {
        return _c(
          "li",
          { key: carBrand.id, staticClass: "block mb-1" },
          [
            _c("CarsBrandsModelsItem", {
              attrs: {
                "get-link": _vm.getLink,
                dependency: _vm.dependency,
                value: carBrand,
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }