import LRU from 'lru-cache';
import md5 from 'md5';
import { env } from '@/lib/core';

const options = {
  max: env.DATA_CACHE_MAX,
  maxAge: env.DATA_CACHE_TIME
};

const cache = new LRU(options);

export const actions = {
  async get ({ dispatch, rootGetters }, { action, payload = {} } = {}, force) {
    const lang = rootGetters.langCode;
    const city = await dispatch('getCityId', null, { root: true });
    const user = rootGetters.userCode;
    const key = md5(`${action}_${user}_${city}_${lang}:${JSON.stringify(payload)}`);

    if (cache.has(key) && !force) {
      return Promise.resolve(cache.get(key));
    }

    const result = await dispatch(action, payload, { root: true });

    cache.set(key, result);

    return Promise.resolve(result);
  },

  reset () {
    cache.reset();
  }
};
