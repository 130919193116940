var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ware-info-kits" }, [
    _vm.ware.kits_part_of.length
      ? _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("div", { staticClass: "mb-1" }, [
              _vm._v(
                _vm._s(_vm.$t("search.formKits", { name: _vm.wareTitle })) + ":"
              ),
            ]),
            _vm._v(" "),
            _c("WaresSmallTable", {
              attrs: { value: _vm.ware.kits_part_of, "row-key": "id" },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.ware.kits_includes.length
      ? _c(
          "div",
          [
            _c("div", { staticClass: "mb-1" }, [
              _vm._v(
                _vm._s(_vm.$t("search.toKits", { name: _vm.wareTitle })) + ":"
              ),
            ]),
            _vm._v(" "),
            _c("WaresSmallTable", {
              attrs: { value: _vm.ware.kits_includes, "row-key": "id" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }