var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sale-point-trademarks-tab" },
    [
      _c("div", { staticClass: "description mb-1" }, [
        _c("p", [
          _vm._v(
            _vm._s(_vm.$t("sellerTrademarks.sellerTrademarksDescription"))
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.$t("sellerTrademarks.sellerTrademarksConfirmDescription")
            )
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb-2", attrs: { justify: "end" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  _vm.isDialog = true
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("buttons.addTrademark")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SellerTrademarkTable", {
        attrs: { load: _vm.loadTrademarks, "prop-value": _vm.tableData },
        on: { "row-click": _vm.showTrademark },
      }),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: {
            title: _vm.$t(
              "sellerTrademarks." + (_vm.trademark ? "edit" : "create") + "Tm"
            ),
          },
          model: {
            value: _vm.isDialog,
            callback: function ($$v) {
              _vm.isDialog = $$v
            },
            expression: "isDialog",
          },
        },
        [
          _c("SellerTrademarkForm", {
            attrs: { value: _vm.trademark },
            on: {
              cancel: _vm.hidePopup,
              created: _vm.addItemToList,
              removed: _vm.removeItem,
              updated: _vm.updateElem,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }