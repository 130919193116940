var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "seller-trademark-form",
      attrs: {
        "label-position": "left",
        "label-width": "280px",
        model: _vm.formData,
        rules: _vm.rules,
        size: "mini",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("sellerTrademarks.trademark_name"),
            prop: "trademark_name",
          },
        },
        [
          _c("el-input", {
            model: {
              value: _vm.formData.trademark_name,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "trademark_name", $$v)
              },
              expression: "formData.trademark_name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "description" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("sellerTrademarks.verificationUrlDescription")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("sellerTrademarks.verification_url"),
            prop: "verification_url",
          },
        },
        [
          _c("el-input", {
            model: {
              value: _vm.formData.verification_url,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "verification_url", $$v)
              },
              expression: "formData.verification_url",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "text-ghost mb-2", attrs: { justify: "center" } },
        [_vm._v("\n    " + _vm._s(_vm.$t("sellerTrademarks.orAnd")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { prop: "verification_document" } },
        [
          _c("DocUploader", {
            attrs: { label: _vm.$t("buttons.addDoc") },
            model: {
              value: _vm.formData.verification_document,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "verification_document", $$v)
              },
              expression: "formData.verification_document",
            },
          }),
          _vm._v(" "),
          _vm.docName
            ? _c(
                "el-row",
                { attrs: { align: "middle", justify: "space-between" } },
                [
                  _c(
                    "el-link",
                    { attrs: { href: _vm.formData.verification_document } },
                    [_vm._v(_vm._s(_vm.docName))]
                  ),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { circle: "", icon: "el-icon-delete" },
                    on: {
                      click: function ($event) {
                        _vm.formData.verification_document = null
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "description" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("sellerTrademarks.verificationDocumentDescription")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mt-2", attrs: { justify: "end" } },
        [
          _vm.formData.id
            ? _c(
                "el-button",
                {
                  attrs: { disabled: _vm.isLoading, type: "danger" },
                  on: { click: _vm.remove },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("buttons.remove")) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.isLoading },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.isEqual,
                loading: _vm.isLoading,
                "native-type": "submit",
                type: "primary",
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.save")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }