<template>
  <div
    :class="['main-info', {
      'highlight-required': dependency.mode === 'create'
    }]"
  >
    <el-row class="fl-wrap mb-2">
      <el-form-item class="mr-2" :label="$t('priceFile.name')" prop="name">
        <el-input
          class="w-350p"
          :value="value.name"
          @input="emit('name', $event)"
        />
      </el-form-item>

      <el-form-item
        class="no-wrap-error mr-4"
        :label="$t('priceFile.currency')"
        prop="currency"
      >
        <CurrencySelector
          :value="value.currency"
          @input="emit('currency', $event)"
        />
      </el-form-item>

      <div class="checkboxes mr-2">
        <el-form-item
          class="mb-0"
          label-width="0"
          prop="quantity_warehouse_is_own"
        >
          <el-checkbox
            :label="$t('priceFile.quantity_warehouse_is_own')"
            :value="value.quantity_warehouse_is_own"
            @change="emit('quantity_warehouse_is_own', $event)"
          />
        </el-form-item>

        <el-form-item
          class="mb-0"
          label-width="0"
          prop="other_selling_terms"
        >
          <el-checkbox-group
            class="cell"
            :value="value.other_selling_terms"
            @input="emit('other_selling_terms', $event)"
          >
            <el-checkbox
              v-for="key in otherSellingTerms"
              :key="key"
              :label="key"
              :value="key"
            >
              {{ $t(`other_selling_terms.${key}`) }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-row>

    <div class="mb-2">
      <el-form-item class="mb-0" label-width="0" prop="payment_terms">
        <el-checkbox
          :label="$t('priceFile.fullPrepaymentForPrice')"
          :value="(value.payment_terms || []).includes('full_prepayment')"
          @change="updateList(
            'payment_terms',
            'full_prepayment',
            $event
          )"
        />
      </el-form-item>

      <div>
        <div class="mb-1 text-ghost">
          {{ $t('priceFile.payment_terms_text') }}
        </div>
        <el-form-item label-width="0" prop="payment_terms_text">
          <el-input
            type="textarea"
            :disabled="!isIn('payment_terms', 'full_prepayment')"
            :value="value.payment_terms_text"
            @input="emit('payment_terms_text', $event)"
          />
        </el-form-item>
      </div>
    </div>

    <div class="mb-2">
      <el-row>
        <el-form-item
          class="mb-0 mr-2"
          label-width="0"
          prop="other_selling_terms"
        >
          <el-checkbox
            :label="$t('labels.is_warranted')"
            :value="(value.other_selling_terms || []).includes('warranted')"
            @change="updateList(
              'other_selling_terms',
              'warranted',
              $event
            )"
          />
        </el-form-item>

        <el-form-item class="mb-0" label-width="0" prop="other_selling_terms">
          <el-checkbox
            :label="$t('other_selling_terms.return_possible')"
            :value="(value.other_selling_terms || []).includes('return_possible')"
            @change="updateList(
              'other_selling_terms',
              'return_possible',
              $event
            )"
          />
        </el-form-item>
      </el-row>

      <div>
        <div class="mb-1 text-ghost">
          {{ $t('priceFile.warrantedReturnTermsText') }}
        </div>

        <el-form-item label-width="0" prop="warranty_terms_text">
          <el-input
            type="textarea"
            :disabled="!isIn(
              'other_selling_terms',
              ['warranted', 'return_possible']
            )"
            :value="value.warranty_terms_text"
            @input="emit('warranty_terms_text', $event)"
          />
        </el-form-item>
      </div>
    </div>

    <div>
      <el-form-item
        v-if="dependency.mode !== 'create'"
        label-width="0"
        prop="description"
      >
        <el-input
          :autosize="{ minRows: 4, maxRows: 20}"
          :placeholder="$t('priceFile.description')"
          type="textarea"
          :value="value.description"
          @input="emit('description', $event)"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
import without from 'lodash/without';
import CurrencySelector from '@/lib/core/components/CurrencySelector';

const disabledOtherTerms = ['warranted', 'return_possible'];

export default {
  name: 'MainInfo',

  components: {
    CurrencySelector
  },

  props: {
    readonly: Boolean,
    dependency: { type: Object, default: () => ({}) },
    value: { type: Object, required: true }
  },

  computed: {
    otherSellingTerms () {
      return this.$store.getters['dependencies/otherSellingTerms']
        .reduce((acc, { in_price_file, label }) => {
          if (
            in_price_file &&
            !disabledOtherTerms.includes(label)
          ) {
            acc.push(label);
          }
          return acc;
        }, []);
    }
  },

  methods: {
    emit (name, value) {
      this.$emit(`update:${name}`, value);
    },

    updateList (listName, key, value) {
      const _list = (this.value[listName] || []);

      const list = value
        ? [..._list, key]
        : without(_list, key);

      this.emit(listName, list);
    },

    isIn (listName, fields) {
      const list = (this.value[listName] || []);

      return typeof fields === 'string'
        ? list.includes(fields)
        : fields.some(key => list.includes(key));
    }
  }
};
</script>
