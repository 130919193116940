var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "price-scheme-form",
      attrs: {
        "label-position": "left",
        "label-width": "230px",
        model: _vm.formData,
        rules: _vm.rules,
        size: "mini",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("priceScheme.name") } },
        [
          _c(
            "el-select",
            {
              staticClass: "name",
              attrs: { "value-key": "url" },
              on: { change: _vm.setScheme },
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            },
            [
              _vm._l(_vm.schemes, function (scheme) {
                return _c("el-option", {
                  key: scheme.url,
                  attrs: { label: scheme.name, value: scheme },
                })
              }),
              _vm._v(" "),
              _c("el-option", {
                attrs: {
                  label: _vm.$t("buttons.newPriceScheme"),
                  value: _vm.defaultScheme,
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("priceScheme.name"), prop: "name" } },
        [
          _c("el-input", {
            attrs: { disabled: _vm.isExisted },
            model: {
              value: _vm.formData.name,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "scheme-type",
              attrs: {
                label: _vm.$t("priceScheme.selectPriceCreationScheme"),
                "label-width": "300px",
                prop: "scheme_type",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.isExisted },
                  model: {
                    value: _vm.formData.scheme_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "scheme_type", $$v)
                    },
                    expression: "formData.scheme_type",
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.schemeTypes[0] },
                      nativeOn: {
                        click: function ($event) {
                          _vm.formData.scheme_type = "static"
                        },
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "w-100", attrs: { align: "middle" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "priceScheme.types." + _vm.schemeTypes[0]
                                )
                              ) +
                              "\n\n            "
                          ),
                          _vm.formData.scheme_type === _vm.schemeTypes[0]
                            ? [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "mb-0",
                                    attrs: { "label-width": "0" },
                                  },
                                  [
                                    _c("NumInput", {
                                      attrs: {
                                        field: "static_coefficient",
                                        "hide-label": "",
                                        readonly: _vm.isExisted,
                                        "validation-prop": "static_coefficient",
                                      },
                                      model: {
                                        value: _vm.formData.static_coefficient,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "static_coefficient",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.static_coefficient",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        label: _vm.schemeTypes[1],
                        "label-width": "320px",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("priceScheme.types." + _vm.schemeTypes[1])
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.formData.scheme_type === _vm.schemeTypes[1]
        ? _c("div", { staticClass: "block ml-1" }, [
            _c("div", { staticClass: "block-label" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("priceScheme.dynamicPriceSchemeParams")) +
                  ":\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("priceScheme.currency"),
                      prop: "currency",
                    },
                  },
                  [
                    _c("CurrencySelector", {
                      attrs: { readonly: _vm.isExisted },
                      model: {
                        value: _vm.formData.currency,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "currency", $$v)
                        },
                        expression: "formData.currency",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticClass: "dynamic-params", attrs: { gutter: 40 } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("priceScheme.min_price", {
                                name: _vm.currencyName,
                              }),
                              "label-width": "220px",
                              prop: "min_price",
                            },
                          },
                          [
                            _c("NumInput", {
                              attrs: {
                                field: "min_price",
                                "hide-label": "",
                                readonly: _vm.isExisted,
                              },
                              model: {
                                value: _vm.formData.min_price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "min_price", $$v)
                                },
                                expression: "formData.min_price",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("priceScheme.max_price", {
                                name: _vm.currencyName,
                              }),
                              "label-width": "220px",
                              prop: "max_price",
                            },
                          },
                          [
                            _c("NumInput", {
                              attrs: {
                                field: "max_price",
                                "hide-label": "",
                                readonly: _vm.isExisted,
                              },
                              model: {
                                value: _vm.formData.max_price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "max_price", $$v)
                                },
                                expression: "formData.max_price",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("priceScheme.max_markup"),
                              "label-width": "220px",
                              prop: "max_markup",
                            },
                          },
                          [
                            _c("NumInput", {
                              attrs: {
                                field: "max_markup",
                                "hide-label": "",
                                readonly: _vm.isExisted,
                              },
                              model: {
                                value: _vm.formData.max_markup,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "max_markup", $$v)
                                },
                                expression: "formData.max_markup",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("priceScheme.min_markup"),
                              "label-width": "220px",
                              prop: "min_markup",
                            },
                          },
                          [
                            _c("NumInput", {
                              attrs: {
                                field: "min_markup",
                                "hide-label": "",
                                readonly: _vm.isExisted,
                              },
                              model: {
                                value: _vm.formData.min_markup,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "min_markup", $$v)
                                },
                                expression: "formData.min_markup",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { justify: "end" } },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.loading, size: "large" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                "native-type": "submit",
                size: "large",
                type: "primary",
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.create")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }