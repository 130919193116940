<template>
  <div class="seller-orders-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_seller_orders') }}
    </h1>

    <SellerOrdersTable
      use-page
      :load="loadOrderItems"
      :prop-count="count"
      :prop-value="items"
      @item:update="updateItem"
    />
  </div>
</template>

<script>
import get from 'lodash/get';
import ordersPageMixin from './mixins/ordersPageMixin';
import SellerOrdersTable from './components/SellerOrdersTable';
import { parsers, filters, useAsyncDebounce, env } from '@/lib/core';
import getConcatedAddress from '@/lib/salePoints/services/getConcatedAddress';

export default {
  name: 'SellerOrdersPage',

  mixins: [ordersPageMixin],

  components: {
    SellerOrdersTable
  },

  computed: {
    sellerId () {
      return this.$store.getters['businessProfiles/sellerId'];
    }
  },

  methods: {
    getParsed (item) {
      const _deliveryCity = getConcatedAddress(
        item.order_detail.delivery_address,
        { hideCountry: true, useAdmin: true }
      );

      return {
        id: item.id,
        article: item.article,
        display_trademark: item.display_trademark,
        display_article: item.display_article,
        display_title: item.display_title,
        delivery_time: item.delivery_time,
        quantity: item.quantity,
        status: item.status,
        order_detail: item.order_detail,
        shipment_detail: item.shipment_detail,
        is_paid: item.is_paid,
        selection_request_detail: item.selection_request_detail,
        _waybillDate: filters.getDate(get(item, 'shipment_detail.waybill_date')),
        _publicId: get(item, 'order_detail.public_id', ''),
        _displayPublicId: get(item, 'order_detail.display_public_id', ''),
        _price: parsers.getPriceNum(item.price),
        _priceSum: parsers.getPriceNum(item.price * item.quantity),
        _orderId: parsers.getIdFromUrl(item.order),
        _pointId: get(item, 'order_detail.point_of_sale_detail.id', ''),
        _pointName: get(item, 'order_detail.point_of_sale_detail.name', ''),
        _pointUrl: get(item, 'order_detail.point_of_sale_detail.url', ''),
        _created: filters.getDateTime(item.order_detail.created),
        _buyerId: parsers.getIdFromUrl(item.order_detail.buyer),
        _buyerUrl: item.order_detail.buyer,
        _buyerName: get(item, 'order_detail.buyer_detail.profile_name', ''),
        _buyerData: this.getBuyerData(item),
        _buyerPhone: get(item, 'order_detail.buyer_phone_number.phone_number', ''),
        _buyerEmail: get(item, 'order_detail.buyer_email.email', ''),
        _recipientPhone: get(item, 'order_detail.recipient_phone_number.phone_number', ''),
        _deliveryCity,
        _deliveryCityHtml: _deliveryCity.replace(', ', '<br>'),
        _deliveryDate: filters.getDate(item.delivery_date),
        _priceFileName: get(item, 'price_file_detail.name', '-'),
        _priceFileId: get(item, 'price_file_detail.id'),
        _canRevertStatus: false,
        _revertStatusTimer: useAsyncDebounce({ debounce: env.STATUS_REVERT_DEBOUNCE })
      };
    },

    getBuyerData (item) {
      const {
        buyer_last_name,
        buyer_first_name,
        buyer_phone_number
      } = item.order_detail || {};

      const phone = buyer_phone_number?.phone_number || '';
      const lName = buyer_last_name ? `, ${buyer_last_name}` : '';
      const fName = buyer_first_name ? `, ${buyer_first_name}` : '';

      return `${phone}${lName}${fName}`;
    }
  }
};
</script>
