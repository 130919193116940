<template>
  <div class="ware-info-kits">
    <div v-if="ware.kits_part_of.length" class="mb-2">
      <div class="mb-1">{{ $t('search.formKits', { name: wareTitle }) }}:</div>
      <WaresSmallTable :value="ware.kits_part_of" row-key="id" />
    </div>

    <div v-if="ware.kits_includes.length">
      <div class="mb-1">{{ $t('search.toKits', { name: wareTitle }) }}:</div>
      <WaresSmallTable :value="ware.kits_includes" row-key="id" />
    </div>
  </div>
</template>

<script>
import WaresSmallTable from '../WaresSmallTable';

export default {
  name: 'WareInfoKits',

  components: {
    WaresSmallTable
  },

  props: {
    wareTitle: { type: String, default: '' },
    ware: { type: Object, required: true }
  }
};
</script>
