var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("el-switch", {
        attrs: {
          disabled: _vm.disabled || _vm.value === null,
          value: _vm.value,
        },
        on: { change: _vm.changeStatus },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }