<template>
  <div class="num-input">
    <div v-if="!hideLabel" class="label mb-1">{{ label || $t(`${scope}.${field}`) }}</div>

    <el-form-item label-width="0" :prop="validationProp">
      <el-input
        v-if="!readonly"
        v-model="val"
        size="mini"
      />
      <el-input v-else disabled size="mini" :value="float" />
    </el-form-item>
  </div>
</template>

<script>
/**
   * USAGE in parent
   *
   * import NumInput from './NumInput'
   * NumInput.data = () => ({ scope: 'priceScheme' }); - set scope !!!
   *
   * export default {
   *   components: { NumInput }
   * }
   *
   * <NumInput
   *   hideLabel
   *   field="min_markup"
   *   :readonly="readonly"
   *   :value="formData"
   *   v-bind.sync="formData"
   * />
   */
import { env } from '@/lib/core';

export default {
  name: 'NumInput',

  props: {
    readonly: Boolean,
    hideLabel: Boolean,
    label: String,
    validationProp: { type: String, default: '' },
    field: { type: String, required: true },
    value: { type: [String, Number], required: true },
    toFixed: { type: Number, default: 2 }
  },

  data: () => ({
    scope: ''
  }),

  computed: {
    val: {
      get () {
        return String(this.value).replace('.', env.PRICE_SPLITTER);
      },
      set (val) {
        this.$emit('input', val.replace(env.PRICE_SPLITTER, '.'));
      }
    },

    float () {
      return this.value.toFixed(this.toFixed).replace(/\./g, env.PRICE_SPLITTER);
    }
  }
};
</script>

<style scoped lang="scss">
.num-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  justify-content: space-between;

  .label {
    line-height: 1.2rem;
    color: $--color-text-regular;
  }

  .el-form-item {
    margin-bottom: 0;
    width: 100px;
  }

  .el-input {
    width: 100%;

    ::v-deep .el-input__inner {
      padding: 0;
      text-align: center;
    }
  }
}
</style>
