<template>
  <div class="order-recipient">
    <el-form-item
      class="mr-1 relative"
      prop="recipient_last_name"
      :label="$t('basket.recipient_last_name')"
    >
      <el-input
        clearable
        :value="value.recipient_last_name"
        @input="onInput"
        @blur="clearResult"
      />

      <div
        v-if="searchResult.length"
        class="custom-autocomplete back-shadow scroll"
      >
        <div
          v-for="item in searchResult"
          :key="item.id"
          class="item"
          @click.stop="setRecipient(item)"
        >
          {{ item._label }}
        </div>
      </div>
    </el-form-item>

    <el-form-item
      class="mr-1"
      prop="recipient_first_name"
      :label="$t('basket.recipient_first_name')"
    >
      <el-input
        :value="value.recipient_first_name"
        @input="setField('recipient_first_name', $event)"
      />
    </el-form-item>

    <el-form-item
      class="mr-1"
      prop="recipient_phone_number"
      :label="$t('basket.recipient_phone_number')"
    >
      <PhoneInput
        :value="value.recipient_phone_number.phone_number"
        @input="setField('recipient_phone_number.phone_number', $event);"
      />
    </el-form-item>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { env } from '@/lib/core';
import orderFormMixin from '@/lib/orders/mixins/orderFormMixin';
import PhoneInput from '@/lib/core/components/PhoneInput';

export default {
  name: 'OrderRecipient',

  mixins: [orderFormMixin],

  components: {
    PhoneInput
  },

  props: {
    dependency: { type: Object, required: true },
    setField: { type: Function, required: true },
    value: { type: Object, required: true },
    recipients: { type: Array, default: () => [] }
  },

  data: () => ({
    searchResult: []
  }),

  created () {
    this.searchBy = debounce(this.searchBy, env.DEBOUNCE);
  },

  methods: {
    onInput (value) {
      this.setField('recipient_last_name', value);

      if (this.$store.getters['auth/isAuthenticated']) {
        this.searchBy(value);
      }
    },

    searchBy (value) {
      if (!value || value.length < 2 || !this.recipients.length) {
        this.searchResult = [];
        return this.searchResult;
      }

      const reg = new RegExp(value.toLowerCase());

      this.searchResult = this.recipients
        .filter(({ _label }) => reg.test(_label.toLowerCase()));
    },

    setRecipient (recipient) {
      this.setField('recipient_first_name', recipient.first_name);
      this.setField('recipient_last_name', recipient.last_name);
      this.setField('recipient_phone_number', recipient.phone_number);

      this.searchResult = [];
    },

    clearResult () {
      setTimeout(() => { this.searchResult = []; }, 1000);
    }
  }
};
</script>

<style scoped lang="scss">
.order-recipient {
  display: flex;
  flex-wrap: wrap;
}
</style>
