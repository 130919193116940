<template>
  <div class="ware-life-cycle-help">
    <img
      class="icon-img c-pointer"
      src="@/assets/icons/help-circle-outline.svg"
      @click="showInfo = !showInfo"
    >

    <el-dialog
      append-to-body
      center
      close-on-click-modal
      :title="$t('orderCycleHelp.title')"
      :visible.sync="showInfo"
      width="80vw"
    >
      <el-row class="mb-4" :gutter="10">
        <el-col :span="3">
          <div class="item">
            <i class="icon el-icon-shopping-cart-full" />
            <div class="item-title">{{ $t('orderStatuses.null') }}</div>
          </div>

          <div class="arrow">&#8595;</div>

          <div class="item">
            <img class="icon" src="@/assets/icons/emoticon-sad-outline.svg">
            <div class="item-title">{{ $t('orderStatuses.buyer_canceled') }}</div>
          </div>
        </el-col>

        <el-col>
          <hr class="left">
        </el-col>

        <el-col :span="6">
          <div class="item">
            <i class="icon el-icon-document-copy" />
            <div class="item-title">{{ $t('orderStatuses.new') }}</div>
          </div>

          <el-row>
            <div class="arrow to-left">&#8595;</div>
            <div class="arrow to-right">&#8595;</div>
          </el-row>

          <el-row>
            <div class="item mr-2">
              <img class="icon" src="@/assets/icons/emoticon-sad-outline.svg">
              <div class="item-title">{{ $t('orderStatuses.buyer_canceled') }}</div>
            </div>

            <div class="item">
              <i class="icon el-icon-circle-close" />
              <div class="item-title">{{ $t('orderStatuses.seller_canceled') }}</div>
            </div>
          </el-row>
        </el-col>

        <el-col>
          <hr class="center">
        </el-col>

        <el-col :span="6">
          <div class="item">
            <i class="icon el-icon-box" />
            <div class="item-title">{{ $t('orderStatuses.in_progress') }}</div>
          </div>

          <el-row>
            <div class="arrow to-left">&#8595;</div>
            <div class="arrow to-right">&#8595;</div>
          </el-row>

          <el-row>
            <div class="item mr-2">
              <img class="icon" src="@/assets/icons/emoticon-sad-outline.svg">
              <div class="item-title">{{ $t('orderStatuses.buyer_canceled') }}</div>
            </div>

            <div class="item">
              <i class="icon el-icon-circle-close" />
              <div class="item-title">{{ $t('orderStatuses.seller_canceled') }}</div>
            </div>
          </el-row>
        </el-col>

        <el-col>
          <hr class="center">
        </el-col>

        <el-col :span="6">
          <div class="item">
            <i class="icon el-icon-goods" />
            <div class="item-title">{{ $t('orderStatuses.ready') }}</div>
          </div>

          <el-row>
            <div class="arrow to-left">&#8595;</div>
            <div class="arrow to-right">&#8595;</div>
          </el-row>

          <el-row>
            <div class="item mr-2">
              <img class="icon" src="@/assets/icons/emoticon-sad-outline.svg">
              <div class="item-title">{{ $t('orderStatuses.buyer_canceled') }}</div>
            </div>

            <div class="item">
              <i class="icon el-icon-circle-close" />
              <div class="item-title">{{ $t('orderStatuses.seller_canceled') }}</div>
            </div>
          </el-row>
        </el-col>

        <el-col>
          <hr class="right">
        </el-col>

        <el-col :span="3">
          <div class="item">
            <i class="icon el-icon-check" />
            <div class="item-title">{{ $t('orderStatuses.issued') }}</div>
          </div>
        </el-col>
      </el-row>

      <div class="advices">
        <span class="color-red">{{ $t('basket.important') }}!</span>
        <span>{{ $t('orderCycleHelp.description') }}:</span>

        <ul class="list mt-1">
          <!--eslint-disable-next-line-->
          <li class="list-item" v-html="$t('orderCycleHelp.forBuyer')" />
          <!--eslint-disable-next-line-->
          <li class="list-item" v-html="$t('orderCycleHelp.forSeller')" />
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'StatusCycleHelp',

  data: () => ({
    showInfo: false
  })
};
</script>

<style scoped lang="scss">
.el-dialog {
  .el-col {
    position: relative;
  }

  hr {
    position: absolute;

    &.center {
      width: calc(100% + 100px);
      left: -50px;
      right: 50px;
    }

    &.left {
      width: calc(100% + 50px);
      left: 0;
      right: 50px;
    }

    &.right {
      width: calc(100% + 50px);
      left: -50px;
      right: 0;
    }
  }

  .item {
    margin: 0 auto;
    width: 90px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon {
      font-size: 25px;
      margin-bottom: 10px;
    }

    &-title {
      word-wrap: break-word;
    }
  }

  .arrow {
    display: block;
    width: 100%;
    font-size: 5rem;
    text-align: center;
    opacity: .4;

    &.to-left {
      position: relative;
      left: 20px;
      transform: rotate(20deg);
    }

    &.to-right {
      position: relative;
      left: -20px;
      transform: rotate(-20deg);
    }
  }

  .advices {
    padding: 0 40px;
  }

  .list {
    padding: 0 40px;

    &-item {
      margin-bottom: 4px;
      list-style-type: disc;
      padding: 0 4px;
    }
  }
}
</style>
