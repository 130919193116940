<template>
  <div v-if="ware" class="ware-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ wareTitle }}
    </h1>

    <AnchorsNav :class="{ 'mb-2': !isMobile }" entity="ware" :value="anchors" />

    <div
      id="mainInfo"
      :class="['mr-block main-info', isMobile ? 'mb-2' : 'mb-4']"
    >
      <div class="left">
        <WarePreviewList
          class="photo"
          :alt="imgAlt"
          :title="wareTitle"
          :ware="ware"
        />
      </div>

      <div class="right">
        <div class="mb-1">
          <el-link type="primary" @click="$router.push(searchLink)">
            {{ $t("ware.showAllProposition") }}
          </el-link>
        </div>

        <WareMainInfo
          :ware="ware"
          :is-loading="isSubmitOrderLoading"
          @basket-click="submitOrder"
          @sale-point:info="setSalePointInfo"
        />
      </div>
    </div>

    <div>
      <WareArticlesTables
        v-if="!isEmpty(analogs)"
        :class="['cv', isMobile ? 'mb-2' : 'mb-4']"
        :title="wareTitle"
        :wares="analogs"
      />

      <div class="w-9">
        <WareMainParams
          id="characteristics"
          :class="['cv', isMobile ? 'mb-1' : 'mb-2']"
          :ware="ware"
        />

        <WareArticleReplacement
          v-if="get(ware, 'replacements_history.length')"
          :class="['cv', isMobile ? 'mb-1' : 'mb-2']"
          :value="ware"
        />

        <WareKits
          v-if="ware.has_kits"
          id="kits"
          :class="['cv', isMobile ? 'mb-1' : 'mb-2']"
          :ware="ware"
        />
      </div>

      <WareCarApplicabilitySelector
        v-if="!isEmpty(ware.car_brands)"
        id="applicability"
        :class="['cv', isMobile ? 'mb-1' : 'mb-2']"
        :value="ware.car_brands"
      />

      <WareDescription
        v-if="meta.text"
        id="description"
        :text="meta.text"
        :class="['cv', isMobile ? 'mb-2' : 'mb-4']"
      />

      <component
        :is="`WareCarApplicability${isMobile ? 'M' : ''}`"
        v-if="!isEmpty(ware.car_brands)"
        :line-id="get(ware, 'product_line.slug')"
        :value="ware.car_brands"
        :class="['cv', isMobile ? 'mb-2' : 'mb-4']"
      />

      <template v-if="$store.getters.isAppLoaded">
        <TheDialog v-model="checkPoints" width="1400px">
          <WareCheckPoints
            v-if="checkPoints"
            :ware="checkPoints.ware"
            :points="checkPoints.otherPoints"
            @select="
              checkPoints = null;
              salePointInfo = $event;
            "
          />
        </TheDialog>

        <TheDialog v-show="!checkPoints" v-model="salePointInfo" width="800px">
          <template v-if="salePointInfo" #title>
            <div class="custom-headline custom-headline__dialog">
              <span class="bold">
                {{ get(salePointInfo, "ware.ware.trademark.name", "") }}
                {{ get(salePointInfo, "ware.ware.article", "") }},
              </span>
              <span>{{ get(salePointInfo, "ware.ware.name", "") }}</span>
            </div>
          </template>

          <template #default>
            <WareSalePointInfo
              v-if="salePointInfo"
              :id="salePointInfo.id"
              v-loading="isSubmitOrderLoading"
              :ware="salePointInfo.ware"
              affiliation-list-name="Product Page"
              @check-points="checkPoints = $event"
              @basket-click="submitOrder"
            />
          </template>
        </TheDialog>
      </template>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import WarePreviewList from './components/ware/WarePreviewList';
import WareMainInfo from './components/ware/WareMainInfo';
import seoHeadMixin from './mixins/seoHeadMixin';
import getSeoProductMeta from './services/getSeoProductMeta';
import getWareBreadcrumbs from './services/getWareBreadcrumbs';
import AnchorsNav from '@/lib/core/components/AnchorsNav';
import addWareToBasket from '@/lib/orders/services/addWareToBasket';
import { getParsedWare } from '@/lib/search/services/getParsedWares';
import parsers from '@/lib/core/services/parsers';

export default {
  name: 'WarePage',

  mixins: [seoHeadMixin],

  components: {
    /* eslint-disable max-len */
    AnchorsNav,
    WarePreviewList,
    WareMainInfo,
    WareMainParams: () =>
      import(
        /* webpackChunkName: "WareMainParams" */ './components/ware/WareMainParams'
      ),
    WareArticleReplacement: () =>
      import(
        /* webpackChunkName: "WareArticleReplacement" */ './components/ware/WareArticleReplacement'
      ),
    WareArticlesTables: () =>
      import(
        /* webpackChunkName: "WareArticlesTables" */ './components/ware/WareArticlesTables'
      ),
    WareKits: () =>
      import(/* webpackChunkName: "WareKits" */ './components/ware/WareKits'),
    WareCarApplicabilitySelector: () =>
      import(
        /* webpackChunkName: "WareCarApplicabilitySelector" */ './components/ware/WareCarApplicabilitySelector'
      ),
    WareDescription: () =>
      import(
        /* webpackChunkName: "WareDescription" */ './components/ware/WareDescription'
      ),
    WareCarApplicability: () =>
      import(
        /* webpackChunkName: "WareCarApplicability" */ './components/ware/WareCarApplicability'
      ),
    WareCarApplicabilityM: () =>
      import(
        /* webpackChunkName: "WareCarApplicabilityM" */ './components/ware/WareCarApplicabilityM'
      ),
    WareSalePointInfo: () =>
      import(
        /* webpackChunkName: "WareSalePointInfo" */ '@/lib/salePoints/components/WareSalePointInfo'
      ),
    WareCheckPoints: () =>
      import(
        /* webpackChunkName: "WareCheckPoints" */ '@/lib/salePoints/components/WareCheckPoints'
      )
    /* eslint-enable max-len */
  },

  async asyncData ({ app, store, query, route, params, redirect, error }) {
    const loadTime = Date.now();
    const { meta, wareId } = params;

    try {
      const ware = await store.dispatch('cache/get', {
        action: 'seo/getWareMeta',
        payload: { id: wareId }
      });

      const tmsl = ware.trademark?.slug || '';

      if (meta && !meta.match(`${tmsl}-${ware.article_slug || ''}`)) {
        await redirect(301, {
          name: route.name,
          params: {
            meta: `${tmsl}-${ware.article_slug}`,
            wareId: ware.id
          },
          query
        });
      }

      const vm = { $route: route, $router: app.router, $store: store };

      const { seoBreadcrumbs, breadcrumbs } = await getWareBreadcrumbs({
        query,
        ware,
        vm
      });

      store.dispatch('setBreadcrumbs', breadcrumbs);

      const { meta: wareMeta, analogs, ..._ware } = ware;

      return {
        loadTime: Date.now() - loadTime,
        ware: _ware,
        analogs: [_ware, ...analogs].map(getParsedWare),
        meta: wareMeta,
        seoBreadcrumbs,
        seoProductMeta: getSeoProductMeta({
          route,
          ware: { ...ware, meta: { h1: wareMeta.h1 } }
        })
      };
    } catch (e) {
      console.error(e);
      error({
        statusCode: e.statusCode || 404,
        message: e.message || app.i18n.t('errors.404.message')
      });
    }
  },

  data () {
    return {
      isBasket: false,
      ware: null,
      meta: null,
      seoBreadcrumbs: {},
      seoProductMeta: {},
      isSubmitOrderLoading: false,
      salePointInfo: null,
      checkPoints: null
    };
  },

  computed: {
    isMobile () {
      return this.maxmq('xs');
    },

    searchLink () {
      return parsers.getSearchRouteBy(this, this.ware, {
        id: this.ware.id,
        query: { utm_mriyar: 'all_offers_card' }
      });
    },

    wareTitle () {
      const { name, trademark, article, display_article } = this.ware;
      const tm = trademark?.name || '';
      const ar = display_article || article || '';

      return this.meta.h1 || `${name} ${ar} ${tm}`;
    },

    imgAlt () {
      const tm = this.ware.trademark?.name;
      const ac = this.ware.display_article || this.ware.article;
      return `${tm} ${ac} ${this.ware.name}`;
    },

    anchors () {
      if (!this.ware) {
        return [];
      }

      const isKits = this.ware.has_kits;
      const isApplicability = !isEmpty(this.ware.car_brands);
      const isDescription = this.meta.text;

      return [
        'mainInfo',
        'characteristics',
        isKits ? 'kits' : '',
        isApplicability ? 'applicability' : '',
        isDescription ? 'description' : ''
      ].filter(Boolean);
    }
  },

  created () {
    if (!this.ware) {
      return this.$nuxt.error({
        statusCode: 404,
        message: this.$t('labels.not_found')
      });
    }
  },

  mounted () {
    this.emitAnaliticEvent();
    console.info(`LOAD_TIME: ${this.loadTime}ms`, this.$data);
  },

  methods: {
    get,
    isEmpty,

    async submitOrder (ware) {
      this.isSubmitOrderLoading = true;
      await addWareToBasket(this, ware, {}, { from: 'Product Page' });
      this.salePointInfo = null;
      this.isSubmitOrderLoading = false;
    },

    setSalePointInfo ({ id, ware }) {
      this.salePointInfo = {
        id,
        ware: {
          ...ware,
          ware: {
            ...ware,
            image_url: ware.images[0]?.image_url
          }
        }
      };
    },

    emitAnaliticEvent () {
      return this.$store.dispatch('analytics/pushWarePageShowEvent', {
        rawData: this.ware,
        options: { item_list_name: 'Product Page' }
      });
    }
  },

  validate ({ params }) {
    return /^\d+$/.test(params.wareId);
  }
};
</script>

<style scoped lang="scss">
.ware-page {
  .mr-block {
    display: flex;
    justify-content: space-between;

    .left {
      min-width: 350px;
      width: 40%;
      margin-right: 20px;
    }

    .right {
      max-width: 500px;
      flex: 1;
    }
  }

  .w-9 {
    max-width: 996px;
  }

  .photo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  ::v-deep .wares-table {
    max-width: 1000px;
  }

  @media all and (max-width: $--md) {
    .main-info {
      padding: 0;
    }

    ::v-deep .photo {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .mr-block {
      flex-direction: column;
      justify-content: center;

      .left {
        min-width: unset;
        width: unset;
        margin-right: 0;
        padding: 0;
      }

      .right {
        max-width: 100%;
        padding: 0;
      }
    }
  }
}
</style>
