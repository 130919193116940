<template>
  <div class="col-items">
    <ColItemSelect
      v-if="value.column"
      v-model="selectData"
      :config="configTypes"
      :dependency="dependency"
      :origin-val="value"
      v-on="listeners"
    />

    <ColItemSelect
      v-else-if="value._isAction"
      v-model="priceActionData"
      :config="config.price_actions"
      :dependency="dependency"
      :origin-val="value"
      v-on="listeners"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import { nonFiltered } from '../../services/getPriceCollMapping';
import ColItemSelect from './ColItemSelect';

export default {
  name: 'ColItems',

  components: {
    ColItemSelect
  },

  props: {
    usedTypes: { type: Object, default: () => ({}) },
    value: Object,
    formData: { type: Array, required: true },
    config: { type: Object, required: true },
    dependency: { type: Object, required: true }
  },

  computed: {
    configTypes () {
      if (isEmpty(this.usedTypes)) {
        return this.config.types;
      }

      return this.config.types.filter(({ value }) => {
        const current = this.usedTypes[value] === this.value.column;
        return nonFiltered.includes(value) || current || !this.usedTypes[value];
      });
    },

    listeners () {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },

    selectData: {
      get () {
        if (this.value.column) {
          return this.parseFrom(this.value, this.config.types);
        }
        return [];
      },

      set (selectData) {
        const value = this.parsedTo(selectData);
        this.$emit('input', value);
      }
    },

    priceActionData: {
      get () {
        const value = { ...this.value, type: this.value.action };
        return this.parseFrom(value, this.config.price_actions);
      },

      set (priceActionData) {
        const _parent = this.value._parent;

        if (priceActionData[1]) {
          _parent.price_actions[this.value._actionIndex] = this.parsedToAction(
            priceActionData
          );
        } else {
          _parent.price_def = null;
          _parent.price_actions.splice(this.value._actionIndex, 1);
        }

        this.$emit('input', { _parent, value: _parent });
      }
    }
  },

  methods: {
    parseFrom (source, types, mainType = 'type') {
      if (isEmpty(source)) {
        return [];
      }

      const result = [];
      const type = source[mainType];
      result.push(type);
      const collElem = types.find(item => item.value === type);

      this.getFromChild(result, source, collElem);

      return result;
    },

    getFromChild (result, source, collElem) {
      const children =
        collElem && (collElem.children || collElem.displayValueOnly);

      if (children) {
        const value = get(source, collElem.field);

        if (value) {
          result.push(value);
        }

        const childElem = children.find(item => item.value === value);

        if (childElem && childElem.children) {
          this.getFromChild(result, source, childElem);
        }
      }
    },

    parsedTo (value) {
      const { column, sheet } = this.value;

      if (isEmpty(value) || !value[0]) {
        return { column, sheet };
      }

      const newCol = { column, sheet, type: value[0] };

      const collElem = this.config.types.find(
        item => item.value === newCol.type
      );
      this.setFromChild(newCol, value, 1, collElem);

      return newCol;
    },

    parsedToAction (value) {
      const { action, pricing } = this.value;
      const newCol = { action, pricing };

      if (isEmpty(value) || !value[0]) {
        return newCol;
      }

      const collElem = this.config.price_actions.find(
        item => item.value === newCol.action
      );

      this.setFromChild(newCol, value, 1, collElem);

      return newCol;
    },

    setFromChild (mapItem, values, index, collElem) {
      if (collElem.field && values[index]) {
        set(mapItem, collElem.field, values[index]);
      }

      if (collElem.children) {
        const nextChild = collElem.children.find(
          item => item.value === values[index]
        );

        if (nextChild && nextChild.children) {
          this.setFromChild(mapItem, values, index + 1, nextChild);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.col-items {
  ::v-deep .el-form-item,
  ::v-deep .form-item {
    margin-bottom: 4px;
    width: 100%;
  }
}
</style>
