var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "seller-orders-table" },
    [
      _vm.localError && _vm.load
        ? _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.reload },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("buttons.searchAgain")) +
                      "\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("TheLoader")
        : [
            _c(
              "div",
              { staticClass: "mb-2 table-header" },
              [
                _c("OrdersFilter", { attrs: { "use-route": "" } }),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { align: "middle" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mr-1",
                        attrs: { size: "mini", plain: "", type: "warning" },
                        on: { click: _vm.reload },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("buttons.reload")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("StatusCycleHelp"),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "mr-1 w-200p",
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$t("placeholders.setStatus"),
                          size: "mini",
                        },
                        model: {
                          value: _vm.selectedStatusCollection,
                          callback: function ($$v) {
                            _vm.selectedStatusCollection = $$v
                          },
                          expression: "selectedStatusCollection",
                        },
                      },
                      _vm._l(_vm.groupedByStatus, function (value, status) {
                        return _c(
                          "el-option",
                          {
                            key: status,
                            attrs: {
                              disabled: value.disabled,
                              label: status
                                ? _vm.$t("orderStatuses." + status) +
                                  " (" +
                                  value.items.length +
                                  ")"
                                : "",
                              value: status,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("orderStatuses." + status)) +
                                " (" +
                                _vm._s(value.items.length) +
                                ")\n          "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.selectedStatusCollection,
                          size: "mini",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setNextStatusForGroup(
                              _vm.selectedStatusCollection
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("buttons.apply")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.usePage && _vm.propCount > _vm.PAGE_SIZE
              ? _c(
                  "el-row",
                  { staticClass: "mt-2 mb-2", attrs: { justify: "center" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "current-page": _vm.page,
                        layout: "prev, pager, next",
                        "page-size": _vm.PAGE_SIZE,
                        total: _vm.propCount,
                      },
                      on: {
                        "update:currentPage": function ($event) {
                          _vm.page = $event
                        },
                        "update:current-page": function ($event) {
                          _vm.page = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-table",
              {
                attrs: {
                  "cell-style": { textAlign: "center" },
                  data: _vm.value,
                  "header-cell-style": { textAlign: "center" },
                },
                on: {
                  "row-click": function ($event) {
                    return _vm.$emit("rowClick", $event)
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("basket.orderNum"),
                    prop: "_created",
                    width: "170px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-row",
                            { attrs: { align: "middle", justify: "center" } },
                            [
                              _c("div", { staticClass: "filter" }, [
                                _c("img", {
                                  staticClass: "icon-img c-pointer",
                                  attrs: {
                                    src: require("@/assets/icons/filter-outline-primary.svg"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleFilter(
                                        "order_public_id",
                                        row._publicId
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "nuxt-link",
                                {
                                  staticClass: "pg-0-1",
                                  attrs: {
                                    to: {
                                      name: _vm.getRouteName(
                                        "profile.business.seller_orders.order"
                                      ),
                                      params: { id: row._orderId },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "color-blue c-pointer" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row._displayPublicId) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  row.order_detail.is_one_click_order
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "TheTooltip",
                                            {
                                              attrs: {
                                                content:
                                                  _vm.$t("orders.fastOrder"),
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "pg-05 required one-click-order-icon",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              row.order_detail.comment
                                ? _c(
                                    "TheTooltip",
                                    {
                                      attrs: {
                                        content: row.order_detail.comment,
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-chat-line-round",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "small" }, [
                            _vm._v(_vm._s(row._created)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("basket.deliveryCity"),
                    prop: "_deliveryCity",
                    width: "150px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "TheTooltip",
                            { attrs: { content: row._deliveryCity } },
                            [
                              _c("div", {
                                staticClass: "pg-0-1 text-ellipsis",
                                domProps: {
                                  innerHTML: _vm._s(row._deliveryCityHtml),
                                },
                              }),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("basket.buyer"),
                    prop: "_buyer",
                    width: "160px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-row",
                            { attrs: { align: "middle" } },
                            [
                              _c("div", { staticClass: "filter" }, [
                                _c("img", {
                                  staticClass: "icon-img c-pointer",
                                  attrs: {
                                    src: require("@/assets/icons/filter-outline-primary.svg"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleFilter(
                                        "buyer",
                                        row._buyerId
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "TheTooltip",
                                { attrs: { content: row._buyerName } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pg-0-1 text-ellipsis" },
                                    [_vm._v(_vm._s(row._buyerName))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "TheTooltip",
                                { attrs: { content: row._buyerData } },
                                [
                                  _c("div", { staticClass: "small" }, [
                                    _vm._v(_vm._s(row._buyerData)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("basket.ware"),
                    "min-width": "280px",
                    prop: "_ware",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-row", { staticClass: "article-row" }, [
                            _c("div", { staticClass: "filter" }, [
                              _c("img", {
                                staticClass: "icon-img c-pointer",
                                attrs: {
                                  src: require("@/assets/icons/filter-outline-primary.svg"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleFilter(
                                      "article",
                                      row.article
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pg-0-1" }, [
                              _c("strong", { staticClass: "text-ellipsis" }, [
                                _vm._v(_vm._s(row.display_trademark)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "article-title" }, [
                              _vm._v(_vm._s(row.display_article)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-row", [
                            _c("div", { staticClass: "small" }, [
                              _vm._v(_vm._s(row.display_title)),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label:
                      _vm.$t("el.table.sumText") +
                      ", " +
                      _vm.MARKETPLACE_CURRENCY,
                    prop: "price",
                    width: "120px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [_vm._v(_vm._s(row._priceSum))]),
                          _vm._v(" "),
                          _c("div", { staticClass: "small" }, [
                            _vm._v(
                              _vm._s(row.quantity) + " × " + _vm._s(row._price)
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label:
                      _vm.$t("basket.priceFile") +
                      ", " +
                      _vm.$t("basket.delivery_time"),
                    prop: "priceFile",
                    width: "200px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-row",
                            { attrs: { align: "middle", justify: "center" } },
                            [
                              _c("div", { staticClass: "filter" }, [
                                row._priceFileId
                                  ? _c("img", {
                                      staticClass: "icon-img c-pointer",
                                      attrs: {
                                        src: require("@/assets/icons/filter-outline-primary.svg"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleFilter(
                                            "price_file",
                                            row._priceFileId
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-ellipsis pg-0-1" },
                                [
                                  _c(
                                    "TheTooltip",
                                    { attrs: { content: row._priceFileName } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(row._priceFileName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div"),
                            ]
                          ),
                          _vm._v(" "),
                          row.selection_request_detail
                            ? _c(
                                "el-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c(
                                    "nuxt-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: _vm.getRouteName(
                                            "profile.business.s-requests.edit"
                                          ),
                                          params: {
                                            id: row.selection_request_detail.id,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            row.selection_request_detail
                                              .public_id
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "small",
                              attrs: { justify: "center" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row._deliveryDate) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: _vm.$t("basket.status"), width: "160px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-row",
                            {
                              staticClass: "color-green",
                              attrs: { justify: "center" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    row.is_paid ? _vm.$t("basket.is_paid") : ""
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("OrderStatusToggle", {
                            attrs: {
                              value: row,
                              "can-set-next-status": _vm.canSetNextStatus,
                              "get-next-status": _vm.getNextStatus,
                              "set-next-status": _vm.setNextStatus,
                            },
                          }),
                          _vm._v(" "),
                          _vm.canCancel(row.status)
                            ? _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.itemFormCancel = row
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "TheTooltip",
                                    {
                                      attrs: {
                                        content: _vm.$t(
                                          "buttons.cancelOrderItem"
                                        ),
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-circle-close c-pointer",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          row.shipment_detail
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticClass: "text-ellipsis",
                                      attrs: { justify: "space-between" },
                                    },
                                    [
                                      _vm.hasWaybill(row)
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "TheTooltip",
                                                {
                                                  staticClass: "text-ellipsis",
                                                  attrs: {
                                                    content:
                                                      row.shipment_detail
                                                        .waybill,
                                                    enterable: "",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        row.shipment_detail
                                                          .waybill
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("div", [_vm._v("-")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("img", {
                                          staticClass: "icon-img c-pointer",
                                          attrs: {
                                            src: require("@/assets/icons/information-variant-primary.svg"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showShipmentInfo(row)
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "40px" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("el-checkbox", {
                            model: {
                              value: _vm.isAllChecked,
                              callback: function ($$v) {
                                _vm.isAllChecked = $$v
                              },
                              expression: "isAllChecked",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-checkbox", {
                            attrs: {
                              disabled: _vm.orderItemEndStatuses.includes(
                                row.status
                              ),
                              value: _vm.checkedIds.includes(row.id),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.toggleItem(row, $event)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.hideBottomStatusSelect
              ? _c(
                  "el-row",
                  {
                    staticClass: "mt-2",
                    attrs: { align: "middle", justify: "end" },
                  },
                  [
                    _c("StatusCycleHelp"),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "mr-1",
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$t("placeholders.setStatus"),
                          size: "mini",
                        },
                        model: {
                          value: _vm.selectedStatusCollection,
                          callback: function ($$v) {
                            _vm.selectedStatusCollection = $$v
                          },
                          expression: "selectedStatusCollection",
                        },
                      },
                      _vm._l(_vm.groupedByStatus, function (value, status) {
                        return _c(
                          "el-option",
                          {
                            key: status,
                            attrs: {
                              disabled: value.disabled,
                              label: status
                                ? _vm.$t("orderStatuses." + status)
                                : "",
                              value: status,
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("orderStatuses." + status)) +
                                " (" +
                                _vm._s(value.items.length) +
                                ")\n        "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.selectedStatusCollection,
                          size: "mini",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setNextStatusForGroup(
                              _vm.selectedStatusCollection
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("buttons.apply")) +
                            "\n      "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.usePage && _vm.propCount > _vm.PAGE_SIZE
              ? _c(
                  "el-row",
                  { staticClass: "mt-2", attrs: { justify: "center" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "current-page": _vm.page,
                        layout: "prev, pager, next",
                        "page-size": _vm.PAGE_SIZE,
                        total: _vm.propCount,
                      },
                      on: {
                        "update:currentPage": function ($event) {
                          _vm.page = $event
                        },
                        "update:current-page": function ($event) {
                          _vm.page = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: {
            width: "600px",
            title: _vm.$t("basket.rejectOrderProcessing"),
          },
          model: {
            value: _vm.itemFormCancel,
            callback: function ($$v) {
              _vm.itemFormCancel = $$v
            },
            expression: "itemFormCancel",
          },
        },
        [
          _vm.itemFormCancel
            ? _c("OrderCancelConfirm", {
                attrs: { value: _vm.itemFormCancel },
                on: {
                  cancel: function ($event) {
                    _vm.itemFormCancel = null
                  },
                  "item:update": _vm.emitCancel,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !!_vm.forShipments
        ? _c("ShipmentFormDialog", {
            attrs: { value: _vm.forShipments },
            on: {
              cancel: function ($event) {
                _vm.forShipments = null
              },
              created: _vm.updateItemsByShipment,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: { width: "600px", title: _vm.$t("basket.orderShipment") },
          model: {
            value: _vm.shipmentInfo,
            callback: function ($$v) {
              _vm.shipmentInfo = $$v
            },
            expression: "shipmentInfo",
          },
        },
        [
          _vm.shipmentInfo
            ? _c("ShipmentInfo", {
                attrs: { id: _vm.shipmentInfo },
                on: {
                  cancel: function ($event) {
                    _vm.shipmentInfo = null
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }