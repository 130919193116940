var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "login-form-card" },
    [
      !_vm.hideCloseIcon
        ? _c(
            "nuxt-link",
            {
              staticClass: "close-icon",
              attrs: { to: { name: _vm.getRouteName("home") } },
            },
            [_c("i", { staticClass: "el-icon-close" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mb-2" }, [
        _c(
          "span",
          { staticClass: "custom-headline custom-headline--level2-bold" },
          [_vm._v("\n      " + _vm._s(_vm.$t("auth.enter")) + "\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("LoginForm", { attrs: { "show-social-links": _vm.showSocialLinks } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }