var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scan-files-requisites-block" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          attrs: {
            action: "https://mp-api.dev.mriyar.ua/",
            "auto-upload": false,
            drag: "",
            "on-change": _vm.changeHandler,
            "on-remove": _vm.clear,
            "show-file-list": false,
          },
        },
        [
          _c("div", { staticClass: "upload-input" }, [
            _vm._v(_vm._s(_vm.imgName)),
          ]),
          _vm._v(" "),
          _c("i", { staticClass: "el-icon-upload2" }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "controls" }, [
        _vm.imgSrc
          ? _c("i", {
              staticClass: "el-icon-delete",
              on: { click: _vm.deleteImg },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.imgSrc
          ? _c("i", {
              staticClass: "el-icon-picture-outline",
              on: {
                click: function ($event) {
                  _vm.dialogVisible = true
                },
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("img", { attrs: { alt: "IMG", src: _vm.imgSrc, width: "100%" } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }