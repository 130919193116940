var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("el-switch", {
        class: ["price-activator", { left: _vm.left }],
        attrs: {
          "active-text": _vm.label,
          disabled: _vm.readonly,
          title: _vm.title,
          value: _vm.priceFile.is_active,
        },
        on: { change: _vm.confirmChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }