<template>
  <div class="generate-invoice-dialog">
    <el-button
      :disabled="!contract"
      type="primary"
      @click="isModal = !isModal"
    >
      {{ $t('balance.upBalance') }}
    </el-button>

    <TheDialog
      v-model="isModal"
      width="400px"
      :title="$t('balance.upBalance')"
    >
      <div class="generate-modal">
        <span>{{ $t('balance.enterAmount') }}</span>

        <el-input v-model="amount" class="mt-1">
          <template slot="append">{{ MARKETPLACE_CURRENCY }} </template>
        </el-input>

        <el-button class="mt-2" type="primary" @click="generateInvoice">
          {{ $t('balance.generateInvoice') }}
        </el-button>
      </div>
    </TheDialog>
  </div>
</template>

<script>
import { env } from '@/lib/core';

export default {
  name: 'GenerateInvoiceDialog',

  data () {
    return {
      contract: null,
      isModal: false,
      amount: 0,
      MARKETPLACE_CURRENCY: env.MARKETPLACE_CURRENCY
    };
  },

  mounted () {
    this.loadContract();
  },

  methods: {
    async loadContract () {
      try {
        const [contact] = await this.$store.dispatch(
          'balance/getContracts',
          {
            limit: 1,
            business: this.$store.getters['businessProfiles/businessId']
          }
        );

        this.contract = contact || null;
      } catch (e) {
        console.error(e);
      }
    },

    async generateInvoice () {
      try {
        const { id } = await this.$store.dispatch(
          'balance/generateInvoice',
          {
            contract: this.contract.url,
            amount: String(this.amount).replace(',', '.')
          }
        );

        this.amount = 0;
        this.isModal = false;

        return this.$router.blank({
          name: this.getRouteName('docs.invoice'),
          params: { id }
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.generate-modal {
  /deep/ .el-button {
    width: 100%;
  }
}
</style>
