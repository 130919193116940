var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "catalog-search-filter" }, [
    _c(
      "div",
      { staticClass: "filter" },
      [
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("TrademarkFilter", {
              attrs: {
                "get-link": _vm.getSeoLink,
                trademarks: _vm.trademarks,
                "use-link": !_vm.formData.trademarks.length,
              },
              on: { clear: _vm.clearTrademarks },
              model: {
                value: _vm.formData.trademarks,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "trademarks", $$v)
                },
                expression: "formData.trademarks",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("div", { staticClass: "item__label" }, [
              _vm._v(_vm._s(_vm.$t("labels.priceGrn"))),
            ]),
            _vm._v(" "),
            _c("el-slider", {
              key: _vm.key + 1,
              attrs: {
                max: _vm.price_lte,
                min: _vm.price_gte,
                range: "",
                size: "mini",
                value: _vm.price,
              },
              on: { input: _vm.setPrice },
            }),
            _vm._v(" "),
            _c(
              "el-row",
              { attrs: { justify: "space-between" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c("el-input", {
                      staticClass: "price-input",
                      attrs: {
                        placeholder: "min",
                        size: "mini",
                        value: _vm.formData.price_gte,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.setMin("price", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c("el-input", {
                      staticClass: "price-input",
                      attrs: {
                        placeholder: "max",
                        size: "mini",
                        value: _vm.formData.price_lte,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.setMax("price", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          { staticClass: "buttons", attrs: { justify: "space-between" } },
          [
            _c("el-button", { on: { click: _vm.clear } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("buttons.clear")) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.submit } },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("buttons.apply")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }