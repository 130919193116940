var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-info" },
    [
      _vm.isPriceInQueueForUpdating
        ? _c("el-row", [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("prices.priceInQueueForUpdating")) +
                "\n  "
            ),
          ])
        : _c(
            "el-row",
            { attrs: { gutter: 20, justify: "space-between" } },
            [
              _c("el-col", [
                _c(
                  "div",
                  [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("prices.uploadStatus")) + ":"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "light",
                          enterable: "",
                          "open-delay": 500,
                          placement: "bottom",
                        },
                        model: {
                          value: _vm.isShowInfo,
                          callback: function ($$v) {
                            _vm.isShowInfo = $$v
                          },
                          expression: "isShowInfo",
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm.isLoading
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.$t("core.loading")) + "..."
                                  ),
                                ])
                              : _c(
                                  "el-row",
                                  {
                                    staticClass: "price-status-info",
                                    attrs: { gutter: 20 },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { staticClass: "info" },
                                      [
                                        _vm._l(_vm.info, function (value, key) {
                                          return _c(
                                            "div",
                                            { key: key, staticClass: "item" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "priceStatusesFields." +
                                                        key
                                                    )
                                                  ) +
                                                  ":\n\n                  "
                                              ),
                                              [
                                                "offers_refreshed",
                                                "updated",
                                              ].includes(key)
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm._f("dateFilter")(
                                                            value
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(_vm._s(value)),
                                                  ]),
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _vm.error
                                          ? _c("div", [
                                              _vm._v(_vm._s(_vm.error)),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.price.status
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "prices.statuses." + _vm.price.status
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("prices.uploadSource")) +
                      ": " +
                      _vm._s(_vm.getSource(_vm.price.enable_auto_update))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("prices.lastUpdate")) +
                      ": " +
                      _vm._s(_vm._f("dateFilter")(_vm.price.price_file.updated))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", [
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("prices.fileSize", {
                        size: _vm.price.file_size / 1000000,
                      })
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          href: _vm.price.download_url,
                          type: "primary",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("prices.downloadFile")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "text-right" },
                [
                  _vm.price.price_file
                    ? _c("PriceRePush", {
                        attrs: {
                          "price-file": _vm.price.price_file,
                          readonly: _vm.readonly,
                        },
                        on: {
                          "price-file:update": function ($event) {
                            return _vm.$emit("price-file:update", $event)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mt-2" },
        [_c("PriceRemover", { attrs: { price: _vm.price } })],
        1
      ),
      _vm._v(" "),
      _vm.price.error_code
        ? _c(
            "el-row",
            { staticClass: "error mt-1", attrs: { gutter: 20 } },
            [
              _c("el-col", [
                _vm._v(
                  _vm._s(_vm.price.error_code) +
                    " - " +
                    _vm._s(_vm.price.error_message)
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }