<template>
  <div class="pdf-preview">
    <div v-if="!previewIcon" class="link-container">
      <a v-if="!previewIcon" class="link" download :href="value.url" @click.stop>
        {{ $t('buttons.upload') }} PDF
      </a>
    </div>

    <img v-else src="@/assets/fileTypes/pdf-icon.svg">
  </div>
</template>

<script>
export default {
  name: 'PdfPreview',

  props: {
    previewIcon: Boolean,
    value: { type: Object, required: true }
  }
};
</script>

<style scoped lang="scss">
.pdf-preview {
  display: flex;
  width: 100%;
  font-size: rem(12);
  color: $grey-600;

  img {
    margin: auto;
    opacity: .7;
  }

  &:hover img {
    opacity: 1;
  }

  .link-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px;

    .link {
      display: block;
      padding: 10px 20px;
      margin: auto;
      background-color: $white;
      border: 1px dotted $grey-300;
      border-radius: 4px;
      text-align: center;
    }
  }
}
</style>
