var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    class: ["multiple-select", { invalid: _vm.invalid }],
    attrs: {
      disabled: _vm.readonly,
      options: _vm.options,
      props: { checkStrictly: true, multiple: true },
      size: "mini",
      value: _vm.formData,
    },
    on: {
      change: _vm.setFormData,
      "remove-tag": _vm.remove,
      "visible-change": _vm.emit,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }