import Vue from 'vue';
import VueI18n from 'vue-i18n';
import langCollection from '../lang';
import env from '../lib/core/services/envConstants';

Vue.use(VueI18n);

const langCode = env.MARKETPLACE_DEFAULT_LANG;

export const i18n = new VueI18n({
  locale: langCode,
  locales: langCollection.locales.map(({ langCode }) => langCode),
  defaultLocale: langCode,
  fallbackLocale: langCode,
  messages: langCollection.messages,
  silentTranslationWarn: process.env.npm_lifecycle_event === 'test'
});

export default ({ app, store }) => {
  i18n.locale = store.getters.langCode;
  app.i18n = i18n;
};
