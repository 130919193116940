<template>
  <div v-if="basket" :class="['basket-icon', { 'only-icon': onlyIcon }]">
    <div :class="['wrapper', { active: isActive }]" @click="goToBasket($event)">
      <div class="icon-wrapper">
        <img
          v-lazy-load
          width="50"
          height="50"
          class="icon"
          :src="icon"
          alt="basket"
        >
        <div v-show="isActive" class="quantity">{{ quantity }}</div>
      </div>

      <div v-if="!onlyIcon" class="price">{{ price }}</div>
    </div>
  </div>
</template>

<script>
import { parsers, env, eventBus } from '@/lib/core';
const sk = require('@/assets/icons/shopping-cart.svg');
const skPrimary = require('@/assets/icons/shopping-cart--primary.svg');

export default {
  name: 'BasketIcon',

  props: {
    onlyIcon: Boolean
  },

  computed: {
    icon () {
      return this.isActive ? skPrimary : sk;
    },

    basket () {
      return this.$store.getters['baskets/basket'];
    },

    quantity () {
      return this.basket.total_quantity || this.getTotalQuantity(this.basket.items);
    },

    price () {
      const price = this.basket.total_amount || this.getTotalAmountNum(this.basket.items);
      return `${parsers.getPriceNum(price)} ${env.MARKETPLACE_CURRENCY}`;
    },

    isActive () {
      return this.basket && (this.basket.total_quantity || this.basket.items?.length);
    }
  },

  methods: {
    goToBasket () {
      if (this.$route.name.split('__')[0] !== 'basket') {
        eventBus.$emit('basket:show');
      }
    },

    getTotalQuantity (items = []) {
      return items.reduce((acc, { error_code, quantity }) => {
        return error_code === 'not_found' ? acc : acc + quantity;
      }, 0);
    },

    getTotalAmountNum (items = []) {
      return items.reduce((acc, { error_code, price, quantity }) => {
        return error_code === 'not_found'
          ? acc
          : acc + (Number.parseFloat(price) * quantity);
      }, 0);
    }
  }
};
</script>

<style scoped lang="scss">
.basket-icon {
  width: fit-content;
  cursor: pointer;

  .icon-wrapper {
    width: fit-content;
    position: relative;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .active {
    color: $blue-500;
  }

  .quantity {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    top: 0;
    right: -4px;
    padding-top: 2px;
    background-color: $red-500;
    color: $white;
    font-size: .6rem;
  }

  .icon {
    position: relative;
    font-size: 2rem;
    width: 50px;
    height: 50px;
  }

  .price {
    font-size: 1rem;
  }

  &.only-icon {
    .icon {
      width: 20px;
      height: 20px;
    }

    .quantity {
      width: 13px;
      height: 13px;
      top: 2px;
      bottom: unset;
      right: -4px;
      left: unset;
    }

    .price {
      font-size: 12px;
    }
  }
}
</style>
