<template>
  <div class="price-txt-type-form">
    <div class="title">{{ $t('priceFile.csvTitle', { name: format.title }) }}</div>

    <div class="wrapper">
      <el-form-item
        :label="format.attributes[0].title"
        label-width="40%"
        :prop="format.attributes[0].code"
      >
        <el-tooltip
          :content="$t('placeholders.chooseOrEnter')"
          effect="light"
          :manual="!format.attributes[0].allowCreate"
          placement="right"
        >
          <el-select
            :allow-create="format.attributes[0].allowCreate"
            default-first-option
            filterable
            :value="value[format.attributes[0].code]"
            @change="emit(format.attributes[0].code, $event)"
          >
            <el-option
              v-for="item in format.attributes[0].options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-tooltip>
      </el-form-item>

      <el-form-item
        v-if="value.file_encoding !== 'auto'"
        :label="format.attributes[1].title"
        label-width="40%"
        :prop="format.attributes[1].code"
      >
        <el-tooltip
          :content="$t('placeholders.chooseOrEnter')"
          effect="light"
          :manual="!format.attributes[1].allowCreate"
          placement="right"
        >
          <el-select
            :allow-create="format.attributes[1].allowCreate"
            default-first-option
            filterable
            :value="value[format.attributes[1].code]"
            @change="emit(format.attributes[1].code, $event)"
          >
            <el-option
              v-for="item in format.attributes[1].options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-tooltip>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { library } from '@/lib/core';

export default {
  name: 'PriceCsvTypeForm',

  props: {
    value: { type: Object, required: true }
  },

  data: () => ({
    format: library.priceTypes.find(({ type }) => type === 'csv')
  }),

  methods: {
    emit (code, value) {
      this.$emit(`update:${code}`, value);
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/components/price-forms.scss';

.price-txt-type-form {
  .splitter-input {
    width: 50px;
  }
}
</style>
