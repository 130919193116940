<template>
  <div class="ware-preview-list">
    <FilePreviewList
      :alt="alt"
      :preview-size="isMobile ? '120' : '300'"
      readonly
      :size="isMobile ? 'xs' : 'md'"
      :img-path="isMobile ? 'image_200x200' : 'image_500x500'"
      dialog-img-path="image_800x800"
      :value="ware.images"
    >
      <template #popupHeader>
        <span class="custom-headline custom-headline--level2-bold text-center">
          {{ title }}
        </span>
      </template>
    </FilePreviewList>
  </div>
</template>

<script>
import get from 'lodash/get';
import { FilePreviewList, parsers } from '@/lib/core';

export default {
  name: 'WarePreviewList',

  components: {
    FilePreviewList
  },

  props: {
    title: String,
    alt: String,
    ware: { type: Object, required: true }
  },

  computed: {
    isMobile () {
      return this.maxmq('xs');
    }
  },

  methods: {
    get,
    getPriceNum: parsers.getPriceNum
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .custom-btn {
    min-width: 176px;
  }

  .price {
    font-weight: 700;
    font-size: 36px;
    color: $black;
  }

  @media all and (max-width: $--xs) {
    .custom-btn {
      min-width: unset;
      width: fit-content;
    }

    .price {
      font-size: 26px;
    }
  }
}

</style>
