<template>
  <div class="sign-in-links">
    <client-only>
      <el-row align="middle" :gutter="8" justify="center">
        <el-col :span="12"><GoogleBtn v-on="$listeners" /></el-col>
        <el-col :span="12"><FacebookBtn v-on="$listeners" /></el-col>
      </el-row>
    </client-only>
  </div>
</template>

<script>
import FacebookBtn from '../plugins/FacebookBtn';
import GoogleBtn from '../plugins/GoogleBtn';

export default {
  name: 'SignInLinks',

  components: {
    FacebookBtn,
    GoogleBtn
  }
};
</script>
