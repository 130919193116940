import get from 'lodash/get';
import uniq from 'lodash/uniq';
import keyBy from 'lodash/keyBy';
import isNumber from 'lodash/isNumber';
import searchMediator from './searchMediator';
import getParsedWares from './getParsedWares';
import { env, getDefault } from '@/lib/core';

let _store = null;

const defaultFilter = {
  limit_by: 'ware_delivery_seller',
  limit_by_order_by: '-rank'
};

async function loadSelectedArticles (searchParams) {
  const {
    filter,
    selectedArticlesAggs,
    selectedArticlesLimit,
    ...params
  } = searchParams;

  const _searchParams = {
    ...params,
    filter: {
      ...filter,
      ware_and_analogs: false,
      limit: isNumber(selectedArticlesLimit) ? selectedArticlesLimit : 4,
      offset: 0,
      ...defaultFilter,
      aggs: selectedArticlesAggs || undefined
    }
  };

  try {
    const res = await searchMediator(_searchParams, _store);
    res.results = getParsedWares(res.results);

    return res;
  } catch (e) {
    console.error(e);
    return getDefault('articlesRes');
  }
}

async function loadAnalogues (searchParams) {
  const { filter, aggs, selectedArticlesAggs, ...params } = searchParams;
  const PAGINATION_SIZE =
    searchParams.analogsLimit ||
    searchParams.PAGINATION_SIZE ||
    env.PAGINATION_SIZE;

  const _params = {
    ...params,
    filter: {
      limit: PAGINATION_SIZE,
      offset: PAGINATION_SIZE * (searchParams.page - 1),
      ...filter,
      ...defaultFilter,
      // use for count data on server
      aggs
    }
  };

  try {
    const res = await searchMediator(_params, _store);

    res.results = getParsedWares(res.results);

    return res;
  } catch (e) {
    console.error(e);
    return getDefault('articlesRes');
  }
}

async function loadCities (items) {
  const ids = items.reduce((acc, item) => {
    const id = get(item, 'point_of_sale.address.city.id');

    if (id) {
      acc.push(id);
    }

    return acc;
  }, []);

  if (!ids.length) {
    return {};
  }

  let cities = [];

  try {
    cities = await _store.dispatch(
      'cache/get',
      { action: 'searchLocation/getCitiesByIds', payload: { ids: uniq(ids) } }
    );
  } catch (e) {
    console.error(e);
    return {};
  }

  return keyBy(cities, 'id');
}

function addCitiesTo (selectedArticles, analogues, cities) {
  const addCityName = (item) => {
    const cityId = get(item, 'point_of_sale.address.city.id');
    item._city = cities[cityId] ? `(${cities[cityId].name})` : '';
  };

  if (selectedArticles[0] && !selectedArticles[0]._city) {
    selectedArticles.forEach(addCityName);
  }

  if (analogues[0] && !analogues[0]._city) {
    analogues.forEach(addCityName);
  }

  return [selectedArticles, analogues];
}

async function loadAndAddCitiesTo (selectedArticles, analogues) {
  const allItems = selectedArticles.concat(analogues);

  try {
    const cities = await loadCities(allItems);

    if (Object.keys(cities).length) {
      const [_selectedArticles, _analogues] = addCitiesTo(selectedArticles, analogues, cities);

      return {
        selectedArticles: _selectedArticles,
        analogues: _analogues
      };
    } else {
      return {
        selectedArticles,
        analogues
      };
    }
  } catch (e) {
    console.error(e);

    return {
      selectedArticles,
      analogues
    };
  }
}

export default async function (payload) {
  const {
    query,
    store,
    searchParams,
    hideSelectedArticles,
    hideAnalogs
  } = payload;

  _store = store;

  let selectedArticlesRes = getDefault('articlesRes');
  let analogsRes = getDefault('articlesRes');

  const promises = [];

  // hideSelectedArticles - for catalog page!
  if (
    hideAnalogs ||
    (
      !hideSelectedArticles &&
      searchParams.page === 1 &&
      !query.osa
    )
  ) {
    promises.push(loadSelectedArticles(searchParams));
  } else {
    promises.push(Promise.resolve(null));
  }

  if (!hideAnalogs) {
    promises.push(loadAnalogues(searchParams));
  }

  try {
    const [_selectedArticlesRes, _analogsRes] = await Promise.all(promises);
    selectedArticlesRes = _selectedArticlesRes || selectedArticlesRes;
    analogsRes = _analogsRes || analogsRes;

    const {
      selectedArticles,
      analogues
    } = await loadAndAddCitiesTo(selectedArticlesRes.results, analogsRes.results);

    selectedArticlesRes.results = selectedArticles;
    analogues.results = analogues;
  } catch (e) {
    console.error(e);
  }

  return {
    analogsRes,
    selectedArticlesRes // : selectedArticlesRes.count ? selectedArticlesRes : null
  };
}
