import { render, staticRenderFns } from "./BusinessHotKeys.vue?vue&type=template&id=c66fd5f6&scoped=true&"
import script from "./BusinessHotKeys.vue?vue&type=script&lang=js&"
export * from "./BusinessHotKeys.vue?vue&type=script&lang=js&"
import style0 from "./BusinessHotKeys.vue?vue&type=style&index=0&id=c66fd5f6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c66fd5f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c66fd5f6')) {
      api.createRecord('c66fd5f6', component.options)
    } else {
      api.reload('c66fd5f6', component.options)
    }
    module.hot.accept("./BusinessHotKeys.vue?vue&type=template&id=c66fd5f6&scoped=true&", function () {
      api.rerender('c66fd5f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "lib/profiles/components/BusinessHotKeys.vue"
export default component.exports