var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "trademark-page" }, [
    _c("h1", { staticClass: "custom-headline custom-headline--level2-bold" }, [
      _vm._v(
        "\n    " +
          _vm._s((_vm.meta && _vm.meta.h1) || _vm.trademark.name) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    !_vm.isEmpty(_vm.productLines)
      ? _c(
          "div",
          { staticClass: "mb-2" },
          [_c("CatalogTree", { attrs: { value: _vm.productLines } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isEmpty(_vm.products)
      ? _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("TopProducts", {
              attrs: {
                title: _vm.$t("catalog.topProducts") + " " + _vm.trademark.name,
                value: _vm.products,
              },
              on: { "submit-order": _vm.submitOrder },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.meta && _vm.meta.text
      ? _c("div", {
          staticClass: "pg-2 default-style",
          domProps: { innerHTML: _vm._s(_vm.meta.text) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }