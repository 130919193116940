var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-requisites-block" },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.contact.address"),
            prop: "actual_address",
            required: "",
          },
        },
        [
          _vm.value.type === "juridical_person"
            ? _c(
                "el-tooltip",
                {
                  staticClass: "copy-block",
                  attrs: {
                    content: _vm.$t("requisites.contact.copy"),
                    effect: "dark",
                    placement: "top-start",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-copy-document",
                    on: { click: _vm.copy },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "Address",
            _vm._g(
              {
                attrs: {
                  "hide-description-input": "",
                  "hide-label": "",
                  "hide-map": "",
                  "hide-no-address": "",
                  "has-physical-address": "",
                  prop: "actual_address",
                  value: _vm.value.actual_address,
                },
              },
              _vm.onAddressUpdate
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.contact.email"),
            prop: "email.email",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-182p",
            attrs: { type: "email", value: _vm.value.email.email },
            on: {
              input: function ($event) {
                return _vm.setField("email", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.contact.phone"),
            prop: "phone_number.phone_number",
          },
        },
        [
          _c("PhoneInput", {
            staticClass: "max-w-182p",
            attrs: { value: _vm.value.phone_number.phone_number },
            on: {
              input: function ($event) {
                return _vm.setField("phone_number", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }