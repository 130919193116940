var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "garage-widget" },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.$store.getters.isAppLoaded,
            expression: "!$store.getters.isAppLoaded",
          },
        ],
        class: ["placeholder", { selected: _vm.defaultData.car_modification }],
      }),
      _vm._v(" "),
      _c("GarageWidgetPanel" + (_vm.isMobile ? "M" : ""), {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$store.getters.isAppLoaded,
            expression: "$store.getters.isAppLoaded",
          },
        ],
        tag: "component",
        attrs: {
          "is-loading": _vm.isWPLoading,
          "hide-options": _vm.hideOptions,
          "full-list": _vm.fullList,
          car: _vm.car,
          "toggle-view": _vm.isMobile ? _vm.toggleViewM : _vm.toggleView,
          "to-vin-request": _vm.toVinRequest,
          "edit-car": _vm.editCar,
          "go-back-select": _vm.goBackSelect,
          "get-short-car-title": _vm.getShortCarTitle,
          "selector-options": _vm.selectorOptions,
        },
      }),
      _vm._v(" "),
      _vm.isShowSelector
        ? _c("CarSelector" + (_vm.isMobile ? "M" : ""), {
            ref: "carSelector",
            tag: "component",
            staticClass: "mt-1",
            attrs: {
              "default-data": _vm.defaultData,
              "use-default": !_vm.$route.query.cm,
              "get-parsed": _vm.parseRestModificationToQl,
            },
            on: {
              select: _vm.saveModificationId,
              "title:update": function ($event) {
                _vm.selectorOptions = $event
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowList
        ? _c(
            "CarsList",
            {
              class: ["mt-1", { "is-mobile": _vm.isMobile }],
              attrs: {
                "is-mobile": "",
                "show-options": !_vm.hideOptions,
                "hide-all-options": _vm.hideOptions,
                value: _vm.fullList,
              },
              on: {
                remove: _vm.remove,
                "set-item": _vm.select,
                "to-vin-request": _vm.toVinRequest,
                "car-edit": _vm.editCar,
              },
            },
            [
              _c(
                "el-row",
                {
                  staticClass: "garage-btns",
                  attrs: { justify: "space-between" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          _vm.isMobile
                            ? _vm.toggleViewM("selector")
                            : _vm.toggleView("selector")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("buttons.addCar")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.toGarage } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("buttons.goToGarage")) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: {
            width: "600px",
            title: _vm.$t("garage.requestToSelectionByVin"),
          },
          model: {
            value: _vm.isShowVinConfirm,
            callback: function ($$v) {
              _vm.isShowVinConfirm = $$v
            },
            expression: "isShowVinConfirm",
          },
        },
        [
          _vm.isShowVinConfirm
            ? _c("VinConfirmForm", {
                attrs: {
                  car: _vm.isShowVinConfirm,
                  submit: _vm.saveVinAndGoToInquire,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }