export default (list, name) => {
  return list.reduce((acc, item) => {
    if (item.is_primary) {
      acc.unshift(item[name]);
    } else {
      acc.push(item[name]);
    }

    return acc;
  }, []).join(', ');
};
