<template>
  <div class="sale-point-main-info-tab">
    <el-form
      ref="form"
      label-position="left"
      :model="formData"
      :rules="rules"
      size="mini"
      @submit.native.prevent="theSubmit"
    >
      <el-form-item
        :label="$t('labels.salePointName')"
        label-width="200px"
        prop="name"
      >
        <el-input v-model="formData.name" class="max-w-350p" />
      </el-form-item>

      <Address
        ref="address"
        class="label-to-top mb-2"
        :value="formData.address"
        v-bind.sync="formData.address"
      />

      <Contacts
        class="mb-2"
        hide-description
        hide-emails
        :rules="rules"
        :value="formData"
        v-bind.sync="formData"
      />

      <SalePointOptions :value="formData" v-bind.sync="formData" />

      <el-row class="mt-4" justify="center">
        <el-button @click="cloneOrigin">
          {{ $t("buttons.cancel") }}
        </el-button>

        <el-button
          :loading="isLoading"
          :disabled="isEqual"
          native-type="submit"
          type="primary"
        >
          {{ $t("buttons.save") }}
        </el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import scrollToTop from '../../core/services/scrollToTop';
import Address from './formItems/Address';
import Contacts from './formItems/Contacts';
import SalePointOptions from './formItems/SalePointOptions';
import getValidationFor from '@/lib/core/validations';

const fields = [
  'name',
  'address',
  'emails',
  'phone_numbers',
  'activities',
  'schedule',
  'payment_methods',
  'delivery_methods',
  'other_selling_terms',
  'payment_methods_text',
  'delivery_methods_text',
  'delivery_services'
];

export default {
  name: 'SalePointMainInfoTab',

  components: {
    Address,
    Contacts,
    SalePointOptions
  },

  props: {
    readonly: Boolean,
    dependency: null,
    ware: Object,
    entity: { type: Object, required: true },
    submit: { type: Function, default: noop }
  },

  data () {
    return {
      isLoading: false,
      rules: getValidationFor(null, 'salePoint', this),
      originFormData: null,
      formData: null,
      isEqual,
      fields
    };
  },

  watch: {
    entity: {
      deep: true,
      immediate: true,
      handler: 'cloneOrigin'
    },
    formData: {
      deep: true,
      immediate: true,
      handler (formData) {
        this.isEqual = isEqual(formData, this.originFormData);
      }
    }
  },

  methods: {
    cloneOrigin () {
      const clearClone = this.fields.reduce((acc, key) => {
        return { ...acc, [key]: this.entity[key] };
      }, {});

      this.originFormData = cloneDeep(clearClone);
      this.formData = cloneDeep(this.originFormData);
    },

    theSubmit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          scrollToTop('is-error');
          return null;
        }

        const formData = this.getClear(this.formData);

        this.isLoading = true;
        await this.submit(formData);
        this.isLoading = false;
      });
    },

    getClear (formData) {
      return Object.entries(formData).reduce((acc, [key, value]) => {
        if (!isEqual(this.originFormData[key], value)) {
          acc[key] = value;
        }

        return acc;
      }, {});
    }
  }
};
</script>
