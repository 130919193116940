import has from 'lodash/has';
import { env } from '@/lib/core';
import { GET_CAR_MODIFICATION_FULL } from '@/schemes/carsGql';

export const state = () => ({
  selectedCar: null,
  savedCars: []
});

export const mutations = {
  setSelectedCar: (state, selectedCar) => { state.selectedCar = selectedCar; },
  setSavedCars: (state, list) => { state.savedCars = list; }
};

export const actions = {
  async getModificationQL (_, { id }) {
    const { data } = await this.$apollo.query({
      query: GET_CAR_MODIFICATION_FULL,
      variables: { carModificationId: id }
    });

    return data.carModification;
  },

  async getModifications ({ dispatch }, params) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: '/car-modifications/',
      params: {
        ordering: 'name',
        ...params
      },
      progress: false
    });

    return data;
  },

  async setCarData ({ commit, dispatch }, pl) {
    const reqs = [];

    if (has(pl, 'carData')) {
      commit('setSelectedCar', pl.carData);
      const _pl = { name: env.CAR_DATA_KEY, value: JSON.stringify(pl.carData) };
      reqs.push(dispatch('setCookie', _pl, { root: true }));
    }

    if (has(pl, 'cars')) {
      commit('setSavedCars', pl.cars || []);
      const _pl = { name: env.SAVED_CARS_KEY, value: JSON.stringify(pl.cars) };

      reqs.push(dispatch('setCookie', _pl, { root: true }));
    }

    await Promise.all(reqs);

    return pl;
  }
};

export const getters = {
  carData: state => state.selectedCar?.carModification
    ? state.selectedCar
    : null,
  modificationId: state => state.selectedCar?.carModification || null,
  savedCars: state => state.savedCars || []
};
