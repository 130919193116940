<template>
  <FormBlock
    class="contacts-block"
    entity-name="salePoint"
    :fields="['phone_numbers', 'emails']"
    :readonly="readonly"
    :rules="['phone_numbers', 'phone_number', 'email']"
    :submit="submitContacts"
    :value="contacts"
    v-on="$listeners"
  >
    <template #default="{ formData, disabled, validationRules }">
      <Contacts
        :readonly="disabled"
        :rules="validationRules"
        :value="formData"
        v-bind.sync="formData"
      />
    </template>
  </FormBlock>
</template>

<script>
import Contacts from '../formItems/Contacts';
import { getValidationFor, parsers } from '@/lib/core';
import FormBlock from '@/lib/core/components/FormBlock';

export default {
  name: 'ContactsBlock',

  components: {
    FormBlock,
    Contacts
  },

  props: {
    value: { type: Object, required: true },
    entity: { type: Object, required: true },
    submit: Function,
    readonly: Boolean
  },

  data () {
    return {
      rules: getValidationFor(['phone_numbers', 'phone_number', 'emails', 'email'], 'account'),
      contacts: {}
    };
  },

  computed: {
    currentBusinessUser () {
      return this.$store.getters['businessUsers/currentBusinessUser'];
    }
  },

  watch: {
    'entity.business_acl': {
      deep: true,
      immediate: true,
      handler: 'updateContacts'
    }
  },

  methods: {
    submitContacts (formData) {
      if (this.currentBusinessUser) {
        return this.submitBusinessContacts(formData);
      } else {
        return this.submit(formData);
      }
    },

    async submitBusinessContacts (formData) {
      formData.business = this.currentBusinessUser.business.url;
      formData.user = this.entity.url;

      try {
        await this.$store.dispatch('businessUsers/patchBusinessUser', {
          id: this.currentBusinessUser.id,
          formData
        });

        return await this.$store.dispatch('users/updateCurrentUser');
      } catch (e) {
        console.error(e);
      }
    },

    updateContacts () {
      this.setContactsFrom(this.currentBusinessUser || this.value);
    },

    setContactsFrom (entity) {
      this.contacts = {
        phone_numbers: [parsers.getPrimaryOrFirst(entity.phone_numbers, 'phone')],
        emails: [parsers.getPrimaryOrFirst(entity.emails, 'email')]
      };
    }
  }
};
</script>
