var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-wrapper file-preview-dialog",
      attrs: {
        "append-to-body": "",
        center: "",
        top: "50px",
        visible: _vm.isVisible,
        width: "90%",
        height: "90%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        closed: _vm.closeSwiper,
      },
    },
    [
      _vm._t("title"),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c(
            "swiper",
            {
              ref: "mainSwiper",
              staticClass: "gallery-top",
              attrs: { options: _vm.mainSwiperOptions },
            },
            [
              _vm._l(_vm.files, function (file, index) {
                return _c(
                  "swiper-slide",
                  { key: file.fileId },
                  [
                    _c("FilePreview", {
                      staticClass: "file-preview",
                      attrs: {
                        file: file,
                        playing: _vm.isActiveIndex(index),
                        "default-img-path": _vm.defaultImgPath,
                        size: "max",
                        "img-path": _vm.imgPath,
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-button-prev",
                attrs: { slot: "button-prev" },
                on: { click: _vm.prevSlide },
                slot: "button-prev",
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-button-next",
                attrs: { slot: "button-next" },
                on: { click: _vm.nextSlide },
                slot: "button-next",
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "swiper",
            {
              ref: "thumbsSwiper",
              staticClass: "gallery-thumbs",
              attrs: { options: _vm.swiperOptionThumbs },
            },
            _vm._l(_vm.files, function (file) {
              return _c(
                "swiper-slide",
                { key: file.fileId },
                [
                  _c("FilePreview", {
                    attrs: {
                      disabled: "",
                      "img-path": "image_200x200",
                      file: file,
                      "default-img-path": _vm.defaultImgPath,
                      "preview-icon": "",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }