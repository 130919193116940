import { i18n } from '@/plugins/i18n';
import getConcatedAddress from '@/lib/salePoints/services/getConcatedAddress';

export default (address, deliveryMethod) => {
  if (!address) {
    return '-';
  }

  const {
    type,
    service,
    service_name,
    service_office_number
  } = address;

  let _deliveryMethod = '';
  let _type = '';
  const _office = service_office_number ? `№${service_office_number}` : '';
  const _address = getConcatedAddress(address, { hideCountry: true, useAdmin: true });

  if (deliveryMethod === 'carrier_in_country') {
    _deliveryMethod = i18n.t(`deliveryServicesType.${type}`);
  } else {
    _deliveryMethod = deliveryMethod
      ? `${i18n.t(`delivery_methods.${deliveryMethod}`)}.`
      : '';

    if (type) {
      const _typeName = type ? i18n.t(`deliveryTypes.${type}`) : '';
      _type = type === 'address' ? _typeName + '.' : _typeName + ',';
    }
  }

  let _serviceName = type === 'service_office'
    ? service_name || i18n.t(`deliveryServices.${service.replace(/\./g, '_')}`)
    : '';

  if (_serviceName && !service_office_number) {
    _serviceName += '.';
  }

  return [_deliveryMethod || _type || '', _serviceName, _office, _address]
    .filter(Boolean)
    .join(', ');
};
