var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.readonly
    ? _c(
        "FormBlock",
        _vm._g(
          {
            staticClass: "description-block",
            attrs: {
              fields: ["description"],
              submit: _vm.submit,
              value: _vm.value,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var formData = ref.formData
                    var disabled = ref.disabled
                    return [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "description" } },
                        [
                          _vm.$store.getters.isAppLoaded
                            ? _c("TheQuillEditor", {
                                attrs: { readonly: disabled },
                                model: {
                                  value: formData.description,
                                  callback: function ($$v) {
                                    _vm.$set(formData, "description", $$v)
                                  },
                                  expression: "formData.description",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2569685315
            ),
          },
          _vm.$listeners
        )
      )
    : _c("div", {
        staticClass: "text color-default",
        domProps: { innerHTML: _vm._s(_vm.description) },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }