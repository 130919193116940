var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contacts-view color-default" }, [
    _vm.value.phone_numbers.length
      ? _c(
          "div",
          { staticClass: "phones mb-1" },
          [
            _c("div", { staticClass: "label mb-1" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("labels.phones")) + ":\n    "),
            ]),
            _vm._v(" "),
            _vm._l(_vm.value.phone_numbers, function (phone, index) {
              return [
                !_vm.collapse || _vm.isVisiblePhones || !index
                  ? _c(
                      "el-row",
                      { key: phone.id, staticClass: "row" },
                      [
                        _c(
                          "el-row",
                          { attrs: { align: "middle" } },
                          [
                            _c(
                              "div",
                              { staticClass: "fit-content mr-1" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "phone",
                                    attrs: {
                                      href: _vm.getHrefFor(
                                        null,
                                        phone.phone_number
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(phone.phone_number) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              { staticClass: "fit-content mr-1" },
                              [
                                _vm._l(phone.channels, function (channel) {
                                  return [
                                    channel !== "phone"
                                      ? _c(
                                          "div",
                                          { key: channel },
                                          [
                                            _c(
                                              "TheTooltip",
                                              {
                                                attrs: {
                                                  content: _vm.$t(
                                                    "phoneChannels." + channel
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-link",
                                                  {
                                                    staticClass: "phone",
                                                    attrs: {
                                                      href: _vm.getHrefFor(
                                                        channel,
                                                        phone.phone_number
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "icon-img",
                                                      attrs: {
                                                        src: _vm.getIcon(
                                                          channel
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "description text-ellipsis" },
                          [
                            _c(
                              "TheTooltip",
                              { attrs: { content: phone.description } },
                              [_c("span", [_vm._v(_vm._s(phone.description))])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _vm.collapse &&
            !_vm.isVisiblePhones &&
            _vm.value.phone_numbers.length > 1
              ? _c(
                  "div",
                  [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.isVisiblePhones = !_vm.isVisiblePhones
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("buttons.allPhones")) +
                            "...\n      "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.value.emails.length
      ? _c(
          "div",
          { staticClass: "emails" },
          [
            _c("div", { staticClass: "label mb-1" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("labels.email")) + ":\n    "),
            ]),
            _vm._v(" "),
            _vm._l(_vm.value.emails, function (email, index) {
              return [
                !_vm.collapse || _vm.isVisibleEmails || !index
                  ? _c(
                      "el-row",
                      {
                        key: email.id + email.email,
                        staticClass: "row fl-wrap",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "fit-content mr-2" },
                          [
                            _c(
                              "el-link",
                              { attrs: { href: "mailto:" + email.email } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(email.email) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "description text-ellipsis" },
                          [
                            _c(
                              "TheTooltip",
                              { attrs: { content: email.description } },
                              [_c("span", [_vm._v(_vm._s(email.description))])]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _vm.collapse && !_vm.isVisibleEmails && _vm.value.emails.length > 1
              ? _c(
                  "div",
                  [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.isVisibleEmails = !_vm.isVisibleEmails
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("buttons.allEmails")) +
                            "...\n      "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }