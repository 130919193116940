import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

const addLowLine = (result, line) => {
  const { children, ..._line } = line;

  if (isEmpty(children)) {
    result.push(_line);
  } else {
    children.forEach(it => addLowLine(result, it));
  }

  return result;
};

export const getLowLines = (catalog = []) => {
  const result = [];
  catalog.forEach(line => addLowLine(result, line));
  return result;
};

export const getAllLines = (catalog, options = {}) => {
  const result = [];

  const addChildren = (list) => {
    list.forEach((item) => {
      const { children, ..._item } = item;
      result.push({ ..._item, children: [] });

      if (children && children.length) {
        addChildren(children);
      }
    });
  };

  addChildren(catalog);

  if (options.key) {
    return keyBy(result, options.key);
  }

  return result;
};
