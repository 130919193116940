<template>
  <div class="orders-filter">
    <el-select
      :class="['w-200p', {'mr-1': !mobile}]"
      clearable
      :placeholder="$t('placeholders.filterByStatus')"
      size="mini"
      :value="status"
      @change="setStatus"
    >
      <el-option
        v-for="code in statuses"
        :key="code"
        :label="$t(`orderStatuses.${code}`)"
        :value="code"
      />
    </el-select>

    <el-input
      v-if="!hide.includes('order_public_id')"
      v-model="order_public_id"
      :class="['w-200p', { 'mr-1': !mobile }]"
      clearable
      :placeholder="$t('basket.display_public_id')"
      size="mini"
      @clear="setOrderPublicId('')"
      @keyup.enter.native="setOrderPublicId(order_public_id)"
    >
      <el-button
        slot="append"
        icon="el-icon-search"
        @click="setOrderPublicId(order_public_id)"
      />
    </el-input>

    <el-input
      v-if="!hide.includes('phone')"
      v-model="buyer_phone_number"
      :class="['w-200p phone', { 'mr-1': !mobile }]"
      clearable
      disabled
      :placeholder="$t('basket.buyer_phone_number')"
      size="mini"
      @clear="setPhoneNumber('')"
      @keyup.enter.native="setPhoneNumber(buyer_phone_number)"
    >
      <template slot="prepend">{{ MARKETPLACE_PHONE_PREFIX }}</template>
      <el-button
        slot="append"
        disabled
        icon="el-icon-search"
        @click="setPhoneNumber(buyer_phone_number)"
      />
    </el-input>

    <el-input
      v-if="!hide.includes('article')"
      v-model="article"
      :class="['w-200p', { 'mr-1': !mobile }]"
      clearable
      :placeholder="$t('basket.display_article')"
      size="mini"
      @clear="setArticle('')"
      @keyup.enter.native="setArticle(article)"
    >
      <el-button
        slot="append"
        icon="el-icon-search"
        @click="setArticle(article)"
      />
    </el-input>

    <el-input
      v-if="!hide.includes('price_file')"
      v-model="price_file"
      :class="['w-200p', { 'mr-1': !mobile }]"
      clearable
      :placeholder="$t('basket.priceFile')"
      size="mini"
      @clear="setPriceFile('')"
      @keyup.enter.native="setPriceFile(price_file)"
    >
      <el-button
        slot="append"
        icon="el-icon-search"
        @click="setPriceFile(price_file)"
      />
    </el-input>

    <el-button
      :disabled="!Object.keys($route.query).length"
      plain
      size="mini"
      :type="mobile ? 'danger': 'default'"
      @click="clearQuery"
    >
      {{ $t('buttons.clearAllFilters') }}
    </el-button>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { env, library } from '@/lib/core';

export default {
  name: 'OrdersFilter',

  props: {
    useRoute: Boolean,
    mobile: Boolean,
    hide: { type: Array, default: () => [] }
  },

  data () {
    return {
      MARKETPLACE_PHONE_PREFIX: env.MARKETPLACE_PHONE_PREFIX,
      statuses: library.orderItemsStatuses,
      status: this.$route.query.status || '',
      order_public_id: this.$route.query.order_public_id || '',
      buyer_phone_number: this.$route.query.buyer_phone_number || '',
      article: this.$route.query.article || '',
      price_file: this.$route.query.price_file || ''
    };
  },

  created () {
    this.emitQuery = debounce(this.emitQuery, env.DEBOUNCE);
  },

  methods: {
    // TODO need to refactor
    setStatus (_status) {
      const { order_public_id, status, ..._query } = this.$route.query;
      const query = _status ? { ..._query, status: _status } : _query;

      if (this.useRoute) {
        this.status = status;
        this.emitQuery(query);
      } else {
        this.$emit('input', query);
      }
    },

    setOrderPublicId (id) {
      const _id = id.trim();
      const query = _id ? { order_public_id: _id } : {};

      if (this.useRoute) {
        this.order_public_id = _id;
        this.emitQuery(query);
      } else {
        this.$emit('input', query);
      }
    },

    setPhoneNumber (phone) {
      const _phone = !phone || phone.startsWith(env.MARKETPLACE_PHONE_PREFIX)
        ? phone
        : `${env.MARKETPLACE_PHONE_PREFIX}${phone}`;

      const _id = _phone.trim();
      const { order_public_id, buyer_phone_number, ..._query } = this.$route.query;
      const query = _id ? { ..._query, buyer_phone_number: _id } : _query;

      if (this.useRoute) {
        this.buyer_phone_number = _id;
        this.emitQuery(query);
      } else {
        this.$emit('input', query);
      }
    },

    setArticle (id) {
      const _id = id.trim();
      const { order_public_id, article, ..._query } = this.$route.query;
      const query = _id ? { ..._query, article: _id } : _query;

      if (this.useRoute) {
        this.article = _id;
        this.emitQuery(query);
      } else {
        this.$emit('input', query);
      }
    },

    setPriceFile (id) {
      const _id = id.trim();
      const { order_public_id, price_file, ..._query } = this.$route.query;
      const query = _id ? { ..._query, price_file: _id } : _query;

      if (this.useRoute) {
        this.price_file = _id;
        this.emitQuery(query);
      } else {
        this.$emit('input', query);
      }
    },

    clearQuery () {
      return this.emitQuery();
    },

    emitQuery ({ page, ...query } = {}) {
      return this.$router.push({ query });
    }
  }
};
</script>

<style scoped lang="scss">
.orders-filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ::v-deep .el-input-group__append, ::v-deep .el-input-group__prepend {
    padding: 0 4px;

    .el-button {
      padding: 5px 3px;
      margin: 0;
    }
  }

  .phone ::v-deep input {
    padding-right: 4px;
    padding-left: 4px;
  }
}
</style>
