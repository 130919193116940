var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image-preview" }, [
    _c("img", {
      directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
      class: { "mini-image": _vm.previewIcon },
      attrs: { alt: _vm.alt, title: _vm.alt, "data-src": _vm.src },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }