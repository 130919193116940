var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "password-recovery-form" },
    [
      _c(
        "nuxt-link",
        {
          staticClass: "close-icon",
          attrs: { to: { name: _vm.getRouteName("auth") } },
        },
        [_c("i", { staticClass: "el-icon-close" })]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [_vm._v("\n    " + _vm._s(_vm.$t("auth.recoveryPassword")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.formData, rules: _vm.rules },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("labels.email"), prop: "email" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.email,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "email",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("labels.recovery_key"),
                prop: "recovery_key",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.recovery_key,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "recovery_key",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.recovery_key",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("labels.password"), prop: "password" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.formData.password,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("labels.password2"), prop: "password2" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.formData.password2,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "password2",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.password2",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "submit-btn",
                  attrs: {
                    loading: _vm.loading,
                    "native-type": "submit",
                    type: "primary",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("buttons.recoveryPassword")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }