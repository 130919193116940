var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    _vm._b(
      {
        ref: "form",
        class: ["form-block", { readonly: _vm.readonly }],
        staticStyle: { display: "block", height: "fit-content" },
        attrs: {
          disabled: _vm.disabled,
          "label-position": _vm.labelPosition,
          "label-width": _vm.labelWidth,
          model: _vm.formData,
          rules: _vm.validationRules,
          size: "mini",
        },
        nativeOn: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.validateAndEmit.apply(null, arguments)
          },
        },
      },
      "el-form",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        [
          _vm._t("default", null, {
            disabled: _vm.disabled,
            formData: _vm.formData,
            isLoading: _vm.isLoading,
            readonly: _vm.disabled,
            validationRules: _vm.validationRules,
          }),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.disabled && !_vm.hideControls
        ? _c(
            "el-row",
            { staticClass: "mt-2", attrs: { justify: "end" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.isEqual && !_vm.showCancel,
                    size: "mini",
                  },
                  on: { click: _vm.refresh },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("buttons.cancel")) + "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.isEqual && !_vm.unlocked,
                    loading: _vm.isLoading,
                    "native-type": "submit",
                    size: "mini",
                    type: "primary",
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("buttons.save")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }