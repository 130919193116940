var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration-form" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.formData, rules: _vm.rules, size: "medium" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("auth.phone_number"),
                prop: "phone_number",
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.MARKETPLACE_PHONE_PREFIX + "..." },
                on: { blur: _vm.fixPhone },
                model: {
                  value: _vm.formData.phone_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "phone_number", $$v)
                  },
                  expression: "formData.phone_number",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("auth.email"), prop: "email" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "email", $$v)
                  },
                  expression: "formData.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("auth.password"), prop: "password" } },
            [
              _c(
                "el-input",
                {
                  attrs: { type: _vm.isShowPass ? "" : "password" },
                  model: {
                    value: _vm.formData.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "password", $$v)
                    },
                    expression: "formData.password",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-view c-pointer",
                    attrs: { slot: "suffix" },
                    on: {
                      click: function ($event) {
                        _vm.isShowPass = !_vm.isShowPass
                      },
                    },
                    slot: "suffix",
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                {
                  staticClass: "submit-btn",
                  attrs: {
                    loading: _vm.isLoading,
                    "native-type": "submit",
                    type: "primary",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("auth.goRegister")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }