<template>
  <div :class="['buyer-data', { 'row': !isMobile }]">
    <div
      v-if="isForm"
      :class="['form-block mb-2', { 'fit-content': !isMobile }]"
    >
      <div class="block-title mt-05">
        {{ $t('basket.buyer') }}
      </div>

      <div class="buyer-block no-wrap-error">
        <el-form-item
          class="mr-2"
          prop="buyer_phone_number"
          :label="$t('basket.buyer_phone_number')"
        >
          <PhoneInput
            :value="value.buyer_phone_number.phone_number"
            @input="setField('buyer_phone_number.phone_number', $event);"
          />
        </el-form-item>
      </div>

      <el-form-item class="checkbox mt-1" prop="no_call_required">
        <el-checkbox
          :label="$t('basket.no_call_required')"
          :value="value.no_call_required"
          @change="setField('no_call_required', $event)"
        >
          <span class="text-small">{{ $t('basket.no_call_required') }}</span>
        </el-checkbox>
      </el-form-item>
    </div>

    <div v-else>
      <div class="buyer-data-readonly mb-1">
        <span>
          {{ $t('basket.buyer') }}:
        </span>

        <span class="buyer-data__name">
          <span v-if="value.buyer_last_name || value.buyer_first_name">
            {{ value.buyer_last_name }} {{ value.buyer_first_name }},
          </span>

          {{ value.buyer_phone_number.phone_number }}
        </span>

        <el-link
          type="primary"
          @click="$emit('is-form:change', true)"
        >
          {{ $t('buttons.change') }}
        </el-link>
      </div>

      <el-form-item
        class="checkbox white-back pg-0"
        prop="no_call_required"
      >
        <el-checkbox
          :value="value.no_call_required"
          @change="setField('no_call_required', $event)"
        >
          <span class="text-small">{{ $t('basket.no_call_required') }}</span>
        </el-checkbox>
      </el-form-item>
    </div>

    <div v-if="isForm">
      <slot name="options" v-bind="{ isMobile }" />
    </div>
  </div>
</template>

<script>
import PhoneInput from '@/lib/core/components/PhoneInput';

export default {
  name: 'BuyerData',

  components: {
    PhoneInput
  },

  props: {
    isForm: Boolean,
    value: { type: Object, required: true },
    setField: { type: Function, required: true }
  },

  computed: {
    isMobile () {
      return this.maxmq('xs');
    }
  }
};
</script>

<style scoped lang="scss">
.buyer-data {
  .el-checkbox {
    display: flex;

    ::v-deep {
      .el-checkbox__inner {
        margin-top: 2px;
      }
    }
  }

  .checkbox {
    min-width: 280px;

    ::v-deep .el-checkbox__label {
      max-width: calc(100% - 14px);
      white-space: normal;
      font-size: .8rem;
    }
  }

  &.row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .buyer-block {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .buyer-data {
    padding: 0 10px;

    * {
      font-size: 1rem;
    }

    &__name {
      font-weight: bold;
      color: $grey-700;
    }
  }

  @media all and (max-width: $--xss) {
    .el-form-item {
      width: 100%;
    }
  }
}
</style>
