import { requiredString } from './common.js';
import orderRules from './orderRules';

export default {
  ...orderRules,
  buyer: [requiredString],
  point_of_sale: [requiredString],
  waybill_date: [requiredString],
  waybill: [requiredString]
};
