<template>
  <section class="trademarks-grouped-list">
    <div class="mr-filter">
      <ul v-for="(_keys, index) in groupedKeys" :key="index">
        <li
          v-for="key in _keys"
          :key="key"
          :class="{ 'is-active': isActiveLetter(key) }"
        >
          <nuxt-link :to="getLitterRoute(key)">
            {{ key.toUpperCase() }}
          </nuxt-link>
        </li>
      </ul>
    </div>

    <ul v-if="activeItem" class="mr-list mr-list__selected">
      <li
        v-for="trademark in contentList"
        :key="trademark.id"
        class="mr-list-item"
      >
        <nuxt-link
          :to="{
            name: getRouteName('trademarkId'),
            params: { trademarkId: trademark.slug }
          }"
        >
          {{ trademark.name }}
        </nuxt-link>
      </li>
    </ul>

    <ul v-else class="mr-list">
      <li v-for="key in keys" :key="key" class="mr-list-item">
        <el-row>
          <div class="mr-1 mr-list-item-title">
            <nuxt-link :to="getLitterRoute(key)" class="bold">
              {{ key.toUpperCase() }}
            </nuxt-link>
          </div>

          <ul class="mr-list-item-brands">
            <template v-for="(item, index) in groupedByName[key]">
              <li
                v-if="(limit && index <= limit) || visibleChildren[key]"
                :key="item.id"
              >
                <nuxt-link :to="getLinkBy(item)">
                  {{ item.name }}
                </nuxt-link>
              </li>
            </template>

            <a
              v-if="(limit && groupedByName[key].length > limit)"
              :class="[
                'show-all-btn shop-category__open',
                { 'is-open': visibleChildren[key] }
              ]"
              @click="toggleShowChildren(key)"
            >
              <span class="shop-category-text">
                {{ $t(`buttons.${visibleChildren[key] ? 'lessTrademarks' : 'moreTrademarks'}` ) }}
              </span>
            </a>
          </ul>
        </el-row>
      </li>
    </ul>
  </section>
</template>

<script>
import groupBy from 'lodash/groupBy';

const letters = {
  nums: '1-9',
  lat: 'a-z',
  kir: 'а-я'
};

export default {
  name: 'TrademarksGroupedList',

  props: {
    value: { type: Array, required: true },
    limit: { type: Number, default: 5 },
    groupBy: { type: Function, default: item => item.name[0].toLowerCase() }
  },

  data: () => ({ visibleChildren: {} }),

  computed: {
    activeItem () {
      return (this.$route.params.letter || '').toLowerCase();
    },

    contentList () {
      if (this.activeItem === letters.nums) {
        return this.groupedKeys[0].reduce((acc, key) => {
          return acc.concat(this.groupedByName[key]);
        }, []);
      } else if (this.activeItem === letters.kir) {
        return this.groupedKeys[2].reduce((acc, key) => {
          return acc.concat(this.groupedByName[key]);
        }, []);
      } else if (this.activeItem) {
        return this.groupedByName[this.activeItem];
      }

      return [];
    },

    groupedKeys () {
      const nums = [];
      const lat = [];
      const kir = [];

      this.keys.forEach((key) => {
        if (/\d/.test(key)) {
          nums.push(key);
        } else if (/\w/.test(key)) {
          lat.push(key.toLowerCase());
        } else {
          kir.push(key.toLowerCase());
        }
      });

      return [nums, lat, kir];
    },

    groupedByName () {
      return groupBy(this.value, this.groupBy);
    },

    keys () {
      return Object.keys(this.groupedByName).sort();
    }
  },

  methods: {
    isActiveLetter (letter) {
      let key;

      if (this.groupedKeys[0].includes(letter)) {
        key = letters.nums;
      } else if (this.groupedKeys[1].includes(letter)) {
        key = letter;
      } else if (this.groupedKeys[2].includes(letter)) {
        key = letters.kir;
      }

      return this.activeItem === key;
    },

    getLitterRoute (letter) {
      let _key = '';

      if (
        this.groupedKeys[0].includes(letter) &&
        (!this.activeItem || this.activeItem !== letters.nums)
      ) {
        _key = letters.nums;
      } else if (
        this.groupedKeys[1].includes(letter) &&
        (!this.activeItem || this.activeItem !== letter)
      ) {
        _key = letter;
      } else if (
        this.groupedKeys[2].includes(letter) &&
        (!this.activeItem || this.activeItem !== letters.kir)
      ) {
        _key = letters.kir;
      }

      return _key
        ? {
            name: this.getRouteName('brands.letter'),
            params: { letter: _key }
          }
        : { name: this.getRouteName('brands') };
    },

    getLinkBy ({ slug: trademarkId }) {
      return {
        name: this.getRouteName('trademarkId'),
        params: { trademarkId }
      };
    },

    toggleShowChildren (key) {
      this.$set(this.visibleChildren, key, !this.visibleChildren[key]);
    }
  }
};
</script>

<style scoped lang="scss">
.trademarks-grouped-list {
  .mr-filter {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10px;

    ul {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      padding: 0;
      margin: 0 10px 10px 0;
      border: 1px solid $grey-300;
      background-color: $grey-200;

      li {
        cursor: pointer;

        a {
          display: block;
          padding: 10px;
        }

        &:hover, &.is-active {
          background-color: $blue-300;

          a {
            color: $white;
          }
        }
      }
    }
  }

  .show-all-btn {
    display: block;
    margin-top: 6px;
    cursor: pointer;
    color: $--color-text-secondary;
    font-size: .8rem;
  }

  .mr-list {
    display: flex;
    flex-wrap: wrap;

    &-item {
      min-width: 250px;
      display: block;
      padding: 10px;

      &-title a {
        cursor: pointer;
        font-size: 1.2rem;
        padding: 0 5px;
      }

      &-brands {
        padding: 4px 0 0;
      }
    }
  }
}
</style>
