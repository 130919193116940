<template>
  <section class="cars-brands-models">
    <div v-if="title" class="pg-0-1 mb-2">
      <span class="custom-headline custom-headline--level2">
        {{ title }}
      </span>
    </div>

    <ul>
      <li v-for="carBrand in value" :key="carBrand.id" class="block mb-1">
        <CarsBrandsModelsItem
          :get-link="getLink"
          :dependency="dependency"
          :value="carBrand"
        />
      </li>
    </ul>
  </section>
</template>

<script>
import get from 'lodash/get';
import getSeoLinkBy from '../services/getSeoLinkBy';
import CarsBrandsModelsItem from './CarsBrandsModelsItem';

export default {
  name: 'CarsBrandsModels',

  components: {
    CarsBrandsModelsItem
  },

  props: {
    title: String,
    dependency: Object,
    value: { type: Array, required: true }
  },

  methods: {
    getLink ({ carBrand, carModel }) {
      const params = {
        carBrand: carBrand.slug,
        carModel: carModel && carModel.slug,
        lineId: get(this, 'dependency.productLine.slug'),
        trademarkId: get(this, 'dependency.trademark.slug')
      };

      return getSeoLinkBy(params, this, params);
    }
  }
};
</script>

<style scoped lang="scss">
.block {
  display: block;
}
</style>
