import {
  phone_number,
  phone_numbers,
  email,
  terms_url,
  requiredString,
  notEmptyArray
} from './common';
import { i18n } from '@/plugins/i18n';

export default {
  phone_number,
  phone_numbers,
  email,
  terms_url,
  name: [requiredString],
  activities: [
    {
      type: 'array',
      required: true,
      message: i18n.t('validation.simple.required'),
      trigger: 'change'
    }
  ],
  address: [
    {
      type: 'object',
      validator: 'address',
      required: true,
      message: i18n.t('validation.address.required'),
      trigger: 'blur'
    }
  ],
  country: [requiredString],
  admin1: [requiredString],
  city: [requiredString],
  street: [requiredString],
  building: [requiredString],
  description: [
    {
      max: 200,
      message: i18n.t('validation.simple.max', { num: 200 }),
      trigger: 'blur'
    }
  ],
  payment_methods: [notEmptyArray],
  delivery_methods: [notEmptyArray],
  schedule: [
    requiredString,
    {
      type: 'object',
      required: true,
      validator: 'hasValue',
      trigger: 'blur'
    }
  ],
  delivery_services: [
    notEmptyArray,
    { validator: 'deliveryServices' }
  ]
};
