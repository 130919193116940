<template>
  <el-card class="login-form-card">
    <nuxt-link
      v-if="!hideCloseIcon"
      class="close-icon"
      :to="{ name: getRouteName('home') }"
    >
      <i class="el-icon-close" />
    </nuxt-link>

    <div class="mb-2">
      <span class="custom-headline custom-headline--level2-bold">
        {{ $t('auth.enter') }}
      </span>
    </div>

    <LoginForm :show-social-links="showSocialLinks" />
  </el-card>
</template>

<script>
import LoginForm from './LoginForm';

export default {
  name: 'LoginFormCard',

  components: {
    LoginForm
  },

  props: {
    showSocialLinks: Boolean,
    hideCloseIcon: Boolean
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/auth-form.scss";
</style>
