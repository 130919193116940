<template>
  <div class="sale-point-page">
    <h1 class="custom-headline custom-headline--level2 mb-2">
      <span class="bold">{{ pageTitle }}</span>
      <span>{{ concatAddress }}</span>
    </h1>

    <div class="fl-wrap mb-4">
      <ContactsView
        class="mr-1 mb-1"
        :value="salePoint"
      />
      <ScheduleView
        class="mr-1 mb-1"
        readonly
        :value="salePoint.schedule"
      />
      <LocationSelectMap
        readonly
        :query-string="concatAddress"
        :value="location"
      />
    </div>

    <div class="fl-wrap mb-4">
      <SalePointOptionsView :value="salePoint" />
    </div>

    <div v-if="salePoint.description" class="mb-4">
      <div class="label mb-1">{{ $t('salePoint.description') }}</div>

      <!--eslint-disable-next-line  vue/no-v-html-->
      <div v-html="salePoint.description" />
    </div>

    <div v-if="photos.length">
      <div class="label mb-1">{{ $t('salePoint.photosBlock') }}</div>

      <FilePreviewList
        readonly
        :value="photos"
        img-path="photo_200"
        alt="photo"
        preview-size="xxl"
        default-img-path="photo"
        dialog-img-path="photo"
      />
    </div>
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import getConcatedAddress from './services/getConcatedAddress';
import ScheduleView from './components/ScheduleView';
import ContactsView from './components/ContactsView';
import SalePointOptionsView from './components/SalePointOptionsView';
import LocationSelectMap from '@/lib/map/components/LocationSelectMap';
import FilePreviewList from '@/lib/core/components/FilePreviewList';
import seoHeadMixin from '@/lib/catalog/mixins/seoHeadMixin';
import getSeoBreadcrumbs from '@/lib/catalog/services/getSeoBreadcrumbs';

export default {
  name: 'SalePointPage',

  mixins: [seoHeadMixin],

  components: {
    ContactsView,
    ScheduleView,
    LocationSelectMap,
    SalePointOptionsView,
    FilePreviewList
  },

  async asyncData ({ app, store, params, error }) {
    const rowBreadcrumbs = [];

    try {
      const salePoint = await store.dispatch(
        'cache/get',
        {
          action: 'salePoints/getSalePoint',
          payload: { id: params.id }
        }
      );

      rowBreadcrumbs.push({
        name: 'sale-point',
        label: `${app.i18n.t('salePoint.salePoint')} - ${salePoint.name}`,
        path: `/sale-point/${salePoint.id}`
      });

      const pl = {
        vm: { $store: store, $router: app.router },
        rowBreadcrumbs
      };

      const { breadcrumbs, seoBreadcrumbs } = getSeoBreadcrumbs(pl);

      store.dispatch('setBreadcrumbs', breadcrumbs);

      return {
        pageTitle: salePoint.name,
        salePoint,
        seoBreadcrumbs
      };
    } catch (e) {
      console.error(e);
      error(e);
    }
  },

  computed: {
    photos () {
      return (this.salePoint.photos || []).map(it => ({
        ...it,
        type: 'image'
      }));
    },

    concatAddress () {
      return getConcatedAddress(this.salePoint.address);
    },

    location () {
      const { latitude, longitude } = this.salePoint.address;
      return { lat: latitude, lng: longitude };
    }
  },

  methods: {
    upperFirst
  },

  validate ({ params }) {
    return /^\d+$/.test(params.id);
  }
};
</script>

<style scoped lang="scss">
.label {
  font-weight: bold;
  color: $--color-text-regular;
}
</style>
