<template>
  <div class="sale-point-address pg-1 flex fl-wrap">
    <div class="mr-2 mb-2">
      <div class="el-form-item__label">
        {{ $t('basket.salePointAddress') }}:
      </div>
      <div class="address">
        {{ getConcatedAddress(address, { hideCountry: true }) }}
      </div>
    </div>

    <div v-if="address.latitude && address.longitude">
      <LocationSelectMap
        readonly
        :search-query-string="getConcatedAddress(address)"
        :value="getLocation(address)"
      />
    </div>
  </div>
</template>

<script>
import getConcatedAddress from '@/lib/salePoints/services/getConcatedAddress';
import LocationSelectMap from '@/lib/map/components/LocationSelectMap';

export default {
  name: 'SalePointAddress',

  components: {
    LocationSelectMap
  },

  props: {
    address: { type: Object, required: true }
  },

  methods: {
    getConcatedAddress,

    getLocation (address) {
      const { latitude, longitude } = address;
      return { lat: latitude, lng: longitude };
    }
  }
};
</script>
