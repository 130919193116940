<template>
  <div class="catalog-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ pageTitle }}
    </h1>

    <section class="mb-4">
      <span
        v-if="!$route.query.cm"
        class="custom-headline custom-headline--level2"
      >
        {{ $t("garage.chooseYourCar") }}
      </span>
      <GarageWidget :default-data="garageDefaultData" use-seo-link />
    </section>

    <div class="mb-2">
      <CatalogTree
        v-if="options.catalogTree"
        :dependency="dependency"
        :value="catalog"
      />

      <SearchCatalogTile
        v-if="options.searchCatalogTile"
        :analogs-res="analogsRes"
        :dependency="dependency"
        :trademark="trademark"
      />
    </div>

    <div class="cv">
      <template v-if="options.seoInfo || options.questionAnswer">
        <hr>
        <SeoInfo
          v-if="options.seoInfo"
          class="mb-4"
          :is-show-prices="options.topProductsPrices"
          :meta="meta"
          :title="topProductsPricesTitle"
          :wares="analogsRes.results"
        />

        <QuestionAnswer
          v-if="options.questionAnswer"
          class="mb-2"
          :title="questionAnswerTitle"
          :max-price="analogsRes.max_price"
          :min-price="analogsRes.min_price"
          :wares="analogsRes.results"
          :dependency="dependency"
        />
      </template>

      <template v-if="options.topProductLines">
        <hr>
        <component
          :is="`TopProductLines${isMobile ? 'M' : ''}`"
          class="mb-2"
          :dependency="dependency"
          :title="topProductLinesTitle"
          :value="topProductLines"
        />
      </template>

      <template v-if="options.carsBrandsModels">
        <hr>
        <CarsBrandsModels
          class="mb-2"
          :dependency="dependency"
          :title="topCarsBrandsTitle"
          :value="carsBrands"
        />
      </template>

      <template v-if="options.topCarsBrands">
        <hr>
        <component
          :is="`TopCarsBrands${isMobile ? 'M' : ''}`"
          class="mb-2"
          :dependency="dependency"
          :title="topCarsBrandsTitle"
          :value="topCarsBrands"
        />
      </template>

      <template v-if="options.topCarsModels">
        <hr>
        <component
          :is="`TopCarsModels${isMobile ? 'M' : ''}`"
          class="mb-2"
          :dependency="dependency"
          :title="topCarsModelsTitle"
          :value="topCarsModels"
        />
      </template>

      <template v-if="options.topCarsGenerations">
        <hr>
        <component
          :is="`TopCarsGenerations${isMobile ? 'M' : ''}`"
          class="mb-2"
          :dependency="dependency"
          :title="topCarsGenerationsTitle"
          :value="topCarsGenerations"
        />
      </template>

      <template v-if="options.topCarsModifications">
        <hr>
        <component
          :is="`TopCarsModifications${isMobile ? 'M' : ''}`"
          class="mb-2"
          :dependency="dependency"
          :title="topCarsModificationsTitle"
          :value="topCarsModifications"
        />
      </template>

      <template v-if="options.topTrademarks">
        <hr>
        <component
          :is="`TopTrademarks${isMobile ? 'M' : ''}`"
          class="mb-2"
          :dependency="dependency"
          :title="topTrademarksTitle"
          :value="topTrademarks"
        />
      </template>

      <template v-if="options.topRegions">
        <hr>
        <component
          :is="`TopRegions${isMobile ? 'M' : ''}`"
          class="mb-2"
          :dependency="dependency"
          :title="topRegionsTitle"
          :value="topRegions"
        />
      </template>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';

import seoHeadMixin from './mixins/seoHeadMixin';
import getSeoPageData from './services/getSeoPageData';
import getPageTitle from './services/getPageTitle';
import getParsedSeoProducts from './services/getParsedSeoProducts';
import getBlocksOptions from './services/getBlocksOptions';
import getSeoLinkBy from './services/getSeoLinkBy';

import CatalogTree from './components/CatalogTree';
import SearchCatalogTile from './components/SearchCatalogTile';

import GarageWidget from '@/lib/garage/components/garageWidget/GarageWidget';
import env from '@/lib/core/services/envConstants';
import { getDefault } from '@/lib/core/services/defaultEntities';

const getCarData = data =>
  data?.id
    ? {
        id: data.id,
        name: data.name,
        slug: data.slug
      }
    : null;

export default {
  name: 'CatalogPage',

  mixins: [seoHeadMixin],

  components: {
    /* eslint-disable max-len */
    GarageWidget,
    CatalogTree,
    SearchCatalogTile,

    CarsBrandsModels: () =>
      import(
        /* webpackChunkName: "CarsBrandsModels" */ './components/CarsBrandsModels'
      ),
    TopCarsBrands: () =>
      import(
        /* webpackChunkName: "TopCarsBrands" */ './components/TopCarsBrands'
      ),
    TopCarsBrandsM: () =>
      import(
        /* webpackChunkName: "TopCarsBrandsM" */ './components/TopCarsBrandsM'
      ),
    TopCarsModels: () =>
      import(
        /* webpackChunkName: "TopCarsModels" */ './components/TopCarsModels'
      ),
    TopCarsModelsM: () =>
      import(
        /* webpackChunkName: "TopCarsModelsM" */ './components/TopCarsModelsM'
      ),
    TopCarsGenerations: () =>
      import(
        /* webpackChunkName: "TopCarsGenerations" */ './components/TopCarsGenerations'
      ),
    TopCarsGenerationsM: () =>
      import(
        /* webpackChunkName: "TopCarsGenerationsM" */ './components/TopCarsGenerationsM'
      ),
    TopCarsModifications: () =>
      import(
        /* webpackChunkName: "TopCarsModifications" */ './components/TopCarsModifications'
      ),
    TopCarsModificationsM: () =>
      import(
        /* webpackChunkName: "TopCarsModificationsM" */ './components/TopCarsModificationsM'
      ),
    TopProductLines: () =>
      import(
        /* webpackChunkName: "TopProductLines" */ './components/TopProductLines'
      ),
    TopProductLinesM: () =>
      import(
        /* webpackChunkName: "TopProductLinesM" */ './components/TopProductLinesM'
      ),
    TopTrademarks: () =>
      import(
        /* webpackChunkName: "TopTrademarks" */ './components/TopTrademarks'
      ),
    TopTrademarksM: () =>
      import(
        /* webpackChunkName: "TopTrademarksM" */ './components/TopTrademarksM'
      ),
    TopRegions: () =>
      import(/* webpackChunkName: "TopRegions" */ './components/TopRegions'),
    TopRegionsM: () =>
      import(/* webpackChunkName: "TopRegionsM" */ './components/TopRegionsM'),
    SeoInfo: () =>
      import(/* webpackChunkName: "SeoInfo" */ './components/SeoInfo'),
    QuestionAnswer: () =>
      import(
        /* webpackChunkName: "QuestionAnswer" */ './components/QuestionAnswer'
      )
    /* eslint-enable max-len */
  },

  async asyncData ({ app, store, route, params, query, error, redirect }) {
    const loadTime = Date.now();

    let meta = null;
    let productLine = null;
    let trademark = null;
    let region = null;
    let carsBrands = null;
    let carBrand = null;
    let carModel = null;
    let carGeneration = null;
    let carModification = null;

    let catalog = [];
    let topRegions = [];
    let topProductLines = [];
    let topCarsBrands = [];
    let topCarsModels = [];
    let topCarsGenerations = [];
    let topCarsModifications = [];
    let topTrademarks = [];

    let analogsRes = getDefault('articlesRes');

    const page = Number(params.page || query.page || 1);

    const _searchParams = {
      ...params,
      products_limit: env.SMALL_PAGINATION_SIZE,
      products_offset: env.SMALL_PAGINATION_SIZE * (page - 1),
      products_ordering: query.og || undefined
    };

    delete _searchParams.page;

    if (query.cm) {
      _searchParams.car_modification = Number(query.cm);
    } else if (
      store.getters.isAppLoaded &&
      store.getters['carModifications/carData']
    ) {
      const pl = { id: null, carData: null };
      await store.dispatch('carModifications/setCarData', pl);
    }

    try {
      const data = await store.dispatch('cache/get', {
        action: 'seo/getSeoData',
        payload: _searchParams
      });

      meta = data.meta;
      catalog = data.product_lines || get(data, 'product_line.children', []);
      productLine = !data.product_line
        ? null
        : {
            id: data.product_line.id,
            description: data.product_line.description,
            image_url: data.product_line.image_url,
            name: data.product_line.name,
            slug: data.product_line.slug
          };
      region = data.region;
      trademark = data.trademark;
      carsBrands = data.cars_brands || [];
      carBrand = data.car_brand;
      carModel = data.car_model;
      carGeneration = data.car_generation;
      carModification = data.car_modification;
      topRegions = data.top_regions || [];
      topProductLines = data.top_product_lines || [];
      topCarsBrands = data.top_cars_brands || [];
      topCarsModels = get(
        data,
        'car_brand.top_models',
        data.top_cars_models || []
      );
      topCarsGenerations = get(data, 'car_model.top_generations', []);
      topCarsModifications = get(data, 'car_generation.modifications', []);
      topTrademarks = data.top_trademarks || [];

      if (!isEmpty(data.products)) {
        analogsRes = {
          count: data.products_count || analogsRes.count,
          results: getParsedSeoProducts(data.products || analogsRes.results),
          trademarks: data.products_trademarks || analogsRes.trademarks,
          min_price: data.products_min_price || analogsRes.min_price,
          max_price: data.products_max_price || analogsRes.max_price
        };
      }

      const _page = Math.ceil(data.products_count / env.SMALL_PAGINATION_SIZE);

      if (
        data.products_count &&
        params.page &&
        isEmpty(catalog) &&
        !analogsRes.count &&
        _page !== Number(params.page)
      ) {
        // https://mriyar.atlassian.net/browse/MBP-4307
        const _params = { ...params, page: _page };

        const route = getSeoLinkBy(
          _params,
          { $router: app.router, $store: store },
          _params,
          { page: _page, gclid: query.gclid }
        );

        return redirect(301, route);
      } else if (
        ['lineId', 'carBrand', 'trademarkId'].every(it =>
          route.name.includes(it)
        ) &&
        isEmpty(catalog) &&
        !data.products_count
      ) {
        // It for fix bug with empty category for google
        const _params = { ...params, trademarkId: null };
        const route = getSeoLinkBy(
          _params,
          { $router: app.router, $store: store },
          _params,
          { gclid: query.gclid }
        );

        return redirect(route);
      }

      const vm = { $store: store, $router: app.router };
      const { seoBreadcrumbs, breadcrumbs } = getSeoPageData({ data, vm });
      store.dispatch('setBreadcrumbs', breadcrumbs);

      // spike for task - https://mriyar.atlassian.net/browse/MBP-4215
      if (meta && ((query.tm && query.tm.split(',').length > 1) || query.og)) {
        meta.robots = env.ROBOTS_VALUES;
      }

      return {
        data: store.getters.isDev ? data : '__production__',
        loadTime: Date.now() - loadTime,

        pageTitle: getPageTitle(data, { page: params.page || query.page }),

        meta,
        region,
        trademark,
        productLine,

        catalog,

        carsBrands,
        carBrand,
        carModel,
        carGeneration,
        carModification,

        topRegions,
        topProductLines,
        topCarsBrands,
        topCarsModels,
        topCarsGenerations,
        topCarsModifications,
        topTrademarks,

        analogsRes,

        seoBreadcrumbs,

        garageDefaultData: {
          brand: getCarData(data.car_brand),
          model: getCarData(data.car_model),
          generation: getCarData(data.car_generation),
          car_modification: carModification,

          brands: get(data, 'cars_brands', null),
          models: get(data, 'car_brand.models', null),
          production_years: get(data, 'car_model.production_years', null)
        }
      };
    } catch (e) {
      console.error(e);

      store.dispatch('cache/reset');

      return error({
        statusCode: 404,
        message: e.message || app.i18n.t('errors.404.message')
      });
    }
  },

  computed: {
    isMobile () {
      return this.maxmq('xs', 'mobile');
    },

    options () {
      return getBlocksOptions(this);
    },

    dependency () {
      const { $data, carTitle, $route } = this;
      return { ...$data, carTitle, isCarSelected: !!$route.query.cm };
    },

    carTitle () {
      const brand = this.carBrand?.name || '';
      const model = this.carModel?.name || '';
      const generation = this.carGeneration?.name || '';

      return generation || `${brand} ${model}`.trim();
    },

    catalogTreeTitle () {
      const tl = this.productLine?.name || '';
      const title = this.$t(`catalog.catalog${tl ? 'For' : ''}`);
      const car = this.carTitle
        ? `${this.$t('catalog.for')} ${this.carTitle}`
        : '';

      return `${title} ${tl} ${car}`;
    },

    titlePrefix () {
      const tl = this.productLine?.name || '';
      const tm = this.trademark?.name || '';
      const prefix = `${tl} ${tm}`.trim();
      const on = prefix ? ` ${this.$t('catalog.on')} ` : '';
      const res = `${prefix} ${on} `.trim();
      return res ? `${res} ` : '';
    },

    topCarsBrandsTitle () {
      return upperFirst(
        `${this.titlePrefix}${this.$t('catalog.topCarsBrands')}`
      );
    },

    topCarsModelsTitle () {
      const brand = this.carBrand?.name || '';
      const car = this.$t('catalog.topCarsModels', {
        name: brand || this.$t('catalog.auto')
      });

      return upperFirst(`${this.titlePrefix}${car}`);
    },

    topCarsGenerationsTitle () {
      const brand = this.carBrand?.name || '';
      const model = this.carModel?.name || '';
      const car = this.$t('catalog.topCarsGenerations', {
        name: `${brand} ${model}`
      });

      return upperFirst(`${this.titlePrefix}${car}`);
    },

    topCarsModificationsTitle () {
      const car = this.$t('catalog.topCarsModifications', {
        name: this.carTitle
      });
      return upperFirst(`${this.titlePrefix} ${car}`);
    },

    topProductLinesTitle () {
      const { carTitle } = this;
      return this.$tc('catalog.topProductLines', carTitle ? 2 : 1, {
        name: carTitle
      });
    },

    topTrademarksTitle () {
      const name = this.productLine?.name || '';

      return (
        this.$t('catalog.topTrademarks', { name }) +
        (this.carTitle ? ` ${this.$t('catalog.for')} ${this.carTitle}` : '')
      );
    },

    topRegionsTitle () {
      const name = getPageTitle(this.data, { withoutRegion: true });
      return this.$t('catalog.topRegions', { name });
    },

    topProductsPricesTitle () {
      const tl = this.productLine?.name || '';
      const tm = this.trademark?.name || '';
      const region = this.region?.name || '';

      return `${this.$t('catalog.pricesFor')} ${tl} ${tm} ${region}`;
    },

    questionAnswerTitle () {
      const tl = this.productLine?.name || '';
      const tm = this.trademark?.name || '';

      return `${tl} ${tm} ${tl || tm ? ' - ' : ''} ${this.$t(
        'questions.title'
      )}`.trim();
    }
  },

  mounted () {
    console.info(`LOAD_TIME: ${this.loadTime}ms`, this.$data);
  },

  methods: {
    get,
    isEmpty
  }
};
</script>

<style scoped lang="scss">
.catalog-page {
  hr {
    margin-bottom: 20px;
  }

  ::v-deep {
    .mr-mobile-block {
      .mr-list {
        display: block;
      }

      .mr-list__item {
        min-width: unset;
        display: block;
      }
    }
  }

  @media all and (max-width: $--md) {
    ::v-deep {
      h1.custom-headline {
        font-size: 1rem;
      }

      h2.custom-headline {
        font-size: 1rem;
      }
    }
  }
}
</style>
