var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      class: [
        "open-street-map",
        "search-point-map",
        { readonly: _vm.readonly },
      ],
    },
    [
      _c("div", {
        staticClass: "map",
        attrs: { id: _vm.mapId },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      }),
      _vm._v(" "),
      _vm.showInputs
        ? _c("GeoInputs", {
            attrs: { value: _vm.center },
            on: { input: _vm.emit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }