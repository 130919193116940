import get from 'lodash/get';
import dayjs from 'dayjs';
import env from '@/lib/core/services/envConstants';

const getCondition = ware => ware.other_selling_terms?.includes('former_use')
  ? 'https://schema.org/UsedCondition'
  : 'https://schema.org/NewCondition';

export default ({ route, ware }) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: get(ware, 'meta.h1', ''),
    image: get(ware, 'images[0].url', ''),
    sku: ware.article,
    mpn: ware.article,
    model: ware.article,
    description: get(ware, 'meta.description'),
    offers: {
      '@type': 'Offer',
      priceCurrency: env.MARKETPLACE_CURRENCY,
      url: `${env.BASE_CLIENT_PATH}${route.fullPath}`,
      price: ware.price,
      priceValidUntil: dayjs(new Date()).add(1, 'day').format('YYYY-MM-DD'),
      itemCondition: getCondition(ware),
      availability: 'https://schema.org/InStock',
      seller: {
        '@type': 'Organization',
        name: ware._seller
      }
    },
    brand: {
      '@type': 'brand',
      name: get(ware, 'trademark.name', '')
    }
  };
};
