var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "anchors-nav" }, [
    _c("div", {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.togglePosition,
          expression: "togglePosition",
        },
      ],
    }),
    _vm._v(" "),
    _c(
      "nav",
      { ref: "nav", class: ["nav scroll", { fixed: _vm.isFixed }] },
      _vm._l(_vm.value, function (id) {
        return _c(
          "div",
          {
            key: id,
            staticClass: "mr-scroll-btn nav__item",
            attrs: { "data-scroll": id },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t(_vm.entity + "." + id)) + "\n    "
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }