<template>
  <div class="order-delivery">
    <div class="delivery-method">
      <div class="mr-2">
        <el-form-item
          :label="$t('basket.delivery_method')"
          prop="delivery_method"
        >
          <el-radio-group
            :value="deliveryMethod"
            @input="toggleDeliveryMethod"
          >
            <el-radio
              v-for="method in deliveryMethods"
              :key="method.key"
              :label="method.key"
            >
              {{ method.title }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div v-if="componentInfo">
        <component
          :is="componentInfo.component"
          ref="component"
          class="mb-2"
          :set-field="setField"
          :value="value.delivery_address"
          :type="value.delivery_address.type"
          :dependency="{ ...dependency, addresses: addressesByType }"
          v-bind="componentInfo.attrs"
        />
      </div>
    </div>

    <div v-if="componentInfo && componentInfo.isRecipient">
      <div class="mb-2 bold">{{ $t('basket.waybillTitle') }}</div>

      <div class="mb-2 fl-wrap">
        <el-form-item
          :label="$t('basket.waybill_date')"
          prop="waybill_date"
          class="mr-1"
        >
          <el-date-picker
            type="date"
            :value="value.waybill_date"
            @input="setField('waybill_date', $event)"
          />
        </el-form-item>

        <el-form-item :label="$t('basket.waybillNum')" prop="waybill">
          <el-input
            :value="value.waybill"
            @input="setField('waybill', $event)"
          />
        </el-form-item>
      </div>

      <div class="mb-2 bold">{{ $t('basket.orderRecipient') }}</div>

      <OrderRecipient
        ref="recipient"
        :value="value"
        :recipients="recipients"
        :set-field="setField"
        :dependency="dependency"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import OrderDelivery from './OrderDelivery';
import { getDefault } from '@/lib/core';
import getConcatedRecipientLabel from '@/lib/orders/services/getConcatedRecipientLabel';

export default {
  name: 'ShipmentOrderDelivery',

  extends: OrderDelivery,

  components: {
    /* eslint-disable max-len */
    ElDatePicker: () => import(/* webpackChunkName: "ElDatePicker" */ 'element-ui/lib/date-picker')
    /* eslint-enable max-len */
  },

  data () {
    return {
      forceLoad: true
    };
  },

  computed: {
    city () {
      return this.dependency.order?.delivery_address?.city_detail;
    }
  },

  methods: {
    loadAddresses () {
      const address = this.dependency.order?.delivery_address;
      this.addresses[address?.type] = this.addresses[address?.type] || [];
      this.addresses[address?.type].push(address);
    },

    loadRecipients () {
      const {
        recipient_first_name,
        recipient_last_name,
        recipient_phone_number
      } = this.dependency.order || {};

      let phone = null;

      if (recipient_phone_number?.phone_number) {
        phone = cloneDeep(recipient_phone_number);
      } else {
        phone = getDefault('phone');
      }

      const _val = {
        first_name: recipient_first_name,
        last_name: recipient_last_name,
        phone_number: cloneDeep(phone)
      };

      this.recipients.push({
        ..._val,
        _label: getConcatedRecipientLabel(_val)
      });
    },

    setDefaultRecipient () {
      const {
        recipient_first_name,
        recipient_last_name,
        recipient_phone_number
      } = this.dependency.order || {};

      let phone = null;

      if (recipient_phone_number?.phone_number) {
        phone = cloneDeep(recipient_phone_number);
      } else {
        phone = getDefault('phone');
      }

      this.setField('recipient_first_name', recipient_first_name || '');
      this.setField('recipient_last_name', recipient_last_name || '');
      this.setField('recipient_phone_number', phone);
    }
  }
};
</script>
