<template>
  <div class="search-filter">
    <el-form
      v-model="formData"
      class="filter"
      label-position="top"
      size="mini"
      @submit.native.prevent="submit()"
    >
      <el-form-item prop="trademarks">
        <TrademarkFilter
          v-model="formData.trademarks"
          :trademarks="trademarks"
          @clear="clearTrademarks"
        />
      </el-form-item>

      <el-form-item :label="$t('labels.mains')">
        <div class="checkbox-group">
          <el-checkbox
            :key="key"
            :checked="formData.onlySelectArticle"
            @change="formData.onlySelectArticle = $event"
          >
            {{ $t("labels.exactArticle") }}
          </el-checkbox>

          <el-checkbox
            :value="formData.onlyCity"
            @change="formData.onlyCity = $event"
          >
            {{ $t("labels.localOffers") }}
          </el-checkbox>

          <el-checkbox v-model="formData.in_stock">
            {{ $t("labels.in_stock") }}
          </el-checkbox>

          <el-checkbox v-model="formData.is_official_trademark">
            {{ $t("labels.is_official_trademark") }}
          </el-checkbox>
        </div>
      </el-form-item>

      <el-form-item :label="$t('labels.priceGrn')">
        <el-slider
          :key="key + 1"
          :max="price_lte"
          :min="price_gte"
          range
          :value="price"
          @input="setPrice"
        />
        <el-row :gutter="80">
          <el-col>
            <el-input
              class="price-input"
              placeholder="min"
              :value="formData.price_gte"
              @input="setMin('price', $event)"
            />
          </el-col>
          <el-col>
            <el-input
              class="price-input"
              placeholder="max"
              :value="formData.price_lte"
              @input="setMax('price', $event)"
            />
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$t('labels.delivery_time')">
        <el-slider
          :key="key + 2"
          :max="delivery_time_lte"
          :min="delivery_time_gte"
          range
          :value="delivery"
          @input="setDelivery"
        />

        <el-row :gutter="80">
          <el-col>
            <el-input
              class="delivery-input"
              placeholder="min"
              :value="formData.delivery_time_gte"
              @input="setMin('delivery_time', $event)"
            />
          </el-col>
          <el-col>
            <el-input
              class="delivery-input"
              placeholder="max"
              :value="formData.delivery_time_lte"
              @input="setMax('delivery_time', $event)"
            />
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$t('labels.payment_methods')" prop="payment_methods">
        <el-checkbox-group
          v-model="formData.payment_methods"
          class="cell"
        >
          <el-checkbox
            v-for="term in paymentMethods"
            :key="term.id"
            :label="term.label"
          >
            {{ $t(`payment_methods.${term.label}`) }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item :label="$t('labels.delivery_methods')" prop="delivery_methods">
        <el-checkbox-group
          v-model="formData.delivery_methods"
          class="cell"
        >
          <el-checkbox
            v-for="term in deliveryMethods"
            :key="term.id"
            :label="term.label"
          >
            {{ $t(`delivery_methods.${term.label}`) }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item :label="$t('labels.warrantedAndReturn')">
        <div class="checkbox-group">
          <el-checkbox v-model="formData.is_warranted">
            {{ $t("labels.is_warranted") }}
          </el-checkbox>
          <el-checkbox v-model="formData.is_return_possible">
            {{ $t("labels.is_return_possible") }}
          </el-checkbox>
        </div>
      </el-form-item>

      <el-form-item
        v-if="otherSellingTerms.length"
        :label="$t('labels.other_selling_terms')"
        prop="other_selling_terms"
      >
        <el-checkbox-group
          v-model="formData.other_selling_terms"
          class="cell"
        >
          <el-checkbox
            v-for="term in otherSellingTerms"
            :key="term.id"
            :label="term.label"
          >
            {{ $t(`other_selling_terms.${term.label}`) }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item :label="$t('labels.payment_terms')" prop="payment_terms">
        <el-checkbox-group
          v-model="formData.payment_terms"
          class="cell"
        >
          <el-checkbox
            v-for="term in paymentTerms"
            :key="term.id"
            :label="term.label"
          >
            {{ $t(`payment_terms.${term.label}`) }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item :label="$t('labels.sale_activities')" prop="sale_activities">
        <el-checkbox-group
          v-model="formData.sale_activities"
          class="cell"
        >
          <el-checkbox
            v-for="term in sale_activities"
            :key="term.id"
            :label="term.label"
          >
            {{ $t(`sale_activities.${term.label}`) }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <vue-position-sticky v-if="isVisible" ref="buttons" :offset-bottom="0">
        <el-form-item class="buttons">
          <el-row justify="space-between">
            <el-button size="medium" @click="clear">
              {{ $t("buttons.clear") }}
            </el-button>
            <el-button
              size="medium"
              native-type="submit"
              type="primary"
              @click="submit"
            >
              {{ $t("buttons.apply") }}
            </el-button>
          </el-row>
        </el-form-item>
      </vue-position-sticky>
    </el-form>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import ElSlider from 'element-ui/lib/slider';

import { mapGetters } from 'vuex';
import { getSearchFilterByRoute, getSearchFilterForRoute } from '../services/searchFilter';
import TrademarkFilter from './TrademarkFilter';
import { parsers, eventBus, getDefault, env } from '@/lib/core';

const getFilteredList = (list, fields = []) => list
  .filter(({ label, in_price_search }) => {
    return in_price_search && !fields.includes(label);
  });

export default {
  name: 'SearchFilter',

  components: {
    ElSlider,
    TrademarkFilter
  },

  props: {
    isVisible: Boolean,
    params: Object,
    isManual: Boolean
  },

  data ({ $route }) {
    const defaultSearchFilter = getDefault('searchFilter');

    return {
      key: 0,
      isLoaded: false,
      trademarks: [],
      price_gte: defaultSearchFilter.price_gte,
      price_lte: defaultSearchFilter.price_lte,
      delivery_time_gte: defaultSearchFilter.delivery_time_gte,
      delivery_time_lte: defaultSearchFilter.delivery_time_lte,
      formData: getSearchFilterByRoute($route.query)
    };
  },

  computed: {
    ...mapGetters({
      sale_activities: 'dependencies/saleActivities',
      payment_terms: 'dependencies/paymentTerms',
      delivery_methods: 'dependencies/deliveryMethods',
      payment_methods: 'dependencies/paymentMethods',
      other_selling_terms: 'dependencies/otherSellingTerms'
    }),

    paymentTerms () {
      return getFilteredList(this.payment_terms);
    },

    deliveryMethods () {
      return getFilteredList(this.delivery_methods);
    },

    paymentMethods () {
      return getFilteredList(this.payment_methods);
    },

    otherSellingTerms () {
      return getFilteredList(
        this.other_selling_terms,
        ['warranted', 'return_possible']
      );
    },

    price () {
      return [this.formData.price_gte, this.formData.price_lte];
    },

    delivery () {
      return [this.formData.delivery_time_gte, this.formData.delivery_time_lte];
    }
  },

  watch: {
    formData: {
      deep: true,
      handler () {
        if (this.isLoaded) {
          this.autoSubmit();
        }
      }
    }
  },

  created () {
    if (this.params) {
      this.$watch(() => this.params, (params) => {
        this.setFilterFromParams(params);
      }, { deep: true, immediate: true });
    }

    this.submit = debounce(this.submit, env.DEBOUNCE);
    this.autoSubmit = debounce(this.autoSubmit, env.FILTER_AUTO_SUBMIT_DEBOUNCE);

    eventBus.$on('filter:update:res-params', this.setFilterFromParams);
    eventBus.$on('filter:part:update', this.setFilterPart);
    eventBus.$on('filter:reload-page', this.reloadPage);
  },

  beforeDestroy () {
    eventBus.$off('filter:update:res-params', this.setFilterFromParams);
    eventBus.$off('filter:part:update', this.setFilterPart);
    eventBus.$off('filter:reload-page', this.reloadPage);
  },

  methods: {
    autoSubmit () {
      if (!this.isManual) {
        return this.submit();
      }
    },

    setFilterPart (filterPart) {
      Object.assign(this.formData, filterPart);

      this.submit();
    },

    setFilterFromParams (params) {
      if (!params) {
        return null;
      }

      this.isLoaded = false;

      const {
        trademarks,
        min_price,
        max_price,
        min_delivery_time,
        max_delivery_time
      } = params;

      this.trademarks = uniqBy(this.trademarks.concat(trademarks), 'id');

      if (min_price) {
        this.price_gte = Math.floor(min_price ? min_price / env.PRICE_GAP : 0);
      }

      if (max_price) {
        this.price_lte = Math.ceil(max_price * env.PRICE_GAP);
      }

      this.setPrice([this.price_gte, this.price_lte]);

      const minDay = parsers.getDayHourBy(min_delivery_time).days;
      const maxDays = parsers.getDayHourBy(max_delivery_time).days;

      if (minDay || maxDays) {
        this.delivery_time_gte = minDay;
        this.delivery_time_lte = maxDays;

        this.setDelivery([minDay, maxDays]);
      }

      this.key += 1;

      this.$nextTick(() => {
        this.isLoaded = true;
      });
    },

    submit ({ clearQuery } = {}) {
      this.isLoaded = false;

      this.autoSubmit.cancel && this.autoSubmit.cancel();

      const { wareId, searchWareId, trademark, article, cm, tab } = this.$route.query;

      const filter = clearQuery
        ? {}
        : this.getFilterFrom(this.formData);

      const page = 1;

      const routeParams = {
        query: { wareId, searchWareId, trademark, article, cm, tab, ...filter },
        params: { ...this.$route.params, page }
      };

      this.reloadPage(routeParams);

      this.isLoaded = true;

      this.$emit('submitted');
    },

    reloadPage (route = this.$route) {
      return this.$router.push(route)
        .catch(console.error)
        .finally(() => eventBus.$emit('filter:updated'));
    },

    getFilterFrom (formData = this.formData) {
      const _formData = cloneDeep(formData);
      const clearList = ['price', 'delivery_time'];

      clearList.forEach((key) => {
        if (this[`${key}_gte`] === _formData[`${key}_gte`]) {
          delete _formData[`${key}_gte`];
        }

        if (this[`${key}_lte`] === _formData[`${key}_lte`]) {
          delete _formData[`${key}_lte`];
        }
      });

      return getSearchFilterForRoute(_formData);
    },

    clear () {
      this.formData = getDefault('searchFilter');
      this.key += 1;
      this.submit({ clearQuery: true });
    },

    setPrice ([price_gte, price_lte]) {
      this.formData.price_gte = price_gte;
      this.formData.price_lte = price_lte;
    },

    setDelivery ([delivery_time_gte, delivery_time_lte]) {
      this.formData.delivery_time_gte = delivery_time_gte;
      this.formData.delivery_time_lte = delivery_time_lte;
    },

    setMin (name, value) {
      const int = Number.parseInt(value || 0);

      if (int <= this.formData[`${name}_lte`]) {
        this.formData[`${name}_gte`] = int;
      } else {
        this.formData[`${name}_gte`] = this.formData[`${name}_lte`];
      }
    },

    setMax (name, value) {
      const int = Number.parseInt(value);
      const min = this.formData[`${name}_gte`];

      if (int >= min) {
        if (int > this[`${name}_lte`]) {
          this[`${name}_lte`] = int;
        }

        this.formData[`${name}_lte`] = int;
      } else {
        this.formData[`${name}_lte`] = this.formData[`${name}_gte`];
      }
    },

    clearTrademarks () {
      this.formData.trademarks = [];
      this.submit();
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/search-filter.scss";
</style>
