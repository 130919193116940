<template>
  <el-card class="resend-activation-form">
    <nuxt-link class="close-icon" :to="{ name: getRouteName('auth') }">
      <i class="el-icon-close" />
    </nuxt-link>
    <span class="custom-headline custom-headline--level2-bold">
      {{ $t('auth.resendActivate') }}
    </span>

    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      size="large"
      @submit.native.prevent="submit"
    >
      <el-form-item prop="email">
        <el-input v-model.trim="formData.email" :placeholder="$t('placeholders.email')" />
      </el-form-item>

      <el-form-item>
        <el-button
          class="submit-btn"
          :loading="isLoading"
          native-type="submit"
          type="primary"
        >
          {{ $t('buttons.send') }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { getValidationFor } from '@/lib/core';

export default {
  name: 'ResendActivationForm',

  data ({ $route }) {
    return {
      isLoading: false,
      rules: getValidationFor('email'),
      formData: {
        email: $route.query.email || ''
      }
    };
  },

  methods: {
    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          return false;
        }

        this.isLoading = true;

        try {
          await this.$store.dispatch(
            'users/postResendActivationKey',
            this.formData
          );

          this.$notify.info({ message: this.$t('message.checkEmail') });
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/auth-form.scss";
</style>
