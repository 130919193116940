<template>
  <el-breadcrumb
    v-if="isCustomMeta"
    v-show="breadcrumbs.length"
    ref="breadcrumb"
    class="breadcrumb scroll"
    separator-class="el-icon-arrow-right"
  >
    <el-breadcrumb-item
      v-for="(crumb, index) in breadcrumbs"
      :id="`breadcrumb_${index + 1}`"
      :key="`breadcrumb_${index + 1}`"
      :title="crumb.label || $t(`routes.${crumb.name}`)"
    >
      <nuxt-link v-if="index + 1 !== breadcrumbs.length" :to="crumb.path">
        {{ crumb.label || $t(`routes.${crumb.name}`) }}
      </nuxt-link>

      <span v-else>
        {{ crumb.label || $t(`routes.${crumb.name}`) }}
      </span>
    </el-breadcrumb-item>
  </el-breadcrumb>

  <el-breadcrumb
    v-else-if="canShow"
    ref="breadcrumb"
    class="breadcrumb"
    separator-class="el-icon-arrow-right"
  >
    <el-breadcrumb-item
      v-for="crumb in listCrumbs"
      :key="crumb.name || crumb.label || crumb.fullName"
    >
      <a @click="navigate(crumb)">
        {{ crumb.fullName ? crumb.label : $t(`routes.${crumb.label}`) }}
      </a>
    </el-breadcrumb-item>
  </el-breadcrumb>

  <div v-else class="pg-1" />
</template>

<script>
import ElBreadcrumb from 'element-ui/lib/breadcrumb';
import ElBreadcrumbItem from 'element-ui/lib/breadcrumb-item';
import getSeoLinkBy from '../../catalog/services/getSeoLinkBy';

const addHomeFor = ['garage'];
const filteredPages = {
  'basket.orders': ['basket']
};

export default {
  name: 'Breadcrumb',

  components: {
    ElBreadcrumb,
    ElBreadcrumbItem
  },

  computed: {
    breadcrumbs () {
      return this.$store.getters.breadcrumbs || [];
    },

    isCustomMeta () {
      const { meta } = this.$route;

      return meta?.seoName || meta?.useCustomBreadcrumb;
    },

    canShow () {
      return (
        this.listCrumbs.length &&
        this.listCrumbs.every(({ label }) => this.$t(`routes.${label}`) !== `routes.${label}`)
      );
    },

    listCrumbs () {
      const parts = this.$route.name
        ? this.$route.name.split('.')
        : [];

      const source = addHomeFor.some(it => this.$route.name?.startsWith(it))
        ? [{
            ...getSeoLinkBy({}, this, {}),
            label: 'home'
          }]
        : [];

      return parts.reduce((acc, name, index) => {
        acc.push({
          name,
          routeName: parts.slice(0, index + 1).join('.'),
          label: parts.slice(0, index + 1)
            .join('_')
            .replace(/-/g, '_')
            .replace(`__${this.$store.getters.langCode}`, '')
        });

        return acc;
      }, source).filter(this.filterByPageName);
    }
  },

  watch: {
    breadcrumbs: 'scrollToEnd'
  },

  mounted () {
    this.scrollToEnd();
  },

  methods: {
    scrollToEnd () {
      this.$nextTick(() => {
        if (typeof this.$refs?.breadcrumb?.$el?.scroll === 'function') {
          this.$refs?.breadcrumb?.$el?.scroll(2000, 0);
        }
      });
    },

    navigate (crumb) {
      const params = {};

      if (crumb.path) {
        params.path = crumb.path;
      } else {
        params.name = crumb.routeName;
      }

      this.$router.push(params).catch(e => e);
    },

    filterByPageName (breadcrumb) {
      const [name] = (this.$route.name || '').split('__');
      const filterList = filteredPages[name];

      if (filterList) {
        const [brName] = breadcrumb.name.split('__');
        return !filterList.includes(brName);
      }

      return true;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/theme/breadcrumb.scss";
@import "@/styles/theme/breadcrumb-item.scss";
</style>

<style scoped lang="scss">
.el-breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px 0;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  ::v-deep {
    .el-breadcrumb__item {
      display: flex;
      flex-wrap: nowrap;
    }

    .el-breadcrumb__inner {
      white-space: nowrap;
    }
  }

  @media all and (max-width: $--xs) {
    padding: 10px;
  }
}
</style>
