<template>
  <div class="business-list-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business') }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'BusinessListPage',

  created () {
    const id = this.$store.getters['businessProfiles/businessId'];

    if (id) {
      return this.$router.replace({
        name: this.getRouteName('profile.business.edit')
      });
    }

    this.$nuxt.error({ statusCode: 404 });
  }
};
</script>
