var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-response-form" },
    [
      _c("div", { staticClass: "mb-2 req-item" }, [
        _c("div", { staticClass: "mb-1 bold" }, [
          _vm._v(_vm._s(_vm.value.raw_item)),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.value.comment))]),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-position": "left",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "form-row" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "article mr-2",
                  attrs: { label: _vm.$t("search.article"), prop: "article" },
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "article",
                      attrs: {
                        remote: "",
                        filterable: "",
                        "default-first-option": "",
                        value: _vm.formData.article,
                        "remote-method": _vm.searchWares,
                        loading: _vm.isVLoading,
                      },
                      on: { change: _vm.onSetArticle },
                    },
                    _vm._l(_vm.brandsOptions, function (option) {
                      return _c(
                        "el-option",
                        {
                          key: option._id,
                          class: [
                            "search-article__option",
                            { custom: option._id === "custom" },
                          ],
                          attrs: { value: option, label: option.name },
                        },
                        [
                          option._id !== "custom"
                            ? _c("el-row", [
                                _c("div", { staticClass: "trademark bold" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(option.origin.display_trademark) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "article" }, [
                                  _vm._v(_vm._s(option.origin.display_article)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(option.origin.display_title)),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "trademark mr-2",
                  attrs: { prop: "trademark", label: _vm.$t("ware.trademark") },
                },
                [
                  _c("el-input", {
                    ref: "trademark",
                    attrs: { disabled: _vm.readonly },
                    model: {
                      value: _vm.formData.trademark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "trademark", $$v)
                      },
                      expression: "formData.trademark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "name",
                  attrs: { prop: "name", label: _vm.$t("search.name") },
                },
                [
                  _c("el-input", {
                    ref: "name",
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "form-row" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "mr-2 price",
                  attrs: {
                    prop: "price",
                    label: _vm.$t("sRequests.price", {
                      val: _vm.MARKETPLACE_CURRENCY,
                    }),
                  },
                },
                [
                  _c("el-input", {
                    ref: "price",
                    attrs: {
                      disabled: !!_vm.formData.offer_id,
                      value: _vm.formData.price,
                    },
                    on: {
                      input: function ($event) {
                        _vm.formData.price = ($event || "").replace(/,/g, ".")
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mr-2 delivery-time",
                  attrs: {
                    label: _vm.$t("sRequests.delivery_time"),
                    prop: "delivery_time",
                  },
                },
                [
                  _c("el-input", {
                    ref: "delivery_time",
                    staticClass: "delivery-time",
                    attrs: {
                      disabled: !!_vm.formData.offer_id,
                      value: _vm.deliveryTime,
                    },
                    on: { input: _vm.setDeliveryTime },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mr-2 is-former-use",
                  attrs: { prop: "is_former_use" },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formData.is_former_use,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "is_former_use", $$v)
                        },
                        expression: "formData.is_former_use",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("ware.is_former_use")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "comment-private",
                  attrs: {
                    prop: "comment_private",
                    label: _vm.$t("sRequests.comment_private"),
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formData.comment_private,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "comment_private", $$v)
                      },
                      expression: "formData.comment_private",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "meta" }, [
                    _c("small", [
                      _vm._v(_vm._s(_vm.$t("sRequests.commentPrivateDesc"))),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("sRequests.comment_public"),
                prop: "comment_public",
              },
            },
            [
              _c("el-input", {
                ref: "comment_public",
                attrs: { type: "textarea" },
                model: {
                  value: _vm.formData.comment_public,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "comment_public", $$v)
                  },
                  expression: "formData.comment_public",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "mt-4", attrs: { justify: "end" } },
            [
              _c("el-button", { on: { click: _vm.confirmCancel } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("buttons.cancel")) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary", "native-type": "submit" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("buttons.addResponse")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isWareInfoId,
              expression: "isWareInfoId",
            },
          ],
          model: {
            value: _vm.isWareInfoId,
            callback: function ($$v) {
              _vm.isWareInfoId = $$v
            },
            expression: "isWareInfoId",
          },
        },
        [
          _c("WareInfo", {
            attrs: { id: _vm.isWareInfoId },
            on: {
              cancel: function ($event) {
                _vm.isWareInfoId = null
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isWareInfoId,
              expression: "!isWareInfoId",
            },
          ],
          attrs: {
            title: _vm.$t("sRequests.selectWareFromPrises"),
            width: "1100px",
          },
          model: {
            value: _vm.isSearchArticles,
            callback: function ($$v) {
              _vm.isSearchArticles = $$v
            },
            expression: "isSearchArticles",
          },
        },
        [
          _vm.isSearchArticles
            ? _c("SResponseSearchWares", {
                staticClass: "mb-4",
                attrs: { value: _vm.isSearchArticles },
                on: {
                  select: _vm.setDataFromWare,
                  cancel: function ($event) {
                    _vm.isSearchArticles = null
                  },
                  "info:show": _vm.showWareInfo,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { justify: "end" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isSearchArticles = null
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("buttons.noPropositionUseCustom")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }