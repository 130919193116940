import {
  email,
  phone_number,
  requiredNumber,
  requiredString,
  requiredStringChange
} from './common';
import { i18n } from '@/plugins/i18n';

export default {
  first_name: [requiredString],
  last_name: [requiredString],
  middle_name: [requiredString],
  official_name: [requiredString],
  short_name: [requiredString],
  udrpou_page_1: [requiredStringChange],
  udrpou_page_2: [requiredStringChange],
  itn_of_vat_payer_page: [requiredStringChange],
  type: [requiredStringChange],
  is_vat_payer: [requiredStringChange],
  signatory_full_name: [requiredString],
  itn_of_vat_payer: [
    requiredNumber,
    { max: 20, message: i18n.t('validation.simple.max', { num: 20 }), trigger: 'blur' }
  ],
  itn: [
    requiredNumber,
    { max: 20, message: i18n.t('validation.simple.max', { num: 20 }), trigger: 'blur' }
  ],
  udrpou: [
    requiredNumber,
    { max: 15, message: i18n.t('validation.simple.max', { num: 15 }), trigger: 'blur' }
  ],
  'email.email': [
    ...email,
    { max: 50, message: i18n.t('validation.simple.max', { num: 50 }), trigger: 'blur' }
  ],
  'phone_number.phone_number': [
    ...phone_number,
    { max: 20, message: i18n.t('validation.simple.max', { num: 20 }), trigger: 'blur' }
  ],
  'bank_requisites.name': [requiredString],
  'bank_requisites.bic': [
    requiredNumber,
    { max: 20, message: i18n.t('validation.simple.max', { num: 20 }), trigger: 'blur' }
  ],
  'bank_requisites.iban_page': [{
    required: true, message: i18n.t('validation.simple.required'), trigger: 'change'
  }],
  'bank_requisites.iban': [
    requiredString,
    { max: 34, message: i18n.t('validation.simple.max', { num: 34 }), trigger: 'blur' }
  ]
};
