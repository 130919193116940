var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header-m" },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "row-1" },
          [
            _c(
              "div",
              { staticClass: "fl-al-center" },
              [
                _c(
                  "div",
                  {
                    staticClass: "hamburger",
                    on: {
                      click: function ($event) {
                        _vm.isShowMenu = true
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" },
                      ],
                      staticClass: "icon-img",
                      attrs: {
                        alt: "menu",
                        src: require("@/assets/icons/hamburger.svg"),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("LangLinks"),
              ],
              1
            ),
            _vm._v(" "),
            _c("UserNav", {
              attrs: { "show-basket": "", "hide-list": ["lang"] },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-2" },
          [_c("TheLogo", { staticClass: "pg-1" })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row-3" },
          [_c("CleverSearch", { attrs: { row: "", size: "mini" } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c("BusinessMenuShort"),
      _vm._v(" "),
      _c("Breadcrumb"),
      _vm._v(" "),
      _vm.$store.getters.isDomReadyState
        ? _c(
            "TheDialog",
            {
              model: {
                value: _vm.isShowMenu,
                callback: function ($$v) {
                  _vm.isShowMenu = $$v
                },
                expression: "isShowMenu",
              },
            },
            [
              _c("div", { staticClass: "dialog-wrapper" }, [
                _c("ul", [
                  _c(
                    "li",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "color-label",
                          attrs: {
                            to: {
                              name: _vm.getRouteName("docs"),
                              params: { id: "payment" },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("core.marketplacePayment")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "color-label",
                          attrs: {
                            to: {
                              name: _vm.getRouteName("docs"),
                              params: { id: "delivery" },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("core.marketplaceDelivery")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "color-label",
                          attrs: {
                            to: {
                              name: _vm.getRouteName("docs"),
                              params: { id: "warranty" },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("core.marketplaceWarranty")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "color-label",
                          attrs: {
                            to: {
                              name: _vm.getRouteName("docs"),
                              params: { id: "about-us" },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("routes.about_us")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }