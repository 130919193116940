var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-request-processing-items-table" },
    _vm._l(_vm.value, function (reqItem) {
      return _c(
        "div",
        { key: reqItem.id, staticClass: "item-block mb-2" },
        [
          _c(
            "el-row",
            { staticClass: "req__item", attrs: { justify: "space-between" } },
            [
              _c("div", [
                _c("div", { staticClass: "mb-1 bold" }, [
                  _vm._v(_vm._s(reqItem.raw_item)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(reqItem.comment))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("add-item", reqItem)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("buttons.addWare")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "responses" }, [
            _c("table", { staticClass: "mr-table" }, [
              _c(
                "tbody",
                _vm._l(reqItem.selection_responses, function (row, index) {
                  return _c(
                    "tr",
                    {
                      key:
                        "" +
                        index +
                        row.id +
                        row.article +
                        row.trademark +
                        row.price,
                      staticClass: "mr-table-body-row res__item",
                    },
                    [
                      _c("el-row", [
                        _c(
                          "td",
                          { staticClass: "mr-table-body-col" },
                          [
                            _c("BlockSize", {
                              attrs: { src: row.ware_image_url },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "mr-table-body-col" },
                          [
                            _c("el-row", { staticClass: "mb-1" }, [
                              _c("div", { staticClass: "mr-2 bold" }, [
                                _vm._v(_vm._s(row.trademark)),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(row.article || "********")),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("small", [_vm._v(_vm._s(row.comment_public))]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "mr-table-body-col" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(row.name) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "mr-table-body-col" },
                          [
                            _c("DeliveryTimeString", {
                              attrs: {
                                value: row.delivery_time,
                                options: { suffix: true },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "mr-table-body-col" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.getPriceWithCurrency(row.price)) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "mr-table-body-col" },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-button", {
                                  staticClass: "mr-1",
                                  attrs: { circle: "", icon: "el-icon-edit" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("edit", {
                                        item: reqItem,
                                        row: row,
                                      })
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    circle: "",
                                    icon: "el-icon-delete",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("remove", {
                                        item: reqItem,
                                        row: row,
                                      })
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isCommentPrivate && row.comment_private
                        ? _c("el-row", { staticClass: "comment-private" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(row.comment_private) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }