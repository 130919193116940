<template>
  <div class="page-header">
    <div class="wrapper">
      <nav class="row-1">
        <ul class="links pg-0-1">
          <li>
            <nuxt-link
              class="color-label"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'payment' }
              }"
            >
              {{ $t('core.marketplacePayment') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              class="color-label"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'delivery' }
              }"
            >
              {{ $t('core.marketplaceDelivery') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              class="color-label"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'warranty' }
              }"
            >
              {{ $t('core.marketplaceWarranty') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              class="color-label"
              :to="{
                name: getRouteName('docs'),
                params: { id: 'about-us' }
              }"
            >
              {{ $t('routes.about_us') }}
            </nuxt-link>
          </li>

          <!--          <li>-->
          <!--            <el-link class="color-label mr-05" @click="isContacts = !isContacts">-->
          <!--              {{ $t('core.contacts') }}-->
          <!--            </el-link>-->
          <!--          </li>-->

          <li><LangLinks class="mr-05" /></li>

          <li v-if="!$store.getters['businessProfiles/businessProfiles'].length">
            <el-button
              type="success"
              class="btn-create-business"
              @click="$router.push({
                name: getRouteName('profile.business.create')
              })"
            >
              {{ $t('buttons.createBusiness') }}
            </el-button>
          </li>
        </ul>

        <UserNav />
      </nav>

      <div class="row-2">
        <div class="left-block">
          <TheLogo />
          <!--          <MarketplacePhones v-if="isMriyar" />-->
        </div>

        <div class="center-block">
          <CleverSearch />
        </div>

        <div class="right-block">
          <!--          <MarketplaceContacts v-if="isMriyar" :col="isMobile" />-->
          <BasketIcon />
        </div>
      </div>

      <div class="row-3">
        <CleverSearch row />
      </div>
    </div>

    <BusinessMenuShort />
    <Breadcrumb />

    <!--    <TheDialog-->
    <!--      v-model="isContacts"-->
    <!--      :title="$t('core.contacts')"-->
    <!--      width="400px"-->
    <!--    >-->
    <!--      <div v-if="isContacts">-->
    <!--        <MarketplacePhones class="mb-2" />-->
    <!--        <MarketplaceContacts col use-hr class="color-label" />-->
    <!--      </div>-->
    <!--    </TheDialog>-->
  </div>
</template>

<script>
import env from '../services/envConstants';
import UserNav from './UserNav';
import TheLogo from './TheLogo';
import Breadcrumb from './Breadcrumb';
// import MarketplacePhones from './MarketplacePhones';
// import MarketplaceContacts from './MarketplaceContacts';
import CleverSearch from './CleverSearch';
import LangLinks from './LangLinks';
import { BasketIcon } from '@/lib/orders';
import { BusinessMenuShort } from '@/lib/profiles';

export default {
  name: 'PageHeader',

  components: {
    LangLinks,
    CleverSearch,
    // MarketplacePhones,
    // MarketplaceContacts,
    UserNav,
    TheLogo,
    BusinessMenuShort,
    Breadcrumb,
    BasketIcon
  },

  data: () => ({
    isContacts: false,
    isMriyar: env.MARKETPLACE === 'mriyar'
  })
};
</script>

<style scoped lang="scss">
.page-header {
  .wrapper {
    border-bottom: 1px solid $grey-300;
    border-left: 1px solid $grey-300;
    border-right: 1px solid $grey-300;
    margin: 0 auto;
    position: relative;

    .links {
      display: flex;
      flex-wrap: wrap;

      li {
        margin-left: 4px;

        * {
          font-size: .8rem;
        }

        &::after {
          content: '\2022'
        }

        &:last-child::after {
          content: ''
        }
      }
    }

    .row-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .row-2 {
      display: flex;
      margin-bottom: 20px;

      .left-block, .right-block {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: calc((100% - 400px) / 2);
      }

      .right-block {
        padding-right: 10px;
        justify-content: flex-end;
      }

      .center-block {
        width: 400px;
        display: flex;
        align-items: center;

        .clever-search {
          width: 100%;
        }
      }
    }

    .row-3 {
      width: 100%;
      display: none;
      margin-bottom: 10px;
    }

    .clever-search {
      max-width: 500px;
      margin: 0 auto;
    }

    .btn-create-business {
      padding: 2px 4px;
    }

    @media all and (max-width: $--lg) {
      .row-2 {
        .left-block, .right-block {
          width: 50%;
        }

        .center-block {
          display: none;
        }
      }

      .row-3 {
        display: block;
        width: 100%;
        padding: 0 10px;
      }
    }

    @media all and (max-width: $--sm) {
      .row-2 {
        > .left-block, .right-block {
          width: unset;
          flex-grow: 1;
        }
      }
    }

    @media all and (min-width: $--lg) {
      .center-block {
        width: 530px!important;
      }
    }
  }
}
</style>
