<template>
  <div class="cars-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ meta && meta.h1 || $t('catalog.autoPartsForCars') }}
    </h1>

    <CarsBrandsModels class="mb-2" :value="carBrands" />

    <!--eslint-disable-next-line  vue/no-v-html-->
    <section v-if="meta && meta.text" class="pg-2 default-style" v-html="meta.text" />
  </div>
</template>

<script>
import CarsBrandsModels from './components/CarsBrandsModels';
import getSeoBreadcrumbs from './services/getSeoBreadcrumbs';
import seoHeadMixin from './mixins/seoHeadMixin';

export default {
  name: 'CarsPage',

  mixins: [seoHeadMixin],

  components: {
    CarsBrandsModels
  },

  async asyncData ({ app, store, error }) {
    const loadTime = Date.now();

    try {
      const data = await store.dispatch('seo/getSeoCars');

      const rowBreadcrumbs = [{
        name: 'cars',
        label: app.i18n.t('catalog.allCarsModels'),
        path: '/cars'
      }];

      const pl = {
        vm: { $store: store, $router: app.router },
        rowBreadcrumbs
      };

      const { breadcrumbs, seoBreadcrumbs } = getSeoBreadcrumbs(pl);

      store.dispatch('setBreadcrumbs', breadcrumbs);

      return {
        // data,
        loadTime: Date.now() - loadTime,
        carBrands: data.cars_brands || [],
        meta: data.meta,
        seoBreadcrumbs
      };
    } catch (e) {
      error({
        statusCode: e.statusCode || 404,
        message: e.message || app.i18n.t('errors.404.message')
      });
    }
  },

  mounted () {
    this.clearSavedCar();

    console.info(`LOAD_TIME: ${this.loadTime}ms`, this.$data);
  },

  methods: {
    clearSavedCar () {
      const pl = { carData: null, id: null };
      this.$store.dispatch('carModifications/setCarData', pl);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/default-style.scss";
</style>
