<template>
  <el-form
    ref="form"
    class="warehouse-form"
    :model="formData"
    :rules="rules"
    @submit.native.prevent="submit"
  >
    <el-form-item :label="$t('warehouses.name')" prop="name">
      <el-input ref="name" v-model="formData.name" />
    </el-form-item>

    <el-form-item class="mt-2">
      <el-row justify="end">
        <el-button @click="$emit('cancel')">{{ $t('buttons.cancel') }}</el-button>
        <el-button :loading="loading" native-type="submit" type="primary">
          {{ $t('buttons.create') }}
        </el-button>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>
import get from 'lodash/get';
import { getValidationFor, eventBus } from '@/lib/core';

export default {
  name: 'WarehouseForm',

  props: {
    params: { type: Object, default: () => ({}) },
    dependency: { type: Object, required: true }
  },

  data () {
    return {
      loading: false,
      rules: getValidationFor(['name'], 'warehouse'),
      formData: {
        is_active: false,
        name: '',
        price_file: get(this.dependency, 'price.price_file.url')
      }
    };
  },

  computed: {
    businessUrl () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'].url;
    },

    usedValues () {
      return (this.dependency.warehouses || []).map(({ name }) => name);
    }
  },

  mounted () {
    this.formData.name = this.getWarehouseDefaultName();
    this.$refs.name.focus();
  },

  methods: {
    getWarehouseDefaultName () {
      const header_row = get(this.dependency, 'price.price_file.header_row', 1);
      const colIndex = this.params._parent - 1;
      const colName = this.dependency.price.data_preview[header_row - 1][colIndex];
      const lastIndex = this.usedValues.length;
      const priceName = get(this.dependency, 'price.name', '');

      return colName || `Stock ${priceName} №${lastIndex ? lastIndex + 1 : 1}`;
    },

    submit () {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          this.loading = true;

          this.formData.business = this.businessUrl;

          try {
            const warehouse = await this.$store.dispatch('warehouses/postWarehouse', this.formData);

            eventBus.$emit('warehouses:create', warehouse);

            this.$emit('created', warehouse);
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>
