var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "photo-gallery" },
    [
      _vm.readonly && !_vm.photos.length
        ? _c("div", [_vm._v(_vm._s(_vm.$t("errors.emptyData")))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "PhotoList",
        _vm._g(
          { attrs: { photos: _vm.photos, readonly: _vm.readonly } },
          _vm.listeners
        )
      ),
      _vm._v(" "),
      _c(
        "el-upload",
        {
          ref: "upload",
          attrs: {
            action: "https://mp-api.dev.mriyar.ua/",
            "auto-upload": false,
            disabled: _vm.readonly,
            "list-type": "picture",
            multiple: true,
            "on-change": _vm.change,
            "show-file-list": false,
          },
        },
        [
          !_vm.readonly
            ? _c(
                "el-button",
                {
                  attrs: { slot: "trigger", size: "small", type: "success" },
                  slot: "trigger",
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("buttons.upload")) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }