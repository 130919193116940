<template>
  <div class="trademark-activator" @click.stop>
    <el-switch :disabled="readonly" :value="entity.is_active" @change="change" />
  </div>
</template>`

<script>
import ElSwitch from 'element-ui/lib/switch';

export default {
  name: 'TrademarkActivator',

  components: {
    ElSwitch
  },

  props: {
    readonly: Boolean,
    entity: { type: Object, required: true }
  },

  methods: {
    async change (is_active) {
      try {
        const formData = {
          is_active,
          business: this.entity.business,
          trademark_name: this.entity.trademark_name
        };

        const point = await this.$store
          .dispatch('sellerTrademarks/patchTrademark', {
            id: this.entity.id,
            formData
          });

        this.$emit('update', point.is_active);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
