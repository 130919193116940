var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "business-list-page" }, [
    _c("h1", { staticClass: "custom-headline custom-headline--level2-bold" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("routes.profile_business")) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }