<template>
  <div class="ware-info-icons" @click="$emit('show', ware)">
    <el-row align="middle" :justify="position">
      <i class="el-icon-warning-outline" />
      <i v-if="ware.has_images" class="el-icon-picture-outline" />
      <img
        v-if="ware.has_applicability"
        class="icon-img car-icon"
        src="@/assets/icons/car-2.svg"
        alt="has_applicability"
      >
      <i v-if="ware.has_kits" class="el-icon-takeaway-box" />

      <!--      <SearchInGoogle-->
      <!--        v-if="googleSearch"-->
      <!--        :query="`${ware.trademark ? ware.trademark.name : ''} ${ware.article}`"-->
      <!--      >-->
      <!--        <img-->
      <!--          class="icon-img"-->
      <!--          src="@/assets/icons/logo-google.svg"-->
      <!--          alt="logo-google"-->
      <!--        >-->
      <!--      </SearchInGoogle>-->
    </el-row>
  </div>
</template>

<script>
/**
 * has_info -   Есть  хотя бы один о/в атрибут
 *              Есть применяемость (пока не используем)
 * has_images - Есть изображение товара
 * has_kits -   Входит в комплект либо является комплектом
 *              Есть отзывы (пока не используем)
 * has_analogs - ?
 */

// import SearchInGoogle from './SearchInGoogle';

export default {
  name: 'WareInfoIcons',

  components: {
    // SearchInGoogle
  },

  props: {
    googleSearch: Boolean,
    position: { type: String, default: 'center' },
    ware: { type: Object, required: true }
  }
};
</script>

<style scoped lang="scss">
.ware-info-icons {
  cursor: pointer;
  color: $grey-600;

  .icon-img {
    width: 16px;
    height: 16px;
  }

  .car-icon {
    width: 20px;
    height: 20px;
  }

  .el-icon-warning-outline {
    transform: rotate(180deg);
  }

  i  {
    margin-right: 4px;
    font-size: 1.2rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
