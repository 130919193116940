var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["avatar-uploader", { circle: _vm.circle }] },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: "https://mp-api.dev.mriyar.ua/",
            "auto-upload": false,
            disabled: _vm.readonly,
            drag: "",
            name: "avatar",
            "on-change": _vm.setAvatar,
            "show-file-list": false,
          },
        },
        [
          _c("div", { staticClass: "upload-text color-default" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("buttons.uploadPhotoText")) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.logoUrl
            ? _c("el-image", {
                staticClass: "avatar",
                attrs: { fit: "contain", src: _vm.logoUrl },
              })
            : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.avatar && !_vm.hideRemoveBtn && !_vm.readonly
        ? _c("el-button", {
            staticClass: "remove-btn",
            attrs: { circle: "", size: "mini", icon: "el-icon-delete" },
            on: {
              click: function ($event) {
                return _vm.$emit("input", null)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }