var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "delivery-services-table" },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.value } },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("delivery_methods.carrier_in_country"),
              prop: "service_detail.label",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " + _vm._s(_vm.getLabel(row)) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("salePoint.comment"),
              prop: "comment",
              width: "100px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("i", {
                      class:
                        "el-icon-chat-" +
                        (row.comment ? "line-round" : "round"),
                      on: {
                        click: function ($event) {
                          return _vm.showCommentPopup(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("delivery_methods.free_shipping"),
              prop: "has_free_ship",
              width: "130px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      attrs: { size: "mini", value: row.has_free_ship },
                      on: {
                        change: function ($event) {
                          return _vm.emit(row, "has_free_ship", $event)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("salePoint.minPrice") + ", " + _vm.MARKETPLACE_CURRENCY,
              prop: "free_ship_amount",
              width: "150px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input-number", {
                      attrs: {
                        size: "mini",
                        min: 0,
                        value: row.free_ship_amount,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.emit(row, "free_ship_amount", $event)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "is_active", width: "60px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: { value: row.is_active },
                      on: {
                        change: function ($event) {
                          return _vm.emit(row, "is_active", $event)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-right text-ghost mt-05" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("salePoint.minPriceDesc")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "TheDialog",
        {
          attrs: {
            title:
              _vm.$t("salePoint.comment") +
              ": " +
              _vm.getLabel(_vm.isShowComment),
          },
          model: {
            value: _vm.isShowComment,
            callback: function ($$v) {
              _vm.isShowComment = $$v
            },
            expression: "isShowComment",
          },
        },
        [
          _vm.isShowComment
            ? _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "mb-2",
                    attrs: { type: "textarea", rows: "6" },
                    model: {
                      value: _vm.tempComment,
                      callback: function ($$v) {
                        _vm.tempComment = $$v
                      },
                      expression: "tempComment",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.isShowComment = null
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.cancel")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled:
                              _vm.isShowComment.comment === _vm.tempComment,
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveComment(_vm.isShowComment)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.save")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }