var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts no-wrap-error" },
    [
      !_vm.readonly && !_vm.hideDescription
        ? _c("div", { staticClass: "description" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("salePoint.setPhones")))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt-2 phone-numbers", attrs: { prop: "phone_numbers" } },
        [
          _vm._l(_vm.formData.phone_numbers, function (phone, index) {
            return _c(
              "el-row",
              {
                key: index,
                staticClass: "row mb-1",
                attrs: { align: "middle" },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "mb-0 mr-1 row-item",
                    attrs: {
                      "label-width": "100px",
                      label: _vm.$t("labels.phone") + " " + (index + 1),
                      prop: "phone_numbers." + index + ".phone_number",
                      rules: _vm.rules.phone_number,
                    },
                  },
                  [
                    _c("PhoneInput", {
                      attrs: { disabled: _vm.readonly },
                      on: {
                        blur: function ($event) {
                          return _vm.fixPhoneNumber(phone)
                        },
                        input: function ($event) {
                          return _vm.emit(
                            "phone_numbers",
                            _vm.formData.phone_numbers
                          )
                        },
                      },
                      model: {
                        value: phone.phone_number,
                        callback: function ($$v) {
                          _vm.$set(phone, "phone_number", $$v)
                        },
                        expression: "phone.phone_number",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-250p mr-1" },
                  [
                    index === 0
                      ? _c(
                          "el-row",
                          { staticClass: "w-250p checkbox-labels" },
                          _vm._l(_vm.channels, function (channel) {
                            return _c(
                              "el-col",
                              {
                                key: channel,
                                attrs: { span: 24 / _vm.channels.length },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("phoneChannels." + channel))
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: _vm.readonly },
                        on: {
                          input: function ($event) {
                            return _vm.emit(
                              "phone_numbers",
                              _vm.formData.phone_numbers
                            )
                          },
                        },
                        model: {
                          value: phone.channels,
                          callback: function ($$v) {
                            _vm.$set(phone, "channels", $$v)
                          },
                          expression: "phone.channels",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { staticClass: "w-250p" },
                          _vm._l(_vm.channels, function (channel) {
                            return _c(
                              "el-col",
                              {
                                key: channel,
                                attrs: { span: 24 / _vm.channels.length },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: channel } },
                                  [_c("span")]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mr-1" },
                  [
                    _c("el-input", {
                      staticClass: "description",
                      attrs: {
                        disabled: _vm.readonly,
                        placeholder: _vm.$t("placeholders.comment"),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.emit(
                            "phone_numbers",
                            _vm.formData.phone_numbers
                          )
                        },
                      },
                      model: {
                        value: phone.description,
                        callback: function ($$v) {
                          _vm.$set(phone, "description", $$v)
                        },
                        expression: "phone.description",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.readonly
                  ? _c(
                      "div",
                      { staticClass: "mr-1" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: {
                              disabled: _vm.readonly,
                              label: true,
                              value: phone.is_primary,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.setActive("phone_numbers", index)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.main")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.readonly
                  ? _c("RowIconControls", {
                      attrs: {
                        index: index,
                        item: phone,
                        list: _vm.formData.phone_numbers,
                        "need-one": _vm.isPhoneRequired(_vm.rules),
                        "value-name": "phone_number",
                      },
                      on: {
                        remove: function ($event) {
                          return _vm.removeItem("phone_numbers", index)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-row",
            [
              !_vm.readonly
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.addItemTo("phone_numbers", "phone")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("buttons.addSome", {
                              name: _vm.$t("labels.phone").toLowerCase(),
                            })
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.readonly && !_vm.hideDescription
        ? _c("div", { staticClass: "description" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("salePoint.setEmails")))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideEmails
        ? _c(
            "el-form-item",
            { staticClass: "mt-2 emails", attrs: { prop: "emails" } },
            [
              _vm._l(_vm.formData.emails, function (email, index) {
                return _c(
                  "el-row",
                  {
                    key: index,
                    staticClass: "row mb-1",
                    attrs: { align: "middle", type: "flex" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mb-0 mr-1",
                        attrs: {
                          "label-width": "100px",
                          label: _vm.$t("labels.email") + " " + (index + 1),
                          prop: "emails." + index + ".email",
                          rules: _vm.rules.email,
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "w-200p",
                          attrs: { disabled: _vm.readonly, type: "email" },
                          on: {
                            input: function ($event) {
                              return _vm.emit("emails", _vm.formData.emails)
                            },
                          },
                          model: {
                            value: email.email,
                            callback: function ($$v) {
                              _vm.$set(email, "email", $$v)
                            },
                            expression: "email.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mr-1" },
                      [
                        _c("el-input", {
                          staticClass: "description",
                          attrs: {
                            disabled: _vm.readonly,
                            placeholder: _vm.$t("placeholders.comment"),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.emit("emails", _vm.formData.emails)
                            },
                          },
                          model: {
                            value: email.description,
                            callback: function ($$v) {
                              _vm.$set(email, "description", $$v)
                            },
                            expression: "email.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.readonly
                      ? _c(
                          "div",
                          { staticClass: "mr-1" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  disabled: _vm.readonly,
                                  label: true,
                                  value: email.is_primary,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.setActive("emails", index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("labels.main")) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.readonly
                      ? _c("RowIconControls", {
                          attrs: {
                            index: index,
                            item: email,
                            list: _vm.formData.emails,
                            "need-one": _vm.isEmailRequired(_vm.rules),
                            "value-name": "email",
                          },
                          on: {
                            remove: function ($event) {
                              return _vm.removeItem("emails", index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  !_vm.readonly
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.addItemTo("emails", "email")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t("buttons.addSome", {
                                  name: _vm.$t("labels.email").toLowerCase(),
                                })
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }