<template>
  <el-row justify="center" class="mt-4 mb-4">
    <span
      v-if="!errors"
      class="list-header mb-2 custom-headline custom-headline--level3-bold"
    >
      {{ $t('prices.finish') }}
    </span>

    <pre v-else-if="errors" class="color-red">
      {{ errors }}
    </pre>
  </el-row>
</template>

<script>
export default {
  name: 'PriceCreateStatus',

  props: {
    errors: { type: Object, default: null }
  }
};
</script>
