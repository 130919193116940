<template>
  <el-container class="layout">
    <el-header
      v-observe-visibility="toggleScrollBtn"
      height="unset"
      class="scroll-target"
    >
      <PageHeaderInfo />
    </el-header>

    <el-container class="main-section">
      <el-main class="mb-4"><nuxt /></el-main>
    </el-container>

    <template v-if="$store.getters.isAppLoaded">
      <ProgressNotifier />
      <AppVersion />
      <TestPanel v-if="isDev" />
      <BackToTop v-if="isShowScroll" />
    </template>
  </el-container>
</template>

<script>
import BackToTop from '@/lib/core/components/BackToTop';
import layoutMixin from '@/lib/core/mixins/layoutMixin';
import PageHeaderInfo from '@/lib/core/components/PageHeaderInfo';

export default {
  name: 'Light',

  mixins: [layoutMixin],

  components: {
    PageHeaderInfo,
    BackToTop,
    /* eslint-disable */
    ProgressNotifier: () => import(/* webpackChunkName: "ProgressNotifier" */ '@/lib/core/components/ProgressNotifier'),
    AppVersion: () => import(/* webpackChunkName: "AppVersion" */ '@/lib/core/components/AppVersion'),
    TestPanel: () => import(/* webpackChunkName: "TestPanel" */ '@/lib/core/components/TestPanel')
    /* eslint-enable */
  },

  data: () => ({ isShowScroll: false }),

  methods: {
    toggleScrollBtn (isVisible) {
      this.isShowScroll = !this.maxmq('xss') && !isVisible;
    }
  }
};
</script>

<style scoped lang="scss">
.layout {
  .el-main {
    padding-top: 0;
  }

  @media only screen and (max-width: $--xs) {
    .el-header {
      padding: 0 !important;
    }

    .el-main {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}
</style>
