const middleware = {}

middleware['businessUserLoaded'] = require('../middleware/businessUserLoaded.js')
middleware['businessUserLoaded'] = middleware['businessUserLoaded'].default || middleware['businessUserLoaded']

middleware['dependencies'] = require('../middleware/dependencies.js')
middleware['dependencies'] = middleware['dependencies'].default || middleware['dependencies']

middleware['development'] = require('../middleware/development.js')
middleware['development'] = middleware['development'].default || middleware['development']

middleware['pageCacheMiddleware'] = require('../middleware/pageCacheMiddleware.js')
middleware['pageCacheMiddleware'] = middleware['pageCacheMiddleware'].default || middleware['pageCacheMiddleware']

middleware['routerMiddleware'] = require('../middleware/routerMiddleware.js')
middleware['routerMiddleware'] = middleware['routerMiddleware'].default || middleware['routerMiddleware']

middleware['serverRedirectMiddleware'] = require('../middleware/serverRedirectMiddleware.js')
middleware['serverRedirectMiddleware'] = middleware['serverRedirectMiddleware'].default || middleware['serverRedirectMiddleware']

middleware['userAuth'] = require('../middleware/userAuth.js')
middleware['userAuth'] = middleware['userAuth'].default || middleware['userAuth']

middleware['versionMiddleware'] = require('../middleware/versionMiddleware.js')
middleware['versionMiddleware'] = middleware['versionMiddleware'].default || middleware['versionMiddleware']

export default middleware
