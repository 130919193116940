<template>
  <FormBlock
    v-if="!readonly"
    class="description-block"
    :fields="['description']"
    :submit="submit"
    :value="value"
    v-on="$listeners"
  >
    <template #default="{ formData, disabled }">
      <el-form-item prop="description">
        <TheQuillEditor
          v-if="$store.getters.isAppLoaded"
          v-model="formData.description"
          :readonly="disabled"
        />
      </el-form-item>
    </template>
  </FormBlock>

  <!--eslint-disable-next-line-->
  <div v-else v-html="description" class="text color-default" />
</template>

<script>
import FormBlock from '@/lib/core/components/FormBlock';

export default {
  name: 'DescriptionBlock',

  components: {
    FormBlock,
    /* eslint-disable max-len */
    TheQuillEditor: () => import(/* webpackChunkName: "TheQuillEditor" */ '@/lib/core/components/TheQuillEditor')
    /* eslint-enable max-len */
  },

  props: {
    submit: Function,
    readonly: Boolean,
    value: { type: Object, required: true }
  },

  computed: {
    description () {
      return this.value.description
        ? this.value.description.replace(/<script>|<\/script>/g, '')
        : this.value.description;
    }
  }
};
</script>

<style scoped lang="scss">
.text {
  font-size: 14px;
}
</style>
