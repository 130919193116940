<script>
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import WarehouseInput from './WarehouseInput';
import MultipleSelect from './MultipleSelect';

export default {
  name: 'ColItemSelect',

  props: {
    value: null,
    config: { type: Array, required: true },
    originVal: { type: Object, required: true },
    dependency: { type: Object, required: true }
  },

  methods: {
    getSelectCollection () {
      const params = {
        result: [],
        index: 0,
        value: this.value,
        options: this.config
      };

      return isEmpty(this.config) ? null : this.joinSelect(params);
    },

    onSelectInput (val, index, options, type) {
      const getOptionBy = val =>
        options.find(({ value }) => value === val) || {};

      const option = !Array.isArray(val) && getOptionBy(val);

      const setVal = (newVal) => {
        const value = this.value.slice(0, index + 1);
        value[index] = newVal;
        this.$emit('input', value);
      };

      const emit = () => {
        if (option.isAction) {
          return this.$emit(val, {
            val,
            index,
            type,
            callback: setVal,
            _parent: this.originVal
          });
        } else {
          setVal(val);
        }
      };

      if (this.originVal._isAction && !val) {
        const name = this.$t(`priceMapping.remove_${this.originVal.action}`);
        const options = {
          type: 'warning',
          title: this.$t('message.warning')
        };

        return this.$confirm(this.$t('priceScheme.removeConfirm', { name }), options)
          .then(isAgree => isAgree && emit());
      } else {
        emit();
      }
    },

    joinSelect (params) {
      const { result, index, value, options } = params;
      const val = get(value, index, null);
      const entityType = options.find(item => item.value === val);

      result[index] = this.getSelect({ ...params, val, entityType });

      if (!entityType) {
        return result;
      }

      const nextParams = {
        result,
        index: index + 1,
        val,
        value,
        options: entityType.children,
        type: entityType,
        _parent: this.originVal
      };

      if (entityType.element) {
        return this[`join${entityType.element}`](nextParams);
      }

      if (entityType.buttons) {
        this.getButtons(entityType, result);
      }

      if (entityType.children) {
        if (entityType.multiple) {
          this.joinCascader(nextParams);
        } else {
          this.joinSelect(nextParams);
        }
      }

      return result;
    },

    joinWarehouseInput (params) {
      const element = this.$createElement(WarehouseInput, {
        props: {
          params,
          dependency: this.dependency
        },
        on: this.$listeners
      });

      params.result.push(element);

      return params.result;
    },

    getSelect ({ index, val, options, type, entityType }) {
      return this.$createElement(
        'el-select',
        {
          class: {
            'form-item': true,
            invalid: index && !type.childIsOptional && (!entityType || isEmpty(val))
          },
          props: {
            size: 'mini',
            clearable: !type || !type.multiple,
            value: val,
            disabled: entityType && entityType.readonly,
            multiple: type && type.multiple
          },
          on: {
            change: newVal => this.onSelectInput(newVal, index, options, type),
            clear: () =>
              this.$emit(`${val}:remove`, { _parent: this.originVal })
          }
        },
        this.getOptionsFor(options)
      );
    },

    joinCascader (params) {
      const { result, index, value, options, type, entityType } = params;
      const parsedVal = get(value, index, []).map(item => [item]);

      const cascader = this.$createElement(MultipleSelect, {
        class: {
          'form-item': true
        },
        props: {
          value: parsedVal,
          readonly: entityType && entityType.readonly,
          options,
          validate: val =>
            index === 0 || (!isEmpty(type.children) && !isEmpty(val))
        },
        on: {
          change: newVal =>
            this.onSelectInput(flatten(newVal), index, options, type)
        }
      });

      result.push(cascader);

      return result;
    },

    getFormItem (type, element, val, index) {
      const invalid =
        index > 0 && (!type || (!isEmpty(type.children) && isEmpty(val)));

      return this.$createElement(
        'el-form-item',
        {
          class: {
            invalid
          },
          props: {
            'label-width': '0'
          }
        },
        [element]
      );
    },

    getOptionsFor (options) {
      return options.map((option) => {
        return this.$createElement('el-option', {
          props: {
            key: option.value,
            label: option.label,
            value: option.value
          }
        });
      });
    },

    getButtons (type, result) {
      const buttons = type.buttons.map((item) => {
        const button = this.$createElement(
          'el-button',
          {
            class: 'button text-ellipsis',
            attrs: {
              type: 'primary'
            },
            on: {
              click: () => {
                if (item.isAction) {
                  this.$emit(item.value, { type, _parent: this.originVal });
                }
              }
            }
          },
          [item.label]
        );

        return this.getFormItem(type, button, item.value);
      });

      result.push(...buttons);

      return result;
    }
  },

  render (h) {
    return h(
      'div',
      {
        class: 'col-item-select'
      },
      this.getSelectCollection()
    );
  }
};
</script>

<style scoped lang="scss">
.col-item-select {
  .el-form-item {
    margin-bottom: 4px;
    width: 100%;
  }

  .button {
    width: 100%;
  }
}
</style>
