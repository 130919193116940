<template>
  <el-row
    v-if="currentProfile && isSeller"
    align="middle"
    class="business-menu-short"
    :class="['business-menu-short', 'border-bottom']"
    justify="space-between"
    type="flex"
  >
    <div v-if="!maxmq('xs')" class="label bold">
      {{ $t('routes.menuBusiness') }}:
    </div>

    <div v-else />

    <div v-if="!hasPrices && !maxmq('lg')">
      <nuxt-link
        v-if="!hasSellPoint"
        :to="{ name: getRouteName('profile.business.sale-points.create') }"
      >
        <span class="color-red">{{ $t('profile.sellIsEasily') }}</span>
        {{ $t('salePoint.createSellPointFirst') }}
      </nuxt-link>

      <nuxt-link
        v-else-if="!hasPrices"
        :to="{ name: getRouteName('profile.business.prices.upload') }"
      >
        <span class="color-red">{{ $t('profile.sellIsEasily') }}</span>
        {{ $t('prices.saleYourWaresEasy') }}
      </nuxt-link>
    </div>

    <div v-else />

    <BusinessHotKeys />
  </el-row>
</template>

<script>
import BusinessHotKeys from './BusinessHotKeys';

export default {
  name: 'BusinessMenuShort',

  components: {
    BusinessHotKeys
  },

  computed: {
    currentProfile () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'];
    },
    isSeller () {
      return this.$store.getters['businessProfiles/isSellBusiness'];
    },
    hasSellPoint () {
      return this.isSeller && this.currentProfile.has_points_of_sale;
    },
    hasPrices () {
      return this.hasSellPoint && this.currentProfile.has_prices;
    }
  }
};
</script>

<style scoped lang="scss">
.business-menu-short {
  min-height: 30px;
  font-size: .8rem;

  .label {
    min-width: 0;
    width: 120px !important;
    word-wrap: normal;
  }

  .color-red {
    &:hover {
      opacity: .8;
    }

    &:active {
      opacity: 1;
    }
  }

  &.border-bottom {
    border-bottom: 1px solid $grey-300;
  }

  @media all and (max-width: $--md) {
    padding: 0 10px;
  }
}
</style>
