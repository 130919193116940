<template>
  <div
    :class="['clever-search relative', { [size]: size }]"
    @mouseenter="isFocus = true"
    @mouseleave="isFocus = false"
    @keydown="onKeyDown"
  >
    <el-input
      ref="input"
      autocomplete="on"
      clearable
      :size="size"
      :placeholder="$t('cleverSearch.searchPlaceholder')"
      :value="query"
      @input="onInput"
      @keydown.native.enter="applySelection"
      @keydown.native.esc="clearOptions()"
      @blur="clearOptions()"
    >
      <el-button
        slot="append"
        class="main-btn--icon"
        type="primary"
        icon="el-icon-search"
        :loading="isLoading"
        :size="size"
        @click="applySelection"
      />
    </el-input>

    <div
      v-if="isCategoryPopup"
      v-loading="isLoading"
      class="custom-autocomplete back-shadow scroll category-popup"
    >
      <div class="mb-2 bold">
        {{ $t('cleverSearch.specifyCategory') }}:
      </div>
      <div>
        <ul>
          <li
            :class="['item', { 'is-active': isActive === doubleItem._id }]"
            @mouseenter="setActive(doubleItem)"
            @click="applySelection()"
          >
            {{ doubleItem._label }}
          </li>
          <li
            v-for="item in options.search.product_line"
            :key="item._id"
            :class="['item', { 'is-active': isActive === item._id }]"
            @mouseenter="setActive(item)"
            @click="onSelect(item, 'product_line')"
          >
            {{ item._label }}
          </li>
        </ul>
      </div>

      <el-row justify="center" class="btn-block">
        <el-button
          type="primary"
          icon="el-icon-search"
          :loading="isLoading"
          :size="size"
          @click="applySelection"
        >
          {{ $t('buttons.search') }}
        </el-button>
      </el-row>
    </div>

    <div
      v-else-if="isValues"
      v-loading="isLoading"
      class="custom-autocomplete back-shadow"
    >
      <div class="content scroll">
        <div
          :class="['item', { 'is-active': isActive === doubleItem._id }]"
          @mouseenter="setActive(doubleItem)"
          @click="applySelection()"
        >
          {{ doubleItem._label }}
        </div>

        <div v-for="(value, key) in options" :key="key">
          <template v-if="key === 'autocomplete' && !options.wares_search.length">
            <div
              v-for="item in value"
              :key="item._id"
              :class="['item', { 'is-active': isActive === item._id }]"
              @mouseenter="setActive(item)"
              @click="onInput(
                item.full_query,
                { focus: true, isAutocomplete: true, apply: true }
              )"
            >
              <!--eslint-disable-next-line  vue/no-v-html-->
              <span v-html="item._label || item.name" />
            </div>
          </template>

          <template v-else-if="key === 'wares_search' && value.length">
            <div
              v-for="item in value"
              :key="item._id"
              :class="['item', { 'is-active': isActive === item._id }]"
              @mouseenter="setActive(item)"
              @click="onSelect(item, 'ware')"
            >
              <!--eslint-disable-next-line  vue/no-v-html-->
              <div v-html="item._label" />
              <div class="text-ghost">
                <small>{{ item.display_title }}</small>
              </div>
            </div>
          </template>

          <template v-else-if="key === 'search' && !options.wares_search.length">
            <template v-for="(list, _key) in value">
              <div v-if="list.length && _key === 'product_line'" :key="_key">
                <div class="label">
                  {{ $t(`cleverSearch.${_key}`) }}
                </div>

                <div
                  v-for="item in list"
                  :key="item._id"
                  :class="['item', { 'is-active': isActive === item._id }]"
                  @mouseenter="setActive(item)"
                  @click="onSelect(item, item._key)"
                >
                  <!--eslint-disable-next-line  vue/no-v-html-->
                  <span v-html="item._label || item.name" />
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import last from 'lodash/last';
import first from 'lodash/first';
import flattenDeep from 'lodash/flattenDeep';
import { env, parsers, eventBus } from '@/lib/core';
import getSeoLinkBy from '@/lib/catalog/services/getSeoLinkBy';

export default {
  name: 'CleverSearch',

  props: {
    size: { type: String, default: 'large' },
    test: Boolean
  },

  data: ({ $route }) => ({
    isFocus: false,
    isLoading: false,
    isDisabled: false,
    isActive: null,
    isCategoryPopup: false,
    isAutocomplete: false,
    query: $route.params.q || $route.query.q || '',
    originQuery: '',
    options: null
  }),

  computed: {
    isValues () {
      return this.options && Object.entries(this.options).some(([key, value]) => {
        return key !== 'search' && !isEmpty(value);
      });
    },

    allOptions () {
      return flattenDeep([
        this.options?.autocomplete || [],
        Object.values(this.options?.search || {}) || [],
        this.options?.wares_search || []
      ]);
    },

    doubleItem () {
      return {
        _label: this.originQuery
      };
    }
  },

  mounted () {
    this.searchD = debounce(this.searchD, env.DEBOUNCE * 2);

    this.$watch(
      () => `${this.$route.params.q}${this.$route.query.q}${this.$route.name}`,
      () => { this.query = this.$route.params.q || this.$route.query.q || ''; }
    );
  },

  methods: {
    onInput (q, options = {}) {
      this.originQuery = q;
      this.query = q;

      if (this.isDisabled) {
        return null;
      }

      this.isCategoryPopup = false;
      this.isAutocomplete = !!options.isAutocomplete;

      if (q.length > 2) {
        this.searchD(q, options);

        if (options.focus) {
          this.$refs.input.focus();
        }
      } else {
        this.options = null;
      }
    },

    applySelection () {
      if (this.isActive) {
        const item = this.allOptions.find(it => it._id === this.isActive);

        if (item) {
          return item._key === 'autocomplete'
            ? this.onInput(
              item.full_query,
              { focus: true, isAutocomplete: true, apply: true }
            )
            : this.onSelect(item, item._key);
        }
      }

      return this.search(this.query, { apply: true });
    },

    searchD (q, options) {
      return this.search(q, options);
    },

    async search (q, options = {}) {
      if (!q) {
        return this.clearOptions('force');
      }

      const _beforeRoute = this.$route.fullPath;

      this.isLoading = true;

      try {
        const CancelToken = this.$axios.CancelToken;
        this.axiosSource = CancelToken.source();

        const data = await this.$store.dispatch(
          'search/cleverSearch',
          { q, cancelToken: this.axiosSource.token }
        );

        if (_beforeRoute !== this.$route.fullPath) {
          return null;
        }

        return options.apply
          ? this.useMediatorForce(data, q)
          : this.useMediator(data, q);
      } catch (e) {
        console.error(e);
        this.clearOptions('force');
      } finally {
        this.axiosSource = null;
        this.isLoading = false;
      }
    },

    useMediator (data, q) {
      const { autocomplete, search, wares_search } = data;

      if (
        autocomplete?.results?.length ||
        search?.results ||
        wares_search?.results?.length
      ) {
        this.options = {
          autocomplete: this.getParsedAutocomplete(autocomplete, q),
          search: this.getParsedSearch(search, q),
          wares_search: this.getParsedWaresSearch(wares_search, q)
        };
        return null;
      }

      this.clearOptions('force');
    },

    async useMediatorForce (data, q) {
      this.options = null;

      const { full_match_search, wares_search, search } = data;

      if (
        isEmpty(wares_search?.results) &&
        !isEmpty(full_match_search?.results?.ware)
      ) {
        return this.onSelect(
          full_match_search.results?.ware[0],
          'full_match_search.ware'
        );
      }

      if (
        isEmpty(wares_search?.results) &&
        !isEmpty(full_match_search?.results)
      ) {
        return this.onSelect(
          full_match_search.results,
          'full_match_search'
        );
      }

      if (wares_search?.results?.length === 1) {
        return this.onSelect(
          wares_search.results[0],
          'ware'
        );
      }

      if (wares_search?.results?.length > 1) {
        return this.onSelect(
          { q: wares_search?.results[0].article },
          'search'
        );
      }

      if (!isEmpty(search?.results?.ware)) {
        return this.onSelect(
          search?.results?.ware[0],
          'full_match_search.ware'
        );
      }

      await this.$router.push({
        name: this.getRouteName('catalog-search'),
        query: { q }
      }).catch(e => e);

      this.clearOptions('force');
    },

    getParsedAutocomplete (autocomplete, q) {
      return (autocomplete.results || [])
        .slice(0, 5)
        .map(it => ({
          ...it,
          _label: this.getStrong(it.full_query, q),
          _id: parsers.getDefaultId(),
          _key: 'autocomplete'
        }));
    },

    getParsedSearch (search, q) {
      return Object
        .entries(search.results)
        .reduce((acc, [key, value]) => {
          if (key === 'product_line') {
            acc[key] = value
              .slice(0, 5)
              .map((it) => {
                return {
                  ...it,
                  _label: this.getStrong(it.related_object_name),
                  _id: parsers.getDefaultId(),
                  _key: key
                };
              });
          } else {
            acc[key] = {
              ...value,
              _label: value.name,
              _id: parsers.getDefaultId(),
              _key: key
            };
          }

          return acc;
        }, {});
    },

    getParsedWaresSearch (wares_search, q) {
      return (wares_search?.results || [])
        .map((it) => {
          const _label = `${it.display_trademark} ${it.display_article}`;

          return {
            ...it,
            _label: this.getStrong(_label, q),
            _id: parsers.getDefaultId(),
            _key: 'ware'
          };
        });
    },

    getStrong (str, q) {
      return q
        ? str.replace(q, `<span class="bold">${q}</span>`)
        : `<span class="bold">${str}</span>`;
    },

    async onSelect (value, key) {
      let route = null;

      if (key === 'full_match_search.ware') {
        route = {
          name: this.getRouteName('article'),
          query: {
            wareId: value.related_object_id
          }
        };
      } else if (key === 'full_match_search') {
        const pl = {
          lineId: get(value, 'product_line[0].related_object_slug'),
          trademarkId: get(value, 'tm[0].related_object_slug'),
          carBrand: get(value, 'car_brand.related_object_slug') ||
            get(value, 'car_model[0].brand.slug'),
          carModel: get(value, 'car_model[0].related_object_slug')
        };

        route = getSeoLinkBy(pl, this, pl);
      } else if (key === 'search') {
        route = {
          name: this.getRouteName('search'),
          query: { q: this.query, ...value }
        };
      } else if (key === 'product_line') {
        const pl = {
          lineId: value.related_object_slug,
          trademarkId: get(this.options, 'search.tm[0].related_object_slug'),
          carBrand: get(this.options, 'search.car_brand[0].related_object_slug'),
          carModel: get(this.options, 'search.car_model[0].related_object_slug')
        };

        route = getSeoLinkBy(pl, this, pl);
      } else if (key === 'car_brand') {
        const pl = { carBrand: value.related_object_slug };
        route = getSeoLinkBy(pl, this, pl);
      } else if (key === 'car_model') {
        const pl = {
          carBrand: value.brand?.slug,
          carModel: value.related_object_slug
        };
        route = getSeoLinkBy(pl, this, pl);
      } else if (key === 'tm') {
        const pl = { trademarkId: value.related_object_slug };
        route = getSeoLinkBy(pl, this, pl);
      } else if (key === 'ware') {
        route = parsers.getSearchRouteBy(this, value);
      }

      if (route) {
        this.axiosSource?.cancel('axiosSource.cancel');

        if (route.name.startsWith('article') && route.name === this.$route.name) {
          eventBus.$emit('filter:reload-page', route);
        } else {
          await this.$router.push(route).catch(e => e);
        }

        return this.clearOptions('force');
      }

      this.clearOptions();
    },

    clearOptions (isForce) {
      setTimeout(() => {
        if ((!this.isFocus && !this.isLoading) || isForce) {
          this.isDisabled = true;
          this.isActive = null;
          this.isCategoryPopup = false;
          this.options = null;
          this.isDisabled = false;
        }
      }, 0);
    },

    setActive ({ _id, _label }) {
      setTimeout(() => {
        this.isDisabled = true;
        this.isActive = _id;
        this.isDisabled = false;
      }, 0);
    },

    onKeyDown (event) {
      const [_isActive] = this.$el.getElementsByClassName('is-active');
      const list = Array.from(this.$el.getElementsByClassName('item'));

      const setActive = (element) => {
        const event = new MouseEvent(
          'mouseenter',
          { bubbles: true }
        );

        element.dispatchEvent(event);
      };

      const useIndex = (index) => {
        const _index = list.findIndex(it => it === _isActive);

        if (_index > -1 && list[_index + index]) {
          setActive(list[_index + index]);
        }
      };

      // arrow up
      if (event.keyCode === 38) {
        if (!_isActive) {
          const _last = last(list);
          setActive(_last);
        } else {
          useIndex(-1);
        }
      } else if (event.keyCode === 40) {
        if (!_isActive) {
          const _first = first(list);
          setActive(_first);
        } else {
          useIndex(+1);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.clever-search {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ::v-deep {
    .el-input {
      .el-input__inner {
        height: 35px;
      }

      .el-input-group__append {
        background-color: $--button-primary-background-color;
        border: 1px solid $--button-primary-background-color;
        color: $white;
      }
    }
  }

  .custom-autocomplete {
    z-index: 9999;

    * {
      text-align: left;
    }
  }

  .main-btn {
    margin: 10px auto 0;
    width: 70%;
    padding: 10px 20px;

    &--icon {
      width: fit-content;
      margin: 0;
      padding: 0;
    }
  }

  .category-popup {
    padding: 10px;
  }

  .custom-autocomplete {
    width: 100%;
    height: fit-content;
    top: 35px;
    left: 0;
    right: 0;
    min-width: unset;
    max-width: unset;
    max-height: unset;

    .content {
      max-height: 250px;
      overflow: auto;
    }

    .btn-block {
      border-top: 1px solid $grey-100;
      padding: 10px;
      width: 100%;
    }
  }
}
</style>
