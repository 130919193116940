import { cookie, parsers, getDefault, env } from '@/lib/core';

const currency = env.MARKETPLACE_CURRENCY_ID;
const defaultCatalogOptions = getDefault('catalogOptions');

export const state = () => ({
  mapSearchSettings: null
});

export const mutations = {
  setMapSearchSettings: (state, settings) => {
    state.mapSearchSettings = settings;
  }
};

export const actions = {
  async getWare ({ rootGetters }, { id }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.SEARCH_API,
      url: `/catalog/${id}`,
      params: { lang: rootGetters.langCode }
    });

    return data.data;
  },

  async searchVariants ({ rootGetters }, { id }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.SEARCH_API,
      url: '/collection/search',
      params: { id, lang: rootGetters.langCode }
    });

    return data.data;
  },

  async searchAnalogs ({ rootGetters }, { id, options = {} }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.SEARCH_API,
      url: `/catalog/${id}/related`,
      params: {
        id,
        lang: rootGetters.langCode,
        ...defaultCatalogOptions,
        select_mode: 1,
        ...options
      }
    });

    return data.data;
  },

  async searchInWares ({ rootGetters }, { query, hideCity }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: '/wares-search/',
      params: {
        q: query,
        lang: rootGetters.langCode,
        city_and_delivery: hideCity ? undefined : rootGetters.cityId
      }
    });

    return data;
  },

  async searchInPrice ({ dispatch, rootGetters }, payload) {
    const { search, priceFileId, filter = {} } = payload;
    const _isForCity = parsers.isFilterForCity(filter);
    const _cityId = rootGetters.cityId;

    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: '/prices-search/',
      params: {
        search,
        lang: rootGetters.langCode,
        city_and_delivery:
          !_isForCity && !priceFileId ? _cityId : undefined,
        city: _isForCity && !priceFileId ? _cityId : undefined,
        price_file: priceFileId
      }
    });

    return data;
  },

  async searchAnaloguesInPrice ({ rootGetters }, payload) {
    const { city_and_delivery, ware_and_analogs, cityId, ...filter } = payload;

    const _isForCity = !city_and_delivery || parsers.isFilterForCity(filter);
    const _cityId = cityId || rootGetters.cityId;

    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: '/prices-search/',
      params: {
        lang: rootGetters.langCode,
        city_and_delivery: !_isForCity ? _cityId : undefined,
        city: _isForCity ? _cityId : undefined,
        ware_and_analogs: ware_and_analogs || undefined,
        currency,
        ...filter
      }
    });

    return data;
  },

  async searchInPrices ({ rootGetters }, payload) {
    const { skipInStock, city_and_delivery, cityId, ...filter } = payload;

    const _isForCity = !city_and_delivery || parsers.isFilterForCity(filter, { skipInStock });
    const _cityId = cityId || rootGetters.cityId;

    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: '/prices-search/',
      params: {
        currency,
        lang: rootGetters.langCode,
        city: _isForCity ? _cityId : undefined,
        city_and_delivery: !_isForCity ? _cityId : undefined,
        ...filter
      }
    });

    return data;
  },

  async searchBusinessPrises ({ rootGetters }, params) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: '/prices-search/',
      params: {
        currency,
        lang: rootGetters.langCode,
        ...params
      }
    });

    return data;
  },

  async getWaresByUrl (_, { url }) {
    const { data } = await this.$axios({ baseURL: url });
    return data;
  },

  fetchMapSearchSettings ({ commit }) {
    const rowSettings = cookie.get('mapSearchSettings') || '{}';
    const settings = JSON.parse(rowSettings);

    commit('setMapSearchSettings', {
      ...getDefault('mapSearchSettings'),
      ...settings
    });
  },

  async cleverSearch (_, { q, cancelToken }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: '/clever-row/autocomplete-and-search/',
      params: { q },
      progress: false,
      cancelToken
    });

    return data;
  },

  setMapSearchSettings ({ commit }, settings) {
    commit('setMapSearchSettings', settings);
    cookie.set('mapSearchSettings', JSON.stringify(settings));
  }
};

export const getters = {
  mapSearchSettings: state => state.mapSearchSettings
};
