var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-request-items-view" },
    [
      _vm.car
        ? _c("CarInfoCard" + (_vm.isMobile ? "M" : ""), {
            tag: "component",
            staticClass: "mb-2",
            attrs: { value: _vm.car },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-2" },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "item-row grey-block mb-2" },
            [
              _c("div", { staticClass: "bold" }, [
                _vm._v(_vm._s(item.rawItem)),
              ]),
              _vm._v(" "),
              item.comment
                ? _c("div", { staticClass: "mt-1" }, [
                    _vm._v(_vm._s(item.comment)),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.comment
        ? _c("div", { staticClass: "grey-block comment" }, [
            _c("div", { staticClass: "mb-1 bold" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("garage.selectionRequests.comment")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.comment))]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }