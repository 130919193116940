<template>
  <el-button class="collapse-toggle" size="mini" type="text" @click="toggle">
    <i :class="`el-icon-arrow-${isOpened ? 'up' : 'down'}`" />

    <span v-if="!hideLabel" class="color-blue">
      {{ $t(`buttons.${isOpened ? 'collapseAll' : 'unCollapseAll'}`) }}
    </span>
  </el-button>
</template>

<script>
export default {
  name: 'CollapseToggle',

  props: {
    hideLabel: Boolean,
    value: { type: Array, required: true },
    blocks: { type: Array, required: true }
  },

  computed: {
    isOpened () {
      return Boolean(this.value.length);
    }
  },

  methods: {
    toggle () {
      this.$emit('input', this.isOpened ? [] : this.blocks.slice(0));
    }
  }
};
</script>

<style scoped>
.collapse-toggle {
  color: black !important;
}
</style>
