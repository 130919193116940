import isEmpty from 'lodash/isEmpty';
import { env } from '@/lib/core';
import { i18n } from '@/plugins/i18n';

export const state = () => ({
  catalog: {}
});

export const mutations = {
  setCatalog: (state, catalog) => {
    state.catalog = { ...state.catalog, [i18n.locale]: catalog };
  }
};

export const actions = {
  async fetchCatalog ({ commit, getters, dispatch }) {
    if (!isEmpty(getters.catalog)) {
      return getters.catalog;
    }

    try {
      const catalog = await dispatch('getLines');
      commit('setCatalog', catalog);
      return catalog;
    } catch (e) {
      console.error(e);
    }
  },

  async getLines ({ rootGetters }, params = {}) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.SEO_API,
      url: '/product-line-tree/',
      params: {
        ordering: 'name',
        limit: 0,
        lang: rootGetters.langCode,
        ...params
      }
    });

    return data.results;
  },

  async getLine (_, { id }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: `/product-lines/${id}/`
    });

    return data;
  }
};

export const getters = {
  catalog: (state, getters, rootState, rootGetters) => {
    return state.catalog[rootGetters.langCode] || [];
  }
};
