import { session, cookie } from '@/lib/core';

export const state = () => ({
  user: null
});

export const mutations = {
  setUser: (state, user) => { state.user = user; }
};

export const actions = {
  postUser ({ commit, dispatch }, formData) {
    return this.$axios.$post('/users/', formData);
  },

  updateCurrentUser ({ dispatch }) {
    return dispatch('auth/updateToken', null, { root: true });
  },

  async postActivateUser ({ commit, dispatch }, formData) {
    const user = await this.$axios.$post('/users/activate/', formData);
    dispatch('auth/setTokenData', user, { root: true });
    dispatch('setUserData', { user });
    return user;
  },

  postResendActivationKey ({ commit }, formData) {
    return this.$axios
      .$post('/users/resend_activation_key/', formData);
  },

  postSendRecoveryPassword ({ commit, dispatch }, payload) {
    const formData = {
      email: payload.email,
      captcha_token: payload.captcha_token
    };

    return this.$axios.$post('/users/send_password_recovery/', formData);
  },

  postPasswordRecovery ({ rootGetters }, formData) {
    return this.$axios.$post('/users/password_recovery/', formData);
  },

  async setUserData ({ state, dispatch, rootGetters }, payload = {}) {
    await dispatch('setLocalUser', payload.user);

    if (!payload.user) {
      return null; // dispatch('setUserCode', null, { root: true });
    }

    if (payload.user.timezone && payload.user.timezone !== rootGetters.timezone) {
      dispatch('setTimezone', payload.user.timezone, { root: true });
    }

    return dispatch('loadProfile', payload);
  },

  async loadProfile ({ getters, rootGetters, dispatch }, payload = {}) {
    const { user, cookie: _cookie } = payload;
    const _user = user || getters.user;

    if (_user) {
      const userCode = (_cookie && _cookie.userCode) ||
        session.get('userCode') ||
        cookie.get('userCode');

      const businessUser = _user.business_acl
        .find(business => `b${business.id}` === userCode);

      const code = businessUser ? `b${businessUser.id}` : `u${_user.id}`;

      await dispatch('setUserCode', code, { root: true });

      return dispatch('cars/fetchCarsQL', { force: true }, { root: true });
    }
  },

  setLocalUser ({ commit }, user) {
    return commit('setUser', user);
  }
};

export const getters = {
  user: state => state.user,
  userId: (state, getters) => getters.user?.id || null,
  buyerId: (state, getters) => getters.user?.profile.buyer.id || null
};
