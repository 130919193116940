var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("keep-alive", [
    _c(
      "div",
      { staticClass: "the-loader" },
      [
        _c("div", { staticClass: "car" }, [
          _c("div", { staticClass: "strike" }),
          _vm._v(" "),
          _c("div", { staticClass: "strike strike2" }),
          _vm._v(" "),
          _c("div", { staticClass: "strike strike3" }),
          _vm._v(" "),
          _c("div", { staticClass: "strike strike4" }),
          _vm._v(" "),
          _c("div", { staticClass: "strike strike5" }),
          _vm._v(" "),
          _c("div", { staticClass: "car-detail spoiler" }),
          _vm._v(" "),
          _c("div", { staticClass: "car-detail back" }),
          _vm._v(" "),
          _c("div", { staticClass: "car-detail center" }),
          _vm._v(" "),
          _c("div", { staticClass: "car-detail center1" }),
          _vm._v(" "),
          _c("div", { staticClass: "car-detail front" }),
          _vm._v(" "),
          _c("div", { staticClass: "car-detail wheel" }),
          _vm._v(" "),
          _c("div", { staticClass: "car-detail wheel wheel2" }),
        ]),
        _vm._v(" "),
        _c(
          "el-row",
          { staticClass: "text-block", attrs: { justify: "middle" } },
          [
            _c("div", { staticClass: "text" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("core.loading")))]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }