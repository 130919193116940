import { i18n } from '@/plugins/i18n';

export default (errorCode, vm) => {
  const getTranslation = (code) => {
    if (!code) {
      return '';
    }

    const langPath = `prices.statuses.${code}`;
    const translation = i18n.t(langPath);

    if (translation === langPath) {
      if (vm && vm.$sentry) {
        vm.$sentry.setTag('type', 'api');
        vm.$sentry.setTag('type', 'api');
        const error = `There isn\`t error translation for code ${errorCode}`;
        vm.$sentry.captureException(new Error(error));
      }

      if ((code.split('.')).length === 1) {
        return code;
      } else {
        const parentCode = code.split(/(_\d)$/)[0];
        return getTranslation(parentCode);
      }
    } else {
      return translation;
    }
  };

  return getTranslation(errorCode.replace(/\./g, '_'));
};
