var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ware-article-replacement" }, [
    _c("div", { staticClass: "mb-1" }, [
      _c(
        "span",
        { staticClass: "custom-headline custom-headline--level3-bold" },
        [_vm._v("\n      " + _vm._s(_vm.$t("ware.history")) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ware-article-container" },
      _vm._l(_vm.value.replacements_history, function (item, index) {
        return _c(
          "el-row",
          {
            key: item.id,
            staticClass: "article-container",
            attrs: { align: "middle" },
          },
          [
            index !== 0 ? _c("i", { staticClass: "el-icon-right" }) : _vm._e(),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                class: [
                  "article",
                  { disabled: item.article === _vm.value.article },
                ],
                attrs: { to: _vm.getLInkBy(item) },
              },
              [_vm._v("\n        " + _vm._s(item.article) + "\n      ")]
            ),
          ],
          1
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }