<template>
  <div class="balance-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_balance') }}
    </h1>

    <el-row class="balance-header" justify="end">
      <div class="mb-2 balance-info">
        <div class="mb-2">
          <span>
            {{ $t('balance.currentBalance') }}:
            <span class="bold">{{ lastTransaction.created }}</span>
          </span>
          <span>
            <strong :class="`color-${lastTransaction.balance < 0 ? 'red': 'green'}`">
              {{ lastTransaction.balance }}
            </strong>
            {{ MARKETPLACE_CURRENCY }}
          </span>
        </div>
      </div>
    </el-row>

    <TheLoader v-if="isTransactionsLoading" />

    <template v-else>
      <TransactionsTable
        v-if="transactions.result.length"
        class="mb-2"
        :value="transactions.result"
        :total="transactions.total"
      />

      <GenerateInvoiceDialog />
    </template>
  </div>
</template>

<script>
import GenerateInvoiceDialog from './components/GenerateInvoiceDialog';
import { filters, env } from '@/lib/core';

export default {
  name: 'BalancePage',

  middleware: ['development'],

  components: {
    /* eslint-disable max-len */
    TransactionsTable: () => import(/* webpackChunkName: "TransactionsTable" */ './components/TransactionsTable'),
    /* eslint-enable max-len */
    GenerateInvoiceDialog
  },

  data () {
    return {
      MARKETPLACE_CURRENCY: env.MARKETPLACE_CURRENCY,
      isTransactionsLoading: true,
      offset: 0,
      transactions: {
        result: [],
        total: 0
      },
      lastTransaction: {
        balance: 0,
        created: '-'
      }
    };
  },

  computed: {
    business () {
      return this.$store.getters['businessProfiles/businessId'];
    }
  },

  watch: {
    '$route.query.page': 'loadTransactions'
  },

  mounted () {
    this.loadTransactions();
    this.loadLastTransaction();
  },

  methods: {
    async loadTransactions () {
      this.isTransactionsLoading = true;

      try {
        const { count, results } = await this.$store.dispatch(
          'balance/getTransactions',
          {
            page: this.page,
            business: this.business
          }
        );

        this.transactions.results = results.map(res => ({
          ...res,
          amount: res.amount > 0 ? `+${res.amount}` : res.amount,
          created: filters.getDateTime(res.created)
        })) || [];

        this.transactions.total = count;
      } catch (e) {
        console.error(e);
      } finally {
        this.isTransactionsLoading = false;
      }
    },

    async loadLastTransaction () {
      try {
        const response = await this.$store.dispatch(
          'balance/getTransactions',
          {
            limit: 1,
            business: this.business
          }
        );

        const transaction = response?.results[0] || {};

        this.lastTransaction = {
          balance: transaction.balance || 0,
          created: filters.getDateTime(transaction.created)
        };
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.balance-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
