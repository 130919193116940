<template>
  <el-tooltip
    v-if="isVisible"
    effect="light"
    placement="top"
    @mouseleave="isVisible = false"
  >
    <div slot="content">
      <div class="pre">{{ val }}</div>
    </div>
    <div class="pre fix">{{ value }}</div>

    <span>
      <slot />
    </span>
  </el-tooltip>

  <div v-else @click.shift="isVisible = true">
    <slot />
  </div>
</template>

<script>
import get from 'lodash/get';

const entities = {
  ware: [
    'id',
    'ware.id',
    'index',
    'seller.id',
    'point_of_sale.id',
    'warehouse.id',
    'price_def.id',
    'price',
    'delivery_time',
    'rank',
    'rank_debug',
    'updated'
  ]
};

const renameFields = {
  rank_debug: 'score_debug'
};

export default {
  name: 'DebugInfo',

  props: {
    value: { type: Object, required: true },
    fields: { type: Array, default: () => [] },
    mode: String
  },

  data: () => ({
    isVisible: false
  }),

  computed: {
    val () {
      if (!this.fields.length && !this.mode) {
        return this.value;
      }

      const fields = this.mode ? entities[this.mode] : this.fields;

      return fields.reduce((acc, key) => {
        acc[renameFields[key] || key] = get(this.value, key, null);
        return acc;
      }, {});
    }
  }
};
</script>
