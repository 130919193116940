<template>
  <div class="order-info">
    <el-row>
      <div class="label">{{ $t('basket.display_public_id') }}:</div>
      <div class="fl-1">
        <span class="bold">{{ value.display_public_id }}</span>
        ({{ getDateTime(value.created) }})

        <span v-if="value.is_one_click_order">
          <TheTooltip :content="$t('orders.fastOrder')">
            <span class="pg-05 required" />
          </TheTooltip>
        </span>
      </div>
    </el-row>

    <el-row>
      <div class="label">{{ $t('basket.seller') }}:</div>
      <div class="fl-1">
        <el-link
          type="primary"
          class="bold"
          @click="$emit(
            'sale-point:info',
            get(value, 'point_of_sale_detail.id')
          )"
        >
          {{ get(value, 'point_of_sale_detail.name', '') }}
        </el-link>

        ({{ get(value, 'point_of_sale_detail.address.city_detail.name', '') }})
      </div>
    </el-row>

    <el-row>
      <div class="label">{{ $t('basket.sellerPhones') }}:</div>
      <div class="fl-1">{{ phones }}</div>
    </el-row>

    <el-row v-if="value.payment_method">
      <div class="label">{{ $t('basket.payment_method') }}:</div>
      <div class="fl-1">{{ $t(`payment_methods.${value.payment_method}`) }}</div>
    </el-row>

    <el-row v-if="value.delivery_method">
      <div class="label">{{ $t('orders.deliveryMethod') }}: </div>
      <div>{{ deliveryMethod }}</div>
    </el-row>

    <el-row v-if="get(value, 'delivery_address.service_name')">
      <div class="label">{{ $t('orders.deliverer') }}: </div>
      <div class="fl-1">{{ get(value, 'delivery_address.service_name', '') }}</div>
    </el-row>

    <el-row v-if="address">
      <div class="label">{{ $t('orders.address') }}: </div>
      <div class="bold fl-1">{{ address }}</div>
    </el-row>

    <el-row v-if="value.recipient_first_name">
      <div class="label">{{ $t('basket.orderRecipient') }}:</div>
      <div class="bold fl-1">{{ buyer }}</div>
    </el-row>

    <el-row v-if="buyerPhone">
      <div class="label">{{ $t('basket.recipient_phone_number') }}:</div>
      <div class="bold fl-1">{{ buyerPhone }}</div>
    </el-row>
  </div>
</template>

<script>
import get from 'lodash/get';
import getCContacts from '../services/getConcatedContacts';
import getCRLabel from '../services/getConcatedRecipientLabel';
import { filters } from '../../core';
import getConcatedAddress from '@/lib/salePoints/services/getConcatedAddress';

export default {
  name: 'OrderInfo',
  components: {},

  props: {
    value: { type: Object, required: true }
  },

  computed: {
    phones () {
      const p = this.value.point_of_sale_detail?.phone_numbers || [];
      return getCContacts(p, 'phone_number');
    },

    deliveryMethod () {
      const type = this.value.delivery_address?.type;
      const method = this.value.delivery_method;

      return type === 'carrier_in_country'
        ? this.$t(`deliveryServicesType.${type}`)
        : this.$t(`delivery_methods.${method}`);
    },

    address () {
      const address = getConcatedAddress(
        this.value.delivery_address,
        { hideCountry: true }
      );

      const officeNum = this.value.delivery_address?.service_office_number;
      const serviceOffice = officeNum
        ? `, ${this.$t('orders.service_office_number')} № ${officeNum}`
        : '';

      return `${address} ${serviceOffice}`;
    },

    buyer () {
      return getCRLabel(this.value, { hidePhone: true });
    },

    buyerPhone () {
      const { recipient_phone_number, phone_number, buyer_phone_number } = this.value;
      return recipient_phone_number?.phone_number ||
        phone_number?.phone_number ||
        buyer_phone_number?.phone_number || ''
      ;
    }
  },

  methods: {
    get,
    getDateTime: filters.getDateTime
  }
};
</script>

<style scoped lang="scss">
.order-info {
  .label {
    min-width: 0;
    text-align: right;
    width: 130px!important;
    margin-right: 5px;
  }

  > .el-row {
    margin-bottom: 10px;
  }
}
</style>
