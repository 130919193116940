<template>
  <div class="warehouse-input" @click="toggleMode">
    <TheTooltip :content="$t('buttons.edit')">
      <el-input
        ref="input"
        v-model="val"
        :disabled="disabled"
        @blur="updateWarehouseName"
        @keydown.enter.native.stop.prevent="updateWarehouseName"
      />
    </TheTooltip>
    <div class="el-form-item__error">{{ existedError }}</div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { eventBus } from '@/lib/core';

export default {
  name: 'WarehouseInput',

  props: {
    params: { type: Object, required: true },
    dependency: { type: Object, required: true }
  },

  data () {
    const originVal = get(this.params._parent, 'quantity_warehouse.name', '');

    return {
      originVal,
      val: '',
      disabled: true
    };
  },

  computed: {
    usedValues () {
      return (this.dependency.column_mapping || []).reduce((acc, item) => {
        if (
          item.type === 'quantity' &&
          item.quantity_warehouse &&
          item.quantity_warehouse.name !== this.originVal
        ) {
          acc.push(item.quantity_warehouse.name);
        }
        return acc;
      }, []);
    },

    businessUrl () {
      return this.$store.getters['businessProfiles/currentBusinessProfile'].url;
    },

    existedError () {
      return this.usedValues.includes(this.val)
        ? this.$t('validation.simple.existedValue')
        : null;
    }
  },

  async created () {
    this.val = this.originVal;

    if (!this.originVal) {
      await this.createDefaultWarehouse();
      this.disabled = true;
    }
  },

  methods: {
    async createDefaultWarehouse () {
      const rowWarehouse = {
        is_active: false,
        name: this.getWarehouseDefaultName(),
        business: this.businessUrl
      };

      try {
        const warehouse = await this.$store.dispatch('warehouses/postWarehouse', rowWarehouse);
        this.emit(warehouse);
      } catch (e) {
        this.disabled = false;
        this.$refs.input.focus();
        console.error(e);
      }
    },

    async updateWarehouseName () {
      if (!this.val || this.val === this.originVal || this.existedError) {
        this.val = this.originVal;
        this.disabled = true;
        return null;
      }

      try {
        const warehouse = await this.$store.dispatch('warehouses/patchWarehouse', {
          id: this.params._parent.quantity_warehouse.id,
          formData: { name: this.val }
        });

        this.emit(warehouse);
        this.disabled = true;
      } catch (e) {
        this.disabled = false;
        console.error(e);
      }
    },

    emit (warehouse) {
      this.$emit('warehouse:update', { params: this.params, warehouse });
      eventBus.$emit('warehouses:create', warehouse);
    },

    getWarehouseDefaultName () {
      const { price, formData } = this.dependency;
      const { _parent } = this.params;
      const colName = formData.header_row
        ? price.data_preview[formData.header_row - 1][_parent.column - 1]
        : '';
      const lastIndex = this.usedValues.length;

      return colName || `Stock ${formData.name} №${lastIndex ? lastIndex + 1 : 1}`;
    },

    toggleMode () {
      this.disabled = false;
      setTimeout(() => this.$refs.input && this.$refs.input.focus(), 100);
    }
  }
};
</script>

<style scoped lang="scss">
.warehouse-input {
  ::v-deep .el-input.is-disabled input {
    cursor: text!important;
  }

  .disabled ::v-deep input {
    background-color: $grey-100;
    color: $grey-300;
  }

  .error-message {
    font-size: .8rem;
    line-height: 14px;
  }
}
</style>
