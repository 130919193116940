import last from 'lodash/last';
import { googleTagsKeys } from '@/lib/core/services/library';
import env from '@/lib/core/services/envConstants';
import {
  getParsedEcommerceData,
  getAddToBasketEcommerceData,
  getViewItemEcommerceData
} from '@/lib/orders/services/gtmEventParsers';

export const state = () => ({
  gtags: {}
});

export const mutations = {
  setGtags: (state, gtags) => { state.gtags = gtags; }
};

export const actions = {
  async postClick ({ rootGetters }, payload) {
    if (!env.USE_SEO_ANALYTICS) {
      return null;
    }

    try {
      return await this.$axios.$post('/click/', payload);
    } catch (e) {
      console.error(e);
    }
  },

  pushCheckoutEvent ({ getters }, { step, products }) {
    if (!env.USE_SEO_ANALYTICS || !env.USE_SEO_GTM) {
      return null;
    }

    this.$gtm.push({ ecommerce: null }); // Clear the previous ecommerce object.
    this.$gtm.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step },
          products
        }
      },
      ...getters.gtags
    });

    console.info('EVENT pushCheckoutEvent gtm:', last(window.dataLayer));
  },

  pushPurchaseEvent (_, { options, rawData }) {
    if (!env.USE_SEO_ANALYTICS || !env.USE_SEO_GTAG) {
      return null;
    }

    if (!options.doNotClear) {
      this.$gtm.push({ ecommerce: null });
    }

    this.$gtm.push({
      event: 'purchase',
      ecommerce: getParsedEcommerceData({
        ...rawData,
        currency: env.MARKETPLACE_CURRENCY
      })
    });

    console.info('EVENT pushPurchaseEvent gtm:', last(window.dataLayer));
  },

  pushAddToBasketEvent (_, { rawData, options }) {
    if (!env.USE_SEO_ANALYTICS || !env.USE_SEO_GTAG) {
      return null;
    }

    if (!options.doNotClear) {
      this.$gtm.push({ ecommerce: null });
    }

    this.$gtm.push({
      event: 'add_to_cart',
      ecommerce: getAddToBasketEcommerceData({
        currency: env.MARKETPLACE_CURRENCY,
        ...rawData
      }, options)
    });

    console.info('EVENT getAddToBasketEcommerceData gtm:', last(window.dataLayer));
  },

  pushWarePageShowEvent (_, { rawData, options }) {
    if (!env.USE_SEO_ANALYTICS || !env.USE_SEO_GTAG) {
      return null;
    }

    if (!options?.doNotClear) {
      this.$gtm.push({ ecommerce: null });
    }

    this.$gtm.push({
      event: 'view_item',
      ecommerce: getViewItemEcommerceData({
        currency: env.MARKETPLACE_CURRENCY,
        ...rawData
      }, options)
    });

    console.info('EVENT pushWarePageShowEvent gtm:', last(window.dataLayer));
  },

  pushOrderToDatalayer2 ({ getters }, eventData) {
    if (!env.USE_SEO_ANALYTICS || !env.USE_SEO_GTM) {
      return null;
    }

    // Clear the previous ecommerce object.
    this.$gtm.push({ ecommerce: null });

    this.$gtm.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: env.MARKETPLACE_CURRENCY,
        add: {
          products: [eventData]
        }
      },
      ...getters.gtags
    });

    console.info('EVENT pushOrderToDatalayer2 gtm:', last(window.dataLayer));
  },

  pushOrderToDatalayer ({ getters }, { step, order }) {
    if (!env.USE_SEO_ANALYTICS || !env.USE_SEO_GTM) {
      return null;
    }

    const ecommerce = {
      purchase: {
        actionField: {
          id: order.display_public_id,
          revenue: order.total_amount,
          affiliation: order.seller_detail?.name || 'aff_id',
          tax: 0,
          shipping: 0
        },
        products: order.items.map(item => ({
          name: item.display_title,
          id: item.display_article,
          price: item.price,
          brand: item.display_trademark,
          quantity: item.quantity
        }))
      }
    };

    this.$gtm.push({
      event: 'purchase',
      ...getters.gtags,
      ecommerce
    });

    console.info('EVENT pushOrderToDatalayer gtm:', last(window.dataLayer));
  },

  // ['seller_info_show', 'checkout_1_step', 'clever-search']
  pushEvent ({ getters }, event) {
    if (!env.USE_SEO_ANALYTICS || !env.USE_SEO_GTM) {
      return null;
    }

    const payload =
      typeof event === 'string'
        ? { event, ...getters.gtags }
        : { ...event, ...getters.gtags };

    this.$gtm.push(payload);

    console.info('EVENT pushEvent gtm:', window.dataLayer);
  },

  pushGtagEvent ({ getters }, { vm, ...payload }) {
    if (!env.USE_SEO_ANALYTICS || !env.USE_SEO_GTAG) {
      return null;
    }

    this.$gtag('event', 'page_view', {
      send_to: env.GOOGLE_GTAG_ID,
      event: payload.event,
      value: payload.value,
      price: payload.price,
      items: [
        {
          id: payload.id,
          category: payload.category,
          google_business_vertical: 'retail'
        }
      ],
      ...getters.gtags
    });

    console.info('EVENT pushGtagEvent gtag:', last(window.dataLayer));
  },

  async checkAndSetGtags ({ dispatch, commit, getters }, checkObject) {
    if (Object.keys(checkObject).some(key => googleTagsKeys.includes(key))) {
      const gtags = Object
        .entries(checkObject)
        .reduce((acc, [key, val]) => {
          if (googleTagsKeys.includes(key)) {
            acc[key] = val;
          }
          return acc;
        }, {});

      commit('setGtags', { ...getters.gtags, ...gtags });
      await dispatch('setGtagsToCookie', { gtags });
    }

    return getters.gtags;
  },

  async setGtagsToCookie ({ dispatch }, { gtags }) {
    const res = Object
      .entries(gtags)
      .reduce((acc, [key, value]) => {
        acc.push(
          dispatch('setCookie', { name: `_tt${key}`, value }, { root: true }),
          dispatch('setCookie', { name: key, value }, { root: true })
        );
        return acc;
      }, []);

    await Promise.all(res);

    return gtags;
  },

  setLocationToGtag ({ getters }) {
    if (this.$gtag) {
      const loc = `${env.BASE_CLIENT_PATH}${this.$router.currentRoute.fullPath}`;

      this.$gtag('set', 'location', loc);
      this.$gtag('set', 'referrer', loc);

      Object.entries(getters.gtags).forEach(([key, value]) => {
        this.$gtag('set', key, value);
      });

      this.$gtag('set', { location: loc, ...getters.gtags });

      this.$gtag('send', 'pageview');
    }
  }
};

export const getters = {
  gtags: state => state.gtags
};
