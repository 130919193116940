<template>
  <el-button
    circle
    class="back-to-top back-shadow"
    icon="el-icon-caret-top"
    @click="scrollToTop"
  />
</template>

<script>
import scrollToTop from '../services/scrollToTop';

export default {
  name: 'BackToTop',

  props: {
    target: { type: [Number, String], default: () => 0 },
    offset: Number
  },

  methods: {
    scrollToTop () {
      scrollToTop(this.target, { offset: this.offset });
    }
  }
};
</script>

<style scoped lang="scss">
.back-to-top {
  position: fixed;
  bottom: 50px;
  right: 20px;
  background-color: $white;
  color: $blue-400;
  font-weight: bold;
  z-index: 100;
}
</style>
