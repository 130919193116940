<template>
  <div class="contacts no-wrap-error">
    <div v-if="!readonly && !hideDescription" class="description">
      <p>{{ $t('salePoint.setPhones') }}</p>
    </div>

    <el-form-item class="mt-2 phone-numbers" prop="phone_numbers">
      <el-row
        v-for="(phone, index) in formData.phone_numbers"
        :key="index"
        align="middle"
        class="row mb-1"
      >
        <el-form-item
          label-width="100px"
          :label="`${$t('labels.phone')} ${index + 1}`"
          class="mb-0 mr-1 row-item"
          :prop="`phone_numbers.${index}.phone_number`"
          :rules="rules.phone_number"
        >
          <PhoneInput
            v-model="phone.phone_number"
            :disabled="readonly"
            @blur="fixPhoneNumber(phone)"
            @input="emit('phone_numbers', formData.phone_numbers)"
          />
        </el-form-item>

        <div class="w-250p mr-1">
          <el-row v-if="index === 0" class="w-250p checkbox-labels">
            <el-col
              v-for="channel in channels"
              :key="channel"
              :span="24 / channels.length"
            >
              <span>{{ $t(`phoneChannels.${channel}`) }}</span>
            </el-col>
          </el-row>

          <el-checkbox-group
            v-model="phone.channels"
            :disabled="readonly"
            @input="emit('phone_numbers', formData.phone_numbers)"
          >
            <el-row class="w-250p">
              <el-col
                v-for="channel in channels"
                :key="channel"
                :span="24 / channels.length"
              >
                <el-checkbox :label="channel"><span /></el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </div>

        <div class="mr-1">
          <el-input
            v-model="phone.description"
            class="description"
            :disabled="readonly"
            :placeholder="$t('placeholders.comment')"
            @input="emit('phone_numbers', formData.phone_numbers)"
          />
        </div>

        <div v-if="!readonly" class="mr-1">
          <el-radio
            :disabled="readonly"
            :label="true"
            :value="phone.is_primary"
            @click.native="setActive('phone_numbers', index)"
          >
            {{ $t('labels.main') }}
          </el-radio>
        </div>

        <RowIconControls
          v-if="!readonly"
          :index="index"
          :item="phone"
          :list="formData.phone_numbers"
          :need-one="isPhoneRequired(rules)"
          value-name="phone_number"
          @remove="removeItem('phone_numbers', index)"
        />
      </el-row>

      <el-row>
        <el-button v-if="!readonly" @click="addItemTo('phone_numbers', 'phone')">
          <i class="el-icon-plus" />
          {{ $t('buttons.addSome', { name: $t('labels.phone').toLowerCase() }) }}
        </el-button>
      </el-row>
    </el-form-item>

    <div v-if="!readonly && !hideDescription" class="description">
      <p>{{ $t('salePoint.setEmails') }}</p>
    </div>

    <el-form-item v-if="!hideEmails" class="mt-2 emails" prop="emails">
      <el-row
        v-for="(email, index) in formData.emails"
        :key="index"
        align="middle"
        class="row mb-1"
        type="flex"
      >
        <el-form-item
          label-width="100px"
          :label="`${$t('labels.email')} ${index + 1}`"
          class="mb-0 mr-1"
          :prop="`emails.${index}.email`"
          :rules="rules.email"
        >
          <el-input
            v-model="email.email"
            class="w-200p"
            :disabled="readonly"
            type="email"
            @input="emit('emails', formData.emails)"
          />
        </el-form-item>

        <div class="mr-1">
          <el-input
            v-model="email.description"
            class="description"
            :disabled="readonly"
            :placeholder="$t('placeholders.comment')"
            @input="emit('emails', formData.emails)"
          />
        </div>

        <div v-if="!readonly" class="mr-1">
          <el-radio
            :disabled="readonly"
            :label="true"
            :value="email.is_primary"
            @click.native="setActive('emails', index)"
          >
            {{ $t('labels.main') }}
          </el-radio>
        </div>

        <RowIconControls
          v-if="!readonly"
          :index="index"
          :item="email"
          :list="formData.emails"
          :need-one="isEmailRequired(rules)"
          value-name="email"
          @remove="removeItem('emails', index)"
        />
      </el-row>

      <el-row>
        <el-button v-if="!readonly" @click="addItemTo('emails', 'email')">
          <i class="el-icon-plus" />
          {{ $t('buttons.addSome', { name: $t('labels.email').toLowerCase() }) }}
        </el-button>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import RowIconControls from '../RowIconControls';
import parsers from '@/lib/core/services/parsers';
import { getDefault } from '@/lib/core/services/defaultEntities';
import PhoneInput from '@/lib/core/components/PhoneInput';

export default {
  name: 'Contacts',

  components: {
    PhoneInput,
    RowIconControls
  },

  props: {
    hideDescription: Boolean,
    hideEmails: Boolean,
    readonly: Boolean,
    value: { type: Object, required: true },
    rules: { type: Object, required: true }
  },

  computed: {
    formData: {
      get () {
        return cloneDeep(this.value);
      }
    },

    channels () {
      return this.$store.getters['dependencies/communicationChannels']
        .map(({ label }) => label)
        .filter(code => code !== 'phone');
    }
  },

  created () {
    if (!this.channels.length) {
      this.loadChannels();
    }
  },

  methods: {
    fixPhoneNumber: parsers.fixPhoneNumber,

    loadChannels () {
      try {
        return this.$store.dispatch('dependencies/fetchCommunicationChannels');
      } catch (e) {
        console.error(e);
      }
    },

    isEmailRequired (rules) {
      return rules.emails && rules.emails.some(rule => rule.required);
    },

    isPhoneRequired (rules) {
      const { phone_numbers } = rules;
      return phone_numbers && phone_numbers.some(rule => rule.required);
    },

    setActive (name, itemIndex) {
      this.formData[name].forEach((item, index) => {
        item.is_primary = itemIndex === index;
      });

      this.emit(name, this.formData[name]);
    },

    addItemTo (listName, name) {
      const entity = getDefault(name);

      if (this.formData[listName].some(({ is_primary }) => is_primary)) {
        entity.is_primary = false;
      }

      this.formData[listName].push(entity);

      this.emit(listName, cloneDeep(this.formData[listName]));
    },

    emit (name, value) {
      this.$emit(`update:${name}`, value);
    },

    removeItem (listName, index) {
      this.formData[listName].splice(index, 1);
      this.emit(listName, this.formData[listName]);
    }
  }
};
</script>

<style scoped lang="scss">
.contacts {
  overflow: auto;

  .emails, .phone-numbers {
    min-width: 1020px;

    ::v-deep {
      .el-form-item__error {
        overflow: visible;
        white-space: nowrap;
        bottom: -12px
      }
    }
  }

  ::v-deep .el-input {
    width: 100%;
  }

  .row-item ::v-deep .el-form-item__content {
    display: flex!important;
    width: 150px;
  }

  .label {
    white-space: nowrap;
    margin-right: 4px;
  }

  .description {
    margin-top: -3px;
  }

  .row {
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;

      .el-col {
        text-align: center;
      }
    }

    .checkbox-labels {
      font-size: .8rem;
      position: absolute;
      top: -20px;
      padding: 0;

      .el-col {
        text-align: center;
      }
    }

    .el-checkbox {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
}
</style>
