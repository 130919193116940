import { env } from '@/lib/core';

const limit = 50;

export const actions = {
  async getCountries (_, { search }) {
    const { results } = await this.$axios.$get(
      '/countries/',
      {
        progress: false,
        params: { limit, search }
      }
    );

    return results;
  },

  getCountryById (_, { id }) {
    return this.$axios
      .$get(`/countries/${id}/`, { progress: false });
  },

  async getAdmin1ById (_, { id }) {
    const [admin1] = await this.$axios.$get('/admin1/', {
      params: { id },
      progress: false
    });

    return admin1;
  },

  async getAdmin1s (_, { search, country }) {
    const { results } = await this.$axios.$get(
      '/admin1/',
      {
        params: { limit, search, country },
        progress: false
      }
    );

    return results;
  },

  async getCities (_, { search, country, admin1 }) {
    const { results } = await this.$axios.$get(
      '/cities/',
      {
        params: { limit, search, country, admin1 },
        progress: false
      }
    );

    return results;
  },

  getCitiesByIds ({ dispatch }, { ids }) {
    return this.$axios
      .$get('/cities/', { params: { id_in: ids.toString() } });
  },

  getCurrentCity () {
    return this.$axios.$get('/cities/current/');
  },

  getCityById (_, { id }) {
    return this.$axios.$get(`/cities/${id}/`, { progress: false });
  },

  async searchByQuery (_, { query }) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: `${env.OSM_SEARCH_URL}?format=json&q=${query}`
    });

    return data;
  }
};
