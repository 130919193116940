import { getClearSearchFilter } from '@/lib/search/services/searchFilter';

export default function (params, query, store) {
  const {
    q,
    wareId,
    wareIndex,
    tab,
    page,
    trademark,
    article,
    ..._query
  } = query;

  const filter = getClearSearchFilter(_query || {});
  const city = store.getters.cityId;
  const langCode = store.getters.langCode;

  return {
    trademark,
    article,
    q,
    ware: wareIndex || params.wareIndex || wareId,
    wareId,
    query,
    city,
    langCode,
    page: Number(params.page || query.page) || 1,
    filter: {
      ...filter,
      _debug_: !!query.debug || undefined
    }
  };
};
