<template>
  <div class="photo-gallery">
    <div v-if="readonly && !photos.length">{{ $t('errors.emptyData') }}</div>

    <PhotoList :photos="photos" :readonly="readonly" v-on="listeners" />

    <el-upload
      ref="upload"
      action="https://mp-api.dev.mriyar.ua/"
      :auto-upload="false"
      :disabled="readonly"
      list-type="picture"
      :multiple="true"
      :on-change="change"
      :show-file-list="false"
    >
      <el-button
        v-if="!readonly"
        slot="trigger"
        size="small"
        type="success"
      >
        {{ $t('buttons.upload') }}
      </el-button>
    </el-upload>
  </div>
</template>

<script>
import ElUpload from 'element-ui/lib/upload';
import PhotoList from './PhotoList';

export default {
  name: 'PhotoGallery',

  components: {
    ElUpload,
    PhotoList
  },

  props: {
    readonly: Boolean,
    value: { type: Array, required: true }
  },

  data () {
    return {
      photos: [],
      listeners: {
        remove: this.remove,
        primary: this.setPrimary
      }
    };
  },

  watch: {
    photos: {
      deep: true,
      handler: 'emit'
    }
  },

  created () {
    this.photos = this.value.slice(0);
  },

  methods: {
    change (file) {
      file.id = file.id || file.uid;
      this.photos.push(file);
      this.setDefaultPrimary();
    },

    remove (file) {
      const index = this.photos.findIndex(item => item.id === file.id);
      this.photos.splice(index, 1);
      this.setDefaultPrimary();
    },

    setDefaultPrimary () {
      if (!this.photos.some(photo => photo.is_primary)) {
        this.$set(this.photos[0], 'is_primary', true);
      }
    },

    setPrimary (file) {
      const fileId = file.id || file.uid;
      this.photos.forEach((item) => {
        const { id, uid } = item;
        this.$set(item, 'is_primary', [id, uid].includes(fileId));
      });
    },

    emit () {
      this.$emit('input', this.photos.slice(0));
    }
  }
};
</script>

<style scoped lang="scss">
.photo-gallery {
  & > div {
    display: flex;
    flex-direction: column-reverse;
  }

  ::v-deep .el-upload {
    text-align: left;
  }
}
</style>
