var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.formData, rules: _vm.rules, size: "mini" },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.theSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "el-checkbox-group",
        {
          staticClass: "cell",
          model: {
            value: _vm.formData.delivery_methods,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "delivery_methods", $$v)
            },
            expression: "formData.delivery_methods",
          },
        },
        [
          _vm._l(_vm.deliveryMethods, function (item) {
            return [
              _c(
                "div",
                { key: item },
                [
                  _c("el-checkbox", { attrs: { label: item } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("delivery_methods." + item)) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.formData.delivery_methods.includes("carrier_in_country"),
              expression:
                "formData.delivery_methods.includes('carrier_in_country')",
            },
          ],
          staticClass: "mt-2",
        },
        [
          _c("DeliveryServicesTable", {
            attrs: { value: _vm.filteredServices },
            on: { input: _vm.setDeliveryService, save: _vm.theSubmit },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-4 mb-4" },
        [
          _c("div", [
            _vm._v(_vm._s(_vm.$t("salePoint.delivery_methods_text"))),
          ]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { rows: 5, type: "textarea" },
            model: {
              value: _vm.formData.delivery_methods_text,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "delivery_methods_text", $$v)
              },
              expression: "formData.delivery_methods_text",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mt-4", attrs: { justify: "end" } },
        [
          _c("el-button", { on: { click: _vm.cloneOrigin } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("buttons.cancel")) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isEqual,
                "native-type": "submit",
                type: "primary",
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.save")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }