var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("TheLoader")
    : _c(
        "el-form",
        {
          ref: "businessReq",
          staticClass: "mt-2",
          attrs: {
            "label-position": "right",
            "label-width": "300px",
            model: _vm.formData,
            rules: _vm.rules,
            size: "mini",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "outline-block" },
            [
              _c("div", { staticClass: "outline-block__title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("profile.mainRequisitesBlock")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "MainRequisitesBlock",
                _vm._b(
                  { attrs: { value: _vm.formData } },
                  "MainRequisitesBlock",
                  _vm.formData,
                  false,
                  true
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "outline-block" },
            [
              _c("div", { staticClass: "outline-block__title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("profile.contactRequisitesBlock")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "ContactRequisitesBlock",
                _vm._b(
                  { attrs: { value: _vm.formData } },
                  "ContactRequisitesBlock",
                  _vm.formData,
                  false,
                  true
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formData.type
            ? [
                _vm.formData.type !== "physical_person"
                  ? _c(
                      "div",
                      { staticClass: "outline-block" },
                      [
                        _c("div", { staticClass: "outline-block__title" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("profile.bankRequisitesBlock")) +
                              "\n      "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "BankRequisitesBlock",
                          _vm._b(
                            { attrs: { value: _vm.formData.bank_requisites } },
                            "BankRequisitesBlock",
                            _vm.formData.bank_requisites,
                            false,
                            true
                          )
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.formData.type !== "physical_person"
                  ? _c(
                      "div",
                      { staticClass: "outline-block" },
                      [
                        _c("div", { staticClass: "outline-block__title" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t("profile.scanFilesRequisitesBlock")
                              ) +
                              "\n      "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "uploader",
                            attrs: {
                              "label-width": "420px",
                              prop: "udrpou_page_1",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t(
                                                "requisites.scan.stateRegister"
                                              )
                                            ) +
                                            ": "
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-small" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "requisites.scan.stateRegisterDesc"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3511788317
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("ScanFilesRequisitesBlock", {
                              attrs: {
                                label: _vm.$t("requisites.scan.stateRegister"),
                              },
                              model: {
                                value: _vm.formData.udrpou_page_1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "udrpou_page_1", $$v)
                                },
                                expression: "formData.udrpou_page_1",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("requisites.scan.stateRegister2"),
                              "label-width": "420px",
                              prop: "udrpou_page_2",
                            },
                          },
                          [
                            _c("ScanFilesRequisitesBlock", {
                              model: {
                                value: _vm.formData.udrpou_page_2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "udrpou_page_2", $$v)
                                },
                                expression: "formData.udrpou_page_2",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "uploader",
                            attrs: {
                              "label-width": "420px",
                              prop: "bank_requisites.iban_page",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t(
                                                "requisites.scan.accountOpen"
                                              )
                                            ) +
                                            ": "
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("small", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "requisites.scan.accountOpenDesc"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1202585191
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("ScanFilesRequisitesBlock", {
                              model: {
                                value: _vm.formData.bank_requisites.iban_page,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.bank_requisites,
                                    "iban_page",
                                    $$v
                                  )
                                },
                                expression:
                                  "formData.bank_requisites.iban_page",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formData.is_vat_payer
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("requisites.scan.pdv"),
                                  "label-width": "420px",
                                  prop: "itn_of_vat_payer_page",
                                },
                              },
                              [
                                _c("ScanFilesRequisitesBlock", {
                                  model: {
                                    value: _vm.formData.itn_of_vat_payer_page,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "itn_of_vat_payer_page",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formData.itn_of_vat_payer_page",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c("div", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("requisites.actualData"))),
                ]),
                _vm._v(" "),
                _vm.platform
                  ? _c(
                      "div",
                      { staticClass: "mb-1 required" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "reverse",
                            model: {
                              value: _vm.checked.terms,
                              callback: function ($$v) {
                                _vm.$set(_vm.checked, "terms", $$v)
                              },
                              expression: "checked.terms",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("requisites.accept_term")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.platform
                  ? _c("span", { staticClass: "print-document" }, [
                      _c("i", { staticClass: "el-icon-printer" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("requisites.print_document")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { justify: "end" } },
                [
                  !_vm.platform
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.isEqual || !_vm.formData.type,
                            "native-type": "submit",
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("buttons.save")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: !_vm.checked.terms || !_vm.formData.type,
                            "native-type": "submit",
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-goods" }),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("requisites.send_connect")) +
                              "\n      "
                          ),
                        ]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }