<template>
  <div class="seller-order-page">
    <h1 class="custom-headline custom-headline--level2-bold">
      {{ $t('routes.profile_business_seller_orders_order') }}
    </h1>

    <TheLoader v-if="isLoader" />

    <template v-else>
      <OrderInfo class="mb-1" :value="order" />

      <SellerOrderItemsTable
        class="mb-2"
        hide-bottom-status-select
        :prop-value="items"
        @item:update="updateItem"
      />

      <div v-if="order.no_call_required || order.comment" class="underline-block">
        <div class="title">{{ $t('basket.commentForm') }}</div>
        <div class="content">{{ order.comment }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import BuyerOrderPage from './BuyerOrderPage';
import SellerOrderItemsTable from './components/SellerOrderItemsTable';
import { useAsyncDebounce, parsers, filters, env } from '@/lib/core';

export default {
  name: 'SellerOrderPage',

  extends: BuyerOrderPage,

  components: {
    SellerOrderItemsTable
  },

  data () {
    return {
      isLoader: true
    };
  },

  computed: {
    sellerId () {
      return this.$store.getters.sellerId;
    }
  },

  methods: {
    async load () {
      try {
        const items = await this.loadItems();

        if (this.sellerId !== items[0].order_detail?.seller_detail?.id) {
          return this.$nuxt.error({ statusCode: 404 });
        }

        this.order = items[0]?.order_detail;
        this.items = items.map(this.getParsed);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoader = false;
      }
    },

    loadItems () {
      try {
        return this.$store.dispatch(
          'orderItems/getItems',
          { order: this.$route.params.id }
        );
      } catch (e) {
        console.error(e);
      }
    },

    getParsed (item) {
      return {
        ...item,
        _waybillDate: filters.getDate(item.shipment_detail?.waybill_date),
        _price: parsers.getPriceNum(item.price),
        _priceSum: parsers.getPriceNum(item.price * item.quantity),
        _orderId: Number(this.$route.params.id),
        _pointUrl: this.order.point_of_sale_detail?.url,
        _buyerUrl: item.order_detail?.buyer,
        _deliveryDate: filters.getDate(item.delivery_date),
        _priceFileName: item.price_file_detail?.name || '-',
        _priceFileId: item.price_file_detail?.id,
        _canRevertStatus: false,
        _revertStatusTimer: useAsyncDebounce({ debounce: env.STATUS_REVERT_DEBOUNCE })
      };
    },

    async updateItem (formData) {
      const { id, multiple, ..._formData } = formData;

      try {
        const item = await this.$store.dispatch(
          'orderItems/patchItem',
          { id, formData: _formData }
        );

        const parsedItem = this.getParsed(item);

        if (!multiple && _formData.status && _formData.status !== 'issued') {
          this.addStartRevertStatusTimer(parsedItem);
        }

        this.updateLocalItem(parsedItem);

        return parsedItem;
      } catch (e) {
        console.error(e);
      }
    }
  },

  validate ({ params }) {
    return /^\d+$/.test(params.id);
  }
};
</script>
