<template>
  <div class="price-uploader">
    <el-upload
      ref="upload"
      action="https://mp-api.dev.mriyar.ua/"
      :auto-upload="false"
      class="mb-1"
      drag
      :on-change="changeHandler"
      :on-remove="clear"
      :show-file-list="!hideList"
    >
      <div class="color-blue">
        {{ $t('files.dropOrClick') }}
      </div>
    </el-upload>

    <ul class="rules-list">
      <li v-for="(rule, index) in rules" :key="index">{{ rule }}</li>
    </ul>
  </div>
</template>

<script>
import ElUpload from 'element-ui/lib/upload';
import { FILE_FORMAT, library } from '@/lib/core';

const formatsStr = library.priceFileFormats.toString().replace(/,/g, ', ');

export default {
  name: 'PriceUploader',

  components: { ElUpload },

  props: {
    hideList: Boolean
  },

  data () {
    return {
      rules: [1, 2, 3].map(num => this.$t(`files.priceRules.${num}`, { formats: formatsStr }))
    };
  },

  computed: {
    priceFile: {
      get () {
        return this.value;
      },

      set (price) {
        this.$emit('input', price);
      }
    }
  },

  methods: {
    checkPriceFileFormat (file) {
      const format = file.name.match(FILE_FORMAT)[0];
      return library.priceFileFormats.includes(format);
    },

    changeHandler (file) {
      if (this.checkPriceFileFormat(file)) {
        this.$set(this.$refs.upload, 'uploadFiles', [file]);
        this.priceFile = file;
      } else {
        this.$notify.error({
          title: this.$t('errors.default.title'),
          message: this.$t('validation.price.format', { format: formatsStr })
        });

        this.$refs.upload.clearFiles();
      }
    },

    clear () {
      this.priceFile = null;
    }
  }
};
</script>

<style scoped lang="scss">
.price-uploader {
  ::v-deep .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
      height: auto;
      padding: 30px;
    }
  }

  .rules-list {
    padding-left: 20px;
    font-size: .8rem;
    opacity: .6;

    li {
      list-style: disc;
    }
  }
}
</style>
