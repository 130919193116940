<template>
  <el-card class="password-send-recovery-form">
    <nuxt-link class="close-icon" :to="{ name: getRouteName('auth') }">
      <i class="el-icon-close" />
    </nuxt-link>

    <span class="custom-headline custom-headline--level2-bold">
      {{ $t('auth.recoveryPassword') }}
    </span>

    <el-form ref="form" :model="formData" :rules="rules" @submit.native.prevent="submit">
      <el-form-item :label="$t('labels.email')" prop="email">
        <el-input v-model.trim="formData.email" />
      </el-form-item>

      <el-form-item>
        <el-button class="submit-btn" :loading="loading" native-type="submit" type="primary">
          {{ $t('buttons.recoveryPassword') }}
        </el-button>
      </el-form-item>

      <el-form-item>
        <nuxt-link :to="{ name: getRouteName('auth') }">
          {{ $t('auth.rememberPassword') }}
        </nuxt-link>
      </el-form-item>
    </el-form>

    <hr>
    <SignInLinks type="login" />
  </el-card>
</template>

<script>
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import SignInLinks from './SignInLinks';
import { getValidationFor, env } from '@/lib/core';

export default {
  name: 'PasswordSendRecoveryForm',

  components: {
    SignInLinks
  },

  data ({ $route }) {
    return {
      loading: false,
      rules: getValidationFor('email'),
      formData: { email: $route.query.email }
    };
  },

  mounted () {
    Vue.use(VueReCaptcha, {
      siteKey: env.GOOGLE_RECAPTCHA_KEY,
      loaderOptions: { autoHideBadge: true }
    });
  },

  methods: {
    async submit () {
      const captcha_token = this.$recaptcha && await this.$recaptcha('login');

      return this.$refs.form.validate(async (valid) => {
        if (valid && captcha_token) {
          this.loading = true;

          try {
            const { email } = await this.$store.dispatch('users/postSendRecoveryPassword', {
              email: this.formData.email,
              captcha_token
            });

            this.$notify.info({ message: this.$t('message.checkEmail') });

            await this.$router.push({
              name: this.getRouteName('auth.pass-recovery'),
              query: { email }
            });
          } finally {
            this.loading = false;
          }
        } else {
          if (!captcha_token) {
            this.$notify.error({
              title: this.$t('errors.captcha.title'),
              message: this.$t('errors.captcha.message')
            });
          }

          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/components/auth-form.scss";
</style>
