<template>
  <div class="sale-point-edit-page">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <el-row class="mb-1" justify="space-between" align="middle">
        <h1 class="custom-headline custom-headline--level2-bold">
          {{ salePoint.name }}
        </h1>

        <SalePointActivator
          left
          :point="salePoint"
          show-label
          @update="salePoint.is_active = $event"
        />
      </el-row>

      <el-tabs type="card" :value="activeTab" @tab-click="setActiveTab">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab"
          :label="$t(`salePoint.${tab}Tab`)"
          lazy
          :name="tab"
        >
          <component
            :is="`SalePoint${upperFirst(tab)}Tab`"
            class="mb-4"
            :entity="salePoint"
            entity-name="salePoint"
            :submit="submit"
            @input="salePoint = $event"
          />
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import SalePointActivator from './components/SalePointActivator';
import SalePointMainInfoTab from './components/SalePointMainInfoTab';
import SalePointDeliveryTab from './components/SalePointDeliveryTab';
import SalePointTrademarksTab from './components/SalePointTrademarksTab';
import SalePointDescriptionTab from './components/SalePointDescriptionTab';
import { parsers } from '@/lib/core';

export default {
  name: 'SalePointEditPage',

  components: {
    SalePointActivator,
    SalePointMainInfoTab,
    SalePointDeliveryTab,
    SalePointTrademarksTab,
    SalePointDescriptionTab
  },

  data: () => ({
    tabs: ['mainInfo', 'delivery', 'trademarks', 'description'],
    isLoading: true,
    salePoint: null
  }),

  computed: {
    activeTab () {
      return this.$route.query.tab || this.tabs[0];
    }
  },

  mounted () {
    this.ladSalePoint();
  },

  methods: {
    upperFirst,

    async ladSalePoint () {
      this.isLoading = true;

      try {
        const salePoint = await this.$store.dispatch(
          'salePoints/getSalePoint',
          { id: this.$route.params.id }
        );

        const id = parsers.getIdFromUrl(salePoint.business);

        if (this.$store.getters['businessProfiles/businessId'] !== id) {
          this.$nuxt.error({ statusCode: 404 });
        }

        this.salePoint = this.getParsed(salePoint);
        this.isLoading = false;
        return this.salePoint;
      } catch (e) {
        console.error(e);
        this.$nuxt.error(e);
      }
    },

    getParsed (salePoint) {
      return {
        ...salePoint,
        delivery_services: salePoint.delivery_services.map((it) => {
          const num = Number(it.free_ship_amount);
          return {
            ...it,
            free_ship_amount: num === 0 ? 0 : num
          };
        })
      };
    },

    setActiveTab ({ name }) {
      this.$router.replace({ query: { ...this.$route.query, tab: name } });
    },

    async submit (formData) {
      try {
        this.salePoint = await this.$store.dispatch(
          'salePoints/patchSalePoint',
          { id: this.salePoint.id, formData }
        );
      } catch (e) {
        console.error(e);
      }
    }
  },

  validate ({ params }) {
    return /^\d+$/.test(params.id);
  }
};
</script>
