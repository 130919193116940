<template>
  <div class="juridical-person-info">
    <el-form-item
      :label="$t('requisites.info.egrpoy')"
      prop="udrpou"
    >
      <el-input
        class="max-w-140p"
        :value="value.udrpou"
        @input="emit('udrpou', $event)"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.officialName')"
      prop="official_name"
    >
      <el-input
        class="max-w-420p"
        :value="value.official_name"
        @input="emit('official_name', $event); setShortName(value.short_name)"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.shortName')"
      prop="short_name"
    >
      <el-input
        class="max-w-210p"
        :value="value.short_name"
        @input="setShortName"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.pdv')"
      prop="is_vat_payer"
    >
      <el-radio-group
        :value="value.is_vat_payer"
        @input="emit('is_vat_payer', $event)"
      >
        <el-row class="mt-1">
          <el-radio :label="true">{{ $t('core.yes') }}</el-radio>
          <el-radio :label="false">{{ $t('core.no') }}</el-radio>
        </el-row>
      </el-radio-group>
    </el-form-item>

    <el-form-item
      v-if="value.is_vat_payer"
      :label="$t('requisites.info.certificate')"
      prop="itn_of_vat_payer"
    >
      <el-input
        class="max-w-182p"
        :value="value.itn_of_vat_payer"
        @input="emit('itn_of_vat_payer', $event)"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.info.address')"
      prop="juridical_address"
      required
    >
      <Address
        hide-description-input
        hide-label
        hide-map
        hide-no-address
        has-physical-address
        prop="juridical_address"
        :value="value.juridical_address"
        v-on="onAddressUpdate"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.signatory_full_name')"
      prop="signatory_full_name"
    >
      <el-input
        class="max-w-420p"
        :value="value.signatory_full_name"
        @input="emit('signatory_full_name', $event);"
      />
    </el-form-item>
  </div>
</template>

<script>
import Address from '@/lib/salePoints/components/formItems/Address';

export default {
  name: 'JuridicalPersonInfo',

  components: { Address },

  props: {
    value: { type: Object, required: true }
  },

  computed: {
    keys () {
      return Object.keys(this.value?.juridical_address || {});
    },

    onAddressUpdate () {
      return this.keys.reduce((acc, name) => {
        acc[`update:${name}`] = val => this.emitAddress(name, val);
        return acc;
      }, {});
    }
  },

  methods: {
    emitAddress (name, val) {
      const value = { ...this.value.juridical_address, [name]: val };
      this.emit('juridical_address', value);
    },

    setShortName (value) {
      const short_name = value.substr(
        0, Math.ceil(this.value.official_name.length / 2)
      );

      this.emit('short_name', short_name);
    },

    emit (name, value) {
      this.$emit(`update:${name}`, value);
    }
  }
};
</script>
