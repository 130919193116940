var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bank-requisites-block" },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.bank.name"),
            prop: "bank_requisites.name",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-420p",
            attrs: { value: _vm.value.name },
            on: {
              input: function ($event) {
                return _vm.emit("name", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.bank.mfo"),
            prop: "bank_requisites.bic",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-182p",
            attrs: { value: _vm.value.bic },
            on: {
              input: function ($event) {
                return _vm.emit("bic", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("requisites.bank.iban"),
            prop: "bank_requisites.iban",
          },
        },
        [
          _c("el-input", {
            staticClass: "max-w-182p",
            attrs: { value: _vm.value.iban },
            on: {
              input: function ($event) {
                return _vm.emit("iban", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }