import { i18n } from '@/plugins/i18n';

const required = i18n.t('validation.simple.required');

export default {
  name: [
    { required: true, message: required, trigger: 'blur' },
    { min: 1, message: i18n.t('validation.simple.min', { num: 1 }), trigger: 'blur' }
  ],
  currency: [
    { required: true, message: required, trigger: 'change' }
  ],
  header_row: [
    {
      required: true,
      trigger: 'change',
      validator: 'valOrNul'
    }
  ],
  data_start_row: [
    { required: true, message: required, trigger: 'change' }
  ],
  file_encoding: [
    { required: true, message: required, trigger: 'change' }
  ],
  csv_delimiter: [
    { required: true, message: required, trigger: 'change' },
    { max: 1, message: i18n.t('validation.simple.max', { num: 1 }), trigger: 'change' }
  ],
  file_type: [
    { required: true, message: required, trigger: 'change' }
  ],
  column_mapping: [
    {
      type: 'array',
      required: true,
      trigger: 'blur',
      validator: 'columnMapping'
    }
  ],
  trademark: [
    { validator: 'hasTrademark', trigger: 'change' }
  ],
  rowWithTitles: [
    { required: true, message: required, trigger: 'blur' }
  ],
  firstDataCol: [
    { required: true, message: required, trigger: 'blur' }
  ],
  availabilities: [
    { type: 'array', trigger: 'change', validator: 'availabilities' }
  ],
  auto_update_url: [
    { required: true, message: required, trigger: 'blur' },
    { max: 200, message: i18n.t('validation.simple.max', { num: 200 }), trigger: 'change' }
  ]
};
