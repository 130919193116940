import { phone_number, phone_numbers, email, emails } from './common';
import { SEARCH_ON_SITE_REG, SITE_REG } from './patterns';
import { i18n } from '@/plugins/i18n';

export default {
  phone_number,
  phone_numbers,
  email,
  emails,
  name: [
    {
      required: true,
      message: i18n.t('validation.simple.required'),
      trigger: 'blur'
    }
  ],
  is_seller: [
    {
      required: true,
      message: i18n.t('validation.simple.required'),
      trigger: 'blur'
    }
  ],
  contact_name: [
    {
      required: true,
      message: i18n.t('validation.simple.required'),
      trigger: 'blur'
    }
  ],
  site: [
    {
      pattern: SITE_REG,
      message: i18n.t('validation.site.pattern'),
      trigger: 'blur',
      transform: 'trim'
    },
    {
      max: 100,
      message: i18n.t('validation.simple.max', { num: 100 }),
      trigger: 'blur'
    }
  ],
  search_on_site_url: [
    {
      pattern: SEARCH_ON_SITE_REG,
      message: i18n.t('validation.search_on_site_url.pattern'),
      trigger: 'blur',
      transform: 'trim'
    },
    {
      max: 500,
      message: i18n.t('validation.simple.max', { num: 500 }),
      trigger: 'blur'
    }
  ]
};
