var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sale-point-options" },
    [
      _c("Activities", {
        staticClass: "label-to-top mr-2",
        attrs: { value: _vm.value.activities },
        on: {
          input: function ($event) {
            return _vm.emit("activities", $event)
          },
        },
      }),
      _vm._v(" "),
      _c("Schedule", {
        staticClass: "mr-2",
        attrs: { "hide-label": "", value: _vm.value.schedule },
        on: {
          input: function ($event) {
            return _vm.emit("schedule", $event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fl-wrap" },
        _vm._l(_vm.options, function (data, listName) {
          return _c(
            "div",
            { key: listName, staticClass: "mr-2 options" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("labels." + listName),
                    prop: listName,
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "cell",
                      attrs: { value: _vm.value[listName] },
                      on: {
                        input: function ($event) {
                          return _vm.emit(listName, $event)
                        },
                      },
                    },
                    _vm._l(data.list, function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item, attrs: { label: item } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t(listName + "." + item)) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  listName === "delivery_methods"
                    ? [
                        _c(
                          "el-checkbox",
                          {
                            attrs: {
                              value:
                                _vm.value.delivery_methods.includes(
                                  "carrier_in_country"
                                ),
                              label: "carrier_in_country",
                            },
                            on: { change: _vm.setCarrierInCountry },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("delivery_methods.carrier_in_country")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isDeliveryServices &&
                        _vm.value.delivery_methods.includes(
                          "carrier_in_country"
                        )
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "0",
                                      prop: "delivery_services",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "delivery-services flex-cell",
                                      },
                                      _vm._l(
                                        _vm.deliveryServices,
                                        function (it) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: it.id,
                                              attrs: {
                                                checked:
                                                  _vm.isActiveDeliveryService[
                                                    it.id
                                                  ],
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.setToggleDeliveryService(
                                                    it,
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "deliveryServices." +
                                                        it.label.replace(
                                                          /\./g,
                                                          "_"
                                                        )
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              data.textField
                ? _c(
                    "div",
                    { key: data.textField },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-1" },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleTextField(data.textField)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("salePoint." + data.textField)
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isVisibleFields[data.textField],
                              expression: "isVisibleFields[data.textField]",
                            },
                          ],
                          staticClass: "mr-2",
                          attrs: { prop: data.textField, "label-width": "0" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              rows: 4,
                              type: "textarea",
                              value: _vm.value[data.textField],
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emit(data.textField, $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }