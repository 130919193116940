<template>
  <div class="garage-page">
    <el-row justify="space-between" align="middle" class="mb-4">
      <h1 class="custom-headline custom-headline--level2-bold">
        {{ $t('routes.garage') }}
      </h1>

      <div>
        <el-button
          size="mini"
          type="success"
          @click="$router.push({ name: getRouteName('garage.create') })"
        >
          {{ $t('buttons.addCar') }}
        </el-button>
      </div>
    </el-row>

    <TheLoader v-if="isLoading" />

    <el-row v-else-if="!cars.length" justify="center" class="pg-2">
      {{ $t('el.table.emptyText') }}
    </el-row>

    <div v-else>
      <template v-for="car in cars">
        <component
          :is="`CarFullInfoItem${maxmq('sm') ? 'M' : ''}`"
          :key="car.id"
          class="mb-4"
          :value="car"
          @edit="toEditCar"
          @request-new="onNewRequest"
          @request-history="toRequestHistory"
        />
      </template>
    </div>

    <TheDialog
      v-model="isShowVinConfirm"
      width="600px"
      :title="$t('garage.requestToSelectionByVin')"
    >
      <VinConfirmForm
        v-if="isShowVinConfirm"
        :car="isShowVinConfirm"
        :submit="updateCarGoToRequest"
      />
    </TheDialog>
  </div>
</template>

<script>
export default {
  name: 'GaragePage',

  components: {
    /* eslint-disable max-len */
    VinConfirmForm: () => import(/* webpackChunkName: "VinConfirmForm" */ './components/VinConfirmForm'),
    CarFullInfoItem: () => import(/* webpackChunkName: "CarFullInfoItem" */ './components/CarFullInfoItem'),
    CarFullInfoItemM: () => import(/* webpackChunkName: "CarFullInfoItemM" */ './components/CarFullInfoItemM')
    /* eslint-enable max-len */
  },

  data: () => ({
    isLoading: true,
    cars: [],
    isShowVinConfirm: null
  }),

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      this.isLoading = true;

      try {
        this.cars = await this.$store.dispatch('cars/fetchCarsQL');
      } catch (e) {
        this.cars = [];
      } finally {
        this.isLoading = false;
      }
    },

    onNewRequest (car) {
      if (car.vin) {
        return this.toRequests(car);
      }

      this.isShowVinConfirm = car;
    },

    async updateCarGoToRequest ({ car, vin }) {
      try {
        const _car = await this.$store.dispatch(
          'cars/updateCarQL',
          { id: car.id, formData: { vin } }
        );

        this.onNewRequest(_car);
      } catch (e) {
        console.error(e);
      }
    },

    toEditCar (car) {
      return this.$router.push({
        name: this.getRouteName('garage.edit'),
        params: { id: car.id }
      });
    },

    toRequests ({ id }) {
      this.$router.push({
        name: this.getRouteName('garage.requests.create'),
        params: { carId: id }
      });
    },

    toRequestHistory (car) {
      this.$router.push({
        name: this.getRouteName('garage.requests'),
        query: { car: car.id }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .car-full-info-item-m {
  padding-bottom: 20px!important;
  border-bottom: 1px solid $grey-300;
}
</style>
