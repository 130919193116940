<template>
  <FormBlock
    class="upload-info-block"
    entity-name="price"
    :fields="['price_file']"
    hide-controls
    label-position="left"
    label-width="0"
    :value="dependency.price"
    v-on="$listeners"
  >
    <template #default="{ readonly }">
      <UploadInfo
        :price="dependency.price"
        :readonly="readonly"
        v-on="$listeners"
      />
    </template>
  </FormBlock>
</template>

<script>
import UploadInfo from '../UploadInfo';
import FormBlock from '@/lib/core/components/FormBlock';

export default {
  name: 'UploadInfoBlock',

  components: {
    FormBlock,
    UploadInfo
  },

  props: {
    submit: Function,
    dependency: Object,
    value: { type: Object, required: true }
  }
};
</script>
