var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "test-page" }, [
    _c(
      "div",
      { staticClass: "outline mb-4" },
      [
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.clearCache } },
          [_vm._v("clearCache")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "mini" },
            on: {
              click: function ($event) {
                return _vm.showError(_vm.err_code)
              },
            },
          },
          [_vm._v("Error")]
        ),
      ],
      1
    ),
    _vm._v("\n  HERE\n  " + _vm._s(_vm.$i18n.availableLocales) + "\n  "),
    _c(
      "div",
      [
        _c("el-input", {
          model: {
            value: _vm.err_code,
            callback: function ($$v) {
              _vm.err_code = $$v
            },
            expression: "err_code",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }