<template>
  <div class="order-created-page">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <img
        v-lazy-load
        src="@/assets/img/done.svg"
        alt="done"
        class="dene-img mb-4"
      >

      <div class="title mb-1">{{ $t('orders.thanksForOrder') }}</div>

      <div class="title title--description mb-4">
        {{ $t('orders.order') }} №{{ order.public_id }}
        <br v-if="maxmq('xs')">
        {{ $t('core.on') }}
        {{ get(order, 'point_of_sale_detail.name') || get(order, 'seller_detail.name', '') }}
      </div>

      <div class="mb-4">
        <nuxt-link :to="{ name: getRouteName('home') }">
          <el-button type="primary" class="w-200p">
            {{ $t('buttons.goBackHome') }}
          </el-button>
        </nuxt-link>
      </div>

      <div class="w-100 ">
        <div class="check-progress mb-1">{{ $t('orders.checkProgress') }}:</div>

        <ul>
          <li>
            {{ $t('orders.forPermanentLink') }}:
            <el-link type="primary" :href="orderLink">{{ orderLink }}</el-link>
          </li>

          <li v-if="infoBotLink">
            {{ $t('orders.forTelegramBot') }}:
            <el-link type="primary" :href="infoBotLink">
              {{ infoBotLink }}
            </el-link>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import get from 'lodash/get';
import { env } from '@/lib/core';

export default {
  name: 'OrderCreatedPage',

  layout: 'light',

  props: {
    propOrder: Object
  },

  data () {
    return {
      isLoading: true,
      order: null
    };
  },

  computed: {
    orderLink () {
      const href = this.$router.resolve({
        name: this.getRouteName('basket.order.hash'),
        query: { hashId: this.order.hash_id }
      }).href;
      return `${env.BASE_CLIENT_PATH}${href}`;
    },

    infoBotLink () {
      return this.$store.getters['businessUsers/businessUsers']?.profile?.telegram_bot_url ||
        this.$store.getters['users/user']?.profile?.telegram_bot_url;
    }
  },

  mounted () {
    const { orderId } = this.$route.params;
    const { hashId } = this.$route.query;

    if (this.propOrder) {
      this.order = this.propOrder;
      this.isLoading = false;
    } else if (hashId) {
      this.loadOrderByHash(hashId);
    } else if (this.$store.getters['auth/isAuthenticated'] && orderId) {
      this.loadOrder(orderId);
    } else {
      this.$router.push({ name: this.getRouteName('home') });
    }
  },

  methods: {
    get,

    async loadOrder (orderId) {
      try {
        this.order = await this.$store.dispatch(
          'orders/getOrder',
          { id: orderId }
        );
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        return this.$nuxt.error(e);
      }
    },

    async loadOrderByHash (hash) {
      try {
        const [order] = await this.$store.dispatch(
          'orders/getOrders',
          { hash_id: hash }
        );

        if (!order) {
          throw new Error('404');
        }

        this.order = order;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        return this.$nuxt.error(e);
      }
    }
  },

  head () {
    return { meta: [{ name: 'robots', content: env.ROBOTS_VALUES }] };
  }
};
</script>

<style scoped lang="scss">
.order-created-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  .dene-img {
    display: block;
    height: 250px;
    width: 250px;
  }

  .title {
    text-align: center;
    font-size: 2rem;
    color: $black;
    font-weight: bold;

    &--description {
      font-size: 1.2rem;
    }
  }

  .check-progress {
    font-size: 1.2rem;
    font-weight: bold;
  }

  ul {
    list-style: disc outside!important;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
      list-style: disc outside!important;
    }
  }

  .mw-5 {
    min-width: 56px;
  }

  @media all and (max-width: $--xs) {
    .dene-img {
      height: 100px;
      width: 100px;
    }

    .title {
      font-size: 1.6rem;

      &--description {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
