<template>
  <span class="lang-links">
    <el-link
      v-for="it in langList"
      :key="it.langCode"
      :href="getHrefBy(it.langCode)"
      :disabled="$store.getters.langCode === it.langCode"
      :underline="false"
    >
      {{ it.label }}
    </el-link>
  </span>
</template>

<script>
import { getRouteByLang } from '../services/parsers';

export default {
  name: 'LangLinks',

  data: () => ({ langList: [] }),

  created () {
    this.computeLinks();
  },

  methods: {
    computeLinks () {
      this.langList = this.$i18n.availableLocales.map(langCode => ({
        langCode,
        label: this.$i18n.messages[langCode].countryCode
      }));
    },

    getHrefBy (langCode) {
      return this.$router.resolve(getRouteByLang(this.$route, langCode)).href;
    }
  }
};
</script>

<style scoped lang="scss">
.lang-links {
  .el-link {
    color: $--color-text-secondary;
    text-transform: uppercase;
    cursor: default;

    &:after {
      content: ' | ';
      font-size: .8rem;
      margin: 0 2px;
      color: $--color-text-secondary;
    }

    &:last-child:after {
      display: none;
    }

    &:not(.is-disabled) {
      color: $--color-text-regular;
      cursor: pointer;
    }
  }
}
</style>
