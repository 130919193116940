<template>
  <section class="catalog-tree">
    <div v-if="!value.length" class="w-100 text-center pg-2">
      {{ $t('errors.emptyData') }}
    </div>

    <ul v-else class="catalog-body">
      <li v-for="(topLine) in value" :key="topLine.id">
        <CatalogItem :dependency="dependency" :value="topLine" />
      </li>
    </ul>
  </section>
</template>

<script>
import CatalogItem from './CatalogItem';

export default {
  name: 'CatalogTree',

  components: {
    CatalogItem
  },

  props: {
    value: { type: Array, required: true },
    dependency: { type: Object, default: () => ({}) }
  }
};
</script>

<style scoped lang="scss">
.catalog-tree {
  .catalog-body {
    display: flex;
    flex-wrap: wrap;
  }

  .show-all-btn {
    display: block;
    margin-top: 10px;
  }

  @media all and (max-width: $--xs) {
    justify-content: space-around;
    flex-direction: column;
    padding: 5px;

    .catalog-body {
      justify-content: center;
    }
  }
}
</style>
