export default async function ({ store }) {
  if (process.server || store.getters.isLocalDev) {
    return null;
  }

  try {
    const { isChanged, lastVersion, version } = await store.dispatch('checkServerVersion');

    if (isChanged) {
      console.info(
        'VERSION WAS CHANGE', isChanged,
        'lastVersion', lastVersion,
        'version', version
      );

      setTimeout(() => window?.location?.reload(true), 0);
    }
  } catch (e) {
    console.error(e);
  }
}
