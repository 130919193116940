export default function searchMediator (searchParams, _store) {
  const { skipInStock, trademark, article, ware, filter: _filter } = searchParams;
  const { ware_and_analogs, ...filter } = _filter;

  let res = null;

  if (ware) {
    const data = ware_and_analogs
      ? {
          action: 'search/searchAnaloguesInPrice',
          payload: { ...filter, ware_and_analogs: ware }
        }
      : {
          action: 'search/searchInPrices',
          payload: { skipInStock, ...filter, ware }
        };

    res = _store.dispatch('cache/get', data);
  } else {
    res = _store.dispatch(
      'cache/get',
      {
        action: 'search/searchInPrices',
        payload: {
          ...filter,
          article,
          trademark,
          no_ware: filter.product_line ? undefined : true
        }
      });
  }

  return res;
}
