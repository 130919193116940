import eventBus from '@/lib/core/services/eventBus';

const emitGtmEvent = async (vm, data, options) => {
  const { id, product_line, display_title, price } = data;

  // TODO remove???
  await vm.$store.dispatch('analytics/pushGtagEvent', {
    event: 'add_to_cart',
    id,
    category: product_line?.name || display_title,
    value: Number(price),
    price,
    user: vm.$store.getters['users/user']?.name,
    vm
  });

  await vm.$store.dispatch('analytics/pushOrderToDatalayer2', {
    id: data.id,
    name: data.article,
    brand: data.trademark,
    category: product_line?.name || display_title,
    price: data.price,
    quantity: 1
  });

  if (options.emitViewItemEvent) {
    vm.$store.dispatch('analytics/pushWarePageShowEvent', {
      rawData: data,
      options: { ...options, doNotClear: true }
    });
  }

  return vm.$store.dispatch('analytics/pushAddToBasketEvent', {
    rawData: data,
    options: { doNotClear: true, ...options }
  });
};

const isMaxInBasket = (vm, ware, forceParams) => {
  const offerId = forceParams.offer_id || ware.offer_id;
  const basket = vm.$store.getters['baskets/basket'];
  const item = basket.items
    .find(({ offer_id }) => offerId === offer_id);

  return ware.stock_quantity && item
    ? ware.stock_quantity <= item.quantity
    : false;
};

export default async (vm, ware, forceParams = {}, options = {}) => {
  if (isMaxInBasket(vm, ware, forceParams)) {
    eventBus.$emit('basket:show');
    return ware;
  }

  const businessProfile = vm.$store.getters['businessProfiles/currentBusinessProfile'];

  const formData = forceParams.selection_response_id
    ? {
        selection_response_id: forceParams.selection_response_id,
        offer_id: forceParams.offer_id || ware.offer_id || undefined,
        quantity: 1,
        point_of_sale_id: ware.point_of_sale?.id,
        buyer_business: businessProfile?.url
      }
    : {
        offer_id: forceParams.offer_id || ware.offer_id || ware.id,
        quantity: 1,
        set_price: ware.price,
        buyer_business: businessProfile?.url
      };

  if (formData.selection_response_id) {
    console.info(formData);
  } else if (
    !formData.offer_id ||
    !formData.quantity ||
    !formData.set_price ||
    typeof formData.offer_id !== 'string'
  ) {
    console.error(`
      THERE IS NO REQUIRED PARAMS
      ${JSON.stringify(formData)}
    `);

    return null;
  }

  try {
    const data = await vm.$store.dispatch(
      'basketItems/postAndUpdateBasket',
      { formData }
    );

    if (!options.hideBasket) {
      eventBus.$emit('basket:show');
    }

    await emitGtmEvent(vm, data, options);

    return data;
  } catch (e) {
    console.error(e, formData);

    if (e?.response?.status === 400) {
      vm.$notify.error({
        title: vm.$t('errors.default.title'),
        message: vm.$t('errors.reloadPage')
      });
    }
  }
};
