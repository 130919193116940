var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-page form-tab" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [_vm._v("\n    " + _vm._s(_vm.$t("routes.profile")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb-1", attrs: { justify: "end" } },
        [
          _c("CollapseToggle", {
            attrs: { blocks: _vm.blocks },
            model: {
              value: _vm.activeBlock,
              callback: function ($$v) {
                _vm.activeBlock = $$v
              },
              expression: "activeBlock",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.user && _vm.formData
        ? _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeBlock,
                callback: function ($$v) {
                  _vm.activeBlock = $$v
                },
                expression: "activeBlock",
              },
            },
            _vm._l(_vm.blocks, function (block) {
              return _c(
                "el-collapse-item",
                { key: block, attrs: { name: block } },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c("CollapseIcon", {
                        attrs: { "is-valid": _vm.validBlocks[block] },
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(_vm.entityName + "." + block + "Block")
                          ) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pg-2" },
                    [
                      _c(_vm.upperFirst(block) + "Block", {
                        tag: "component",
                        attrs: {
                          entity: _vm.user,
                          submit: _vm.submit,
                          value: _vm.formData,
                        },
                        on: {
                          validate: function ($event) {
                            _vm.validBlocks[block] = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }