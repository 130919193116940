<template>
  <div class="s-requests-list-page">
    <div class="header mb-2">
      <h1 class="custom-headline custom-headline--level2-bold">
        {{ $t('routes.garage_requests') }}
      </h1>

      <div>
        <el-button
          size="mini"
          type="success"
          @click="toRequestCreate"
        >
          {{ $t('buttons.newSelectionRequest') }}
        </el-button>
      </div>
    </div>

    <TheLoader v-if="isLoading" />

    <component
      :is="`SRequestsTable${maxmq('xs') ? 'M' : ''}`"
      v-else
      :value="tableData"
      @select="goToResponseBy"
    />
  </div>
</template>

<script>
export default {
  name: 'SRequestsListPage',

  components: {
    /* eslint-disable max-len */
    SRequestsTable: () => import(/* webpackChunkName: "SRequestsTable" */ './components/SRequestsTable'),
    SRequestsTableM: () => import(/* webpackChunkName: "SRequestsTableM" */ './components/SRequestsTableM')
    /* eslint-enable max-len */
  },

  data () {
    return {
      isLoading: true,
      tableData: []
    };
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      this.isLoading = true;

      try {
        this.tableData = await this.$store.dispatch(
          'selectionRequests/getVinRequestsQL',
          { car: Number(this.$route.query.car) }
        );
      } catch (e) {
        console.error(e);
        this.tableData = [];
      } finally {
        this.isLoading = false;
      }
    },

    goToResponseBy ({ id }) {
      return this.$router.push({
        name: this.getRouteName('garage.requests.response'),
        params: { id }
      });
    },

    toRequestCreate () {
      const carId = this.$route.query.car;

      const path = carId
        ? {
            name: this.getRouteName('garage.requests.create'),
            params: { carId }
          }
        : { name: this.getRouteName('garage') };

      return this.$router.push(path);
    }
  }
};
</script>

<style scoped lang="scss">
.s-requests-list-page {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .s-requests-table {
    max-width: 980px;
    margin: 0 auto;
  }

  @media all and (max-width: $--xs) {
    .header {
      flex-direction: column;
    }
  }
}
</style>
