<template>
  <div class="trademark-filter">
    <el-input v-model="query" :placeholder="$t('placeholders.filterByTrademark')" />

    <ul class="list scroll">
      <li class="list-item">
        <el-row v-for="trademark in filteredTrademarks" :key="trademark.id">
          <nuxt-link v-if="useLink" :to="getLink(trademark)">
            <el-checkbox
              :value="value.includes(trademark.id)"
              @change="toggleTm($event, trademark)"
            >
              <span>{{ trademark.name }}</span>
            </el-checkbox>
          </nuxt-link>

          <el-checkbox
            v-else
            :value="value.includes(trademark.id)"
            @change="toggleTm($event, trademark)"
          >
            <span>{{ trademark.name }}</span>
          </el-checkbox>
        </el-row>
      </li>
    </ul>

    <TheTooltip :content="$t('buttons.clear')">
      <i class="clear el-icon-circle-close" @click="clear" />
    </TheTooltip>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import xor from 'lodash/xor';

export default {
  name: 'TrademarkFilter',

  props: {
    useLink: Boolean,
    getLink: Function,
    trademarks: { type: Array, default: () => [] },
    value: { type: Array, default: () => [] }
  },

  data: () => ({ query: '' }),

  computed: {
    filteredTrademarks () {
      if (!this.query) {
        return this.sortByChecked(this.trademarks);
      }

      const reg = new RegExp(this.query, 'ig');
      const filtered = this.trademarks.filter(({ name }) => name.match(reg));

      return this.sortByChecked(filtered);
    }
  },

  methods: {
    sortByChecked (list) {
      return sortBy(list, [({ id }) => !this.value.includes(id), 'name']);
    },

    toggleTm (isChecked, trademark) {
      const trademarks = xor(this.value, [trademark.id]);

      this.$emit('input', trademarks);

      this.query = '';
    },

    clear () {
      this.$emit('clear');
    }
  }
};
</script>

<style scoped lang="scss">
.trademark-filter {
  position: relative;

  .list {
    border: 1px solid $--border-color-base;
    height: 290px;
    border-radius: $--border-radius-base;
    border-top: none;
    overflow: auto;
    padding-top: 8px;

    &-item {
      padding: 0 4px;
    }
  }

  .clear {
    position: absolute;
    bottom: 1px;
    right: 7px;
    font-size: 1.4rem !important;
    color: $--border-color-base;
    cursor: pointer;

    &:hover {
      color: $--color-text-secondary;
    }
  }
}
</style>
