var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormBlock",
    _vm._g(
      {
        ref: "form",
        staticClass: "availabilities-block",
        attrs: {
          "entity-name": "price",
          "label-position": "left",
          "label-width": "300px",
          comparator: _vm.comparator,
          fields: ["availabilities"],
          submit: _vm.submit,
          value: _vm.value,
        },
        on: { refresh: _vm.refresh },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var formData = ref.formData
              return [
                _vm.dependency.warehouses
                  ? _c(
                      "Availabilities",
                      _vm._b(
                        {
                          key: _vm.key,
                          ref: "availabilities",
                          attrs: {
                            dependency: Object.assign({}, _vm.dependency, {
                              column_mapping: _vm.column_mapping,
                            }),
                            value: formData.availabilities,
                          },
                        },
                        "Availabilities",
                        formData,
                        false,
                        true
                      )
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }