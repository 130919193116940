<template>
  <div class="ware-car-applicability-selector">
    <div v-show="!hideLabel" class="mb-1">
      <span class="custom-headline custom-headline--level3-bold">
        {{ $t('ware.selectCarFormApplicability') }}
      </span>
    </div>

    <el-tabs
      type="card"
      :value="activeTab"
      @tab-click="activeTab = $event.name"
    >
      <el-tab-pane
        v-for="name in menuTabs"
        :key="name"
        :label="getTitleBy(name) || $t(`catalog.${name}`)"
        :name="name"
        :disabled="disabled[name]"
      >
        <CarDefaultList
          :value="collection[`${name}s`]"
          :is-active="isActiveItem"
          :readonly="activeTab === 'modification'"
          @set-item="setItem"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CarDefaultList from '@/lib/garage/components/garageWidget/CarDefaultList';

const getParsed = (list) => {
  return (list || []).map(({ id, name }) => ({ value: id, name }));
};

export default {
  name: 'WareCarApplicabilitySelector',

  components: {
    CarDefaultList
  },

  props: {
    hideLabel: Boolean,
    value: { type: Array, required: true }
  },

  data () {
    return {
      menuTabs: ['brand', 'model', 'generation', 'modification'],
      activeTab: 'brand',
      formData: {
        brand: null,
        model: null,
        generation: null,
        modification: null
      }
    };
  },

  computed: {
    disabled () {
      return {
        model: !this.formData.brand,
        generation: !this.formData.model,
        modification: !this.formData.generation
      };
    },

    collection () {
      const brands = this.value;

      const models = this.getChildrenCollection(brands, 'brand', 'models');
      const generations = this.getChildrenCollection(
        models, 'model', 'generations'
      );
      const modifications = this.getChildrenCollection(
        generations, 'generation', 'modifications'
      ).map(item => ({
        ...item,
        name: `${item.name} / ${item.engine_code} (${item.power_ps}${this.$t('catalog.ls')})`
      }));

      return {
        brands: getParsed(this.value),
        models: getParsed(models),
        modifications: getParsed(modifications),
        generations: getParsed(generations)
      };
    }
  },

  methods: {
    getChildrenCollection (parentCollection, name, childName) {
      return this.formData[name]
        ? (parentCollection.find(
            ({ id }) => id === this.formData[name]) || {}
          )[childName]
        : [];
    },

    setItem (item) {
      if (this.activeTab !== 'modification') {
        this.formData[this.activeTab] = item.value;
        const index = this.menuTabs.findIndex(name => name === this.activeTab);

        if (index > -1) {
          this.activeTab = this.menuTabs[index + 1];
          this.clearNextMenuTabs(index + 1);
        }
      }
    },

    clearNextMenuTabs (index) {
      for (let i = index, l = this.menuTabs.length; i < l; i++) {
        this.formData[this.menuTabs[i]] = null;
      }
    },

    getTitleBy (name) {
      const list = this.collection[`${name}s`] || [];
      const item = list.find(({ value }) => value === this.formData[name]);
      return item ? String(item.name) : '';
    },

    isActiveItem (item) {
      return this.formData[this.activeTab] === item.value;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/components/car-selector.scss';
</style>
