var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "garage-widget-panel" }, [
    _c("div", { staticClass: "flex" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "div",
            { staticClass: "mr-1" },
            [
              _c(
                "el-button",
                {
                  staticClass: "garage-list-btn",
                  attrs: { type: "danger", disabled: !_vm.fullList.length },
                  on: {
                    click: function ($event) {
                      return _vm.toggleView("list")
                    },
                  },
                },
                [
                  _c("el-row", { attrs: { align: "middle" } }, [
                    _c("img", {
                      staticClass: "mr-05",
                      attrs: {
                        width: "24",
                        height: "24",
                        src: require("@/assets/icons/car-1-white.svg"),
                        alt: "Garage",
                      },
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("catalog.garage")) +
                        "\n            (" +
                        _vm._s(_vm.fullList.length) +
                        ")\n          "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "widget-title" },
            [
              _vm.car
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.getShortCarTitle(_vm.car))),
                      ]),
                      _vm._v(" "),
                      _vm.car.vin
                        ? _c(
                            "el-row",
                            {
                              staticClass: "text-small color-label",
                              attrs: { align: "bottom" },
                            },
                            [
                              _vm._v(
                                "\n            VIN: " +
                                  _vm._s(_vm.car.vin) +
                                  "\n            "
                              ),
                              _c("BufferCopy", {
                                staticClass: "ml-05",
                                attrs: { value: _vm.car.vin },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                    _vm._v(" "),
                    _vm.selectorOptions.isBackspace
                      ? _c("el-link", { staticClass: "ml-1" }, [
                          _c("img", {
                            staticClass: "block",
                            attrs: {
                              src: require("@/assets/icons/backspace_outlined-blue.svg"),
                              alt: "backspace",
                            },
                            on: { click: _vm.goBackSelect },
                          }),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          !_vm.hideOptions
            ? _c(
                "el-row",
                {
                  staticClass: "widget-options ml-1",
                  attrs: { align: "middle" },
                },
                [
                  _vm.car && _vm.car._isGarage
                    ? _c(
                        "el-link",
                        {
                          staticClass: "mr-1",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.editCar(_vm.car)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-edit-outline" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      staticClass: "mr-1 bold",
                      attrs: { underline: false, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.toVinRequest(_vm.car)
                        },
                      },
                    },
                    [_vm._v("\n          VIN\n        ")]
                  ),
                  _vm._v(" "),
                  !_vm.$route.name.includes("home") ||
                  _vm.selectorOptions.isBackspace
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleView("selector", "home")
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "right" }, [
        !_vm.hideOptions
          ? _c("img", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              staticClass: "c-pointer",
              attrs: {
                width: "45",
                height: "45",
                src: require("@/assets/icons/plus-success.svg"),
                alt: "Create Car",
              },
              on: {
                click: function ($event) {
                  return _vm.toggleView("selector")
                },
              },
            })
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }