var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "price-re-upload-dialog",
      attrs: {
        title: _vm.$t("priceFile.priceReUpload"),
        visible: "",
        width: "800px",
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "point-options" },
        [
          _c("p", { staticClass: "description mb-1" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("prices.priceLoadOption1Description2")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("PriceUploader", {
            staticClass: "mb-3",
            on: { input: _vm.setFile },
          }),
          _vm._v(" "),
          _c("PriceFormatForm", {
            ref: "priceFormat",
            attrs: { "is-child": "", price: _vm.price, value: _vm.formData },
            on: { input: _vm.update },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mt-2", attrs: { justify: "end" } },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.isLoading },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.file,
                loading: _vm.isLoading,
                "native-type": "submit",
                type: "primary",
              },
              on: { click: _vm.submit },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("buttons.save")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }