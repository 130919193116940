export const actions = {
  getPage ({ rootGetters }, { path }) {
    return this.$axios.$get('/pages/', {
      params: {
        path,
        language_code: rootGetters.langCode
      }
    });
  }
};
