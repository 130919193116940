var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "the-logo" },
    [
      _c("nuxt-link", { staticClass: "link", attrs: { to: _vm.link } }, [
        _c("img", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          staticClass: "img",
          attrs: {
            "data-src": _vm.logo,
            alt: _vm.APP_NAME,
            width: "120",
            height: "36",
            title: _vm.APP_NAME,
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }