var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s-request-form" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.formData, rules: _vm.rules },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.checkAndSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "vin" } },
            [
              _c("CarInfoCard" + (_vm.isMobile ? "M" : ""), {
                tag: "component",
                attrs: { value: _vm.car },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "itemsAdd" } },
            _vm._l(_vm.formData.itemsAdd, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "item-row grey-block" },
                [
                  _c(
                    "el-form-item",
                    {
                      class: [
                        "mb-1 required-form-label",
                        { "mr-2": !_vm.isMobile },
                      ],
                      attrs: {
                        label: _vm.$t("garage.selectionRequests.rawItem"),
                        rules: _vm.rules.rawItem,
                        prop: "itemsAdd[" + index + "].rawItem",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "relative" },
                        [
                          _c("el-input", {
                            ref: "itemId_" + index,
                            refInFor: true,
                            staticClass: "select-input",
                            attrs: {
                              size: "small",
                              clearable: "",
                              value: item.rawItem,
                            },
                            on: {
                              blur: _vm.hideSelect,
                              input: function ($event) {
                                return _vm.onSearchChange(index, item, $event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.activeItem === "itemId_" + index &&
                          _vm.autocomplete.length
                            ? _c(
                                "div",
                                { staticClass: "select back-shadow scroll" },
                                _vm._l(_vm.autocomplete, function (_item, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i + "_" + _item,
                                      staticClass: "item",
                                      on: {
                                        click: function ($event) {
                                          item.rawItem = _item
                                          _vm.autocomplete = []
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_item) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("garage.selectionRequests.itemComment"),
                        prop: "itemsAdd[" + index + "].comment",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", autosize: { minRows: 1 } },
                        model: {
                          value: item.comment,
                          callback: function ($$v) {
                            _vm.$set(item, "comment", $$v)
                          },
                          expression: "item.comment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "mb-2", attrs: { justify: "end" } },
            [
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.addItem } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("buttons.addMoreRow")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.hideCity
            ? _c(
                "el-row",
                {
                  staticClass: "mb-2 city",
                  attrs: { justify: _vm.isMobile ? "center" : "end" },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("sRequests.myCity")) + ":"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      staticClass: "ml-05",
                      attrs: { type: "primary" },
                      on: { click: _vm.showCitySelector },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.cityName) + "\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "buttons", attrs: { justify: "center" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("buttons.cancel")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.canSubmit,
                    loading: _vm.isVLoading,
                    type: "primary",
                    "native-type": "submit",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.submitBtnText || _vm.$t("buttons.emitRequest")
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }