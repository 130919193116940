var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ware-info-icons",
      on: {
        click: function ($event) {
          return _vm.$emit("show", _vm.ware)
        },
      },
    },
    [
      _c("el-row", { attrs: { align: "middle", justify: _vm.position } }, [
        _c("i", { staticClass: "el-icon-warning-outline" }),
        _vm._v(" "),
        _vm.ware.has_images
          ? _c("i", { staticClass: "el-icon-picture-outline" })
          : _vm._e(),
        _vm._v(" "),
        _vm.ware.has_applicability
          ? _c("img", {
              staticClass: "icon-img car-icon",
              attrs: {
                src: require("@/assets/icons/car-2.svg"),
                alt: "has_applicability",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.ware.has_kits
          ? _c("i", { staticClass: "el-icon-takeaway-box" })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }