<script>
import MainInfoBlock from './formBlocks/MainInfoBlock';
import AvailabilitiesBlock from './formBlocks/AvailabilitiesBlock';
import FormTab from '@/lib/core/components/FormTab';

export default {
  name: 'PriceSettingsTab',

  extends: FormTab,

  components: {
    MainInfoBlock,
    AvailabilitiesBlock
  },

  data: () => ({
    blocks: ['mainInfo', 'availabilities']
  })
};
</script>
