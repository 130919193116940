<template>
  <div class="contacts no-wrap-error">
    <div v-if="!readonly" class="description mb-2">
      <p>{{ $t('account.contactsDescription1') }}</p>
      <p>{{ $t('account.contactsDescription2') }}</p>
    </div>

    <el-form-item class="row-item mb-2" prop="phone_numbers">
      <el-row
        v-for="(phone, index) in formData.phone_numbers"
        :key="index"
        align="middle"
        class="row"
        :gutter="20"
      >
        <el-col>
          <el-form-item
            class="mb-0"
            :label-width="labelWidth"
            :prop="`phone_numbers.${index}.phone_number`"
            :rules="rules.phone_number"
          >
            <template #label>
              <span class="bold">{{ $t('labels.phone') }}:</span>
            </template>

            <PhoneInput
              v-model="phone.phone_number"
              class="w-250p"
              :disabled="readonly"
              @blur="fixPhoneNumber(phone)"
              @input="emit('phone_numbers', formData.phone_numbers)"
            />
          </el-form-item>
        </el-col>

        <el-col class="fit-content">
          <el-row v-if="index === 0" class="w-250p checkbox-labels">
            <el-col
              v-for="channel in channels"
              :key="channel"
              :span="24 / channels.length"
            >
              <span>{{ $t(`phoneChannels.${channel}`) }}</span>
            </el-col>
          </el-row>

          <el-checkbox-group
            v-model="phone.channels"
            :disabled="readonly"
            @input="emit('phone_numbers', formData.phone_numbers)"
          >
            <el-row class="w-250p" justify="center">
              <el-col
                v-for="channel in channels"
                :key="channel"
                :span="24 / channels.length"
              >
                <el-checkbox :label="channel"><span /></el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </el-col>

        <el-col />
      </el-row>
    </el-form-item>

    <el-form-item class="row-item" prop="emails">
      <el-row
        v-for="(email, index) in formData.emails"
        :key="index"
        align="middle"
        class="row"
        :gutter="20"
      >
        <el-col class="fit-content">
          <el-form-item
            class="mb-0"
            :label-width="labelWidth"
            :prop="`emails.${index}.email`"
            :rules="rules.email"
          >
            <template #label>
              <span class="bold">{{ $t('labels.email') }}:</span>
            </template>

            <el-input
              v-model="email.email"
              class="w-250p"
              :disabled="readonly"
              type="email"
              @input="emit('emails', formData.emails)"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import { Contacts } from '@/lib/salePoints';

export default {
  name: 'Contacts',

  extends: Contacts,

  data: () => ({
    labelWidth: '150px'
  })
};
</script>

<style scoped lang="scss">
.contacts {
  .label {
    white-space: nowrap;
    margin-right: 4px;
  }

  ::v-deep .el-input {
    width: 100%;
  }

  .row {
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;

      .el-col {
        text-align: center;
      }
    }

    .checkbox-labels {
      font-size: .8rem;
      position: absolute;
      top: -20px;
      padding: 0;

      .el-col {
        text-align: center;
      }
    }

    .el-checkbox {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

  .row-item {
    max-width: 900px;
  }
}
</style>
