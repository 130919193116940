import { requiredString } from './common';
import { VIN } from './patterns';
import { i18n } from '@/plugins/i18n';

const reqValidate = [{ validator: 'carFields', required: true, trigger: 'change' }];

export default {
  brand: reqValidate,
  model: reqValidate,
  productionYear: [{ validator: 'carYear', trigger: 'change' }],
  bodyType: reqValidate,
  fuelType: reqValidate,
  engineCapacity: reqValidate,
  modification: reqValidate,
  driveType: reqValidate,
  vin: [
    requiredString,
    {
      len: 17,
      message: i18n.t('validation.simple.length', { num: 17 }),
      trigger: 'blur'
    },
    {
      required: true,
      type: 'string',
      pattern: VIN,
      message: i18n.t('validation.simple.format'),
      trigger: 'blur',
      transform: 'vinReplacer'
    }
  ]
};
