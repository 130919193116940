import isEmpty from 'lodash/isEmpty';
import { env } from '@/lib/core';

export const state = () => ({
  brands: {}
});

export const mutations = {
  setBrands: (state, brands) => { state.brands = brands; }
};

export const actions = {
  async fetchBrands ({ getters, commit, dispatch }, params = { limit: 0 }) {
    if (!isEmpty(getters.brands)) {
      return getters.brands;
    }

    try {
      const brands = await dispatch('getBrands', params);

      commit('setBrands', brands);
      return getters.brands;
    } catch (e) {
      console.error(e);
      return getters.brands;
    }
  },

  async getBrands (_, params = {}) {
    const { data } = await this.$axios({
      method: 'get',
      baseURL: env.OS_API,
      url: '/car-brands/',
      params: {
        ordering: 'name',
        limit: 200,
        ...params
      },
      progress: false
    });

    return data;
  },

  clearQueryCache ({ commit, getters }) {
    console.info('brands', getters.brands);
    commit('setBrands', []);
    return null;
  }
};

export const getters = {
  brands: state => state.brands
};
