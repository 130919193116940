import Vue from 'vue';
import Router from 'vue-router';

// TODO ADD NEW PAGE TO noCache in pageCacheMiddleware.js !!!

import {
  getParsedRouting,
  updateCollections
} from './core/services/routeParser';

import {
  DocsPage,
  DevelopmentPage,
  TestPage,
  DocsInvoicePage,
  PingPage
} from './core';

import {
  SearchTrademarksPage,
  SearchArticlesPage
} from './search';

import {
  CatalogPage,
  WarePage,
  TrademarksPage,
  TrademarkPage,
  CarsPage
} from './catalog';

import SearchEntityPage from './catalog/SearchEntityPage';

import {
  AuthenticationPage,
  PasswordSendRecoveryForm,
  PasswordRecoveryForm,
  LoginRegistrationForms,
  ActivateResendForms,
  CompletionAuth
} from './auth';

import {
  AccountPage,
  ProfilePage,
  BusinessListPage,
  BusinessCreatePage,
  BusinessEditPage,
  BalancePage,
  PlatformsListPage,
  PlatformsSendPage,
  PlatformsContractPage
} from './profiles';

import {
  SalePointPage,
  SalePointCreatePage,
  SalePointEditPage,
  SalePointsPage
} from './salePoints';

import {
  PricesListPage,
  PriceUploadPage,
  PriceCreatePage,
  PriceEditPage
} from './prices';

import {
  OrderCreatePage,
  OrderCreatedPage,
  BuyerOrdersPage,
  BuyerOrderPage,
  SellerOrdersPage,
  SellerOrderPage,
  BasketPage
} from './orders';

import {
  GarageWrapper,
  GaragePage,
  CarEditPage
} from './garage';

import {
  SRequestWrapper,
  SRequestsListPage,
  SRequestCreatePage,
  SRequestCreatedPage,
  SRequestsProcessingListPage,
  SRequestProcessingPage,
  SResponsePage,
  SRequestCreateMasterPage
} from './selectionRequests';

const _routes = [
  {
    path: '/',
    name: 'home',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Главная страница раздела Автотовары'
    }
  },
  {
    path: '/search/catalog',
    name: 'catalog-search',
    component: SearchEntityPage
  },
  {
    path: '/search/article',
    name: 'article',
    props: true,
    component: SearchArticlesPage,
    meta: {
      useCustomBreadcrumb: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: SearchTrademarksPage,
    meta: {
      useCustomBreadcrumb: true
    }
  },
  {
    path: '/basket',
    name: 'basket',
    component: BasketPage,
    meta: {
      layoutLoadBasket: false
    }
  },
  {
    path: '/basket/orders/create/:pointId',
    name: 'basket.order-create',
    component: OrderCreatePage,
    meta: {
      layoutLoadBasket: false
    }
  },
  {
    path: '/basket/orders/created/hash/:hashId',
    name: 'basket.order-created.hash',
    component: OrderCreatedPage,
    props: true,
    meta: {
      layoutLoadBasket: false
    }
  },
  {
    path: '/basket/orders/created/:orderId',
    name: 'basket.order-created',
    component: OrderCreatedPage,
    props: true,
    meta: {
      layoutLoadBasket: false
    }
  },
  {
    path: '/basket/orders/hash',
    name: 'basket.order.hash',
    component: BuyerOrderPage,
    meta: {
      useCustomBreadcrumb: true
    }
  },
  {
    path: '/basket/orders/:orderId',
    name: 'basket.order',
    component: BuyerOrderPage,
    meta: {
      useCustomBreadcrumb: true
    }
  },
  {
    path: '/basket/orders',
    name: 'basket.orders',
    component: BuyerOrdersPage
  },
  {
    path: '/auth',
    component: AuthenticationPage,
    children: [
      {
        path: '',
        name: 'auth',
        component: LoginRegistrationForms
      },
      {
        path: 'pass-send',
        name: 'auth.pass-send',
        component: PasswordSendRecoveryForm
      },
      {
        path: 'recovery',
        name: 'auth.pass-recovery',
        component: PasswordRecoveryForm
      },
      {
        path: 'activation',
        name: 'auth.activation',
        component: ActivateResendForms
      },
      {
        path: 'completion',
        name: 'auth.completion',
        component: CompletionAuth
      }
    ]
  },
  {
    path: '/profile',
    name: 'profile',
    component: AccountPage
  },
  {
    path: '/profile',
    component: ProfilePage,
    children: [
      {
        path: 'business',
        name: 'profile.business',
        component: BusinessListPage
      },
      {
        path: 'business/create',
        name: 'profile.business.create',
        component: BusinessCreatePage
      },
      {
        path: 'business/edit',
        name: 'profile.business.edit',
        component: BusinessEditPage
      },
      {
        path: 'sale-points',
        name: 'profile.business.sale-points',
        component: SalePointsPage
      },
      {
        path: 'sale-points/create',
        name: 'profile.business.sale-points.create',
        component: SalePointCreatePage
      },
      {
        path: 'sale-points/:id',
        name: 'profile.business.sale-points.edit',
        component: SalePointEditPage
      },
      {
        path: 'prices',
        name: 'profile.business.prices',
        component: PricesListPage
      },
      {
        path: 'prices/upload',
        name: 'profile.business.prices.upload',
        component: PriceUploadPage
      },
      {
        path: 'prices/:id/create',
        name: 'profile.business.prices.create',
        component: PriceCreatePage
      },
      {
        path: 'prices/:id/edit',
        name: 'profile.business.prices.edit',
        component: PriceEditPage
      },
      {
        path: 'orders',
        name: 'profile.business.seller_orders',
        meta: { isHideAsideNav: true },
        component: SellerOrdersPage
      },
      {
        path: 'orders/:id',
        name: 'profile.business.seller_orders.order',
        meta: { isHideAsideNav: true },
        component: SellerOrderPage
      },

      // TODO it is in development mode
      {
        path: 'business/platforms',
        name: 'profile.business.platforms',
        component: PlatformsListPage
      },
      {
        path: 'business/platforms/contract',
        name: 'profile.business.platforms.contract',
        component: PlatformsContractPage
      },
      {
        path: 'business/platforms/:id/connect',
        name: 'profile.business.platforms.connect',
        component: PlatformsSendPage
      },
      {
        path: 'business/balance',
        name: 'profile.business.balance',
        component: BalancePage
      }
    ]
  },
  // it for unregistered user
  {
    path: '/vin-requests-master/create',
    name: 'vin-requests-master_create',
    component: SRequestCreateMasterPage,
    props: true,
    meta: {
      useCustomBreadcrumb: true
    }
  },
  {
    path: '/vin-requests-master/created/:requestId',
    name: 'vin-requests-master_created',
    component: SRequestCreatedPage,
    props: true,
    meta: {
      useCustomBreadcrumb: true
    }
  },
  {
    path: '/vin-requests/hash',
    name: 'vin-requests-hash',
    component: SResponsePage,
    meta: {
      useCustomBreadcrumb: true
    }
  },
  // TODO update parser for deep parse children then put it to /profile and remove SRequestWrapper
  {
    path: '/profile/selection-requests',
    component: SRequestWrapper,
    children: [
      {
        path: '',
        name: 'profile.business.s-requests',
        component: SRequestsProcessingListPage
      },
      {
        path: ':id',
        name: 'profile.business.s-requests.edit',
        component: SRequestProcessingPage
      }
    ]
  },
  {
    path: '/garage',
    component: GarageWrapper,
    children: [
      {
        path: '',
        name: 'garage',
        component: GaragePage
      },
      {
        path: 'cars/create',
        name: 'garage.create',
        component: CarEditPage
      },
      {
        path: 'cars/:id',
        name: 'garage.edit',
        component: CarEditPage
      },
      {
        path: 'selection-requests',
        name: 'garage.requests',
        component: SRequestsListPage
      },
      {
        path: 'selection-requests/create/:carId',
        name: 'garage.requests.create',
        component: SRequestCreatePage
      },
      {
        path: 'selection-requests/created/:requestId',
        name: 'garage.requests.created',
        component: SRequestCreatedPage,
        props: true
      },
      {
        path: 'selection-requests/responses/:id',
        name: 'garage.requests.response',
        component: SResponsePage
      }
    ]
  },
  {
    path: '/sale-point/:id',
    name: 'sale-point',
    component: SalePointPage
  },
  {
    path: '/docs/invoice/:id',
    name: 'docs.invoice',
    component: DocsInvoicePage
  },
  {
    path: '/docs/:id',
    name: 'docs',
    component: DocsPage
  },
  {
    path: '/ping',
    name: 'ping',
    component: PingPage
  },
  {
    path: '/development',
    name: 'development',
    component: DevelopmentPage
  },
  {
    path: '/test',
    name: 'test',
    component: TestPage
  },
  // SEO PAGES
  {
    path: '/part-:meta([\\w-%]+?)-:wareId(\\d+)',
    name: 'wareId',
    component: WarePage,
    meta: {
      seoName: 'Карточка запчасти (товара) - /part-{{ТМ}}-{{артикул}}-{{внутренний ID товара}}/'
      // http://localhost:3000/part-mahle-original-oc90-904660
    }
  },
  {
    path: '/brands/letter-:letter',
    name: 'brands.letter',
    component: TrademarksPage,
    meta: {
      seoName: 'Все ТМ + буква - /brands/:',
      robots: 'noindex, follow'
      // http://localhost:3000/brands/letter-A
    }
  },
  {
    path: '/brands/:trademarkId',
    name: 'trademarkId',
    component: TrademarkPage,
    meta: {
      seoName: 'Торговая марка (ТМ) - /brands/{{ТМ}}/'
      // http://localhost:3000/brands/bosch
    }
  },
  {
    path: '/brands',
    name: 'brands',
    component: TrademarksPage,
    meta: {
      seoName: 'Все ТМ - /brands/'
      // http://localhost:3000/brands
    }
  },
  {
    path: '/cars',
    name: 'cars',
    component: CarsPage,
    meta: {
      seoName: 'Все марки авто - /cars/'
      // http://localhost:3000/cars/
    }
  },
  {
    path: '/cars/:carBrand',
    name: 'carBrand',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Марка авто - /cars/{{марка авто}}/'
      // http://localhost:3000/cars/audi
    }
  },
  {
    path: '/cars/:carBrand/:carModel',
    name: 'carBrand.carModel',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Модель авто - /cars/{{марка авто}}/{{модель авто}}/'
      // http://localhost:3000/cars/audi/q7
    }
  },
  {
    path: '/cars/:carBrand/:carModel/:carGeneration',
    name: 'carBrand.carGeneration.carModel',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Поколение авто -' +
        ' /cars/{{марка авто}}/{{модель авто}}/{{модификация авто}}/'
      // http://localhost:3000/cars/audi/q7/audi-q7-4lb
    }
  },
  {
    path: '/region/:regionId/:lineId',
    name: 'lineId.regionId',
    component: CatalogPage,
    meta: {
      seoName: 'Автозапчастина в регионе -' +
       ' /region/{{регион}}//part-{{ТМ}}-{{артикул}}-{{внутренний ID товара}}/'
    }
  },
  {
    path: '/region/:regionId/:lineId/brand-:trademarkId([\\w-]+?)',
    name: 'lineId.regionId.trademarkId',
    component: CatalogPage,
    meta: {
      seoName: 'Товарная линия + ТМ + region - {{ название товарной линии }}/brand-{{ТМ}}/'
    }
  },
  {
    path: '/:lineId',
    name: 'lineId',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Товарная линия - {{ название товарной линии }}/'
      // http://localhost:3000/zapchastyny-dlja-to
      // http://localhost:3000/filtr-masljanyj
    }
  },
  {
    path: '/:lineId/brand-:trademarkId([\\w-]+?)',
    name: 'lineId.trademarkId',
    component: CatalogPage,
    meta: {
      seoName: 'Товарная линия + ТМ - {{ название товарной линии }}/brand-{{ТМ}}/'
      // http://localhost:3000/filtr-masljanyj/brand-bosch
    }
  },
  {
    path: '/:lineId/brand-:trademarkId([\\w-]+?)/cars/:carBrand',
    name: 'carBrand.lineId.trademarkId',
    component: CatalogPage,
    meta: {
      seoName: 'Поколение авто + Товарная линия + ТМ -' +
        ' /cars/{{марка авто}}/{{модель авто}}/{{ название товарной линии }}/brand-{{ТМ}}/'
      // http://localhost:3000/filtr-masljanyj/brand-bosch/cars/acura
    }
  },
  {
    path: '/:lineId/cars/:carBrand',
    name: 'carBrand.lineId',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Марка авто + Товарная линия - {{ название товарной линии }}/cars/{{марка авто}}/'
      // http://localhost:3000/filtr-masljanyj/cars/audi
    }
  },
  {
    path: '/:lineId/brand-:trademarkId([\\w-]+?)/cars/:carBrand/:carModel',
    name: 'carBrand.carModel.lineId.trademarkId',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Марка авто + Товарная линия + ТМ -' +
        ' /{{ название товарной линии }}/brand-{{название производителя}}' +
        '/cars/{{марка авто}}/{{модель авто}}'
      // http://localhost:3000/filtr-masljanyj/brand-bosch/cars/audi/q7
    }
  },
  {
    path: '/:lineId/brand-:trademarkId([\\w-]+?)/cars/:carBrand/:carModel/:carGeneration',
    name: 'carBrand.carGeneration.carModel.lineId.trademarkId',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Марка авто + Товарная линия + ТМ -' +
        ' {{ название товарной линии }}/cars/{{марка авто}}/brand-{{ТМ}}/'
      // http://localhost:3000/filtr-masljanyj/brand-bosch/cars/audi/q7/audi-q7-4lb
    }
  },
  {
    path: '/:lineId/cars/:carBrand/:carModel',
    name: 'carBrand.carModel.lineId',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Модель авто + Товарная линия - ' +
        '{{ название товарной линии }}/cars/{{марка авто}}/{{модель авто}}/'
      // http://localhost:3000/filtr-masljanyj/cars/audi/q7
    }
  },
  {
    path: '/:lineId/cars/:carBrand/:carModel/:carGeneration',
    name: 'carBrand.carGeneration.carModel.lineId',
    component: CatalogPage,
    meta: {
      withCar: true,
      seoName: 'Поколение + Товарная линия' +
        '/{{ название товарной линии }}/cars/{{марка авто}}/{{модель авто}}/{{модификация авто}}'
      // http://localhost:3000/filtr-masljanyj/cars/audi/q7/audi-q7-4lb
    }
  }
];

const routes = getParsedRouting(_routes);

updateCollections(routes);

Vue.use(Router);

export function createRouter () {
  const router = new Router({
    mode: 'history',
    routes
  });

  router.blank = (options) => {
    const url = typeof options === 'string'
      ? options
      : router.resolve(options).href;

    if (window) {
      window.open(url, '_blank');
    } else {
      router.push(url);
    }
  };

  router.reload = async (options) => {
    await router.push(options).catch(e => e);

    if (window) {
      window.location.reload();
    }
  };

  return router;
}
