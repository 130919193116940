var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "seller-orders-page" },
    [
      _c(
        "h1",
        { staticClass: "custom-headline custom-headline--level2-bold" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("routes.profile_business_seller_orders")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("SellerOrdersTable", {
        attrs: {
          "use-page": "",
          load: _vm.loadOrderItems,
          "prop-count": _vm.count,
          "prop-value": _vm.items,
        },
        on: { "item:update": _vm.updateItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }