var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GoogleLogin",
    {
      staticClass: "google-btn google",
      attrs: {
        "on-failure": _vm.onFailure,
        "on-success": _vm.onSuccess,
        params: _vm.params,
      },
    },
    [
      _c("img", {
        staticClass: "icon-img",
        attrs: { alt: "Google", src: require("@/assets/img/google1.png") },
      }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.$t("buttons.google")))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }