<template>
  <div class="bank-requisites-block">
    <el-form-item
      :label="$t('requisites.bank.name')"
      prop="bank_requisites.name"
    >
      <el-input
        class="max-w-420p"
        :value="value.name"
        @input="emit('name', $event)"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.bank.mfo')"
      prop="bank_requisites.bic"
    >
      <el-input
        class="max-w-182p"
        :value="value.bic"
        @input="emit('bic', $event)"
      />
    </el-form-item>

    <el-form-item
      :label="$t('requisites.bank.iban')"
      prop="bank_requisites.iban"
    >
      <el-input
        class="max-w-182p"
        :value="value.iban"
        @input="emit('iban', $event)"
      />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'BankRequisitesBlock',

  props: {
    value: { type: Object, required: true }
  },

  methods: {
    emit (name, value) {
      this.$emit(`update:${name}`, value);
    }
  }
};
</script>
