var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "num-input" },
    [
      !_vm.hideLabel
        ? _c("div", { staticClass: "label mb-1" }, [
            _vm._v(_vm._s(_vm.label || _vm.$t(_vm.scope + "." + _vm.field))),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { "label-width": "0", prop: _vm.validationProp } },
        [
          !_vm.readonly
            ? _c("el-input", {
                attrs: { size: "mini" },
                model: {
                  value: _vm.val,
                  callback: function ($$v) {
                    _vm.val = $$v
                  },
                  expression: "val",
                },
              })
            : _c("el-input", {
                attrs: { disabled: "", size: "mini", value: _vm.float },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }